import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import {
  Box,
  Container,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Autocomplete,
  SwipeableDrawer,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  useTheme,
  Stack,
  Paper,
  CircularProgress
} from '@mui/material';
import { Add, Save } from '@material-ui/icons';
import { styled, alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import * as ReactDOMServer from 'react-dom/server';

// library
import Drawflow from 'drawflow';
import styleDrawflow from 'drawflow/dist/drawflow.min.css';
import cytoscape from 'cytoscape';
import coseBilkent from 'cytoscape-cose-bilkent';
import CytoscapeComponent from 'react-cytoscapejs';
import contextMenus from 'cytoscape-context-menus'; // cytoscape-context-menus
// import cytoscape-context-menus CSS
import 'cytoscape-context-menus/cytoscape-context-menus.css';
// State management
import { useSelector, useDispatch } from 'react-redux';
import edgehandles from 'cytoscape-edgehandles';
import popper from 'cytoscape-popper';
// components
import Page from '../Page';
import KnowledgeGraphDataSource from './KnowledgeGraphDataSource';
import NodeStyleSetting from './NodeStyleSetting';
import EdgeStyleSetting from './EdgeStyleSetting';
import KnowledgeGraphEditOptionsPlate from './KnowledgeGraphEditOptionsPlate';

import {
  getIcon,
  groupChainIcon,
  JourneyIcon,
  DataSourceIcon,
  ModelIcon,
  WhatsAppIcon,
  UserIcon,
  MoreIcon,
  SidebarSettingIcon,
  AddIcon,
  CloseIcon,
  UploadIcon,
  ColorPanelIcon,
  DoubleArrowIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlank,
  HomeIcon
} from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';

// services
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import dataSourceService from '../../../services/dataSourceService';
import KnowledgeGraphModelComp from './KnowledgeGraphModelComp';

// user Details
import { userDataFromLocal } from '../../../utils/getUserDetails';
// component call from other
import NodeInfo from './NodeInfo';
import NodeEdge from './NodeEdge';
import { collapsedGraphDataToPrev, expandGraphByNodeNeighborhood } from './Utils';
import KgDataSourceComp from './KgDataSourceComp';
import { fDateTime } from '../../../utils/formatTime';
import ApiErrorDialog from './ApiErrorDialog';
import LoaderBlur from './LoaderBlur';

const edgeStylesheet = [
  {
    selector: 'node[name]',
    style: {
      content: 'data(name)'
    }
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'bezier',
      'target-arrow-shape': 'triangle',
      'target-arrow-color': '#ccc',
      'line-color': 'pink'
    }
  },
  {
    selector: '.eh-handle',
    style: {
      'background-color': 'red',
      width: 12,
      height: 12,
      shape: 'ellipse',
      'overlay-opacity': 0,
      'border-width': 12,
      'border-opacity': 0
    }
  },
  {
    selector: '.eh-hover',
    style: {
      'background-color': 'red'
    }
  },
  {
    selector: '.eh-source',
    style: {
      'border-width': 2,
      'border-color': 'red',
      'border-opacity': 1
    }
  },
  {
    selector: '.eh-target',
    style: {
      'border-width': 2,
      'border-color': 'red',
      'background-opacity': 1
    }
  },
  {
    selector: '.eh-preview, .eh-ghost-edge',
    style: {
      'background-color': 'red',
      'line-color': 'red',
      'target-arrow-color': 'red',
      'source-arrow-color': 'red'
    }
  },
  {
    selector: '.eh-ghost-edge.eh-preview-active',
    style: {
      opacity: 0
    }
  },
  // selected elements style
  {
    selector: `node:selected`,
    style: {
      'border-width': '10px',
      'border-color': 'rgb(253, 204, 89)',
      'border-opacity': 1
    }
  },
  {
    selector: `edge:selected`,
    style: {
      width: '10px',
      'line-color': 'rgb(253, 204, 89)',
      'target-arrow-color': 'rgb(253, 160, 60)'
    }
  }
];

// Use APIs
const { CreateVisualizationExp, ProjectList, DataSourcelist, GetApiByUrl } = KnowledgeGraphService;
//
const { DatasetSchema, DatasetColumn } = dataSourceService;
// register extension
cytoscape.use(contextMenus);
cytoscape.use(coseBilkent);
cytoscape.use(edgehandles);
cytoscape.use(popper);
// require variable
const DrawFlow = require('drawflow');
//  const styleDrawflow = require('drawflow/dist/drawflow.min.css');
// const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
function CreateKnowledgeGraph({ noModal }) {
  const [labelToDisplay, setLabelToDisplay] = useState([]);
  const [labelEdgeToDisplay, setlabelEdgeToDisplay] = useState([]);
  const [helperTxtErr, setHelperTxtErr] = useState('');
  const navigate = useNavigate();
  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);

  const {
    allSelectedData: {
      currentActiveStep,
      initMainGraph,
      nodeForConnection,
      sourceType,
      dataSource,
      schemas,
      tables,
      fileds,
      mappedSource,
      mappedField,
      curentField,
      currentSelectedmodelId,
      retrivedDatasetId,
      refreshComponent,
      newtableName,
      hideSideBar,
      hideSideBarRight,
      expanded,
      projectListCount
    },
    createKnowledgeGraph: { styling, stylingArchive },
    addMoreLinkingArray,
    currFieldsLists,
    mappedFieldList,
    allModelsForProjects,
    exitsTableList
  } = knowledgeGraphStoreItem;

  useEffect(() => {
    (async () => {
      const authToken = userDataFromLocal();
      const params = `?user_id=${authToken.userID}`;
      const projectListData = await ProjectList(params, authToken.token); // api to fire projectlist
      if (projectListData.status === 'success') {
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'projectListCount',
            value: projectListData.data.projects
          }
        });
      }
    })();
  }, []);

  // console.log(knowledgeGraphStoreItem.allSelectedData.projectListCount, 'allSelectedData235');

  // console.log('newTableName', newtableName);
  // console.log('exitsTableListexitsTableList', exitsTableList);
  // console.log('exitsTableListexitsTableList', currentSelectedmodelId);
  // console.log('currentActiveStep', tables);
  // console.log('currentActiveStep', fileds);
  // console.log('currentActiveStep', mappedSource);
  // console.log('currentActiveStep', mappedField);
  // console.log('currentActiveStep', currentActiveStep);
  // console.log('exitsTableListexitsTableList', exitsTableList);
  const dispatch = useDispatch();
  // const todo = useSelector((state) => console.log('init store2', state));
  // console.log(useLocation());
  const elementReference = useRef();
  // const drawFlowRef = useRef();
  // const drawflowReference = useRef();
  const [expandedPanel, setExpandedPanel] = useState(false);
  const handleExpanedPanel = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { experimentId, experimentName, modelId } = useParams();

  // console.log('useParams()', useParams());
  // Accordion use

  // const [expanded, setExpanded] = useState('panel1');
  const [isExpanded, setIsExpanded] = useState('panel4');

  const handleLeftAccordionPanel = (panel) => (event, newExpanded) => {
    setIsExpanded(newExpanded ? panel : false);
    if (panel === 'panel4') {
      setRefreshPopupComp(new Date());
      navigate(`/knowledgegraph/edit/${experimentId}/${experimentName}`);
    }
  };

  useEffect(() => {
    if (expanded) {
      panelLeftSideBar('hideBehind');
      setIsExpanded(expanded);
    }
  }, [expanded]);

  // create visualization state
  const [openVisualization, setOpenVisualization] = useState(false);
  const [visualizationName, setVisualizationName] = useState('');
  const newProjectNameRef = useRef(null);
  const [saveProjectNameLoader, setSaveProjectNameLoader] = useState(false);
  const [createVisualMutate, setCreateVisualMutate] = useState('');
  const [slectedOption, setSlectedOption] = useState('');

  const [datasetDialog, setDatasetDialog] = useState();
  // Diaolog box open and close state
  const [refreshKGComp, setRefreshKGComp] = useState(null);
  const [refreshTableConnectionCount, setRefreshTableConnectionCount] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [edgeOpen, setEdgeOpen] = useState(false);
  const [sourceLabelId, setSourceLabelId] = useState(null);
  const [targetLabelId, setTargetLabelId] = useState(null);

  // drawFlowCanvas
  const [drawFlowCanvas, setDrawFlowCanvas] = useState(null);
  // console.log('drawFlowCanvas', drawFlowCanvas);
  // single project data
  const [projectData, setProjectData] = useState({});

  // Right click menu on graph
  const [graphRightClick, setGraphRightClick] = useState({});

  // data source column data
  const [responseColoumData, setResponseColoumData] = useState([
    'satisfaction_level',
    'last_evaluation',
    'number_project',
    'average_montly_hours',
    'time_spend_company',
    'Work_accident',
    'promotion_last_5years',
    'department',
    'salary',
    'left'
  ]);
  const [editor, setEditor] = useState(null);
  const [cyToState, setCyToState] = useState(null);
  const [recallEverything, setRecallEverything] = useState(true);
  const [cytoscapeData, setCytoscapeData] = useState([]);
  // const [nodeId, setNodeId] = useState(); // nodeId for selected node
  const [cySelectedElement, setCySelectedElement] = useState({ id: '', type: '' });
  console.log('createcyToState', cyToState);

  const [rightPanelConfirm, setRightPanelConfirm] = useState(false);

  useEffect(() => {
    console.log('cyToState menu', cyToState);
    if (cyToState) {
      setGraphRightClick({
        // Possible options https://js.cytoscape.org/#events/user-input-device-events
        evtType: 'cxttap',
        menuItems: [
          {
            id: 'delete',
            content: 'Delete',
            tooltipText: 'Delete',
            selector: 'node',
            onClickFunction: function (evt) {
              if (cyToState) {
                console.log('remove node', evt.target.data(), cyToState);
                cyToState.remove(evt.target);
              }
            }
          }
        ],
        // css classes that menu items will have
        menuItemClasses: [
          // add class names to this list
          'rightClickMenuItem'
        ],
        // css classes that context menu will have
        contextMenuClasses: [
          // add class names to this list
          'rightClickMenuHolder'
        ],
        // Indicates that the menu item has a submenu. If not provided default one will be used
        submenuIndicator: { src: 'assets/submenu-indicator-default.svg', width: 12, height: 12 }
      });
    }
  }, [cyToState]);

  useEffect(() => {
    if (
      Object.keys(graphRightClick).length > 0 &&
      cyToState &&
      cyToState?.nodes
      // &&
      // cyToState.nodes().length > 0
    ) {
      const instance = cyToState.contextMenus(graphRightClick);
      // instance.enableMenuItem('add-edge');
    }
    console.log(
      'context menu',
      Object.keys(graphRightClick).length > 0 && cyToState && cyToState?.nodes
    );
  }, [cyToState, graphRightClick, cytoscapeData]);

  useEffect(() => {
    // Popper settings below
    let popperNode;
    let popper;
    let popperDiv;
    let started = false;

    function removeHandle(popper, popperDiv, popperNode) {
      if (popper) {
        popper.destroy();
        popper = null;
      }

      if (popperDiv && popperDiv.parentNode) {
        popperDiv.parentNode.removeChild(popperDiv);
        popperDiv = null;
      }

      popperNode = null;
    }

    if (cyToState) {
      // Edge Drag Feature
      const eh = cyToState.edgehandles({
        canConnect: function (sourceNode, targetNode) {
          // whether an edge can be created between source and target
          return !sourceNode.same(targetNode); // e.g. disallow loops
        },
        edgeParams: function (sourceNode, targetNode) {
          // for edges between the specified source and target
          // return element object to be passed to cy.add() for edge
          return {};
        },
        hoverDelay: 150, // time spent hovering over a target node before it is considered selected
        snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
        snapThreshold: 8, // the target node must be less than or equal to this many pixels away from the cursor/finger
        snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
        noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
        disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
      });
      // eh.enableDrawMode();

      function startDraw() {
        eh.start(popperNode);
      }

      function stopDraw() {
        eh.stop();
      }

      function setHandleOn(node) {
        if (started) {
          return;
        }

        removeHandle(popper, popperDiv, popperNode); // remove old handle

        popperNode = node;

        popperDiv = document.createElement('div');
        popperDiv.classList.add('popper-handle');
        popperDiv.addEventListener('mousedown', startDraw);
        document.body.appendChild(popperDiv);

        popper = node.popper({
          content: popperDiv,
          popper: {
            placement: 'top',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10]
                }
              }
            ]
          }
        });
      }

      cyToState.on('mouseover', 'node', function (e) {
        setHandleOn(e.target);
      });

      cyToState.on('mouseout', 'node', function (e) {
        removeHandle(popper, popperDiv, popperNode);
      });

      cyToState.on('grab', 'node', function () {
        removeHandle(popper, popperDiv, popperNode);
      });

      cyToState.on('tap', function (e) {
        if (e.target === cyToState) {
          removeHandle(popper, popperDiv, popperNode);
        }
      });

      cyToState.on('zoom pan', function () {
        removeHandle(popper, popperDiv, popperNode);
      });

      window.addEventListener('mouseup', function (e) {
        stopDraw();
      });

      cyToState.on('ehstart', function () {
        started = true;
      });

      cyToState.on('ehstop', function () {
        started = false;
      });
    }
    // console.log('experimentId && experimentName && modelId', experimentId, experimentName, modelId);
    if (experimentId && experimentName && modelId) {
      panelLeftSideBar('hideBehind');
      setIsExpanded('panel5');
    }

    return () => {
      removeHandle(popper, popperDiv, popperNode);
    };
  }, [cyToState]);

  const [colorCodes, setColroCodes] = useState([
    { company: 'rgb(96, 74, 14)' },
    { customer: 'rgb(201, 144, 192)' },
    { address: 'rgb(247, 151, 103)' },
    { director: 'rgb(87, 199, 227)' },
    { blackListDirector: 'rgb(241, 102, 103)' },

    { blackListCompany: 'rgb(239, 9, 10)' },
    { blaclist: 'rgb(239, 9, 10)' },
    { buyer: 'rgb(217, 200, 174)' },
    { companyDirector: 'rgb(141, 204, 147)' },
    { transactions: 'rgb(76, 142, 218)' }
  ]);
  // console.log('colorCodescolorCodes', colorCodes);
  const [stylingsss, setStyling] = useState(); // Style for all linked nodes

  // console.log('elementStyle', refreshKGComp);
  // console.log('elementStyle', styling);
  // hover node state
  const [hoverState, setHoverState] = useState(false);
  // sidebar on node selection
  const [nodeSelect, setNodeSelect] = useState({
    category: 'Messaging',
    country: 'USA',
    first_funding: '01/10/2009',
    founded_at: '01/10/2009',
    founded_monthly: '2009-01',
    founded_quarterly: '2009-01',
    founded_year: 2009,
    funding_round: 3,
    funding_total: 58250000,
    last_funding: '01/07/2003'
  });
  // static data
  const [relationshipData, setRelationshipData] = useState([]);
  // dataset state
  // const [dataSourceList, setDataSourceList] = useState([]);
  // dataset state
  const [dataSourceSchema, setDataSourceSchema] = useState([]);
  // dataset state
  const [datasetList, setDatasetList] = useState([
    {
      table: 'Dataset'
    }
  ]);
  // fields Name
  const [fieldsnames, setFieldsnames] = useState([
    { filedName: 'id' },
    { filedName: 'name' },
    { filedName: 'class' },
    { filedName: 'police' },
    { filedName: 'bankNo' },
    { filedName: 'age' }
  ]);
  // properties state

  // Selected datasource name, Scema name, Table Name and column Name
  const [selectedSourceAndColumn, setSelectedSourceAndColumn] = useState({
    sourceType: 'Db',
    dataSourceName: '%%',
    schmeaName: '%%',
    tableName: '%%',
    columnName: '%%'
  });
  // handleCloseVisualization functoin
  const handleCloseVisualization = () => {
    setOpenVisualization(false);
    // setVisualizationName('');
  };

  let saveProjectNameInterval;
  // create visualization function
  const CreateVisualization = async (e, projectName) => {
    // getting response from create visualization api
    // const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const authToken = userDataFromLocal();
    const createVisualizationApi = await CreateVisualizationExp(
      {
        project_name: projectName || visualizationName,
        username: userDataFromLocal().userName,
        datasource_id: 1,
        creator_user_id: authToken.userID
      },
      authToken.token
    );

    if (createVisualizationApi.status === 'success') {
      const demoExperiment_id = createVisualizationApi.data.project_id;
      const demoExperiment_name = projectName || visualizationName;
      setOpenVisualization(false);
      setSaveProjectNameLoader(false);
      navigate(`/knowledgegraph/edit/${demoExperiment_id}/${demoExperiment_name}`);
      enqueueSnackbar(
        // createVisualizationApi.message
        `${newProjectNameRef.current && newProjectNameRef.current.value} created successfully.`,
        {
          variant: createVisualizationApi.status,
          autoHideDuration: 2000
        }
      );
    } else {
      const demoExperiment_id = 0;
      const demoExperiment_name = 'demoaccount';
      setOpenVisualization(false);
      navigate(`/knowledgegraph/edit/${demoExperiment_id}/${demoExperiment_name}`);
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'apiErrorDialogValue',
          value: { dialog: true, message: createVisualizationApi.message }
        }
      });
    }
    // enqueueSnackbar(createVisualizationApi.message, {
    //   variant: createVisualizationApi.status,
    //   autoHideDuration: 2000
    // });

    setTimeout(() => {
      setCreateVisualMutate(new Date());
    }, 1000);
  };

  const CancelHandler = (e) => {
    navigate(`/knowledgegraph`);
    setOpenVisualization(false);
    setCreateVisualMutate(new Date());
  };

  // function for show and hide when mouse over
  const showHideNode = (e) => {
    const MouseEventX = e.clientX;
    const MouseEventY = e.clientY;
    if (MouseEventY < 90) {
      setHoverState(true);
    } else {
      setHoverState(false);
    }
  };

  const panelLeftSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBar',
        value: newValue
      }
    });
  };

  const panelRightSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBarRight',
        value: newValue
      }
    });
  };
  /**
   * Drag function for node
   */
  const drag = (ev) => {
    ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'));
  };
  const drop = (ev, cyToState) => {
    ev.preventDefault();
    let maxValueOf_ID = 0;
    const elementsData = cyToState.elements().map((item) => item.data());
    if (elementsData.length > 0) {
      maxValueOf_ID = elementsData.reduce(
        (max, obj) => (obj.name > max ? obj.name : max),
        elementsData[0]?.name
      );
    }
    console.log('cytoscapedata style before', elementsData);
    console.log('cytoscapedata style', styling, stylingArchive);

    cyToState.add({
      group: 'nodes',
      data: {
        id: maxValueOf_ID + 1,
        label: maxValueOf_ID + 1,
        name: maxValueOf_ID + 1,
        targeted: [],
        dataset: datasetList,
        properties: [],
        relationship: relationshipData,
        datasetValue: ' ',
        propertiesValue: [],

        position: { x: ev.clientX - 60, y: ev.clientY }
      },
      position: { x: ev.clientX - 60, y: ev.clientY }
    });

    // console.log(cyToState.nodes().data());
    setRefreshPopupComp(new Date());
    cyToState.zoom(0.7);
    console.log(
      'cytoscapedata style after',
      cyToState.elements().map((item) => item.data())
    );
  };
  // Api for properties dialog box by man
  const [sourceNode, setSourceNode] = useState([]);
  const [getpropertiesLabel, setGetPropertiesLabel] = useState([
    { column: 'column', field_value: 'column' },
    { column: 'test2', field_value: 'test2' },
    { column: 'column', field_value: 'column' },
    { column: 'columnName', field_value: 'columnName' },
    { column: 'aaa', field_value: 'aaaa' },
    { column: 'bbbb', field_value: 'bbbb' }
  ]);
  const [nodeProperties, setNodeProperties] = useState([
    { field_name: 'test1', field_value: 'test1' },
    { field_name: 'test2', field_value: 'test2' },
    { field_name: 'test3', field_value: 'test3' },
    { field_name: 'test4', field_value: 'test4' },
    { field_name: 'test5', field_value: 'test5' },
    { field_name: 'test6', field_value: 'test6' }
  ]);
  const [refreshPopupComp, setRefreshPopupComp] = useState(null);
  const getpropertiesList = async (selectedTableName) => {
    const credential = {
      datasource_name: selectedSourceAndColumn.dataSourceName,
      crea_user: userDataFromLocal().userName,
      schema: selectedSourceAndColumn.schmeaName,
      table: selectedTableName
    };
    const getPropertiesData = await DatasetColumn(credential);
    // console.log('getpropertiesList', getPropertiesData);
    if (getPropertiesData.status === 'success') {
      setNodeProperties(getPropertiesData.data);
      setRefreshPopupComp(new Date());
    }
  };
  const [nodeAdded, setNodeAdded] = useState([]);
  // console.log('nodeAdded', nodeAdded);
  // useEffect for add experiment name dialogbox
  useEffect(() => {
    if (mappedFieldList && Object.values(mappedFieldList).length > 0 && modelId === undefined) {
      panelLeftSideBar('hideBehind');
      handleLeftAccordionPanel('panel4');
      console.log('currentActiveStep > 0 && !noModal', currentActiveStep, noModal);
    }

    // console.log(iconsObjects[activeMainTab]);
    // console.log('experimentId', experimentId);
    if (experimentId === undefined) {
      setOpenVisualization(true);
    }
    // check IF cyToState not null so click function work properly
    if (cyToState !== null) {
      cyToState.nodes().forEach((node) => {
        console.log('cuurrentnode', node);
      });
      // console.log('cytoscapeData2', cyToState.elements());
      // console.log(cyToState.$(':selected'));
      cyToState.on('tap', 'node', (evt) => {
        const target = evt.target || evt.cyTarget;
        // setNodeId(target.data().id);
        setCySelectedElement({ id: target.data().id, type: 'node' });
        panelLeftSideBar('hideBehind');
        setSlectedOption('node');
        // setRefreshKGComp(new Date());
      });
      cyToState.on('tap', (evt) => {
        // console.log('targetNode', evt.target === cyToState);
        // evt.target to target clicked elm
        if (evt.target === cyToState) {
          setSlectedOption('');
          // collapsedGraphDataToPrev(cyToState);
        }
      });
      cyToState.on('tap', 'edge', (evt) => {
        panelLeftSideBar('hideBehind');
        setSlectedOption('edge');
        console.log('cyToStatecyToState', evt.target.data());
        // setNodeId(evt.target.data().id);
        setCySelectedElement({ id: evt.target.data().id, type: 'edge' });
      });
      // console.log('cyToState.nodes()', cyToState.nodes().length);
      const allLabels = [];
      for (let i = 0; i < cyToState.nodes().length; i++) {
        // console.log('cyToState.nodes()', cyToState.nodes()[i]);
        const isExits = allLabels.filter(
          (item) => item.label === cyToState.nodes()[i].data().label
        );
        if (isExits.length === 0) {
          allLabels.push(cyToState.nodes()[i].data());
        }
      }

      const allEdgeLabels = [];
      for (let i = 0; i < cyToState.edges().length; i++) {
        const isExits = allEdgeLabels.filter(
          (item) => item.label === cyToState.edges()[i].data().label
        );
        if (isExits.length === 0) {
          allEdgeLabels.push(cyToState.edges()[i].data());
        }
      }

      // cyToState.nodes().some((nodeData) => {
      //   console.log('nodeData', nodeData.data());
      //   const isExits = allLabels.filter((item) => item.label === nodeData.data().label);
      //   if (isExits.length === 0) {
      //     allLabels.push(nodeData.data());
      //   }
      // });
      if (allLabels?.length > 0) {
        const _newData = {};
        allLabels.forEach((node) => {
          _newData[node.label] = {
            'background-color': '',
            label: 'data(label)',
            height: '',
            width: '',
            padding: '',
            'background-fit': '',
            'background-opacity': '',
            'border-width': '',
            'border-style': '',
            'border-color': '',
            'border-opacity': '',
            shape: '',
            'background-repeat': '',
            'background-image': '',
            'background-image-opacity': '',
            'font-size': '',
            // 'text-margin-y': '-7px',
            'text-valign': '',
            'text-halign': '',
            color: '',
            'text-opacity': ''
          };
        });
        console.log(_newData, '_newData');
        dispatch({
          type: 'STYLING_DATA_UPDATE',
          payload: {
            key: 'vizNodeStyles',
            value: _newData
          }
        });
      }
      if (allEdgeLabels?.length > 0) {
        const _newData = {};
        allEdgeLabels.forEach((node) => {
          _newData[node.label] = {
            'line-color': '#f9ab62',
            label: 'data(label)',
            width: '2',
            padding: '0',
            'curve-style': 'unbundled-bezier',
            'line-style': 'solid',
            'target-arrow-shape': 'triangle',
            'target-arrow-color': '#c4c4c4',
            'arrow-scale': 1,
            'line-opacity': 1,
            'font-size': '9',
            // 'text-margin-y': '-7px',
            'text-valign': 'center',
            'text-halign': 'center',
            color: '#000',
            'text-opacity': 1
          };
        });
        console.log(_newData, '_newData');
        dispatch({
          type: 'STYLING_DATA_UPDATE',
          payload: {
            key: 'vizEdgeStyles',
            value: _newData
          }
        });
      }
      setLabelToDisplay(allLabels);
      setlabelEdgeToDisplay(allEdgeLabels);
      // console.log('allLabelsallLabels', allLabels);
      // for enable the menu item with given ID
      // if (cyToState.nodes().length > 0) {
      //   const instance = cyToState.contextMenus(graphRightClick);
      //   // instance.enableMenuItem('add-edge');
      // }
    }
    // show sidebar and topbar on mouse move
    // window.addEventListener('click', (e) => {
    //      setHideSideBar('hideBehind');

    //   }
    //   // if (slectedOption === '') {
    //   //   if (e.clientX < 250) {
    //   //     setHideSideBar('hideBehind');
    //   //   } else {
    //   //     setHideSideBar('');
    //   //   }
    //   // }
    //   // const MouseEventY = e.clientY;
    //   // if (MouseEventY < 70) {
    //   //   setHoverState(true);
    //   // } else {
    //   //   setHoverState(false);
    //   // }
    // });
    // api for dataset list on node selected
    (async () => {
      if (experimentId) {
        const authToken = userDataFromLocal();
        const params = `?user_id=${authToken.userID}`;
        const projectListData = await ProjectList(
          params + '&project_id=' + experimentId,
          authToken.token
        ); // api to fire projectlist
        // console.log(projectListData);
        if (
          projectListData.status === 'success'
          // &&
          // currentSelectedmodelId &&
          // currentSelectedmodelId !== ''
        ) {
          setProjectData(projectListData.data.projects);
        }
        let getMetadata = {};
        if (
          recallEverything
          // || cyToState === null || cyToState.elements.length <= 0
        ) {
          getMetadata = await GetApiByUrl(
            'visual/manage_metadata?project_id=' + experimentId + '&link_id=' + modelId
          );
        }
        if (getMetadata.status === 'success' && getMetadata.code === 200) {
          setRecallEverything(false);
          // console.log('getMetadata1', getMetadata.data.metadata);
          if (getMetadata.data.metadata && getMetadata.data.metadata.length > 0) {
            // console.log('getMetadata2', getMetadata.data.metadata[0]);
            if (
              getMetadata.data.metadata[0].others &&
              getMetadata.data.metadata[0].nodes_metadata
            ) {
              const {
                others: { savedDatasetId, stylying },
                meta_id,
                nodes_metadata
              } = getMetadata.data.metadata[0];
              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: {
                  key: 'retrivedDatasetId',
                  value: savedDatasetId
                }
              });
              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: {
                  key: 'currentMetaDataId',
                  value: meta_id
                }
              });
              const grapharray = createNodesAndEdges(nodes_metadata);
              // stylying.forEach((item) => (item.style = {}));
              // console.log('stylyingstylying', stylying);
              setCytoscapeData(grapharray);
              // setStyling(stylying);
              dispatch({
                type: 'CREATE_KG',
                payload: {
                  key: 'styling',
                  value: [
                    ...styling,
                    ...stylying
                    // some style for the edge extension
                    // ...edgeStylesheet
                  ]
                }
              });

              // cyToState.zoom(0.5);
              if (cyToState) {
                const layout = cyToState.layout({
                  name: 'cose-bilkent',
                  zoom: 0.5,
                  animate: true, // whether to transition the node positions
                  animationDuration: 500 // duration of animation in ms if enabled
                });
                layout.run();
              }
            } else {
              // console.log('getMetadata3', cyToState);
              setCytoscapeData([]);
              const layout = cyToState.layout({
                name: 'cose-bilkent',
                zoom: 0.5,
                animate: true, // whether to transition the node positions
                animationDuration: 500 // duration of animation in ms if enabled
              });
              layout.run();
              // dispatch({
              //   type: 'STORE_ALL_SELECTED_DATA',
              //   payload: {
              //     key: 'retrivedDatasetId',
              //     value: ''
              //   }
              // });
              // dispatch({
              //   type: 'STORE_ALL_SELECTED_DATA',
              //   payload: {
              //     key: 'currentdataSetId',
              //     value: ''
              //   }
              // });
              // dispatch({
              //   type: 'STORE_ALL_SELECTED_DATA',
              //   payload: {
              //     key: 'currentMetaDataId',
              //     value: ''
              //   }
              // });
            }
          } else {
            // console.log('getMetadata3', cyToState);
            setCytoscapeData([]);
            // const layout = cyToState.layout({
            //   name: 'cose-bilkent',
            //   zoom: 0.5,
            //   animate: true, // whether to transition the node positions
            //   animationDuration: 500 // duration of animation in ms if enabled
            // });
            // layout.run();
            // dispatch({
            //   type: 'STORE_ALL_SELECTED_DATA',
            //   payload: {
            //     key: 'retrivedDatasetId',
            //     value: ''
            //   }
            // });
            // dispatch({
            //   type: 'STORE_ALL_SELECTED_DATA',
            //   payload: {
            //     key: 'currentdataSetId',
            //     value: ''
            //   }
            // });
            // dispatch({
            //   type: 'STORE_ALL_SELECTED_DATA',
            //   payload: {
            //     key: 'currentMetaDataId',
            //     value: ''
            //   }
            // });
          }
        }
      }

      // need to fetch via dynamic user name latter
      // const createUserName = userDataFromLocal().userName
      //   ? userDataFromLocal().userName
      //   : 'postgres';
      // const urlParams = `fetch_datasource_lists/?create_user=postgres&datasource_type=${selectedSourceAndColumn.sourceType}`;
      const authToken = userDataFromLocal();
      const params = `?user_id=${authToken.userID}`;
      // const urlParams = `visual/fetch_datasource_lists${params}&datasource_type=${selectedSourceAndColumn.sourceType}`;
      const urlParams = `visual/fetch_datasource_lists${params}&data_source_type=${selectedSourceAndColumn.sourceType}`;

      const allDataSourceList = await DataSourcelist(urlParams);
      // console.log('allDataSourceList', allDataSourceList);
      if (allDataSourceList.status === 'success') {
        allDataSourceList.data.forEach((element) => {
          element.data_set_name = element.datasource_name;
          element.record_id = element.datasource_id;
        });
        // console.log('allDataSourceList', allDataSourceList);
        // setDataSourceList(allDataSourceList.data);
        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: { key: 'dataSourceList', value: allDataSourceList.data }
        });
      }
    })();
  }, [
    recallEverything,
    cyToState,
    createVisualMutate,
    refreshPopupComp,
    drawFlowCanvas,
    allModelsForProjects,
    currentSelectedmodelId,
    refreshKGComp,
    colorCodes,
    mappedFieldList
  ]);
  // Accordion tab const
  const handleChange = (panel) => (event, newExpanded) => {
    // console.log('panelPanel', panel, newExpanded);
    setIsExpanded(newExpanded ? panel : false);
  };
  // console.log('responseColoumData: ', responseColoumData);

  const [state, setState] = useState(false);
  const [nodeProperty, setnodeProperty] = useState('');
  const selectChange = (event) => {
    setnodeProperty(event.target.value);
  };
  // console.log('nodeProperties', nodeProperties);

  const rightPosition = document.body.clientWidth - 240;
  // console.log('addMoreLinkingArray2', refreshPopupComp);

  console.log('mappedFieldListmappedFieldListMain', mappedFieldList);

  // const [ckgLoader, setCkgLoader] = useState(false);

  let ckTimer;

  const measuredRef = useCallback(
    (node) => {
      console.log('mappedFieldListmappedFieldListMain callback', mappedFieldList);
      // console.log('mappedFieldListmappedFieldList', exitsTableList);
      // console.log('mappedFieldListmappedFieldList', window.innerWidth);
      // console.log('currFieldsLists', currFieldsLists);
      // console.log('addMoreLinkingArray2', tables.name);
      const mappedBySorting = sortedArray(mappedFieldList);

      Object.values(mappedBySorting).forEach((mapoedFiled, filedIndex) => {
        // console.log('mapoedFiled', mapoedFiled);
        mapoedFiled.pos_x = getPosX(filedIndex, mappedBySorting);
        mapoedFiled.pos_y = getPosY(filedIndex, mappedBySorting);
      });
      const targetedArray = [];
      addMoreLinkingArray.forEach((data) => {
        const sourceIndex = targetedArray.findIndex(
          (source) => source.sourceName === data.mappedSource.id
        );

        if (sourceIndex === -1) {
          const newSource = {
            sourceName: data.mappedSource.id,
            fieldsArray: [{ columName: data.mappedField.id, connectedColumn: data.curentField.id }]
          };
          targetedArray.push(newSource);
        } else {
          targetedArray[sourceIndex].fieldsArray.push({
            columName: data.mappedField.id,
            connectedColumn: data.curentField.id
          });
        }
      });
      // console.log('targetedArray', targetedArray);
      if (node !== null) {
        // console.log('node.childNodes', node.childNodes);
        if (node.childNodes.length > 0) {
          node.querySelectorAll('*').forEach((n) => n.remove());
          // measuredRef();
        }
        // node.childNodes.length = 0;
        const canvas = new Drawflow(node);
        // console.log('nodenode', canvas);
        canvas.reroute = true;
        const allData = {
          [newtableName]: {
            id: newtableName,
            name: newtableName,
            data: {},
            class: 'columnHolder leftHolder mapped',
            html: getLeftHolderHtml(currFieldsLists, newtableName),
            typenode: false,
            inputs: getInputDots(currFieldsLists),
            outputs: getOutputDots(currFieldsLists),
            pos_x: 380,
            pos_y: 30,
            sorting: Object.keys(mappedFieldList).length
              ? Object.keys(mappedFieldList).length + 1
              : 1
          },
          ...mappedFieldList
        };

        // console.log('dataToImport', allData);

        canvas.on('connectionCreated', () => {
          setRefreshTableConnectionCount(new Date());
        });
        canvas.on('connectionRemoved', () => {
          setRefreshTableConnectionCount(new Date());
        });

        const dataToImport = {
          drawflow: {
            Home: {
              data: allData
            }
          }
        };

        // console.log('dataToImport', dataToImport);
        // console.log('dataToImport', node.childNodes.length);
        if (node.childNodes.length < 1) {
          canvas.start();
          canvas.import(dataToImport);

          setDrawFlowCanvas(canvas);
          // setStyling(stylying);
          dispatch({
            type: 'CREATE_KG',
            payload: {
              key: 'datasourceLinkMap',
              value: canvas
            }
          });
        }
      }
    },
    [refreshPopupComp, experimentId, mappedFieldList]
  );

  // useEffect(() => {
  //   setCkgLoader(true);

  //   clearTimeout(ckTimer);
  //   ckTimer = setTimeout(() => {
  //     setCkgLoader(false);
  //   }, 2000);
  // }, [refreshPopupComp, experimentId, mappedFieldList]);

  // Hide new table until currentActiveStep === 2
  useEffect(() => {
    if (
      currentActiveStep === 2 &&
      newtableName &&
      document.getElementById(`node-${newtableName}`)
    ) {
      document.getElementById(`node-${newtableName}`).style.display = 'flex';
    } else if (newtableName && document.getElementById(`node-${newtableName}`)) {
      document.getElementById(`node-${newtableName}`).style.display = 'none';
    }
    // console.log(`newtableName node-${newtableName}`, newtableName, currentActiveStep);
  }, [newtableName, refreshPopupComp, currentActiveStep, experimentId, mappedFieldList]);

  useEffect(() => {
    // console.log('modelidmodelid', modelId);
    setCytoscapeData([]);
    if (modelId === undefined) {
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'currentSelectedmodelId', value: null }
      });
    }

    dispatch({
      type: 'CREATE_KG',
      payload: { key: 'styling', value: stylingArchive }
    });
  }, [modelId]);

  useEffect(() => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'currentActiveStep', value: 0 }
    });
  }, [experimentId]);

  let projectNameTimeoutID;

  return (
    <>
      {/* {ckgLoader && <LoaderBlur customMsg="Content loading..." bgProp="white" />} */}
      <Page title="Knowledge Graph" ref={elementReference} className="pageNodeStyle">
        <ApiErrorDialog />
        <Container maxWidth="xl" className="noPadding" onMouseMove={(e) => showHideNode(e)}>
          {allModelsForProjects.length > 0 && currentSelectedmodelId && (
            <Box
              className={hoverState ? 'DefaultHeaderNode nodeHolder' : 'headerNodeStyle nodeHolder'}
            >
              <Box className="nodeStyle" draggable="true" onDragStart={drag} data-node="facebook">
                Drag <br /> Node
              </Box>
            </Box>
          )}
          <Box id="settingPanel">
            {allModelsForProjects.length > 0 && currentSelectedmodelId && (
              <Box
                id="settingView"
                onClick={(e) => {
                  setState(true);
                  dispatch({
                    type: 'STORE_ALL_SELECTED_DATA',
                    payload: {
                      key: 'refreshComponent',
                      value: new Date()
                    }
                  });
                  setRefreshKGComp(new Date());
                }}
              >
                <Box className="rotateCogs" onClick={() => panelRightSideBar(true)}>
                  <FaCog />
                </Box>
              </Box>
            )}

            <Dialog open={rightPanelConfirm} onClose={() => setRightPanelConfirm(false)}>
              <DialogTitle sx={{ textAlign: 'center' }}>Confirmation Message</DialogTitle>
              <DialogContent sx={{ padding: '20px 10px', width: '400px' }}>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Please confirm if you wish to proceed. Once you close this panel, the styling will
                  revert to default when you reopen it.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setRightPanelConfirm(false)}>Back to edit</Button>
                <Button
                  onClick={() => {
                    panelRightSideBar(false);
                    setRightPanelConfirm(false);
                  }}
                >
                  Yes, I am sure
                </Button>
              </DialogActions>
            </Dialog>

            <SwipeableDrawer
              anchor="right"
              variant="persistent"
              classes={{ paper: 'SwipeableDrawer' }}
              open={hideSideBarRight}
              onClose={(e) => panelRightSideBar(false)}
              onOpen={(e) => panelRightSideBar(true)}
            >
              {hideSideBarRight && (
                <Box className="drawerWrap demoscrollbar">
                  <Box
                    id="settingView"
                    className="close"
                    // onClick={(e) => setRightPanelConfirm(true)}
                    onClick={(e) => panelRightSideBar(false)}
                  >
                    <RenderIcons icon={getIcon(CloseIcon, null, 18, 18)} />
                  </Box>
                  <NodeStyleSetting
                    styling={styling}
                    setStyling={setStyling}
                    setRefreshKGComp={setRefreshKGComp}
                    refreshKGComp={refreshKGComp}
                    cyToState={cyToState}
                    labelToDisplay={labelToDisplay}
                    expandedPanel={expandedPanel}
                    handleExpanedPanel={handleExpanedPanel}
                  />
                  <EdgeStyleSetting
                    styling={styling}
                    setStyling={setStyling}
                    setRefreshKGComp={setRefreshKGComp}
                    refreshKGComp={refreshKGComp}
                    cyToState={cyToState}
                    labelEdgeToDisplay={labelEdgeToDisplay}
                    expandedPanel={expandedPanel}
                    handleExpanedPanel={handleExpanedPanel}
                  />
                </Box>
              )}
            </SwipeableDrawer>
          </Box>

          {/* Left bar show hide */}
          <Box id="leftBarShow" className={hideSideBar}>
            <Box className="nodeVisualization" onClick={() => panelLeftSideBar('hideBehind')}>
              <RenderIcons
                className="top-minus-margin"
                icon={getIcon(DoubleArrowIcon, null, 20, 20)}
              />
            </Box>
            <Box className="close">
              <RenderIcons
                icon={getIcon(CloseIcon, null, 18, 18)}
                onClick={() => panelLeftSideBar('')}
              />
            </Box>
            {slectedOption === '' ? (
              <>
                {/* Info of canvas/editor eg: Visualization name, Node counts, Edge count */}
                <Box className="editorInfo">
                  <Accordion className="accordT" expanded={true}>
                    <AccordionSummary
                      className="accordTrigger"
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      classes={{ content: 'accordTgrtxt' }}
                    >
                      <Typography className="typoAccord">Project:</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordSummery">
                      <Typography className="userVisual" variant="h2">
                        <RenderIcons icon={getIcon(UserIcon, null, 13, 13)} />
                        <span className="wordwrapbreak"> {experimentName}</span>
                        <Typography variant="body2" className="spanCustom">
                          #{experimentId}
                        </Typography>
                      </Typography>
                      <Typography sx={{ lineHeight: '1.25rem' }}>
                        Created on <GetDate projectData={projectData} type="creation_date" />
                        <br />
                        Edited on <GetDate projectData={projectData} type="modified_date" />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordT"
                    expanded={isExpanded === 'panel4'}
                    onChange={handleLeftAccordionPanel('panel4')}
                  >
                    <AccordionSummary
                      className="accordTrigger"
                      aria-controls="panel4d-content"
                      id="panel4d-header"
                      classes={{ content: 'accordTgrtxt' }}
                    >
                      <Typography className="typoAccord">
                        <Typography className="typoC-bound">
                          <RenderIcons icon={getIcon(DataSourceIcon, null, 12, 12)} />
                          Datasource
                        </Typography>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      className="datasourceOptions"
                      style={{ overflowY: 'auto', height: '50vh' }}
                    >
                      <Typography className="accordInfo ">
                        <KgDataSourceComp
                          // dataSourceList={dataSourceList}
                          refreshTableConnectionCount={refreshTableConnectionCount}
                          refreshPopupComp={refreshPopupComp}
                          dataSourceSchema={dataSourceSchema}
                          setDataSourceSchema={setDataSourceSchema}
                          selectedSourceAndColumn={selectedSourceAndColumn}
                          setSelectedSourceAndColumn={setSelectedSourceAndColumn}
                          datasetList={datasetList}
                          setDatasetList={setDatasetList}
                          editor={cyToState}
                          fieldsnames={fieldsnames}
                          setFieldsnames={setFieldsnames}
                          setRefreshPopupComp={setRefreshPopupComp}
                          handleChange={setIsExpanded}
                          measuredRefRoot={drawFlowCanvas}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={
                      Object.keys(mappedFieldList) < 1
                        ? { pointerEvents: 'none', opacity: 0.5 }
                        : {}
                    }
                    className="accordT"
                    expanded={isExpanded === 'panel5'}
                    onChange={handleLeftAccordionPanel('panel5')}
                  >
                    <AccordionSummary
                      className="accordTrigger"
                      aria-controls="panel5d-content"
                      id="panel5d-header"
                      classes={{ content: 'accordTgrtxt' }}
                    >
                      <Typography className="typoAccord">
                        <Typography className="typoC-bound">
                          <RenderIcons icon={getIcon(ModelIcon, null, 12, 12)} />
                          Model
                        </Typography>
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails style={{ overflowY: 'auto', height: '50vh' }}>
                      <Typography className="accordInfo kGraphExportPge">
                        <KnowledgeGraphModelComp
                          setRecallEverything={setRecallEverything}
                          cyToState={cyToState}
                          projectName={experimentName}
                          compTyp="create"
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className="accordT"
                    expanded={isExpanded === 'panel2'}
                    onChange={handleLeftAccordionPanel('panel2')}
                  >
                    <AccordionSummary
                      className="accordTrigger"
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                      classes={{ content: 'accordTgrtxt' }}
                    >
                      <Typography className="typoAccord">
                        <Typography className="typoC-bound">
                          <RenderIcons
                            className="typoBadge"
                            icon={getIcon(groupChainIcon, null, 13, 13)}
                          />
                          Nodes
                        </Typography>
                        {cyToState !== null ? (
                          <Badge badgeContent={cyToState.nodes().length} color="primary" />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                  <Accordion
                    className="accordT"
                    expanded={isExpanded === 'panel3'}
                    onChange={handleLeftAccordionPanel('panel3')}
                  >
                    <AccordionSummary
                      className="accordTrigger"
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                      classes={{ content: 'accordTgrtxt' }}
                    >
                      <Typography className="typoAccord">
                        <Typography className="typoC-bound">
                          <RenderIcons icon={getIcon(JourneyIcon, null, 12, 12)} />
                          Edges
                        </Typography>
                        {cyToState !== null ? (
                          <Badge
                            className="typoBadge"
                            badgeContent={cyToState.edges().length}
                            color="primary"
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                </Box>
              </>
            ) : slectedOption === 'node' || slectedOption === 'edge' ? (
              <>
                {/* Info of node */}
                <NodeInfo
                  // key to re-render nodeInfo on nodes tap
                  key={cySelectedElement.id}
                  // ----------------------------------

                  nodeSelect={nodeSelect}
                  navigate={navigate}
                  responseColoumData={responseColoumData}
                  cySelectedElement={cySelectedElement}
                  cyToState={cyToState}
                  randomIntFromInterval={randomIntFromInterval}
                  datasetList={datasetList}
                  experimentName={experimentName}
                  experimentId={experimentId}
                  selectedSourceAndColumn={selectedSourceAndColumn}
                  fieldsnames={fieldsnames}
                  setColroCodes={setColroCodes}
                  colorCodes={colorCodes}
                  setRefreshKGComp={setRefreshKGComp}
                  setStyling={setStyling}
                  styling={styling}
                  slectedOption={slectedOption}
                />
              </>
            ) : (
              ''
            )}
          </Box>
          <Box>
            <Dialog
              open={openVisualization}
              onClose={(e) => {
                CancelHandler(e);
              }}
            >
              {/* // onClose={(e) => handleCloseVisualization()} */}
              <DialogTitle>Creating New Project</DialogTitle>
              <DialogContent>
                <DialogContentText>Enter Project Name</DialogContentText>
                <TextField
                  inputRef={newProjectNameRef}
                  autoFocus
                  margin="dense"
                  id="name"
                  type="name"
                  fullWidth
                  variant="standard"
                  error={Boolean(helperTxtErr)}
                  helperText={helperTxtErr}
                  FormHelperTextProps={{
                    style: { color: 'red' } // Set the error color
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (
                        !saveProjectNameLoader &&
                        newProjectNameRef.current &&
                        newProjectNameRef.current.value.length > 0
                      ) {
                        setSaveProjectNameLoader(true);
                        setTimeout(() => {
                          if (
                            knowledgeGraphStoreItem?.allSelectedData?.projectListCount?.some(
                              (project) => project?.project_name === newProjectNameRef.current.value
                            )
                          ) {
                            setHelperTxtErr('Project name already exists.');
                            setSaveProjectNameLoader(false);
                          } else {
                            setHelperTxtErr('');
                            CreateVisualization(e, newProjectNameRef.current.value);
                          }
                        }, 500);
                      }
                    }
                  }}
                  onChange={(e) => {
                    clearTimeout(projectNameTimeoutID);
                    projectNameTimeoutID = setTimeout(() => {
                      setVisualizationName(e.target.value);
                    }, 300);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => CancelHandler(e)} variant="contained" color="info">
                  Cancel
                </Button>

                <Button
                  sx={
                    saveProjectNameLoader ||
                    !(newProjectNameRef.current && newProjectNameRef.current.value.length > 0)
                      ? { opacity: 0.5, pointerEvents: 'none' }
                      : {}
                  }
                  startIcon={
                    saveProjectNameLoader ? (
                      <CircularProgress size={20} sx={{ color: 'black' }} />
                    ) : (
                      <Save />
                    )
                  }
                  variant="contained"
                  color="success"
                  onClick={(e) => {
                    setSaveProjectNameLoader(true);
                    clearTimeout(saveProjectNameInterval);
                    saveProjectNameInterval = setTimeout(() => {
                      //  CreateVisualization(e, newProjectNameRef.current.value);
                      if (
                        knowledgeGraphStoreItem?.allSelectedData?.projectListCount?.some(
                          (project) => project?.project_name === newProjectNameRef.current.value
                        )
                      ) {
                        setHelperTxtErr('Project name already exists.');
                        setSaveProjectNameLoader(false);
                      } else {
                        setHelperTxtErr('');
                        CreateVisualization(e, newProjectNameRef.current.value);
                      }
                    }, 400);
                  }}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <Box
            onDrop={(e) => drop(e, cyToState)}
            onDragOver={(e) => e.preventDefault()}
            position="relative"
          >
            {/* {slectedOption !== '' ? (
              <Box
                width="100%"
                height="100%"
                style={{ background: 'rgba(0,0,0,0)', zIndex: 1 }}
                position="absolute"
                onClick={() => {
                  setSlectedOption('');
                  collapsedGraphDataToPrev(cyToState);
                }}
              />
            ) : (
              ''
            )} */}
            {exitsTableList &&
              // exitsTableList.length < 1 &&
              isExpanded === 'panel4' && (
                <Box className="nodatasourceModelAdded">
                  <Box
                    sx={
                      currentActiveStep === 2 || exitsTableList.length > 0
                        ? { zIndex: -1 }
                        : { zIndex: 3 }
                    }
                    className="contentHolder"
                  >
                    <Typography className="grpahTitle" variant="h5">
                      {exitsTableList.length > 0
                        ? 'Add another source to create connection'
                        : 'Add new source to graph'}
                    </Typography>
                    <Button
                      className="dialogIconBtn"
                      variant="outlined"
                      endIcon={<Add />}
                      color="primary"
                      onClick={() => {
                        panelLeftSideBar('hideBehind');
                        setIsExpanded('panel4');
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              )}
            {allModelsForProjects.length > 0 && currentSelectedmodelId ? (
              <CytoscapeComponent
                key={modelId}
                className="cytographs counter_"
                elements={cytoscapeData}
                style={{ width: '100%', height: '96vh', display: noModal ? 'block' : 'none' }}
                stylesheet={[...styling, ...edgeStylesheet]}
                wheelSensitivity={0.05}
                zoom={1}
                layout={{ name: 'cose' }}
                cy={(cy) => setCyToState(cy)}
              />
            ) : (
              ''
            )}
            {/* Message box to show condition and changes in dom for temp */}
            {/* <Box className="temMsgBox">
            <Typography>
              allModelsForProjects.length{' '}
              {allModelsForProjects ? JSON.stringify(allModelsForProjects.length) : ''}
            </Typography>
            <Typography>
              exitsTableList.length {exitsTableList ? JSON.stringify(exitsTableList.length) : ''}
            </Typography>
            <Typography>currentSelectedmodelId {currentSelectedmodelId}</Typography>
          </Box> */}
            {exitsTableList &&
            exitsTableList.length > 0 &&
            currentSelectedmodelId === null &&
            isExpanded === 'panel5' ? (
              <Box className="nodatasourceModelAdded">
                <Box className="contentHolder">
                  <Typography className="grpahTitle" variant="h5">
                    {allModelsForProjects.length > 0
                      ? 'Create / Select model'
                      : 'Add new model to graph'}
                  </Typography>
                  <Button
                    className="dialogIconBtn"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      panelLeftSideBar('hideBehind');
                      setIsExpanded('panel5');
                    }}
                  >
                    {allModelsForProjects.length > 0 ? (
                      'Select'
                    ) : (
                      <>
                        <RenderIcons className="dialogIcon" icon={getIcon(AddIcon, null, 25, 25)} />{' '}
                        Add
                      </>
                    )}
                  </Button>
                </Box>
              </Box>
            ) : (
              ''
            )}
            {cyToState &&
              cyToState.elements().length === 0 &&
              isExpanded === 'panel5' &&
              currentSelectedmodelId !== null && (
                <Box className="nodatasourceModelAdded">
                  <Box className="contentHolder">
                    <Typography className="grpahTitle" variant="h5">
                      Add first node
                    </Typography>
                    <Button
                      className="dialogIconBtn"
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        drop(e, cyToState);
                      }}
                    >
                      <RenderIcons className="dialogIcon" icon={getIcon(AddIcon, null, 25, 25)} />
                      Add
                    </Button>
                  </Box>
                </Box>
              )}
            {currentActiveStep >= 0 && !noModal ? (
              <Box
                sx={{
                  display: isExpanded === 'panel4' ? 'block' : 'none',
                  overflow: 'hidden',
                  zIndex: 2
                }}
                border={0}
                borderColor="#ddd"
                borderRadius={1}
                className="mappedSource"
              >
                <div
                  style={{
                    display: isExpanded === 'panel4' ? 'block' : 'none',
                    background: 'white'
                  }}
                  ref={measuredRef}
                  id="drawflow"
                  className="mapDrawFlow"
                  onDragOver={(e) => e.preventDefault()}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
          <Box className="MuiTopContainer">
            <Stack direction="row" spacing={1}>
              <Item onClick={(e) => navigate('/knowledgegraph/')}>
                <RenderIcons
                  icon={getIcon(HomeIcon, null, 30, 30)}
                  style={{
                    backgroundColor: '#f4f8fd',
                    border: '1px solid',
                    borderRadius: '50%',
                    padding: '7px 0px 3px 10px'
                    // padding: '7px 3px 7px 12px'
                  }}
                />
              </Item>
            </Stack>
          </Box>
        </Container>
        <Dialog
          className="nodeDialog"
          onClose={(e) => setOpenDialog(false)}
          open={openDialog}
          maxWidth="md"
          fullWidth
        >
          <Box sx={{ textAlign: 'center', borderBottom: 1, borderColor: 'divider' }}>
            <DialogContent>Create new node</DialogContent>
          </Box>
          <Grid
            container
            spacing={2}
            className="nodeDialogProp"
            style={{ marginTop: 0, marginLeft: 0, width: '98%' }}
          >
            <Grid item xs={3}>
              <Box sx={{ mb: 0, p: 0 }}>
                <Box>
                  <FormControl fullWidth className="form-row size-col-2">
                    <Typography className="labelHeading col-2">Dataset</Typography>
                    <Select
                      className="col-2 form-control selectControl"
                      labelId=""
                      id=""
                      value={datasetDialog}
                      // variant="standard"
                      onChange={(e) => {
                        setDatasetDialog(e.target.value);
                        getpropertiesList(e.target.value);
                      }}
                    >
                      <MenuItem value=" ">Select</MenuItem>
                      {datasetList.length > 0 &&
                        datasetList.map((item, index) => {
                          console.log('itemitem', item);
                          return (
                            <MenuItem value={item.table} key={index}>
                              {item.table}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="form-row size-col-2 propertyNode">
                <Typography className="labelHeading col-2">Properties</Typography>
                <Autocomplete
                  multiple
                  className="col-2 form-control"
                  id=""
                  onChange={(e, newValue) => {
                    setGetPropertiesLabel(newValue);
                  }}
                  disableCloseOnSelect
                  options={nodeProperties.map((option) => option)}
                  getOptionLabel={(option) => option.field_name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        size="small"
                        // icon={getIcon(CheckBoxOutlineBlank, null, 15, 15)}
                        // checkedIcon={getIcon(CheckBoxIcon, null, 15, 15)}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />

                      {option.field_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="standard"
                      InputProps={{ ...params.InputProps, disableUnderline: true }}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <FormControl fullWidth className="form-row size-col-2">
                  <Typography className="labelHeading col-2">Source Node</Typography>
                  <Select
                    className="col-2 form-control selectControl"
                    labelId=""
                    id="setSourceNode"
                    value={sourceNode}
                    // variant="standard"
                    onChange={(e) => {
                      setSourceNode(e.target.value);
                    }}
                  >
                    <MenuItem value=" ">Select</MenuItem>
                    {getpropertiesLabel.map((item, index) => (
                      <MenuItem className="" value={item.column} key={index}>
                        {item.column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Box mt={1} ml={2} mr={2} sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              className="dialogIconBtn"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                console.log('');
                const obj = {
                  group: 'nodes',
                  data: {
                    id: cyToState.nodes().length + 1,
                    label: sourceNode,
                    name: 'abc',
                    targeted: [],
                    dataset: datasetList,
                    properties: [],
                    relationship: relationshipData,
                    datasetValue: ' ',
                    propertiesValue: [],
                    position: {
                      x: randomIntFromInterval(300, 1000),
                      y: randomIntFromInterval(150, 800)
                    }
                  },
                  position: {
                    x: randomIntFromInterval(300, 1000),
                    y: randomIntFromInterval(150, 800)
                  }
                };
                const prevNodes = nodeAdded;
                prevNodes.push(obj);
                setNodeAdded(prevNodes);
                setRefreshPopupComp(new Date());
              }}
            >
              <RenderIcons className="dialogIcon" icon={getIcon(AddIcon, null, 25, 25)} />
            </Button>
          </Box>
          <Box m={2} mt={1}>
            <Box
              className="nodeLabelBadge"
              sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 0 }}
            >
              {nodeAdded.map((item, index) => {
                const label = item.data.label;
                return <Typography className="nodeLabelInfo">{label}</Typography>;
              })}
            </Box>
            <Box
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: 'divider',
                justifyContent: 'flex-end',
                p: 0,
                m: 0,
                mt: 1,
                pb: 2,
                py: 1
              }}
            >
              <DialogActions>
                <Box>
                  <Button onClick={(e) => setOpenDialog(false)} variant="outlined" color="primary">
                    Close
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      cyToState.add(nodeAdded);
                      setOpenDialog(false);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </Box>
        </Dialog>
        {/* for edge dialog box */}
        <Dialog onClose={(e) => setEdgeOpen(false)} open={edgeOpen} maxWidth="xs" fullWidth>
          <Box sx={{ textAlign: 'center', borderBottom: 1, borderColor: 'divider' }}>
            <DialogContent>Create new node </DialogContent>
          </Box>
          <Box m={2}>
            <Box sx={{ mb: 0, p: 0 }}>
              <Box>
                <FormControl fullWidth className="form-row size-col-2">
                  <Typography className="labelHeading col-2">Source</Typography>
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={getAllNodes(cyToState)}
                    // disableCloseOnSelect
                    onChange={(e, newValue) => {
                      console.log('newValuenewValue', newValue);
                      setSourceLabelId(newValue[0].data().id);
                      console.log(newValue[0].data().id);
                      setRefreshKGComp(new Date());
                    }}
                    getOptionLabel={(option) =>
                      option.data().label !== ' ' ? option.data().label : option.data().id
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search..." variant="standard" />
                    )}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth className="form-row size-col-2">
                  <Typography className="labelHeading col-2">Target</Typography>
                  {Array.isArray(getAllNodesTarget(cyToState)) &&
                  getAllNodesTarget(cyToState).length > 0 ? (
                    <Autocomplete
                      id="checkboxes-tags-demo"
                      options={getAllNodesTarget(cyToState)}
                      // disableCloseOnSelect
                      onChange={(e, newValue) => {
                        setTargetLabelId(newValue[0].data().id);
                        setRefreshKGComp(new Date());
                      }}
                      getOptionLabel={(option) =>
                        option.data().label !== ' ' ? option.data().label : option.data().id
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Search..." variant="standard" />
                      )}
                    />
                  ) : (
                    ''
                  )}
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: 'divider',
                justifyContent: 'flex-end',
                p: 0,
                m: 0,
                mt: 2,
                pb: 2,
                py: 1
              }}
            >
              <DialogActions>
                <Box>
                  <Button onClick={(e) => setEdgeOpen(false)} variant="outlined" color="primary">
                    Close
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      console.log(cyToState.nodes().data());
                      cyToState.add({
                        data: { source: sourceLabelId, target: targetLabelId }
                      });
                      // const prevTargetedIds = cyToState.$('#' + nodeId).data().targeted;
                      // prevTargetedIds.push(targetId);
                      // const sourceData = {
                      //   targeted: prevTargetedIds
                      // };
                      // cyToState.$('#' + nodeId).data(sourceData); // pushing all connected targeted node id to the source node
                      const prevTargetdIds = cyToState.$('#' + sourceLabelId).data().targeted;
                      prevTargetdIds.push(targetLabelId);
                      const sourceData = {
                        targeted: prevTargetdIds
                      };
                      cyToState.$('#', sourceLabelId).data(sourceData); // pushing all connected targeted node id to the source node
                      setEdgeOpen(false);
                      console.log('outlined');
                    }}
                  >
                    Add &nbsp;
                    <RenderIcons icon={getIcon(AddIcon, null, 25, 25)} />
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </Box>
        </Dialog>
      </Page>
      {cyToState && cyToState.elements().length > 0 ? (
        <KnowledgeGraphEditOptionsPlate cyToState={cyToState} />
      ) : (
        ''
      )}
    </>
  );
}

export default CreateKnowledgeGraph;

// function RuleEngine() {
//   const [age, setAge] = React.useState('');
//   const [arr, setArray] = useState([]);
//   const [num, setNum] = useState(0);
//   const [mutate, setMutate] = useState();
//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };
//   const AddRuleHandler = (e) => {
//     const ruleArray = arr;
//     const num1 = num;
//     setNum(num1 + 1);
//     ruleArray.push(num);
//     // ruleArray.forEach((item) => {
//     //   num += 1;
//     // });
//     setArray(ruleArray);
//     setMutate(new Date());
//   };
//   console.log('ruleArray1:', arr);
//   return (
//     <>
//       <Box className="ruleEngine_HeaderBox">
//         <Box className="ruleEngine_HeaderBox_Main">
//           <FormControl sx={{ m: 1, minWidth: 50 }}>
//             <Select
//               value={age}
//               onChange={handleChange}
//               displayEmpty
//               inputProps={{ 'aria-label': 'Without label' }}
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               <MenuItem value="and">And</MenuItem>
//               <MenuItem value="or">Or</MenuItem>
//             </Select>
//           </FormControl>
//           <Button small variant="outlined" onClick={(e) => AddRuleHandler(e)}>
//             <Typography className="typo">
//               <span className="spanIcon">+</span>
//               <span className="spantext">RULE</span>
//             </Typography>
//           </Button>
//           <Button small variant="outlined">
//             <Typography className="typo">
//               <span className="spanIcon">+</span>
//               <span className="spantext">GROUP</span>
//             </Typography>
//           </Button>
//         </Box>
//         <Box className="ruleEngine_HeaderBox_Main">
//           <FormControl sx={{ m: 1, minWidth: 50 }}>
//             <Select
//               value={age}
//               onChange={(e) => handleChange(e)}
//               displayEmpty
//               inputProps={{ 'aria-label': 'Without label' }}
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               <MenuItem value="and">And</MenuItem>
//               <MenuItem value="or">Or</MenuItem>
//             </Select>
//           </FormControl>
//           <Button small variant="outlined" onClick={(e) => AddRuleHandler(e)}>
//             <Typography className="typo">
//               <span className="spanIcon">+</span>
//               <span className="spantext">RULE</span>
//             </Typography>
//           </Button>
//           <Button small variant="outlined">
//             <Typography className="typo">
//               <span className="spanIcon">+</span>
//               <span className="spantext">GROUP</span>
//             </Typography>
//           </Button>
//         </Box>
//       </Box>
//       <Box className="ruleEngine_FooterBox">
//         <Button small variant="outlined">
//           Close
//         </Button>
//         <Button small variant="outlined">
//           Ok
//         </Button>
//         <Button small variant="outlined">
//           Filter
//         </Button>
//       </Box>
//     </>
//   );
// }

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getBase64(file, setState) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    console.log(reader.result);
    setState(reader.result);
    // base64 = reader.result;
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

function getAllNodes(cyToState) {
  if (cyToState !== null) {
    const allNodes = cyToState.nodes();
    console.log('allNodes', cyToState.nodes());
    console.log('allNodes', cyToState.nodes().toArray());
    return allNodes;
  } else {
    return [];
  }
}

function getAllNodesTarget(cyToState) {
  if (cyToState !== null) {
    const allNodes = cyToState.nodes();
    console.log('allNodes', allNodes);
    return allNodes;
  } else {
    return [];
  }
}

function getLeftHolderHtml(dataSourceListItems, tableName) {
  console.log('dataSourceListItems', tableName && tableName.length);
  return `<div class="rightHolderBox mainHolderBox">
  <div class="holderTitle">${tableName}</div>
  <div class="holderBox">${ReactDOMServer.renderToStaticMarkup(
    getLoopData(dataSourceListItems)
  )}</div>
  </div>`;
}

const getShorterName = (tableName) => {
  const reqlen = tableName.length - 15;
  console.log('reqlen', reqlen);
  console.log('reqlen', tableName.length - reqlen);
  const str = tableName.substr(0, tableName.length - reqlen);
  return str + '...';
};

function getRightHolderHtml(dataForMaspping, tableName) {
  return `<div class="rightHolderBox mainHolderBox">
  <div class="holderTitle">Title 2</div>
  <div class="holderBox">Content 2</div>
  </div>`;
}

function getLoopData(data) {
  return (
    data &&
    data.length > 0 &&
    data.map((tables, tableIndex) => {
      console.log('tablestables', tables);
      return (
        <Box className="columnForMapped">
          {tables.column_name.length > 17 ? getShorterName(tables.column_name) : tables.column_name}
        </Box>
      );
    })
  );
}

function getLoopData2(data) {
  return (
    data &&
    data.length > 0 &&
    data.map((tables, tableIndex) => {
      console.log('tablestables', tables);
      return <Box className="columnForMapped">{tables.app_column_name}</Box>;
    })
  );
}

function getOutputDots(data) {
  console.log('data143', data);
  // console.log('data143', data.output);
  const obj = {};
  if (data) {
    if (data.length > 0) {
      data.forEach((item, index) => {
        obj[`output_${index + 1}`] = { connections: item.output ? item.output : [], label: item };
      });
    }
  }
  return obj;
}

function getInputDots(data) {
  const obj = {};
  if (data) {
    if (data.length > 0) {
      data.forEach((item, index) => {
        obj[`input_${index + 1}`] = { connections: [], label: item };
      });
    }
  }
  return obj;
}

function createNodesAndEdges(graphadata) {
  console.log('grapharraygrapharray', graphadata);
  const newGraphaData = [];
  graphadata.forEach((item) => {
    // if (item.source && item.target) {
    //   const obj = { data: item, position: item.position };
    // } else {

    // }
    const obj = { data: item, position: item.position };
    newGraphaData.push(obj);
  });
  return newGraphaData;
  // { data: { id: 'one', label: 'Node 1' }, position: { x: 0, y: 0 } },
  // { data: { id: 'two', label: 'Node 2' }, position: { x: 100, y: 0 } },
  // { data: { source: 'one', target: 'two', label: 'Edge from Node1 to Node2' } }
}
const newValue = 230;
function getPosX(filedIndex, mappedFieldList) {
  const totalWidth = window.innerWidth;
  if (filedIndex < 3 && filedIndex > -1) {
    return Number(totalWidth) - Number(newValue * (filedIndex + 1));
  }
  if (filedIndex < 7 && filedIndex > 2) {
    return Number(totalWidth) - Number(newValue * (filedIndex - 2 + 1));
  }
}
function getPosY(filedIndex, mappedFieldList) {
  if (filedIndex < 3 && filedIndex > -1) {
    return 30;
  }
  if (filedIndex > 2 && filedIndex < 6) {
    return Math.random() * (1200 - 360) + 360;
  }
}

function sortedArray(obj) {
  const sortArray = Object.entries(obj)
    .map(([key, value]) => ({ key, value }))
    .sort((a, b) => a.value.sorting - b.value.sorting);
  console.log('sortedObject', sortArray);
  sortArray.forEach((item, index) => {
    console.log('sortedObject', item.value, index);
    item.value.posX = getPosX(index, item);
    item.value.posY = getPosY(index, item);
  });

  console.log('sortedObject', sortArray);
  // Convert sorted array back to object
  const sortedObject = sortArray.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
  console.log('sortedObject', sortedObject);
  return sortedObject;
}

function GetDate({ projectData, type }) {
  const [newDate, setNewDate] = useState('N/A');

  useEffect(() => {
    let dateVal = 'N/A';

    if (Object.keys(projectData).length > 0) {
      switch (type) {
        case 'creation_date':
          if (projectData.creation_date) {
            dateVal = fDateTime(projectData.creation_date);
          }
          break;
        case 'modified_date':
          if (projectData.modified_date) {
            dateVal = fDateTime(projectData.modified_date);
          }
          break;
        default:
          break;
      }
    }

    setNewDate(dateVal);
  }, [projectData]);

  return newDate;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));
