import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import { userDataFromLocal } from '../../utils/getUserDetails';

// ----------------------------------------------------------------------
/**
 * Design of the page
 * @class
 * @param {object} prop - Input object
 * @param {React.ReactElement} prop.children - ReactElement
 * @param {string} prop.title - Title of the page
 * @return {React.ReactElement} - React component with title of the page
 */
const Page = forwardRef(({ children, rfreshPageComponent, title = '', ...other }, ref) => {
  const [refreshComp, setRefreshComp] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserDetailsLocalStorage = userDataFromLocal();
    if (userDataFromLocal() === 'null' || userDataFromLocal() === null) {
      // fetchUserDetailsLocalStorage = '{"isLogedIn":false}';
      // console.log('fetchUserDetailsLocalStorage', fetchUserDetailsLocalStorage);
      navigate('/login');
    }
    if (!userDataFromLocal().isLogedIn) {
      navigate('/login');
    }
    // setTimeout(() => {
    //   const PADDING = 0.2; // 20% padding on all sides
    //   const svgElements = document.querySelectorAll('svg:not(.drawflow svg)');
    //   svgElements.forEach((svg) => {
    //     const bbox = svg.getBBox();
    //     const padX = PADDING * bbox.width;
    //     const padY = PADDING * bbox.height;
    //     const viewbox = [
    //       bbox.x - padX,
    //       bbox.y - padY,
    //       bbox.width + 2 * padX,
    //       bbox.height + 2 * padY
    //     ].join(' ');
    //     svg.setAttribute('viewBox', viewbox);
    //     if (refreshComp === null) {
    //       setRefreshComp(new Date());
    //     }
    //   });
    // }, 1000);
  }, [refreshComp, rfreshPageComponent]);
  return (
    <Box ref={ref} {...other} className="pageHolder">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
