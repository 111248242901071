import React, { useState, useEffect } from 'react';
import {
  Box,
  ButtonGroup,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  Checkbox,
  Slider,
  InputAdornment
} from '@mui/material';
import { ExpandMore, IndeterminateCheckBoxSharp } from '@mui/icons-material';
import './BottomTabsStyle.css';

// State management
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { getIcon, UploadIcon, ColorPanelIcon } from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
import NodeRuleEngine from './NodeRuleEngine';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import LoaderBlur from './LoaderBlur';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';

function NodeStyleSetting(props) {
  const {
    currentMetaDataId,
    cyToState,
    labelToDisplay,
    setLabelToDisplay,
    expandedPanel,
    handleExpanedPanel,
    nodeSavedRulesNameList,
    setNodeSavedRulesNameList,
    setMutateSavedRuleList,
    mutateSavedRuleList
  } = props;

  let _debounceTimer;

  const _debounceSetState = (newValue, setValue, delay) => {
    clearTimeout(_debounceTimer);
    _debounceTimer = setTimeout(() => {
      setValue(newValue);
    }, delay);
  };

  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const dispatch = useDispatch();
  // console.log('knowledgeGraphStoreItem', knowledgeGraphStoreItem);
  const {
    allSelectedData: { refreshComponent, currentActiveStep },
    node_edge_styling: { vizNodeStyles },
    createKnowledgeGraph: { styling }
  } = knowledgeGraphStoreItem;
  const [selectedNode, setSelectedNode] = useState('#');
  const [openRuleModel, setOpenRuleModel] = useState(false);
  const [toggleSaveModal, setToggleSaveModal] = useState(false);
  const [patchToggleSaveModal, setPatchToggleSaveModal] = useState(false);

  // const [labelToDisplay, setLabelToDisplay] = useState([]);
  const { experimentId, modelId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { GetApiByUrl, DeleteApiByUrl } = KnowledgeGraphService;

  const [nodeProperty, setnodeProperty] = useState('');
  const [nodeValues, setNodeValues] = useState([]);
  const [refreshStyleComp, setRefreshStyleComp] = useState(null);
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [radioValue, setRadioValue] = useState('Image');
  const [savedRulesNameList, setSavedRulesNameList] = useState([]);
  const [savedRuleSelection, setSavedRuleSelection] = useState([]);
  const [savedNodeRuleData, setSavedNodeRuleData] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [apiLoader, setApiLoader] = useState(false);

  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };

  // onchange for changing shape
  // console.log('refreshStyleComp', refreshStyleComp);

  // const [apiNodesStyling, setApiNodesStyling] = useState([{}]);

  // const getNodeStyleData = async () => {
  //   try {
  //     const nodeInitialStyling = await GetApiByUrl(
  //       `/visual/manage_metadata?link_id=${modelId}&project_id=${experimentId}`
  //     );

  //     if (nodeInitialStyling.status === 'success' && nodeInitialStyling.code === 200) {
  //       const metadataArray = nodeInitialStyling.data.metadata;
  //       if (metadataArray.length > 0) {
  //         const firstMetadata = metadataArray[0];
  //         if (
  //           firstMetadata.others &&
  //           firstMetadata.others.stylying &&
  //           firstMetadata.others.stylying.length > 0
  //         ) {
  //           const styleData = firstMetadata.others.stylying;
  //           setApiNodesStyling(styleData);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     // Handle API fetch errors here
  //     console.error('Error fetching node style data:', error);
  //   }
  // };

  // useEffect(() => {
  //   getNodeStyleData();
  // }, [modelId, experimentId]);

  const initialNodeStyles = {
    'background-color': '',
    label: 'data(label)',
    height: '',
    width: '',
    padding: '',
    'background-fit': '',
    'background-opacity': '',
    'border-width': '',
    'border-style': '',
    'border-color': '',
    'border-opacity': '',
    shape: '',
    'background-repeat': '',
    'background-image': '',
    'background-image-opacity': '',
    'font-size': '',
    // 'text-margin-y': '-7px',
    'text-valign': '',
    'text-halign': '',
    color: '',
    'text-opacity': ''
  };

  // const [allLabelSettings, setAllLabelSettings] = useState({});
  // const [previousNode, setPreviousNode] = useState(null);

  //  const handleLabelStyle = () => {
  //   if (selectedNode !== 'all') {
  //     const filteredStyles = apiNodesStyling?.find(
  //       (item) => item && item.selector && item.selector.includes(`'${selectedNode}'`)
  //     );

  //     if (filteredStyles) {
  //       const apiNodeStyles = filteredStyles.style;
  //       setAllNodesSettings((prevSettings) => ({
  //         ...prevSettings,
  //         [selectedNode]: { ...apiNodeStyles }
  //       }));
  //     }
  //   } else {
  //     setAllNodesSettings(initialNodeStyles);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedNode !== previousNode) {
  //     handleLabelStyle();
  //     setPreviousNode(selectedNode);
  //   }
  // }, [selectedNode, previousNode]);

  const [allNodesSettings, setAllNodesSettings] = useState(initialNodeStyles);

  const groupBtnStyle = {
    flex: 1,
    fontSize: '0.750rem',
    paddingRight: '9px',
    paddingLeft: '9px',
    background: '#f5f8fc',
    border: '1px solid #ddd',
    color: 'black',
    '&:hover': { color: 'white' }
  };

  const groupBtnStyleBdr = { borderColor: '#C4CDD5' };

  // const generateSliderKey = (label, styleProperty) => {
  //   // Combine label and styleProperty to create a unique key
  //   return `${label}-${styleProperty}`;
  // };

  // const getDynamicStyleValue = (styleProperty) => {
  //   if (selectedNode !== 'all') {
  //     const getStylesFromApi = apiNodesStyling?.find(
  //       (item) => item && item.selector && item.selector.includes(`'${selectedNode}'`)
  //     );

  //     if (getStylesFromApi) {
  //       const apiEdgeStyles = getStylesFromApi.style;
  //       return apiEdgeStyles ? apiEdgeStyles[styleProperty] : null;
  //     }
  //   }
  //   return null;
  // };

  let debounceTimer;

  // Generalized Debounce function for style properties
  const debounce = (func, delay, styleProperty) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func(styleProperty);
    }, delay);
  };

  const handleColorValueChange = (event, styleProperty) => {
    const newValue = event.target.value; // Extract the new value from the event
    // Call the debounce function when the slider value changes
    debounce(
      (property) => {
        dispatch({
          type: 'STYLING_DATA_UPDATE',
          payload: {
            key: 'vizNodeStyles',
            value: {
              [selectedNode]: {
                ...vizNodeStyles[selectedNode],
                [property]: newValue
              }
            }
          }
        });
        // setAllNodesSettings((prevSettings) => ({
        //   ...prevSettings,
        //   [property]: newValue
        // }));
        setRefreshStyleComp(new Date());
      },
      200,
      styleProperty
    ); // Adjust the debounce delay time as needed (e.g., 300 milliseconds)
  };

  const handleStyleValueChange = (event, styleProperty) => {
    const newValue = event.target.value; // Extract the new value from the event
    dispatch({
      type: 'STYLING_DATA_UPDATE',
      payload: {
        key: 'vizNodeStyles',
        value: {
          [selectedNode]: {
            ...vizNodeStyles[selectedNode],
            [styleProperty]: newValue
          }
        }
      }
    });
    // setAllNodesSettings((prevSettings) => ({
    //   ...prevSettings,
    //   [styleProperty]: newValue
    // }));
    setRefreshStyleComp(new Date());
  };

  // const handleOpacityChange = (newValue) => {
  //   setAllNodesSettings((prevSettings) => ({
  //     ...prevSettings,
  //     'background-opacity': newValue
  //   }));
  //   //   setRefreshStyleComp(new Date());
  // };

  const selectChange = (event) => {
    setnodeProperty(event.target.value);
  };
  const uploadFileClick = (e) => {
    const browseField = document.getElementById('contained-button-file');
    browseField.click();
  };
  const handleImageDialogOpen = () => {
    setOpenImageUpload(true);
  };
  const handleImageDialogClose = () => {
    setOpenImageUpload(false);
  };

  const [savedRuleId, setSavedRuleId] = useState(null);
  const [selectedRules, setSelectedRules] = useState([]);

  const handleSavedRuleSelection = (_, value) => {
    setSelectedRules(value);

    // console.log(value, 'valuevalue');
  };

  useEffect(() => {
    setSelectedRules([]);
  }, [mutateSavedRuleList]);

  const handleApplyRules = () => {
    if (selectedRules.length > 0) {
      setApiLoader(true);
      (async () => {
        const savedRuleId = selectedRules[0].id;

        const getApiData = await GetApiByUrl('visual/apply_rule?rule_id=' + savedRuleId);
        if (getApiData.status === 'success' && getApiData.code === 200) {
          if (getApiData.data.length > 0) {
            const filtredIds = [];
            getApiData.data.forEach((item) => filtredIds.push(String(item.properties.node_id)));

            const uniqueFiltredIds = [...new Set(filtredIds)];

            const _styleNodes = cyToState
              .nodes()
              .filter((elm) => uniqueFiltredIds.includes(String(elm.data().props.node_id)));

            // Model Specific Meta API
            const metaResponse = await GetApiByUrl(
              `visual/manage_metadata?link_id=${modelId}&project_id=${experimentId}`
            );

            // Project Specific Meta API
            // const metaResponse = await GetApiByUrl(
            //   `/visual/manage_metadata?project_id=${experimentId}`
            // );
            if (
              _styleNodes.length > 0 &&
              metaResponse.status === 'success' &&
              metaResponse.code === 200 &&
              // --------------- Model Specific ---------------------------
              metaResponse.data.metadata.length > 0 &&
              Object.keys(metaResponse.data.metadata[0].node_rules).length > 0
            ) {
              const { node_rules } = metaResponse.data.metadata[0];
              if (Object.keys(node_rules).length > 0 && node_rules[savedRuleId]) {
                _styleNodes.style({
                  'background-color': node_rules[savedRuleId]['background-color'] ?? 'red',
                  label: node_rules[savedRuleId]?.label,
                  height: node_rules[savedRuleId]?.height,
                  width: node_rules[savedRuleId]?.width,
                  padding: node_rules[savedRuleId]?.padding,
                  'background-fit': node_rules[savedRuleId]['background-fit'],
                  'background-opacity': node_rules[savedRuleId]['background-opacity'],
                  'border-width': node_rules[savedRuleId]['border-width'],
                  'border-style': node_rules[savedRuleId]['border-style'],
                  'border-color': node_rules[savedRuleId]['border-color'] ?? '#000',
                  'border-opacity': node_rules[savedRuleId]['border-opacity'],
                  shape: node_rules[savedRuleId]?.shape,
                  'background-repeat': node_rules[savedRuleId]['background-repeat'],
                  'background-image': node_rules[savedRuleId]['background-image'],
                  'background-image-opacity': node_rules[savedRuleId]['background-image-opacity'],
                  'font-size': node_rules[savedRuleId]['font-size'],
                  // 'text-margin-y': '-7px',
                  'text-valign': node_rules[savedRuleId]['text-valign'],
                  'text-halign': node_rules[savedRuleId]['text-halign'],
                  color: node_rules[savedRuleId]?.color,
                  'text-opacity': node_rules[savedRuleId]['text-opacity']
                });
                setApiLoader(false);
                // Success
                enqueueSnackbar('Rule Applied Successfully', {
                  variant: metaResponse.status,
                  autoHideDuration: 5000
                });
              } else {
                // metadata else condition
                setApiLoader(false);
                enqueueSnackbar('Problem in applying rules', {
                  variant: 'error',
                  autoHideDuration: 5000
                });
              }
            } else {
              // metadata else condition
              setApiLoader(false);
              enqueueSnackbar('Problem in applying rules', {
                variant: 'error',
                autoHideDuration: 5000
              });
            }
          } else {
            setApiLoader(false);
            enqueueSnackbar('No data found for this rule', {
              variant: 'warning',
              autoHideDuration: 5000
            });
          }
        } else {
          // First Api Error
          setApiLoader(false);
          enqueueSnackbar('Problem in applying rules', {
            variant: 'error',
            autoHideDuration: 5000
          });
        }
      })();
    } else {
      enqueueSnackbar('Please select rule(s).', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }
  };

  // Delete selected Items from Autocomplete rules

  const handleDeleteNodeRule = () => {
    const authToken = userDataFromLocal();
    const ruleCredential = {
      rule_ids: selectedRules.map((item) => item.id),
      creator_user_id: authToken.userID
    };

    (async () => {
      const deleteRules = await DeleteApiByUrl('/visual/manage_rule_engine', ruleCredential);
      if (selectedRules.length > 0) {
        if (deleteRules?.status === 'success' && deleteRules?.code === 200) {
          setSelectedRules([]);

          setNodeSavedRulesNameList((prevRules) =>
            prevRules.filter((rule) => !ruleCredential.rule_ids.includes(rule.id))
          );

          enqueueSnackbar(deleteRules.message, {
            variant: deleteRules.status,
            autoHideDuration: 3000
          });
        } else {
          enqueueSnackbar('Problem in rule deletion', {
            variant: deleteRules.status,
            autoHideDuration: 3000
          });
        }
      } else {
        enqueueSnackbar('Please select rule(s).', {
          variant: 'warning',
          autoHideDuration: 3000
        });
      }
    })();
  };

  useEffect(() => {
    setNodeValues(styling);
  }, [styling]);

  useEffect(() => {
    // console.log('vizNodeStyles', refreshStyleComp);
    // console.log('vizNodeStyles', vizNodeStyles);
    if (cyToState) {
      labelToDisplay.forEach((item) => {
        if (item.label === selectedNode) {
          item['background-color'] = vizNodeStyles[selectedNode]['background-color'];
        }
      });
      const nodesToStyle = cyToState.nodes(`[label='${selectedNode}']`);
      // selectedNode === 'all' ? cyToState.nodes() : cyToState.nodes(`[label='${selectedNode}']`);
      // console.log('vizNodeStyles[background-image]', nodesToStyle);
      nodesToStyle.forEach((node) => {
        // console.log('vizNodeStyles[background-image]', node.data('id'));
        node.style({
          'background-color': vizNodeStyles[selectedNode]['background-color'] ?? 'red',
          height: vizNodeStyles[selectedNode].height,
          width: vizNodeStyles[selectedNode].width,
          padding: vizNodeStyles[selectedNode].padding,
          'background-fit': vizNodeStyles[selectedNode]['background-fit'],
          'background-opacity': vizNodeStyles[selectedNode]['background-opacity'],
          'border-width': vizNodeStyles[selectedNode]['border-width'],
          'border-style': vizNodeStyles[selectedNode]['border-style'],
          'border-color': vizNodeStyles[selectedNode]['border-color'],
          'border-opacity': vizNodeStyles[selectedNode]['border-opacity'],
          shape: vizNodeStyles[selectedNode].shape,
          'background-repeat': vizNodeStyles[selectedNode]['background-repeat'],
          'background-image': vizNodeStyles[selectedNode]['background-image'],
          'background-image-opacity': vizNodeStyles[selectedNode]['background-image-opacity'],
          'font-size': vizNodeStyles[selectedNode]['font-size'],
          // 'text-margin-y': vizNodeStyles[selectedNode]['text-margin-y'],
          'text-valign': vizNodeStyles[selectedNode]['text-valign'],
          'text-halign': vizNodeStyles[selectedNode]['text-halign'],
          color: vizNodeStyles[selectedNode].color,
          'text-opacity': vizNodeStyles[selectedNode]['text-opacity']
        });
      });
    }
  }, [vizNodeStyles, refreshStyleComp]);

  return nodeValues.length > 0 ? (
    <>
      {apiLoader && <LoaderBlur customMsg="Rules are being applied" />}
      <Box sx={{ pb: 0 }} className="nodeDesign">
        <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
        />
        <Accordion
          className="accordT"
          expanded={expandedPanel === 'panel1'}
          onChange={handleExpanedPanel('panel1')}
        >
          <AccordionSummary
            className="accordTrigger"
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{ content: 'accordTgrtxt' }}
            expandIcon={<ExpandMore />}
          >
            <Typography variant="h5" className="nodeAccordHead">
              Node
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="model-box" m={1}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <Select
                  className="form-control selectControl"
                  value={selectedNode}
                  onChange={(e) => {
                    setSelectedNode(e.target.value);
                    if (e.target.value !== '#') {
                      const selectedStyle = cyToState.nodes(`[label='${e.target.value}']`).style();
                      if (selectedStyle) {
                        const prevObj = {};
                        Object.keys(vizNodeStyles[e.target.value]).map((key) => {
                          if (key.includes('color')) {
                            let red = selectedStyle[key].replace('rgb(', '');
                            red = red.replace(')', '');
                            // console.log('redred', red);
                            prevObj[key] = rgbToHex(red.split(','));
                          } else {
                            // prevObj[key] = selectedStyle[key];
                            prevObj[key] = vizNodeStyles[e.target.value][key];
                          }
                        });

                        // console.log('checkLabel', prevObj);
                        // rgbToHex
                        dispatch({
                          type: 'STYLING_DATA_UPDATE',
                          payload: {
                            key: 'vizNodeStyles',
                            value: {
                              [e.target.value]: prevObj
                            }
                          }
                        });
                      }
                    }
                    // else {
                    //   dispatch({
                    //     type: 'STYLING_DATA_UPDATE',
                    //     payload: {
                    //       key: 'vizNodeStyles',
                    //       value: {
                    //         ['all']: initialNodeStyles
                    //       }
                    //     }
                    //   });
                    // }
                  }}
                  label="nodeProperty"
                >
                  <MenuItem disabled value="#">
                    Select Node
                  </MenuItem>
                  {labelToDisplay.length > 0 &&
                    labelToDisplay.map((node) => (
                      <MenuItem key={node.label} value={node.label}>
                        {node.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Typography className="accordInfo">
              <Typography variant="h6" className="nodeSubHead">
                Node Size
              </Typography>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Shape</Typography>
                <FormControl variant="standard" className="col-2">
                  <Select
                    className="form-control selectControl"
                    value={vizNodeStyles[selectedNode]?.shape ?? ''}
                    onChange={(e) => handleStyleValueChange(e, 'shape')}
                    label="nodeProperty"
                  >
                    {/* <MenuItem value="">Select </MenuItem> */}
                    <MenuItem value="ellipse">Ellipse</MenuItem>
                    <MenuItem value="triangle">Triangle</MenuItem>
                    <MenuItem value="round-triangle">Round-triangle</MenuItem>
                    <MenuItem value="rectangle">Rectangle</MenuItem>
                    <MenuItem value="round-rectangle">Round-rectangle</MenuItem>
                    <MenuItem value="bottom-round-rectangle">Bottom-round-rectangle</MenuItem>
                    <MenuItem value="cut-rectangle">cut-rectangle</MenuItem>
                    <MenuItem value="barrel">Barrel</MenuItem>
                    <MenuItem value="rhomboid">Rhomboid</MenuItem>
                    <MenuItem value="diamond">Diamond</MenuItem>
                    <MenuItem value="round-diamond">Round-diamond</MenuItem>
                    <MenuItem value="pentagon">Pentagon</MenuItem>
                    <MenuItem value="round-pentagon">Round-pentagon</MenuItem>
                    <MenuItem value="hexagon">Hexagon</MenuItem>
                    <MenuItem value="round-hexagon">Round-hexagon</MenuItem>
                    <MenuItem value="concave-hexagon">Concave-hexagon</MenuItem>
                    <MenuItem value="heptagon">Heptagon</MenuItem>
                    <MenuItem value="round-heptagon">Round-heptagon</MenuItem>
                    <MenuItem value="octagon">Octagon</MenuItem>
                    <MenuItem value="round-octagon">Round-octagon</MenuItem>
                    <MenuItem value="star">Star</MenuItem>
                    <MenuItem value="tag">Tag</MenuItem>
                    <MenuItem value="round-tag">Round-tag</MenuItem>
                    <MenuItem value="vee">Vee</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Size</Typography>
                <TextField
                  className="col-2"
                  id=""
                  variant="standard"
                  fullWidth
                  value={vizNodeStyles[selectedNode]?.width ?? ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'STYLING_DATA_UPDATE',
                      payload: {
                        key: 'vizNodeStyles',
                        value: {
                          [selectedNode]: {
                            ...vizNodeStyles[selectedNode],
                            width: e.target.value,
                            height: e.target.value
                          }
                        }
                      }
                    });
                    // setAllNodesSettings((prevSettings) => ({
                    //   ...prevSettings,
                    //   width: e.target.value,
                    //   height: e.target.value
                    // }));
                    //  setRefreshStyleComp(new Date());
                  }}
                />
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Padding</Typography>
                <TextField
                  className="col-2"
                  id=""
                  variant="standard"
                  fullWidth
                  value={vizNodeStyles[selectedNode]?.padding ?? ''}
                  onChange={(e) => handleStyleValueChange(e, 'padding')}
                />
              </Box>
              <Typography variant="h6" className="nodeSubHead">
                Node Background
              </Typography>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Background Color</Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <input
                    type="color"
                    id="nodeBgColor"
                    style={{
                      width: '20px',
                      height: '24px',
                      marginTop: '1px',
                      marginLeft: '7px'
                    }}
                    value={vizNodeStyles[selectedNode]?.['background-color'] ?? ''}
                    onChange={(e) => handleColorValueChange(e, 'background-color')}
                  />

                  <TextField
                    variant="standard"
                    style={{ width: '80px', marginRight: '4px' }}
                    value={vizNodeStyles[selectedNode]?.['background-color'] ?? ''}
                    disabled
                    placeholder="#000000"
                  />
                  <RenderIcons
                    icon={getIcon(ColorPanelIcon, null, 18, 18)}
                    onClick={(e) => {
                      const nodeBgColor = document.getElementById('nodeBgColor');
                      nodeBgColor.click();
                      // document.getElementById('nodeBgColor').click(); another method
                    }}
                  />
                </Box>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Background Opacity</Typography>
                <Box className="col-2">
                  <Slider
                    value={Number(vizNodeStyles[selectedNode]?.['background-opacity']) ?? ''}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'background-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ maxWidth: '100px', margin: 'auto' }}
                  />
                </Box>
              </Box>
              <Typography variant="h6" className="nodeSubHead">
                Node Background Image
              </Typography>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Background Image</Typography>

                <Box className="fileTypeClm col-2">
                  <Button
                    onClick={(e) => {
                      handleImageDialogOpen(e.target);
                    }}
                  >
                    upload image
                  </Button>
                  {/* arundhuti */}
                  <Dialog
                    open={openImageUpload}
                    onClose={(e) => {
                      handleImageDialogClose();
                    }}
                    fullWidth
                  >
                    <Box sx={{ textAlign: 'center' }}>
                      <DialogContent>Upload background image</DialogContent>
                    </Box>

                    <Box m={2}>
                      <FormControl className="imgUploadTab">
                        <RadioGroup
                          className="radioHolder"
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={radioValue}
                          onChange={(e) => {
                            setBackgroundImage('');
                            setRadioValue(e.target.value);
                          }}
                          // onChange={handlebackgroundImage}
                        >
                          <FormControlLabel value="Image" control={<Radio />} label="Image url" />
                          <FormControlLabel
                            value="Upload"
                            control={<Radio />}
                            label="Upload image"
                          />
                        </RadioGroup>
                      </FormControl>
                      {radioValue === 'Image' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 3
                          }}
                        >
                          <TextField
                            sx={{ width: 400 }}
                            // variant="filled"
                            // value={backgroundImage}
                            placeholder="Paste image link here."
                            onChange={(e) =>
                              _debounceSetState(e.target.value, setBackgroundImage, 200)
                            }
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 3
                          }}
                        >
                          <input
                            className="imgtablist"
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => {
                              const files = e.target.files;
                              getBase64(files[0], setBackgroundImage, enqueueSnackbar);
                            }}
                          />
                          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <TextField
                              sx={{ width: 400 }}
                              contentEditable={false}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Button
                                      className="btnFileType"
                                      onClick={(e) => uploadFileClick(e)}
                                      startIcon={
                                        <RenderIcons icon={getIcon(UploadIcon, null, 16, 16)} />
                                      }
                                    >
                                      Browse
                                    </Button>
                                  </InputAdornment>
                                )
                              }}
                              value={backgroundImage}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 0,
                        m: 0,
                        mt: 2,
                        pb: 2,
                        py: 1
                      }}
                    >
                      <DialogActions>
                        <Box>
                          <Button
                            onClick={(e) => handleImageDialogClose(e)}
                            variant="outlined"
                            color="primary"
                          >
                            Close
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            disabled={backgroundImage === '' || backgroundImage === null}
                            onClick={() => {
                              dispatch({
                                type: 'STYLING_DATA_UPDATE',
                                payload: {
                                  key: 'vizNodeStyles',
                                  value: {
                                    [selectedNode]: {
                                      ...vizNodeStyles[selectedNode],
                                      'background-image': backgroundImage
                                    }
                                  }
                                }
                              });
                              // setRefreshStyleComp(new Date());
                              handleImageDialogClose();
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </Box>
                      </DialogActions>
                    </Box>
                  </Dialog>
                </Box>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Background Repeat</Typography>
                <FormControl variant="standard" className="col-2">
                  <Select
                    className="form-control selectControl"
                    value={vizNodeStyles[selectedNode]?.['background-repeat'] ?? ''}
                    onChange={(e) => handleStyleValueChange(e, 'background-repeat')}
                    label="nodeProperty"
                  >
                    {/* <MenuItem value="">Select </MenuItem> */}
                    <MenuItem value="no-repeat">No-repeat</MenuItem>
                    <MenuItem value="repeat">Repeat</MenuItem>
                    <MenuItem value="repeat-x">Repeat-x</MenuItem>
                    <MenuItem value="repeat-y">Rrepeat-y</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Background-image-opacity</Typography>

                <Box className="col-2">
                  <Slider
                    value={Number(vizNodeStyles[selectedNode]?.['background-image-opacity']) ?? ''}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'background-image-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ maxWidth: '100px', margin: 'auto' }}
                  />
                </Box>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Background Fit</Typography>
                <FormControl variant="standard" className="col-2">
                  <Select
                    className="form-control selectControl"
                    value={vizNodeStyles[selectedNode]?.['background-fit'] ?? ''}
                    onChange={(e) => handleStyleValueChange(e, 'background-fit')}
                    label="nodeProperty"
                  >
                    {/* <MenuItem value="">Select </MenuItem> */}
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="contain">Contain</MenuItem>
                    <MenuItem value="cover">Cover</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Typography variant="h6" className="nodeSubHead">
                Node Border
              </Typography>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Border Width</Typography>
                <TextField
                  className="col-2"
                  id=""
                  variant="standard"
                  fullWidth
                  value={vizNodeStyles[selectedNode]?.['border-width'] ?? ''}
                  onChange={(e) => handleStyleValueChange(e, 'border-width')}
                />
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Border Style</Typography>
                <FormControl variant="standard" className="col-2">
                  <Select
                    className="form-control selectControl"
                    value={vizNodeStyles[selectedNode]?.['border-style'] ?? ''}
                    onChange={(e) => handleStyleValueChange(e, 'border-style')}
                    label="nodeProperty"
                  >
                    {/* <MenuItem value="">Select </MenuItem> */}
                    <MenuItem value="solid">Solid</MenuItem>
                    <MenuItem value="dotted">Dotted</MenuItem>
                    <MenuItem value="dashed">Dashed</MenuItem>
                    <MenuItem value="double">Double</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Border Color</Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <input
                    type="color"
                    id="nodeBdrColor"
                    style={{
                      width: '20px',
                      height: '24px',
                      marginTop: '1px',
                      marginLeft: '7px'
                    }}
                    value={vizNodeStyles[selectedNode]?.['border-color'] ?? ''}
                    onChange={(e) => handleColorValueChange(e, 'border-color')}
                  />
                  <TextField
                    variant="standard"
                    style={{ width: '80px', marginRight: '4px' }}
                    value={vizNodeStyles[selectedNode]?.['border-color'] ?? ''}
                    disabled
                    placeholder="#000000"
                  />
                  <RenderIcons
                    icon={getIcon(ColorPanelIcon, null, 18, 18)}
                    onClick={(e) => {
                      const nodeBdrColor = document.getElementById('nodeBdrColor');
                      nodeBdrColor.click();
                      // document.getElementById('nodeBgColor').click(); another method
                    }}
                  />
                </Box>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Border Opacity</Typography>
                <Box className="col-2">
                  <Slider
                    value={Number(vizNodeStyles[selectedNode]?.['border-opacity']) ?? ''}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'border-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ maxWidth: '100px', margin: 'auto' }}
                  />
                </Box>
              </Box>
              <Typography variant="h6" className="nodeSubHead">
                Label
              </Typography>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Font Size</Typography>
                <TextField
                  className="col-2"
                  id=""
                  variant="standard"
                  fullWidth
                  value={vizNodeStyles[selectedNode]?.['font-size'] ?? ''}
                  onChange={(e) => handleStyleValueChange(e, 'font-size')}
                />
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Text Color</Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <input
                    type="color"
                    id="nodeFntColor"
                    style={{
                      width: '20px',
                      height: '24px',
                      marginTop: '1px',
                      marginLeft: '7px'
                    }}
                    value={vizNodeStyles[selectedNode]?.color ?? ''}
                    onChange={(e) => handleColorValueChange(e, 'color')}
                  />
                  <TextField
                    variant="standard"
                    style={{ width: '80px', marginRight: '4px' }}
                    value={vizNodeStyles[selectedNode]?.color ?? ''}
                    disabled
                    placeholder="#000000"
                  />
                  <RenderIcons
                    icon={getIcon(ColorPanelIcon, null, 18, 18)}
                    onClick={(e) => {
                      const nodeFntColor = document.getElementById('nodeFntColor');
                      nodeFntColor.click();
                      // document.getElementById('nodeBgColor').click(); another method
                    }}
                  />
                </Box>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Text Opacity</Typography>
                <Box className="col-2">
                  <Slider
                    value={Number(vizNodeStyles[selectedNode]?.['text-opacity']) ?? ''}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'text-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ maxWidth: '100px', margin: 'auto' }}
                  />
                </Box>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Verticle</Typography>
                <FormControl variant="standard" className="col-2">
                  <Select
                    className="form-control selectControl"
                    value={vizNodeStyles[selectedNode]?.['text-valign'] ?? ''}
                    onChange={(e) => handleStyleValueChange(e, 'text-valign')}
                    label="nodeProperty"
                  >
                    <MenuItem value="center">Center</MenuItem>
                    <MenuItem value="top">Top</MenuItem>
                    <MenuItem value="bottom">Bottom</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className="form-row size-col-2">
                <Typography className="col-2 labelHeading">Horizontal</Typography>
                <FormControl variant="standard" className="col-2">
                  <Select
                    className="form-control selectControl"
                    value={vizNodeStyles[selectedNode]?.['text-halign'] ?? ''}
                    onChange={(e) => handleStyleValueChange(e, 'text-halign')}
                    label="nodeProperty"
                  >
                    <MenuItem value="center">Center</MenuItem>
                    <MenuItem value="left">Left</MenuItem>
                    <MenuItem value="right">Right</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {nodeSavedRulesNameList !== undefined && (
          <Accordion
            className="accordT"
            expanded={expandedPanel === 'panel2'}
            onChange={handleExpanedPanel('panel2')}
          >
            <AccordionSummary
              classes={{ root: 'accordTrigger', content: 'accordTgrtxt' }}
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<ExpandMore />}
            >
              <Typography variant="h5" className="nodeAccordHead ">
                Node Filter
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ marginBottom: '10px' }}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  size={'small'}
                  onChange={handleSavedRuleSelection}
                  value={selectedRules}
                  sx={{ width: '100%', display: 'inline-block' }}
                  options={nodeSavedRulesNameList.map((option) => option)}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox size="small" style={{ marginRight: 8 }} checked={selected} />

                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={'Select Rule'}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}
                className="ruleEngine"
                s
              >
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined button group"
                  sx={{ width: '100%' }}
                >
                  <Button
                    disabled={selectedRules.length > 1}
                    variant="contained"
                    sx={groupBtnStyle}
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.view
                        ) {
                          handleApplyRules();
                        } else {
                          setValidationMsg('User does not have permission to view apply.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="contained"
                    sx={groupBtnStyle}
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.add
                        ) {
                          setToggleSaveModal(true);
                        } else {
                          setValidationMsg('User does not have permission to Add.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    disabled={!(selectedRules.length === 1)}
                    variant="contained"
                    sx={groupBtnStyle}
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.edit
                        ) {
                          setPatchToggleSaveModal(true);
                        } else {
                          setValidationMsg('User does not have permission to edit.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="contained"
                    sx={
                      (groupBtnStyle,
                      {
                        backgroundColor: '#f10404',
                        '&:hover': { backgroundColor: '#be0303!important' }
                      })
                    }
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.delete
                        ) {
                          handleDeleteNodeRule();
                        } else {
                          setValidationMsg('User does not have permission to delete.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Delete
                  </Button>
                </ButtonGroup>
                <NodeRuleEngine
                  currentMetaDataId={currentMetaDataId}
                  nodeSavedRulesNameList={nodeSavedRulesNameList}
                  setNodeSavedRulesNameList={setNodeSavedRulesNameList}
                  // allNodesSettings={allNodesSettings}
                  // setAllNodesSettings={setAllNodesSettings}
                  setRefreshStyleComp={setRefreshStyleComp}
                  cyToStateTwo={cyToState}
                  labelToDisplay={labelToDisplay}
                  selectedNode={selectedNode}
                  setSelectedNode={setSelectedNode}
                  toggleSaveModal={toggleSaveModal}
                  setToggleSaveModal={setToggleSaveModal}
                  patchToggleSaveModal={patchToggleSaveModal}
                  setPatchToggleSaveModal={setPatchToggleSaveModal}
                  savedRulesNameList={savedRulesNameList}
                  setSavedRulesNameList={setSavedRulesNameList}
                  setSavedRuleSelection={setSavedRuleSelection}
                  setSavedNodeRuleData={setSavedNodeRuleData}
                  savedNodeRuleData={savedNodeRuleData}
                  selectedRules={selectedRules}
                  setMutateSavedRuleList={setMutateSavedRuleList}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </>
  ) : (
    ''
  );
}

export default NodeStyleSetting;

function getBase64(file, setState, enqueueSnackbar) {
  const maxSizeInBytes = 20 * 1024; // 20kb

  if (file.size > maxSizeInBytes) {
    setState('');
    enqueueSnackbar('File size exceeds the maximum limit (20KB).', {
      variant: 'error',
      autoHideDuration: 3000
    });
    return;
  }

  // Reset error and update the state with the selected file
  enqueueSnackbar('File uploaded succesfully.', {
    variant: 'success',
    autoHideDuration: 3000
  });

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    // console.log(reader.result);
    setState(reader.result);
    // base64 = reader.result;
  };
  reader.onerror = function (error) {
    // console.log('Error: ', error);
  };
}

function rgbToHex(r, g, b) {
  // console.log('rrr', r);
  const hexR = Math.floor(r[0]).toString(16).padStart(2, '0');
  const hexG = Math.floor(r[1]).toString(16).padStart(2, '0');
  const hexB = Math.floor(r[2]).toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
}

function RuleEngine() {
  const [age, setAge] = React.useState('');
  const [arr, setArray] = useState([]);
  const [num, setNum] = useState(0);
  const [mutate, setMutate] = useState();
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const AddRuleHandler = (e) => {
    const ruleArray = arr;
    const num1 = num;
    setNum(num1 + 1);
    ruleArray.push(num);
    // ruleArray.forEach((item) => {
    //   num += 1;
    // });
    setArray(ruleArray);
    setMutate(new Date());
  };
  // console.log('ruleArray1:', arr);
  return (
    <>
      <Box className="ruleEngine_HeaderBox">
        <Box className="ruleEngine_HeaderBox_Main">
          <FormControl sx={{ m: 1, minWidth: 50 }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="and">And</MenuItem>
              <MenuItem value="or">Or</MenuItem>
            </Select>
          </FormControl>
          <Button small variant="outlined" onClick={(e) => AddRuleHandler(e)}>
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">RULE</span>
            </Typography>
          </Button>
          <Button small variant="outlined">
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">GROUP</span>
            </Typography>
          </Button>
        </Box>
        <Box className="ruleEngine_HeaderBox_Main">
          <FormControl sx={{ m: 1, minWidth: 50 }}>
            <Select
              value={age}
              onChange={(e) => handleChange(e)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="and">And</MenuItem>
              <MenuItem value="or">Or</MenuItem>
            </Select>
          </FormControl>
          <Button small variant="outlined" onClick={(e) => AddRuleHandler(e)}>
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">RULE</span>
            </Typography>
          </Button>
          <Button small variant="outlined">
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">GROUP</span>
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box className="ruleEngine_FooterBox">
        <Button small variant="outlined">
          Close
        </Button>
        <Button small variant="outlined">
          Ok
        </Button>
        <Button small variant="outlined">
          Filter
        </Button>
      </Box>
    </>
  );
}
