import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import '../../../global.css';
import './Source.css';
// material ui components theme and icons import
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
  Stack,
  Container,
  Paper,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Badge,
  Link,
  Toolbar,
  Menu,
  ButtonGroup
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Close, InfoOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import { AiFillSave, AiFillEdit, AiOutlineMinus, AiFillCloseCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { BiCloudUpload } from 'react-icons/bi';
import { RiPlayFill } from 'react-icons/ri';
import { MdOutlineExpand, MdOutlineClose, MdOutlineAdd } from 'react-icons/md';
import { uniqWith, isEqual } from 'lodash';
import ReactJson from 'react-json-view';
import Loader from '../../global/Loader';

// icons import
import { getIcon, iconsForDatasourcePage } from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
// import custom components
import Page from '../Page';
import PageTitle from '../../global/PageHeading';
import { userDataFromLocal } from '../../../utils/getUserDetails';
// import pages components
import dataSourceService from '../../../services/dataSourceService';
import testingJson from './testingJson.json';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';
import { getGlobals } from '../../../utils/Globals';
import BrudCrumbs from '../../global/BreadCrumbs';
import TopNavigationBar from '../TopNavigationBar';
import LoaderBlur from '../KnowledgeGraph/LoaderBlur';

const TestingApi = testingJson;

const {
  Savedbconfig,
  SavedbconfigFormData,
  Savedatasource,
  Diallinputpram,
  Editidatasourcelist,
  Editidatasource,
  Testdbedit,
  Apicall,
  ApicallFormData,
  JsonFileSave,
  JsonToDf,
  ExcelSheetName,
  TestFileDynamic,
  FormURLEncodedFilePath,
  XmlFileSave,
  XmlToDf
} = dataSourceService;

/**
 * @class DataSourceAddPage link to Add Button
 * @param properties from outside
 * @returns {React.ReactElement} - React component
 */
function DataSourceAddPage(props) {
  // props
  const {
    userId,
    currentSelection,
    closePopup,
    setIsListMutate,
    setCurrentSelection,
    totalrecords
  } = props;
  // services
  const navigate = useNavigate();
  const { GLOBAL_PATH } = getGlobals();
  const { enqueueSnackbar } = useSnackbar();
  const { rowID, experimentId } = useParams();

  // state
  const [DataSetTitle, setDataSetTitle] = useState('Untitled');
  const [dataSourceType, setDataSourceType] = useState(' ');
  const [fieldData, setFieldData] = useState('');
  const [subCategory, setSubCategory] = useState(' ');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isTested, setIsTested] = useState(false);
  const [serachedDb, setserachedDb] = useState('');
  const [loadAgain, setloadAgain] = useState(null);
  const [dataSourceTypeList, setDataSourceTypeList] = useState([]);
  const [testedData, setTestedData] = useState({});
  const [connectionMsg, setConnectionMsg] = useState('');
  const [connectionStatus, setConnectionStatus] = useState('');
  const [configData, setConfigData] = useState({});
  const [configDataNew, setConfigDataNew] = useState({});
  const [testLoader, setTestLoader] = useState('');
  const [isLoader, setIsLoder] = useState(false);
  const [pathFileName, setpathFileName] = useState(null);
  const [openTestDialobBox, setOpenTestDialobBox] = useState(false);
  const [openCricularDialobBox, setOpenCricularDialobBox] = useState(false);
  const [refreshComp, setRefreshComp] = useState(null);

  // onchange state update
  const [enableSaveOnChange, setEnableSaveOnChange] = useState('');
  const [testEnableOnChange, setTestEnableOnChange] = useState('');

  // data grid state
  const [dataGridData, setDataGridData] = useState([]);
  const [dataGridRow, setDataGridRow] = useState([]);
  const [dataGridColumn, setDataGridColumn] = useState([]);

  // Select file component state
  const [authType, setAuthType] = useState('noAuth');
  const [authUser, setAuthUser] = useState('');
  const [authPass, setAuthPass] = useState('');
  const [token, setToken] = useState('');
  const [parsedTableVal, setParsedTableVal] = useState('-');
  const [parsedTableValArr, setParsedTableValArr] = useState('-');
  const [bodyRequest, setBodyRequest] = useState('');
  const [bodyFileRequest, setBodyFileRequest] = useState('');

  const [SelectBodyType, setSelectBodyType] = useState('Text');
  const [configFileData, setConfigFileData] = useState({});
  const [fileChanged, setFileChanged] = useState('');
  const [apiLoader, setApiLoader] = useState(false);

  const textDialogBoxClose = (e) => {
    setOpenTestDialobBox(false);
  };

  //  const row = []
  //  const rowObj = {}
  //  dataGridData.forEach((item)=>{
  //   Object.values(item).forEach((value)=>{

  //   })
  //  });

  // const row = [{  lastName: 'Snow', firstName: 'Jon', age: 35 }]
  /**
   * @type {function} - function
   * @return return test result
   */

  const testConnection = async (e) => {
    setOpenTestDialobBox(true);
    setTestLoader(true);
    // const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const configProp = configData;
    // configProp.datasource_name = DataSetTitle.toLowerCase();
    configProp.sub_category = subCategory;
    // configProp.updt_user = userDataFromLocal().userName;
    // configProp.crea_user = userDataFromLocal().userName;

    let isConnection = '';
    const authToken = userDataFromLocal();
    const fd = new FormData();

    for (const [key, value] of Object.entries(configProp)) {
      if (configProp.hasOwnProperty(key) && value) {
        if (value.startsWith('http')) {
          try {
            const response = await TestFileDynamic(value, authToken.token);

            if (response) {
              const fileBlob = response;
              const fileName = value.substring(value.lastIndexOf('/') + 1);
              fd.append(key, fileBlob, fileName);
            } else {
              console.error('Error downloading file:', value, response.status);
            }
          } catch (error) {
            console.error('Error downloading file:', value, error);
          }
        } else {
          fd.append(key, value);
        }
      }
    }

    if (configFileData.hasOwnProperty(subCategory)) {
      const subCategoryData = configFileData[subCategory];
      Object.entries(subCategoryData).forEach(([subKey, subValue]) => {
        if (subCategoryData.hasOwnProperty(subKey) && subValue) {
          fd.delete(subKey);
          fd.append(subKey, subValue);
        }
      });
    }

    isConnection = await SavedbconfigFormData(fd, authToken.token);

    enqueueSnackbar(isConnection.message, {
      variant: isConnection.status,
      autoHideDuration: 2000
    });
    if (isConnection.status === 'success') {
      setTestLoader(false);
      setConnectionMsg(isConnection.message);
      setConnectionStatus(isConnection.status);
      setTestedData(isConnection.data);
      setTimeout(() => {
        setIsTested(true);
      }, 500);
    }
    if (isConnection.status === 'error') {
      setTestLoader(false);
      setConnectionMsg(isConnection.message);
      setConnectionStatus(isConnection.status);
      setTestedData({});
    }
  };

  /**
   * @type {function} -submitCreateClient
   * @param {event} - event parameter
   * @returns {Promise} -Savedatasource() that store data to sever
   */

  const submitCreateClient = async (e) => {
    const configProp = {
      data_source_type: dataSourceType,
      sub_category: subCategory,
      input_parameters: configData
    };
    if (rowID !== undefined) {
      configProp.initial = 'False';
    } else {
      configProp.initial = 'True';
    }
    /**
     * @description FormData() element
     * @description Appending data in form object
     */

    const formData = new FormData();
    if (uploadedFile) {
      formData.append('path', uploadedFile);
    }
    formData.append('crea_user', userDataFromLocal().userName);
    formData.append('updt_user', userDataFromLocal().userName);
    formData.append('config', JSON.stringify(configProp));

    // data from api for data payload
    if (Object.keys(bodyFileRequest).length > 0) {
      Object.keys(bodyFileRequest).forEach((item) => {
        formData.append(
          [item],
          bodyFileRequest[item].constructor.name === 'Array'
            ? bodyFileRequest[item][0]
            : bodyFileRequest[item]
        );
      });
    }
    if (rowID !== undefined) {
      const authToken = userDataFromLocal();
      formData.append('creator_user_id', authToken.userID);
      try {
        (async () => {
          configProp.input_parameters.datasource_name = DataSetTitle;
          configProp.input_parameters.updt_user = userDataFromLocal().userName;
          formData.delete('config');
          formData.append('config', JSON.stringify(configProp));

          const editDataSourceApi = await Editidatasource(rowID, formData, authToken.token);
          if (editDataSourceApi.status === 'success') {
            console.log('editDataSourceApi', editDataSourceApi);
            navigate(`${GLOBAL_PATH}/datasource`, { replace: true });
            const savedData = editDataSourceApi.data;
            if (editDataSourceApi.data && editDataSourceApi.data.length > 0) {
              setDataGridData(editDataSourceApi.data);
            }
          } else if (editDataSourceApi.status === 'error') {
            enqueueSnackbar(editDataSourceApi.message, {
              variant: 'error',
              autoHideDuration: 2000
            });
          }
        })();
      } catch (error) {
        console.log('error', error);
      }
    } else {
      if (DataSetTitle === 'Untitled' || DataSetTitle === '') {
        enqueueSnackbar('Source name should not be blank', {
          variant: 'error',
          autoHideDuration: 2000
        });
        return;
      }

      const authToken = userDataFromLocal();
      formData.append('creator_user_id', authToken.userID);

      try {
        formData.append('datasource_name', DataSetTitle);
        setApiLoader(true);
        const saveDataSourcesApi = await Savedatasource(formData, authToken.token);
        if (saveDataSourcesApi.status === 'success') {
          setApiLoader(false);
          navigate(`${GLOBAL_PATH}/datasource`, { replace: true });
          const savedData = saveDataSourcesApi.data;
          if (saveDataSourcesApi.data.length > 0) {
            setDataGridData(saveDataSourcesApi.data);
          }
        } else {
          setApiLoader(false);
          enqueueSnackbar(saveDataSourcesApi.message, {
            variant: 'error',
            autoHideDuration: 2000
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  useEffect(() => {
    const columns = [];
    if (dataGridData.length > 0) {
      Object.keys(dataGridData[0]).forEach((item, i) => {
        const columnObj = {};
        columnObj.field = item;
        columnObj.headerName = item;
        columnObj.width = 100;
        columns.push(columnObj);
      });
      setDataGridColumn(columns);
      dataGridData.forEach((item, index) => {
        item.id = index;
      });
      setDataGridRow(dataGridData);
    }
  }, [dataGridData, refreshComp]);

  useEffect(() => {
    const authToken = userDataFromLocal();
    (async () => {
      const isFeildsAvailable = await Diallinputpram(authToken.token); // empty object if needed
      if (isFeildsAvailable.status === 'success') {
        setFieldData(isFeildsAvailable.data);
        const uniqueSource = [
          ...new Map(isFeildsAvailable.data.map((item) => [item.data_source_type, item])).values()
        ];
        setDataSourceTypeList(uniqueSource);
        setDataSourceType(uniqueSource[0].data_source_type);
      }

      /**
       * @description when click on edit and row is define ,isEditdataAvailable function fire an get api
       * @param take rowID as parameter and set the value of all field
       */
      if (rowID !== undefined) {
        // const authToken = userDataFromLocal();
        const isEditdataAvailable = await Editidatasourcelist(rowID, authToken.token); // getApi(editidatasourcelist + rowID, {});
        if (isEditdataAvailable.status === 'success') {
          // setDataSourceType setSubCategory setConfigData
          setDataSetTitle(isEditdataAvailable.data[0].datasource_name);
          setDataSourceType(isEditdataAvailable.data[0].config.data_source_type);
          setSubCategory(isEditdataAvailable.data[0].config.sub_category);
          // setting all data into config auth url payload and file
          setConfigData(isEditdataAvailable.data[0].config.input_parameters);
          setConfigDataNew(isEditdataAvailable.data[0].config.input_parameters);
          setAuthType(isEditdataAvailable.data[0].config?.input_parameters?.auth?.Authtype);
          setAuthUser(isEditdataAvailable.data[0].config?.input_parameters?.auth?.pwd);
          setAuthPass(isEditdataAvailable.data[0].config?.input_parameters?.auth?.user);
          setToken(isEditdataAvailable.data[0].config?.input_parameters?.token);

          if (
            isEditdataAvailable.data[0].config?.input_parameters?.mainBodyData !== 'formURLEncoded'
          ) {
            setBodyRequest(
              JSON.stringify(isEditdataAvailable.data[0].config?.input_parameters?.payload)
            );
          }

          setBodyFileRequest('');
          setpathFileName(() => {
            const path = isEditdataAvailable.data[0].path;
            let pathArray = '';
            if (path !== null) {
              pathArray = path.split('/').pop();
            }
            return pathArray;
          });
        }
      }
    })();

    // Try to use to svg img delay loader at this page
    setTimeout(() => {
      const PADDING = 0.2; // 20% padding on all sides
      const svgElements = document.querySelectorAll('.fileExtention svg');
      svgElements.forEach((svg) => {
        const bbox = svg.getBBox();
        const padX = PADDING * bbox.width;
        const padY = PADDING * bbox.height;
        const viewbox = [
          bbox.x - padX,
          bbox.y - padY,
          bbox.width + 2 * padX,
          bbox.height + 2 * padY
        ].join(' ');
        svg.setAttribute('viewBox', viewbox);
        if (refreshComp === null) {
          setRefreshComp(new Date());
        }
      });
    }, 1000);
  }, [loadAgain]);

  return (
    <Page title={`Create-Source`}>
      <Container maxWidth="xl" className="umgnoPadding bg-h-100">
        {apiLoader && <LoaderBlur customMsg="Please wait.." />}
        <TopNavigationBar />
        <Box py={3} px={2} mb={4}>
          {/* <Stack
            className="pageTitleholder"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <PageTitle
              icon={getIcon(DataSourceIcon)}
              info="Source"
              className="globalCardHeadertitleBox"
            />
          </Stack> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.8rem' }}>
            <Typography variant="h6" sx={{ color: '#004C99', p: 1 }}>
              Add Data Source
            </Typography>
          </Box>
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="umgpageHeading"
          >
            <PageTitle info="Source" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack> */}
          <Box mb={2}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Box display="flex" alignItems="center">
                  {isEditable ? (
                    <>
                      <TextField
                        fullWidth
                        sx={{ width: '300px' }}
                        inputProps={{ className: 'umgtextInput umgtextInputTable' }}
                        // className="formInputText"
                        value={DataSetTitle}
                        onChange={(e) => {
                          const regx = /[^a-z0-9_ ]/gi;
                          if (regx.test(e.target.value)) {
                            return;
                          }
                          setDataSetTitle(e.target.value);
                          if (rowID !== undefined) {
                            // setEnableSaveOnChange('setsavedisable');
                            setTestEnableOnChange('Data Source name changed');
                          }
                        }}
                        onFocus={(e) => {
                          if (DataSetTitle === 'Untitled') {
                            setDataSetTitle('');
                          }
                        }}
                        onBlur={(e) => {
                          if (DataSetTitle === '') {
                            setDataSetTitle('Untitled');
                          }
                        }}
                      />
                      <IconButton
                        className="umgsquareIconButton umgml-1 umgactionButton"
                        onClick={(e) => setIsEditable(false)}
                      >
                        <HiCheck size={14} />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography variant="body2" className="umgpage-sub-title">
                        {DataSetTitle}
                      </Typography>
                      <IconButton
                        className="umgsquareIconButton umgactionButton umgml-1"
                        onClick={(e) => setIsEditable(true)}
                      >
                        <AiFillEdit size={14} />
                      </IconButton>
                    </>
                  )}
                </Box>

                <Dialog open={openTestDialobBox} textAlign="center">
                  <DialogTitle id="alert-dialog-title" className="umgpopupTitle">
                    <Typography variant="subtitle1" className="umgpopupSubTitle">
                      Testing DB Connection
                    </Typography>
                  </DialogTitle>
                  {testLoader ? (
                    <DialogContent dividers className="umgpopupInfo" sx={{ textAlign: 'center' }}>
                      <Typography variant="body1">
                        <CircularProgress />
                      </Typography>
                    </DialogContent>
                  ) : (
                    <DialogContent
                      id="alert-dialog-description"
                      dividers
                      className="umgpopupInfo"
                      sx={{
                        textAlign: 'center',
                        color: connectionStatus === 'success' ? 'green' : 'red'
                      }}
                    >
                      <Typography variant="body1"> {connectionMsg}</Typography>
                    </DialogContent>
                  )}

                  <DialogActions disableSpacing={false} className="umgpopupAction">
                    <Button
                      onClick={(e) => textDialogBoxClose(e)}
                      // variant="outlined"
                      // color="primary"
                      className="umgmainBtn"
                    >
                      {connectionStatus === '' || connectionStatus === 'success'
                        ? 'Done'
                        : 'Try Again'}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* progress cricular bar when showing list of 10 table */}

                <Dialog open={openCricularDialobBox} width="600px" textAlign="center">
                  <DialogTitle id="alert-dialog-title">Getting Table Row</DialogTitle>
                  {dataGridData ? (
                    <DialogContent sx={{ textAlign: 'center' }}>
                      <Box>
                        <CircularProgress />
                      </Box>
                    </DialogContent>
                  ) : (
                    ''
                  )}

                  {/* <DialogActions>
                    <Button onClick={(e) => textDialogBoxClose(e)}>
                      {connectionStatus === '' || connectionStatus === 'success'
                        ? 'Done'
                        : 'Try Again'}
                    </Button>
                  </DialogActions> */}
                </Dialog>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Button
                    // variant="outlined"
                    // size="small"
                    disabled={TestDisableHandler(
                      dataSourceType,
                      rowID,
                      testEnableOnChange,
                      configData,
                      configDataNew,
                      fileChanged
                    )}
                    onClick={(e) => testConnection(e.currentTarget)}
                    // classes={{
                    //   root: 'globalSubmitButton globalSubmitButtonCancel autoButton'
                    // }}
                    className="umgmainBtn umgmainBtnSec"
                  >
                    <RiPlayFill />
                    <span className="umgml-1">Test</span>
                  </Button>
                  <Button
                    // variant="outlined"
                    // size="small"
                    disabled={IsDisabled(dataSourceType, isTested, enableSaveOnChange)}
                    onClick={(e) => submitCreateClient(e.currentTarget)}
                    className="umgmainBtn umgmainBtnSec umgml-1"
                    // classes={{
                    //   root: 'globalSubmitButton globalSubmitButtonCancel autoButton'
                    // }}
                  >
                    <AiFillSave />
                    <span className="umgml-1">Save</span>
                  </Button>
                  <Button
                    onClick={(e) => {
                      setDataSetTitle('Untitled');
                      setDataSourceType(' ');
                      setSubCategory(' ');
                      setConfigData({});
                      navigate(`${GLOBAL_PATH}/datasource`);
                      setloadAgain(new Date());
                    }}
                    // classes={{
                    //   root: 'globalSubmitButton globalSubmitButtonCancel autoButton'
                    // }}
                    className="umgmainBtn umgmainBtnSec umgml-1"
                  >
                    <AiFillCloseCircle />
                    <span className="umgml-1">Cancel</span>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* <Typography variant="body1" className="headingLineUp headingLineUpOut">
            Source Type*
          </Typography> */}
          <SelectSourceType
            dataSourceType={dataSourceType}
            setDataSourceType={setDataSourceType}
            dataSourceTypeList={dataSourceTypeList}
            setSubCategory={setSubCategory}
            setIsTested={setIsTested}
            setUploadedFile={setUploadedFile}
            setConfigData={setConfigData}
            rowID={rowID}
          />
          <Box className="umgviewLeadCard" mb={3} mt={2}>
            {dataSourceType !== ' ' ? (
              <>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    {/* <Typography variant="body2" className="umgformLabel">
                      {dataSourceType} Type*
                    </Typography> */}
                    <TextField
                      value={serachedDb}
                      inputProps={{ className: 'umgtextInput' }}
                      onChange={(e) => {
                        setserachedDb(e.target.value);
                        setTimeout(() => {
                          const PADDING = 0.2; // 20% padding on all sides
                          const svgElements = document.querySelectorAll('.fileExtention svg');
                          svgElements.forEach((svg) => {
                            const bbox = svg.getBBox();
                            const padX = PADDING * bbox.width;
                            const padY = PADDING * bbox.height;
                            const viewbox = [
                              bbox.x - padX,
                              bbox.y - padY,
                              bbox.width + 2 * padX,
                              bbox.height + 2 * padY
                            ].join(' ');
                            svg.setAttribute('viewBox', viewbox);
                            if (refreshComp === null) {
                              setRefreshComp(new Date());
                            }
                          });
                        }, 1);
                      }}
                      placeholder="Search Data Source"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Box className="umgfileExtention">
                  <SelectFileType
                    dataSourceType={dataSourceType}
                    filterData={fieldData.filter(
                      (item) =>
                        item.data_source_type === dataSourceType &&
                        item.sub_category.toLowerCase().includes(serachedDb)
                    )}
                    DataSetTitle={DataSetTitle}
                    setDataSetTitle={setDataSetTitle}
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                    setUploadedFile={setUploadedFile}
                    setConfigData={setConfigData}
                    configData={configData}
                    authType={authType}
                    setAuthType={setAuthType}
                    authUser={authUser}
                    setAuthUser={setAuthUser}
                    authPass={authPass}
                    setAuthPass={setAuthPass}
                    token={token}
                    setToken={setToken}
                    parsedTableVal={parsedTableVal}
                    setParsedTableVal={setParsedTableVal}
                    bodyRequest={bodyRequest}
                    setBodyRequest={setBodyRequest}
                    bodyFileRequest={bodyFileRequest}
                    setBodyFileRequest={setBodyFileRequest}
                    setParsedTableValArr={setParsedTableValArr}
                    parsedTableValArr={parsedTableValArr}
                    rowID={rowID}
                  />
                </Box>
              </>
            ) : (
              ''
            )}

            {/* {Object.keys(testedData).length > 0 ? (
              <>
                <Box
                  // key={index}
                  // borderTop={index === 0 ? 1 : 0}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  borderBottom={1}
                  // bgcolor={index % 2 === 0 ? '#eee' : '#fffeee'}
                >
                  <Box width={130} textAlign="left">
                    {Object.keys(testedData)[0]}
                  </Box>
                </Box>
                {testedData.Schemas > 0 &&
                  testedData.Schemas.map((item, index) => (
                    <Box width={200} textAlign="left">
                      {item}
                    </Box>
                  ))}
                <br />
                <br />
              </>
            ) : (
              ''
            )} */}
          </Box>
          {dataSourceType !== ' ' && subCategory !== ' ' ? (
            <Box className="umgviewLeadCard">
              <Allfields
                dataSourceType={dataSourceType}
                subCategory={subCategory}
                fieldData={fieldData}
                setConfigData={setConfigData}
                configData={configData}
                setUploadedFile={setUploadedFile}
                pathFileName={pathFileName}
                setTestEnableOnChange={setTestEnableOnChange}
                setConfigFileData={setConfigFileData}
                setFileChanged={setFileChanged}
                setIsTested={setIsTested}
                isTested={isTested}
                setApiLoader={setApiLoader}
              />
            </Box>
          ) : (
            ''
          )}
          {dataGridData.length > 0 ? (
            <Box className="umggreenBorder umgtableBox" style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={dataGridRow}
                columns={dataGridColumn}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Container>
    </Page>
  );
}

export default DataSourceAddPage;

/**
 * @type {function} - Select Source Type
 * @param {object} dataSourceType- Input object
 * @param {object} setDataSourceType- Input object
 * @param {object} dataSourceTypeList- Input object
 * @param {object} setSubCategory- Input object
 * @param {object} setUploadedFile- Input object
 * @param {object} setConfigData- Input object
 * @param {object} setIsTested- Input object
 * @return {React.ReactElement} - React component
 */
function SelectSourceType({
  dataSourceType,
  setDataSourceType,
  dataSourceTypeList,
  setSubCategory,
  setUploadedFile,
  setConfigData,
  setIsTested,
  rowID
}) {
  const [refreshComp, setRefreshComp] = useState(null);

  useEffect(() => {}, [dataSourceTypeList]);

  return (
    <Tabs
      className="umgtabOuter"
      aria-label="basic tabs example"
      classes={{ indicator: 'umgtabIndicator' }}
      value={dataSourceType}
      onChange={(e, newValue) => {
        setDataSourceType(newValue);
        setSubCategory(' ');
        setUploadedFile(null);
        setConfigData({});
        setIsTested(false);
        setTimeout(() => {
          const PADDING = 0.2; // 20% padding on all sides
          const svgElements = document.querySelectorAll('.fileExtention svg');
          svgElements.forEach((svg) => {
            const bbox = svg.getBBox();
            const padX = PADDING * bbox.width;
            const padY = PADDING * bbox.height;
            const viewbox = [
              bbox.x - padX,
              bbox.y - padY,
              bbox.width + 2 * padX,
              bbox.height + 2 * padY
            ].join(' ');
            svg.setAttribute('viewBox', viewbox);
            if (refreshComp === null) {
              setRefreshComp(new Date());
            }
          });
        }, 1);
      }}
    >
      {dataSourceTypeList?.length > 0
        ? dataSourceTypeList?.map((item, index) => (
            <Tab
              key={`dataSourceTypeList-${index}`}
              disabled={TabDIsable(item, dataSourceType, rowID)}
              label={item.data_source_type}
              value={item.data_source_type}
              classes={{ selected: 'umgtabSelected', root: 'umgtabDefault' }}
            />
          ))
        : ''}
    </Tabs>
  );
}

/**
 * @type {function} - SelectFileType
 * @param {object} dataSourceType- Input object
 * @param {object} filterData- Input object
 * @param {object} subCategory- Input object
 * @param {object} setSubCategory- Input object
 * @param {object} setUploadedFile- Input object
 * @param {object} setConfigData- Input object
 * @param {object} setConfigData- Input object
 * @return {React.ReactElement} - React component
 */

function SelectFileType({
  dataSourceType,
  filterData,
  subCategory,
  setSubCategory,
  setUploadedFile,
  setConfigData,
  configData,
  DataSetTitle,
  setDataSetTitle,
  authType,
  setAuthType,
  authUser,
  setAuthUser,
  authPass,
  setAuthPass,
  token,
  setToken,
  parsedTableVal,
  setParsedTableVal,
  bodyRequest,
  setBodyRequest,
  bodyFileRequest,
  setBodyFileRequest,
  setParsedTableValArr,
  parsedTableValArr,
  rowID
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [openDialogSource, setOpenDialogSource] = useState(false);
  // New page field
  // const [sourceName, setSourceName] = useState(''); using instead DataSetTitle, setDataSetTitle
  const [enterUrl, setEnterUrl] = useState('');
  const [method, setMethod] = useState('GET');
  const [methodAuth, setMethodAuth] = useState('GET');
  const [authorization, setAuthorization] = useState(' ');
  const [bodyType, setBodyType] = useState(' ');
  const [openDialogParam, setOpenDialogParam] = useState(false);
  const [hdrCredKey, setHdrCredKey] = useState('');
  const [hdrKeyValue, setHdrKeyValue] = useState('');
  const [show, setShow] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [credParams, setCredParams] = useState('');
  const [jsonApiData, setJsonApiData] = useState('');
  const [jsonApiDataForGrid, setJsonApiDataForGrid] = useState('');
  const [allApiOptions, setallApiOptions] = useState([]);
  const [jsonViewMode, setJsonViewMode] = useState('compressed');
  const [gridRowsForTable, setGridRowsForTable] = useState([]);
  const [gridColumnsForTable, setGridColumnsForTable] = useState([]);
  const [tableErrosMsg, setTableErrosMsg] = useState(false);

  // Authorization token API url, sothat can get token while procedding
  const [authTokenUrl, setAuthTokenUrl] = useState('');
  const [AuthbodyRequest, setAuthbodyRequest] = useState('');
  const [resultToken, setResultToken] = useState('');
  const [tokenKeySelect, setTokenKeySelect] = useState(' ');
  const [authTypeChild, setAuthTypeChild] = useState('noAuth');

  const [uploadFileName, setUploadFileName] = useState('Upload File');
  const [dataGridTableArray, setDataGridTableArray] = useState([]);
  const [curTempFileName, setcurTempFileName] = useState('');
  const [mutateTable, setMtateTable] = useState('');
  const [openloader, setOpenLoader] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [parsedData, setparsedData] = useState([]);
  const [openParsedKey, setOpenParsedKey] = useState(false);

  const [selectedColumn, setSelectedColumn] = useState('-');
  const [SelectedArea, setSelectedArea] = useState(null);
  const [allStepsAnddata, setAllStepsAndData] = useState([]);
  const [allStepsNaviagation, setAllStepsNaviagation] = useState([]);

  const [hideLoaderIcon, setHideLoaderIcon] = useState(true);

  const createRowsByArraydataApi = () => {};

  const getValueDataApi = (params, allStepsAnddata, currentStep, setCurrentStep) => {
    if (typeof params?.value === 'object') {
      if (Array.isArray(params?.value)) {
        if (params?.value.length > 0) {
          return (
            <Button
              onClick={(e) => {
                getJsonToTableApi(
                  params.row,
                  setDataGridTableArray,
                  setcurTempFileName,
                  getValueDataApi,
                  getColumnTypeApi,
                  params?.field,
                  params.row.index,
                  setSelectedColumn,
                  'selectlist'
                );
              }}
              className="umgmainBtn"
            >
              List
            </Button>
          );
        }
        return 'No value found';
      } else if (params?.value) {
        if (Object.keys(params?.value) && Object.keys(params?.value).length > 0) {
          return (
            <Button
              onClick={(e) =>
                getJsonToTableApi(
                  params.row,
                  setDataGridTableArray,
                  setcurTempFileName,
                  getValueDataApi,
                  getColumnTypeApi,
                  params?.field,
                  params.row.index,
                  setSelectedColumn,
                  'selectobject'
                )
              }
              className="umgmainBtn"
            >
              Records
            </Button>
          );
        }
        return 'No value found';
        //   }
        // }
      } else {
        return 'No value found';
      }
    } else {
      return String(params?.value);
    }
  };

  const getColumnTypeApi = (params) => {
    return 'String';
  };
  const handleCloseParseKey = () => {
    setOpenValidation(false);
  };

  const [addField, setAddField] = useState([
    {
      hdrKey: '',
      hdrValue: ''
    }
  ]);

  // main body form data field
  const [addFieldFormD, setAddFieldFormD] = useState([
    {
      type: 'Text',
      hdrKey: '',
      hdrValue: ''
    }
  ]);

  // Authorization child body form data field
  const [addFieldAuthBodyFormD, setAddFieldAuthBodyFormD] = useState([
    {
      hdrKey: '',
      hdrValue: ''
    }
  ]);
  const [addFieldAuthHdrFormD, setAddFieldAuthHdrFormD] = useState([
    {
      hdrKey: '',
      hdrValue: ''
    }
  ]);

  const getJsonDataFriomApi = async (
    isMainBodyData,
    isMethod,
    isUrl,
    isToken,
    isAuth,
    isPayload
  ) => {
    setOpenLoader(true);
    const AuthKeyAndValue = {};

    addFieldFormD.forEach((item, index) => {
      if (item.type === 'Text') {
        AuthKeyAndValue[item?.hdrKey] = item?.hdrValue;
      }
    });
    let credential = {};
    // const credential = {
    //   mainBodyData: isMainBodyData ? isMainBodyData : radioValue,
    //   method: isMethod ? isMethod : method,
    //   url: isUrl ? isUrl : enterUrl,
    //   token: isToken ? isToken : token,
    //   auth: {
    //     Authtype: isAuth
    //       ? isAuth.authType
    //         ? isAuth.authType
    //         : authType === '-'
    //         ? ''
    //         : authType
    //       : authType === '-'
    //       ? ''
    //       : authType,
    //     user: isAuth ? (isAuth.authUser ? isAuth.authUser : authUser) : authUser,
    //     pwd: isAuth ? (isAuth.authPass ? isAuth.authPass : authPass) : authPass
    //   },
    //   payload:
    //     radioValue === 'formURLEncoded'
    //       ? AuthKeyAndValue
    //       : isPayload
    //       ? isPayload
    //       : bodyRequest !== ''
    //       ? JSON.parse(bodyRequest)
    //       : {}
    // };
    // for form data condition
    const formData = new FormData();
    // for payload when last apis save
    const payloadbodyRequest = {};
    const payloadbodyfileRequest = {};

    // real data
    credential = {
      mainBodyData: isMainBodyData ? isMainBodyData : radioValue,
      method: isMethod ? isMethod : method,
      url: isUrl ? isUrl : enterUrl,
      token: isToken ? isToken : token,
      auth: {
        Authtype: isAuth
          ? isAuth.authType
            ? isAuth.authType
            : authType === '-'
            ? ''
            : authType
          : authType === '-'
          ? ''
          : authType,
        user: isAuth ? (isAuth.authUser ? isAuth.authUser : authUser) : authUser,
        pwd: isAuth ? (isAuth.authPass ? isAuth.authPass : authPass) : authPass
      },
      payload:
        radioValue === 'formURLEncoded'
          ? AuthKeyAndValue
          : isPayload
          ? isPayload
          : bodyRequest !== ''
          ? JSON.parse(bodyRequest)
          : {}
    };
    console.log('credential', credential);
    if (radioValue === 'raw') {
      credential = {
        mainBodyData: isMainBodyData ? isMainBodyData : radioValue,
        method: isMethod ? isMethod : method,
        url: isUrl ? isUrl : enterUrl,
        token: isToken ? isToken : token,
        auth: {
          Authtype: isAuth
            ? isAuth.authType
              ? isAuth.authType
              : authType === '-'
              ? ''
              : authType
            : authType === '-'
            ? ''
            : authType,
          user: isAuth ? (isAuth.authUser ? isAuth.authUser : authUser) : authUser,
          pwd: isAuth ? (isAuth.authPass ? isAuth.authPass : authPass) : authPass
        },
        payload:
          radioValue === 'formURLEncoded'
            ? AuthKeyAndValue
            : isPayload
            ? isPayload
            : bodyRequest !== ''
            ? JSON.parse(bodyRequest)
            : {}
      };
    } else if (radioValue === 'formURLEncoded') {
      formData.append('mainBodyData', radioValue);
      formData.append('method', isMethod ? isMethod : method);
      formData.append('url', isUrl ? isUrl : enterUrl);
      formData.append('token', isToken ? isToken : token);
      formData.append(
        '  auth',
        JSON.stringify({
          Authtype: isAuth
            ? isAuth.authType
              ? isAuth.authType
              : authType === '-'
              ? ''
              : authType
            : authType === '-'
            ? ''
            : authType,
          user: isAuth ? (isAuth.authUser ? isAuth.authUser : authUser) : authUser,
          pwd: isAuth ? (isAuth.authPass ? isAuth.authPass : authPass) : authPass
        })
      );
      const filekeys = [];
      addFieldFormD.forEach((filetype, filetypeindex) => {
        if (filetype.type === 'File') {
          formData.append(filetype.hdrKey, filetype.hdrValue);
          filekeys.push(filetype.hdrKey);
        }
      });
      if (filekeys.length > 0) {
        AuthKeyAndValue.filekeys = filekeys;
      }

      formData.append('payload', JSON.stringify(AuthKeyAndValue));
      // formData.append('filekeys', JSON.stringify([...filekeys]));

      // data for last save api in payload key

      payloadbodyRequest.payload =
        radioValue === 'formURLEncoded'
          ? AuthKeyAndValue
          : isPayload
          ? isPayload
          : bodyRequest !== ''
          ? JSON.parse(bodyRequest)
          : {};
      // // const filekeys = [];
      addFieldFormD.forEach((filetype, filetypeindex) => {
        if (filetype.type === 'File') {
          payloadbodyfileRequest[filetype.hdrKey] = [filetype.hdrValue];
          // filekeys.push(filetype.hdrKey);
        }
      });
      // payloadbodyfileRequest.filekeys = JSON.stringify([...filekeys]);
    }

    // working on above code
    let isApiDataRecieved = '';
    const authToken = userDataFromLocal();
    if (radioValue === 'formURLEncoded') {
      // setBodyRequest(JSON.stringify({ ...payloadbodyRequest.payload }));

      setBodyFileRequest(payloadbodyfileRequest);
      isApiDataRecieved = await ApicallFormData(formData, authToken.token);
    } else if (radioValue === 'raw') {
      // setBodyRequest(JSON.stringify({ ...payloadbodyRequest.payload }));
      isApiDataRecieved = await Apicall(credential, authToken.token);
    } else {
      isApiDataRecieved = await Apicall(credential, authToken.token);
    }

    setOpenLoader(false);

    if (isApiDataRecieved.status === 'success') {
      setJsonApiDataForGrid(isApiDataRecieved.data);
      const optionsArray = [];
      const optionArra = getAllKeysAsOptions(isApiDataRecieved.data, optionsArray, ''); // Object.keys(isApiDataRecieved.data);
      setallApiOptions(optionArra);

      // if in edit case  when click excute button
      if (rowID === '' || rowID === undefined) {
        setJsonViewMode('table');
        setShow(true);
        setJsonApiData(JSON.stringify(isApiDataRecieved.data));
        setResultToken(isApiDataRecieved.data.data);
        displayTableFromJsonData(
          isApiDataRecieved,
          setDataGridTableArray,
          setcurTempFileName,
          getValueDataApi,
          getColumnTypeApi
        );
      } else {
        setShow(true);
        // set all option arry in value
        // on th base of option array ,,fire create table function
        // optionsArray and filter with parsingKey or directly set configData.parsingKey
        // setJsonApiDataForGrid(isApiDataRecieved.data);
        setJsonViewMode('table');
        setParsedTableValArr(configData?.parsingKey);
        setParsedTableVal(configData?.parsingKey);
        createTableFromJsonData(configData?.parsingKey, isApiDataRecieved.data);
        setMtateTable(new Date());
      }
    } else {
      enqueueSnackbar(isApiDataRecieved.message, {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
  };
  const getJsonDataChildApi = async (
    methodAuth,
    authTokenUrl,
    AuthbodyRequest,
    AuthKeyAndValue
  ) => {
    function recurMethod(obj) {
      if (Object.values(obj).length === 0) return;

      Object.values(obj).forEach((data) => {
        recurMethod(data);
      });
    }
    // AuthKeyAndValue.Authtype = isAuth ? isAuth.authType : authType;
    const credential = {
      method: methodAuth,
      url: authTokenUrl,
      token: null,
      auth: { Authtype: authTypeChild, user: '', pwd: '' },
      payload:
        radioValueAuthChild === 'rawAuth'
          ? AuthbodyRequest !== ''
            ? JSON.parse(AuthbodyRequest)
            : {}
          : AuthKeyAndValue
    };
    const authToken = userDataFromLocal();
    const isApiDataRecieved = await Apicall(credential, authToken.token);
    if (isApiDataRecieved.status === 'success') {
      enqueueSnackbar('API executed successful', {
        variant: 'success',
        autoHideDuration: 2000
      });
      setShowToken(true);
      if (rowID === '') {
        setResultToken(isApiDataRecieved.data.data);
      }
      recurMethod(isApiDataRecieved.data);
    } else {
      enqueueSnackbar(isApiDataRecieved.message, {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
  };

  const renderCellByParam = (params) => {
    if (typeof params.value === 'string' || typeof params.value === 'number') {
      return params.value;
    }
    return JSON.stringify(params.value);
  };
  const getJsonToTableApi = async (
    params,
    setDataGridTableArray,
    setcurTempFileName,
    getValueDataApi,
    getColumnTypeApi,
    selectedColumn,
    indexNull,
    setSelectedColumn,
    typeOfSelectButton
  ) => {
    const getCurrentSteps = localStorage.getItem('currentApiStep');
    let typeOfSelect = '';
    let formatename = '';
    if (typeOfSelectButton) {
      typeOfSelect = typeOfSelectButton;
      formatename =
        typeOfSelectButton === 'selectlist'
          ? 'Select list ' + selectedColumn
          : 'Select object ' + selectedColumn;
    } else if (typeof params[selectedColumn] === 'object') {
      if (Array.isArray(params[selectedColumn]) && params[selectedColumn].length > 0) {
        typeOfSelect = 'expandlist';
        formatename = 'Expand list ' + selectedColumn;
      } else {
        typeOfSelect = 'expandobject';
        formatename = 'Expand object ' + selectedColumn;
      }
    }
    if (typeOfSelect !== '') {
      const newStepQuery = [
        ...params.steps[0].stepQuery,
        ...[
          {
            type: typeOfSelect,
            column: selectedColumn,
            index: indexNull,
            formatedName: formatename
          }
        ]
      ];
      const credential = {
        file_name: params.fileName,
        parsing_values: newStepQuery
      };
      const fileExtension = /\.([0-9a-z]+)$/i.exec(params.fileName);
      const authToken = userDataFromLocal();
      setOpenLoader(true);
      let getNextStep;

      if (fileExtension[1] === 'xml') {
        getNextStep = await XmlToDf(credential, authToken.token);
      } else {
        getNextStep = await JsonToDf(credential, authToken.token);
      }

      setOpenLoader(false);
      setSelectedColumn('-');
      displayTableFromJsonData(
        getNextStep,
        setDataGridTableArray,
        setcurTempFileName,
        getValueDataApi,
        getColumnTypeApi,
        getCurrentSteps,
        typeOfSelect,
        newStepQuery
      );
    }
  };

  const displayTableFromJsonData = (
    JsonFileSaveApi,
    setDataGridTableArray,
    setcurTempFileName,
    getValueDataApi,
    getColumnTypeApi,
    currentApiStep,
    typeOfSelect,
    newStepQuery
  ) => {
    localStorage.setItem('currentApiStep', currentApiStep ? currentApiStep : 1);
    setcurTempFileName(JsonFileSaveApi?.file_name);
    let rowIdObj = [];

    const rowArray = [];
    const colArray = [];
    if (
      typeOfSelect === 'expandlist' ||
      typeOfSelect === 'expandobject' ||
      typeOfSelect === 'selectlist' ||
      typeOfSelect === 'selectobject'
    ) {
      JsonFileSaveApi.data.forEach((data, index) => {
        const obj = {
          id: index + 1,
          index: index,
          fileName: JsonFileSaveApi?.file_name,
          ...data
        };
        console.log('obj', obj);
        rowIdObj.push(obj);
      });
      Object.entries(JsonFileSaveApi?.data[0]).map((jsonObj, jsonIndex) => {
        console.log('JsonFileSaveApi', jsonObj);
        const colObj = {
          field: jsonObj[0],
          headerName: jsonObj[0],
          width: 400,
          renderCell: (params) => getValueDataApi(params, null, null, null),
          type: 'string' // kudos
          // type: getColumnTypeApi  // konnect
        };
        console.log('colObj', colObj);
        colArray.push(colObj);
      });
    } else {
      rowIdObj = [
        {
          id: 1,
          index: 0,
          fileName: JsonFileSaveApi?.file_name,
          ...JsonFileSaveApi?.data
        }
      ];
      console.log('rowIdObj', rowIdObj);
      Object.entries(JsonFileSaveApi?.data).map((jsonObj, jsonIndex) => {
        console.log('JsonFileSaveApi', jsonObj);
        const colObj = {
          field: jsonObj[0],
          headerName: jsonObj[0],
          width: 400,
          renderCell: (params) => getValueDataApi(params, null, null, null),
          type: 'string' // kudos
          // type: getColumnTypeApi  // konnect
        };
        const obj = {
          id: jsonIndex + 1,
          name: jsonObj[0],
          value: jsonObj[1],
          index: jsonIndex
        };
        rowArray.push(obj);
        colArray.push(colObj);
        console.log('obj', obj);
        console.log('colObj', colObj);
      });
    }
    console.log('rowArrayrowArray', rowArray);
    const firstStep = [
      {
        name: 'Source',
        data: {},
        step: currentApiStep ? Number(currentApiStep) + 1 : 1,
        stepQuery: newStepQuery ? newStepQuery : []
      }
    ];
    if (currentApiStep) {
      localStorage.setItem('currentApiStep', Number(currentApiStep) + 1);
    }
    console.log('firstStep', firstStep);
    rowIdObj[0].steps = firstStep;
    setDataGridTableArray([{ tableColumns: colArray, tableRows: rowIdObj }]);
    // let queryForSave = [];
    // if (newStepQuery) {
    //   queryForSave = newStepQuery;
    //   queryForSave.forEach((item) => {
    //     delete item.formatedName;
    //     queryForSave.push(item);
    //   });
    // }
    setParsedTableVal(newStepQuery ? newStepQuery : []);
  };

  const createTableFromJsonData = (isParsedKey, jsonApiDataForGrid) => {
    const dataGridArray = [
      {
        tableRows: [],
        tableColums: []
      }
    ];
    const tableRows = [];
    // define  fucntion here
    jsonApiDataForGrid.getProps = function (path, context) {
      let wrapper = '';
      let method = typeof path != 'string' && context ? path : this;
      context = typeof path === 'object' && context === undefined ? path : context || this;

      if (typeof path === 'string') {
        path = path.split('.');

        for (let i = 0; i < path.length; i++) {
          method = method[path[i]];
          if (context === true) context = i === path.length - 2 ? method : context;
        }
      }
      wrapper = function () {
        method.apply(context, arguments);
      };

      return method;
      // return wrapper;
    };

    // loop through an array to create table
    isParsedKey?.forEach((ChildKey, childKeyIndex) => {
      const tableColumns = [
        {
          field: 'id',
          headerName: 'ID',
          hide: true
        }
      ];
      const TableRows = [];
      const makeAnobjectForRow = [{}];
      if (
        Array.isArray(jsonApiDataForGrid.getProps(ChildKey.label)) &&
        jsonApiDataForGrid.getProps(ChildKey.label).length > 0
      ) {
        jsonApiDataForGrid.getProps(ChildKey.label).forEach((item, index) => {
          if (index === 0) {
            if (Object.keys(item).length > 0) {
              Object.keys(item).forEach((object, objIndex) => {
                const obj = {
                  field: object,
                  headerName: object,
                  renderCell: renderCellByParam
                };
                tableColumns.push(obj);
                // push an object at first index in array with name tableColumn -[{},{},{}]
                // when run again push tableColum in second index of array with object and value tableColumn -[{},{},{}]

                // dataGridArray[index] = {
                //   tableColumns: tableColumns.push(obj)
                // };
              });
            }
          }
          // add id to each item
          item.id = index + 1;
          TableRows.push(item);

          // make rows for dara grid
          // set  row for table
          // jsonApiDataForGrid[isParsedKey ? isParsedKey : parsedTableVal].forEach(
          //   (item, index) => (item.id = index + 1)
          // );
          // setGridRowsForTable(jsonApiDataForGrid[isParsedKey ? isParsedKey : parsedTableVal]);
        });

        dataGridArray[childKeyIndex] = {
          tableColumns: tableColumns,
          tableRows: TableRows
        };
      } else if (
        jsonApiDataForGrid.getProps(ChildKey.label).constructor.name === 'Object' &&
        Object.keys(jsonApiDataForGrid.getProps(ChildKey.label)).length > 0
      ) {
        // check if the given is a object or dictonary
        Object.keys(jsonApiDataForGrid.getProps(ChildKey.label)).forEach((ObjItem, index) => {
          if (
            jsonApiDataForGrid.getProps(ChildKey.label)[ObjItem].constructor.name !== 'Object' ||
            Array.isArray(jsonApiDataForGrid.getProps(ChildKey.label)[ObjItem])
          ) {
            const obj = {
              field: ObjItem,
              headerName: ObjItem,
              renderCell: renderCellByParam
            };

            if (obj.field === 'id') {
              tableColumns.splice(0, 1);
            }

            tableColumns.push(obj);

            makeAnobjectForRow[0][ObjItem] = jsonApiDataForGrid.getProps(ChildKey.label)[ObjItem];

            // above code
          } else {
            console.log(' condition 101 ');
          }
        });

        // add row in table
        // jsonApiDataForGrid.getProps(ChildKey.label).id = 1;
        // // add id to each item
        // item.id = index + 1;
        TableRows.push(...makeAnobjectForRow);
        dataGridArray[childKeyIndex] = {
          tableColumns: tableColumns,
          // tableColumns.filter((byid) => byid.field === 'id').length > 1
          //   ? tableColumns.splice(0, 1)
          //   : tableColumns,
          tableRows: TableRows[0].id || TableRows[0].ID ? TableRows : [{ ...TableRows[0], id: 1 }]
        };
      }

      setDataGridTableArray(dataGridArray);
      // setShow(true); // show apply api button
    });
  };
  const handleAddField = () => {
    setAddField([...addField, { hdrKey: '', hdrValue: '' }]);
  };
  const handleRemoveField = (FieldIndex) => {
    const prevField = [...addField];
    prevField.splice(FieldIndex, 1);
    setAddField(prevField);
  };
  // Main body form data field
  const handleAddFieldFormD = () => {
    setAddFieldFormD([...addFieldFormD, { type: 'Text', hdrKey: '', hdrValue: '' }]);
  };
  const handleRemoveFieldFormD = (FieldIndex) => {
    const prevField = [...addFieldFormD];
    prevField.splice(FieldIndex, 1);
    setAddFieldFormD(prevField);
  };
  // Authorization > body > form data fields
  const handleAddFieldAuthBodyFormD = () => {
    setAddFieldAuthBodyFormD([...addFieldAuthBodyFormD, { hdrKey: '', hdrValue: '' }]);
  };
  const handleRemoveFieldAuthBodyFormD = (FieldIndex) => {
    const prevField = [...addFieldAuthBodyFormD];
    prevField.splice(FieldIndex, 1);
    setAddFieldAuthBodyFormD(prevField);
  };
  // Authorization > Header > form data fields
  const handleAddFieldAuthHdrFormD = () => {
    setAddFieldAuthHdrFormD([...addFieldAuthHdrFormD, { hdrKey: '', hdrValue: '' }]);
  };
  const handleRemoveFieldAuthHdrFormD = (FieldIndex) => {
    const prevField = [...addFieldAuthHdrFormD];
    prevField.splice(FieldIndex, 1);
    setAddFieldAuthHdrFormD(prevField);
  };

  const handleParams = () => {
    setOpenDialogParam(true);
  };
  const handleClose = () => {
    setOpenDialogParam(false);
  };
  // Radio group
  const [radioValue, setRadioValue] = useState('raw');
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };
  // Radio group of after execute
  const [radioValue2, setRadioValue2] = useState('json');
  const handleRadioChange2 = (e) => {
    setRadioValue2(e.target.value);
  };
  // Radio group of after execute
  const [radioValueAuthChild, setRadioValueAuthChild] = useState('rawAuth');

  const handleRadioChangeAuthChild = (e) => {
    setRadioValueAuthChild(e.target.value);
  };

  // Radio group of Authorization child body and header
  const [AuthChildRadio, setAuthChildRadio] = useState('childAuth');

  const handleChangeAuthChildRadio = (e, newValue) => {
    // setAuthChildRadio(e.target.value);
    setAuthChildRadio(newValue);
  };

  // Badge styling
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -13,
      top: 7,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '3px',
      fontSize: '9px'
    }
  }));

  // Tabbing const
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // New page field end
  const iconsColor = {
    postgresql: 'rgb(48, 92, 145)',
    mysql: 'rgb(221, 137, 0)',
    MariaDB: 'rgb(186, 114, 87)',
    Oracle: 'rgb(193, 68, 50)',
    MSSQL: 'rgb(182, 30, 35)',
    MongoDB: 'rgb(37, 147, 69)',
    Neo4j: 'rgb(1, 138, 255)',
    Cassandra: '#b5dff3'
  };

  const handleCloseDialogSource = () => {
    setOpenDialogSource(false);
  };

  const getAction = (params) => {
    return (
      <Button
        onClick={async (e) => {
          setOpenDialogSource(true);
          setValue(params.row.method === 'GET' ? 0 : params.row.method === 'POST' ? 1 : 2);
          setMethod(params.row.method);
          setRadioValue(params.row.data.mainBodyData);
          setEnterUrl(params.row.api_url);
          setParsedTableVal(JSON.parse(params.row.key));
          const addFieldObj1 = [];

          Object.keys(configData?.mainBodyData === 'formURLEncoded' && configData?.payload).forEach(
            (keys, index) => {
              if (keys === 'file_path') {
                try {
                  setOpenLoader(true);
                  if (keys === 'file_path') {
                    Object.keys(configData?.payload.file_path).forEach(
                      (filePath, filePathIndex) => {
                        configData?.payload.filekeys?.forEach((file, fileIkeyndex) => {
                          if (file === filePath) {
                            const authToken = userDataFromLocal();
                            (async () => {
                              const formURLEncodedFile_PathApi = await FormURLEncodedFilePath(
                                configData?.payload.file_path[file],
                                authToken.token
                              );
                              formURLEncodedFile_PathApi.name = file;
                              addFieldObj1.push({
                                type: 'File',
                                hdrKey: file,
                                hdrValue: formURLEncodedFile_PathApi
                              });
                            })();
                          }
                        });
                      }
                    );
                  }
                } catch (e) {
                  console.log(e);
                }
              } else if (keys === 'filekeys') {
                console.log('filekeys');
              } else {
                addFieldObj1.push({
                  type: 'Text',
                  hdrKey: keys,
                  hdrValue: configData?.payload[keys]
                });
              }
            }
          );
          setOpenLoader(false);
          if (addFieldObj1.length > 0) {
            setTimeout(() => {
              setAddFieldFormD(addFieldObj1);
              setMtateTable(new Date());
            }, 2000);
          }

          // getJsonDataFriomApi(
          //   params.row.data.mainBodyData,
          //   params.row.method,
          //   params.row.api_url,
          //   params.row.auth,
          //   params.row.payload
          // );
        }}
      >
        Edit
      </Button>
    );
  };

  useEffect(() => {
    /**
     * @type {array} - uniqueSource
     * @description unique category object from Api
     * @description setting uniqueSource to subCatOption State
     */
    const uniqueSource = [...new Map(filterData.map((item) => [item.sub_category, item])).values()];
    setSubCatOptions(uniqueSource);

    // Try to use to svg img delay loader at this page
    setTimeout(() => {
      setHideLoaderIcon(false);
      const PADDING = 0.2; // 20% padding on all sides
      const svgElements = document.querySelectorAll('.fileExtention svg');
      svgElements.forEach((svg) => {
        const bbox = svg.getBBox();
        const padX = PADDING * bbox.width;
        const padY = PADDING * bbox.height;
        const viewbox = [
          bbox.x - padX,
          bbox.y - padY,
          bbox.width + 2 * padX,
          bbox.height + 2 * padY
        ].join(' ');
        svg.setAttribute('viewBox', viewbox);

        // if (refreshComp === null) {
        //   setRefreshComp(new Date());
        // }
      });
    }, 1100);
  }, [dataSourceType, filterData, subCategory, setSubCategory]);

  return (
    <Box display="flex" flexWrap="wrap">
      <Loader openloader={openloader} />
      <ValidationMsgOnly
        openValidation={openValidation}
        validationMsg={validationMsg}
        handleClose={handleCloseParseKey}
        setOpenValidation={setOpenValidation}
      />
      {subCatOptions.map((option, index) => (
        <>
          <Box key={index} className="umgDBPreviewBox">
            {option.sub_category === 'API' ? (
              <>
                <Box
                  mb={1}
                  onClick={(e) => {
                    setSubCategory(option.sub_category);
                    setUploadedFile(null);
                    setConfigData({});
                    setOpenDialogSource(true);
                  }}
                  // p={2}
                  // border={1}
                  // mb={2}
                  // borderRadius={3}
                  // borderColor="#ddd"
                  // bgcolor="#eee"
                  // width={95}
                  // height={95}
                  // mr={2}
                  // display="flex"
                  // alignItems="center"
                  // justifyContent="center"
                >
                  {hideLoaderIcon ? (
                    <CircularProgress style={{ width: '20px', height: '20px' }} />
                  ) : (
                    <RenderIcons
                      icon={getIcon(
                        iconsForDatasourcePage[option.sub_category],
                        iconsColor[option.sub_category],
                        50,
                        50
                      )}
                    />
                  )}
                </Box>
                {''}
              </>
            ) : (
              <Box
                mb={1}
                onClick={(e) => {
                  console.log('tabselectfileCkick', option, e);
                  setSubCategory(option.sub_category);
                  setUploadedFile(null);
                  setConfigData({});
                }}
                // p={2}
                // border={1}
                // mb={2}
                // borderRadius={3}
                // borderColor="#ddd"
                // bgcolor="#eee"
                // width={95}
                // height={95}
                // mr={2}
                // display="flex"
                // alignItems="center"
                // justifyContent="center"
              >
                {hideLoaderIcon ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  <RenderIcons
                    icon={getIcon(
                      iconsForDatasourcePage[option.sub_category],
                      iconsColor[option.sub_category],
                      50,
                      50
                    )}
                  />
                )}
              </Box>
            )}

            <Typography variant="body2">
              <strong>{option.sub_category}</strong>
            </Typography>
          </Box>

          {option.sub_category === 'API' && Object.keys(configData).length > 0 && (
            <Box height={130}>
              <DataGrid
                style={{ width: 750 }}
                columns={[
                  { field: 'id', headerName: 'ID', hideable: true, hide: true },
                  { field: 'api_url', headerName: 'API URL', width: 400 },
                  { field: 'method', headerName: 'Method' },
                  {
                    field: 'key',
                    headerName: 'Parsed KEY'
                  },
                  {
                    field: 'actions',
                    headerName: 'Action',
                    renderCell: getAction
                  }
                ]}
                rows={[
                  {
                    id: 1,
                    api_url: configData.url,
                    method: configData.method,
                    key: JSON.stringify(configData.parsingKey),
                    data: configData
                  }
                ]}
                hideFooter
                hideFooterPagination
                onCellClick={(params, event, details) => {
                  if (event?.target?.parentElement?.dataset?.field === 'key') {
                    setparsedData(JSON.parse(event?.target?.textContent));
                    setOpenParsedKey(true);
                    // setValidationMsg(event?.target?.textContent);
                    // setOpenValidation(true);
                  }
                }}
              />
              <Dialog
                open={openParsedKey}
                onClose={(e) => {
                  setOpenParsedKey(false);
                }}
                fullWidth
              >
                <DialogTitle className="umgpopupTitle">
                  <Typography variant="subtitle1" className="umgpopupSubTitle">
                    Parsed keys
                  </Typography>
                  <IconButton onClick={(e) => setOpenParsedKey(false)} size="small">
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers className="umgpopupInfo">
                  <Stack direction="row" spacing={1}>
                    {parsedData.length > 0 &&
                      parsedData.map((keys, index) => <Chip label={keys.label} />)}
                  </Stack>

                  {/* <Typography variant="body1">Are you sure you want to delete?</Typography> */}
                </DialogContent>
                <DialogActions disableSpacing={false} className="umgpopupAction">
                  <Button onClick={(e) => setOpenParsedKey(false)} className="umgmainBtn">
                    Close
                  </Button>
                  <Button
                    className="umgmainBtn"
                    onClick={(e) => {
                      setOpenParsedKey(false);
                    }}
                  >
                    Done
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </>
      ))}
      <Dialog
        open={openDialogSource}
        onClose={(e) => {
          handleCloseDialogSource();
        }}
        fullScreen
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          {/* <Button autoFocus color="inherit" onClick={handleCloseDialogSource}>
            save
          </Button> */}
          <Button
            // onClick={}
            // variant="outlined"
            // color="primary"
            className="umgmainBtn umgmainBtnSec"
            disabled={!show ? true : false}
            // className="umgmainBtn"
            onClick={(e) => {
              // Alert if any required filed is blank
              if (parsedTableVal === '' || parsedTableVal === '-') {
                enqueueSnackbar('Please select response api key or value', {
                  variant: 'error',
                  autoHideDuration: 2000
                });
                return;
              } else if (jsonViewMode === 'compressed') {
                enqueueSnackbar('Please convert response API into parse', {
                  variant: 'error',
                  autoHideDuration: 2000
                });
                return;
              }
              // Alert if any required filed is blank
              setConfigData({
                mainBodyData: radioValue,
                method: method ? method : '',
                url: enterUrl ? enterUrl : '',
                token: token ? token : '',
                parsingKey: parsedTableVal,
                auth: { Authtype: authType, user: authUser, pwd: authPass },
                payload: bodyRequest ? JSON.parse(bodyRequest) : {}
              });
              handleCloseDialogSource(null);
            }}
          >
            Apply API Source
          </Button>
          <Box>
            <IconButton className="umgactionButton" onClick={handleCloseDialogSource} size="small">
              <MdOutlineClose size={18} />
            </IconButton>
            {/* <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialogSource}
              aria-label="close"
            >
              <Close />
            </IconButton> */}
            {/* <Typography
              sx={{ ml: 1, cursor: 'pointer' }}
              variant="subtitle1"
              component="span"
              onClick={handleCloseDialogSource}
            >
              Close
            </Typography> */}
          </Box>
        </Toolbar>

        <DialogContent>
          <Box className="umgdbField">
            <Box mb={3}>
              <Typography variant="body2" className="umgformLabel">
                Source Name
              </Typography>
              <TextField
                fullWidth
                type="text"
                placeholder="Enter a name for your source"
                inputProps={{ className: 'umgtextInput' }}
                value={DataSetTitle}
                onChange={(e) => {
                  const regx = /[^a-z0-9_ ]/gi;
                  if (regx.test(e.target.value)) {
                    return;
                  }
                  setDataSetTitle(e.target.value);
                }}
                onFocus={(e) => {
                  if (DataSetTitle === 'Untitled') {
                    setDataSetTitle('');
                  }
                }}
                onBlur={(e) => {
                  if (DataSetTitle === '') {
                    setDataSetTitle('Untitled');
                  }
                }}
              />
              <Typography variant="body2" sx={{ fontSize: '11px', color: '#9b9b9b' }} mt={1}>
                Only alphanumeric (a-z, A-s, 0-9) and _(underscore) values are allowed
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" className="umgformLabel">
                Request URL
              </Typography>
              <Paper component="form">
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Box display="flex">
                      <Select
                        // variant="standard"
                        // size="small"
                        // placeholder="Enter a name for your source"
                        // className="gbleinp"
                        sx={{ width: '100px' }}
                        fullWidth
                        inputProps={{
                          className: `umgtextInput umgdissabledMenu${method}`
                        }}
                        value={method}
                        onChange={(e) => setMethod(e.target.value)}
                      >
                        <MenuItem value="POST"> Post</MenuItem>
                        <MenuItem value="GET"> Get</MenuItem>
                        {/* <MenuItem value="DELETE"> Delete</MenuItem>
                      <MenuItem value="put"> Put</MenuItem> */}
                      </Select>
                      <TextField
                        sx={{ width: '400px', marginLeft: '1rem' }}
                        fullWidth
                        // className="gbleinp entrUrl"
                        placeholder="Enter Request Url"
                        inputProps={{ className: 'umgtextInput' }}
                        value={enterUrl}
                        onChange={(e) => setEnterUrl(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button onClick={(e) => handleParams()} className="umgmainBtn umgmainBtnSmall">
                      Params
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Typography variant="body2" mt={1} sx={{ fontSize: '11px', color: '#9b9b9b' }}>
                Start with $ in the url path or query string value to auto complete column names.
                Type $ after/or=
              </Typography>
            </Box>
            {/* Dialog for params */}
            <Dialog
              open={openDialogParam}
              onClose={(e) => {
                handleClose();
              }}
              fullWidth
            >
              <DialogTitle className="umgpopupTitle">
                <Typography variant="subtitle1" className="">
                  Params
                </Typography>
                <IconButton onClick={(e) => handleClose()} size="small">
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers className="umgpopupInfo">
                {/* <Typography mb={1}>Params</Typography> */}
                <TextField
                  fullWidth
                  type="text"
                  // variant="standard"
                  // size="small"
                  placeholder="Enter Parameter"
                  // className="gbleinp"
                  inputProps={{ className: 'umgtextInput' }}
                  value={credParams}
                  onChange={(e) => {
                    setCredParams(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions disableSpacing={false} className="umgpopupAction">
                <Button
                  onClick={(e) => handleClose()}
                  // variant="outlined"
                  // color="primary"
                  className="umgmainBtn"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {/* Dialog for params */}

            <Box className="umggbleTabs umgeditorTab">
              <Grid container spacing={2} mb={2}>
                <Grid item xs="auto">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                    className="umgtabOuter"
                    classes={{ indicator: 'umgtabIndicator' }}
                    // centered
                    // classes={{
                    //   indicator: 'activemark'
                    // }}
                    // sx={{ borderBottom: 1, borderColor: 'divider' }}
                  >
                    <Tab
                      label="Authorization"
                      classes={{ selected: 'umgtabSelected', root: 'umgtabDefault' }}
                    />
                    <Tab
                      label={<StyledBadge variant="dot">Body</StyledBadge>}
                      classes={{ selected: 'umgtabSelected', root: 'umgtabDefault' }}
                    />
                    <Tab
                      label={
                        <StyledBadge color="secondary" badgeContent={1}>
                          Headers
                        </StyledBadge>
                      }
                      classes={{ selected: 'umgtabSelected', root: 'umgtabDefault' }}
                    />
                  </Tabs>
                </Grid>
                <Grid item xs style={{ textAlign: 'right' }}>
                  <Button
                    // onClick={}
                    // variant="outlined"
                    // color="primary"
                    className="umgmainBtn umgmainBtnSmall"
                    onClick={(e) => {
                      if (DataSetTitle === 'Untitled' || DataSetTitle === '') {
                        enqueueSnackbar('Source name should not be blank', {
                          variant: 'error',
                          autoHideDuration: 2000
                        });
                        return;
                      } else if (enterUrl === '') {
                        enqueueSnackbar('Request url should not be blank', {
                          variant: 'error',
                          autoHideDuration: 2000
                        });
                        return;
                      }
                      getJsonDataFriomApi();
                      setJsonViewMode('compressed');
                      setParsedTableVal('-');
                    }}
                  >
                    Execute
                  </Button>
                </Grid>
              </Grid>

              <Box className="umgviewLeadCard">
                <TabPanel value={value} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="umgformLabel" variant="body2">
                        Authorization Type
                      </Typography>
                      <Select
                        fullWidth
                        // placeholder="No Auth"
                        // className="gbleinp"
                        // value={authorization}
                        value={authType}
                        inputProps={{
                          className: `umgtextInput umgdissabledMenu${authType}`
                        }}
                        // onChange={(e) => setAuthorization(e.target.value)}
                        onChange={(e) => {
                          setAuthType(e.target.value);
                          if (e.target.value === 'basicAuth') {
                            setToken('');
                          } else if (e.target.value === 'jwtAuth') {
                            setAuthUser('');
                            setAuthPass('');
                          }
                        }}
                        // style={{ minWidth: '300px' }}
                      >
                        <MenuItem value=" " disabled>
                          Select Authorization Type
                        </MenuItem>
                        <MenuItem value="noAuth"> No Auth</MenuItem>
                        <MenuItem value="basicAuth"> Basic Authentication</MenuItem>
                        <MenuItem value="jwtAuth"> JWT Authentication</MenuItem>
                        <MenuItem value="jwtAuthToken"> JWT Get Token Authentication</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  {authType === 'basicAuth' ? (
                    <Box className="umginnerBoxBg" mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="umgformLabel">
                            User
                          </Typography>
                          <TextField
                            fullWidth
                            type="text"
                            // variant="standard"
                            // size="small"
                            placeholder="Enter User Name"
                            inputProps={{ className: 'umgtextInput' }}
                            value={authUser}
                            onChange={(e) => {
                              setAuthUser(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="umgformLabel">
                            Password
                          </Typography>
                          <TextField
                            fullWidth
                            type="text"
                            inputProps={{ className: 'umgtextInput' }}
                            placeholder="Enter Password"
                            value={authPass}
                            onChange={(e) => {
                              setAuthPass(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : authType === 'jwtAuth' ? (
                    <Box className="umginnerBoxBg" mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography className="umgformLabel" variant="body2">
                            Token Authentication
                          </Typography>
                          <TextField
                            fullWidth
                            type="text"
                            inputProps={{ className: 'umgtextInput' }}
                            placeholder="Enter Authentication"
                            value={token}
                            onChange={(e) => {
                              setToken(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : authType === 'jwtAuthToken' ? (
                    <Box className="umginnerBoxBg" mt={2}>
                      <Typography variant="body2" className="umgformLabel">
                        Request URL
                      </Typography>
                      <Box display="flex" component="form" mb={2}>
                        <Select
                          sx={{ width: '100px' }}
                          fullWidth
                          inputProps={{
                            className: `umgtextInput umgdissabledMenu${methodAuth}`
                          }}
                          value={methodAuth}
                          onChange={(e) => setMethodAuth(e.target.value)}
                        >
                          <MenuItem value="POST"> Post</MenuItem>
                          <MenuItem value="GET"> Get</MenuItem>
                        </Select>
                        <TextField
                          sx={{ width: '400px', marginLeft: '1rem' }}
                          fullWidth
                          placeholder="API URL to Get Token"
                          inputProps={{ className: 'umgtextInput' }}
                          value={authTokenUrl}
                          onChange={(e) => {
                            setAuthTokenUrl(e.target.value);
                          }}
                        />
                      </Box>
                      {/* <Typography>
                              <Select
                                variant="standard"
                                size="small"
                                placeholder="Enter a name for your source."
                                className="gbleinp"
                                value={methodAuth}
                                onChange={(e) => setMethodAuth(e.target.value)}
                                style={{ minWidth: '80px', paddingLeft: '10px' }}
                              >
                                <MenuItem value="POST"> Post</MenuItem>
                                <MenuItem value="GET"> Get</MenuItem>
                              </Select>
                            </Typography>
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              className="gbleinp entrUrl"
                              placeholder="API URL to get token"
                              inputProps={{ 'aria-label': 'search google maps' }}
                              value={authTokenUrl}
                              onChange={(e) => {
                                setAuthTokenUrl(e.target.value);
                              }}
                            /> */}

                      {/* <Typography mb={1}>API URL to get token </Typography>
                              <Typography>
                                <TextField
                                  fullWidth
                                  type="text"
                                  variant="standard"
                                  size="small"
                                  placeholder="Paste URL here"
                                  className="gbleinp"
                                  value={authTokenUrl}
                                  onChange={(e) => {
                                    setAuthTokenUrl(e.target.value);
                                  }}
                                />
                              </Typography> */}
                      {/* Tabbing to authorization body form data and header */}
                      <Box className="umggbleTabs umgeditorTab2" mb={2}>
                        <Tabs
                          value={AuthChildRadio}
                          onChange={handleChangeAuthChildRadio}
                          aria-label="tabs"
                          className="umgtabOuter"
                          classes={{ indicator: 'umgtabIndicator' }}
                        >
                          <Tab
                            value="childAuth"
                            label="Authorization"
                            classes={{ selected: 'umgtabSelected', root: 'umgtabDefault' }}
                          />
                          <Tab
                            label="Body"
                            value="bodyAuth"
                            classes={{ selected: 'umgtabSelected', root: 'umgtabDefault' }}
                          />
                          <Tab
                            label="Headers"
                            value="headerAuth"
                            classes={{ selected: 'umgtabSelected', root: 'umgtabDefault' }}
                          />
                        </Tabs>
                        {/* <Tabs
                            size="small"
                            value={AuthChildRadio}
                            onChange={handleChangeAuthChildRadio}
                            aria-label="tabs"
                            className="gbleTabs"
                            centered
                            classes={{
                              indicator: 'activemark'
                            }}
                          >
                            <Tab
                              label="Authorization"
                              value="childAuth"
                              classes={{
                                selected: 'TabSelected',
                                root: 'TabDefault'
                              }}
                              style={{ padding: '6px 15px' }}
                            />
                            <Tab
                              label="Body"
                              value="bodyAuth"
                              classes={{
                                selected: 'TabSelected',
                                root: 'TabDefault'
                              }}
                              style={{ padding: '6px 15px' }}
                            />
                            <Tab
                              label="Headers"
                              value="headerAuth"
                              classes={{
                                selected: 'TabSelected',
                                root: 'TabDefault'
                              }}
                              style={{ padding: '6px 15px' }}
                            />
                          </Tabs> */}
                      </Box>
                      {/* <FormControl sx={{ width: '100%', alignItems: 'center' }}>
                              <RadioGroup
                                row
                                aria-labelledby="1"
                                name="controlled-radio-buttons-group2"
                                value={AuthChildRadio}
                                // onClick={(e)=> set}
                                onChange={(e) => handleChangeAuthChildRadio(e)}
                              >
                                <FormControlLabel
                                  size="small"
                                  value="childAuth"
                                  control={<Radio />}
                                  label="Authorization"
                                />
                                <FormControlLabel
                                  size="small"
                                  value="bodyAuth"
                                  control={<Radio />}
                                  label="Body"
                                />
                                <FormControlLabel
                                  size="small"
                                  value="headerAuth"
                                  control={<Radio />}
                                  label="Header"
                                />
                              </RadioGroup>
                            </FormControl> */}
                      <Box
                        mb={2}
                        sx={{ background: '#fff', borderRadius: '0.5rem', padding: '1rem' }}
                      >
                        {AuthChildRadio === 'childAuth' ? (
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                              <Typography variant="body2" className="umgformLabel">
                                Authorization Type
                              </Typography>
                              <Select
                                inputProps={{
                                  className: `umgtextInput umgdissabledMenu${authTypeChild}`
                                }}
                                fullWidth
                                value={authTypeChild}
                                onChange={(e) => {
                                  setAuthTypeChild(e.target.value);
                                }}
                              >
                                <MenuItem value=" " disabled>
                                  Select Authorization Type
                                </MenuItem>
                                <MenuItem value="noAuth">No Auth</MenuItem>
                                <MenuItem value="basicAuth">Basic Authentication</MenuItem>
                              </Select>
                            </Grid>
                          </Grid>
                        ) : AuthChildRadio === 'bodyAuth' ? (
                          <>
                            <Box mb={1}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  size="small"
                                  aria-labelledby="1"
                                  name="controlled-radio-buttons-group3"
                                  value={radioValueAuthChild}
                                  // onClick={(e)=> set}
                                  onChange={(e) => handleRadioChangeAuthChild(e)}
                                >
                                  <FormControlLabel
                                    size="small"
                                    value="rawAuth"
                                    control={<Radio />}
                                    label="Raw"
                                  />
                                  <FormControlLabel
                                    size="small"
                                    value="formData"
                                    control={<Radio />}
                                    label="formData"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            {radioValueAuthChild === 'rawAuth' ? (
                              <Box>
                                <Typography variant="body2" mb={2}>
                                  Content Type (JSON)
                                </Typography>
                                <TextField
                                  fullWidth
                                  multiline
                                  placeholder="Request Body"
                                  rows={7}
                                  InputProps={{
                                    classes: { root: 'umgtextareaInput' }
                                  }}
                                  value={AuthbodyRequest}
                                  onChange={(e) => {
                                    setAuthbodyRequest(e.target.value);
                                  }}
                                />
                              </Box>
                            ) : radioValueAuthChild === 'formData' ? (
                              <Box>
                                <Typography mb={2} variant="body2">
                                  Content Type (Form-Data)
                                </Typography>
                                {addFieldAuthBodyFormD.map((FieldItem, FieldIndex) => {
                                  return (
                                    <Grid container spacing={3} mb={2} alignItems="end">
                                      <Grid item xs>
                                        <Typography variant="body2" className="umgformLabel">
                                          Key
                                        </Typography>
                                        <TextField
                                          fullWidth
                                          type="text"
                                          placeholder="Key Input"
                                          value={FieldItem.hdrKey}
                                          onChange={(e) => {
                                            const prevField = [...addFieldAuthBodyFormD];
                                            prevField[FieldIndex].hdrKey = e.target.value;
                                            setAddFieldAuthBodyFormD(prevField);
                                          }}
                                          inputProps={{ className: 'umgtextInput' }}
                                        />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant="body2" className="umgformLabel">
                                          Value
                                        </Typography>
                                        <TextField
                                          fullWidth
                                          type="text"
                                          placeholder="Value Input"
                                          value={FieldItem.hdrValue}
                                          // onChange={(e) => setHdrKeyValue(e.target.value)}
                                          onChange={(e) => {
                                            const prevField = [...addFieldAuthBodyFormD];
                                            prevField[FieldIndex].hdrValue = e.target.value;
                                            setAddFieldAuthBodyFormD(prevField);
                                          }}
                                          inputProps={{ className: 'umgtextInput' }}
                                        />
                                      </Grid>
                                      <Grid xs="auto" item>
                                        {FieldIndex === 0 ? (
                                          <Tooltip
                                            title="Add Header"
                                            placement="top"
                                            arrow
                                            sx={{ marginBottom: '4px' }}
                                          >
                                            <IconButton
                                              className="umgsquareIconButton"
                                              disableRipple="true"
                                              disableFocusRipple="true"
                                              onClick={(e) => handleAddFieldAuthBodyFormD()}
                                            >
                                              <MdOutlineAdd size={18} />
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title="Remove Header"
                                            placement="top"
                                            arrow
                                            sx={{ marginBottom: '4px' }}
                                          >
                                            <IconButton
                                              className="umgsquareIconButton"
                                              disableRipple="true"
                                              disableFocusRipple="true"
                                              onClick={(e) =>
                                                handleRemoveFieldAuthBodyFormD(FieldIndex)
                                              }
                                            >
                                              <AiOutlineMinus size={18} />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Box>
                            ) : (
                              ''
                            )}
                          </>
                        ) : AuthChildRadio === 'headerAuth' ? (
                          <Box>
                            <Typography mb={2} variant="body2">
                              Content Type
                            </Typography>
                            {addFieldAuthHdrFormD.map((FieldItem, FieldIndex) => {
                              return (
                                <Grid container spacing={3} mb={2} alignItems="end">
                                  <Grid item xs>
                                    <Typography className="umgformLabel" variant="body2">
                                      Key
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      placeholder="Key Input"
                                      inputProps={{ className: 'umgtextInput' }}
                                      value={FieldItem.hdrKey}
                                      onChange={(e) => {
                                        const prevField = [...addFieldAuthHdrFormD];
                                        prevField[FieldIndex].hdrKey = e.target.value;
                                        setAddFieldAuthHdrFormD(prevField);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography className="umgformLabel" variant="body2">
                                      Value
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      placeholder="Value Input"
                                      inputProps={{ className: 'umgtextInput' }}
                                      value={FieldItem.hdrValue}
                                      onChange={(e) => {
                                        const prevField = [...addFieldAuthHdrFormD];
                                        prevField[FieldIndex].hdrValue = e.target.value;
                                        setAddFieldAuthHdrFormD(prevField);
                                      }}
                                    />
                                  </Grid>
                                  <Grid xs="auto" item>
                                    {FieldIndex === 0 ? (
                                      <Tooltip
                                        title="Add Header"
                                        placement="top"
                                        arrow
                                        sx={{ marginBottom: '4px' }}
                                      >
                                        <IconButton
                                          className="umgsquareIconButton"
                                          disableRipple="true"
                                          disableFocusRipple="true"
                                          onClick={(e) => handleAddFieldAuthHdrFormD()}
                                        >
                                          <MdOutlineAdd size={18} />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Remove Header"
                                        placement="top"
                                        arrow
                                        sx={{ marginBottom: '4px' }}
                                      >
                                        <IconButton
                                          className="umgsquareIconButton"
                                          disableRipple="true"
                                          disableFocusRipple="true"
                                          onClick={(e) => handleRemoveFieldAuthHdrFormD(FieldIndex)}
                                        >
                                          <AiOutlineMinus size={18} />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>

                      {/* Tabbing to authorization body form data and header end */}
                      <Box textAlign="right">
                        <Button
                          className="umgmainBtn"
                          onClick={(e) => {
                            const AuthKeyAndValue = {};
                            addFieldAuthBodyFormD.forEach((item1, index) => {
                              AuthKeyAndValue[item1?.hdrKey] = item1?.hdrValue;
                            });
                            getJsonDataChildApi(
                              methodAuth,
                              authTokenUrl,
                              AuthbodyRequest,
                              AuthKeyAndValue
                            );
                            // if (DataSetTitle === 'Untitled' || DataSetTitle === '') {
                            //   enqueueSnackbar('Source name should not be blank', {
                            //     variant: 'error',
                            //     autoHideDuration: 2000
                            //   });
                            //   return;
                            // } else if (enterUrl === '') {
                            //   enqueueSnackbar('Request url should not be blank', {
                            //     variant: 'error',
                            //     autoHideDuration: 2000
                            //   });
                            //   return;
                            // }

                            // // Form-Data into body
                            // const formData = new FormData();
                            // addFieldAuthBodyFormD.map((item1, index) =>
                            //   formData.append(item1?.hdrKey, item1?.hdrValue)
                            // );

                            // // header dynamic Body
                            // const mainHdrBody = {
                            //   method: methodAuth,
                            //   [radioValueAuthChild !== 'formData' && 'headers']: {
                            //     'Content-Type': 'application/json'
                            //   },
                            //   body:
                            //     radioValueAuthChild === 'rawAuth'
                            //       ? AuthbodyRequest
                            //       : radioValueAuthChild === 'formData'
                            //       ? formData
                            //       : ''
                            // };
                            // // header dynamic main header
                            // const hdrObjt = {};
                            // addFieldAuthHdrFormD.map((item, index) => {
                            //   hdrObjt[item?.hdrKey] = item?.hdrValue;
                            // });
                            // // console.log('hdrObjt', hdrObjt);
                            // const mainHdrKey = {
                            //   method: methodAuth,
                            //   [AuthChildRadio === 'headerAuth' && 'headers']: hdrObjt,
                            //   body: radioValueAuthChild === 'rawAuth' ? AuthbodyRequest : ''
                            // };
                            // // APi fetch start from here
                            // await fetch(
                            //   // authTokenUrl,
                            //   'http://216.48.180.65:10000/apicall',
                            //   AuthChildRadio === 'bodyAuth'
                            //     ? mainHdrBody
                            //     : AuthChildRadio === 'headerAuth'
                            //     ? mainHdrKey
                            //     : ''
                            // )
                            //   .then((response) => {
                            //     if (response.ok) {
                            //       return response.json();
                            //     }
                            //     return {
                            //       status: 'error',
                            //       msg: response.statusText,
                            //       data: []
                            //     };
                            //   })
                            //   .then((result) => {
                            //     console.log('result', result);
                            //     if (result.status === 'success') {
                            //       console.log('result', result.status);
                            //       setShowToken(!showToken);
                            //       setResultToken(result.data);
                            //     }
                            //   })
                            //   .catch((error) => {
                            //     console.log(error);
                            //     return {
                            //       status: 'error',
                            //       msg: 'Server not responding',
                            //       data: []
                            //     };
                            //   });
                          }}
                          textAlign="center"
                        >
                          Get Token
                        </Button>
                      </Box>
                      {showToken ? (
                        <Box sx={{ padding: '1rem', background: '#fff', borderRadius: '0.5rem' }}>
                          <Box mb={2}>
                            <Typography variant="body2" className="umgformLabel">
                              Token authentication
                            </Typography>
                            <Select
                              fullWidth
                              inputProps={{
                                className: `umgtextInput umgdissabledMenu${tokenKeySelect}`
                              }}
                              value={tokenKeySelect}
                              onChange={(e) => {
                                setTokenKeySelect(e.target.value);
                              }}
                            >
                              <MenuItem value=" " disabled>
                                Choose Key
                              </MenuItem>

                              {Object.keys(resultToken).length > 0 &&
                                Object.keys(resultToken).map((keyItem, KeyIndex) => {
                                  console.log('keyItem', keyItem, resultToken[keyItem]);
                                  return (
                                    <MenuItem value={resultToken[keyItem]}> {keyItem}</MenuItem>
                                  );
                                })}
                            </Select>
                          </Box>
                          <Box>
                            <Typography className="umgformLabel" variant="body2">
                              Response Token
                            </Typography>
                            <TextField
                              fullWidth
                              multiline
                              // placeholder="Copy and paste your token"
                              rows={5}
                              InputProps={{
                                classes: { root: 'umgtextareaInput' },
                                readOnly: true
                              }}
                              value={JSON.stringify(resultToken)}
                            />
                          </Box>
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  ) : (
                    ''
                  )}
                </TabPanel>
                <TabPanel value={value} index={1} className="umggbletabInfo">
                  <Box mb={2}>
                    <FormControl>
                      <FormLabel id="1" />
                      <RadioGroup
                        row
                        aria-labelledby="1"
                        name="controlled-radio-buttons-group"
                        value={radioValue}
                        // onClick={(e)=> set}
                        onChange={(e) => handleRadioChange(e)}
                      >
                        <FormControlLabel
                          size="small"
                          value="raw"
                          control={<Radio />}
                          label="Raw"
                        />
                        <FormControlLabel
                          size="small"
                          value="formURLEncoded"
                          control={<Radio />}
                          label="FormURLEncoded"
                        />
                        {/* <FormControlLabel
                          size="small"
                          value="sources"
                          control={<Radio />}
                          label="Sources"
                        /> */}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {radioValue === 'raw' ? (
                    <Box>
                      <Box mb={2}>
                        <Typography className="umgformLabel" variant="body2">
                          Content Type
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: `umgtextInput umgdissabledMenu${tokenKeySelect}`
                          }}
                          placeholder="JSON (application/json)"
                          value={bodyType}
                          onChange={(e) => setBodyType(e.target.value)}
                        >
                          <MenuItem value=" "> JSON (application)</MenuItem>
                          <MenuItem value="auth"> XML (application)</MenuItem>
                        </Select>
                      </Box>
                      <Box>
                        <Typography
                          className="umgformLabel"
                          variant="body2"
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          Request Body{' '}
                          <Tooltip
                            title={
                              <Box p={1.5}>
                                <Typography variant="subtitle2" mb={0.5}>
                                  Lorem ipsum dummy text?
                                </Typography>
                                <Typography variant="body2">Suggested text here</Typography>
                              </Box>
                            }
                            placement="right"
                            arrow
                          >
                            <InfoOutlined sx={{ ml: 1, cursor: 'pointer', fontSize: '14px' }} />
                          </Tooltip>
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          placeholder="Request Body"
                          rows={7}
                          InputProps={{
                            classes: { root: 'umgtextareaInput' }
                          }}
                          value={bodyRequest}
                          onChange={(e) => {
                            setBodyRequest(e.target.value);
                          }}
                        />
                      </Box>
                      {/* <Box p={3} textAlign="center">
                        <Button
                          // onClick={}
                          variant="outlined"
                          color="primary"
                          // className="umgmainBtn"
                          onClick={(e) => {
                            getJsonDataFriomApi();
                            setJsonViewMode('compressed');
                            setParsedTableVal('-');
                          }}
                          textAlign="center"
                        >
                          Execute
                        </Button>
                      </Box> */}
                    </Box>
                  ) : radioValue === 'formURLEncoded' ? (
                    <Box>
                      <Typography mb={2} variant="body2">
                        Content Type (Form-Data)
                      </Typography>
                      {addFieldFormD.map((FieldItem, FieldIndex) => {
                        console.log('FieldItem', FieldItem);
                        return (
                          <Grid container spacing={3} mb={2} alignItems="flex-end">
                            <Grid item xs>
                              <Typography variant="body2" className="umgformLabel">
                                Type
                              </Typography>
                              <Select
                                fullWidth
                                placeholder="JSON (application/json)"
                                value={FieldItem.type}
                                inputProps={{
                                  className: `umgtextInput umgdissabledMenu${FieldItem.type}`
                                }}
                                onChange={(e) => {
                                  const prevData = [...addFieldFormD];
                                  prevData[FieldIndex].type = e.target.value;
                                  setAddFieldFormD(prevData);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Select Type
                                </MenuItem>
                                <MenuItem value="Text">Text</MenuItem>
                                <MenuItem value="File">File</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs>
                              <Typography variant="body2" className="umgformLabel">
                                Key
                              </Typography>
                              <TextField
                                fullWidth
                                type="text"
                                placeholder="Key Input"
                                inputProps={{ className: 'umgtextInput' }}
                                value={FieldItem.hdrKey}
                                onChange={(e) => {
                                  const prevField = [...addFieldFormD];
                                  prevField[FieldIndex].hdrKey = e.target.value;
                                  setAddFieldFormD(prevField);
                                }}
                              />
                            </Grid>
                            {FieldItem.type === 'File' ? (
                              <Grid item xs="auto" sx={{ alignSelf: 'flex-end' }}>
                                <Button
                                  className="umgmainBtn umgotherBtn"
                                  component="label"
                                  sx={{ marginBottom: '3px' }}
                                >
                                  {/* {uploadFileName} */}
                                  {addFieldFormD[FieldIndex].hdrValue !== ''
                                    ? addFieldFormD[FieldIndex].hdrValue.name
                                    : 'Upload File'}

                                  <input
                                    hidden
                                    // accept="*"
                                    multiple
                                    type="file"
                                    onChange={(e) => {
                                      console.log('filetype', e.target.files);
                                      const file = e.target.files[0];
                                      // setUploadFileName(e.target.files[0].name);
                                      const prevField = [...addFieldFormD];
                                      prevField[FieldIndex].hdrValue = file;
                                      setAddFieldFormD(prevField);
                                    }}
                                  />
                                </Button>
                              </Grid>
                            ) : (
                              <Grid item xs>
                                <Typography variant="body2" className="umgformLabel">
                                  Value
                                </Typography>

                                <TextField
                                  fullWidth
                                  type="text"
                                  inputProps={{ className: 'umgtextInput' }}
                                  placeholder="Value Input"
                                  value={FieldItem.hdrValue}
                                  // onChange={(e) => setHdrKeyValue(e.target.value)}
                                  onChange={(e) => {
                                    const prevField = [...addFieldFormD];
                                    prevField[FieldIndex].hdrValue = e.target.value;
                                    setAddFieldFormD(prevField);
                                  }}
                                />
                              </Grid>
                            )}

                            <Grid xs="auto" item>
                              {FieldIndex === 0 ? (
                                <Tooltip
                                  title="Add Header"
                                  placement="top"
                                  arrow
                                  sx={{ marginBottom: '4px' }}
                                >
                                  <IconButton
                                    className="umgsquareIconButton"
                                    disableRipple="true"
                                    disableFocusRipple="true"
                                    onClick={(e) => handleAddFieldFormD()}
                                  >
                                    <MdOutlineAdd size={18} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Remove Header"
                                  placement="top"
                                  arrow
                                  sx={{ marginBottom: '4px' }}
                                >
                                  <IconButton
                                    className="umgsquareIconButton"
                                    disableRipple="true"
                                    disableFocusRipple="true"
                                    onClick={(e) => handleRemoveFieldFormD(FieldIndex)}
                                  >
                                    <AiOutlineMinus size={18} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Box>
                  ) : (
                    ''
                  )}
                </TabPanel>
                <TabPanel value={value} index={2} className="umggbletabInfo">
                  {addField.map((FieldItem, FieldIndex) => {
                    console.log();
                    return (
                      <Grid container spacing={3} mb={2} alignItems="flex-end">
                        <Grid item xs>
                          <Typography variant="body2" className="umgformLabel">
                            Key
                          </Typography>
                          <TextField
                            fullWidth
                            type="text"
                            placeholder="Content-Type"
                            inputProps={{ className: 'umgtextInput' }}
                            value={FieldItem.hdrKey}
                            // onChange={(e) => setHdrCredKey(e.target.value)}
                            onChange={(e) => {
                              const prevField = [...addField];
                              prevField[FieldIndex].hdrKey = e.target.value;
                              setAddField(prevField);
                            }}
                          />
                          {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={hdrCredKeyOpt}
                            className="gbleinp"
                            size="small"
                            multiple={true}
                            limitTags={2}
                            value={FieldItem.hdrkey ? FieldItem.hdrkey : []}
                            // defaultValue={item.user ? item.user : []}
                            onChange={(e, value) => {
                              const allFieldData = [...addField];
                              allFieldData[FieldIndex].hdrkey = value;
                              setHdrCredKey(allFieldData);
                            }}
                            // inputValue={item.user ? item.user : []}
                            onInputChange={(e, newInputValue) => {
                              // console.log('newInputValue', newInputValue);
                            }}
                            renderInput={(params) => {
                              params.inputProps.className = `${params.inputProps.className} sourceHdrKeyInp`;
                              return <TextField {...params} fullWidth placeholder="Content-Type" />;
                            }}
                          /> */}
                        </Grid>
                        <Grid item xs>
                          <Typography
                            variant="body2"
                            className="umgformLabel"
                            display="flex"
                            alignItems="center"
                          >
                            Value
                            <Tooltip
                              title={
                                <Box p={2}>
                                  <Typography variant="subtitle2" mb={0.5}>
                                    Don’t see your primary key?
                                  </Typography>
                                  <Typography variant="body2">
                                    Only string, GUID, and integer data types are supported.
                                  </Typography>
                                </Box>
                              }
                              placement="right"
                              arrow
                            >
                              <InfoOutlined sx={{ ml: 1, cursor: 'pointer', fontSize: '14px' }} />
                            </Tooltip>
                          </Typography>
                          <TextField
                            fullWidth
                            type="text"
                            placeholder="Application/json"
                            inputProps={{ className: 'umgtextInput' }}
                            value={FieldItem.hdrValue}
                            // onChange={(e) => setHdrKeyValue(e.target.value)}
                            onChange={(e) => {
                              const prevField = [...addField];
                              prevField[FieldIndex].hdrValue = e.target.value;
                              setAddField(prevField);
                            }}
                          />
                        </Grid>
                        <Grid xs="auto" item>
                          {FieldIndex === 0 ? (
                            <Tooltip
                              title="Add Header"
                              placement="top"
                              arrow
                              sx={{ marginBottom: '4px' }}
                            >
                              <IconButton
                                className="umgsquareIconButton"
                                disableRipple="true"
                                disableFocusRipple="true"
                                onClick={(e) => handleAddField()}
                              >
                                <MdOutlineAdd size={18} />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Remove Header"
                              placement="top"
                              arrow
                              sx={{ marginBottom: '4px' }}
                            >
                              <IconButton
                                className="umgsquareIconButton"
                                disableRipple="true"
                                disableFocusRipple="true"
                                onClick={(e) => handleRemoveField(FieldIndex)}
                              >
                                <AiOutlineMinus size={18} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </TabPanel>
              </Box>

              {/* After excute below code will show  */}
              {show ? (
                <>
                  <Box mt={5} textAlign="center" justifyContent="center">
                    <Link
                      component="button"
                      variant="body2"
                      className="umglinkTxt"
                      onClick={() => {
                        setJsonViewMode('compressed');
                        // const prevdata = jsonApiData;
                        // setJsonApiData(JSON.stringify(prevdata));
                      }}
                    >
                      Compress JSON
                    </Link>
                    <Link
                      component="button"
                      variant="body2"
                      className="umglinkTxt"
                      onClick={() => {
                        setJsonViewMode('prettify');
                        // const prevdata = jsonApiData;
                        // setJsonApiData(JSON.parse(prevdata));
                      }}
                    >
                      Prettify JSON
                    </Link>
                    <Link
                      component="button"
                      variant="body2"
                      className="umglinkTxt"
                      // onClick={() => {
                      //   console.info("I'm a button.");
                      // }}
                    >
                      Validate JSON
                    </Link>
                  </Box>
                  <Box>
                    <Typography mb={1}>Response type</Typography>
                    <Box mb={2}>
                      <FormControl>
                        <FormLabel id="2" />
                        <RadioGroup
                          row
                          aria-labelledby="2"
                          name="controlled-radio-buttons-group"
                          value={radioValue2}
                          onChange={(e) => handleRadioChange2(e)}
                        >
                          <FormControlLabel
                            size="small"
                            value="json"
                            control={<Radio />}
                            label="JSON"
                          />
                          <FormControlLabel
                            size="small"
                            value="xml"
                            control={<Radio />}
                            label="XML"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {radioValue2 === 'json' ? (
                      <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box mr={3}>
                            <Typography>Sample json response of api</Typography>
                          </Box>
                          <Box width={700}>
                            {!tableErrosMsg && dataGridTableArray.length > 0 ? (
                              <Box
                                className="umgjsonToolHolder umgholderApi"
                                display="flex"
                                alignItems="center"
                                p={2}
                              >
                                {dataGridTableArray[0].tableColumns.length > 0 && (
                                  <>
                                    <Select
                                      value={selectedColumn}
                                      classes={{
                                        select: 'umgtoolSelectBox',
                                        root: 'umgtoolSelectBoxRoot'
                                      }}
                                      style={{ width: '600' }}
                                      onChange={(e) => {
                                        setSelectedColumn(e.target.value);
                                        console.log(
                                          'abrakadabra',
                                          typeof dataGridTableArray[0].tableColumns[e.target.value]
                                        );
                                        if (
                                          typeof dataGridTableArray[0].tableColumns[0][
                                            e.target.value
                                          ] === 'object'
                                        ) {
                                          if (
                                            Array.isArray(
                                              dataGridTableArray[0].tableColumns[0][e.target.value]
                                            )
                                          ) {
                                            setSelectedArea('expandlist');
                                          } else {
                                            setSelectedArea('expandobject');
                                          }
                                        } else {
                                          setSelectedArea(null);
                                        }
                                      }}
                                    >
                                      <MenuItem value="-" disabled>
                                        Select column
                                      </MenuItem>
                                      {dataGridTableArray[0].tableColumns.map((item) => (
                                        <MenuItem value={item.field}>{item.headerName}</MenuItem>
                                      ))}
                                    </Select>
                                    <Box
                                      onClick={(e) =>
                                        getJsonToTableApi(
                                          dataGridTableArray[0].tableRows[0],
                                          setDataGridTableArray,
                                          setcurTempFileName,
                                          getValueDataApi,
                                          getColumnTypeApi,
                                          selectedColumn,
                                          'null',
                                          setSelectedColumn
                                        )
                                      }
                                      className={getIconClassname(
                                        dataGridTableArray,
                                        selectedColumn
                                      )}
                                      // className={getClassNameByData(
                                      //   [...allStepsAnddata, ...allStepsNaviagation],
                                      //   currentStep,
                                      //   jsonDataForTable,
                                      //   selectedColumn,
                                      //   setisExpendedArray,
                                      //   setExpendedArray,
                                      //   setExpendedObject
                                      // )}
                                    >
                                      <MdOutlineExpand />
                                    </Box>
                                  </>
                                )}
                              </Box>
                            ) : (
                              ''
                            )}
                          </Box>
                        </Box>
                        <Typography mt={1}>
                          {jsonViewMode === 'compressed' ? (
                            <TextField
                              fullWidth
                              multiline
                              rows={20}
                              InputProps={{
                                classes: { root: 'umgtextEditor' }
                              }}
                              value={jsonApiData}
                            />
                          ) : jsonViewMode === 'prettify' ? (
                            <Box className="umgshowJsonPrettified">
                              <ReactJson src={jsonApiDataForGrid?.data} theme="ashes" />
                            </Box>
                          ) : (
                            <Box
                              className="umgshowJsonPrettified"
                              display="flex"
                              alignItems="flex-start"
                            >
                              {!tableErrosMsg && dataGridTableArray.length > 0 ? (
                                <>
                                  {' '}
                                  {dataGridTableArray.map((table, index) => {
                                    const localStep = localStorage.getItem('currentApiStep');
                                    const prevStep = [];
                                    console.log('Number(localStep)', table);
                                    // tableColumns: tableColumns,
                                    // tableRows: TableRows
                                    return (
                                      <>
                                        <DataGrid
                                          rows={table.tableRows}
                                          columns={table.tableColumns}
                                          style={{ color: '#fff' }}
                                          hideFooter
                                          hideFooterPagination
                                          hideFooterSelectedRowCount
                                          key={index}
                                          sx={{ marginBottom: '10px' }}
                                        />
                                        <Box className="umgpropertyHolder umgapiPropertyHolder">
                                          <Typography className="umgpropertTitle">
                                            Applied steps
                                          </Typography>
                                          <Box p={1}>
                                            <Box
                                              className={
                                                Number(1) === Number(localStep)
                                                  ? 'umgstepsOnApi umgactiveStep'
                                                  : 'umgstepsOnApi'
                                              }
                                              onClick={() => {
                                                getJsonDataFriomApi();
                                              }}
                                            >
                                              Source
                                            </Box>
                                            {table.tableRows.length > 0 &&
                                              table.tableRows[0].steps &&
                                              table.tableRows[0].steps[0].stepQuery.map(
                                                (query, qIndex) => {
                                                  console.log('currentApiStep -', qIndex + 2);
                                                  console.log('currentApiStep +', localStep);
                                                  const currentStep = qIndex + 2;
                                                  prevStep.push(query);
                                                  console.log('prevStepprevStep', query);
                                                  return (
                                                    <Box
                                                      className={
                                                        Number(currentStep) === Number(localStep)
                                                          ? 'umgstepsOnApi umgactiveStep'
                                                          : 'umgstepsOnApi'
                                                      }
                                                      key={qIndex}
                                                      onClick={async () => {
                                                        const prevTabledataArr = dataGridTableArray;
                                                        prevTabledataArr[0].tableRows[0].steps[0].stepQuery.length =
                                                          qIndex + 1;
                                                        const credential = {
                                                          file_name: curTempFileName,
                                                          parsing_values:
                                                            prevTabledataArr[0].tableRows[0]
                                                              .steps[0].stepQuery
                                                        };
                                                        const fileExtension =
                                                          /\.([0-9a-z]+)$/i.exec(curTempFileName);
                                                        const authToken = userDataFromLocal();

                                                        setOpenLoader(true);

                                                        let getNextStep;

                                                        if (fileExtension[1] === 'xml') {
                                                          getNextStep = await XmlToDf(
                                                            credential,
                                                            authToken.token
                                                          );
                                                        } else {
                                                          getNextStep = await JsonToDf(
                                                            credential,
                                                            authToken.token
                                                          );
                                                        }

                                                        setOpenLoader(false);
                                                        setSelectedColumn('-');
                                                        displayTableFromJsonData(
                                                          getNextStep,
                                                          setDataGridTableArray,
                                                          setcurTempFileName,
                                                          getValueDataApi,
                                                          getColumnTypeApi,
                                                          qIndex + 1,
                                                          prevTabledataArr[0].tableRows[0].steps[0]
                                                            .stepQuery[
                                                            prevTabledataArr[0].tableRows[0]
                                                              .steps[0].stepQuery.length - 1
                                                          ].type,
                                                          prevTabledataArr[0].tableRows[0].steps[0]
                                                            .stepQuery
                                                        );
                                                      }}
                                                    >{`${query.formatedName} `}</Box>
                                                  );
                                                }
                                              )}
                                          </Box>
                                        </Box>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                'error'
                              )}
                            </Box>
                          )}
                        </Typography>
                      </Box>
                    ) : radioValue2 === 'xml' ? (
                      <Box mb={3}>
                        <Box display="flex">
                          <Box>
                            <Typography mb={1}>Sample xml response of api</Typography>
                          </Box>
                          {/* <Box>Slect</Box>
                          <Box>Button</Box> */}
                        </Box>
                        <Typography>
                          <TextField
                            fullWidth
                            multiline
                            rows={20}
                            InputProps={{
                              classes: { root: 'umgtextEditor' }
                            }}
                            // value={}
                            // onChange={(e) => {
                            //   aaa(e.target.value);
                            // }}
                          />
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </>
              ) : (
                ''
              )}
              {/* After excute below code will show  end responseApi const set */}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
// Tabbing component
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
// Tabbing component

// Authorization Type option
const hdrCredKeyOpt = [
  { label: 'Content-Type' },
  { label: 'Content-Type2' },
  { label: 'Content-Type3' }
];
// Used to API data after clicking the dbSelect API

/**
 * @type {function} - Allfields
 * @param {object} dataSourceType- Input object
 * @param {object} subCategory- Input object
 * @param {object} fieldData- Input object
 * @param {object} setUploadedFile- Input object
 * @param {object} setConfigData- Input object
 * @param {object} configData- Input object
 * @return {React.ReactElement} - React component
 */
function Allfields({
  dataSourceType,
  subCategory,
  fieldData,
  setUploadedFile,
  setConfigData,
  configData,
  pathFileName,
  setTestEnableOnChange,
  setConfigFileData,
  setFileChanged,
  setIsTested,
  isTested,
  setApiLoader
}) {
  const [curFileName, setcurFileName] = useState('');
  const [openCustomJsonEditor, setCustomJsonEditor] = useState(null);
  const [jsonDataForTable, setJsonDataForTable] = useState([]);
  const [allStepsAnddata, setAllStepsAndData] = useState([]);
  const [allStepsNaviagation, setAllStepsNaviagation] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [expandMenuOpen, setExpandMenuOpen] = useState(null);
  const isExpandMenuOpen = Boolean(expandMenuOpen);
  const [tempselectedData, setTempSelectedData] = useState([]);
  const [refreshThisComp, setRefreshThisComp] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState('-');
  const [SelectedArea, setSelectedArea] = useState(null);
  const [expendedArray, setExpendedArray] = useState([]);
  const [expendedObject, setExpendedObject] = useState([]);
  const [isExpendedArray, setisExpendedArray] = useState(null);
  const [jsonFileSaveApi, setJsonFileSaveApi] = useState(null);
  const [stepsQuery, setStepsQuery] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // Upload excel

  const [openChoosSheet, setOpenChoosSheet] = useState(null);
  const [allSheetsData, setAllSheetsdata] = useState({});
  const [currentSheets, setCurrentSheets] = useState('');
  const [delimetretextFile, setDelimetretextFile] = useState('');

  const getValueData = (params, allStepsAnddata, currentStep, setCurrentStep) => {
    if (typeof params?.value === 'object') {
      if (Array.isArray(params?.value)) {
        if (params?.value.length > 0) {
          return (
            <Button
              onClick={(e) => {
                createRowsByArraydata(
                  params,
                  allStepsAnddata,
                  'selectlist',
                  currentStep,
                  setCurrentStep
                );
              }}
              className="umgumgmainBtn"
            >
              List
            </Button>
          );
        }
        return 'No value found';
      } else if (params?.value) {
        if (Object.keys(params?.value) && Object.keys(params?.value).length > 0) {
          return (
            <Button
              onClick={(e) =>
                createRowsByArraydata(
                  params,
                  allStepsAnddata,
                  'selectobject',
                  currentStep,
                  setCurrentStep
                )
              }
              className="umgmainBtn"
            >
              Records
            </Button>
          );
        }
        return 'No value found';
        //   }
        // }
      }

      return 'No value found';
    } else {
      return String(params?.value);
    }
  };
  const getColumnType = (params) => {
    return 'String';
  };
  const [gridColumns, setGridColumns] = useState([]);

  const isCustomJsonEditor = Boolean(openCustomJsonEditor);

  const createRowsByArraydata = async (
    params,
    allStepsAnddata,
    typeOfSelect,
    currentStep,
    setCurrentStep
  ) => {
    // Reset selected column
    setSelectedColumn('-');

    // Get current step from local storage
    const currentStepFromLocalStorage = localStorage.getItem('currentStep');

    // Check if params.row exists
    if (!params.row) return;

    // Create new step query based on current step and selected field
    const newStepQuery = [
      ...params.row.steps[currentStepFromLocalStorage - 1].stepQuery,
      { type: typeOfSelect, column: params.field, index: params.row.index }
    ];

    // Prepare credential for API call
    const credential = {
      file_name: params.row.fileName,
      parsing_values: newStepQuery
    };

    // Extract file extension and authentication token
    const fileExtension = /\.([0-9a-z]+)$/i.exec(params.row.fileName);
    const authToken = userDataFromLocal();

    let getNextStep;

    // Call respective API based on file extension
    if (fileExtension[1] === 'xml') {
      getNextStep = await XmlToDf(credential, authToken.token);
    } else {
      getNextStep = await JsonToDf(credential, authToken.token);
    }

    console.log('getNextStep', getNextStep);

    // Check if API call was successful
    if (getNextStep.status !== 'Success') return;

    // setStepsQuery(newStepQuery);
    const prevSteps = params.row.steps;
    const stepNumber = prevSteps.length + 1;
    setCurrentStep(stepNumber);
    localStorage.setItem('currentStep', Number(currentStepFromLocalStorage) + 1);

    // Ensure each row has a unique id
    const getNextStepDataWithIds = getNextStep.data.map((item, index) => ({
      ...item,
      id: index + 1 // You can adjust how you generate unique ids here
    }));

    console.log('getNextStepDataWithIds', getNextStepDataWithIds);

    // Handle 'selectlist' type
    if (typeOfSelect === 'selectlist') {
      // Create columns for data grid
      getNextStep.data.forEach((item, index) => {
        item.id = index + 1;
        item.index = index;
        item.fileName = getNextStep.file_name ? getNextStep.file_name : '';
      });

      const columns = [];
      Object.keys(getNextStep.data[0]).forEach((columKey) => {
        if (columKey !== 'fileName' && columKey !== 'index') {
          const obj = {
            field: columKey,
            headerName: columKey,
            width: 230,
            renderCell: (params) =>
              getValueData(params, allStepsAnddata, currentStep, setCurrentStep),
            type: 'string'
            // type: getColumnType
          };
          columns.push(obj);
        }
      });

      console.log('columns', columns);

      // Prepare next step data
      const nextStep = {
        name: `Select ${params.field}`,
        data: {
          dataGridHeader: columns,
          dataGridRow: getNextStep.data
        },
        step: stepNumber,
        stepQuery: newStepQuery
      };

      console.log('nextStep', nextStep);

      // Update previous steps and data
      prevSteps.push(nextStep);
      getNextStep.data.forEach((item, itemIndex) => {
        item.steps = prevSteps;
      });
      setAllStepsAndData(prevSteps);
      setJsonDataForTable(getNextStep.data);
      setGridColumns(columns);
    } else {
      const rowIdObj = [
        {
          id: 1,
          index: 0,
          fileName: getNextStep.file_name,
          ...getNextStep?.data[0]
        }
      ];
      setJsonFileSaveApi(getNextStep?.data);

      const rowArray = [];
      const colArray = [];
      Object.entries(getNextStep?.data[0])?.map((jsonObj, jsonIndex) => {
        const colObj = {
          field: jsonObj[0],
          headerName: jsonObj[0],
          width: 400,
          renderCell: (params) =>
            getValueData(params, allStepsAnddata, currentStep, setCurrentStep),
          type: 'string'
          // type: getColumnType
        };

        const obj = {
          id: jsonIndex + 1,
          name: jsonObj[0],
          value: jsonObj[1],
          index: jsonIndex
        };
        rowArray.push(obj);
        colArray.push(colObj);
      });
      const nextStep = {
        name: `Select ${params.field}`,
        data: {
          dataGridHeader: colArray,
          dataGridRow: rowIdObj
        },
        step: stepNumber,
        stepQuery: newStepQuery
      };

      prevSteps.push(nextStep);
      getNextStep.data.forEach((item, itemIndex) => {
        item.steps = prevSteps;
      });
      rowIdObj[0].steps = prevSteps;
      setAllStepsAndData(prevSteps);
      setJsonDataForTable(rowIdObj);
      setGridColumns(colArray);
      setRefreshThisComp(new Date());
    }
  };

  const getExpandTableFromdata = async (
    jsonDataForTable,
    setJsonDataForTable,
    gridColumns,
    setGridColumns,
    selectedColumn,
    getValueData,
    allStepsNaviagation,
    setAllStepsNaviagation,
    setCurrentStep,
    setSelectedColumn,
    SelectedArea,
    getColumnType,
    setAllStepsAndData
  ) => {
    const getCurrentSteps = localStorage.getItem('currentStep');
    const newStepQuery = [
      ...jsonDataForTable[0].steps[getCurrentSteps - 1].stepQuery, // need to be dynamic
      ...[{ type: SelectedArea, column: selectedColumn, index: 'null' }]
    ];

    const credential = {
      file_name: jsonDataForTable[0].fileName, // need to be dynamic
      parsing_values: newStepQuery
    };

    const fileExtension = /\.([0-9a-z]+)$/i.exec(jsonDataForTable[0].fileName);
    const authToken = userDataFromLocal();
    let getNextStep;

    if (fileExtension[1] === 'xml') {
      getNextStep = await XmlToDf(credential, authToken.token);
    } else {
      console.log('fourth DF');
      getNextStep = await JsonToDf(credential, authToken.token);
    }
    if (getNextStep.status === 'Success') {
      localStorage.setItem('currentStep', Number(getCurrentSteps) + 1);
      setCurrentStep(Number(getCurrentSteps) + 1);
      setSelectedColumn('-');
      const prevSteps = jsonDataForTable[0].steps;
      if (SelectedArea === 'expandobject') {
        const columnArray = [];
        Object.keys(getNextStep.data[0]).map((key) => {
          const colObj = {
            field: key,
            headerName: key,
            width: 200,
            renderCell: (params) =>
              getValueData(params, allStepsAnddata, currentStep, setCurrentStep),
            type: getColumnType
          };
          columnArray.push(colObj);
        });
        setGridColumns(columnArray);
        const rowIdObj = [];
        getNextStep?.data.forEach((item, index) => {
          const obj = {
            id: index + 1,
            index: index,
            fileName: getNextStep.file_name,
            ...item
          };
          rowIdObj.push(obj);
        });
        const nextStep = {
          name: `Expand ${selectedColumn}`,
          data: {
            dataGridHeader: columnArray,
            dataGridRow: rowIdObj
          },
          step: Number(getCurrentSteps) + 1,
          stepQuery: newStepQuery
        };
        prevSteps.push(nextStep);
        rowIdObj[rowIdObj.length - 1].steps = prevSteps;
        setAllStepsAndData(prevSteps);
        setJsonDataForTable(rowIdObj);
      } else {
        getNextStep.data.forEach((item, index) => {
          item.id = index + 1;
          item.index = index;
          item.fileName = getNextStep.file_name;
        });
        // const columns = [
        //   {
        //     field: Object.keys(getNextStep.data[0])[0],
        //     headerName: Object.keys(getNextStep.data[0])[0],
        //     width: 230,
        //     renderCell: (params) =>
        //       getValueData(params, allStepsAnddata, currentStep, setCurrentStep),
        //     type: getColumnType
        //   }
        // ];
        const nextStep = {
          name: `Expand ${selectedColumn}`,
          data: {
            dataGridHeader: gridColumns,
            dataGridRow: getNextStep.data
          },
          step: Number(getCurrentSteps) + 1,
          stepQuery: newStepQuery
        };
        prevSteps.push(nextStep);
        setAllStepsAndData(prevSteps);
        getNextStep.data[0].steps = prevSteps;
        setJsonDataForTable(getNextStep.data);
      }
    }
  };

  const createTableFromdata = (
    jsonDataForTable,
    setJsonDataForTable,
    gridColumns,
    setGridColumns,
    selectedColumn,
    getValueData,
    allStepsNaviagation,
    setAllStepsNaviagation,
    setCurrentStep,
    setSelectedColumn
  ) => {
    const isStringOrNumber = [];
    const isObjectData = [];
    const isArrayData = [];
    if (selectedColumn !== '-') {
      jsonDataForTable.forEach((columnVal) => {
        if (
          typeof columnVal[selectedColumn] !== 'string' &&
          typeof jsonDataForTable[0][selectedColumn] !== 'number'
        ) {
          if (Array.isArray(columnVal[selectedColumn])) {
            isArrayData.push(columnVal[selectedColumn]);
          } else {
            isObjectData.push(columnVal[selectedColumn]);
          }
        } else {
          isStringOrNumber.push(columnVal[selectedColumn]);
        }
      });
      if (isObjectData.length === jsonDataForTable.length) {
        const newColumns = gridColumns.filter((item) => item.field !== selectedColumn);
        const newRows = jsonDataForTable.map((item) => {
          delete item[selectedColumn];
          return item;
        });
        isObjectData.forEach((childItem, childindex) => {
          const newColumName = Object.keys(childItem);
          newColumName.forEach((column, colIndex) => {
            const colObj = { field: column, headerName: column };
            if (
              typeof Object.values(childItem)[colIndex] !== 'string' &&
              typeof Object.values(childItem)[colIndex] !== 'number'
            ) {
              colObj.renderCell = getValueData;
            }

            newColumns.push(colObj);
          });
          newRows[childindex] = Object.assign(newRows[childindex], childItem);
        });
        const uniqColArray = uniqWith(newColumns, isEqual);
        setGridColumns(uniqColArray);
        setJsonDataForTable(newRows);
        const prevStepsdata = allStepsNaviagation;
        const nextStepcount = allStepsNaviagation.length + 2;
        const nextStep = {
          name: `View ${selectedColumn}`,
          data: { dataGridHeader: uniqColArray, dataGridRow: newRows },
          step: nextStepcount
        };
        prevStepsdata.push(nextStep);
        setAllStepsNaviagation(prevStepsdata);
        setCurrentStep(nextStepcount);
        setSelectedColumn('-');
      }
      if (isArrayData.length === jsonDataForTable.length) {
        const columnArray = [];
        const rowsArray = [];
        let uniqColArray = [];
        jsonDataForTable.forEach((mainArray) => {
          isArrayData.forEach((childArray) => {
            Object.keys(mainArray).forEach((item, index) => {
              const colObj = { field: item, headerName: item };
              if (
                typeof Object.values(mainArray)[index] !== 'string' &&
                typeof Object.values(mainArray)[index] !== 'number'
              ) {
                colObj.renderCell = getValueData;
              }
              columnArray.push(colObj);
            });
            uniqColArray = uniqWith(columnArray, isEqual);
            childArray.forEach((childItem, childIndex) => {
              const childObj = {};
              uniqColArray.forEach((colItem) => {
                if (colItem.field === selectedColumn) {
                  childObj[colItem.field] = childItem;
                } else if (colItem.field === 'id') {
                  childObj[colItem.field] = childIndex + 1;
                } else {
                  childObj[colItem.field] = mainArray[colItem.field];
                }
              });
              rowsArray.push(childObj);
            });
          });
        });
        setGridColumns(uniqColArray);
        setJsonDataForTable(rowsArray);
        const prevStepsdata = allStepsNaviagation;
        const nextStepcount = allStepsNaviagation.length + 2;
        const nextStep = {
          name: `View ${selectedColumn}`,
          data: { dataGridHeader: columnArray, dataGridRow: rowsArray },
          step: nextStepcount
        };
        prevStepsdata.push(nextStep);
        setAllStepsNaviagation(prevStepsdata);
        setCurrentStep(nextStepcount);
        setSelectedColumn('-');
      }
    }
  };

  // console.log('onClick', tempselectedData);
  // if (tempselectedData.value && tempselectedData.value.length) {
  //   const createtableColumn = tempselectedData.value[0];
  //   console.log('onClick', tempselectedData);
  //   const gridColumnsArr = [
  //     { field: 'id', headerName: 'ID', hide: true },
  //     { field: 'name', headerName: 'Name' },
  //     { field: 'value', headerName: 'Value', width: 400, renderCell: getValueData }
  //   ];
  //   if (Array.isArray(createtableColumn)) {
  //     console.log('123');
  //   } else {
  //     Object.keys(createtableColumn).map((objKey, keyIndex) => {
  //       const colObj = {
  //         field: objKey,
  //         headerName: objKey,
  //         width: 400,
  //         renderCell: getValueData
  //       };
  //       gridColumnsArr.push(colObj);
  //     });
  //   }
  //   console.log('onClick', gridColumnsArr);
  //   setGridColumns(gridColumnsArr);
  //   setRefreshThisComp(new Date());
  // }

  // [
  //   { type: 'expandobject', column: 'data', index: 'null' },
  //   { type: 'expandobject', column: 'data.result', index: 'null' },
  //   { type: 'expandlist', column: 'data.result.filingStatus', index: 'null' }
  // ];

  /**
   *
   * @param {event} e
   * @param {parameter} param
   * @param {string} files
   */

  const handleFileUpload = async (e, param, files, uploadFileType) => {
    let file = '';
    if (files) {
      file = files;
    } else {
      file = e.target.files[0];
    }
    setcurFileName(file.name);
    setUploadedFile(file);
    if (uploadFileType === 'json') {
      // fire an api to upload a file
      (async () => {
        const authToken = userDataFromLocal();
        const formData = new FormData();
        formData.append('file', file);
        const JsonFileSaveApi = await JsonFileSave(formData, authToken.token);
        if (JsonFileSaveApi.status === 'Success') {
          localStorage.setItem('currentStep', 1);
          setcurFileName(JsonFileSaveApi?.data.file_name);
          const rowIdObj = [
            {
              id: 1,
              index: 0,
              fileName: JsonFileSaveApi?.data.file_name,
              ...JsonFileSaveApi?.data?.first_level_key[0]
            }
          ];
          setJsonFileSaveApi(JsonFileSaveApi?.data);

          const rowArray = [];
          const colArray = [];
          Object.entries(JsonFileSaveApi?.data?.first_level_key[0]).map((jsonObj, jsonIndex) => {
            const colObj = {
              field: jsonObj[0],
              headerName: jsonObj[0],
              width: 400,
              renderCell: (params) =>
                getValueData(params, allStepsAnddata, currentStep, setCurrentStep),
              type: 'string'
              // type: getColumnType
            };
            const obj = {
              id: jsonIndex + 1,
              name: jsonObj[0],
              value: jsonObj[1],
              index: jsonIndex
            };
            rowArray.push(obj);
            colArray.push(colObj);
          });
          const firstStep = [
            {
              name: 'Source',
              data: {
                dataGridHeader: colArray,
                dataGridRow: rowIdObj
              },
              step: 1,
              stepQuery: []
            }
          ];
          rowIdObj[0].steps = firstStep;
          setAllStepsAndData(firstStep);
          setJsonDataForTable(rowIdObj);
          setGridColumns([...gridColumns, ...colArray]);
          setRefreshThisComp(new Date());
        }
      })();
      setCustomJsonEditor(e.target);
    }
    if (uploadFileType === 'xml') {
      // fire an api to upload a file
      (async () => {
        const authToken = userDataFromLocal();
        const formData = new FormData();
        formData.append('file', file);
        const JsonFileSaveApi = await XmlFileSave(formData, authToken.token);
        if (JsonFileSaveApi.status === 'Success') {
          localStorage.setItem('currentStep', 1);
          setcurFileName(JsonFileSaveApi?.data.file_name);
          const rowIdObj = [
            {
              id: 1,
              index: 0,
              fileName: JsonFileSaveApi?.data.file_name,
              ...JsonFileSaveApi?.data?.first_level_key[0]
            }
          ];
          setJsonFileSaveApi(JsonFileSaveApi?.data);

          const rowArray = [];
          const colArray = [];
          Object.entries(JsonFileSaveApi?.data?.first_level_key[0]).map((jsonObj, jsonIndex) => {
            const colObj = {
              field: jsonObj[0],
              headerName: jsonObj[0],
              width: 400,
              renderCell: (params) =>
                getValueData(params, allStepsAnddata, currentStep, setCurrentStep),
              type: 'string'
            };
            const obj = {
              id: jsonIndex + 1,
              name: jsonObj[0],
              value: jsonObj[1],
              index: jsonIndex
            };
            rowArray.push(obj);
            colArray.push(colObj);
          });
          const firstStep = [
            {
              name: 'Source',
              data: {
                dataGridHeader: colArray,
                dataGridRow: rowIdObj
              },
              step: 1,
              stepQuery: []
            }
          ];
          rowIdObj[0].steps = firstStep;
          setAllStepsAndData(firstStep);
          setJsonDataForTable(rowIdObj);
          setGridColumns([...gridColumns, ...colArray]);
          setRefreshThisComp(new Date());
        }
      })();
      setCustomJsonEditor(e.target);
    }
    if (uploadFileType === 'excel') {
      // fire an api to upload a file
      (async () => {
        const authToken = userDataFromLocal();
        const formData = new FormData();
        formData.append('file', file);
        setApiLoader(true);
        const excelSheetsnames = await ExcelSheetName(formData, authToken.token);
        if (excelSheetsnames.status === 'Success') {
          setAllSheetsdata(excelSheetsnames.data);
          setApiLoader(false);
          setTimeout(() => {
            setOpenChoosSheet(true);
          }, 100);
        } else {
          setApiLoader(false);
          enqueueSnackbar(excelSheetsnames.message, {
            variant: 'error',
            autoHideDuration: 2000
          });
        }
      })();
    }

    // if (uploadFileType === 'text') {
    //   setdelimeterInput(true);
    // }
    // Reset input tag
    const fileInput = document.getElementById('contained-button-file');
    fileInput.value = '';
  };

  const handleConfigFileUpload = (e, topmostKey, fieldName) => {
    const file = e.target.files[0];
    document.getElementById(fieldName).innerHTML = file.name;
    setConfigFileData((prevConfigFileData) => ({
      ...prevConfigFileData,
      [topmostKey]: {
        ...prevConfigFileData[topmostKey],
        [fieldName]: file
      }
    }));
    setFileChanged('File Changed');
    setTestEnableOnChange('File Changed');

    setIsTested(false);
  };
  /**
   *
   * @param {event} e
   * @param {*} param
   */
  const fileDrop = (e, param) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileUpload(e, param, files[0]);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const uploadFileClick = (e) => {
    const browseField = document.getElementById('contained-button-file');
    browseField.click();
  };

  const uploadFileClickConfigFiles = (index) => {
    const browseField = document.getElementById('contained-button-file' + index);
    browseField.click();
  };
  useEffect(() => {
    setcurFileName(pathFileName);
  }, [dataSourceType, subCategory, fieldData, pathFileName, refreshThisComp]);

  return (
    <Box>
      <Grid container spacing={3}>
        {fieldData
          .filter(
            (item) => item.data_source_type === dataSourceType && item.sub_category === subCategory
          )
          .map((fields, index) => {
            console.log('fieldsfields', fields);
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2" className="umgformLabel">
                    {dataSourceType === 'File' ? 'Upload ' + subCategory + ' ' : ''}
                    {fields.input_parameters}*
                  </Typography>
                  {fields.input_type === 'userInput' ? (
                    <TextField
                      fullWidth
                      placeholder=""
                      type={fields.input_parameters.includes('Password') ? 'password' : 'text'}
                      inputProps={{ className: 'umgtextInput' }}
                      value={configData[fields.input_from_ui]}
                      onChange={(e) => {
                        setIsTested(false);
                        setConfigData({
                          ...configData,
                          [fields.input_from_ui]: e.target.value
                        });
                        setTestEnableOnChange(new Date());
                      }}
                    />
                  ) : fields.input_type === 'fileUpload' ? (
                    <Box className="umguploadBox umguploadBoxSec">
                      <input
                        accept={
                          fields.sub_category === 'json'
                            ? 'application/JSON'
                            : '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        }
                        className="umgfileInput"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            removeSpace(fields.input_from_ui),
                            null,
                            fields.sub_category
                          )
                        }
                      />
                      <label htmlFor="contained-button-file">
                        <Box
                          variant="contained"
                          color="primary"
                          component="span"
                          onDragOver={dragOver}
                          onDragEnter={dragEnter}
                          onDragLeave={dragLeave}
                          onDrop={(e) => fileDrop(e, removeSpace(fields.input_from_ui))}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <BiCloudUpload className="umgupladIcon" />
                          <Typography variant="body2" mb={1}>
                            Drag and drop File or
                          </Typography>
                          <Typography className="umgglobalDragDropButton">
                            <Button onClick={uploadFileClick} className="umgmainBtn umgotherBtn">
                              Browser
                            </Button>
                          </Typography>
                          {/* <Typography
                            variant="body1"
                            className="globalDragDropSubFileHeading"
                            style={{ fontSize: 12, marginTop: '10px' }}
                          >
                            Maximum upload file size: 1GB
                          </Typography> */}
                          <Typography className="" mt={1}>
                            {curFileName}
                          </Typography>
                        </Box>
                      </label>
                      {/* <FormHelperText error={fileNameErr}>
                              {fileNameHelp}
                            </FormHelperText> */}
                    </Box>
                  ) : fields.input_type === 'configFileUpload' ? (
                    <Box className="umguploadBox umguploadBoxSec">
                      <input
                        accept={'.' + fields.validation.split('.')[1]}
                        className="umgfileInput"
                        id={'contained-button-file' + index}
                        type="file"
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const fileName = file.name;
                          if (fileName === fields.validation) {
                            handleConfigFileUpload(e, fields.sub_category, fields.input_from_ui);
                          } else {
                            enqueueSnackbar(
                              'Please select correct file named as ' + fields.validation,
                              {
                                variant: 'error',
                                autoHideDuration: 4000
                              }
                            );
                            enqueueSnackbar(
                              'Please select correct file named as ' + fields.validation,
                              {
                                variant: 'error',
                                autoHideDuration: 4000
                              }
                            );
                          }
                        }}
                      />

                      <label htmlFor="contained-button-file">
                        <Box
                          variant="contained"
                          color="primary"
                          component="span"
                          onDragOver={dragOver}
                          onDragEnter={dragEnter}
                          onDragLeave={dragLeave}
                          onDrop={(e) => fileDrop(e, removeSpace(fields.input_from_ui))}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <BiCloudUpload className="umgupladIcon" />
                          <Typography variant="body2" mb={1}>
                            Drag and drop File or
                          </Typography>
                          <Button
                            onClick={() => uploadFileClickConfigFiles(index)}
                            className="umgmainBtn umgotherBtn"
                          >
                            Browser
                          </Button>
                          <div style={{ margin: '3px' }} id={fields.input_from_ui}>
                            {' '}
                            {configData?.[fields.input_from_ui] &&
                              configData[fields.input_from_ui].split('/').pop()}{' '}
                          </div>
                        </Box>
                      </label>
                      {/* <FormHelperText error={fileNameErr}>
                            {fileNameHelp}
                          </FormHelperText> */}
                    </Box>
                  ) : (
                    ''
                  )}
                  {fields.sub_category === 'text' ? (
                    <Box mt={2} display="flex" alignItems="center" position="relative">
                      <TextField
                        fullWidth
                        inputProps={{ className: 'umgtextInput' }}
                        value={delimetretextFile}
                        onChange={(e) => {
                          setDelimetretextFile(e.target.value);
                        }}
                        placeholder="Add delimiter"
                      />
                      <Button
                        sx={{ position: 'absolute', right: '0' }}
                        onClick={(e) => {
                          setConfigData({ delimiter: delimetretextFile });
                        }}
                        className="umgmainBtn umgotherBtn"
                      >
                        Apply
                      </Button>
                    </Box>
                  ) : (
                    ''
                  )}
                </Grid>
              </React.Fragment>
            );
          })}

        <Dialog open={openChoosSheet} textAlign="center" onClose={() => setOpenChoosSheet(null)}>
          <DialogTitle id="alert-dialog-title" className="umgpopupTitle">
            <Typography variant="subtitle1" className="umgpopupSubTitle">
              Choose Sheet
            </Typography>
          </DialogTitle>
          <DialogContent id="alert-dialog-description" dividers className="umgpopupInfo">
            {/* // new json to table from api  */}
            <Box display="flex">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '& > *': {
                    m: 1
                  }
                }}
              >
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {Object.values(allSheetsData).length > 0 &&
                    Object.values(allSheetsData).map((item) => {
                      return (
                        <Button
                          onClick={() => setCurrentSheets(item)}
                          variant={item === currentSheets ? 'contained' : 'outlined'}
                        >
                          {item}
                        </Button>
                      );
                    })}
                </ButtonGroup>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions disableSpacing={false} className="umgpopupAction">
            <Button
              onClick={(e) => {
                setOpenChoosSheet(null);
              }}
              variant="outlined"
              color="primary"
              className="umgmainBtn"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                setOpenChoosSheet(null);
                setConfigData({ sheet_name: currentSheets });
              }}
              variant="outlined"
              color="primary"
              className="umgmainBtn"
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isCustomJsonEditor} textAlign="center" fullScreen>
          <DialogTitle id="alert-dialog-title" className="umgpopupTitle">
            <Typography variant="subtitle1" className="umgpopupSubTitle">
              Json/XML to Table
            </Typography>
          </DialogTitle>
          <DialogContent id="alert-dialog-description" dividers className="umgpopupInfo">
            {/* // new json to table from api  */}
            <Box display="flex">
              {/* <Box height={'81vh'} className="dataGridHolder">
              <DataGrid
                columns={gridColumns}
                rows={jsonDataForTable}
                getRowClassName={(params) =>
                  `newClass-${Number(params.row.id) % 2 === 0 ? 'even' : 'odd'}`
                }
                rowHeight={27}
                headerHeight={27}
                hideFooterPagination={true}
                classes={{ row: 'gridRows', cell: 'gridCell', columnHeaders: 'columnHeaders' }}
                components={{
                  ColumnMenu: CustomColumnMenuComponent
                }}
              />
            </Box> */}
              {/* <Box className="propertyHolder">
              <Typography className="propertTitle">Applied steps</Typography>
              <Box>
                {(allStepsAnddata.length > 0 || allStepsNaviagation.length > 0) &&
                  [...allStepsAnddata, ...allStepsNaviagation].map((steps, stepsIndex) => (
                    <Box
                      key={steps.step}
                      onClick={() => {
                        setCurrentStep(steps.step);
                        setGridColumns(steps.data.dataGridHeader);
                        setJsonDataForTable(steps.data.dataGridRow);
                        console.log('step onclick', steps);
                      }}
                      className={`stepsrows step-${stepsIndex === 0 ? 'first' : 'others'} step-${
                        stepsIndex % 2 === 0 ? 'even' : 'odd'
                      } step-${currentStep === steps.step ? 'active' : ''} ${stepsIndex}`}
                    >
                      {steps.name}
                    </Box>
                  ))}
              </Box>
            </Box> */}
            </Box>
          </DialogContent>
          <DialogContent id="alert-dialog-description" dividers className="umgpopupInfo">
            <Box className="umgjsonToolBar">
              <Box
                onClick={(e) => {
                  setCurrentStep(currentStep - 1);
                  setGridColumns(
                    [...allStepsAnddata, ...allStepsNaviagation][currentStep - 2].data
                      .dataGridHeader
                  );
                  setJsonDataForTable(
                    [...allStepsAnddata, ...allStepsNaviagation][currentStep - 2].data.dataGridRow
                  );
                }}
                className={
                  currentStep === 1 ? 'umgjsonToolHolder umgnoActive' : 'umgjsonToolHolder'
                }
              >
                <GrLinkPrevious />
              </Box>
              <Box
                onClick={(e) => {
                  setCurrentStep(currentStep + 1);
                  setGridColumns(
                    [...allStepsAnddata, ...allStepsNaviagation][currentStep].data.dataGridHeader
                  );
                  setJsonDataForTable(
                    [...allStepsAnddata, ...allStepsNaviagation][currentStep].data.dataGridRow
                  );
                }}
                className={
                  currentStep === [...allStepsAnddata, ...allStepsNaviagation].length
                    ? 'umgjsonToolHolder umgnoActive'
                    : 'umgjsonToolHolder'
                }
              >
                <GrLinkNext />
              </Box>
              <Box className="umgjsonToolHolder">
                {gridColumns.length > 0 && (
                  <Select
                    value={selectedColumn}
                    classes={{ select: 'umgtoolSelectBox' }}
                    onChange={(e) => {
                      setSelectedColumn(e.target.value);
                      if (typeof jsonDataForTable[0][e.target.value] === 'object') {
                        if (Array.isArray(jsonDataForTable[0][e.target.value])) {
                          setSelectedArea('expandlist');
                        } else {
                          setSelectedArea('expandobject');
                        }
                      } else {
                        setSelectedArea(null);
                      }
                    }}
                  >
                    <MenuItem value="-" disabled>
                      Select column
                    </MenuItem>
                    {gridColumns.map((item) => (
                      <MenuItem value={item.field}>{item.headerName}</MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
              <Box
                onClick={(e) => {
                  getExpandTableFromdata(
                    jsonDataForTable,
                    setJsonDataForTable,
                    gridColumns,
                    setGridColumns,
                    selectedColumn,
                    getValueData,
                    allStepsNaviagation,
                    setAllStepsNaviagation,
                    setCurrentStep,
                    setSelectedColumn,
                    SelectedArea,
                    getColumnType,
                    setAllStepsAndData
                  );
                }}
                className={getClassNameByData(
                  [...allStepsAnddata, ...allStepsNaviagation],
                  currentStep,
                  jsonDataForTable,
                  selectedColumn,
                  setisExpendedArray,
                  setExpendedArray,
                  setExpendedObject
                )}
              >
                <MdOutlineExpand />
              </Box>
            </Box>
            <Box display="flex">
              <Box height={'81vh'} className="umgdataGridHolder">
                <DataGrid
                  columns={gridColumns}
                  rows={jsonDataForTable}
                  getRowClassName={(params) =>
                    `newClass-${Number(params.row.id) % 2 === 0 ? 'even' : 'odd'}`
                  }
                  rowHeight={27}
                  headerHeight={27}
                  hideFooterPagination={true}
                  classes={{
                    row: 'umggridRows',
                    cell: 'umggridCell',
                    columnHeaders: 'umgcolumnHeaders'
                  }}
                  components={{
                    ColumnMenu: CustomColumnMenuComponent
                  }}
                />
              </Box>
              <Box className="umgpropertyHolder">
                <Typography className="umgpropertTitle">Applied steps</Typography>
                <Box>
                  {(allStepsAnddata.length > 0 || allStepsNaviagation.length > 0) &&
                    [...allStepsAnddata, ...allStepsNaviagation].map((steps, stepsIndex) => (
                      <Box
                        key={steps.step}
                        onClick={() => {
                          setCurrentStep(steps.step);
                          setGridColumns(steps.data.dataGridHeader);
                          setJsonDataForTable(steps.data.dataGridRow);
                          localStorage.setItem('currentStep', steps.step);
                          const prevAllSteps = allStepsAnddata;
                          prevAllSteps.length = Number(steps.step);
                          setAllStepsAndData(prevAllSteps);
                        }}
                        className={`umgstepsrows umgstep-${
                          stepsIndex === 0 ? 'first' : 'others'
                        } step-${stepsIndex % 2 === 0 ? 'even' : 'odd'} step-${
                          currentStep === steps.step ? 'active' : ''
                        } ${stepsIndex}`}
                      >
                        {steps.name}
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions disableSpacing={false} className="umgpopupAction">
            <Button
              onClick={(e) => {
                setCustomJsonEditor(null);
                setcurFileName('');
                setGridColumns([]);
                setJsonDataForTable([]);
              }}
              variant="outlined"
              color="primary"
              className="umgmainBtn"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                setCustomJsonEditor(null);
                console.log('currentStep', currentStep);
                console.log('currentStep', jsonDataForTable);
                setConfigData(allStepsAnddata[allStepsAnddata.length - 1].stepQuery);
              }}
              variant="outlined"
              color="primary"
              className="umgmainBtn"
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          className="umgsubMenuHolder"
          open={isExpandMenuOpen}
          anchorEl={expandMenuOpen}
          onClose={(e) => setExpandMenuOpen(null)}
        >
          <MenuItem className="umgsubMenuItem" onClick={() => createTableFromdata()}>
            Create table from data
          </MenuItem>
          <MenuItem className="umgsubMenuItem" onClick={() => createRowsByArraydata()}>
            Expand
          </MenuItem>
        </Menu>
      </Grid>
    </Box>
  );
}
/**
 *
 * @param {String} str
 * @returns  Removed Space string
 */
const removeSpace = (str) => {
  // alert(str)
  if (str) {
    const str2 = str.replace(/ /g, '_');

    return str2;
  }
  return '';
};
/**
 *
 * @param {string} dType
 * @param {boolean} isTest
 * @returns boolean value
 */
function IsDisabled(dType, isTest, enableSaveOnChange) {
  useEffect(() => {}, [isTest]);

  if (enableSaveOnChange) {
    return false;
  }
  if (dType === 'Db' && isTest === false) {
    return true;
  } else {
    return false;
  }
}

function TestDisableHandler(
  dataSourceType,
  rowID,
  testEnableOnChange,
  configData,
  configDataNew,
  fileChanged
) {
  const [returnState, setReturnState] = useState('');

  useEffect(() => {
    // setNewConfig(configData)
    if (Object.entries(configDataNew).toString() === Object.entries(configData).toString()) {
      if (testEnableOnChange) {
        setReturnState(true);
      }
    }
    if (Object.entries(configDataNew).toString() !== Object.entries(configData).toString()) {
      if (testEnableOnChange) {
        setReturnState(false);
      }
    }

    if (fileChanged === 'File Changed') {
      setReturnState(false);
    }
    if (testEnableOnChange === 'Data Source name changed') {
      setReturnState(false);
    }
  }, [testEnableOnChange]);
  //  {dataSourceType === 'Db' ? (rowID ? true : false) : true}

  if (testEnableOnChange) {
    return returnState;
  }
  if (dataSourceType === 'Db') {
    if (rowID) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

// item.data_source_type !== dataSourceType
function TabDIsable(item, dataSourceType, rowID) {
  if (rowID !== undefined) {
    return item.data_source_type !== dataSourceType;
  } else {
    return false;
  }
}

function CustomColumnMenuComponent(props) {
  return 'abcd';
}

const getClassNameByData = (
  allData,
  stepNumber,
  jsonDataForTable,
  selectedColumn,
  setisExpendedArray,
  setExpendedArray,
  setExpendedObject
) => {
  const isStringOrNumber = [];
  const isObjectData = [];
  const isArrayData = [];
  if (selectedColumn !== '-') {
    jsonDataForTable.forEach((columnVal) => {
      if (
        typeof columnVal[selectedColumn] !== 'string' &&
        typeof jsonDataForTable[0][selectedColumn] !== 'number'
      ) {
        if (Array.isArray(columnVal[selectedColumn])) {
          isArrayData.push(columnVal[selectedColumn]);
          // setisExpendedArray('True');
        } else {
          isObjectData.push(columnVal[selectedColumn]);
          // setisExpendedArray('False');
        }
      } else {
        isStringOrNumber.push(columnVal[selectedColumn]);
      }
    });
    if (isObjectData.length === jsonDataForTable.length) {
      // setExpendedArray(isObjectData);
      return 'umgjsonToolHolder';
    }
    if (isArrayData.length === jsonDataForTable.length) {
      // setExpendedObject(isArrayData);
      return 'umgjsonToolHolder';
    }
    return 'umgjsonToolHolder umgnoActive';
  }
  // jsonDataForTable.forEach((tableItem) => {
  //   tableItem;
  // });
  return 'umgjsonToolHolder umgnoActive';
};

// const optionsArray = [];
function getAllKeysAsOptions(allData, optionsArray, parents) {
  if (allData.constructor.name === 'Object') {
    if (Object.keys(allData).length > 0) {
      Object.values(allData).forEach((dataObj, objIndex) => {
        if (
          dataObj &&
          (dataObj.constructor.name === 'Object' || dataObj.constructor.name === 'Array')
        ) {
          const obj = {
            label:
              parents === ''
                ? Object.keys(allData)[objIndex]
                : `${parents}.${Object.keys(allData)[objIndex]}`,
            parents: parents,
            dataType: dataObj.constructor.name
          };
          if (optionsArray.filter((options) => deepEqual(options, obj)).length === 0) {
            optionsArray.push(obj);
          }
          const currentPar =
            parents === ''
              ? Object.keys(allData)[objIndex]
              : `${parents}.${Object.keys(allData)[objIndex]}`;
          getAllKeysAsOptions(dataObj, optionsArray, currentPar);
        }
      });
    }
  }
  if (allData.constructor.name === 'Array') {
    if (allData.length > 0) {
      allData.forEach((dataArray, arrayIndex) => {
        getAllKeysAsOptions(dataArray, optionsArray, parents);
      });
    }
  }
  return optionsArray;
}

function deepEqual(x, y) {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}

// function toBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
//       if (encoded.length % 4 > 0) {
//         encoded += '='.repeat(4 - (encoded.length % 4));
//       }
//       resolve(encoded);
//     };
//     reader.onerror = (error) => reject(error);
//   });
// }

function objnestedKeys(path) {
  // let context = context || this;
  let context1 = this;
  path = path.split('.');

  for (let i = 0; i < path.length; i++) {
    context1 = context1[path[i]];
  }

  return context1;
}

function getIconClassname(allDataSet, selectedColumn) {
  let className = 'umgjsonToolHolder umgnoActive';
  if (selectedColumn !== '-') {
    const selectedData = allDataSet[0].tableRows[0][selectedColumn];
    if (typeof selectedData === 'object') {
      if (Array.isArray(selectedData) && selectedData.length) {
        className = 'umgjsonToolHolder';
      } else if (Object.keys(selectedData).length) {
        className = 'umgjsonToolHolder';
      } else {
        className = 'umgjsonToolHolder umgnoActive';
      }
    } else {
      className = 'umgjsonToolHolder umgnoActive';
    }
  }
  return className;
}
