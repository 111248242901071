import { useEffect, useState } from 'react';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import './ruleEngineQuery.css';
import { useSelector } from 'react-redux';
import { Button, DialogContent, DialogActions, Dialog, DialogTitle } from '@mui/material';
import { AutoAwesome, FilterAltOff } from '@mui/icons-material';

export default function DatagridRuleEngine({
  operators,
  colsWithTypes,
  tableRows,
  setTableRows,
  handleGlobalFilterDialogOpen,
  handleGlobalFilterDialogClose,
  rowDataArchive,
  initialQuery,
  query,
  setQuery
}) {
  const [fieldData, setFieldData] = useState([]);

  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const {
    createKnowledgeGraph: { cyToState }
  } = knowledgeGraphStoreItem;
  // console.log('knowledgeGraphStoreItemcyToState', cyToState);

  useEffect(() => {
    if (colsWithTypes.length > 0) {
      const formattedFieldsData = colsWithTypes.map((data) => ({
        name: data.name,
        label: data.name,
        type: data.type,
        operators: operators.filter((item) => {
          return item.type === data.type;
        })
      }));

      //   console.log('nagar ruleEngine', formattedFieldsData);
      setFieldData([...formattedFieldsData]);
      // console.log('satyam opr', formattedFieldsData);
    }
  }, [colsWithTypes]);

  const handleApplyRule = () => {
    const ruleData = JSON.parse(formatQuery(query.rules, 'json_without_ids'));
    // console.log('nagar filter', ruleData);

    function processFilterRules(data, filterRules) {
      // Initialize an empty array to store the rows that satisfy the filter rules.
      let result = [];

      // Initialize an operator to "or" for the first rule set.
      let operator = 'or';

      for (let i = 0; i < filterRules.length; i++) {
        const rule = filterRules[i];

        if (typeof rule === 'string') {
          // If the rule is an operator ("and" or "or"), update the operator.
          operator = rule;
        } else {
          // Apply the rule to the data based on the operator.
          const filteredRows = applyRule(data, rule);
          // console.log('result', filteredRows);

          if (operator === 'or') {
            // For "or" operator, merge the results with the existing result.
            result = [...result, ...filteredRows];
          } else if (operator === 'and') {
            // For "and" operator, intersect the results with the existing result.
            if (result.length === 0) {
              // If result is empty, set it to the filtered rows.
              result = [];
            } else {
              // Otherwise, keep only the rows that exist in both result and filteredRows.
              result = result.filter((row) => filteredRows.includes(row));
            }
          }
        }
      }

      return result;
    }

    function applyRule(data, rule) {
      if (typeof rule === 'string') {
        // This is an "and" or "or" operator, return as is.
        return null;
      }

      const { field, value, operator } = rule;

      switch (operator) {
        case 'contains':
          return data.filter((row) => row[field].toLowerCase().includes(value.toLowerCase()));

        case 'exclude':
          return data.filter((row) => !row[field].toLowerCase().includes(value.toLowerCase()));

        case 'starts_with':
          return data.filter((row) => row[field].toLowerCase().startsWith(value.toLowerCase()));

        case 'ends_with':
          return data.filter((row) => row[field].toLowerCase().endsWith(value.toLowerCase()));

        case 'exact_match':
          return data.filter((row) => row[field].toLowerCase() === value.toLowerCase());

        case 'not_an_exact':
          return data.filter((row) => row[field].toLowerCase() !== value.toLowerCase());

        case 'greater_than':
          return data.filter((row) => parseFloat(row[field]) > parseFloat(value));

        case 'less_than':
          return data.filter((row) => parseFloat(row[field]) < parseFloat(value));

        case 'equals':
          return data.filter((row) => parseFloat(row[field]) === parseFloat(value));

        case 'not_equals':
          return data.filter((row) => parseFloat(row[field]) !== parseFloat(value));

        default:
          return [];
      }
    }

    // Apply the filter rules to the data
    const filteredResult = processFilterRules(rowDataArchive, ruleData);
    const nonDuplicateData = [...new Set(filteredResult)];
    // console.log('rule engine', nonDuplicateData);

    if (nonDuplicateData.length > 0) {
      setTableRows(nonDuplicateData);
      handleGlobalFilterDialogClose();
    } else {
      handleEmptyRuleDialogOpen();
    }
  };

  const [applyFilterBtnActive, setApplyFilterBtnActive] = useState(false);

  useEffect(() => {
    if (query.rules.length > 0) {
      // console.log('nagar query', query);
      const isAnyFieldEmpty = query.rules.some((rule) => rule.value === '');
      setApplyFilterBtnActive(!isAnyFieldEmpty);
    }
  }, [query]);

  const [openEmptyRuleDialog, setOpenEmptyRuleDialog] = useState(false);
  const handleEmptyRuleDialogOpen = () => {
    setOpenEmptyRuleDialog(true);
  };
  const handleEmptyRuleDialogClose = () => {
    setOpenEmptyRuleDialog(false);
  };

  return (
    <>
      <DialogContent sx={{ position: 'relative' }}>
        <QueryBuilder
          fields={fieldData}
          query={query}
          onQueryChange={(q) => setQuery(q)}
          controlElements={{
            addGroupAction: () => null
          }}
          independentCombinators
          showCloneButtons
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            setTableRows(rowDataArchive);
            handleGlobalFilterDialogClose();
            setQuery(initialQuery);
          }}
          color="warning"
          startIcon={<FilterAltOff />}
          variant={'contained'}
          sx={{ border: '1px solid #ddd' }}
        >
          Reset Filter
        </Button>
        <Button
          disabled={!applyFilterBtnActive}
          onClick={handleApplyRule}
          startIcon={<AutoAwesome />}
          variant={'contained'}
          sx={{ border: '1px solid #ddd' }}
        >
          Apply Rule
        </Button>
      </DialogActions>
      <Dialog open={openEmptyRuleDialog} onClose={handleEmptyRuleDialogClose}>
        <DialogTitle>Alert!</DialogTitle>
        <DialogContent>Sorry, no matching data found.</DialogContent>
        <DialogActions>
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={handleEmptyRuleDialogClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
