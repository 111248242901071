import { ResetTv, VerticalAlignCenter, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box, Stack, Paper, styled, Divider, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';

import FullScreenTogglePage from './FullScreenTogglePage';

// State management

function KnowledgeGraphEditOptionsPlate({ cyToState }) {
  const dispatch = useDispatch();

  const updateCanvas = (type) => {
    if (type === 'aligncanvascenter') {
      // cyToState.removeListener('zoom');
      cyToState.center();
      //  cyToState.fit();
    }

    if (type === 'fitscreen') {
      cyToState.removeListener('zoom');
      cyToState.center();
      cyToState.fit();
      panelLeftSideBar(false);
      panelRightSideBar(false);
    }
  };

  const panelLeftSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBar',
        value: newValue
      }
    });
  };

  const panelRightSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBarRight',
        value: newValue
      }
    });
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  return (
    <Box className="MuiBottomContainer">
      <Box className="MuiBottomElements">
        <Stack direction="row" spacing={1}>
          <FullScreenTogglePage />
          <Divider orientation="vertical" flexItem />
          <Item
            onClick={() => {
              // handleTogglePanel();
              updateCanvas('aligncanvascenter');
              panelLeftSideBar(false);
              panelRightSideBar(false);
            }}
          >
            <Tooltip title="Center" arrow>
              <VerticalAlignCenter className="rotate90deg" />
            </Tooltip>
          </Item>
          <Divider orientation="vertical" flexItem />
          <Item
            onClick={() => {
              //  handleTogglePanel();
              updateCanvas('fitscreen');
            }}
          >
            <Tooltip title="Fit Screen" arrow>
              <ResetTv />
            </Tooltip>
          </Item>
        </Stack>
      </Box>
    </Box>
  );
}

export default KnowledgeGraphEditOptionsPlate;
