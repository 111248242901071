import { useEffect, useState } from 'react';
import '../../../global.css';
import './Source.css';
import {
  Card,
  Stack,
  Container,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  DialogTitle,
  DialogActions,
  Typography
} from '@mui/material';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// icons

import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiFillEdit } from 'react-icons/ai';

// components
import Page from '../Page';
import Scrollbar from '../../global/Scrollbar';
import PageTitle from '../../global/PageHeading';
import dataSourceService from '../../../services/dataSourceService';
// import losService from '../../../services/LosService';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import { getGlobals } from '../../../utils/Globals';
import BrudCrumbs from '../../global/BreadCrumbs';
import DatasourceToolbar from './DatasourceToolbar';
import { getIcon, AddIcon } from '../../../vector/index';
import TopNavigationBar from '../TopNavigationBar';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';
import DatasourceToolbar2 from './Datadourcetoolbar2';

const { Datasourcelist, Deletedatasource } = dataSourceService;
// const { Login } = losService;
const useStyles = makeStyles({
  root: {
    border: '0',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid rgb(241, 243, 244)',
      '&:nth-child(even)': {
        backgroundColor: 'transparent' // Set your desired background color for even rows
      },
      '&:nth-child(odd)': {
        backgroundColor: 'rgba(145, 158, 171, 0.08)' // Set the background color for odd rows (if needed)
      }
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid rgb(241, 243, 244)',
      fontSize: '12px'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: 'rgb(13, 70, 137)',
      backgroundColor: '#fff',
      fontWeight: 500,
      fontSize: '12px',
      textTransform: 'uppercase'
    },
    '& .MuiDataGrid-columnSeparator ': {
      display: 'none'
    }
  }
});
/**
 * component for rendering datasource list
 * @class
 * @return {React.ReactElement} - React component
 */

function DatasourceList() {
  console.log('userDataFromLocal', userDataFromLocal());
  const classes = useStyles();
  const navigate = useNavigate();
  // const { GLOBAL_PATH } = getGlobals();
  // console.log('GLOBAL_PATH', GLOBAL_PATH);
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);
  const [rowsArchive, setRowsArchive] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log('selectedUsers', selectedUsers);

  const [mutateList, setMutateList] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [allParamData, setAllParamData] = useState('');
  const [currentSelection, setCurrentSelection] = useState([]);
  const [currentCellSelection, setCurrentCellSelection] = useState([]);

  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };
  /**
   * For handling open delete popup
   */
  const handleOpenDelete = () => {
    setCurrentCellSelection([]);
    setOpenDelete(true);
  };
  /**
   * For handling close delete popup
   */
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  /**
   * For handling edit and delete button in list
   */
  const [currentHeight, setCurrentHeight] = useState('70vh');
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  const getActionMore = (params) => (
    <Box className="umgactionTd umgglobalMoreIconHolder" display="flex">
      <Tooltip title="Edit" arrow placement="top">
        <StyledIconButton
          size="small"
          classes={{
            root: 'umgsquareIconButton umgactionButton'
          }}
          onClick={(e) => {
            // check permission then let it access
            console.log(
              'editcase',
              userDataFromLocal().modules.some((module) => module.module_name === 'Datasource')
            );
            if (userDataFromLocal().modules.some((module) => module.module_name === 'Datasource')) {
              console.log(
                'editcase',
                userDataFromLocal().modules.find((module) => module.module_name === 'Datasource')
                  .permissions.edit
              );
              //
              if (
                userDataFromLocal().modules.find((module) => module.module_name === 'Datasource')
                  .permissions.edit
              ) {
                navigate(`/datasource/edit/${params.row.id}`);
              } else {
                setValidationMsg('User does not have permission to Edit.');
                setOpenValidation(true);
              }
            }
          }}
        >
          <AiFillEdit size={14} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Delete" arrow placement="top">
        <StyledIconButton
          size="small"
          classes={{
            root: 'umgsquareIconButton umgactionButton mr-1'
          }}
          onClick={(e) => {
            // check permission then let it access
            console.log(
              'editcase',
              userDataFromLocal().modules.some((module) => module.module_name === 'Datasource')
            );
            if (userDataFromLocal().modules.some((module) => module.module_name === 'Datasource')) {
              console.log(
                'editcase',
                userDataFromLocal().modules.find((module) => module.module_name === 'Datasource')
                  .permissions.delete
              );
              //
              if (
                userDataFromLocal().modules.find((module) => module.module_name === 'Datasource')
                  .permissions.delete
              ) {
                setAllParamData(params?.row);
                handleOpenDelete(e.target);
                setCurrentSelection([]);
                setCurrentCellSelection([params.row.id]);
              } else {
                setValidationMsg('User does not have permission to delete.');
                setOpenValidation(true);
              }
            }
          }}
        >
          <RiDeleteBinLine size={14} />
        </StyledIconButton>
      </Tooltip>
    </Box>
  );

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <div>
          <GridToolbarQuickFilter
          // quickFilterFormatter={(value) => {
          //   console.log('value', value);
          // }}
          // quickFilterParser={(value) => {
          //   console.log('value', value);
          // }}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100, hide: true },
    { field: 'name', headerName: 'Dataset', width: 350 },
    { field: 'data_source_type', headerName: 'Source type', width: 350 },
    { field: 'Sub_type', headerName: 'Subcategory type', width: 300 },
    { field: 'Created_on', headerName: 'Added at', width: 280 },
    {
      field: 'ViewFlows',
      headerName: 'Action',
      width: 150,
      renderCell: getActionMore
    }
  ];
  /**
   * For handling delete function
   */
  const handleDelete = async () => {
    try {
      // Retrieving the authentication token from userDataFromLocal
      const authToken = userDataFromLocal()?.allTokens?.access;

      // Showing loader while deleting data
      setOpenLoader(true);

      const payload = {
        ids: currentCellSelection.length > 0 ? currentCellSelection : currentSelection,
        user_id: userDataFromLocal().userID
      };

      console.log('newSelectionnewSelection', payload);
      // Calling Deletedatasource API to delete the data
      const deleteData = await Deletedatasource(payload, authToken);

      // Handling success case
      if (deleteData.status === 'success') {
        handleCloseDelete();
        setMutateList(new Date());
        enqueueSnackbar(deleteData.message, {
          variant: deleteData.status,
          autoHideDuration: 2000
        });
      }
    } catch (error) {
      // Handling unexpected errors
      console.error('Error:', error);
      enqueueSnackbar('An error occurred while deleting the data.', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      setOpenLoader(false);
    }
  };

  const manageAccessListApi = async (userName) => {
    // manageList API is also using for manage list API
    try {
      if (userName !== '' && userName !== undefined) {
        const lowerCaseValue = userName.toLowerCase();
        const exactMatches = [];
        const partialMatches = [];

        rowsArchive.map((item) => {
          const _username = String(item.name).toLowerCase();
          const _firstname = String(item.data_source_type).toLowerCase();
          const _lastname = String(item.Sub_type).toLowerCase();
          const _email = String(item.id).toLowerCase();

          if (
            _username === lowerCaseValue ||
            _firstname === lowerCaseValue ||
            _lastname === lowerCaseValue ||
            _email === lowerCaseValue
          ) {
            exactMatches.unshift(item);
          } else if (
            _username.includes(lowerCaseValue) ||
            _firstname.includes(lowerCaseValue) ||
            _lastname.includes(lowerCaseValue) ||
            _email.includes(lowerCaseValue)
          ) {
            partialMatches.push(item);
          }
        });

        setRows([...exactMatches, ...partialMatches]);
      } else {
        // Retrieving the authentication token from userDataFromLocal
        const authToken = userDataFromLocal();

        // Calling Datasourcelist API to fetch the list data
        // const params = `?datasource_type=File&user_id=${authToken.userID}`;
        const params = `?user_id=${authToken.userID}`;
        const isListAvailable = await Datasourcelist(params, authToken);

        // Checking if the API call was successful
        if (isListAvailable.status === 'success') {
          // Update the state with the fetched data

          // const fltrArray = isListAvailable.data.filter((data, index) => (data.id = data?.user_id)); // Id is mandatory so define from here
          // console.log('fltrArray', isListAvailable.data, fltrArray);

          setRows(isListAvailable.data);

          setRowsArchive(isListAvailable.data);
        } else if (isListAvailable.status === 'failed') {
          setOpenValidation(true);
          setValidationMsg(isListAvailable.message);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   try {

    //   } catch (error) {
    //     // Handling unexpected errors
    //     console.error('Error:', error);
    //     enqueueSnackbar('An error occurred while fetching data.', {
    //       variant: 'error',
    //       autoHideDuration: 2000
    //     });
    //   }
    // };

    // Calling the fetchData function when the component mounts or when mutateList changes
    if (userDataFromLocal().modules.some((module) => module.module_name === 'Datasource')) {
      if (
        userDataFromLocal().modules.find((module) => module.module_name === 'Datasource')
          .permissions.view
      ) {
        manageAccessListApi();
      } else {
        setValidationMsg('User does not have permission to view.');
        setOpenValidation(true);
      }
    }
  }, [mutateList]);

  return (
    <Page title={`Data Source`}>
      <Container maxWidth={false} className="umgnoPadding bg-h-100">
        <TopNavigationBar />
        <Box pt={3} pb={2} px={2} mb={4}>
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="umgpageTitleholder"
          >
            <PageTitle icon={getIcon(DataSourceIcon)} info="Source" className="umgnavTxt" />
            <Tooltip title="Add new" arrow placement="top">
              <Button
                className="umgnavLink"
                variant="outlined"
                size="small"
                to="#"
                startIcon={getIcon(AddIcon, 16, 16)}
                onClick={(e) => navigate(`${GLOBAL_PATH}/datasource/add`)}
              >
                Add New
              </Button>
            </Tooltip>
          </Stack> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.8rem' }}>
            <Typography variant="h6" sx={{ color: '#004C99', p: 1 }}>
              Data Source
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '10px'
              }}
            >
              <Box sx={{ p: 1 }}>
                {currentSelection.length > 1 && (
                  <Button size="small" variant="contained" onClick={handleOpenDelete} color="error">
                    Delete All
                  </Button>
                )}
              </Box>
              <Tooltip title="Add new" arrow placement="top">
                <Button
                  variant="outlined"
                  to="#"
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  startIcon={getIcon(AddIcon, null, 16, 16)}
                  onClick={(e) => {
                    // check permission then let it access
                    if (
                      userDataFromLocal().modules.some(
                        (module) => module.module_name === 'Datasource'
                      )
                    ) {
                      if (
                        userDataFromLocal().modules.find(
                          (module) => module.module_name === 'Datasource'
                        ).permissions.add
                      ) {
                        navigate(`/datasource/add`);
                      } else {
                        setValidationMsg('User does not have permission to Add.');
                        setOpenValidation(true);
                      }
                    }
                  }}
                >
                  Add New
                </Button>
              </Tooltip>
            </Box>
          </Box>
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="umgpageHeading"
          >
            <PageTitle info="Source" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack> */}
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            className="umgpageTitleholder"
          >
          
           
          </Stack> */}
          <Card>
            <DatasourceToolbar2
              selectedUsers={selectedUsers}
              setOpenDelete={setOpenDelete}
              manageAccessListApi={manageAccessListApi}
            />

            {/* <DatasourceToolbar /> */}
            <Scrollbar>
              <Box
                className="umgdataGridBox umgglobalDatGridHolder"
                style={{ height: currentHeight, width: '100%' }}
              >
                <DataGrid
                  headerHeight={40}
                  checkboxSelection
                  disableSelectionOnClick
                  rowSelectionModel={currentSelection}
                  onRowSelectionModelChange={(newSelection) => {
                    console.log(newSelection, 'newSelectionnewSelection');
                    setCurrentSelection(newSelection);
                  }}
                  rowHeight={40}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5
                      }
                    }
                  }}
                  pageSizeOptions={[25, 50, 100]}
                  pagination
                  paginationMode="client"
                  // page={pageNumber}
                  // onPageChange={(params) => {
                  //   console.log('page', params);
                  //   setPageNumber(params);
                  // }}
                  classes={{
                    root: classes.root
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  components={{
                    Toolbar: CustomToolbar
                  }}
                />
              </Box>
            </Scrollbar>
          </Card>
          <Dialog
            open={openDelete}
            onClose={(e) => {
              handleCloseDelete();
            }}
            fullWidth
          >
            <DialogTitle className="umgpopupTitle">
              <Typography variant="subtitle1" className="umgpopupSubTitle">
                Delete Source List
              </Typography>
              <IconButton onClick={(e) => handleCloseDelete()} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers className="umgpopupInfo">
              <Typography variant="body1">
                {`Are you sure you want to delete `}
                {currentCellSelection.length > 0 ? (
                  <strong>{allParamData.name}</strong>
                ) : (
                  <>
                    these <strong>{currentSelection.length} rows</strong>?
                  </>
                )}
              </Typography>
              {/* <Typography variant="body1">{<strong>{allParamData.Name}</strong>} </Typography> */}
            </DialogContent>
            <DialogActions disableSpacing={false} className="umgpopupAction">
              <Button
                onClick={handleCloseDelete}
                variant="outlined"
                color="primary"
                className="umgmainBtn"
              >
                Close
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                className="umgmainBtn"
                // className="umgglobalSubmitButton"
                // classes={{
                //   root: 'globalSubmitButton globalSubmitButtonDelete autoButton',
                //   label: 'globalSubmitButtonLabel globalSubmitButtonDeleteLabel'
                // }}
                // variant="contained"
                // color="secondary"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
        />
      </Container>
    </Page>
  );
}
export default DatasourceList;

export const GlobelLosParameter = {
  LosParameter: {
    loanType: ' ',
    loanSubType: ' ',
    employee: ' ',
    employeeSubType: ' ',
    measuresType: ' ',
    employeeSubType_id: '',
    loanSubType_id: '',
    loanType_id: '',
    employee_id: '',
    measures_category: ' '
  },
  loanTypeValues: ['Corporate Loan'],
  loanSubTypeValue: ['Term Loan', 'Cash Credit', 'Overdraft'],
  employeeValues: ['Business'],
  employeeTypeValues: ['Proprietorship', ' Private Limited'],

  measuresTypeValues: [
    {
      measureName: 'Kpi',

      measureValue: 'KPI'
    },
    {
      measureName: 'Api',

      measureValue: 'API'
    },
    {
      measureName: 'Custom',

      measureValue: 'Custom'
    }
  ]
};
