import useApiServices from './useApiServices';
import { BankWiseUrl } from './globalApi';

const { globalKnowledgeGraphUrl } = BankWiseUrl;

console.log('globalKnowledgeGraphUrl', globalKnowledgeGraphUrl);

/**
 * @module KnowledgeGraphService
 * @returns  it return All KnowledgeGraphService Apis
 */
const KnowledgeGraphService = {
  CreateVisualizationExp: (credential, authToken) =>
    useApiServices().postApi(
      `${globalKnowledgeGraphUrl}/visual/manage_projects`,
      credential,
      authToken
    ),
  EditProject: (credential, authToken) =>
    useApiServices().patchApi(
      `${globalKnowledgeGraphUrl}/visual/manage_projects`,
      credential,
      authToken
    ),
  ProjectList: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/visual/manage_projects${path}`, authToken),
  SharedProjectList: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/visual/shrd_proj${path}`, authToken),
  SearchNodes: (credential, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/experimentlist`, credential, authToken),
  DeleteNodes: (path, authToken) =>
    useApiServices().putApi(`${globalKnowledgeGraphUrl}/deleteexperiment/${path}`, authToken),
  Editexperiment: (path, credential, authToken) =>
    useApiServices().postApi(
      `${globalKnowledgeGraphUrl}/editexperiment/${path}`,
      credential.authToken
    ),
  SaveSourceMapings: (credential, authToken) =>
    useApiServices().postApi(
      `${globalKnowledgeGraphUrl}/visual/manage_app_tables`,
      credential,
      authToken
    ),
  SaveNodesAndEdges: (credential, authToken) =>
    useApiServices().postApi(
      `${globalKnowledgeGraphUrl}/visual/neo4j/create_nodes`,
      credential,
      authToken
    ),

  PostApiByUrl: (path, credential, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}${path}`, credential, authToken),

  PostSaveRuleApi: (path, credential, authToken) =>
    useApiServices().postApi(
      `${globalKnowledgeGraphUrl}/visual/manage_rule_engine`,
      credential,
      authToken
    ),
  PatchSavedRuleApi: (credential, authToken) =>
    useApiServices().patchApi(
      `${globalKnowledgeGraphUrl}/visual/manage_rule_engine`,
      credential,
      authToken
    ),
  ExtractRuleApi: (params, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/extractruledata?${params}`,
      authToken
    ),
  ViewSavedRuleApi: (params, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/manage_rule_engine?${params}`,
      authToken
    ),

  DeleteApiByUrl: (path, credential, authToken) =>
    useApiServices().deleteApi(`${globalKnowledgeGraphUrl}${path}`, credential, authToken),

  PatchApiByUrl: (path, credential, authToken) =>
    useApiServices().patchApi(`${globalKnowledgeGraphUrl}${path}`, credential, authToken),
  PutApiByUrl: (path, credential, authToken) =>
    useApiServices().putApi(`${globalKnowledgeGraphUrl}${path}`, credential, authToken),
  Openconfig: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/editexperiment/${path}`, authToken),
  Savedataset: () => `${globalKnowledgeGraphUrl}/savedataset`,
  DataSourcelist: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/${path}`, authToken), // dataset list
  DatasetSchema: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/${path}`, authToken), // properties list
  GetTableNames: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/${path}`, authToken), // properties list
  GetFieldsNames: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/${path}`, authToken), // properties list
  GetDataTypes: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/${path}`, authToken), // properties list
  GetApiByUrl: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/${path}`, authToken), // properties list
  ListExistingDataSetInProject: (credential, authToken) =>
    useApiServices().postApi(
      `http://164.52.208.46:8080/KnowledgeGraphRestServices/project/listExistingDataSetInProject`,
      credential,
      authToken
    ),
  ShowColumnOfExistingFileInProject: (credential, authToken) =>
    useApiServices().postApi(
      `http://164.52.208.46:8080/KnowledgeGraphRestServices/project/showColumnOfExistingFileInProject`,
      credential,
      authToken
    ),
  GetElements: () => useApiServices().getApi(`http://192.168.1.2:6069/`),

  // Share in app service
  ShareUserList: (param, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/user_list?project_id=${param.projID}&user_id=${param.userID}`,
      authToken
    ),

  SaveSharedUserList: (cred, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/visual/save_shrd_proj`, cred, authToken),

  // Datagrid
  SaveSqlQueryApi: (payload, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/visual/SQLQuery`, payload, authToken),
  SavedSqlRuleList: (param, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/manage_rule_engine?${param}`,
      authToken
    ),
  SqlRuleApply: (param, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/ExecuteRuleQuery?${param}`,
      authToken
    ),
  DatasetDetails: (param, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/data_set_details?dataset_id=${param}`,
      authToken
    )
};

export default KnowledgeGraphService;
