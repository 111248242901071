import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  ButtonGroup,
  Button,
  Checkbox,
  Slider
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import './BottomTabsStyle.css';
// State management
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getIcon, UploadIcon, ColorPanelIcon } from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
import EdgeRuleEngine from './EdgeRuleEngine';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import LoaderBlur from './LoaderBlur';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';

function EdgeStyleSetting(props) {
  const {
    currentMetaDataId,
    cyToState,
    labelEdgeToDisplay,
    setLabelEdgeToDisplay,
    expandedPanel,
    handleExpanedPanel,
    edgeSavedRulesNameList,
    setEdgeSavedRulesNameList,
    setMutateSavedRuleList,
    mutateSavedRuleList
  } = props;
  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const dispatch = useDispatch();
  // console.log('knowledgeGraphStoreItem', knowledgeGraphStoreItem);
  const {
    allSelectedData: { refreshComponent, currentActiveStep },
    node_edge_styling: { vizEdgeStyles },
    createKnowledgeGraph: { styling }
  } = knowledgeGraphStoreItem;

  const { experimentId, modelId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedEdge, setSelectedEdge] = useState('#');
  const [openRuleModel, setOpenRuleModel] = useState(false);
  const [toggleSaveModal, setToggleSaveModal] = useState(false);

  const { GetApiByUrl, DeleteApiByUrl } = KnowledgeGraphService;
  // const [apiEdgesStyling, setApiEdgesStyling] = useState([{}]);

  const initialEdgeStyles = {
    'line-color': '#f9ab62',
    label: 'data(label)',
    width: '2',
    padding: '0',
    'curve-style': 'unbundled-bezier',
    'line-style': 'solid',
    'target-arrow-shape': 'triangle',
    'target-arrow-color': '#c4c4c4',
    'arrow-scale': 1,
    'line-opacity': 1,
    'font-size': '9',
    // 'text-margin-y': '-7px',
    'text-valign': 'center',
    'text-halign': 'center',
    color: '#000',
    'text-opacity': 1
  };

  const [allEdgesSettings, setAllEdgesSettings] = useState(initialEdgeStyles);
  const [edgeProperty, setedgeProperty] = useState('');
  const [edgeValues, setedgeValues] = useState([]);
  const [refreshStyleComp, setRefreshStyleComp] = useState(null);
  const [savedRulesNameList, setSavedRulesNameList] = useState([]);
  const [savedRuleSelection, setSavedRuleSelection] = useState([]);
  const [savedEdgeRuleData, setSavedEdgeRuleData] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [apiLoader, setApiLoader] = useState(false);
  const [selectedRules, setSelectedRules] = useState([]);
  const [patchToggleSaveModal, setPatchToggleSaveModal] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };

  const groupBtnStyle = {
    flex: 1,
    fontSize: '0.750rem',
    paddingRight: '9px',
    paddingLeft: '9px',
    background: '#f5f8fc',
    border: '1px solid #ddd',
    color: 'black',
    '&:hover': { color: 'white' }
  };

  const groupBtnStyleBdr = { borderColor: '#C4CDD5' };

  let debounceTimer;

  // Generalized Debounce function for style properties
  const debounce = (func, delay, styleProperty) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func(styleProperty);
    }, delay);
  };

  const handleColorValueChange = (event, styleProperty) => {
    const newValue = event.target.value; // Extract the new value from the event
    // Call the debounce function when the slider value changes
    debounce(
      (property) => {
        dispatch({
          type: 'STYLING_DATA_UPDATE',
          payload: {
            key: 'vizEdgeStyles',
            value: {
              [selectedEdge]: {
                ...vizEdgeStyles[selectedEdge],
                [property]: newValue
              }
            }
          }
        });
        // setAllEdgesSettings((prevSettings) => ({
        //   ...prevSettings,
        //   [property]: newValue
        // }));
        setRefreshStyleComp(new Date());
      },
      300,
      styleProperty
    ); // Adjust the debounce delay time as needed (e.g., 300 milliseconds)
  };

  const handleStyleValueChange = (event, styleProperty) => {
    const newValue = event.target.value; // Extract the new value from the event
    dispatch({
      type: 'STYLING_DATA_UPDATE',
      payload: {
        key: 'vizEdgeStyles',
        value: {
          [selectedEdge]: {
            ...vizEdgeStyles[selectedEdge],
            [styleProperty]: newValue
          }
        }
      }
    });
    // setAllEdgesSettings((prevSettings) => ({
    //   ...prevSettings,
    //   [styleProperty]: newValue
    // }));
    setRefreshStyleComp(new Date());
  };

  // onchange for changing shape
  const selectShape = (event) => {
    setedgeProperty(event.target.value);
  };
  const selectChange = (event) => {
    setedgeProperty(event.target.value);
  };

  // Delete selected Items from Autocomplete rules

  const handleDeleteEdgeRule = () => {
    const authToken = userDataFromLocal();
    const ruleCredential = {
      rule_ids: selectedRules.map((item) => item.id),
      creator_user_id: authToken.userID
    };

    (async () => {
      const deleteEdgeRules = await DeleteApiByUrl('/visual/manage_rule_engine', ruleCredential);
      if (selectedRules.length > 0) {
        if (deleteEdgeRules?.status === 'success' && deleteEdgeRules?.code === 200) {
          setSelectedRules([]);

          setEdgeSavedRulesNameList((prevRules) =>
            prevRules.filter((rule) => !ruleCredential.rule_ids.includes(rule.id))
          );

          enqueueSnackbar(deleteEdgeRules.message, {
            variant: deleteEdgeRules.status,
            autoHideDuration: 3000
          });
        } else {
          enqueueSnackbar('Problem in rule deletion', {
            variant: deleteEdgeRules.status,
            autoHideDuration: 3000
          });
        }
      } else {
        enqueueSnackbar('Please select rule(s).', {
          variant: 'warning',
          autoHideDuration: 3000
        });
      }
    })();
  };

  useEffect(() => {
    setedgeValues(styling);
  }, [styling]);

  const handleSavedRuleSelection = (_, value) => {
    setSelectedRules(value);
    // if (value.label) {
    //   savedEdgeRuleData.map((items) => {
    //     if (items.ruleName === value.label) {
    //       setAllEdgesSettings(items.ruleSettings);
    //       setSelectedLabel(value.label);
    //     }
    //   });
    //   // setAutoCompleteValue(value);
    // }
  };

  const handleApplyRules = () => {
    if (selectedRules.length > 0) {
      setApiLoader(true);
      (async () => {
        const savedRuleId = selectedRules[0].id;

        const getApiData = await GetApiByUrl('visual/apply_rule?rule_id=' + savedRuleId);
        if (getApiData.status === 'success' && getApiData.code === 200) {
          if (getApiData.data.length > 0) {
            const uniqueNodeIds = Array.from(
              new Set(
                getApiData.data.map(({ relationship, node1, node2 }) => ({
                  source_id: String(node1.properties.node_id),
                  target_id: String(node2.properties.node_id),
                  name: relationship.name
                }))
              )
            );
            console.log(uniqueNodeIds, 'uniqueNodeIds');
            const _filteredEdges = cyToState
              .edges()
              .filter((item) =>
                uniqueNodeIds.some(
                  (edge) =>
                    edge.source_id === item.data().source &&
                    edge.target_id === item.data().target &&
                    edge.name === item.data().label
                )
              );

            console.log(
              _filteredEdges.map((item) => item.data()),
              'uniqueNodeIds _filteredEdges'
            );

            const metaResponse = await GetApiByUrl(
              `visual/manage_metadata?link_id=${modelId}&project_id=${experimentId}`
            );
            if (
              _filteredEdges.length > 0 &&
              metaResponse.status === 'success' &&
              metaResponse.code === 200
            ) {
              const { edge_rules } = metaResponse.data.metadata[0];
              if (Object.keys(edge_rules).length > 0 && edge_rules[savedRuleId]) {
                _filteredEdges.style({
                  color: edge_rules[savedRuleId]?.color,
                  label: edge_rules[savedRuleId]?.label,
                  width: edge_rules[savedRuleId]?.width,
                  padding: edge_rules[savedRuleId]?.padding,
                  'font-size': edge_rules[savedRuleId]['font-size'] ?? '11px',
                  'line-color': edge_rules[savedRuleId]['line-color'] ?? 'red',
                  'arrow-scale': edge_rules[savedRuleId]['arrow-scale'] ?? 1,
                  'curve-style': edge_rules[savedRuleId]['curve-style'] ?? 'unbundled-bezier',
                  'text-halign': edge_rules[savedRuleId]['text-halign'] ?? 'center',
                  'text-valign': edge_rules[savedRuleId]['text-halign'] ?? 'center',
                  'line-opacity': edge_rules[savedRuleId]['line-opacity'] ?? 100,
                  'text-opacity': edge_rules[savedRuleId]['text-opacity'] ?? 100,
                  'target-arrow-color': edge_rules[savedRuleId]['target-arrow-color'] ?? '#f21f07',
                  'target-arrow-shape': edge_rules[savedRuleId]['target-arrow-shape'] ?? 'triangle',
                  'line-style': edge_rules[savedRuleId]['line-style'] ?? 'solid'
                });
                setApiLoader(false);
                // Success
                enqueueSnackbar('Rule Applied Successfully', {
                  variant: metaResponse.status,
                  autoHideDuration: 5000
                });
              } else {
                // metadata else condition
                setApiLoader(false);
                enqueueSnackbar('Problem in applying rules', {
                  variant: 'error',
                  autoHideDuration: 5000
                });
              }
            } else {
              // metadata else condition
              setApiLoader(false);
              enqueueSnackbar('Problem in applying rules', {
                variant: 'error',
                autoHideDuration: 5000
              });
            }
          } else {
            setApiLoader(false);
            enqueueSnackbar('No data found for this rule', {
              variant: 'warning',
              autoHideDuration: 5000
            });
          }
        } else {
          // First Api Error
          console.log(getApiData, 'uniqueNodeIds getApiData');
          setApiLoader(false);
          enqueueSnackbar('Problem in applying rules', {
            variant: 'error',
            autoHideDuration: 5000
          });
        }
      })();
    } else {
      enqueueSnackbar('Please select rule(s).', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }
  };

  useEffect(() => {
    if (cyToState) {
      labelEdgeToDisplay.forEach((item) => {
        if (item.label === selectedEdge) {
          item['line-color'] = vizEdgeStyles[selectedEdge]['line-color'];
        }
      });
      // setLabelEdgeToDisplay(labelEdgeToDisplay);
      const edgesToStyle = cyToState.edges(`[label='${selectedEdge}']`);
      // selectedEdge === 'all' ? cyToState.edges() : cyToState.edges(`[label='${selectedEdge}']`);
      edgesToStyle.forEach((edge) => {
        edge.style({
          label: vizEdgeStyles[selectedEdge].label,
          // label: savedEdgeRuleData.edgeRuleData.label,
          height: vizEdgeStyles[selectedEdge].height,
          width: vizEdgeStyles[selectedEdge].width,
          padding: vizEdgeStyles[selectedEdge].padding,
          'curve-style': vizEdgeStyles[selectedEdge]['curve-style'],
          'line-color': vizEdgeStyles[selectedEdge]['line-color'],
          'line-style': vizEdgeStyles[selectedEdge]['line-style'],
          'target-arrow-shape': vizEdgeStyles[selectedEdge]['target-arrow-shape'],
          'target-arrow-color': vizEdgeStyles[selectedEdge]['target-arrow-color'],
          'arrow-scale': vizEdgeStyles[selectedEdge]['arrow-scale'],
          'line-opacity': vizEdgeStyles[selectedEdge]['line-opacity'],
          'font-size': vizEdgeStyles[selectedEdge]['font-size'],
          // 'text-margin-y': allNodesSettings['text-margin-y'],
          'text-valign': vizEdgeStyles[selectedEdge]['text-valign'],
          'text-halign': vizEdgeStyles[selectedEdge]['text-halign'],
          color: vizEdgeStyles[selectedEdge].color,
          'text-opacity': vizEdgeStyles[selectedEdge]['text-opacity']
        });
      });
    }
  }, [refreshStyleComp, vizEdgeStyles]);

  useEffect(() => {
    setSelectedRules([]);
  }, [mutateSavedRuleList]);

  return edgeValues.length > 0 ? (
    <>
      {apiLoader && <LoaderBlur customMsg="Rules are being applied" />}
      <Box sx={{ pb: 0 }} className="nodeDesign">
        <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
        />

        <Accordion
          className="accordT"
          expanded={expandedPanel === 'panel3'}
          onChange={handleExpanedPanel('panel3')}
        >
          <AccordionSummary
            className="accordTrigger"
            aria-controls="panel3d-content"
            id="panel3d-header"
            classes={{ content: 'accordTgrtxt' }}
            expandIcon={<ExpandMore />}
          >
            <Typography variant="h5" className="edgeAccordHead">
              Edge
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="model-box" m={1}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <Select
                  className="form-control selectControl"
                  value={selectedEdge}
                  onChange={(e) => {
                    setSelectedEdge(e.target.value);
                    if (e.target.value !== '#') {
                      const selectedStyle = cyToState.edges(`[label='${e.target.value}']`).style();
                      if (selectedStyle) {
                        const prevObj = {};
                        Object.keys(vizEdgeStyles[e.target.value]).map((key) => {
                          if (key.includes('color')) {
                            let red = selectedStyle[key].replace('rgb(', '');
                            red = red.replace(')', '');
                            // console.log('redred', red);
                            prevObj[key] = rgbToHex(red.split(','));
                          } else {
                            prevObj[key] = vizEdgeStyles[e.target.value][key];
                          }
                        });
                        // console.log('checkLabel', prevObj);
                        // rgbToHex
                        dispatch({
                          type: 'STYLING_DATA_UPDATE',
                          payload: {
                            key: 'vizEdgeStyles',
                            value: {
                              [e.target.value]: prevObj
                            }
                          }
                        });
                        // setAllEdgesSettings(prevObj);
                      }
                    }
                  }}
                  label="edgeProperty"
                >
                  <MenuItem disabled value="#">
                    Select Edge
                  </MenuItem>
                  {labelEdgeToDisplay.length > 0 &&
                    labelEdgeToDisplay.map((edge) => (
                      <MenuItem key={edge.label} value={edge.label}>
                        {edge.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge Arrow Size</Typography>
              <TextField
                className="col-2"
                id=""
                variant="standard"
                fullWidth
                value={vizEdgeStyles[selectedEdge]?.['arrow-scale'] ?? ''}
                onChange={(e) => handleStyleValueChange(e, 'arrow-scale')}
                type="number"
              />
            </Box>

            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge Arrow Style</Typography>
              <FormControl variant="standard" className="col-2">
                <Select
                  className="form-control selectControl"
                  value={vizEdgeStyles[selectedEdge]?.['target-arrow-shape'] ?? ''}
                  onChange={(e) => handleStyleValueChange(e, 'target-arrow-shape')}
                  label="edgeProperty"
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="triangle">Triangle</MenuItem>
                  <MenuItem value="triangle-tee">Triangle-Tee</MenuItem>
                  <MenuItem value="circle-triangle">Circle-Triangle</MenuItem>
                  <MenuItem value="triangle-cross">Triangle-Cross</MenuItem>
                  <MenuItem value="triangle-backcurve">Triangle-Backcurve</MenuItem>
                  <MenuItem value="vee">Vee</MenuItem>
                  <MenuItem value="tee">Tee</MenuItem>
                  <MenuItem value="chevron">chevron</MenuItem>
                  <MenuItem value="square">Square</MenuItem>
                  <MenuItem value="circle">Circle</MenuItem>
                  <MenuItem value="diamond">Diamond</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge Arrow Color</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <input
                  type="color"
                  id="edgeArrowClr"
                  style={{
                    width: '20px',
                    height: '24px',
                    marginTop: '1px',
                    marginLeft: '7px'
                  }}
                  value={vizEdgeStyles[selectedEdge]?.['target-arrow-color'] ?? ''}
                  onChange={(e) => handleColorValueChange(e, 'target-arrow-color')}
                />
                <TextField
                  variant="standard"
                  style={{ width: '80px', marginRight: '4px' }}
                  value={vizEdgeStyles[selectedEdge]?.['target-arrow-color'] ?? ''}
                  disabled
                />
                <RenderIcons
                  icon={getIcon(ColorPanelIcon, null, 18, 18)}
                  onClick={(e) => {
                    const edgeBgColor = document.getElementById('edgeArrowClr');
                    edgeBgColor.click();
                  }}
                />
              </Box>
            </Box>
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge Type</Typography>
              <FormControl variant="standard" className="col-2">
                <Select
                  className="form-control selectControl"
                  value={vizEdgeStyles[selectedEdge]?.['curve-style'] ?? ''}
                  onChange={(e) => handleStyleValueChange(e, 'curve-style')}
                  label="edgeProperty"
                >
                  <MenuItem value="bezier">Bezier</MenuItem>
                  <MenuItem value="unbundled-bezier">Unbundled-Bezier</MenuItem>
                  <MenuItem value="haystack">Haystack</MenuItem>
                  <MenuItem value="segments">Segments</MenuItem>
                  <MenuItem value="taxi">Taxi</MenuItem>
                  <MenuItem value="straight">Straight</MenuItem>
                  <MenuItem value="straight-triangle">Straight-Triangle</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge width</Typography>
              <TextField
                className="col-2"
                id=""
                variant="standard"
                fullWidth
                value={vizEdgeStyles[selectedEdge]?.width ?? ''}
                onChange={(e) => handleStyleValueChange(e, 'width')}
              />
            </Box>

            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge Line Style</Typography>
              <FormControl variant="standard" className="col-2">
                <Select
                  className="form-control selectControl"
                  value={vizEdgeStyles[selectedEdge]?.['line-style'] ?? ''}
                  onChange={(e) => handleStyleValueChange(e, 'line-style')}
                >
                  <MenuItem value="solid">Solid</MenuItem>
                  <MenuItem value="dotted">Dotted</MenuItem>
                  <MenuItem value="dashed">Dashed</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge Color</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <input
                  type="color"
                  id="edgeClr"
                  style={{
                    width: '20px',
                    height: '24px',
                    marginTop: '1px',
                    marginLeft: '7px'
                  }}
                  value={vizEdgeStyles[selectedEdge]?.['line-color'] ?? ''}
                  onChange={(e) => handleColorValueChange(e, 'line-color')}
                />
                <TextField
                  variant="standard"
                  style={{ width: '80px', marginRight: '4px' }}
                  value={vizEdgeStyles[selectedEdge]?.['line-color'] ?? ''}
                  disabled
                />
                <RenderIcons
                  icon={getIcon(ColorPanelIcon, null, 18, 18)}
                  onClick={(e) => {
                    const edgeBgColor = document.getElementById('edgeClr');
                    edgeBgColor.click();
                  }}
                />
              </Box>
            </Box>
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Edge Line Opacity</Typography>
              <Box className="col-2">
                <Slider
                  value={vizEdgeStyles[selectedEdge]?.['line-opacity'] ?? ''}
                  step={0.1}
                  min={0}
                  max={1}
                  onChange={(e) => handleStyleValueChange(e, 'line-opacity')}
                  size="small"
                  valueLabelDisplay="auto"
                  sx={{ maxWidth: '100px', margin: 'auto' }}
                />
              </Box>
            </Box>
            <Typography variant="h6" className="nodeSubHead">
              Label
            </Typography>
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Font Size</Typography>
              <TextField
                className="col-2"
                id=""
                variant="standard"
                fullWidth
                value={vizEdgeStyles[selectedEdge]?.['font-size'] ?? ''}
                onChange={(e) => handleStyleValueChange(e, 'font-size')}
              />
            </Box>
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Text Color</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <input
                  type="color"
                  id="edgeFntColor"
                  style={{
                    width: '20px',
                    height: '24px',
                    marginTop: '1px',
                    marginLeft: '7px'
                  }}
                  value={vizEdgeStyles[selectedEdge]?.color ?? ''}
                  onChange={(e) => handleColorValueChange(e, 'color')}
                />
                <TextField
                  variant="standard"
                  style={{ width: '80px', marginRight: '4px' }}
                  value={vizEdgeStyles[selectedEdge]?.color ?? ''}
                  disabled
                />
                <RenderIcons
                  icon={getIcon(ColorPanelIcon, null, 18, 18)}
                  onClick={(e) => {
                    const edgeBgColor = document.getElementById('edgeFntColor');
                    edgeBgColor.click();
                  }}
                />
              </Box>
            </Box>
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Text Opacity</Typography>
              <Box className="col-2">
                <Slider
                  value={vizEdgeStyles[selectedEdge]?.['text-opacity'] ?? ''}
                  step={0.1}
                  min={0}
                  max={1}
                  onChange={(e) => handleStyleValueChange(e, 'text-opacity')}
                  size="small"
                  valueLabelDisplay="auto"
                  sx={{ maxWidth: '100px', margin: 'auto' }}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {edgeSavedRulesNameList !== undefined && (
          <Accordion
            className="accordT"
            expanded={expandedPanel === 'panel4'}
            onChange={handleExpanedPanel('panel4')}
          >
            <AccordionSummary
              classes={{ root: 'accordTrigger', content: 'accordTgrtxt' }}
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4d-content"
              id="panel4d-header"
              expandIcon={<ExpandMore />}
            >
              <Typography variant="h5" className="nodeAccordHead ">
                Edge Filter
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ marginBottom: '10px' }}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  size={'small'}
                  onChange={handleSavedRuleSelection}
                  value={selectedRules}
                  sx={{ width: '100%', display: 'inline-block' }}
                  options={edgeSavedRulesNameList.map((option) => option)}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox size="small" style={{ marginRight: 8 }} checked={selected} />

                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={'Select Rule'}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true
                      }}
                    />
                  )}
                />
                {/* <Autocomplete
              size={'small'}
              disablePortal
              onChange={(evt, value) => setSelectedRules(value)}
              value={selectedRules}
              options={edgeSavedRulesNameList}
              sx={{ width: 280, margin: '10px 10px 10px 0' }}
              renderInput={(params) => (
                <TextField
                  placeholder={'Select Rule'}
                  sx={{ border: '1px solid #ddd' }}
                  {...params}
                />
              )}
            /> */}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}
                className="ruleEngine"
              >
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined button group"
                  sx={{ width: '100%' }}
                >
                  <Button
                    disabled={selectedRules.length > 1}
                    variant="contained"
                    sx={groupBtnStyle}
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.view
                        ) {
                          handleApplyRules();
                        } else {
                          setValidationMsg('User does not have permission to view apply.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="contained"
                    sx={groupBtnStyle}
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.add
                        ) {
                          setToggleSaveModal(true);
                        } else {
                          setValidationMsg('User does not have permission to Add.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    disabled={!(selectedRules.length === 1)}
                    variant="contained"
                    sx={groupBtnStyle}
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.edit
                        ) {
                          setPatchToggleSaveModal(true);
                        } else {
                          setValidationMsg('User does not have permission to edit.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="contained"
                    sx={
                      (groupBtnStyle,
                      {
                        backgroundColor: '#f10404',
                        '&:hover': { backgroundColor: '#be0303!important' }
                      })
                    }
                    style={groupBtnStyleBdr}
                    onClick={() => {
                      if (
                        userDataFromLocal().modules.some((module) => module.module_name === 'Rules')
                      ) {
                        if (
                          userDataFromLocal().modules.find(
                            (module) => module.module_name === 'Rules'
                          ).permissions.delete
                        ) {
                          handleDeleteEdgeRule();
                        } else {
                          setValidationMsg('User does not have permission to delete.');
                          setOpenValidation(true);
                        }
                      }
                    }}
                  >
                    Delete
                  </Button>
                </ButtonGroup>

                <EdgeRuleEngine
                  currentMetaDataId={currentMetaDataId}
                  edgeSavedRulesNameList={edgeSavedRulesNameList}
                  setEdgeSavedRulesNameList={setEdgeSavedRulesNameList}
                  // allEdgesSettings={allEdgesSettings}
                  // setAllEdgesSettings={setAllEdgesSettings}
                  setRefreshStyleComp={setRefreshStyleComp}
                  cyToStateTwo={cyToState}
                  labelEdgeToDisplay={labelEdgeToDisplay}
                  selectedEdge={selectedEdge}
                  setSelectedEdge={setSelectedEdge}
                  toggleSaveModal={toggleSaveModal}
                  setToggleSaveModal={setToggleSaveModal}
                  patchToggleSaveModal={patchToggleSaveModal}
                  setPatchToggleSaveModal={setPatchToggleSaveModal}
                  savedRulesNameList={savedRulesNameList}
                  setSavedRulesNameList={setSavedRulesNameList}
                  setSavedRuleSelection={setSavedRuleSelection}
                  setSavedEdgeRuleData={setSavedEdgeRuleData}
                  savedEdgeRuleData={savedEdgeRuleData}
                  selectedRules={selectedRules}
                  setMutateSavedRuleList={setMutateSavedRuleList}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </>
  ) : (
    ''
  );
}

export default EdgeStyleSetting;

function rgbToHex(r, g, b) {
  console.log('rrr', r);
  const hexR = Math.floor(r[0]).toString(16).padStart(2, '0');
  const hexG = Math.floor(r[1]).toString(16).padStart(2, '0');
  const hexB = Math.floor(r[2]).toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
}
