import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Button,
  TextField,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { Save, Cancel } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../Page';

// services
import TopNavigationBar from '../TopNavigationBar';
import LeftNavigationBar from '../LeftNavigationBar';

function ForgetPassword() {
  const userInformation = useSelector((state) => state.userManagement.userDetailsAdd);
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState('');

  const validateForm = () => {
    const errors = {};
    if (!userInformation.fullname) {
      errors.fullname = 'Full Name is required';
    }
    if (!userInformation.username) {
      errors.username = 'Username is required';
    }
    if (!userInformation.email) {
      errors.email = 'Email is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddUser = () => {
    // If no ID is provided, it's an add operation.
    dispatch({
      type: 'STORE_USER_INFORMATION_DATA',
      payload: {
        key: 'userDetailsAdd',
        value: [...userInformation]
      }
    });
  };

  return (
    <Page title="Forget Password">
      <Container maxWidth="xl" className="noPadding">
        <TopNavigationBar />
        <Grid container marginTop={0} spacing={5} id="k-Graph-wrap">
          {/* <Grid item xs={3} id="k-graphLeft">
            <LeftNavigationBar />
          </Grid> */}
          <Grid item xs={12} id="k-graphRight" sx={{ paddingRight: '15px' }} className="useraccess">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 10px' }}>
              <Typography variant="h6" sx={{ color: '#004C99' }}>
                Create new password
              </Typography>
            </Box>
            <Box className="custom-form">
              <Typography
                sx={{
                  fontSize: '1rem',
                  padding: '15px 25px',
                  borderBottom: '2px solid #dee2e6',
                  fontWeight: '500',
                  color: '#004C99'
                }}
                className="headingLineUp"
              >
                Create New Password
                {/* {condition ? 'Edit and Update Information' : ' Please fill the information'} */}
              </Typography>

              <Box>
                {userInformation && (
                  <form onSubmit={handleAddUser}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          sx={{ margin: '5px 8px', fontSize: '0.750rem', textAlign: 'left' }}
                        >
                          User Name
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={userInformation.username}
                          sx={{ margin: '7px 5px' }}
                          helperText={formErrors.username}
                          error={!!formErrors.username}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          sx={{ margin: '5px 8px', fontSize: '0.750rem', textAlign: 'left' }}
                        >
                          Email
                        </Typography>
                        <TextField
                          variant="outlined"
                          type="email"
                          fullWidth
                          value={userInformation.email}
                          sx={{ margin: '7px 5px' }}
                          helperText={formErrors.email}
                          error={!!formErrors.email}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          sx={{ margin: '5px 8px', fontSize: '0.750rem', textAlign: 'left' }}
                        >
                          Set New Password:
                        </Typography>
                        <TextField
                          variant="outlined"
                          type="password"
                          fullWidth
                          value={userInformation.password}
                          onChange={(e) =>
                            dispatch({
                              type: 'STORE_USER_INFORMATION_DATA',
                              payload: {
                                key: 'userDetailsAdd',
                                value: { ...userInformation, password: e.target.value }
                              }
                            })
                          }
                          sx={{ margin: '7px 5px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          sx={{ margin: '5px 8px', fontSize: '0.750rem', textAlign: 'left' }}
                        >
                          Confirm Password:
                        </Typography>
                        <TextField
                          variant="outlined"
                          type="password"
                          fullWidth
                          value={userInformation.password}
                          onChange={(e) =>
                            dispatch({
                              type: 'STORE_USER_INFORMATION_DATA',
                              payload: {
                                key: 'userDetailsAdd',
                                value: { ...userInformation, password: e.target.value }
                              }
                            })
                          }
                          sx={{ margin: '7px 5px' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-end" mt={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Save />}
                          onClick={() => {
                            validateForm();
                            handleAddUser();
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="secondary" startIcon={<Cancel />}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default ForgetPassword;
