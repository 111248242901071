import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Close } from '@material-ui/icons';
import { Delete, Edit, Refresh, Visibility } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
// Enqueue Snackbar

// icons import
import { getIcon, DataSourceCheckIcon } from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
import { userDataFromLocal } from '../../../utils/getUserDetails';

const { SaveNodesAndEdges, PostApiByUrl, GetApiByUrl, PatchApiByUrl, DeleteApiByUrl } =
  KnowledgeGraphService;

const KnowledgeGraphModelComp = ({
  cyToState,
  setRecallEverything,
  setRefreshKGComp,
  projectName,
  compTyp,
  hideSomeIcons,
  modelSwitcher
}) => {
  const dispatch = useDispatch();
  // console.log('cyToStatecyToState', cyToState ? cyToState.elements() : '');
  const { experimentId, experimentName, modelId } = useParams();
  // console.log('experimentNamm');
  const [loder, setLoder] = useState(false);

  const [allNodesSettings, setAllNodesSettings] = useState({
    'background-color': '#64bdf9',
    label: 'data(label)',
    height: 80,
    width: 80,
    padding: 0,
    'background-fit': 'cover',
    'background-opacity': 1,
    'border-width': 2,
    'border-style': 'solid',
    'border-color': '#52a8e1',
    'border-opacity': 1,
    shape: 'ellipse',
    'background-repeat': 'no-repeat',
    'background-image': [],
    'background-image-opacity': 1,
    'font-size': 9,
    // 'text-margin-y': '-7px',
    'text-valign': 'center',
    'text-halign': 'center',
    color: '#000',
    'text-opacity': 1
  });

  const [allEdgesSettings, setAllEdgesSettings] = useState({
    'line-color': '#f9ab62',
    label: 'data(label)',
    width: 3,
    padding: 0,
    'curve-style': 'bezier',
    'line-style': 'solid',
    'target-arrow-shape': 'none',
    'target-arrow-color': '#c4c4c4',
    'line-opacity': 100
  });

  const navigate = useNavigate();

  const dataSourceListItems = useSelector((state) => state.knowledgeGraph);
  const {
    allSelectedData: {
      currentdataSetId,
      retrivedDatasetId,
      currentSelectedmodelId,
      currentMetaDataId
    }
  } = dataSourceListItems;

  const { enqueueSnackbar } = useSnackbar();

  console.log('currentdataSetIdcurrentdataSetId 1', currentdataSetId);
  console.log('currentdataSetIdcurrentdataSetId 2', retrivedDatasetId);

  // State for the new model name entered by the user
  const [newModelName, setNewModelName] = useState('');

  // console.log(newModelName, 'newModelName');

  // State for the list of models
  const [models, setModels] = useState([]);
  const [new_editModel, setNew_editModel] = useState({
    dialog: false,
    dialogType: '',
    modelID: '',
    newModelName: '',
    modelDesc: ''
  });

  // console.log(models, 'modelsmodels');

  // State for the active model
  // const [activeModel, setActiveModel] = useState(null);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [confirmModelDeletePop, setConfirmModelDeletePop] = useState({
    open: false,
    modelObj: {},
    expId: null
  });
  // console.log(showEmptyError, 'showEmptyError');

  // Handler function to update the new model name state when the user types in the input field
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setNewModelName(inputValue);
    if (!inputValue.trim()) {
      setShowEmptyError(true);
    } else {
      setShowEmptyError(false);
    }
  };

  // Handler function to add a new model to the list when the user submits the form
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Check if the new model name already exists in the models array
  //   if (!newModelName.trim()) {
  //     setShowEmptyError(true); // Show error for empty input
  //     return; // Exit the function without saving the model
  //   } else {
  //     setShowEmptyError(false);
  //   }

  //   // Check if the new model name already exists in the models array
  //   if (models.some((model) => model.link_model_name === newModelName)) {
  //     // Display an error message to the user and do not add the new model to the list
  //     alert(`The model name "${newModelName}" already exists. Please choose a different name.`);
  //     return;
  //   }

  //   dispatch({
  //     type: 'STORE_ALL_SELECTED_DATA',
  //     payload: {
  //       key: 'currentMetaDataId',
  //       value: null
  //     }
  //   });
  //   const authToken = userDataFromLocal();
  //   // const params = `&user_id=${authToken.userID}`;
  //   const modelCredential = {
  //     project_id: experimentId,
  //     link_model_name: newModelName,
  //     creator_user_id: authToken.userID
  //   };
  //   const saveSingleModel = await PostApiByUrl(
  //     '/visual/neo4j/get_project_link_model',
  //     modelCredential
  //   );
  //   if (saveSingleModel.status === 'success') {
  //     // Call list of all models api
  //     getSvedModels();
  //     // Adding the new model to the beginning of the models array to show the latest model first
  //     setNewModelName('');

  //     dispatch({
  //       type: 'STORE_ALL_SELECTED_DATA',
  //       payload: {
  //         key: 'currentMetaDataId',
  //         value: null
  //       }
  //     });
  //     setRecallEverything(true);

  //     navigate(
  //       `/knowledgegraph/edit/${experimentId}/${experimentName}/${saveSingleModel.data.link_model_id}`
  //     );

  //     enqueueSnackbar(`Model ${newModelName} Created Successfully`, {
  //       variant: saveSingleModel.status,
  //       autoHideDuration: 4000
  //     });

  //     if (setRefreshKGComp) {
  //       setRefreshKGComp(new Date());
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if the new model name already exists in the models array
    if (!new_editModel.newModelName.trim()) {
      setShowEmptyError(true); // Show error for empty input
      return;
    } else {
      setShowEmptyError(false);
    }

    // Check if the new model name already exists in the models array
    if (models.some((model) => model.link_model_name === new_editModel.newModelName)) {
      // Display an error message to the user and do not add the new model to the list
      alert(
        `The model name "${new_editModel.newModelName}" already exists. Please choose a different name.`
      );
      return;
    }

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'currentMetaDataId',
        value: null
      }
    });
    const authToken = userDataFromLocal();
    // const params = `&user_id=${authToken.userID}`;

    if (new_editModel.dialogType === 'create') {
      const modelCredential = {
        project_id: experimentId,
        creator_user_id: authToken.userID,
        link_model_name: new_editModel.newModelName,
        description: new_editModel.modelDesc
      };

      const saveSingleModel = await PostApiByUrl(
        '/visual/neo4j/get_project_link_model',
        modelCredential
      );
      if (saveSingleModel.status === 'success') {
        // Call list of all models api
        getSvedModels();
        // Adding the new model to the beginning of the models array to show the latest model first
        // setNewModelName('');

        setNew_editModel({
          dialog: false,
          dialogType: '',
          modelID: '',
          newModelName: '',
          modelDesc: ''
        });

        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'currentMetaDataId',
            value: null
          }
        });
        setRecallEverything(true);

        navigate(
          `/knowledgegraph/edit/${experimentId}/${experimentName}/${saveSingleModel.data.link_model_id}`
        );

        enqueueSnackbar(`Model ${new_editModel.newModelName} Created Successfully`, {
          variant: saveSingleModel.status,
          autoHideDuration: 4000
        });
      } else if (saveSingleModel.code === 403) {
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: saveSingleModel.message }
          }
        });
      } else {
        enqueueSnackbar(saveSingleModel.message, {
          variant: saveSingleModel.status,
          autoHideDuration: 3000
        });
      }
      if (setRefreshKGComp) {
        setRefreshKGComp(new Date());
      }
    } else if (new_editModel.dialogType === 'edit') {
      const modelCredential = {
        project_id: experimentId,
        link_model_id: new_editModel.modelID,
        creator_user_id: authToken.userID,
        // link_model_name: new_editModel.newModelName,
        model_name: new_editModel.newModelName,
        description: new_editModel.modelDesc
      };

      const saveSingleModel = await PatchApiByUrl(
        '/visual/neo4j/get_project_link_model',
        modelCredential
      );
      if (saveSingleModel.status === 'success') {
        // Call list of all models api
        getSvedModels();
        // Adding the new model to the beginning of the models array to show the latest model first
        // setNewModelName('');

        setNew_editModel({
          dialog: false,
          dialogType: '',
          modelID: '',
          newModelName: '',
          modelDesc: ''
        });

        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'currentMetaDataId',
            value: null
          }
        });
        setRecallEverything(true);

        navigate(
          `/knowledgegraph/edit/${experimentId}/${experimentName}/${saveSingleModel.data.link_model_id}`
        );

        enqueueSnackbar(`Model ${new_editModel.newModelName} Updated Successfully`, {
          variant: saveSingleModel.status,
          autoHideDuration: 4000
        });
      } else if (saveSingleModel.code === 403) {
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: saveSingleModel.message }
          }
        });
      }
      if (setRefreshKGComp) {
        setRefreshKGComp(new Date());
      }
    }
  };

  //  Handler function to delete a model from the list when the user clicks the delete icon
  // const handleDelete = (index) => {
  //   const newModels = [...models];
  //   newModels.splice(index, 1);
  //   setModels(newModels);
  // };

  const deleteModel = async (experimentId, model_id, model_name) => {
    try {
      const authToken = userDataFromLocal();
      const modelCredential = {
        project_ids: [Number(experimentId)],
        model_ids: [Number(model_id)],
        creator_user_id: authToken.userID
      };

      console.log(model_id, 'projectsandmodels');
      // return;

      const deletingModel = await DeleteApiByUrl(
        '/visual/neo4j/get_project_link_model',
        modelCredential
      );

      if (deletingModel?.status === 'success' && deletingModel?.code === 200) {
        // Update state and reset selectedProjectIds and selectedModelIds
        const updatedData = models.filter(
          (item) => item.project_id !== experimentId && item.link_id !== model_id
        );
        setModels(updatedData);
        setConfirmModelDeletePop({
          expId: null,
          open: false,
          modelObj: {}
        });

        enqueueSnackbar(`Model ${model_name} deleted successfully.`, {
          variant: deletingModel.status,
          autoHideDuration: 3500
        });

        if (Number(model_id) === Number(modelId)) {
          // dispatch({
          //   type: 'STORE_ALL_SELECTED_DATA',
          //   payload: { key: 'currentSelectedmodelId', value: null }
          // });

          dispatch({
            type: 'STORE_ALL_SELECTED_DATA',
            payload: {
              key: 'hideSideBar',
              value: 'hideBehind'
            }
          });
          navigate(`/knowledgegraph/edit/${experimentId}/${experimentName}`);
        }
      } else if (deletingModel.code === 403) {
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: deletingModel.message }
          }
        });
        setConfirmModelDeletePop({
          expId: null,
          open: false,
          modelObj: {}
        });
      } else {
        setConfirmModelDeletePop({
          expId: null,
          open: false,
          modelObj: {}
        });

        enqueueSnackbar('Problem in Model Deletion', {
          variant: 'error',
          autoHideDuration: 4000
        });
        // console.error('Failed to delete models:', deletingModel);
      }
    } catch (error) {
      console.error('Error deleting models:', error);
    }
  };

  // Handler function to set the active model when the user clicks on a model box
  const handleSetActiveModel = (index, modelId) => {
    // console.log('modelId', modelId);
    if (index !== null && modelId) {
      // setActiveModel(index);
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'currentSelectedmodelId', value: modelId }
      });
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (modelId && models.length > 0) {
      const m_index = models.findIndex((item) => item.link_id === Number(modelId));
      handleSetActiveModel(m_index, Number(modelId));
      // console.log('mID', m_index, modelId);
    }
  }, [modelId, models]);

  const getSvedModels = async () => {
    const authToken = userDataFromLocal();
    console.log('authToken', authToken);
    const params = `&user_id=${authToken.userID}`;
    const allSavedModels = await GetApiByUrl(
      'visual/neo4j/get_project_link_model?project_id=' + experimentId + params
    );
    // console.log('allSavedModels', allSavedModels.data);
    if (allSavedModels.status === 'success') {
      allSavedModels.data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.modified_time) - new Date(a.modified_time);
      });
      setModels(allSavedModels.data);
      dispatch({
        type: 'STORE_LISTS_DATA',
        payload: { key: 'allModelsForProjects', value: allSavedModels.data }
      });
    }
  };

  // Handler function to save graph to backend
  const saveKnowledgeGraphByModel = async (_action) => {
    // setLoder(true);
    const stylingArray = [];
    if (cyToState) {
      cyToState.nodes().forEach((iten) => {
        const nodeObj = {
          selector: `node[label='${iten.data().label}']`
        };
        console.log('iten.data() node', iten.data().label);
        const nodeStyleObj = {};
        Object.keys(allNodesSettings).forEach((key) => (nodeStyleObj[key] = iten.style()[key]));
        // console.log('iten.style()', nodeStyleObj);
        nodeObj.style = nodeStyleObj;
        stylingArray.push(nodeObj);
      });
      cyToState.edges().forEach((iten) => {
        const edgeObj = {
          selector: `edge[label='${iten.data().label}']`
        };
        console.log('iten.data() edge', iten.data().label);
        const edgeStyleObj = {};
        Object.keys(allEdgesSettings).forEach((key) => (edgeStyleObj[key] = iten.style()[key]));
        edgeObj.style = edgeStyleObj;
        // console.log('iten.style()edge', iten.style()); // allEdgesSettings
        stylingArray.push(edgeObj);
      });
      const graphMetaData = [];
      cyToState.elements().forEach((iten) => graphMetaData.push(iten.data()));
      // console.log('stylingArray', stylingArray);

      const metaCred = {
        project_id: experimentId,
        link_id: currentSelectedmodelId,
        nodes_metadata: graphMetaData,
        others: {
          savedDatasetId: currentdataSetId
            ? currentdataSetId
            : retrivedDatasetId
            ? retrivedDatasetId
            : '',
          stylying: stylingArray
        }
      };
      let saveMetaData = '';
      if (currentMetaDataId && currentMetaDataId !== null && currentMetaDataId !== '') {
        metaCred.meta_id = currentMetaDataId;
        saveMetaData = await PatchApiByUrl('/visual/manage_metadata', metaCred);
      } else {
        saveMetaData = await PostApiByUrl('/visual/manage_metadata', metaCred);
      }

      const linkModeldataFrom = [];
      let _datasetID = null;
      cyToState.edges().forEach((element) => {
        console.log('cyToStatedataComp', element.data());
        const dataFromEdge = element.data();
        const dataFromSource = cyToState.nodes(`#${dataFromEdge.source}`).data();
        const dataFromTarget = cyToState.nodes(`#${dataFromEdge.target}`).data();
        console.log('cyToStatedataComp', dataFromSource);
        console.log('cyToStatedataComp', dataFromTarget);
        const sourcePropValues = [];
        dataFromSource.propertiesValue.forEach((item) => {
          if (typeof item === 'object') {
            sourcePropValues.push(item.app_column_name);
          } else {
            sourcePropValues.push(item);
          }
        });

        const targetPropValues = [];
        dataFromTarget.propertiesValue.map((item) => {
          if (typeof item === 'object') {
            targetPropValues.push(item.app_column_name);
          } else {
            targetPropValues.push(item);
          }
        });
        console.log('dataFromSource', sourcePropValues);
        console.log('dataFromSource', targetPropValues);
        _datasetID = dataFromSource.datasetValue.dataset_id;
        const modelObj = {
          node1: {
            create_merge: 'merge',
            label: dataFromSource.label,
            labelType: dataFromSource.labelType,
            app_table_name: dataFromSource.datasetValue.table_name,
            dataset_id: dataFromSource.datasetValue.dataset_id,
            properties: sourcePropValues
          },
          node2: {
            create_merge: 'merge',
            label: dataFromTarget.label,
            labelType: dataFromTarget.labelType,
            app_table_name: dataFromTarget.datasetValue.table_name,
            dataset_id: dataFromTarget.datasetValue.dataset_id,
            properties: targetPropValues
          },
          relation: {
            name: dataFromEdge.label,
            create_merge: 'create',
            from: 'node1',
            to: 'node2',
            properties: getEdgesProperty(
              dataFromEdge.properties,
              dataFromSource.properties, // sourcePropValues,
              dataFromTarget.properties // targetPropValues
            )
          }
        };
        linkModeldataFrom.push(modelObj);
      });
      const credential = {
        action: _action,
        project_id: experimentId,
        project_name: experimentName,
        link_model_id: currentSelectedmodelId,
        dataset_id: _datasetID
          ? _datasetID
          : currentdataSetId
          ? currentdataSetId
          : retrivedDatasetId
          ? retrivedDatasetId
          : '',
        link_model_data: linkModeldataFrom
      };
      console.log('create_nodes', credential);

      let ifNodesSaved = null;
      (async () => {
        ifNodesSaved = await SaveNodesAndEdges(credential, null);
        if (ifNodesSaved.status === 'success' && ifNodesSaved.code === 200) {
          enqueueSnackbar(`Model Created Successfully`, {
            variant: 'success',
            autoHideDuration: 4000
          });
        }
      })();
      navigate('/knowledgegraph');

      // setLoder(false);
      // console.log('ifNodesSaved', ifNodesSaved);
      // if (ifNodesSaved.status === 'success' && ifNodesSaved.code === 200) {
      //   enqueueSnackbar(`Model Created Successfully`, {
      //     variant: 'success',
      //     autoHideDuration: 4000
      //   });
      //   // navigate('/knowledgegraph');
      //   // const authToken = userDataFromLocal();
      //   // console.log('authToken', authToken);
      //   // const storedData = JSON.parse(localStorage.getItem('sseTaskID')) || [];
      //   // storedData.push(ifNodesSaved.task_id);
      //   // localStorage.setItem('sseTaskID', JSON.stringify(storedData));
      //   // navigate('/knowledgegraph');
      //   // const params = `&user_id=${authToken.userID}`;
      //   // const elementsData = await GetApiByUrl(
      //   //   'visual/neo4j/get_project_link_model?project_id=' +
      //   //     experimentId +
      //   //     '&link_id=' +
      //   //     currentSelectedmodelId +
      //   //     params
      //   // );
      //   // if (
      //   //   elementsData.status === 'success' &&
      //   //   elementsData.code === 200 &&
      //   //   elementsData.data.length > 0
      //   // ) {
      //   //   navigate(
      //   //     '/knowledgegraph/view/visualization/' + experimentId + '/' + currentSelectedmodelId
      //   //   );
      //   // }
      // }
    }
  };

  // useEffect hook to run additional logic or API calls when the models state updates
  useEffect(() => {
    // You can add any additional logic or API calls here that should run when the component mounts or updates.
    getSvedModels();
  }, []);

  // Rendering the component
  return (
    <div>
      {/* Edit/Create Model Dialog */}
      <Dialog open={new_editModel.dialog}>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">
            {new_editModel.dialogType === 'create' ? 'Create Model' : 'Edit Model'}
          </Typography>
          <IconButton
            //  onClick={(e) => setOpenValidation(false)}
            onClick={(e) =>
              setNew_editModel({
                dialog: false,
                dialogType: '',
                modelID: '',
                newModelName: '',
                modelDesc: ''
              })
            }
            size="small"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
          // sx={{ minWidth: 700 }}
          >
            <Box
              py={1}
              px={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              textAlign="center"
            >
              <Typography sx={{ mt: 2 }} variant="body2">
                Model Name:
              </Typography>
              <TextField
                size="small"
                value={new_editModel.newModelName}
                onChange={(e) =>
                  setNew_editModel((prev) => ({
                    ...prev,
                    newModelName: e.target.value
                  }))
                }
                placeholder="Enter Model Name"
                // helperText="Incorrect entry."
              />
            </Box>
            <Box
              py={1}
              px={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              textAlign="center"
            >
              <Typography sx={{ mt: 2 }} variant="body2">
                Model Description:
              </Typography>
              <TextField
                size="small"
                multiline={true}
                sx={{ width: '100%' }}
                rows={3}
                value={new_editModel.modelDesc}
                onChange={(e) =>
                  setNew_editModel((prev) => ({
                    ...prev,
                    modelDesc: e.target.value
                  }))
                }
                placeholder="Enter Model Description"
                // helperText="Incorrect entry."
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="dialogBtn" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {loder && (
        <Box
          style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            background: 'rgba(0,0,0,0.7)',
            top: '0',
            left: '0',
            zIndex: '10000000'
          }}
        >
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: '10000000'
            }}
          />
        </Box>
      )}
      {/* Component for adding a new model */}
      {hideSomeIcons !== 'hideSomeIcons' && (
        <Button
          className="form-container"
          variant="contained"
          style={{
            border: showEmptyError === true ? '1px solid red' : '1px solid #ddd',
            justifyContent: 'center'
          }}
          onClick={() => {
            setNew_editModel({
              dialog: true,
              dialogType: 'create',
              modelID: '',
              newModelName: '',
              modelDesc: ''
            });
          }}
        >
          Create New Model
        </Button>
      )}
      {/* Component for displaying existing models */}
      <Box className="models-container">
        {models.length > 0 &&
          models.map((model, index) => (
            <Paper
              key={index}
              // className={`model-box ${activeModel === index ? 'active' : ''}`}
              className={`model-box ${currentSelectedmodelId === model.link_id ? 'active' : ''}`}
              // function to set active model
            >
              <Box
                sx={{
                  '&:hover': {
                    background: '#c8d8df'
                  },
                  padding: '9px',
                  borderTopLeftRadius: 'inherit',
                  borderTopRightRadius: 'inherit',
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  // metadata api error
                  if (hideSomeIcons !== 'hideSomeIcons') {
                    dispatch({
                      type: 'STORE_ALL_SELECTED_DATA',
                      payload: {
                        key: 'currentMetaDataId',
                        value: null
                      }
                    });
                    setRecallEverything(true);

                    navigate(
                      `/knowledgegraph/edit/${experimentId}/${experimentName}/${model.link_id}`
                    );
                  } else {
                    navigate(
                      '/knowledgegraph/view/visualization/' + experimentId + '/' + model.link_id
                    );
                  }
                  if (setRefreshKGComp) {
                    setRefreshKGComp(new Date());
                  }
                }}
              >
                {/* Model name */}
                <Typography variant="h6" className="model-title">
                  {model.link_model_name}
                </Typography>

                {/* Model description */}
                <Typography variant="body2" className="model-title">
                  {model.description}
                </Typography>

                {/* Model creation date */}
                <Typography variant="subtitle2" className="model-created-date">
                  {model.modified_time
                    ? new Date(model.modified_time).toLocaleString('en-IN', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })
                    : model.created_time
                    ? new Date(model.created_time).toLocaleString('en-IN', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })
                    : 'N/A'}
                </Typography>
              </Box>
              <Box className="model-footer thisThat">
                {/* Icons for actions on model */}
                <Box className="icons-container">
                  <Tooltip title="View">
                    <IconButton
                      onClick={() => {
                        navigate(
                          '/knowledgegraph/view/visualization/' + experimentId + '/' + model.link_id
                        );
                        if (setRefreshKGComp) {
                          setRefreshKGComp(new Date());
                        }
                        modelSwitcher((prevLoader) => ({
                          ...prevLoader,
                          switchModels: true
                        }));
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  {hideSomeIcons !== 'hideSomeIcons' && (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setNew_editModel({
                              dialog: true,
                              dialogType: 'edit',
                              modelID: model.link_id,
                              newModelName: model.link_model_name,
                              modelDesc: model.description
                            });
                            // metadata api error
                            // dispatch({
                            //   type: 'STORE_ALL_SELECTED_DATA',
                            //   payload: {
                            //     key: 'currentMetaDataId',
                            //     value: null
                            //   }
                            // });
                            // setRecallEverything(true);

                            // navigate(
                            //   `/knowledgegraph/edit/${experimentId}/${experimentName}/${model.link_id}`
                            // );

                            // if (setRefreshKGComp) {
                            //   setRefreshKGComp(new Date());
                            // }
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() =>
                            setConfirmModelDeletePop({
                              expId: experimentId,
                              open: true,
                              modelObj: model
                            })
                          }
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {hideSomeIcons !== 'hideSomeIcons' && (
                    <Tooltip title="Refresh">
                      <IconButton onClick={() => saveKnowledgeGraphByModel('update_nodes')}>
                        <Refresh />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                {hideSomeIcons !== 'hideSomeIcons' && (
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      className="knowledgeGraph-button"
                      onClick={() => saveKnowledgeGraphByModel('create_nodes')}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
              {/* Save button  */}
            </Paper>
          ))}
        <Dialog
          open={confirmModelDeletePop.open}
          onClose={(e) => {
            setConfirmModelDeletePop({
              expId: null,
              open: false,
              modelObj: {}
            });
          }}
          maxWidth
          fullWidth
          sx={{ width: '35vw', margin: 'auto' }}
        >
          <DialogContent>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              Model Name:
              <span style={{ fontWeight: '500', marginLeft: '10px' }}>
                {confirmModelDeletePop.modelObj.link_model_name}
              </span>
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: '1rem', padding: '1rem', lineHeight: '1.25rem' }}
            >
              Are you sure want to delete this Model?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                setConfirmModelDeletePop({
                  expId: null,
                  open: false,
                  modelObj: {}
                })
              }
            >
              No
            </Button>
            <Button
              className="globalSubmitButton"
              classes={{
                root: 'globalSubmitButton globalSubmitButtonDelete autoButton',
                label: 'globalSubmitButtonLabel globalSubmitButtonDeleteLabel'
              }}
              variant="contained"
              color="secondary"
              onClick={() =>
                deleteModel(
                  confirmModelDeletePop.expId,
                  confirmModelDeletePop.modelObj.link_id,
                  confirmModelDeletePop.modelObj.link_model_name
                )
              }
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
  // Component to display knowledge graph model that consists of a form to add a new model and a list of existing models with various actions available for each model.
};

export default KnowledgeGraphModelComp;

function getEdgesProperty(dataFromEdge, sourcePropValues, targetPropValues) {
  console.log('targetPropValues', dataFromEdge);
  console.log('targetPropValues', targetPropValues);
  console.log('targetPropValues', sourcePropValues);
  let edgesprops = {
    node1: [],
    node2: []
  };
  if (dataFromEdge && dataFromEdge.length > 0) {
    dataFromEdge.forEach((item) => {
      console.log('targetPropValues item', item);
      sourcePropValues.forEach((sorceArr) => {
        if (sorceArr.app_column_name === item.app_column_name) {
          edgesprops.node1.push(item.app_column_name);
        }
      });

      targetPropValues.forEach((sorceArr) => {
        if (sorceArr.app_column_name === item.app_column_name) {
          edgesprops.node2.push(item.app_column_name);
        }
      });
    });
  }
  if (edgesprops.node1.length === 0 && edgesprops.node2.length === 0) {
    edgesprops = {};
  }
  // console.log('edgesprops', edgesprops);
  return edgesprops;
}
