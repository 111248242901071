import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Card,
  Box,
  Stack,
  Container,
  CardContent,
  Badge,
  List,
  ListItem,
  generate,
  ListItemIcon,
  ListItemText,
  Demo,
  MailIcon,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
  Grid,
  Item,
  AppBar,
  IconButton,
  MenuIcon,
  SearchIcon,
  Toolbar,
  InputBase,
  Autocomplete,
  Menu,
  MenuItem,
  Checkbox,
  Tooltip
} from '@mui/material';

import { styled, alpha } from '@mui/material/styles';
import { FiEdit } from 'react-icons/fi';
import { Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';

// components
import Page from '../Page';

import {
  getIcon,
  KnowledgegraphIcon,
  MoreIcon,
  DashboardIcon,
  SharedWithAllIcon,
  RelationShipCategoryIcon,
  DomainMasterIcon,
  RelationshipIcon,
  RoundedPlusIcon,
  UserIcon
} from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
import PageTitle from '../../global/PageHeading';
// services
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import TopNavigationBar from '../TopNavigationBar';
import LeftNavigationBar from '../LeftNavigationBar';

// user Details

import { userDataFromLocal } from '../../../utils/getUserDetails';
import { fDate, fDateTime } from '../../../utils/formatTime';
import { camlecaseAndUnderScore } from '../../../utils/camleCaseAndUScroe';
import FunctionButtons from '../KnowledgeGraph/DataGridButtons';

const ITEM_HEIGHT = 48;

const { CreateVisualization, ProjectList, SearchNodes, DeleteNodes, GetApiByUrl, DeleteApiByUrl } =
  KnowledgeGraphService;

/**
 *
 * @returns react-element
 */
function SharedWithMe() {
  // react Router Dom Variable
  const navigate = useNavigate();
  console.log(useLocation());
  const { enqueueSnackbar } = useSnackbar();
  const chartRef = useRef(null);

  // const [activeList, setActiveList] = useState('');
  const [anchorEl, setAnchorEl] = useState(null); // states for more icon pop over
  const [anchorEl2, setAnchorEl2] = useState(null); // state for other menu item
  const [currentSelection, setCurrentSelection] = useState(null);
  const [currentSelectionName, setCurrentSelectionName] = useState(null);

  const { experimentId, experimentName } = useParams;
  const [searchKnowledgeG, setSearchKnowledgeG] = useState('');
  const [inputText, setInputText] = useState(''); // states for search bar
  const [activeList, setActiveList] = useState(3);
  const [mutateList, setMutateList] = useState(); // for mutate state
  const [showData, setShowData] = useState([]); // states for showing data in card item
  // states using for autocomplete search bar
  const [openSearch, setOpenSearch] = useState(false);
  const [options, setOptions] = useState([]);
  const [openDelete, setOpenDelete] = useState(false); // dialog box for delete
  const [deletableArray, setDeletableArray] = useState([]);
  console.log('deletableArray', deletableArray);
  const loading = openSearch && options.length === 0;
  // used for moreicon pop over
  const open = Boolean(anchorEl);
  const openOtherMenu = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOtherMenuClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleOtherMenuClose = (event) => {
    setAnchorEl2(null);
  };

  //  For handling open delete popup
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  //  For handling close delete popup
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setAnchorEl(null);
  };
  // create visualization state
  const [openVisualization, setOpenVisualization] = useState(false);
  const [openVisualizationIdentifier, setOpenVisualizationIdentifier] = useState('');

  // handleCloseVisualization functoin
  const openSpecificVisualization = (identifier) => {
    setOpenVisualizationIdentifier(identifier.toLowerCase());
    setOpenVisualization(true);
  };
  // handleCloseVisualization functoin
  const handleCloseVisualization = () => {
    setOpenVisualization(false);
    setOpenVisualizationIdentifier('');
  };
  // autocomplete functionality
  const onChangeHandle = async (value) => {
    const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const response = await SearchNodes({ experiment_name: value, crea_user: userDetails.userName });
    if (response.status === 'success') {
      // setOptions(response.data);
      if (response.data.length > 0) {
        setOptions(response.data);
      }
    }
  };
  // for deleting the card
  const handleDelete = async (e, itemId) => {
    console.log(itemId);
    const authToken = userDataFromLocal();
    const credential = {
      project_ids: itemId,
      creator_user_id: authToken.userID
    };
    const deleteCard = await DeleteApiByUrl('/visual/manage_projects', credential);
    console.log('deleteCard', deleteCard);
    enqueueSnackbar(deleteCard.message, {
      variant: deleteCard.status,
      autoHideDuration: 2000
    });
    if (deleteCard.status === 'success') {
      handleClose();
      handleOtherMenuClose();
      handleCloseDelete();
      setDeletableArray([]);
      setMutateList(new Date());
    }
  };

  const makeDeletable = (e, itemId) => {
    console.log('itemIditemId', itemId, e.target.checked);
    const cuurentCheck = e.target.checked;
    const prevData = deletableArray;
    if (cuurentCheck) {
      prevData.push(itemId);
      setDeletableArray([...prevData]);
    } else {
      const filterData = prevData.filter((ids) => ids !== itemId);
      setDeletableArray([...filterData]);
    }
    // deletableArray
  };

  useEffect(() => {
    (async () => {
      const authToken = userDataFromLocal();
      const params = `?user_id=${authToken.userID}`;
      const projectListData = await ProjectList(params); // api to fire projectlist
      console.log(projectListData, 'checkprojectlistdata');
      if (projectListData.status === 'success') {
        projectListData.data.projects.sort((a, b) => {
          return new Date(b.modified_date) - new Date(a.modified_date);
        });
        setShowData(projectListData.data.projects);
      }
    })();
    // condition for autocomplete search box
    if (!openSearch) {
      setOptions([]);
    }
  }, [openSearch, mutateList]);
  return (
    <Page title="Shared Visualizations">
      <Container maxWidth="xl" className="noPadding bg-h-100">
        <TopNavigationBar />
        <Grid container marginTop={0} spacing={5} id="k-Graph-wrap">
          {/* <Grid item xs={3} id="k-graphLeft">
            <LeftNavigationBar />
          </Grid> */}
          <Grid item xs={12} id="k-graphRight" sx={{ backgroundColor: '#f4f8fd' }}>
            <Box className="graphRight-info active">
              {/* <Card
                sx={{ minWidth: 275 }}
                className="k-list-item"
                onClick={() => {
                  //  navigate(`/knowledgegraph/add`);
                  openSpecificVisualization('dialog3');
                }}
              >
                <CardContent className="k-card-cell">
                  <Typography
                    sx={{ textAlign: 'center', marginBottom: 0, display: 'flex' }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <RenderIcons icon={getIcon(RoundedPlusIcon, null, 28, 28)} color="primary" />
                    Add Relationship
                  </Typography>
                </CardContent>
              </Card> */}
              {/* open DialogBox for New Visualization */}
              <Dialog
                open={
                  openVisualization &&
                  openVisualizationIdentifier === String('dialog3').toLowerCase()
                }
                onClose={(e) => handleCloseVisualization()}
              >
                <DialogTitle>Create New Relationship</DialogTitle>
                <DialogContent>
                  <DialogContentText>Enter Relationship Name</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="name"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={(e) => handleCloseVisualization()}>Cancel</Button>
                  <Button onClick={(e) => handleCloseVisualization()}>Submit</Button>
                </DialogActions>
              </Dialog>
              {/* using map function for showing data in card item */}
              {/* 
              {/* 
              <Card sx={{ minWidth: 275 }} className="k-list-item k-cardLoop">
                <CardContent className="k-card-cell">
                  <Typography
                    sx={{ fontSize: 14, textAlign: 'center' }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Shared Graph #1 <br />
                    {fDate(new Date())} <br />
                  </Typography>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    onClick={(e) => {
                      handleOtherMenuClick(e);
                    }}
                    className="moreBtn"
                  >
                    <RenderIcons icon={getIcon(MoreIcon, null, 16, 16)} color="primary" />
                  </IconButton>
                  <IconButton aria-label="more" id="long-button" className="checkBtn">
                    <Checkbox onChange={(e) => makeDeletable(e)} />
                  </IconButton>

                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl2}
                    open={openOtherMenu}
                    className="menuPop"
                    classes={{ paper: 'menuDialog' }}
                    onClose={handleOtherMenuClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch'
                      }
                    }}
                  >
                    <OtherMenuComponent
                      //  navigate={navigate}
                      handleDelete={handleDelete}
                      // currentSelection={currentSelection}
                      // currentSelectionName={currentSelectionName}
                      handleOpenDelete={handleOpenDelete}
                      handleCloseDelete={handleCloseDelete}
                      openDelete={openDelete}
                    />
                  </Menu>
                </CardContent>
              </Card> */}

              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <Typography variant="h6" sx={{ color: '#004C99', margin: '10px 0 20px' }}>
                    Shared Visualization
                  </Typography>
                </Box>
              </Grid>
              <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box className="cardOuter cardOuterWithBorder">
                    <Box className="k-cardLoop-New">
                      <Box
                        className="k-card-cell cs-padding"
                        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100px' }}
                      >
                        <Box className="flex-container">
                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: 'center'
                            }}
                            color="text.secondary"
                            className="project-name"
                            gutterBottom
                          >
                            Testing 04
                          </Typography>
                        </Box>
                        <Box className="flex-item">
                          <p className="create-date"> {fDate(new Date())} </p>
                          <p className="project-status"> Active</p>
                        </Box>
                      </Box>
                      <Box className="cardBottom" style={{ justifyContent: 'flex-end' }}>
                        <Box>
                          <Tooltip title="Delete" arrow placement="top">
                            <IconButton className="cardIconFooter" sx={{ padding: '0px 8px' }}>
                              <Delete size={20} sx={{ color: '#722F37' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box className="cardOuter cardOuterWithBorder">
                    <Box className="k-cardLoop-New">
                      <Box
                        className="k-card-cell cs-padding"
                        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100px' }}
                      >
                        <Box className="flex-container">
                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: 'center'
                            }}
                            color="text.secondary"
                            className="project-name"
                            gutterBottom
                          >
                            Testing 03
                          </Typography>
                        </Box>
                        <Box className="flex-item">
                          <p className="create-date"> {fDate(new Date())} </p>
                          <p className="project-status"> Active</p>
                        </Box>
                      </Box>
                      <Box className="cardBottom" style={{ justifyContent: 'flex-end' }}>
                        <Box>
                          <Tooltip title="Delete" arrow placement="top">
                            <IconButton className="cardIconFooter" sx={{ padding: '0px 8px' }}>
                              <Delete size={20} sx={{ color: '#722F37' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {deletableArray.length > 0 ? (
        <Box>
          <Button
            className="globalSubmitButton floatingButton"
            classes={{
              root: 'globalSubmitButton globalSubmitButtonDelete autoButton',
              label: 'globalSubmitButtonLabel globalSubmitButtonDeleteLabel'
            }}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        </Box>
      ) : (
        ''
      )}
    </Page>
  );
}
export default SharedWithMe;

// function OtherMenuComponent(props) {
//   const {
//     anchorEl,
//     open,
//     handleClose,
//     navigate,
//     currentSelection,
//     currentSelectionName,
//     handleDelete,
//     handleOpenDelete,
//     handleCloseDelete,
//     openDelete,
//     handleOtherMenuClick
//   } = props;

//   console.log(currentSelection);
//   const [otherModelPopup, setOtherModelPopup] = useState(null);
//   const [allOtherModelData, setAllOtherModelData] = useState([]);
//   const [allOtherModelColumn, setAllOtherModelColumn] = useState([]);

//   return (
//     <Page title="Knowledge Graph">
//       <Container maxWidth="xl" className="noPadding bg-h-100">
//         <MenuItem
//         // onClick={() => {
//         //   navigate(`/knowledgegraph/edit/${currentSelection}/${currentSelectionName}`);
//         // }}
//         >
//           Edit
//         </MenuItem>
//         <Dialog
//           open={otherModelPopup}
//           onClose={(e) => {
//             setOtherModelPopup(null);
//           }}
//           maxWidth
//           fullWidth
//         >
//           <Box sx={{ textAlign: 'center' }}>
//             <DialogContent>Relationship List</DialogContent>
//           </Box>

//           <Box m={2}>
//             {allOtherModelData.length > 0 ? (
//               <Box style={{ height: 400 }}>
//                 <DataGrid
//                   rows={allOtherModelData}
//                   columns={allOtherModelColumn}
//                   hideFooter
//                   hideFooterPagination
//                   hideFooterSelectedRowCount
//                 />
//               </Box>
//             ) : (
//               <Box>
//                 <Typography>No Relationship found in this project</Typography>
//                 <Button
//                   // onClick={(e) => {
//                   //   navigate(`/knowledgegraph/edit/${currentSelection}/${currentSelectionName}`);
//                   // }}
//                   // variant="outlined"
//                   color="primary"
//                 >
//                   Create Relationship
//                 </Button>
//               </Box>
//             )}
//             <Box
//               sx={{
//                 display: 'flex',
//                 borderTop: '1px solid #ccc',
//                 justifyContent: 'flex-end',
//                 p: 0,
//                 m: 0,
//                 mt: 2,
//                 pb: 2,
//                 py: 1
//               }}
//             >
//               <DialogActions>
//                 <Box>
//                   <Button
//                     onClick={(e) => setOtherModelPopup(null)}
//                     variant="outlined"
//                     color="primary"
//                   >
//                     Close
//                   </Button>
//                 </Box>
//               </DialogActions>
//             </Box>
//           </Box>
//         </Dialog>
//         <MenuItem
//           onClick={(e) => {
//             handleOpenDelete(e.target);
//             // handleDelete(e, currentSelection);
//           }}
//         >
//           Delete
//         </MenuItem>
//         {/* <Dialog
//         open={openDelete}
//         onClose={(e) => {
//           handleCloseDelete();
//         }}
//         fullWidth
//       >
//         <Box sx={{ textAlign: 'center' }}>
//           <DialogContent>Delete List</DialogContent>
//         </Box>

//         <Box m={2}>
//           <Typography>Are you sure you want to delete?</Typography>
//           <Box
//             sx={{
//               display: 'flex',
//               borderTop: '1px solid #ccc',
//               justifyContent: 'flex-end',
//               p: 0,
//               m: 0,
//               mt: 2,
//               pb: 2,
//               py: 1
//             }}
//           >
//             <DialogActions>
//               <Box>
//                 <Button onClick={handleCloseDelete} variant="outlined" color="primary">
//                   Close
//                 </Button>
//               </Box>
//               <Box>
//                 <Button
//                   className="globalSubmitButton"
//                   classes={{
//                     root: 'globalSubmitButton globalSubmitButtonDelete autoButton',
//                     label: 'globalSubmitButtonLabel globalSubmitButtonDeleteLabel'
//                   }}
//                   variant="contained"
//                   color="secondary"
//                   onClick={(e) => {
//                     handleDelete(e, currentSelection);
//                   }}
//                 >
//                   Delete
//                 </Button>
//               </Box>
//             </DialogActions>
//           </Box>
//         </Box>
//       </Dialog> */}
//       </Container>
//     </Page>
//   );
// }
