import { Box, Tabs, Tab } from '@mui/material';
import AddGraphTabs from './AddGraphTabs';
import './BottomTabsStyle.css';

function BottomTabs({ callTabValue, callSetTabValue }) {
  return (
    <Box>
      <Box
        sx={{ width: 'auto' }}
        style={{
          position: 'fixed',
          bottom: '0',
          left: '2px'
        }}
      >
        <Tabs
          className={'tabContainer bottom'}
          value={callTabValue}
          onChange={(e, newVal) => callSetTabValue(newVal)}
          style={{ minHeight: '36px' }}
        >
          <Tab
            label={'Visualization'}
            className={`tabLabel ${callTabValue === 0 ? 'active' : ''}`}
          />
          <Tab label={'Map'} className={`tabLabel ${callTabValue === 1 ? 'active' : ''}`} />
          <Tab label={'Datagrid'} className={`tabLabel ${callTabValue === 2 ? 'active' : ''}`} />
          <Tab label={'Timeline'} className={`tabLabel ${callTabValue === 3 ? 'active' : ''}`} />
        </Tabs>
      </Box>
      {callTabValue === 0 ? <AddGraphTabs /> : ''}
    </Box>
  );
}
export default BottomTabs;
