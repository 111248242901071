import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  IconButton,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  MenuItem
} from '@mui/material';
import { Refresh, Edit, Delete, AddCircleOutline } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

// components
import { useDispatch, useSelector } from 'react-redux';
import Page from '../Page';

// services
import TopNavigationBar from '../TopNavigationBar';
import LeftNavigationBar from '../LeftNavigationBar';
import '../customstyled.css';
// user Details

/**
 *
 * @returns react-element
 */
function RoleManagement() {
  const navigate = useNavigate();
  const [userInformationData, setUserInformationData] = useState([
    {
      id: 1,
      access_type: 'Admin',
      mail: 'admin@xyz.com',
      created_at: new Date(),
      modified_at: new Date(),
      active_status: 'Yes'
    },
    {
      id: 2,
      access_type: 'Viewer',
      mail: 'viewer@xyz.com',
      created_at: new Date(),
      modified_at: new Date(),
      active_status: 'No'
    },
    {
      id: 3,
      access_type: 'Editor',
      mail: 'editor@xyz.com',
      created_at: new Date(),
      modified_at: new Date(),
      active_status: 'Pending'
    }
  ]);

  const [editedAccessType, setEditedAccessType] = useState('');
  const [accessStatus, setAccessStatus] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState('Are you sure,you want to delete this user?');

  const [editFormData, setEditFormData] = useState({
    userid: '',
    access_type: '',
    email: '',
    active_status: ''
  });

  // Function to open the "Edit" modal and pre-fill form fields
  const handleEditUser = (user) => {
    // Set the editedAccessType state to the user's access_type
    setEditedAccessType(user.access_type);
    setAccessStatus(user.active_status);

    // Set the other form fields as you were doing before
    setEditFormData({
      userid: user.id,
      access_type: user.access_type,
      email: user.mail,
      active_status: user.active_status
    });

    // Open the Edit modal
    setEditModalOpen(true);
  };

  // Function to handle form input changes
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleDeleteUser = (id) => {
    if (id !== null) {
      const userToDelete = userInformationData.find((user) => user.id === id);
      if (userToDelete && userToDelete.access_type !== 'Admin') {
        const updatedRoles = userInformationData.filter((user) => user.id !== id);
        setUserInformationData(updatedRoles);
      }
      setDeleteUserId(null);
      setDeleteModalOpen(false);
    }
  };

  const handleDisplayMessage = (id) => {
    if (id !== null) {
      const userToDelete = userInformationData.find((user) => user.id === id);
      if (userToDelete && userToDelete.access_type === 'Admin') {
        setDisableDeleteBtn(true);
        setDeleteMessage('Admin cannot be deleted');
      } else {
        setDisableDeleteBtn(false);
        setDeleteMessage('Are you sure,you want to delete this user?');
      }
    }
    setDeleteModalOpen(true);
  };

  const closeModalBox = () => {
    setDeleteModalOpen(false);
    setEditModalOpen(false);
  };

  const handleSaveEdit = () => {
    const userIndexToEdit = userInformationData.findIndex(
      (user) => user.id === editFormData.userid
    );

    if (userIndexToEdit !== -1) {
      userInformationData[userIndexToEdit].access_type = editFormData.access_type;
      userInformationData[userIndexToEdit].active_status = editFormData.active_status;

      const updatedData = [...userInformationData];
      setUserInformationData(updatedData);
    }

    // Close the edit modal
    setEditModalOpen(false);
  };
  const handleAddUser = () => {
    navigate(`/usermanagement/add-user`);
  };

  return (
    <Page title="Role & Access Management">
      <Container maxWidth="xl" className="noPadding bg-h-100">
        <TopNavigationBar />
        <Grid container marginTop={0} spacing={5} id="k-Graph-wrap">
          {/* <Grid item xs={3} id="k-graphLeft">
            <LeftNavigationBar />
          </Grid> */}
          <Grid item xs={12} id="k-graphRight" sx={{ paddingRight: '15px' }} className="useraccess">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 10px' }}>
              <Typography variant="h6" sx={{ color: '#004C99' }}>
                Manage Access
              </Typography>
              <Button
                sx={{
                  backgroundColor: '#0d4689',
                  padding: '6px px',
                  color: 'white',
                  minWidth: '48px',
                  '&:hover': { backgroundColor: '#0056b3' }
                }}
                onClick={() => handleAddUser()}
              >
                <Tooltip title="Gant New User Access" placement="top" arrow>
                  <AddCircleOutline sx={{ color: '#fff' }} />
                </Tooltip>
              </Button>
            </Box>

            <TableContainer component={Paper} className="custom-table-container">
              <Table sx={{ margin: '10px' }} aria-label="User Table" className="custom-user-table">
                <TableHead>
                  <TableRow sx={{ '&>.MuiTableCell-root': { textAlign: 'center' } }}>
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                    <TableCell>Access ID</TableCell>
                    <TableCell>Access Type</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Edit Date</TableCell>
                    <TableCell>Active Status</TableCell>
                    <TableCell>Modify</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userInformationData.map((user) => (
                    <TableRow
                      key={user.id}
                      sx={{ '&>.MuiTableCell-root': { textAlign: 'center' } }}
                    >
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.access_type}</TableCell>
                      <TableCell>{user.created_at.toDateString()}</TableCell>
                      <TableCell>{user.modified_at.toDateString()}</TableCell>
                      <TableCell>
                        <Chip
                          label={user.active_status}
                          sx={
                            user.active_status.toLowerCase() === 'yes'
                              ? { color: '#4d9934', backgroundColor: '#e8f7df' }
                              : {
                                  color: '#a93e4d',
                                  backgroundColor: '#f2dcdb'
                                }
                          }
                          style={{ fontWeight: '600', padding: '3px 8px' }}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '60px' }}>
                        <Tooltip title="Edit" placement="top" arrow>
                          <IconButton
                            className="customized-icons"
                            onClick={() => handleEditUser(user)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ width: '60px' }}>
                        <Tooltip title="Delete" placement="top" arrow>
                          <IconButton
                            className="customized-icons"
                            onClick={() => {
                              handleDisplayMessage(user.id);
                              setDeleteUserId(user.id);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {/* Delete Modal Open */}
        <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <DialogTitle>Edit Or Modify Access</DialogTitle>
          <DialogContent sx={{ minWidth: '440px' }}>
            <form>
              <Box className="roleEditBox">
                <Typography className="label">User ID</Typography>
                <TextField
                  name="userid"
                  value={editFormData.userid}
                  onChange={handleEditFormChange}
                  disabled
                  className="w-80"
                />
              </Box>
              <Box className="roleEditBox">
                <Typography className="label">Access</Typography>
                <TextField
                  select
                  name="access_type" // Change the name property
                  value={editFormData.access_type} // Use editFormData.access_type
                  onChange={handleEditFormChange}
                  className="w-80"
                >
                  <MenuItem value="Viewer">Viewer</MenuItem>
                  <MenuItem value="Editor">Editor</MenuItem>
                </TextField>
              </Box>
              <Box className="roleEditBox">
                <Typography className="label">Status</Typography>

                <TextField
                  select
                  name="active_status" // Change the name property
                  value={editFormData.active_status} // Use editFormData.access_type
                  onChange={handleEditFormChange}
                  className="w-80"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                </TextField>
              </Box>
              {/* Add more fields as needed */}
            </form>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleSaveEdit} disabled={disableDeleteBtn} color="secondary">
              Save
            </Button>
            <Button
              onClick={() => {
                closeModalBox();
              }}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>{deleteMessage}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                closeModalBox();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteUser(deleteUserId)}
              disabled={disableDeleteBtn}
              color="secondary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

export default RoleManagement;
