import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';

// material ui components theme and icons import
import {
  Card,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
  Stack,
  Container,
  MenuItem,
  StepConnector,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  TableRow,
  TableCell
} from '@mui/material';
import { Box } from '@mui/system';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// icons import
import {
  getIcon,
  DataSourceIcon,
  CancelIcon,
  TestIcon,
  SaveIcon,
  DataSourceEditIcon,
  DataSourceCheckIcon,
  UploadIcon,
  iconsForDatasourcePage
} from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
// import custom components
import Page from '../Page';
import PageTitle from '../../global/PageHeading';
// import pages components
import dataSourceService from '../../../services/dataSourceService';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
// user Details
import { userDataFromLocal } from '../../../utils/getUserDetails';
// import Linkscreen from './Linkscreen';

const camelCase = (str) => {
  // alert(str)
  const str2 = str.replace(/_/g, ' ');
  let camCase = '';
  [...str2].forEach((item, i) => {
    camCase += i === 0 ? item.toUpperCase() : item.toLowerCase();
  });
  return camCase;
};

// stepper icon and style component
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient(90deg, rgba(46,49,146,1) 0%, rgba(238,58,70,1) 100%);'
    }
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient(90deg, rgba(46,49,146,1) 0%, rgba(238,58,70,1) 100%);'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  }
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: 'Source',
    2: 'Map',
    3: 'Link'
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
/**
 * @class DataSourceAddPage link to Add Button
 * @param properties from outside
 * @returns {React.ReactElement} - React component
 */
function KnowledgeGraphDataSource(props) {
  // props
  const { setResponseColoumData, setIsListMutate, setCurrentSelection, totalrecords } = props;
  // services
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { experimentId, experimentName, rowID, id, dId, dName, action } = useParams();
  // console.log('useParams()', useParams());
  const projectId = id;
  const { Savedbconfig, Savedatasource, Diallinputpram, Editidatasourcelist } = dataSourceService;
  const { Savedataset, Datasetlist } = KnowledgeGraphService;

  // state
  const [datasetName, setDataSetName] = useState('');
  const [datasetNameHelp, setDatasetNameHelp] = useState('');
  const [datasetNameErr, setDatasetNameErr] = useState(false);
  // select data set state
  const [dropDownDataSet, setDropDownDataSet] = useState([]);
  const [selectDataSet, setSelectDataSet] = useState('');
  const [DataSetTitle, SetDataSetTitle] = useState('Data Set Name');
  const [dataSourceType, setDataSourceType] = useState(' ');
  const [fieldData, setFieldData] = useState('');
  const [subCategory, setSubCategory] = useState(' ');
  const [uploadedFile, setUploadedFile] = useState(null);
  // console.log('uploadedFile', uploadedFile);
  const [isEditable, setIsEditable] = useState(false);
  const [isTested, setIsTested] = useState(false);
  const [serachedDb, setserachedDb] = useState('');
  const [loadAgain, setloadAgain] = useState(null);
  const [dataSourceTypeList, setDataSourceTypeList] = useState([]);
  const [testedData, setTestedData] = useState([]);
  const [configData, setConfigData] = useState({});
  const [testLoader, setTestLoader] = useState(false);
  const [pathFileName, setpathFileName] = useState(null);
  // console.log('pathFileName', pathFileName);
  const [hideFiled, setHideField] = useState('notHidden');
  const [hideAppendList, setHideApendList] = useState('hidden');
  const [fileName, setFileName] = useState('');
  const [csvFile, setCsvFile] = useState('');
  const [fileNameHelp, setFilenameHelp] = useState('');
  const [fileNameErr, setFilenameErr] = useState(false);
  // stepper state
  const [activeStep, setActiveStep] = useState(0);
  // dataObjectiveValu state
  const [dataObjectiveValue, setDataObjectiveValue] = useState('New');
  const [exitingDatasetnames, setExittingDataset] = useState([]);
  // tab state
  const [value, setValue] = React.useState('1');
  const [columnData, setColumnData] = useState('');
  // console.log('columnData', columnData);
  // disable next button when checkbox is empty
  const [getDisabled, setGetDisable] = useState('');
  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  // hide show data set when click on overright
  const setDataObjective = (e) => {
    const value = e.target.value;
    // console.log('e.target.value', e);
    // console.log('e.target.value', e.target.value);
    setDataObjectiveValue(value);
    // if (value !== 'new') {
    //   setHideField('hidden');
    // } else {
    //   setHideField('notHidden');
    // }
    // if (value === 'append' || value === 'overwrite') {
    //   setHideApendList('notHidden');
    // } else {
    //   setHideApendList('hidden');
    // }
  };
  // stepper function
  const getStepTwo = async (e) => {
    if (dataObjectiveValue === 'New' && datasetName === '') {
      setDatasetNameHelp('Please provide Dataset name');
      setDatasetNameErr(true);
    }
    if (fileName === '') {
      setFilenameHelp('Please upload CSV file');
      setFilenameErr(true);
    }
    // const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const config = {
      data_source_type: dataSourceType,
      sub_category: subCategory,
      upload_type: dataObjectiveValue
    };
    const formData = new FormData();
    if (uploadedFile !== null) {
      formData.append('path', uploadedFile);
    }
    formData.append('dataset_name', datasetName);
    formData.append('experiment_id', experimentId);
    formData.append('crea_user', userDataFromLocal().userName);
    formData.append('updt_user', userDataFromLocal().userName);
    formData.append('config', JSON.stringify(config));
    if (dataObjectiveValue === 'Overwrite' || dataObjectiveValue === 'Append') {
      formData.append('dataset_id', selectDataSet);
    }
    // console.log('credential', formData);
    // config:{"sub_category":"csv", "data_source_type":"File","upload_type":"New"}
    return fetch(Savedataset(), {
      method: 'post',
      body: formData
    }).then((response) => {
      response.json().then((data) => {
        // console.log('isDataSaved', data);
        enqueueSnackbar(data.message, {
          variant: data.status,
          autoHideDuration: 2000
        });
        if (data.status === 'success') {
          if (data.data.length > 0) {
            const column = Object.keys(data.data[0]);
            // console.log('getColumnDataApi.data', column);
            setActiveStep(1);
            setColumnData(column);
            setResponseColoumData(column);
          }
          // setIsListMutate(new Date());
        }
      });
    });
  };
  const getStepOne = (e) => {
    setActiveStep(0);
  };
  const getStepThree = async (e) => {
    // const token = await fireApi(listExistingDataSetInProject, {
    //   projectId: projectId
    // });
    // // console.log(token);
    // setExittingDataset(token.data);
    setActiveStep(2);
  };

  useEffect(() => {
    // const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const experiment_Id = experimentId;
    // console.log('experiment_Id', experiment_Id);
    const getDropDownDataSet = async () => {
      const DataSetApi = await Datasetlist(userDataFromLocal().userName, experiment_Id);
      // console.log('DataSetApi', DataSetApi);
      if (DataSetApi.status === 'success') {
        // console.log('DataSetApi', DataSetApi.data);
        setDropDownDataSet(DataSetApi.data);
      }
    };
    getDropDownDataSet();
  }, []);
  // // useEffect for dataSet
  // useEffect(() => {
  //   if (dName !== undefined && !dNameFound) {
  //     dNameFound = true;
  //     setDataObjectiveValue(action);
  //     setDataSetId(dId);
  //     if (action === "new") setHideField("notHidden");
  //     else setHideField("hidden");
  //     setSelectedDataSetName(dName);
  //     async function fetchData() {
  //       let credentials = {};
  //       credentials.fileName = dName;
  //       credentials.projectId = projectId;
  //       const token = await fireApi(showColumnOfExistingFile, credentials);
  //       console.log("useEffect projectview");
  //       if (token.status === "success") {
  //         setFieldNamesForAppend(token.data);
  //       }
  //       if (token.status === "error") {
  //         props.enqueueSnackbar("No data found for this dataset name", {
  //           variant: token.status,
  //           autoHideDuration: 2000,
  //         });
  //       }
  //     }
  //     fetchData();
  //   }
  //   setDisabled(true);
  //   dataSet.forEach((item) => {
  //     if (item.pk) {
  //       setDisabled(false);
  //       return;
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataSet, exitingDatasetnames, dataObjectiveValue]);
  /**
   * @type {function} - function
   * @return return test result
   */
  const testConnection = async (e) => {
    setTestLoader(true);
    // const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const configProp = configData;
    configProp.datasource_name = DataSetTitle.toLowerCase();
    configProp.sub_category = subCategory;
    configProp.updt_user = userDataFromLocal().userName;
    configProp.crea_user = userDataFromLocal().userName;
    const isConnection = await Savedbconfig(configProp);
    setTestLoader(false);
    enqueueSnackbar(isConnection.message, {
      variant: isConnection.status,
      autoHideDuration: 2000
    });
    if (isConnection.status === 'success') {
      setTestedData(isConnection.data);
      setTimeout(() => {
        setIsTested(true);
      }, 500);
    }
  };
  /**
   * @type {function} -submitCreateClient
   * @param {event} - event parameter
   * @returns {Promise} -Savedatasource() that store data to sever
   */
  const submitCreateClient = async (e) => {
    // console.log('configData', configData);
    const configProp = {
      data_source_type: dataSourceType,
      sub_category: subCategory,
      input_parameters: configData
    };
    /**
     * @description FormData() element
     * @description Appending data in form object
     */
    // const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const formData = new FormData();
    if (uploadedFile !== null) {
      formData.append('path', uploadedFile);
    }
    formData.append('crea_user', userDataFromLocal().userName);
    formData.append('updt_user', userDataFromLocal().userName);
    formData.append('datasource_name', DataSetTitle);
    formData.append('config', JSON.stringify(configProp));
    // console.log('configProp', configProp);
    // console.log('formData', formData);
    return fetch('http://164.52.215.10:1010/savedatasource', {
      method: 'post',
      body: formData
    }).then((response) => {
      response.json().then((data) => {
        // console.log('isDataSaved', data);
        enqueueSnackbar(data.message, {
          variant: data.status,
          autoHideDuration: 2000
        });
        if (data.status === 'success') {
          if (data.data.length > 0) {
            const column = Object.keys(data.data[0]);
            setResponseColoumData(column);
          }

          setIsListMutate(new Date());
        }
      });
    });
  };
  // const submitfile = async (e, fileData) => {
  //   let data = JSON.stringify(dataSet);
  //   const formData = new FormData();
  //   //console.log(fileData);
  //   formData.append("file", csvFile);
  //   formData.append("userId", userId);
  //   formData.append("projectId", projectId);
  //   formData.append("type", dataObjectiveValue);
  //   formData.append("importMethod", "flat_file");
  //   formData.append("objective", dataObjectiveValue);
  //   formData.append("exitingFileName", exitingFileName);
  //   formData.append("data", data);
  //   formData.append("datasetName", datasetName || selectedDatasetName);
  //   formData.append("datasetDesc", datasetDesc);
  //   formData.append("dataSetId", dataSetId);
  //   formData.append("link", fileData);

  //   return fetch(uploadFile, {
  //     method: "post",
  //     body: formData,
  //   }).then((response) => response.json());
  // };
  useEffect(() => {
    (async () => {
      const isFeildsAvailable = await Diallinputpram(); // empty object if needed
      // console.log('dialInputparam api data in isFeildsAvailable :', isFeildsAvailable.data);
      if (isFeildsAvailable.status === 'success') {
        setFieldData(isFeildsAvailable.data);
        const uniqueSource = [
          ...new Map(isFeildsAvailable.data.map((item) => [item.data_source_type, item])).values()
        ];
        setDataSourceTypeList(uniqueSource);
        setDataSourceType(uniqueSource[0].data_source_type);
        // console.log("uniqueSource",uniqueSource)
      }
      // console.log(rowID);
      /**
       * @description when click on edit and row is define ,isEditdataAvailable function fire an get api
       * @param take rowID as parameter and set the value of all field
       */
      if (rowID !== undefined) {
        const isEditdataAvailable = await Editidatasourcelist(rowID); // getApi(editidatasourcelist + rowID, {});
        // console.log('isEditdataAvailable', isEditdataAvailable);
        if (isEditdataAvailable.status === 'success') {
          // setDataSourceType setSubCategory setConfigData
          SetDataSetTitle(isEditdataAvailable.data[0].datasource_name);
          setDataSourceType(isEditdataAvailable.data[0].config.data_source_type);
          setSubCategory(isEditdataAvailable.data[0].config.sub_category);
          setConfigData(isEditdataAvailable.data[0].config.input_parameters);
          setpathFileName(() => {
            const path = isEditdataAvailable.data[0].path;
            const pathArray = path.split('/').pop();
            // console.log(pathArray);
            return pathArray;
          });
        }
      }
    })();
  }, [loadAgain]);
  return (
    <Page title="Decision Engine | KnowledgeGraph-DataSource">
      <Container maxWidth="xl" className="noPadding">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          classes={{
            root: 'globalCardStepper',
            horizontal: 'globalCardStepperHorizontal',
            vertical: 'globalCardStepperVertical'
          }}
        >
          <Step
            classes={{
              root: 'globalStepperSteps',
              horizontal: 'globalStepperStepsHorizontal',
              vertical: 'globalStepperStepsVertical',
              alternativeLabel: 'globalStepperStepsAlternativeLabel',
              completed: 'globalStepperStepsCompleted'
            }}
          >
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              classes={{
                root: 'globalStepLabel',
                horizontal: 'globalStepLabelHorizontal',
                vertical: 'globalStepLabelVertical',
                label: 'globalStepLabelLabel',
                active: 'globalStepLabelActive',
                completed: 'globalStepLabelCompleted',
                error: 'globalStepLabelError',
                disabled: 'globalStepLabelDisabled',
                iconContainer: 'globalStepLabelIconContainer',
                alternativeLabel: 'globalStepLabelAlternativeLabel',
                labelContainer: 'globalStepLabelLabelContainer'
              }}
            >
              Source
            </StepLabel>
          </Step>
          <Step
            classes={{
              root: 'globalStepperSteps',
              horizontal: 'globalStepperStepsHorizontal',
              vertical: 'globalStepperStepsVertical',
              alternativeLabel: 'globalStepperStepsAlternativeLabel',
              completed: 'globalStepperStepsCompleted'
            }}
          >
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              classes={{
                root: 'globalStepLabel',
                horizontal: 'globalStepLabelHorizontal',
                vertical: 'globalStepLabelVertical',
                label: 'globalStepLabelLabel',
                active: 'globalStepLabelActive',
                completed: 'globalStepLabelCompleted',
                error: 'globalStepLabelError',
                disabled: 'globalStepLabelDisabled',
                iconContainer: 'globalStepLabelIconContainer',
                alternativeLabel: 'globalStepLabelAlternativeLabel',
                labelContainer: 'globalStepLabelLabelContainer'
              }}
            >
              Map
            </StepLabel>
          </Step>
          <Step
            classes={{
              root: 'globalStepperSteps',
              horizontal: 'globalStepperStepsHorizontal',
              vertical: 'globalStepperStepsVertical',
              alternativeLabel: 'globalStepperStepsAlternativeLabel',
              completed: 'globalStepperStepsCompleted'
            }}
          >
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              classes={{
                root: 'globalStepLabel',
                horizontal: 'globalStepLabelHorizontal',
                vertical: 'globalStepLabelVertical',
                label: 'globalStepLabelLabel',
                active: 'globalStepLabelActive',
                completed: 'globalStepLabelCompleted',
                error: 'globalStepLabelError',
                disabled: 'globalStepLabelDisabled',
                iconContainer: 'globalStepLabelIconContainer',
                alternativeLabel: 'globalStepLabelAlternativeLabel',
                labelContainer: 'globalStepLabelLabelContainer'
              }}
            >
              Link
            </StepLabel>
          </Step>
        </Stepper>
        {/* first Stepper start here */}
        <Box className={activeStep === 0 ? 'stepContent activeStep' : 'stepContent hidden'}>
          <Card>
            {/* new box */}
            <Box
              border={0}
              borderColor="#ddd"
              borderRadius={0}
              boxShadow={0}
              bgcolor="background.paper"
              mt={1.5}
              p={0}
              style={{ width: '100%', height: '75%' }}
            >
              <Box p={1}>
                <SelectSourceType
                  dataSourceType={dataSourceType}
                  setDataSourceType={setDataSourceType}
                  dataSourceTypeList={dataSourceTypeList}
                  setSubCategory={setSubCategory}
                  setIsTested={setIsTested}
                  setUploadedFile={setUploadedFile}
                  setConfigData={setConfigData}
                />
                {dataSourceType !== ' ' ? (
                  <>
                    <Box>
                      <Typography>Data Objective {dataObjectiveValue}</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onClick={(e) => setDataObjective(e)}
                          value={dataObjectiveValue}
                        >
                          <FormControlLabel
                            value="New"
                            control={<Radio />}
                            label="New"
                            // disabled={action === 'new'}
                          />
                          <FormControlLabel
                            value="Overwrite"
                            control={<Radio />}
                            label="Overwrite"
                            // disabled={action === 'overwrite'}
                          />
                          <FormControlLabel
                            value="Append"
                            control={<Radio />}
                            label="Append"
                            // disabled={action === 'append'}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {dataObjectiveValue === 'New' ? (
                      <Box className="knowledgeGraph-mainBox">
                        <Box className="knowledgeGraph-textIconBox">
                          <TextField
                            fullWidth
                            variant="standard"
                            label="Dataset Name"
                            className="formInputText"
                            value={datasetName}
                            onChange={(e) => {
                              setDataSetName(e.target.value);
                            }}
                            helperText={datasetNameHelp}
                            error={datasetNameErr}
                          />
                        </Box>
                        {/* button text save box here */}
                      </Box>
                    ) : (
                      ''
                    )}
                    {dataObjectiveValue !== 'New' ? (
                      <Box>
                        <Typography>Select Dataset:</Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value="age"
                          label="Age"
                          fullWidth
                          onChange={(e) => setSelectDataSet(e.target.value)}
                        >
                          {dropDownDataSet.length > 0 ? (
                            dropDownDataSet.map((item, i) => (
                              <MenuItem key={i} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="none">Select</MenuItem>
                          )}
                        </Select>
                      </Box>
                    ) : (
                      ''
                    )}

                    <Box display="flex" alignItems="center" mt={2} mb={2}>
                      <Typography>{dataSourceType} type*</Typography>
                      &nbsp;&nbsp;&nbsp;
                      <TextField
                        value={serachedDb}
                        className="forminp"
                        onChange={(e) => setserachedDb(e.target.value)}
                        placeholder="Search..."
                      />
                    </Box>
                    <Divider />
                    <br />
                    <Box className="" mb={3}>
                      <SelectFileType
                        dataSourceType={dataSourceType}
                        filterData={fieldData.filter(
                          (item) =>
                            item.data_source_type === dataSourceType &&
                            item.sub_category.toLowerCase().includes(serachedDb)
                        )}
                        subCategory={subCategory}
                        setSubCategory={setSubCategory}
                        setUploadedFile={setUploadedFile}
                        setConfigData={setConfigData}
                      />
                    </Box>
                  </>
                ) : (
                  ''
                )}
                {testedData.length > 0 ? (
                  <>
                    {testedData.map((item, index) => (
                      <Box
                        key={index}
                        borderTop={index === 0 ? 1 : 0}
                        display="flex"
                        justifyContent="center"
                        borderBottom={1}
                        bgcolor={index % 2 === 0 ? '#eee' : '#fffeee'}
                      >
                        <Box width={130} textAlign="left">
                          {Object.keys(item)[0]}
                        </Box>
                        <Box width={20} textAlign="left">
                          :
                        </Box>
                        <Box width={200} textAlign="left">
                          {Object.values(item)[0]}
                        </Box>
                      </Box>
                    ))}
                    <br />
                    <br />
                  </>
                ) : (
                  ''
                )}
                {dataSourceType !== ' ' && subCategory !== ' ' ? (
                  <Allfields
                    dataSourceType={dataSourceType}
                    subCategory={subCategory}
                    fieldData={fieldData}
                    setConfigData={setConfigData}
                    configData={configData}
                    setUploadedFile={setUploadedFile}
                    pathFileName={pathFileName}
                  />
                ) : (
                  ''
                )}
              </Box>
            </Box>
            {/* next button and fire api here */}
            <Box
              className={activeStep === 0 ? 'defaultBtn globalButtonHolder' : 'hidden'}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                className=""
                variant="contained"
                color="primary"
                classes={{
                  root: 'globalSubmitButton globalSubmitButtonOk',
                  label: 'globalSubmitButtonLabel globalSubmitButtonOkLabel'
                }}
                onClick={getStepTwo}
              >
                Next
              </Button>
            </Box>
          </Card>
          {/* first Stepper finish here */}
        </Box>
        {/*  second Stepper start here */}
        <Box className={activeStep === 1 ? 'stepContent activeStep' : 'stepContent hidden'}>
          {/* {console.log('columnData', columnData)} */}
          {columnData.length > 0 ? (
            dataObjectiveValue === 'New' ? (
              <>
                <table className="globalSimpleTable">
                  <thead>
                    <tr className="globalSimpleTableTR">
                      <th className="globalSimpleTableTH">Field name</th>
                      <th className="globalSimpleTableTH" style={{ width: '100px' }}>
                        Primary key
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {columnData.map((row, i) => (
                      <TableRow key={i} className="globalSimpleTableTR">
                        <TableCell className="globalSimpleTableTD">
                          {row.replace(/_/g, ' ')}
                        </TableCell>
                        <TableCell className="globalSimpleTableTD">
                          <Checkbox />
                          {/* <Checkboxc index={i} makeJson={makeJson} /> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </table>
                <Box className="defaultBtn" display="flex" justifyContent="flex-end">
                  <Box p={2}>
                    <Button
                      className=""
                      classes={{
                        root: 'globalSubmitButton globalSubmitButtonCancel',
                        label: 'globalSubmitButtonLabel globalSubmitButtonCancelLabel'
                      }}
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={getStepOne}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box p={2}>
                    <Button
                      className=""
                      classes={{
                        root: 'globalSubmitButton globalSubmitButtonOk',
                        label: 'globalSubmitButtonLabel globalSubmitButtonOkLabel'
                      }}
                      disabled={getDisabled}
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={getStepThree}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <table className="globalSimpleTable">
                  <thead>
                    <tr className="globalSimpleTableTR">
                      <th className="globalSimpleTableTH" width="10%">
                        S.No.
                      </th>
                      <th className="globalSimpleTableTH" width="45%">
                        Field name
                      </th>
                      <th className="globalSimpleTableTH" width="45%">
                        Field name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableCell className="globalSimpleTableTD">row.name</TableCell>
                  </tbody>
                </table>
                <Box className="defaultBtn" display="flex" justifyContent="flex-end">
                  <Box p={2}>
                    <Button
                      classes={{
                        root: 'globalSubmitButton globalSubmitButtonCancel',
                        label: 'globalSubmitButtonLabel globalSubmitButtonCancelLabel'
                      }}
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={getStepOne}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box p={2}>
                    <Button
                      classes={{
                        root: 'globalSubmitButton globalSubmitButtonOk',
                        label: 'globalSubmitButtonLabel globalSubmitButtonOkLabel'
                      }}
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={getStepThree}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </>
            )
          ) : (
            ''
          )}
        </Box>
        <Box className={activeStep === 2 ? 'stepContent activeStep' : 'stepContent hidden'}>
          <p> LinkScreen</p>
          {/* <Linkscreen
            columns={columnData}
            objectiveType={dataObjectiveValue}
            exitingDatasetnames={exitingDatasetnames}
            getStepTwo={getStepTwo}
            setActiveStep={setActiveStep}
            // submitfile={submitfile}
            projectId={projectId}
          /> */}
          {/* new next save button here */}
          <Box className="knowledgeGraph-buttonBox">
            <Button
              variant="outlined"
              size="small"
              disabled={dataSourceType !== 'Db'}
              onClick={(e) => testConnection(e.currentTarget)}
              className="knowledgeGraph-button"
            >
              <RenderIcons icon={getIcon(TestIcon, null, 16, 16)} />
              Test
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="outlined"
              size="small"
              disabled={IsDisabled(dataSourceType, isTested)}
              onClick={(e) => submitCreateClient(e.currentTarget)}
              className="knowledgeGraph-button"
            >
              <RenderIcons icon={getIcon(SaveIcon, null, 16, 16)} /> Save
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => {
                setDataSourceType(' ');
                setSubCategory(' ');
                setConfigData({});
                navigate('/datasource/add', { replace: true });
                setloadAgain(new Date());
              }}
              className="knowledgeGraph-button"
            >
              <RenderIcons icon={getIcon(CancelIcon, null, 16, 16)} /> Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default KnowledgeGraphDataSource;

/**
 * @type {function} - Select Source Type
 * @param {object} dataSourceType- Input object
 * @param {object} setDataSourceType- Input object
 * @param {object} dataSourceTypeList- Input object
 * @param {object} setSubCategory- Input object
 * @param {object} setUploadedFile- Input object
 * @param {object} setConfigData- Input object
 * @param {object} setIsTested- Input object
 * @return {React.ReactElement} - React component
 */
function SelectSourceType({
  dataSourceType,
  setDataSourceType,
  dataSourceTypeList,
  setSubCategory,
  setUploadedFile,
  setConfigData,
  setIsTested
}) {
  useEffect(() => {}, [dataSourceTypeList]);
  const [refreshComp, setRefreshComp] = useState(null);
  return (
    <Tabs
      value={dataSourceType}
      onChange={(e, newValue) => {
        setDataSourceType(newValue);
        setSubCategory(' ');
        setUploadedFile(null);
        setConfigData({});
        setIsTested(false);
        setTimeout(() => {
          const PADDING = 0.2; // 20% padding on all sides
          const svgElements = document.querySelectorAll('svg:not(.drawflow svg)');
          svgElements.forEach((svg) => {
            const bbox = svg.getBBox();
            const padX = PADDING * bbox.width;
            const padY = PADDING * bbox.height;
            const viewbox = [
              bbox.x - padX,
              bbox.y - padY,
              bbox.width + 2 * padX,
              bbox.height + 2 * padY
            ].join(' ');
            svg.setAttribute('viewBox', viewbox);
            if (refreshComp === null) {
              setRefreshComp(new Date());
            }
          });
        }, 1);
      }}
      aria-label="simple tabs example"
    >
      {dataSourceTypeList.length > 0
        ? dataSourceTypeList.map((item, index) => (
            <Tab label={item.data_source_type} value={item.data_source_type} />
          ))
        : ''}
    </Tabs>
  );
}

/**
 * @type {function} - SelectFileType
 * @param {object} dataSourceType- Input object
 * @param {object} filterData- Input object
 * @param {object} subCategory- Input object
 * @param {object} setSubCategory- Input object
 * @param {object} setUploadedFile- Input object
 * @param {object} setConfigData- Input object
 * @param {object} setConfigData- Input object
 * @return {React.ReactElement} - React component
 */

function SelectFileType({
  dataSourceType,
  filterData,
  subCategory,
  setSubCategory,
  setUploadedFile,
  setConfigData
}) {
  const [subCatOptions, setSubCatOptions] = useState([]);
  /**
   * Datasource icon color Object
   * @type {object}
   */
  const iconsColor = {
    postgresql: 'rgb(48, 92, 145)',
    mysql: 'rgb(221, 137, 0)',
    MariaDB: 'rgb(186, 114, 87)',
    Oracle: 'rgb(193, 68, 50)',
    MSSQL: 'rgb(182, 30, 35)',
    MongoDB: 'rgb(37, 147, 69)',
    Neo4j: 'rgb(1, 138, 255)',
    Cassandra: '#b5dff3'
  };
  useEffect(() => {
    /**
     * @type {array} - uniqueSource
     * @description unique category object from Api
     * @description setting uniqueSource to subCatOption State
     */
    const uniqueSource = [...new Map(filterData.map((item) => [item.sub_category, item])).values()];
    // console.log('uniqueSource', uniqueSource);
    setSubCatOptions(uniqueSource);
  }, [dataSourceType, filterData, subCategory, setSubCategory]);

  return (
    <Box display="flex" flexWrap="wrap" className="k-graphdbWrap">
      {subCatOptions.map((option, index) => (
        <Box textAlign="center" key={index} className="k-graphdbIcon">
          <Box
            className="dbSelect"
            onClick={(e) => {
              setSubCategory(option.sub_category);
              setUploadedFile(null);
              setConfigData({});
            }}
            p={2}
            border={1}
            mb={2}
            borderRadius={3}
            borderColor="#ddd"
            bgcolor="#eee"
            width={25}
            height={25}
            mr={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <RenderIcons
              icon={getIcon(
                iconsForDatasourcePage[option.sub_category],
                iconsColor[option.sub_category],
                40,
                40
              )}
            />
          </Box>
          {option.sub_category}
        </Box>
      ))}
    </Box>
  );
}

/**
 * @type {function} - Allfields
 * @param {object} dataSourceType- Input object
 * @param {object} subCategory- Input object
 * @param {object} fieldData- Input object
 * @param {object} setUploadedFile- Input object
 * @param {object} setConfigData- Input object
 * @param {object} configData- Input object
 * @return {React.ReactElement} - React component
 */
function Allfields({
  dataSourceType,
  subCategory,
  fieldData,
  setUploadedFile,
  setConfigData,
  configData,
  pathFileName
}) {
  const [curFileName, setcurFileName] = useState('');
  /**
   *
   * @param {event} e
   * @param {parameter} param
   * @param {string} files
   */
  const handleFileUpload = async (e, param, files) => {
    let file = '';
    if (files) {
      file = files;
    } else {
      file = e.target.files[0];
    }
    setcurFileName(file.name);
    setUploadedFile(file);
  };
  /**
   *
   * @param {event} e
   * @param {*} param
   */
  const fileDrop = (e, param) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    // console.log(files[0]);
    handleFileUpload(e, param, files[0]);
  };
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const uploadFileClick = (e) => {
    const browseField = document.getElementById('contained-button-file');
    browseField.click();
  };
  useEffect(() => {
    setcurFileName(pathFileName);
  }, [dataSourceType, subCategory, fieldData, pathFileName]);
  return fieldData
    .filter((item) => item.data_source_type === dataSourceType && item.sub_category === subCategory)
    .map((fields, index) => (
      <React.Fragment key={index}>
        <Typography>
          {dataSourceType === 'File' ? 'Upload ' + subCategory + ' ' : ''}
          {fields.input_parameters}*
        </Typography>
        {fields.input_type === 'userInput' ? (
          <TextField
            fullWidth
            className="formInputText"
            value={configData[fields.input_from_ui]}
            onChange={(e) => {
              setConfigData({
                ...configData,
                [fields.input_from_ui]: e.target.value
              });
            }}
          />
        ) : fields.input_type === 'fileUpload' ? (
          <Box display="flex" flexDirection="column" border={1} borderColor="#ddd" p={1}>
            <input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="fileInput"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => handleFileUpload(e, removeSpace(fields.input_from_ui))}
            />
            <label htmlFor="contained-button-file">
              <Box
                variant="contained"
                color="primary"
                component="span"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={(e) => fileDrop(e, removeSpace(fields.input_from_ui))}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <RenderIcons icon={getIcon(UploadIcon)} />
                <Typography
                  className=""
                  style={{
                    fontSize: 12,
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}
                >
                  Drag and drop File or
                </Typography>
                <Typography className="globalDragDropButton">
                  <Button
                    onClick={uploadFileClick}
                    variant="contained"
                    className="UploadBtn globalUploadButton"
                  >
                    Browser
                  </Button>
                </Typography>
                <Typography
                  variant="body1"
                  className="globalDragDropSubFileHeading"
                  style={{ fontSize: 12, marginTop: '10px' }}
                >
                  Maximum upload file size: 1GB
                </Typography>
                {curFileName}
              </Box>
            </label>
            {/* <FormHelperText error={fileNameErr}>
                            {fileNameHelp}
                          </FormHelperText> */}
          </Box>
        ) : (
          ''
        )}
        <br />
        <br />
      </React.Fragment>
    ));
}
/**
 *
 * @param {String} str
 * @returns  Removed Space string
 */
const removeSpace = (str) => {
  // alert(str)
  if (str) {
    const str2 = str.replace(/ /g, '_');

    return str2;
  }
  return '';
};
/**
 *
 * @param {string} dType
 * @param {boolean} isTest
 * @returns boolean value
 */
function IsDisabled(dType, isTest) {
  useEffect(() => {}, [isTest]);
  // console.log('dType', dType, isTest);
  if (dType === 'Db' && isTest === false) {
    return true;
  }
  return false;
}
