import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton
} from '@mui/material';

import { Visibility } from '@mui/icons-material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fDateTimeSec } from '../../../utils/formatTime';

export default function ApiErrorDialog({ setRefreshKGComp, forbidden }) {
  const {
    allSelectedData: { apiErrorDialogValue },
    allModelsForProjects
  } = useSelector((state) => state.knowledgeGraph);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const { modelId } = useParams();

  useEffect(() => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'apiErrorDialogValue',
        value: { dialog: false, message: '' }
      }
    });
  }, []);

  return (
    <>
      <Dialog
        //  onClose={() => setApiErrorHandle(false)}
        open={apiErrorDialogValue.dialog}
        sx={{ '& .MuiPaper-root': { maxWidth: '540px' } }}
      >
        <DialogTitle>Server Error</DialogTitle>
        <DialogContent>
          {allModelsForProjects && allModelsForProjects.length > 1 ? (
            <>
              <DialogContentText>
                There was an error while processing your request.
              </DialogContentText>
              <DialogContentText sx={{ marginTop: '25px', lineHeight: '1.5rem' }}>
                <strong>Note</strong>: This Project includes additional models. Click the{' '}
                <strong>'Switch'</strong> button to explore them.
              </DialogContentText>
            </>
          ) : (
            <DialogContentText>{apiErrorDialogValue.message}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {allModelsForProjects && allModelsForProjects.length > 1 && (
            <Button
              color="primary"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Switch
            </Button>
          )}
          <Button
            onClick={() => {
              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: {
                  key: 'apiErrorDialogValue',
                  value: { dialog: false, message: '' }
                }
              });
            }}
            color="success"
          >
            <Link
              to={'/knowledgegraph'}
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              Go to Homepage
            </Link>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ '& .css-11zwbe3-MuiPaper-root-MuiDialog-paper': { maxWidth: '80vw' } }}
      >
        <DialogTitle>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: '900',
              fontSize: '1rem',
              textAlign: 'center',
              margin: '10px'
            }}
          >
            Model List
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ '& .css-1khmt4d-MuiTableCell-root': { padding: '6px 10px' } }}>
                  <TableCell align="center" style={{ width: '100px' }}>
                    View Model
                  </TableCell>
                  <TableCell align="center">Model Name</TableCell>
                  <TableCell align="center">Creation Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allModelsForProjects.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      '& .MuiTableCell-root': {
                        backgroundColor:
                          String(row.link_id) === String(modelId) ? '#f1f1f1' : '#ffffff',
                        padding: '4px 10px'
                      }
                    }}
                  >
                    <TableCell align="center">
                      <Tooltip title="view" placement="right" arrow>
                        <IconButton
                          onClick={() => {
                            setOpenModal(false);
                            dispatch({
                              type: 'STORE_ALL_SELECTED_DATA',
                              payload: {
                                key: 'apiErrorDialogValue',
                                value: { dialog: false, message: '' }
                              }
                            });
                            navigate(
                              '/knowledgegraph/view/visualization/' +
                                row.project_id +
                                '/' +
                                row.link_id
                            );

                            if (setRefreshKGComp) {
                              setRefreshKGComp(new Date());
                            }
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.link_model_name}
                    </TableCell>

                    <TableCell align="center">
                      {fDateTimeSec(row.created_time)}
                      {/* <>
                        <span>{dateTimeFormatter(row.created_time).formattedDate}, </span>
                        <span style={{ paddingLeft: '10px' }}>
                          {dateTimeFormatter(row.created_time).formattedTime}
                        </span>
                      </> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
