import { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './components/layouts/dashboard/DashboardLayout';
//
import Login from './components/pages/Login';
import NotFound from './components/pages/Page404';
// import DatasourceList from './components/pages/Source/SourceList';
// import DataSourceAddPage from './components/pages/Source/SourceAdd';
// import MeasuresList from './components/pages/Measures/MeasuresList';
// import Setting from './components/pages/Setting';
// import MeasuresAdd from './components/pages/Measures/MeasuresAdd';
// import Policies from './components/pages/Policies/Policies';
// import Product from './components/pages/Products';

// import Measures from './components/pages/Measures/Measures';
// import Library from './components/pages/Library/Library';
// import MeasuresAdd from './components/pages/Measures/MeasuresAdd';

// Journey or Decison engine
// import DecisionList from './components/pages/Decision/DecisionList';
// import DecisionAdd from './components/pages/Decision/DecisionAdd';
// import DecisionList2 from './components/pages/Decision2/DecisionList';
// import DecisionAdd2 from './components/pages/Decision2/DecisionAdd';

// Rule pages
// import RulesList from './components/pages/Policies/RulesList';
// import RuleAdd from './components/pages/Policies/RuleAdd';
// import RunTest from './components/pages/Policies/RunTest';
// import RunPreview from './components/pages/Policies/RunPreview';

// Scorecard page
// import ScoreCardList from './components/pages/ScoreCard/ScoreCardList';
// import ScoreCardAdd from './components/pages/ScoreCard/ScoreCardAdd';
// import ManageScoreMatrix from './components/pages/ScoreCard/Managescorematrix';
// import ScoreMatrixAdd from './components/pages/ScoreCard/ScoreMatrixAdd';

// AutoMl page
// import AutoMLList from './components/pages/Model/AutoMLList';
// import CreateAutoMl from './components/pages/Model/CreateAutoMl';
// import AutoMLResult from './components/pages/Model/AutoMLResult';

// Dashboard
// knowledgeGraphs
import KnowledgeGraphList from './components/pages/KnowledgeGraph/KnowledgeGraphList';
import CreateKnowledgeGraph from './components/pages/KnowledgeGraph/CreateKnowledgeGraph';
// import VisualizationDashboard from './components/pages/visualization/VisualizationDashboard';
import Visualization from './components/pages/KnowledgeGraph/Visualization';
import UserManagement from './components/pages/UserManagement/UserManagement';
import AddUserRole from './components/pages/UserManagement/AddUserRole';
import SharedWithMe from './components/pages/ShareWithMe/ShareWithMe';
import RoleManagement from './components/pages/RoleManagement/RoleManagement';
import ForgetPassword from './components/pages/UserManagement/ForgetPassword';

// Manage Access
import ManageAccess from './components/pages/ManageAccess/ManageAccess';
import ManageAccessAdd from './components/pages/ManageAccess/ManageAccessAdd';

// Datasource
import DatasourceList from './components/pages/Datasource/DatasourceList';
import DataSourceAddPage from './components/pages/Datasource/DataSourceAddPage';

import { userDataFromLocal } from './utils/getUserDetails';

// import ScoreCardResult from './components/pages/ScoreCard/ScoreCardResult';
// ----------------------------------------------------------------------
/**
 * return routing paths
 * @return  {array}  routing object
 */
function Router() {
  const [userDeatils, setUserDetails] = useState({});
  useEffect(() => {
    const fetchUserDetailsLocalStorage = userDataFromLocal();
    console.log('fetchUserDetailsLocalStorage', fetchUserDetailsLocalStorage);
    if (fetchUserDetailsLocalStorage === 'null' || fetchUserDetailsLocalStorage === null) {
      // fetchUserDetailsLocalStorage = '{"isLogedIn":false}';
    }
    setUserDetails(fetchUserDetailsLocalStorage);
  }, []);
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/knowledgegraph" />
      // children: [
      //   { path: '', element: <Login /> },
      //   { path: '404', element: <NotFound /> },
      //   { path: '*', element: <Navigate to="/knowledgegraph" /> }
      // ]
    },
    {
      path: '/login',
      element: <Login />
    },
    // {
    //   path: '/product',
    //   element: <Product />
    // },
    // {
    //   path: '/decision',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <DecisionList /> },
    //     { path: 'add', element: <DecisionAdd /> },
    //     { path: 'edit', element: <DecisionAdd /> },
    //     { path: 'edit/', element: <DecisionAdd /> },
    //     { path: 'edit/:experimentId', element: <DecisionAdd /> },
    //     { path: 'edit/:experimentId/:experimentName', element: <DecisionAdd /> }
    //   ]
    // },
    // {
    //   path: '/decision2',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <DecisionList2 /> },
    //     { path: 'add', element: <DecisionAdd2 /> }
    //   ]
    // },
    // {
    //   path: '/scorecard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <ScoreCardList /> },
    //     { path: 'addnew', element: <ScoreCardAdd /> },
    //     { path: 'edit', element: <ScoreCardAdd /> },
    //     { path: 'managescorematrix', element: <ManageScoreMatrix /> },
    //     { path: 'addscorematrix', element: <ScoreMatrixAdd /> },
    //     { path: 'edit-scorematrix', element: <ScoreMatrixAdd /> },
    //     { path: 'scorecard-result', element: <ScoreCardResult /> }
    //   ]
    // },
    // {
    //   path: '/model',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <AutoMLList /> },
    //     { path: 'add', element: <CreateAutoMl /> },
    //     { path: 'result/:rowID', element: <AutoMLResult /> },
    //     { path: 'edit', element: <CreateAutoMl /> },
    //     { path: 'edit/', element: <CreateAutoMl /> },
    //     { path: 'edit/:experimentId', element: <CreateAutoMl /> },
    //     { path: 'edit/:experimentId/:experimentName', element: <CreateAutoMl /> }
    //   ]
    // },
    // {
    //   path: '/source',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <DatasourceList /> },
    //     { path: 'add', element: <DataSourceAddPage /> },
    //     { path: 'edit/:rowID', element: <DataSourceAddPage /> }
    //   ]
    // },
    // {
    //   path: '/measures',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <MeasuresList /> },
    //     { path: 'add', element: <MeasuresAdd /> },
    //     { path: 'edit', element: <MeasuresAdd /> },
    //     { path: 'edit/', element: <MeasuresAdd /> },
    //     { path: 'edit/:experimentId', element: <MeasuresAdd /> },
    //     { path: 'edit/:experimentId/:experimentName', element: <MeasuresAdd /> }
    //   ]
    // },
    // // {
    // //   path: '/catalogs',
    // //   element: <DashboardLayout />,
    // //   children: [
    // //     { path: '', element: <Catalogs /> },
    // //     { path: 'browser-catalog', element: <BrowserCatalog /> },
    // //     { path: 'browser-catalog/:dataset', element: <BrowserCatalogDataset /> },
    // //     { path: 'metadatainsight', element: <MetadataInsight /> },
    // //     { path: 'publish-catalog', element: <PublishCatalog /> },
    // //     { path: 'publish-catalog/add-new-source', element: <AddNewSource /> },
    // //     // { path: 'publish-catalog/:id', element: <AddNewSource /> },
    // //     { path: 'publish-catalog/edit/:userName/:rowId', element: <AddNewSource /> },
    // //     { path: 'publish-catalog/add-new-classification', element: <AddNewClassification /> },
    // //     { path: 'publish-catalog/add-new-scanrule', element: <AddNewScanRule /> },
    // //     { path: 'publish-catalog/view-source', element: <ViewSource /> }
    // //   ]
    // // },
    // // {
    // //   path: '/data-quality',
    // //   element: <DashboardLayout />,
    // //   children: [
    // //     { path: '', element: <DataQuality /> },
    // //     { path: 'data-quality-detailed', element: <DataQualityDetailed /> },
    // //     { path: 'data-quality-dataset', element: <DataQualityDataset /> }
    // //   ]
    // // },
    // {
    //   path: '/policies',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <RulesList /> },
    //     { path: 'create', element: <RuleAdd /> },
    //     { path: 'runtest', element: <RunTest /> },
    //     { path: 'runtest/:ruleId', element: <RunTest /> },
    //     { path: 'edit', element: <RuleAdd /> },
    //     { path: 'edit/', element: <RuleAdd /> },
    //     { path: 'edit/:ruleId', element: <RuleAdd /> },
    //     { path: 'preview/:ruleId', element: <RunPreview /> },
    //     { path: 'edit/:ruleId/:experimentName', element: <RuleAdd /> }
    //   ]
    // },
    // // {
    // //   path: '/measures',
    // //   element: <DashboardLayout />,
    // //   children: [
    // //     { path: '', element: <Measures /> },
    // //     { path: 'add', element: <MeasuresAdd /> }
    // //   ]
    // // },
    // // {
    // //   path: '/unify',
    // //   element: <DashboardLayout />,
    // //   children: [{ path: '', element: <Unify /> }]
    // // },
    // {
    //   path: '/library',
    //   element: <DashboardLayout />,
    //   children: [{ path: '', element: <Library /> }]
    // },
    {
      path: '/knowledgegraph',
      children: [
        { path: '', element: <KnowledgeGraphList /> },
        { path: 'add', element: <CreateKnowledgeGraph /> },
        { path: 'edit', element: <CreateKnowledgeGraph /> },
        { path: 'edit/:experimentId', element: <CreateKnowledgeGraph /> },
        {
          path: 'edit/:experimentId/:experimentName',
          element: <CreateKnowledgeGraph noModal={false} />
        },
        {
          path: 'edit/:experimentId/:experimentName/:modelId',
          element: <CreateKnowledgeGraph noModal={true} />
        },
        { path: 'view/visualization', element: <Visualization /> },
        { path: 'view/visualization/:experimentId/:modelId', element: <Visualization /> }
      ]
    },
    {
      path: '/tbml',
      children: [
        { path: '', element: <KnowledgeGraphList /> },
        { path: 'add', element: <CreateKnowledgeGraph /> },
        { path: 'edit', element: <CreateKnowledgeGraph /> },
        { path: 'edit/:experimentId', element: <CreateKnowledgeGraph /> },
        {
          path: 'edit/:experimentId/:experimentName',
          element: <CreateKnowledgeGraph noModal={false} />
        },
        {
          path: 'edit/:experimentId/:experimentName/:modelId',
          element: <CreateKnowledgeGraph noModal={true} />
        },
        { path: 'view/visualization', element: <Visualization /> },
        { path: 'view/visualization/:experimentId/:modelId', element: <Visualization /> }
      ]
    },
    {
      path: '/cus360',
      children: [{ path: '', element: <KnowledgeGraphList /> }]
    },
    {
      path: '/usermanagement',
      children: [
        { path: '', element: <UserManagement /> },
        { path: '/usermanagement/add-user', element: <AddUserRole /> },
        { path: '/usermanagement/forget-password/:userId', element: <ForgetPassword /> },
        { path: '/usermanagement/edit/:username', element: <AddUserRole /> }
      ]
    },
    {
      path: `/manage-access`,
      // element: <DashboardLayout />,
      children: [
        { path: '', element: <ManageAccess /> },
        { path: 'add', element: <ManageAccessAdd /> },
        { path: 'edit/:userid', element: <ManageAccessAdd /> }
      ]
    },
    {
      path: `/datasource`,
      // element: <DashboardLayout />,
      children: [
        { path: '', element: <DatasourceList /> },
        { path: 'add', element: <DataSourceAddPage /> },
        { path: 'edit/:rowID', element: <DataSourceAddPage /> }
      ]
    },

    {
      path: '/shared-with-me',
      children: [
        { path: '', element: <KnowledgeGraphList /> }
        // { path: '', element: <SharedWithMe /> }
        //  { path:'edit-domain', element: <DomainMaster/>}
      ]
    },

    {
      path: '/rolemanagement',
      children: [
        { path: '', element: <RoleManagement /> }
        //  { path:'edit-domain', element: <DomainMaster/>}
      ]
    },

    // {
    //   path: '/visualizations',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <VisualizationDashboard /> },
    //     // { path: 'add', element: <CreateKnowledgeGraph /> },
    //     // { path: 'edit', element: <CreateKnowledgeGraph /> },
    //     // { path: 'edit/:experimentId', element: <CreateKnowledgeGraph /> },
    //     // { path: 'edit/:experimentId/:experimentName', element: <CreateKnowledgeGraph /> },
    //     { path: 'view/visualization', element: <Visualization /> },
    //     { path: 'view/visualization/:experimentId', element: <Visualization /> }
    //   ]
    // },
    { path: '*', element: <NotFound /> }
  ]);
}
export default Router;
