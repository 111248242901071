import { Box, Typography } from '@mui/material';
import React from 'react';
import kg_logo from '../../../images/kg_logo.png';

export default function LoaderBlur({ customMsg, bgProp }) {
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        background: bgProp ? bgProp : 'rgba(0,0,0,0.6)',
        backdropFilter: 'blur(2px)',
        top: '0',
        left: '0',
        zIndex: '10000000'
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          zIndex: '10000000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <img
          className="imgLoadingAnimation"
          style={{ height: 100, width: 100 }}
          // src="https://i.ibb.co/BKmwmTy/logo192.png"
          src={kg_logo}
          alt=""
        />

        <Typography style={{ fontSize: '1rem', color: bgProp ? '#000' : '#fff' }}>
          {customMsg ? customMsg : 'Please wait...'}
        </Typography>
      </Box>
    </Box>
  );
}
