// ----------------------------------------------------------------------
/**
 * Shape of the boxes using in Project
 * @module shape
 */
const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16
};

export default shape;
