import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Box, Tabs, Tab, Tooltip } from '@mui/material';
import { Search, Close, Scale } from '@mui/icons-material';
import './BottomTabsStyle.css';

export default function SearchNodesAndEdges({ searchBarRef }) {
  const dispatch = useDispatch();
  const nodeEdgeSearchClicks = useSelector((state) => state.knowledgeGraph.allSelectedData);
  const { slectedOption, currentNodeid, hideSideBar, expanded, currentEdgeData } =
    nodeEdgeSearchClicks;

  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  // console.log('1knowledgeGraphStoreItem', knowledgeGraphStoreItem);
  const {
    createKnowledgeGraph: { cyToState }
  } = knowledgeGraphStoreItem;
  const [isActive, setIsActive] = useState(false);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [searchedValue, setSearchedValue] = useState('');
  const [searchedNodesArr, setSearchedNodesArr] = useState('');
  const [searchedEdgesArr, setSearchedEdgesArr] = useState('');
  const [searchTabValue, setSearchTabValue] = useState('nodes');

  const resetSearchValue = () => {
    setSearchedNodesArr([]);
    setSearchedEdgesArr([]);
    setSearchedData([]);
  };

  //   const ref = useRef();
  // useEffect(() => {
  //   const handleClick = (event) => {
  //     if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
  //       resetSearchValue();
  //       setSearchedValue('');
  //       cyToState.elements().style({ opacity: 1, events: 'yes' });

  //       // toggleClass();
  //       setIsActive(false);
  //     }
  //   };

  //   document.addEventListener('click', handleClick, true);

  //   return () => {
  //     document.removeEventListener('click', handleClick, true);
  //   };
  // }, [searchBarRef, isActive]);

  const revertHighlighting = () => {
    cyToState.elements().removeClass('faded');
  };

  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const highlightedNode = (id) => {
    resetSearchValue();
    // setIsActive(false);
    const nodeId = cyToState.getElementById(id);
    const nodePosition = nodeId.position();
    const viewPortCenter = { x: cyToState.width(), y: cyToState.height() };
    const panX = viewPortCenter.x / 2 - nodePosition.x;
    const panY = viewPortCenter.y / 2 - nodePosition.y;
    // console.log('viewPortCenter', nodePosition);

    cyToState.elements().addClass('faded');

    nodeId.removeClass('faded');

    cyToState.zoom({
      level: 1,
      position: nodeId.position()
    });

    cyToState.animate({
      pan: { x: panX, y: panY },
      duration: 1000 // Adjust the duration as needed
    });

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'slectedOption',
        value: 'node'
      }
    });

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'currentNodeid',
        value: id
      }
    });

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'currentNodeInfo',
        value: nodeId.data()
      }
    });

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBar',
        value: 'hideBehind'
      }
    });

    // setIsExpanded('panel2');
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'expanded',
        value: 'panel2'
      }
    });
  };

  const highlightedEdge = (id) => {
    resetSearchValue();

    // Get the edge element by its ID
    const edgeId = cyToState.getElementById(id);

    // Get the source and target nodes of the edge
    const sourceNode = edgeId.source();
    const targetNode = edgeId.target();

    // Calculate the average position of the source and target nodes
    const sourcePosition = sourceNode.position();
    const targetPosition = targetNode.position();
    const edgePosition = {
      x: (sourcePosition.x + targetPosition.x) / 2,
      y: (sourcePosition.y + targetPosition.y) / 2
    };

    const viewPortCenter = { x: cyToState.width() / 2, y: cyToState.height() / 2 };
    const panX = viewPortCenter.x - edgePosition.x;
    const panY = viewPortCenter.y - edgePosition.y;

    cyToState.elements().addClass('faded');
    edgeId.removeClass('faded');

    sourceNode.removeClass('faded');
    targetNode.removeClass('faded');

    cyToState.zoom({
      level: 1
    });

    cyToState.animate({
      pan: { x: panX, y: panY },
      duration: 800,
      easing: 'ease'
    });

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'slectedOption',
        value: 'edge'
      }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBar',
        value: 'hideBehind'
      }
    });
    // setIsExpanded('panel3');
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'expanded',
        value: 'panel3'
      }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'currentEdgeData',
        value: edgeId.data()
      }
    });
  };

  const getSearchedresults = (e) => {
    const searchTerms = e.target.value;
    setSearchedValue(searchTerms);
    const searchResults = cyToState.elements().filter((item) => {
      // Check if any of the keys contain the search term
      const keysToSearch = ['label', 'labelType'];
      const hasSearchTermInKeys = keysToSearch.some((key) => {
        if (
          (item.data()[key] && typeof item.data()[key] === 'string') ||
          typeof item.data()[key] === 'number'
        ) {
          // console.log('mySearchKey', key);
          // console.log('mySearchKey', item.data());
          // console.log('mySearchKey', item.data()[key]);
          // console.log('mySearchKey', item.data()[key].toLowerCase());
          // console.log('mySearchKeyKey', item.data()[key].toLowerCase());

          return String(item.data()[key]).toLowerCase().includes(searchTerms.toLowerCase());
        }
      });

      // Check if any value in the 'props' object contains the search term
      const propsValues = Object.values(item.data().props);
      // console.log('propsValues', propsValues);

      const hasSearchTermInProps = propsValues.some((value) => {
        // console.log('propsValues 1', value);
        return String(value).toLowerCase().includes(searchTerms.toLowerCase());
      });
      return hasSearchTermInKeys || hasSearchTermInProps;
    });

    // console.log('searchResults', searchResults);
    if (searchResults.length > 0 && searchTerms !== '') {
      const searchednodes = [];
      const searchededges = [];
      searchResults.forEach((element) => {
        if (element.group() === 'nodes') {
          searchednodes.push(element);
        }
        if (element.group() === 'edges') {
          searchededges.push(element);
        }
      });
      setSearchedNodesArr(searchednodes);
      setSearchedEdgesArr(searchededges);
      setSearchedData(searchResults);
      setNoMatchFound(false);
    } else {
      setNoMatchFound(true);
      resetSearchValue();
      // setSearchedValue('');
    }
  };

  return (
    <Box className="nodesSearchArea">
      <Box className={isActive ? 'searchbar active' : 'searchbar'}>
        <Box className="searchboxholder">
          <TextField
            className="search_input"
            type="text"
            placeholder="Search..."
            key="password"
            value={searchedValue}
            onChange={(e) => getSearchedresults(e)}
          />
          <Box href="#" className="search_icon">
            {isActive ? (
              <Close
                onClick={() => {
                  dispatch({
                    type: 'STORE_ALL_SELECTED_DATA',
                    payload: {
                      key: 'hideSideBar',
                      value: ''
                    }
                  });
                  toggleClass();
                  resetSearchValue();
                  setSearchedValue('');
                  revertHighlighting();
                  setNoMatchFound(false);
                  // cyToState.elements().animate(
                  //   {
                  //     style: {
                  //       opacity: 1,
                  //       events: 'yes',
                  //       'border-width': 2,
                  //       'border-color': '#52a8e1',
                  //       'line-color': '#ddd',
                  //       'target-arrow-color': '#c4c4c4'
                  //     }
                  //   },
                  //   {
                  //     duration: 500
                  //   }
                  // );
                }}
              />
            ) : (
              <Tooltip title="Search">
                <Search onClick={() => toggleClass()} />
              </Tooltip>
            )}
          </Box>
        </Box>
        {searchedData.length > 0 && isActive && (
          <Box className="displaySearchedBox">
            <Box className="searchBoxHeader">
              <Tabs
                value={searchTabValue}
                onChange={(e, newValue) => setSearchTabValue(newValue)}
                classes={{ root: 'tabRoot', flexContainer: 'tabFlexContainer' }}
              >
                <Tab
                  label="Nodes"
                  value="nodes"
                  classes={{
                    root: 'childTabRoot',
                    selected: 'childTabsSelected',
                    disabled: 'childTabdisabled',
                    wrapped: 'childTabWrapped'
                  }}
                  icon={
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 256 256"
                      height="1.5em"
                      width="1.5em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M208,116a28,28,0,0,0-27.71,24H152a44.2,44.2,0,0,1-35.2-17.6L87.28,83A28,28,0,1,0,76,83.71v88.58a28,28,0,1,0,8,0V92l26.4,35.2A52.26,52.26,0,0,0,152,148h28.29A28,28,0,1,0,208,116ZM60,56A20,20,0,1,1,80,76,20,20,0,0,1,60,56Zm40,144a20,20,0,1,1-20-20A20,20,0,0,1,100,200Zm108-36a20,20,0,1,1,20-20A20,20,0,0,1,208,164Z" />
                    </svg>
                  }
                />
                <Tab
                  label="Edges"
                  value="edges"
                  classes={{
                    root: 'childTabRoot',
                    selected: 'childTabsSelected',
                    disabled: 'childTabdisabled',
                    wrapped: 'childTabWrapped'
                  }}
                  icon={
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      t="1569683635191"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M843.5 737.4c-12.4-75.2-79.2-129.1-155.3-125.4S550.9 676 546 752c-153.5-4.8-208-40.7-199.1-113.7 3.3-27.3 19.8-41.9 50.1-49 18.4-4.3 38.8-4.9 57.3-3.2 1.7 0.2 3.5 0.3 5.2 0.5 11.3 2.7 22.8 5 34.3 6.8 34.1 5.6 68.8 8.4 101.8 6.6 92.8-5 156-45.9 159.2-132.7 3.1-84.1-54.7-143.7-147.9-183.6-29.9-12.8-61.6-22.7-93.3-30.2-14.3-3.4-26.3-5.7-35.2-7.2-7.9-75.9-71.5-133.8-147.8-134.4-76.3-0.6-140.9 56.1-150.1 131.9s40 146.3 114.2 163.9c74.2 17.6 149.9-23.3 175.7-95.1 9.4 1.7 18.7 3.6 28 5.8 28.2 6.6 56.4 15.4 82.4 26.6 70.7 30.2 109.3 70.1 107.5 119.9-1.6 44.6-33.6 65.2-96.2 68.6-27.5 1.5-57.6-0.9-87.3-5.8-8.3-1.4-15.9-2.8-22.6-4.3-3.9-0.8-6.6-1.5-7.8-1.8l-3.1-0.6c-2.2-0.3-5.9-0.8-10.7-1.3-25-2.3-52.1-1.5-78.5 4.6-55.2 12.9-93.9 47.2-101.1 105.8-15.7 126.2 78.6 184.7 276 188.9 29.1 70.4 106.4 107.9 179.6 87 73.3-20.9 119.3-93.4 106.9-168.6zM329.1 345.2c-46 0-83.3-37.3-83.3-83.3s37.3-83.3 83.3-83.3 83.3 37.3 83.3 83.3-37.3 83.3-83.3 83.3zM695.6 845c-46 0-83.3-37.3-83.3-83.3s37.3-83.3 83.3-83.3 83.3 37.3 83.3 83.3-37.3 83.3-83.3 83.3z" />
                    </svg>
                  }
                />
              </Tabs>
              {searchTabValue === 'nodes' && (
                <Box className="searchResHolder">
                  {searchedNodesArr.length > 0 &&
                    searchedNodesArr.map((nodes, nodeIndex) => {
                      // console.log('allNodes', nodes.data());
                      const { labelType, props, label, id } = nodes.data();
                      return (
                        <Box
                          className="searchResRows"
                          id={`${labelType}-${label}`}
                          key={`${labelType}-${label}-${nodeIndex}`}
                          onClick={() => highlightedNode(id)}
                        >
                          <Box className="manageSearchRows">
                            <div className="LeftSideResultPanel">
                              <div
                                className="labelSearch"
                                dangerouslySetInnerHTML={{
                                  __html: highlightSearchTerm(labelType, searchedValue)
                                }}
                              />
                              <div className="propertSearch">
                                {getPropSearched(props, searchedValue)}
                              </div>
                            </div>
                            <div
                              className="customChip"
                              dangerouslySetInnerHTML={{
                                __html: highlightSearchTerm(label, searchedValue)
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              )}
              {searchTabValue === 'edges' && (
                <Box className="searchResHolder">
                  {searchedEdgesArr.length > 0 &&
                    searchedEdgesArr.map((edges, nodeIndex) => {
                      // console.log('alledgesStyle', edges.data());
                      const { labelType, props, label, id } = edges.data();

                      return (
                        <Box
                          className="searchResRows"
                          key={`${labelType}-${label}-${nodeIndex}`}
                          onClick={() => highlightedEdge(id)}
                        >
                          <Box>
                            {label && (
                              <span
                                className="labelSearch"
                                dangerouslySetInnerHTML={{
                                  __html: highlightSearchTerm(label, searchedValue)
                                }}
                              />
                            )}

                            <div className="propertSearch">
                              {getPropSearched(props, searchedValue)}
                            </div>
                          </Box>
                          {/* <Box>
                        <div
                          className="customChip"
                          dangerouslySetInnerHTML={{
                            __html: highlightSearchTerm(labelType, searchedValue)
                          }}
                        />
                      </Box> */}
                        </Box>
                      );
                    })}
                </Box>
              )}
            </Box>
          </Box>
        )}
        {noMatchFound && searchedValue !== '' && (
          <Box className="displaySearchedBox">
            <Box className="searchBoxHeader">
              <Box
                class="searchResRows"
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                No match found for{' '}
                <strong style={{ backgroundColor: 'yellow', margin: '0 5px', padding: '0 5px' }}>
                  {searchedValue}
                </strong>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function getPropSearched(nodesProps, searchedValue) {
  // console.log('nodesProps', nodesProps, searchedValue);
  return (
    <Box>
      {Object.entries(nodesProps).map(([key, value]) => {
        if (String(value).toLowerCase().includes(String(searchedValue).toLowerCase())) {
          return (
            <div key={key} className="ResultSmallPart">
              <strong>{key}</strong>

              <div>:</div>
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightSearchTerm(value.toString(), searchedValue)
                }}
              />
            </div>
          );
        }
        return '';
      })}
    </Box>
  );
}

function highlightSearchTerm(text, searchTerm) {
  const regex = new RegExp(searchTerm, 'gi');
  return String(text).replace(
    regex,
    (match) => `<span style="background-color: yellow; color:#000">${match}</span>`
  );
}
