import {
  Box,
  TextField,
  Select,
  MenuItem,
  Grid,
  Paper,
  styled,
  Button,
  Typography,
  InputLabel,
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import './ruleEngineQuery.css';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { InputAdornment } from '@material-ui/core';
import { getIcon, UploadIcon, ColorPanelIcon } from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
import CustomizedTextField from './CustomizedTextField';
import CustomizedTextFieldGraphNodes from './CustomizedTextFieldGraphNodes';
import RuleEngineQuery from './RuleEngineQuery';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';

const operators = [
  { name: '=', label: 'Equals', type: 'string' },
  { name: 'contains', label: 'Contains', type: 'string' },
  { name: 'starts_with', label: 'Starts With', type: 'string' },
  { name: 'ends_with', label: 'Ends With', type: 'string' },
  { name: 'matches', label: 'Matches (Regex)', type: 'string' },
  { name: 'like', label: 'Like (SQL Wildcards)', type: 'string' },
  { name: 'i_equals', label: 'Case-Insensitive Equals', type: 'string' }
];

const initialQuery = { rules: [] };

export default function NodeRuleEngine(props) {
  const {
    currentMetaDataId,
    setRefreshStyleComp,
    cyToStateTwo,
    labelToDisplay,
    selectedNode,
    setSelectedNode,
    toggleSaveModal,
    setToggleSaveModal,
    savedRulesNameList,
    setSavedRulesNameList,
    setSavedRuleSelection,
    setSavedNodeRuleData,
    savedNodeRuleData,
    nodeSavedRulesNameList,
    setNodeSavedRulesNameList,
    patchToggleSaveModal,
    setPatchToggleSaveModal,
    selectedRules,
    setMutateSavedRuleList
  } = props;

  let _debounceTimer;

  const _debounceSetState = (newValue, setValue, delay) => {
    clearTimeout(_debounceTimer);
    _debounceTimer = setTimeout(() => {
      setValue(newValue);
    }, delay);
  };

  const { experimentId, modelId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { GetApiByUrl } = KnowledgeGraphService;

  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const {
    createKnowledgeGraph: { cyToState }
  } = knowledgeGraphStoreItem;
  const [query, setQuery] = useState(initialQuery);
  const [fieldData, setFieldData] = useState([]);
  const [savedRulesData, setSavedRulesData] = useState([]);
  const [ruleTextFieldName, setRuleTextFieldName] = useState('');
  const [ruleTextFieldErrorMsg, setRuleTextFieldErrorMsg] = useState('');
  // const [savedRulesNameList, setSavedRulesNameList] = useState([]);
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [radioValue, setRadioValue] = useState('Image');
  const [expanded, setExpanded] = useState(false);

  const initialNodeStyles = {
    'background-color': '',
    label: 'data(label)',
    height: 80,
    width: 80,
    padding: 10,
    'background-fit': 'cover',
    'background-opacity': 1,
    'border-width': '2',
    'border-style': 'solid',
    'border-color': '',
    'border-opacity': 1,
    shape: 'ellipse',
    'background-repeat': 'no-repeat',
    'background-image': [],
    'background-image-opacity': 1,
    'font-size': 9,
    // 'text-margin-y': '-7px',
    'text-valign': 'center',
    'text-halign': 'center',
    color: '',
    'text-opacity': 1
  };
  const [allRuleStyleSettings, setAllRuleStyleSettings] = useState(initialNodeStyles);
  const [dynamicNodeRuleEngineKey, setDynamicNodeRuleEngineKey] = useState(
    'dynamicNodeRuleEngineKey'
  );

  useEffect(() => {
    if (patchToggleSaveModal) {
      (async () => {
        const metaResponse = await GetApiByUrl(
          `visual/manage_metadata?link_id=${modelId}&project_id=${experimentId}`
        );

        if (
          metaResponse.status === 'success' &&
          metaResponse.code === 200 &&
          // --------------- Model Specific ---------------------------
          metaResponse.data.metadata.length > 0 &&
          Object.keys(metaResponse.data.metadata[0].node_rules).length > 0
        ) {
          const savedRuleId = selectedRules[0].id;
          const { node_rules } = metaResponse.data.metadata[0];

          if (Object.keys(node_rules).length > 0 && node_rules[savedRuleId]) {
            const _savedStyles = {
              'background-color': node_rules[savedRuleId]['background-color'] ?? 'red',
              label: node_rules[savedRuleId]?.label,
              height: node_rules[savedRuleId]?.height,
              width: node_rules[savedRuleId]?.width,
              padding: node_rules[savedRuleId]?.padding,
              'background-fit': node_rules[savedRuleId]['background-fit'],
              'background-opacity': node_rules[savedRuleId]['background-opacity'],
              'border-width': node_rules[savedRuleId]['border-width'],
              'border-style': node_rules[savedRuleId]['border-style'],
              'border-color': node_rules[savedRuleId]['border-color'] ?? '#000',
              'border-opacity': node_rules[savedRuleId]['border-opacity'],
              shape: node_rules[savedRuleId]?.shape,
              'background-repeat': node_rules[savedRuleId]['background-repeat'],
              'background-image': node_rules[savedRuleId]['background-image'],
              'background-image-opacity': node_rules[savedRuleId]['background-image-opacity'],
              'font-size': node_rules[savedRuleId]['font-size'],
              // 'text-margin-y': '-7px',
              'text-valign': node_rules[savedRuleId]['text-valign'],
              'text-halign': node_rules[savedRuleId]['text-halign'],
              color: node_rules[savedRuleId]?.color,
              'text-opacity': node_rules[savedRuleId]['text-opacity']
            };
            setAllRuleStyleSettings(_savedStyles);
          } else {
            console.log('no data found');
          }
        }
      })();
    }
  }, [patchToggleSaveModal]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const uploadFileClick = (e) => {
    const browseField = document.getElementById('contained-button-file');
    browseField.click();
  };
  const handleImageDialogOpen = () => {
    setOpenImageUpload(true);
  };
  const handleImageDialogClose = () => {
    setOpenImageUpload(false);
  };

  // const [toggleSaveModal, setToggleSaveModal] = useState(false);
  // const [autoCompleteValue, setAutoCompleteValue] = useState('');

  const handleOpen = () => setToggleSaveModal(true);
  const handleNodeRuleClose = () => {
    // alert('this');
    setToggleSaveModal(false);
    setPatchToggleSaveModal(false);
    console.log('handleRuleClose');
  };

  const handleNodeRuleResetValues = () => {
    setAllRuleStyleSettings(initialNodeStyles);
    setDynamicNodeRuleEngineKey(new Date());
  };
  // -- Push all Nodes properties to the rule engine.
  useEffect(() => {
    if (cyToState) {
      const fieldDataAll = [];
      cyToState.elements().forEach((element) => {
        // console.log('pp', element.data().props);
        if (element.data().props !== undefined) {
          fieldDataAll.push(...Object.keys(element.data().props));
        }
      });

      const filteredData = [...new Set(fieldDataAll)];
      const formattedFieldsData = filteredData.map((data) => ({
        name: data,
        label: data
      }));
      setFieldData([...formattedFieldsData]);
    }
  }, [cyToState]);

  // Debounce function for style properties with different delays
  const debounceTimers = {};

  const debouncedUpdateState = (styleProperty, value, delay) => {
    clearTimeout(debounceTimers[styleProperty]);
    debounceTimers[styleProperty] = setTimeout(() => {
      setAllRuleStyleSettings((prevSettings) => ({
        ...prevSettings,
        [styleProperty]: value
      }));
      setRefreshStyleComp(new Date());
    }, delay);
  };

  const handleStyleValueChange = (e, styleProperty, debounceDelay) => {
    const newValue = e.target.value;
    if (debounceDelay) {
      debouncedUpdateState(styleProperty, newValue, debounceDelay);
    } else {
      setAllRuleStyleSettings((prevSettings) => ({
        ...prevSettings,
        [styleProperty]: newValue
      }));
      setRefreshStyleComp(new Date());
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));

  return (
    <Box>
      <Modal
        open={toggleSaveModal || patchToggleSaveModal}
        onClose={handleNodeRuleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
        key="noderulengine"
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '85%',
            background: 'white',
            border: '1px solid #ddd',
            boxShadow: 24,
            padding: '1.25rem 1rem',
            height: 'auto',
            maxHeight: '80vh',
            borderRadius: 8,
            overflow: 'scroll'
          }}
          className="drawerWrap"
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '-1rem',
              marginBottom: '25px',
              padding: '0.50rem 1rem 0.25rem 1rem',
              backgroundColor: '#061d6b'
            }}
          >
            <Typography variant="h6" style={{ color: '#fff' }}>
              {patchToggleSaveModal ? 'Edit Node Rule' : 'Create Node Rule'}
            </Typography>
            <CloseIcon onClick={handleNodeRuleClose} style={{ cursor: 'pointer', color: '#fff' }} />
          </Box>
          {/* <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Item className="RuleEngineGridItemFlex">
                <Typography className="labelGridItem">Rule Name</Typography>
                <CustomizedTextField
                  ruleTextFieldName={ruleTextFieldName}
                  setRuleTextFieldName={setRuleTextFieldName}
                  ruleTextFieldErrorMsg={ruleTextFieldErrorMsg}
                  placeholder="Enter rule Name here..."
                />
              </Item>
            </Grid>
            <Grid item xs={6} md={6}>
              <Item className="RuleEngineGridItemFlex">
                <Typography className="labelGridItem">Labels</Typography>
                <FormControl
                  variant="standard"
                  style={{ width: '100%' }}
                  className="inputGridItemField"
                >
                 
                  <Select
                    className="form-control selectControl"
                    value={selectedNode}
                    onChange={(e) => {
                      setSelectedNode(e.target.value);
                      if (e.target.value !== 'all') {
                        const selectedStyle = cyToStateTwo
                          .nodes(`[label='${e.target.value}']`)
                          .style();
                        if (selectedStyle) {
                          const prevObj = {};
                          Object.keys(allRuleStyleSettings).map((key) => {
                            if (key.includes('color')) {
                              let red = selectedStyle[key].replace('rgb(', '');
                              red = red.replace(')', '');
                              // console.log('redred', red);
                              prevObj[key] = rgbToHex(red.split(','));
                            } else {
                              prevObj[key] = selectedStyle[key];
                            }
                          });
                          // console.log('checkLabel', prevObj);
                          // rgbToHex
                          setAllRuleStyleSettings(prevObj);
                        }
                      }
                    }}
                    label="nodeProperty"
                  >
                    <MenuItem value="all">All</MenuItem>
                    {labelToDisplay.length > 0 &&
                      labelToDisplay.map((node) => (
                        <MenuItem value={node.label}>{node.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Item>
            </Grid>
          </Grid> */}
          <Accordion
            className="accordTRuleEngine"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              className="accordTrigger"
              aria-controls="panel1d-content"
              id="panel1d-header"
              classes={{ content: 'accordTgrtxt' }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '0.825rem',
                  fontWeight: '500',
                  marginLeft: '5px'
                }}
                gutterBottom
              >
                Customize Node Properties:
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Node Shape</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Node Shape</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings.shape}
                      onChange={(e) => handleStyleValueChange(e, 'shape')}
                      // setRefreshStyleComp(new Date());
                      label="nodeProperty"
                    >
                      {/* <MenuItem value="">Select </MenuItem> */}
                      <MenuItem value="ellipse">Ellipse</MenuItem>
                      <MenuItem value="triangle">Triangle</MenuItem>
                      <MenuItem value="round-triangle">Round-triangle</MenuItem>
                      <MenuItem value="rectangle">Rectangle</MenuItem>
                      <MenuItem value="round-rectangle">Round-rectangle</MenuItem>
                      <MenuItem value="bottom-round-rectangle">Bottom-round-rectangle</MenuItem>
                      <MenuItem value="cut-rectangle">cut-rectangle</MenuItem>
                      <MenuItem value="barrel">Barrel</MenuItem>
                      <MenuItem value="rhomboid">Rhomboid</MenuItem>
                      <MenuItem value="diamond">Diamond</MenuItem>
                      <MenuItem value="round-diamond">Round-diamond</MenuItem>
                      <MenuItem value="pentagon">Pentagon</MenuItem>
                      <MenuItem value="round-pentagon">Round-pentagon</MenuItem>
                      <MenuItem value="hexagon">Hexagon</MenuItem>
                      <MenuItem value="round-hexagon">Round-hexagon</MenuItem>
                      <MenuItem value="concave-hexagon">Concave-hexagon</MenuItem>
                      <MenuItem value="heptagon">Heptagon</MenuItem>
                      <MenuItem value="round-heptagon">Round-heptagon</MenuItem>
                      <MenuItem value="octagon">Octagon</MenuItem>
                      <MenuItem value="round-octagon">Round-octagon</MenuItem>
                      <MenuItem value="star">Star</MenuItem>
                      <MenuItem value="tag">Tag</MenuItem>
                      <MenuItem value="round-tag">Round-tag</MenuItem>
                      <MenuItem value="vee">Vee</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Size</Typography>
                  {/* <CustomizedTextFieldGraphNodes
                    allRuleStyleSettings={allRuleStyleSettings}
                    setAllRuleStyleSettings={setAllRuleStyleSettings}
                    setRefreshStyleComp={setRefreshStyleComp}
                    propType="width"
                    propType2="height"
                  /> */}

                  <TextField
                    variant="standard"
                    size="small"
                    value={allRuleStyleSettings.width}
                    onChange={(e) => {
                      handleStyleValueChange(e, 'width');
                      handleStyleValueChange(e, 'height');
                    }}
                    className="inputGridItemField"
                    error={ruleTextFieldErrorMsg ? true : false}
                    helperText={ruleTextFieldErrorMsg}
                  />
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Padding</Typography>
                  <TextField
                    variant="standard"
                    size="small"
                    value={allRuleStyleSettings.padding}
                    onChange={(e) => handleStyleValueChange(e, 'padding')}
                    className="inputGridItemField"
                    error={ruleTextFieldErrorMsg ? true : false}
                    helperText={ruleTextFieldErrorMsg}
                  />
                </Grid>

                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Background Color</Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="inputGridItemField"
                  >
                    <input
                      type="color"
                      id="nodeBgColor"
                      name="nodeBgColor"
                      style={{
                        width: '20px',
                        height: '24px',
                        marginRight: '15px',
                        padding: 0
                      }}
                      value={allRuleStyleSettings['background-color']}
                      onChange={(e) => handleStyleValueChange(e, 'background-color', 300)}
                    />
                    <TextField
                      variant="standard"
                      //  label="Background Color"
                      InputLabelProps={{ className: 'shiftLeft' }}
                      style={{
                        width: 'auto',
                        marginLeft: '5px',
                        BorderBottom: 'solid'
                      }}
                      value={allRuleStyleSettings['background-color']}
                      disabled
                      placeholder="#000000"
                    />
                    {/* <RenderIcons icon={getIcon(ColorPanelIcon, null, 18, 18)} /> */}
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Background Opacity</Typography>
                  <Slider
                    value={allRuleStyleSettings['background-opacity']}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'background-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ margin: '15px auto auto auto' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  className="RuleEngineGridItemFlex"
                  sx={{ borderBottom: '1px solid #ddd', borderRadius: '0' }}
                >
                  {/* <Typography className="labelGridItem">Upload Image</Typography> */}
                  <Box className="fileTypeClm col-2" style={{ marginTop: '20px' }}>
                    <Button
                      onClick={(e) => {
                        handleImageDialogOpen(e.target);
                      }}
                    >
                      Upload Image
                    </Button>
                    <Dialog
                      open={openImageUpload}
                      onClose={(e) => {
                        handleImageDialogClose();
                      }}
                      fullWidth
                    >
                      <Box sx={{ textAlign: 'center' }}>
                        <DialogContent>Upload background image</DialogContent>
                      </Box>

                      <Box m={2}>
                        <FormControl className="imgUploadTab">
                          <RadioGroup
                            className="radioHolder"
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radioValue}
                            onChange={(e) => {
                              setBackgroundImage('');
                              setRadioValue(e.target.value);
                            }}
                            // onChange={handlebackgroundImage}
                          >
                            <FormControlLabel value="Image" control={<Radio />} label="Image url" />
                            <FormControlLabel
                              value="Upload"
                              control={<Radio />}
                              label="Upload image"
                            />
                          </RadioGroup>
                        </FormControl>
                        {radioValue === 'Image' ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              mt: 3
                            }}
                          >
                            <TextField
                              sx={{ width: 400 }}
                              // variant="filled"
                              // value={backgroundImage}
                              placeholder="Paste image link here."
                              onChange={(e) =>
                                _debounceSetState(e.target.value, setBackgroundImage, 200)
                              }
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              mt: 3
                            }}
                          >
                            <input
                              className="imgtablist"
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              onChange={(e) => {
                                const files = e.target.files;
                                getBase64(files[0], setBackgroundImage, enqueueSnackbar);
                              }}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <TextField
                                sx={{ width: 400 }}
                                contentEditable={false}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Button
                                        className="btnFileType"
                                        onClick={(e) => uploadFileClick(e)}
                                        startIcon={
                                          <RenderIcons icon={getIcon(UploadIcon, null, 16, 16)} />
                                        }
                                      >
                                        Browse
                                      </Button>
                                    </InputAdornment>
                                  )
                                }}
                                value={backgroundImage}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          p: 0,
                          m: 0,
                          mt: 2,
                          pb: 2,
                          py: 1
                        }}
                      >
                        <DialogActions>
                          <Box>
                            <Button
                              onClick={(e) => handleImageDialogClose(e)}
                              variant="outlined"
                              color="primary"
                            >
                              Close
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              disabled={backgroundImage === '' || backgroundImage === null}
                              onClick={(e) => {
                                setAllRuleStyleSettings((prevSettings) => ({
                                  ...prevSettings,
                                  'background-image': backgroundImage
                                }));
                                handleImageDialogClose();
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              Submit
                            </Button>
                          </Box>
                        </DialogActions>
                      </Box>
                    </Dialog>
                  </Box>
                </Grid>

                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Background Repeat</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Background Repeat</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['background-repeat']}
                      onChange={(e) => {
                        setAllRuleStyleSettings((prevSettings) => ({
                          ...prevSettings,
                          'background-repeat': e.target.value
                        }));
                        // setRefreshStyleComp(new Date());
                      }}
                      label="nodeProperty"
                    >
                      {/* <MenuItem value="">Select </MenuItem> */}
                      <MenuItem value="no-repeat">No-repeat</MenuItem>
                      <MenuItem value="repeat">Repeat</MenuItem>
                      <MenuItem value="repeat-x">Repeat-x</MenuItem>
                      <MenuItem value="repeat-y">Rrepeat-y</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Background Image Opacity</Typography>
                  <Slider
                    value={allRuleStyleSettings['background-image-opacity']}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'background-image-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ margin: '15px auto auto auto' }}
                  />
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Background Fit</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Background Fit</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['background-fit']}
                      onChange={(e) => {
                        setAllRuleStyleSettings((prevSettings) => ({
                          ...prevSettings,
                          'background-fit': e.target.value
                        }));
                        // setRefreshStyleComp(new Date());
                      }}
                      label="nodeProperty"
                    >
                      {/* <MenuItem value="">Select </MenuItem> */}
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="contain">Contain</MenuItem>
                      <MenuItem value="cover">Cover</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Border Width</Typography>
                  <TextField
                    variant="standard"
                    size="small"
                    value={allRuleStyleSettings['border-width']}
                    onChange={(e) => handleStyleValueChange(e, 'border-width')}
                    className="inputGridItemField"
                    error={ruleTextFieldErrorMsg ? true : false}
                    helperText={ruleTextFieldErrorMsg}
                  />
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Border Style</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Border Style</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['border-style']}
                      onChange={(e) => {
                        setAllRuleStyleSettings((prevSettings) => ({
                          ...prevSettings,
                          'border-style': e.target.value
                        }));
                        // setRefreshStyleComp(new Date());
                      }}
                      label="nodeProperty"
                    >
                      {/* <MenuItem value="">Select </MenuItem> */}
                      <MenuItem value="solid">Solid</MenuItem>
                      <MenuItem value="dotted">Dotted</MenuItem>
                      <MenuItem value="dashed">Dashed</MenuItem>
                      <MenuItem value="double">Double</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Border Color</Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="inputGridItemField"
                  >
                    <input
                      type="color"
                      id="nodeBdrColor"
                      name="nodeBdrColor"
                      style={{
                        width: '20px',
                        height: '24px',
                        marginRight: '15px',
                        padding: 0
                      }}
                      value={allRuleStyleSettings['border-color']}
                      onChange={(e) => handleStyleValueChange(e, 'border-color', 300)}
                    />
                    <TextField
                      variant="standard"
                      // label="Border Color"
                      InputLabelProps={{ className: 'shiftLeft' }}
                      style={{
                        width: 'auto',
                        marginLeft: '5px',
                        BorderBottom: 'solid'
                      }}
                      value={allRuleStyleSettings['border-color']}
                      onChange={(e) => {
                        setAllRuleStyleSettings((prevSettings) => ({
                          ...prevSettings,
                          'border-color': e.target.value
                        }));
                        // setRefreshStyleComp(new Date());
                      }}
                      disabled
                      placeholder="#000000"
                    />
                    {/* <RenderIcons icon={getIcon(ColorPanelIcon, null, 18, 18)} /> */}
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Border Opacity</Typography>
                  <Slider
                    value={allRuleStyleSettings['border-opacity']}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'border-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ margin: '15px auto auto auto' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="accordTRuleEngine"
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              className="accordTrigger"
              aria-controls="panel2d-content"
              id="panel2d-header"
              classes={{ content: 'accordTgrtxt' }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '0.825rem',
                  fontWeight: '500',
                  marginLeft: '5px'
                }}
                gutterBottom
              >
                Customize Node Labels:
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <TextField
                    variant="standard"
                    size="small"
                    value={allRuleStyleSettings['font-size']}
                    onChange={(e) => handleStyleValueChange(e, 'font-size')}
                    className="inputGridItemField"
                    error={ruleTextFieldErrorMsg ? true : false}
                    helperText={ruleTextFieldErrorMsg}
                  />
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Text Color</Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    variant="standard"
                    className="inputGridItemField"
                  >
                    <input
                      type="color"
                      id="txtColor"
                      name="txtColor"
                      style={{
                        width: '20px',
                        height: '24px',
                        marginRight: '15px',
                        padding: 0
                      }}
                      value={allRuleStyleSettings.color}
                      onChange={(e) => handleStyleValueChange(e, 'color', 300)}
                    />
                    <TextField
                      variant="standard"
                      // label="Text  Color"
                      InputLabelProps={{ className: 'shiftLeft' }}
                      style={{
                        width: 'auto',
                        marginLeft: '5px',
                        BorderBottom: 'solid'
                      }}
                      value={allRuleStyleSettings.color}
                      disabled
                      placeholder="#000000"
                    />
                    {/* <RenderIcons icon={getIcon(ColorPanelIcon, null, 18, 18)} /> */}
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Text Opacity</Typography>
                  <Slider
                    value={allRuleStyleSettings['text-opacity']}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'text-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ margin: '15px auto auto auto' }}
                  />
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Vertical Position</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Vertical Position</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['text-valign']}
                      onChange={(e) => {
                        setAllRuleStyleSettings((prevSettings) => ({
                          ...prevSettings,
                          'taxt-valign': e.target.value
                        }));
                        // setRefreshStyleComp(new Date());
                      }}
                      label="nodeProperty"
                    >
                      <MenuItem value="center">Center</MenuItem>
                      <MenuItem value="top">Top</MenuItem>
                      <MenuItem value="bottom">Bottom</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Horizontal Position</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Horizontal Position</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['text-halign']}
                      onChange={(e) => {
                        setAllRuleStyleSettings((prevSettings) => ({
                          ...prevSettings,
                          'text-halign': e.target.value
                        }));
                        // setRefreshStyleComp(new Date());
                      }}
                      label="nodeProperty"
                    >
                      <MenuItem value="center">Center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                      <MenuItem value="right">Right</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* <QueryBuilder
            fields={fieldData}
            operators={operators}
            query={query}
            onQueryChange={(q) => setQuery(q)}
            controlElements={{
              addGroupAction: () => null
            }}
            independentCombinators
            showCloneButtons
            showLockButtons
          /> */}
          <RuleEngineQuery
            key={dynamicNodeRuleEngineKey}
            currentMetaDataId={currentMetaDataId}
            stylesheet={allRuleStyleSettings}
            savedRulesNameList={nodeSavedRulesNameList}
            setSavedRulesNameList={setNodeSavedRulesNameList}
            closeRuleSaveModel={handleNodeRuleClose}
            ruleType="node_rule"
            handleNodeRuleResetValues={handleNodeRuleResetValues}
            selectedRules={selectedRules}
            patchToggleSaveModal={patchToggleSaveModal}
            setMutateSavedRuleList={setMutateSavedRuleList}
          />
          {/* <Button
            sx={{ float: 'right', marginTop: '20px' }}
            onClick={handleRuleSave}
            variant={'contained'}
          >
            Save Rule
          </Button> */}
        </Box>
      </Modal>
    </Box>
  );
}

function rgbToHex(r, g, b) {
  // console.log('rrr', r);
  const hexR = Math.floor(r[0]).toString(16).padStart(2, '0');
  const hexG = Math.floor(r[1]).toString(16).padStart(2, '0');
  const hexB = Math.floor(r[2]).toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
}

function getBase64(file, setState, enqueueSnackbar) {
  const maxSizeInBytes = 20 * 1024; // 20kb

  if (file.size > maxSizeInBytes) {
    setState('');
    enqueueSnackbar('File size exceeds the maximum limit (20KB).', {
      variant: 'error',
      autoHideDuration: 3000
    });
    return;
  }

  // Reset error and update the state with the selected file
  enqueueSnackbar('File uploaded succesfully.', {
    variant: 'success',
    autoHideDuration: 3000
  });
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    // console.log(reader.result);
    setState(reader.result);
    // base64 = reader.result;
  };
  reader.onerror = function (error) {
    // console.log('Error: ', error);
  };
}
