// React
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as ReactDOMServer from 'react-dom/server';

// material ui
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  ButtonGroup,
  Autocomplete,
  TextField,
  Radio,
  tableBodyClasses,
  Snackbar,
  Slide,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  DialogActions,
  IconButton,
  ListItemText,
  InputLabel,
  FormControl,
  OutlinedInput
} from '@mui/material';
import { Close, Phone } from '@material-ui/icons';
import MuiAlert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// Icons
import { FaCloudUploadAlt } from 'react-icons/fa';
import { BsMapFill } from 'react-icons/bs';
import { Checklist } from '@mui/icons-material';
import { AiOutlineDelete, AiOutlineLink } from 'react-icons/ai';
import { GrClose, GrEdit } from 'react-icons/gr';

// State management
import { useSelector, useDispatch } from 'react-redux';

// cytoscapejs
import CytoscapeComponent from 'react-cytoscapejs';

// Drawflow for maping two source
import Drawflow from 'drawflow';
import 'drawflow/dist/drawflow.min.css';
import axios from 'axios';

// styling
import './KnowledgeGraph.css';

// user Details
import { userDataFromLocal } from '../../../utils/getUserDetails';
import { CheckBoxIcon, CheckBoxOutlineBlank } from '../../../vector';

// API services
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import dataSourceService from '../../../services/dataSourceService';
import LoaderBlur from './LoaderBlur';
// import DatasourceList from '../Datasource/DatasourceList';

const { DatasetSchema, GetTableNames, GetFieldsNames, SaveSourceMapings, GetApiByUrl } =
  KnowledgeGraphService;
const { Datasourcelist } = dataSourceService;

function KgDataSourceComp({
  editor,
  setRefreshPopupComp,
  refreshPopupComp,
  handleChange,
  measuredRefRoot,
  refreshTableConnectionCount
}) {
  // Enable Buttton

  const { enqueueSnackbar } = useSnackbar();

  const { experimentId } = useParams();
  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const dataSourceListItems = useSelector((state) => state.knowledgeGraph);
  const {
    allSelectedData,
    sourceTypeList,
    exitsTableList,
    selectedTableList,
    dataTypeDropdownList,
    currFieldsLists,
    exitsColumnListForMap,
    mappedFieldList,
    selectedTableListMap,
    refreshDataSourceComp,
    createKnowledgeGraph: { datasourceLinkMap }
  } = dataSourceListItems;
  const dispatch = useDispatch();
  console.log('mappedFieldListmappedFieldList', dataSourceListItems);

  // Get schema lists from API on select datasource
  const getSchemaList = async (e) => {
    setLoader(true);
    const selectedDatasourcename = e.target.value;
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'dataSource', value: { id: selectedDatasourcename, name: '%%' } }
    });
    const currentUser = userDataFromLocal().userName;
    // const urlParams = `fetch_schema_lists/?create_user=postgres&datasource_id=${selectedDatasourcename}`;
    const urlParams = `visual/fetch_schema_lists?datasource_id=${selectedDatasourcename}`;
    const getPropertiesData = await DatasetSchema(urlParams);
    if (getPropertiesData.status === 'success') {
      getPropertiesData.data.forEach((item) => (item.schema = item.table_schema));
      dispatch({
        type: 'STORE_LISTS_DATA',
        payload: { key: 'schemaLists', value: getPropertiesData.data }
      });

      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'schemas', value: { id: '%%', name: '%%' } }
      });

      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'tables', value: { id: '%%', name: '%%' } }
      });
    }
    setLoader(false);
  };

  // Get table lists from API on select schema
  const getDataSetList = async (e) => {
    setLoader(true);
    const selectedSchemaename = e.target.value;
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'schemas', value: { id: '%%', name: selectedSchemaename } }
    });
    // const currentUser = userDataFromLocal().userName;
    // const urlParams = `fetch_table_list/?create_user=postgres&datasource_id=${dataSourceListItems.allSelectedData.dataSource.id}&schema_name=${selectedSchemaename}`;

    const authToken = userDataFromLocal();
    // const params = `?user_id=${authToken.userID}`;
    const urlParams = `visual/fetch_table_list?datasource_id=${dataSourceListItems.allSelectedData.dataSource.id}&schema_name=${selectedSchemaename}`;
    const getPropertiesData = await GetTableNames(urlParams);
    // console.log('getEditor', getPropertiesData);
    if (getPropertiesData.status === 'success') {
      getPropertiesData.data.forEach((item) => (item.table = item.table_name));
      dispatch({
        type: 'STORE_LISTS_DATA',
        payload: { key: 'tablesLists', value: getPropertiesData.data }
      });
      // temp
      dispatch({
        type: 'STORE_LISTS_DATA',
        payload: { key: 'mapedSourceLists', value: getPropertiesData.data }
      });

      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'tables', value: { id: '%%', name: '%%' } }
      });
      // console.log('getEditor', editor.nodes().length);
      // console.log('getEditor', editor.editor);
      if (editor && editor.editor && editor.editor.nodes().length > 0) {
        editor.editor.nodes().forEach((element) => {
          element.data().dataset = getPropertiesData.data;
        });
      }
      // console.log('getEditor', editor.editor.nodes());
    }
    setLoader(false);
  };

  // Get fields lists from API on select table
  const getFieldsNames = async (selectedTableName, otherData) => {
    setLoader(true);

    const currentUser = userDataFromLocal().userName;
    // const urlParams = `show_fields_name/?create_user=postgres&datasource_id=${otherData.dataSource.id}&schema_name=${otherData.schemas.name}&table_name=${selectedTableName}`;
    let urlParams = '';
    if (allSelectedData.sourceType.id === 'File') {
      urlParams = `visual/show_fields_name?datasource_id=${selectedTableName}`;
    } else {
      urlParams = `visual/show_fields_name?datasource_id=${otherData.dataSource.id}&schema_name=${otherData.schemas.name}&table_name=${selectedTableName}`;
    }
    const getPropertiesData = await GetFieldsNames(urlParams);
    // console.log('getPropertiesData', getPropertiesData);
    if (getPropertiesData.status === 'success') {
      setNextBtnDisabled(false);

      getPropertiesData.data.forEach((item) => {
        item.primary_key = false;
        item.character_maximum_length = getMaxlength(item);
      });
      setAutocomplateInputVal(getPropertiesData.data);
      dispatch({
        type: 'STORE_LISTS_DATA',
        payload: { key: 'currFieldsLists', value: getPropertiesData.data }
      });
    } else {
      setNextBtnDisabled(true);
      enqueueSnackbar(getPropertiesData?.message, {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    setLoader(false);
  };

  // Steps labels values
  const steps = ['Source', 'Map', 'Link'];

  // Define a function to handle menu item selection
  const handleSourceTypeSelect = (event) => {
    const selectedSourceType = event.target.value;
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'sourceType', value: { id: selectedSourceType, name: selectedSourceType } }
    });
  };
  // const [changeFileMenuSelection, setChangeFileMenuSelection] = useState('Select');

  const [selectedFileType, setSelectedFileType] = useState('Select');
  const [openAlert, setOpenAlert] = useState(false);
  const [uploadFileErrorMsg, setUploadFileErrorMsg] = useState('');

  const handleFileTypeChange = (event) => {
    setSelectedFileType(event.target.value);
  };

  const handleFileUpload = (event) => {
    //  event.preventDefault();

    if (!event.target.files || event.target.files.length === 0) {
      // setUploadFileErrorMsg('No file selected.');
      // console.log('No file selected.');
      return;
    }

    const selectedFile = event.target.files[0];
    setUploadFileErrorMsg('');

    if (
      (selectedFileType === 'Excel' &&
        selectedFile.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
      (selectedFileType === 'Excel' && selectedFile.type === 'application/vnd.ms-excel') ||
      (selectedFileType === 'CSV' && selectedFile.type === 'text/csv') ||
      (selectedFileType === 'TSV' && selectedFile.type === 'text/tab-separated-values') ||
      (selectedFileType === 'XML' && selectedFile.type === 'application/xml') ||
      (selectedFileType === 'XML' && selectedFile.type === 'text/xml') ||
      (selectedFileType === 'JSON' && selectedFile.type === 'application/json') ||
      (selectedFileType === 'TXT' && selectedFile.type === 'text/plain')
    ) {
      // console.log('selectedFileTypeee', selectedFileType);
      // console.log('Uploading file:', selectedFile.name);
    } else {
      setOpenAlert(true); // Show the alert
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false); // Close the alert
  };

  // Steps active state
  const [activeStep, setactiveStep] = useState(0);

  // All linked popup
  const [showAllLinked, setShowAllLinked] = useState(false);

  // State for new or exits datasource maping
  const [newOrExits, setNewOrExits] = useState('new');

  // state for show hide maped column screen
  const [ShowMapExitsColumn, setShowMapExitsColumn] = useState(null);
  const [ShowMapColumn, setShowMapColumn] = useState(null);
  const [mapeExitsSource, setmapeExitsSource] = useState(null);

  // Mutate component
  const [mutateComp, setMutateComp] = useState(null);

  // Enable Button
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [mapBtnDisabled, setMapBtnDisabled] = useState(true);
  const [confirmationPopup, setConfirmationPopup] = useState({ open: false, confirm: false });

  useEffect(() => {
    if (activeStep === 0) {
      setNextBtnDisabled(true);
      if (
        dataSourceListItems.allSelectedData.dataSource.id !== '%%' &&
        dataSourceListItems.allSelectedData.schemas.name !== '%%' &&
        dataSourceListItems.allSelectedData.tables.name !== '%%'
      ) {
        setNextBtnDisabled(false);
      } else if (
        allSelectedData.sourceType.id === 'File' &&
        dataSourceListItems.allSelectedData.tables.name !== '%%'
      ) {
        setNextBtnDisabled(false);
      }
    }
    if (activeStep === 1) {
      setMapBtnDisabled(true);
      setNextBtnDisabled(true);
      const primaryKeyArr = dataSourceListItems.currFieldsLists.filter((item) => {
        return item.primary_key === true;
      });

      if (allSelectedData.curentdataType.name !== '%%' && allSelectedData.newtableName !== '') {
        setMapBtnDisabled(false);
        if (primaryKeyArr.length > 0) {
          setNextBtnDisabled(false);
        }
      }
    }
  }, [
    dataSourceListItems.allSelectedData.dataSource.id,
    dataSourceListItems.allSelectedData.schemas.name,
    dataSourceListItems.allSelectedData.tables.name,
    allSelectedData.curentdataType.name,
    allSelectedData.newtableName,
    allSelectedData.appTableExits,
    activeStep
  ]);

  // Graph will be hide until datasource not inti
  const [initMainGraph, setInitMainGraph] = useState(null);

  // Style for all linked nodes
  const nodeStyle = [
    {
      selector: 'node',
      css: {
        content: 'data(id)',
        'text-valign': 'center',
        'text-halign': 'center',
        width: '120px',
        height: '15px',
        'font-size': '9px',
        shape: 'rectangle'
      }
    },
    {
      selector: ':parent',
      css: {
        'text-valign': 'top',
        'text-halign': 'center',
        paddingTop: '10px',
        fontSize: '9px'
      }
    },
    {
      selector: 'edge',
      css: {
        'curve-style': 'bezier',
        'target-arrow-shape': 'triangle'
      }
    }
  ];
  const [nodesData, setNodesData] = useState([
    { data: { id: 'a', parent: 'b' }, position: { x: 215, y: 85 } },
    { data: { id: 'b' } },
    { data: { id: 'c', parent: 'b' }, position: { x: 300, y: 85 } },
    { data: { id: 'd' }, position: { x: 215, y: 175 } },
    { data: { id: 'e' } },
    { data: { id: 'f', parent: 'e' }, position: { x: 300, y: 175 } },
    { data: { id: 'ad', source: 'a', target: 'd' } },
    { data: { id: 'eb', source: 'e', target: 'b' } }
  ]);
  const [cyToState, setCyToState] = useState(null);
  const [currentSelected, setCurrentSelected] = useState(null);

  const [drawFlowCanvas, setDrawFlowCanvas] = useState(null);

  const [autoComplateInputVal, setAutocomplateInputVal] = useState([]);
  // console.log('autoComplateInputVal', autoComplateInputVal);

  const rightPosition = document.body.clientWidth - 240;

  const dataForMaspping = {
    currFieldsLists: [
      { id: 1, column_name: 'column 1' },
      { id: 2, column_name: 'column 2' },
      { id: 3, column_name: 'column 3' },
      { id: 4, column_name: 'column 4' },
      { id: 5, column_name: 'column 5' },
      { id: 6, column_name: 'column 6' },
      { id: 7, column_name: 'column 7' },
      { id: 8, column_name: 'column 8' },
      { id: 9, column_name: 'column 9' }
    ]
  };

  const measuredRef = useCallback((node) => {
    // console.log('nodenode', node);
    // console.log('nodenode', node !== null);
    // console.log('nodenode', node === null);
    if (node !== null) {
      const canvas = new Drawflow(node);
      // console.log('nodenode', canvas);
      canvas.reroute = true;
      const dataToImport = {
        drawflow: {
          Home: {
            data: {
              1: {
                id: 1,
                name: 'welcome',
                data: {},
                class: 'columnHolder leftHolder',
                html: getLeftHolderHtml(dataSourceListItems),
                typenode: false,
                inputs: {},
                outputs: getOutputDots(dataSourceListItems),
                pos_x: 27,
                pos_y: 30
              },
              2: {
                id: 2,
                name: 'slack',
                data: {},
                class: 'columnHolder rightHolder',
                html: getRightHolderHtml(exitsColumnListForMap, allSelectedData.exitsTableName),
                typenode: false,
                inputs: getInputDots(exitsColumnListForMap),
                outputs: {},
                pos_x: rightPosition,
                pos_y: 30
              }
            }
          }
        }
      };

      // console.log('nodenode', dataToImport);
      if (node.childNodes.length < 1) {
        canvas.start();
        canvas.import(dataToImport);
        setDrawFlowCanvas(canvas);
      }
    }
  });

  const [loader, setLoader] = useState(false);
  const [tableConnectionCount, setTableConnectionCount] = useState(0);

  const handleResetAllValues = () => {
    console.log('resetting values');
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'commonTableColumns',
        value: { key: null }
      }
    });
    dispatch({
      type: 'CREATE_KG',
      payload: {
        key: 'datasourceLinkMap',
        value: ''
      }
    });
    setactiveStep(0);

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'dataSource', value: { id: '%%', name: '%%' } }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'curentdataType',
        value: { id: '%%', name: '%%' }
      }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'exitsTableName',
        value: ' '
      }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'schemas', value: { id: '%%', name: '%%' } }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'tables', value: { id: '%%', name: '%%' } }
    });

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'initMainGraph', value: true }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'currentActiveStep', value: 3 }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'currentdataSetId', value: '' }
    });
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: { key: 'newtableName', value: '' }
    });
  };

  useEffect(() => {
    if (document.querySelector('.drawflow')) {
      const drawflowDiv = document.querySelector('.drawflow');

      if (drawflowDiv) {
        const svgElements = drawflowDiv.querySelectorAll('.connection');
        // console.log(svgElements.length, 'document.query');
        setTableConnectionCount(svgElements.length);
      }
    }
  }, [refreshPopupComp, measuredRefRoot, refreshTableConnectionCount]);

  const finshiedSourceLinkNmap = async () => {
    setLoader(true);
    // console.log('exitsTableList2', exitsTableList);
    // console.log('measuredRefRoot', allSelectedData.newtableName);
    // console.log('datasourceLinkMap', datasourceLinkMap);
    const allDrawFlowData = datasourceLinkMap.drawflow.drawflow.Home.data;
    const newObj = {};
    const commonAppCol = {};
    //   {
    //     "table_name__titanic111": {"partner_recom": "passengerId", 'score_deb': 'survived'},
    // }
    const drawFlowData = {};
    // console.log('exitsTableList2', datasourceLinkMap.drawflow);
    if (datasourceLinkMap.drawflow) {
      // console.log('fromCanvas', 'condition');
      Object.values(allDrawFlowData).forEach((tableNode) => {
        // console.log('tableNode2', tableNode);
        // console.log('tableNode2', allSelectedData.newtableName);
        if (tableNode.name === allSelectedData.newtableName) {
          Object.values(tableNode.outputs).forEach((output) => {
            // console.log('exitsTableList2', output.connections);
            if (output.connections.length > 0) {
              // console.log('fromCanvas', 'connection condition');
              // console.log('exitsTableList2', allDrawFlowData);
              // console.log('exitsTableList2', output.connections[0].output);
              // console.log('exitsTableList2', output.connections[0].node);
              const prevDatasetId = exitsTableList.filter(
                (tableObj) => tableObj.table_name === output.connections[0].node
              )[0].dataset_id;
              const tableSource =
                allDrawFlowData[output.connections[0].node].inputs[output.connections[0].output]
                  .connections[0].node;
              // console.log('exitsTableList2', tableSource);
              // console.log('exitsTableList2', prevDatasetId);
              const columnSource =
                allDrawFlowData[output.connections[0].node].inputs[output.connections[0].output]
                  .label.column_name;
              // console.log('exitsTableList2', columnSource);
              const tabletarget = output.connections[0].node;
              // console.log('exitsTableList2', tabletarget);
              const columnTarget = output.label.column_name;
              // console.log('exitsTableList2', columnTarget);
              const targetSource = [columnTarget, columnSource];
              const objKey = `${tableSource}__${tabletarget}`;
              const objVal = { [columnTarget]: columnSource };
              if (objKey in newObj) {
                newObj[objKey] = { ...newObj[objKey], ...objVal };
              } else {
                newObj[objKey] = {
                  [columnTarget]: columnSource
                };
              }
              if (prevDatasetId in commonAppCol) {
                commonAppCol[prevDatasetId] = [...commonAppCol[prevDatasetId], ...[targetSource]];
              } else {
                commonAppCol[prevDatasetId] = [targetSource];
              }
            }
          });
        }
      });
    }
    currFieldsLists.forEach((filedList) => {
      if (filedList.output) {
        const objKey = `${allSelectedData.newtableName}__${filedList.output[0].node}`;
        const objVal = { [filedList.column_name]: filedList.output[0].output };
        if (objKey in newObj) {
          newObj[objKey] = { ...newObj[objKey], ...objVal };
        } else {
          newObj[objKey] = {
            [filedList.column_name]: filedList.output[0].output
          };
        }
      }
    });
    console.log('commonAppCol', commonAppCol);
    const appTableScema = [];
    if (currFieldsLists && currFieldsLists.length > 0) {
      currFieldsLists.forEach((filedList) => {
        const obj = {
          datasource_column_name: filedList.column_name,
          app_column_name: filedList.column_name,
          data_type: filedList.data_type,
          size: getMaxlength(filedList),
          exist: false,
          primary_key: filedList.primary_key,
          foreign_key: {}
        };
        appTableScema.push(obj);
      });
    }
    const credentials = {
      action: 'create',
      datasource_id:
        allSelectedData.sourceType.id === 'File'
          ? allSelectedData.tables.id
          : allSelectedData.dataSource.id,
      project_id: experimentId,
      created_by: 'postgres',
      schema_name: allSelectedData.schemas.name,
      datasource_table_name: allSelectedData.tables.name,
      dataset_type: allSelectedData.curentdataType.name,
      app_table_name: allSelectedData.newtableName,
      source_table_pr_key: appTableScema.find((item) => item.primary_key)?.datasource_column_name,
      app_table_exists: allSelectedData.appTableExits,
      app_datasets_common_columns: commonAppCol,
      app_table_schema: appTableScema,
      ui_config: datasourceLinkMap.drawflow ? datasourceLinkMap.drawflow : {}
    };
    // console.log('saveSourceMaping', credentials);
    const saveSourceMaping = await SaveSourceMapings(credentials);
    // console.log('saveSourceMaping', saveSourceMaping);
    if (saveSourceMaping.status === 'success' && saveSourceMaping.code === 200) {
      allDrawFlowData[allSelectedData.newtableName].dataset_id = saveSourceMaping.data.dataset_id;
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'commonTableColumns',
          value: commonAppCol
        }
      });
      dispatch({
        type: 'CREATE_KG',
        payload: {
          key: 'datasourceLinkMap',
          value: {
            ...datasourceLinkMap,
            datasourceLinkMap: { drawflow: { drawflow: { Home: { data: allDrawFlowData } } } }
          }
        }
      });
      setactiveStep(0);
      if (exitsTableList.length === 1) {
        handleChange('panel4');
      }
      if (exitsTableList.length > 1) {
        handleChange('panel5');
      }

      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'dataSource', value: { id: '%%', name: '%%' } }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'curentdataType',
          value: { id: '%%', name: '%%' }
        }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'exitsTableName',
          value: ' '
        }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'schemas', value: { id: '%%', name: '%%' } }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'tables', value: { id: '%%', name: '%%' } }
      });

      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'initMainGraph', value: true }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'currentActiveStep', value: 3 }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'currentdataSetId', value: saveSourceMaping.data.dataset_id }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: { key: 'newtableName', value: '' }
      });
    }
    // http://216.48.182.96:9998/visual/manage_app_tables newtableName
    setLoader(false);
  };
  useEffect(() => {
    // check IF cyToState not null so click function work properly
    if (cyToState !== null) {
      cyToState.fit();
      cyToState.on('tap', 'node', (evt) => {
        const selectedIndex = evt.target.data().index;
        const addMoreLinkingArray = dataSourceListItems.addMoreLinkingArray;
        setCurrentSelected(addMoreLinkingArray[selectedIndex]);
        // console.log('evt', evt.target.data());
      });
      cyToState.on('tap', 'edge', (evt) => {
        const selectedIndex = evt.target.data().index;
        const addMoreLinkingArray = dataSourceListItems.addMoreLinkingArray;
        setCurrentSelected(addMoreLinkingArray[selectedIndex]);
        // console.log('evt', evt.target.data());
      });

      cyToState.on('tap', (evt) => {
        // evt.target to target clicked elm
        if (evt.target === cyToState) {
          setCurrentSelected(null);
        }
      });
    }
    (async () => {
      const getPropertiesData = await GetFieldsNames('visual/get_datatypes');
      if (getPropertiesData.status === 'success' && getPropertiesData.code === 200) {
        const dataTypeArray = [{ lable: 'select' }];
        getPropertiesData.data.forEach((dataType) => dataTypeArray.push({ lable: dataType }));
        // console.log('dataTypeArray', dataTypeArray);
        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: { key: 'sourceTypeList', value: dataTypeArray }
        });
      }
      const getTablesList = await GetFieldsNames('visual/manage_app_tables');
      if (getTablesList.status === 'success' && getTablesList.code === 200) {
        // experimentId
        // console.log('getTablesList', experimentId);
        // console.log('getTablesList', getTablesList.data[0].project_id);
        const filteredTableNames = getTablesList.data.filter(
          (item) => Number(item.project_id) === Number(experimentId)
        );
        // console.log('mappedFieldListmappedFieldList', filteredTableNames);
        const lastAddedTables = findLengthyObject(filteredTableNames);
        // console.log('lastAddedTables', lastAddedTables);
        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: { key: 'exitsTableList', value: filteredTableNames }
        });
        // console.log('mappedFieldListmappedFieldList', filteredTableNames.length);
        if (filteredTableNames.length > 0) {
          // console.log('mappedFieldListmappedFieldList', filteredTableNames[0]);
          // console.log(
          //   'mappedFieldListmappedFieldList',
          //   filteredTableNames[0].ui_config.drawflow.Home.data
          // );
          // dispatch({
          //   type: 'STORE_ALL_SELECTED_DATA',
          //   payload: { key: 'currentActiveStep', value: 3 }
          // });

          dispatch({
            type: 'STORE_ALL_SELECTED_DATA',
            payload: { key: 'initMainGraph', value: true }
          });
          dispatch({
            type: 'STORE_LISTS_DATA',
            payload: {
              key: 'mappedFieldList',
              value: lastAddedTables
            }
          });
        } else {
          dispatch({
            type: 'STORE_ALL_SELECTED_DATA',
            payload: { key: 'initMainGraph', value: false }
          });
          dispatch({
            type: 'STORE_LISTS_DATA',
            payload: {
              key: 'mappedFieldList',
              value: {}
            }
          });
        }
      } else {
        enqueueSnackbar(getTablesList?.message, {
          variant: 'error',
          autoHideDuration: 2000
        });
      }
    })();
    // if (drawFlowCanvas !== null) {
    //   drawFlowCanvas.on(
    //     'connectionCreated',
    //     ({ output_id, input_id, output_class, input_class }) => {
    //       const outputLabel = drawFlowCanvas.getNodeFromId(output_id).outputs[output_class].label;
    //       const inputLabel = drawFlowCanvas.getNodeFromId(input_id).inputs[input_class].label;
    //       // console.log(outputLabel, inputLabel);
    //     }
    //   );
    // }
  }, [cyToState, drawFlowCanvas, activeStep, experimentId]);

  let sourceTypeTimeoutID;
  let sourceTypeID;

  const [allExistingTableNames, setAllExistingTableNames] = useState([]);
  const [mapBtnCondiion, setMapBtnCondiion] = useState(false);
  const [fileUploadedTabels, setFileUploadedTabels] = useState([]);
  const [multiColDatatypeDialog, setMultiColDatatypeDialog] = useState({
    dialog: false,
    columns: [],
    datatype: ''
  });

  useEffect(() => {
    handleResetAllValues();
    (async () => {
      try {
        const response = await GetApiByUrl(
          // `visual/fetch_table_list/?create_user=postgres&datasource_id=4&schema_name=data_integration`
          `visual/fetch_table_list?datasource_id=4&schema_name=data_integration`
        );
        if (response.status === 'success') {
          setAllExistingTableNames(response.data.map((item) => item.table_name));
        }

        // console.log(
        //   'saty Data:',
        //   response.data.data.map((item) => item.table_name)
        // );
      } catch (error) {
        // Something happened in setting up the request that triggered an error
        console.error('Error:', error.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (allSelectedData.sourceType.id === 'File') {
      (async () => {
        try {
          // Retrieving the authentication token from userDataFromLocal
          const authToken = userDataFromLocal();

          // Calling Datasourcelist API to fetch the list data
          // const params = `?datasource_type=File&user_id=${authToken.userID}`;
          const params = `?user_id=${authToken.userID}&data_source_type=File`;
          const _fileTableData = await Datasourcelist(params, authToken);

          // Checking if the API call was successful
          if (_fileTableData.status === 'success') {
            // Update the state with the fetched data
            setFileUploadedTabels(_fileTableData.data);
          }
        } catch (error) {
          // Handling unexpected errors
          console.error('Error:', error);
          // enqueueSnackbar('An error occurred while fetching data.', {
          //   variant: 'error',
          //   autoHideDuration: 2000
          // });
        }
      })();
    }
  }, [allSelectedData.sourceType.id]);

  const handleSubmitColMapping = () => {
    if (confirmationPopup.confirm) {
      setNextBtnDisabled(false);
      if (mapeExitsSource) {
        // console.log('drawFlowCanvas', drawFlowCanvas);
        const currentTableColumn = drawFlowCanvas.drawflow.drawflow.Home.data[1].outputs;
        const currFieldsListsArray = [];
        Object.values(currentTableColumn).map((tableColumn) => {
          // console.log('drawFlowCanvas2', tableColumn);
          const obj = {
            datasource_column_name: tableColumn.label.column_name,
            app_column_name: tableColumn.label.column_name,
            exist: true,
            primary_key: false,
            data_type: '',
            size: 0,
            foreign_key: {}
          };
          if (tableColumn.connections.length > 0) {
            const connectedColumn = tableColumn.connections[0];
            // console.log('drawFlowCanvas3', connectedColumn);
            const connectedlabel = drawFlowCanvas.getNodeFromId(connectedColumn.node).inputs[
              connectedColumn.output
            ];
            // console.log('drawFlowCanvas4', connectedlabel);
            obj.primary_key = connectedlabel.label.primary_key;
            obj.data_type = connectedlabel.label.data_type;
            obj.size = connectedlabel.label.size;
            obj.foreign_key = {
              table_name: allSelectedData.exitsTableName,
              column_name: connectedlabel.label.app_column_name
            };
          }
          currFieldsListsArray.push(obj);
        });

        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: { key: 'currFieldsLists', value: currFieldsListsArray }
        });
        setShowMapExitsColumn(null);
        setShowMapColumn(null);
      } else {
        setShowMapExitsColumn(null);
        setShowMapColumn(null);
      }
    } else {
      setConfirmationPopup({ open: true, confirm: true });
    }
  };

  return (
    <Box className="dataSourceSelection">
      <Dialog
        onClose={() => setMultiColDatatypeDialog({ dialog: false, columns: [], datatype: '' })}
        open={multiColDatatypeDialog.dialog}
      >
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Multi Datatype Change</Typography>
          <IconButton
            onClick={() => setMultiColDatatypeDialog({ dialog: false, columns: [], datatype: '' })}
            size="small"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: 400,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Box
              sx={{
                margin: '10px auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Typography>Select Columns:</Typography>
              <Autocomplete
                multiple
                disableCloseOnSelect
                size={'small'}
                onChange={(_, value) => {
                  setMultiColDatatypeDialog((prev) => ({
                    ...prev,
                    datatype: ''
                  }));
                  setMultiColDatatypeDialog((prev) => ({ ...prev, columns: value }));
                }}
                value={multiColDatatypeDialog.columns}
                sx={{ width: '100%' }}
                options={
                  dataSourceListItems.currFieldsLists &&
                  dataSourceListItems.currFieldsLists.length > 0 &&
                  dataSourceListItems.currFieldsLists.map((table) => table)
                }
                getOptionLabel={(table) => table.column_name}
                renderOption={(props, table, { selected }) => (
                  <li {...props}>
                    <Checkbox size="small" style={{ marginRight: 8 }} checked={selected} />

                    {table.column_name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // placeholder={'Select Columns'}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true
                    }}
                  />
                )}
              />
            </Box>

            {dataTypeDropdownList &&
              dataTypeDropdownList.length > 0 &&
              autoComplateInputVal.length > 0 && (
                <Box
                  sx={{
                    margin: '10px auto',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <Typography>Select Datatype:</Typography>
                  <Autocomplete
                    // classes={{
                    //   root: 'columnMppingAuto',
                    //   input: 'columnMppingAutoInput'
                    // }}
                    size={'small'}
                    sx={{
                      width: '100%',
                      '& .MuiAutocomplete-clearIndicator': {
                        display: multiColDatatypeDialog?.datatype === '' ? 'none' : 'inline'
                      }
                    }}
                    options={dataTypeDropdownList}
                    defaultValue="varchar"
                    value={multiColDatatypeDialog?.datatype}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" onChange={(e) => {}} />
                    )}
                    onChange={(e, newvalue) => {
                      setMultiColDatatypeDialog((prev) => ({
                        ...prev,
                        datatype: newvalue?.value ?? ''
                      }));
                      // console.log('newvaluenewvalue 2', newvalue);
                      const prevDataList = dataSourceListItems.currFieldsLists;
                      const _updatedList = prevDataList.map((item) => {
                        const columnExists = multiColDatatypeDialog.columns.some(
                          (col) => col.column_name === item.column_name
                        );

                        if (columnExists) {
                          return { ...item, data_type: newvalue?.value };
                        } else {
                          return item;
                        }
                      });
                      if (_updatedList.length > 0) {
                        console.log(
                          'newvaluenewvalue list',
                          _updatedList,
                          multiColDatatypeDialog.columns
                        );
                        dispatch({
                          type: 'STORE_LISTS_DATA',
                          payload: {
                            key: 'currFieldsLists',
                            value: _updatedList
                          }
                        });
                        // });
                      }
                    }}
                  />
                </Box>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMultiColDatatypeDialog({ dialog: false, columns: [], datatype: '' })}
            variant="contained"
            className="dialogBtn"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Stepper start */}
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {/* Loop into steps lables array */}
        {steps.map((label) => (
          <Step key={label} className="stepRoot">
            <StepLabel
              className=""
              classes={{
                label: 'stepperLabel',
                labelContainer: 'stepperLabelContainer',
                root: 'stepperLabelRoot'
              }}
              StepIconComponent={ColorlibStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box className="dashedBox">
        {/* If first step selected (Source) */}
        {activeStep === 0 && (
          <>
            <Box p={1}>
              <Typography variant="h3">Source type</Typography>
            </Box>
            <Box pl={1} pr={1}>
              <Select
                className="col-2 form-control selectControl"
                variant="standard"
                style={{ width: '100%' }}
                value={allSelectedData.sourceType.id}
                onChange={handleSourceTypeSelect}
              >
                <MenuItem value="%%">All</MenuItem>
                <MenuItem value="Db">DB</MenuItem>
                <MenuItem value="File">File</MenuItem>
                <MenuItem value="Api">API</MenuItem>
              </Select>
            </Box>

            {allSelectedData.sourceType.id === 'File' ? (
              // <>
              //   <RadioGroup
              //     aria-labelledby="demo-radio-buttons-group-label"
              //     defaultValue="newdatafile"
              //     name="radio-buttons-group"
              //     style={{ margin: '7px 5px', flexDirection: 'row' }}
              //   >
              //     <FormControlLabel value="newdatafile" control={<Radio />} label="New File" />
              //     <FormControlLabel value="appendata" control={<Radio />} label="Append" />
              //     <FormControlLabel value="overwritedata" control={<Radio />} label="Overwrite" />
              //   </RadioGroup>
              //   <Box p={1}>
              //     <Typography variant="h3">Select Filetype</Typography>
              //   </Box>
              //   <Box pl={1} pr={1}>
              //     {/* Check if dataSourceListItems.dataSourceList exits and length greater than 0 on Redux store states */}
              //     {/* {dataSourceListItems.dataSourceList &&
              //     dataSourceListItems.dataSourceList.length > 0 ? ( */}
              //     <Box>
              //       <Select
              //         className="col-2 form-control selectControl"
              //         variant="standard"
              //         style={{ width: '100%' }}
              //         value={selectedFileType}
              //         onChange={handleFileTypeChange}
              //       >
              //         <MenuItem value="Select">Select</MenuItem>
              //         <MenuItem value="Excel">Excel</MenuItem>
              //         <MenuItem value="CSV">CSV</MenuItem>
              //         <MenuItem value="TSV">TSV</MenuItem>
              //         <MenuItem value="XML">XML</MenuItem>
              //         <MenuItem value="JSON">JSON</MenuItem>
              //         <MenuItem value="TXT">TXT</MenuItem>
              //       </Select>
              //     </Box>
              //     {/* ) : (
              //       // If dataSourceListItems.dataSourceList not exits or length is not greater than 0 on Redux store states
              //       <Select
              //         className="col-2 form-control selectControl"
              //         variant="standard"
              //         disabled
              //         value={dataSourceListItems.allSelectedData.dataSource.id}
              //         style={{ width: '100%' }}
              //       >
              //         <MenuItem value="%%">Select</MenuItem>
              //       </Select>
              //     )} */}

              //     <Box
              //       display="flex"
              //       flexDirection="column"
              //       justifyContent="center"
              //       style={{
              //         textAlign: 'center',
              //         border: '2px dashed #ddd',
              //         margin: '10px 0',
              //         paddingTop: '5px',
              //         paddingBottom: '5px'
              //       }}
              //     >
              //       <TextField
              //         type="file"
              //         accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/tab-separated-values, text/plain, application/json, application/xml, text/xml"
              //         style={{ display: 'none' }}
              //         id="file-upload"
              //         onChange={handleFileUpload}
              //         // error={Boolean(uploadFileErrorMsg)}
              //         // helperText={uploadFileErrorMsg}
              //       />
              //       <Snackbar
              //         //  sx={{ position: 'absolute', top: '30px', width: '100%' }}
              //         open={openAlert}
              //         onClose={handleCloseAlert}
              //         TransitionComponent={TransitionRight}
              //         autoHideDuration={3000}
              //       >
              //         <MuiAlert
              //           elevation={6}
              //           variant="filled"
              //           onClose={handleCloseAlert}
              //           severity="error"
              //         >
              //           Invalid file type. Please select a valid file.
              //         </MuiAlert>
              //       </Snackbar>

              //       <label
              //         htmlFor="file-upload"
              //         style={{
              //           margin: '10px 0',
              //           display: 'flex',
              //           flexDirection: 'column',
              //           alignItems: 'center'
              //         }}
              //       >
              //         <Typography variant="body1" sx={{ fontSize: '0.82rem!important' }}>
              //           Drag and Drop file or
              //         </Typography>
              //         <CloudUploadIcon
              //           sx={{
              //             width: '30px',
              //             height: '30px',
              //             margin: '5px 0',
              //             color: '#0d4689'
              //           }}
              //         />
              //         <Button
              //           variant="outlined"
              //           component="span"
              //           sx={{
              //             margin: '10px 0',
              //             width: '200px',
              //             height: '40px',
              //             fontSize: '0.825rem!important',
              //             backgroundColor: '#ddd',
              //             borderColor: '#666',
              //             '&:hover': {
              //               backgroundColor: '#ddd' // Change the background color on hover
              //             }
              //           }}
              //         >
              //           Browse File To Upload
              //         </Button>
              //       </label>
              //       <Box style={{ margin: '10px 0' }}>
              //         <Button variant="contained" color="primary">
              //           Confirm Upload
              //         </Button>
              //       </Box>
              //     </Box>
              //   </Box>
              // </>
              <>
                <Box p={1}>
                  <Typography variant="h3">Select File</Typography>
                </Box>
                <Box pl={1} pr={1}>
                  {/* Check if dataSourceListItems.tablesLists exits and length greater than 0 on Redux store states */}
                  {fileUploadedTabels && fileUploadedTabels.length > 0 ? (
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      style={{ width: '100%' }}
                      value={JSON.stringify({
                        file_id: dataSourceListItems.allSelectedData.tables.id,
                        file_name: dataSourceListItems.allSelectedData.tables.name
                      })}
                      onChange={(e) => {
                        // const selectedTablename = e.target.value;
                        // const selectedTableID = e.target.value;
                        const { file_id, file_name } = JSON.parse(e.target.value);
                        dispatch({
                          type: 'STORE_ALL_SELECTED_DATA',
                          payload: {
                            key: 'tables',
                            value: { id: file_id, name: file_name }
                          }
                        });
                        getFieldsNames(file_id, dataSourceListItems.allSelectedData);
                      }}
                    >
                      <MenuItem value="%%" disabled>
                        Select
                      </MenuItem>
                      {/* Loop into fileUploadedTabels */}
                      {fileUploadedTabels.map((dataset, sourceIndex) => {
                        return (
                          <MenuItem
                            key={dataset.datasource_id}
                            value={JSON.stringify({
                              file_id: dataset.datasource_id,
                              file_name: dataset.datasource_name
                            })}
                          >
                            {dataset.datasource_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    // If fileUploadedTabels not exits or length is not greater than 0 on Redux store states
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      disabled
                      value={dataSourceListItems.allSelectedData.tables.name}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value="%%">Select</MenuItem>
                    </Select>
                  )}
                </Box>
              </>
            ) : (
              // : allSelectedData.sourceType.id === 'Api' ? (
              //  //  required later
              // )
              <>
                <Box p={1}>
                  <Typography variant="h3">Select datasource</Typography>
                </Box>
                <Box pl={1} pr={1}>
                  {/* Check if dataSourceListItems.dataSourceList exits and length greater than 0 on Redux store states */}
                  {dataSourceListItems.dataSourceList &&
                  dataSourceListItems.dataSourceList.length > 0 ? (
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      style={{ width: '100%' }}
                      value={dataSourceListItems.allSelectedData.dataSource.id}
                      onChange={(e) => getSchemaList(e)}
                    >
                      <MenuItem value="%%" disabled>
                        Select
                      </MenuItem>
                      {/* Loop into dataSourceListItems.dataSourceList */}
                      {dataSourceListItems.dataSourceList.map((dataSourceItem, sourceIndex) => {
                        return (
                          <MenuItem key={dataSourceItem.id} value={dataSourceItem.datasource_id}>
                            {dataSourceItem.data_set_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    // If dataSourceListItems.dataSourceList not exits or length is not greater than 0 on Redux store states
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      disabled
                      value={dataSourceListItems.allSelectedData.dataSource.id}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value="%%">Select</MenuItem>
                    </Select>
                  )}
                </Box>
                <Box p={1}>
                  <Typography variant="h3">Select schema</Typography>
                </Box>
                <Box pl={1} pr={1}>
                  {/* Check if dataSourceListItems.schemaLists exits and length greater than 0 on Redux store states */}
                  {dataSourceListItems.schemaLists && dataSourceListItems.schemaLists.length > 0 ? (
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      style={{ width: '100%' }}
                      value={dataSourceListItems.allSelectedData.schemas.name}
                      onChange={(e) => getDataSetList(e)}
                    >
                      <MenuItem value="%%" disabled>
                        Select
                      </MenuItem>
                      {/* Loop into dataSourceListItems.schemaLists */}
                      {dataSourceListItems.schemaLists.map((schemaItem, sourceIndex) => {
                        return (
                          <MenuItem key={schemaItem.id} value={schemaItem.schema}>
                            {schemaItem.schema}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    // If dataSourceListItems.dataSourceList not exits or length is not greater than 0 on Redux store states
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      disabled
                      value={dataSourceListItems.allSelectedData.schemas.name}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value="%%">Select</MenuItem>
                    </Select>
                  )}
                </Box>
                <Box p={1}>
                  <Typography variant="h3">Select table</Typography>
                </Box>
                <Box pl={1} pr={1}>
                  {/* Check if dataSourceListItems.tablesLists exits and length greater than 0 on Redux store states */}
                  {dataSourceListItems.tablesLists && dataSourceListItems.tablesLists.length > 0 ? (
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      style={{ width: '100%' }}
                      value={dataSourceListItems.allSelectedData.tables.name}
                      onChange={(e) => {
                        const selectedTablename = e.target.value;
                        dispatch({
                          type: 'STORE_ALL_SELECTED_DATA',
                          payload: { key: 'tables', value: { id: '%%', name: selectedTablename } }
                        });
                        getFieldsNames(selectedTablename, dataSourceListItems.allSelectedData);
                      }}
                    >
                      <MenuItem value="%%" disabled>
                        Select
                      </MenuItem>
                      {/* Loop into dataSourceListItems.tablesLists */}
                      {dataSourceListItems.tablesLists.map((dataset, sourceIndex) => {
                        return (
                          <MenuItem key={dataset.ID} value={dataset.table}>
                            {dataset.table}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    // If dataSourceListItems.tablesLists not exits or length is not greater than 0 on Redux store states
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      disabled
                      value={dataSourceListItems.allSelectedData.tables.name}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value="%%">Select</MenuItem>
                    </Select>
                  )}
                </Box>
              </>
            )}
          </>
        )}
        {/* If second step selected (Map) */}
        {activeStep === 1 && (
          <Box>
            <Box>
              <Box
                sx={{ borderBottom: 1, borderColor: 'divider', fontSize: '9px !important' }}
                p={1}
              >
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button
                    className={
                      newOrExits === 'new'
                        ? 'thisActive submitBtn glbSubmitBtn'
                        : 'notActive submitBtn glbSubmitBtn'
                    }
                    classes={{
                      root: 'globalSubmitButton globalSubmitButtonOk'
                    }}
                    onClick={(e) => {
                      setNewOrExits('new');
                      dispatch({
                        type: 'STORE_ALL_SELECTED_DATA',
                        payload: {
                          key: 'appTableExits',
                          value: false
                        }
                      });
                    }}
                  >
                    Add New maping
                  </Button>
                  <Button
                    className={
                      newOrExits === 'exits'
                        ? 'thisActive submitBtn glbSubmitBtn'
                        : 'notActive submitBtn glbSubmitBtn'
                    }
                    classes={{
                      root: 'globalSubmitButton globalSubmitButtonOk'
                    }}
                    onClick={(e) => {
                      setNewOrExits('exits');
                      dispatch({
                        type: 'STORE_ALL_SELECTED_DATA',
                        payload: {
                          key: 'appTableExits',
                          value: true
                        }
                      });
                    }}
                    disabled={exitsTableList && exitsTableList.length === 0}
                  >
                    Map exitsing dataset
                  </Button>
                </ButtonGroup>
                {newOrExits === 'exits' ? (
                  <Box pb={2} pt={2} pr={1} pl={1} backgroundColor="#dddddd45">
                    <Box p={1}>
                      <Typography variant="h3">Source type 2</Typography>
                    </Box>
                    <Box pl={1} pr={1}>
                      {sourceTypeList && sourceTypeList.length > 0 && (
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo={newOrExits === 'exits' ? false : true}
                          options={sourceTypeList.map((option) => option.lable)}
                          renderInput={(params) => <TextField {...params} variant="standard" />}
                          defaultValue={sourceTypeList[0].lable}
                          onInputChange={(e, newInputValue) => {
                            // console.log('newInputValue', newInputValue);
                            dispatch({
                              type: 'STORE_ALL_SELECTED_DATA',
                              payload: {
                                key: 'curentdataType',
                                value: { id: newInputValue, name: newInputValue }
                              }
                            });
                          }}
                          onChange={(e, newvalue) => {
                            dispatch({
                              type: 'STORE_ALL_SELECTED_DATA',
                              payload: {
                                key: 'curentdataType',
                                value: { id: newvalue, name: newvalue }
                              }
                            });
                            // console.log('exitsTableList', exitsTableList);
                            if (exitsTableList && exitsTableList.length > 0) {
                              // console.log('exitsTableList', exitsTableList);
                              const filteredTables = exitsTableList.filter(
                                (item) => item.dataset_type === newvalue
                              );
                              // console.log('filteredTables', filteredTables);

                              dispatch({
                                type: 'STORE_LISTS_DATA',
                                payload: { key: 'selectedTableList', value: filteredTables }
                              });
                            }
                          }}
                        />
                      )}
                    </Box>
                    <Box p={1}>
                      <Typography variant="h3">Table name</Typography>
                    </Box>
                    <Box pl={1} pr={1}>
                      {selectedTableList && selectedTableList.length > 0 ? (
                        <Select
                          className="col-2 form-control selectControl"
                          variant="standard"
                          style={{ width: '100%' }}
                          value={allSelectedData.exitsTableName}
                          onChange={(e) => {
                            const selectedtable = e.target.value;
                            dispatch({
                              type: 'STORE_ALL_SELECTED_DATA',
                              payload: {
                                key: 'exitsTableName',
                                value: selectedtable
                              }
                            });

                            const getColumns = selectedTableList.filter(
                              (table) => table.table_name === selectedtable
                            );

                            if (getColumns.length > 0) {
                              dispatch({
                                type: 'STORE_LISTS_DATA',
                                payload: {
                                  key: 'exitsColumnListForMap',
                                  value: getColumns[0].columns_schema
                                }
                              });
                            } else {
                              dispatch({
                                type: 'STORE_LISTS_DATA',
                                payload: { key: 'exitsColumnListForMap', value: getColumns }
                              });
                            }
                          }}
                        >
                          <MenuItem disabled value=" ">
                            Select
                          </MenuItem>
                          {selectedTableList &&
                            selectedTableList.length > 0 &&
                            selectedTableList.map((table, tableIndex) => {
                              return (
                                <MenuItem key={tableIndex} value={table.table_name}>
                                  {table.table_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      ) : (
                        // If selectedTableList not exits or length is not greater than 0 on Redux store states
                        <Select
                          className="col-2 form-control selectControl"
                          variant="standard"
                          disabled
                          value={allSelectedData.exitsTableName}
                          style={{ width: '100%' }}
                        >
                          <MenuItem value=" ">Select</MenuItem>
                        </Select>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box
                      mt={1}
                      mb={2}
                      p={1}
                      backgroundColor="#dddddd45"
                      border={1}
                      borderColor="#111"
                      borderRadius={1}
                    >
                      <Box>
                        <Typography variant="h3">Source type (Category)</Typography>
                      </Box>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo={newOrExits === 'exits' ? false : true}
                        options={sourceTypeList.map((option) => option.lable)}
                        renderInput={(params) => {
                          // console.log('paramsparams', params);
                          // console.log('paramsparams', allSelectedData.curentdataType);

                          // if (params.inputProps.value === '') {
                          //   params.inputProps.value = sourceTypeList[0].lable;
                          // }
                          return (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder={
                                allSelectedData.curentdataType
                                  ? allSelectedData.curentdataType.name
                                  : sourceTypeList[0].lable
                              }
                            />
                          );
                        }}
                        // defaultValue={sourceTypeList[0].lable}
                        onInputChange={(e, newInputValue) => {
                          // console.log('newInputValue', newInputValue);
                          clearTimeout(sourceTypeID);
                          sourceTypeID = setTimeout(() => {
                            dispatch({
                              type: 'STORE_ALL_SELECTED_DATA',
                              payload: {
                                key: 'curentdataType',
                                value: { id: newInputValue, name: newInputValue }
                              }
                            });
                          }, 500);
                        }}
                        onChange={(e, newvalue) => {
                          clearTimeout(sourceTypeTimeoutID);
                          sourceTypeTimeoutID = setTimeout(() => {
                            dispatch({
                              type: 'STORE_ALL_SELECTED_DATA',
                              payload: {
                                key: 'curentdataType',
                                value: { id: newvalue, name: newvalue }
                              }
                            });
                          }, 500);
                        }}
                      />
                    </Box>

                    <Box
                      mt={1}
                      mb={2}
                      p={1}
                      backgroundColor="#dddddd45"
                      border={1}
                      borderColor="#111"
                      borderRadius={1}
                    >
                      <Box>
                        <Typography variant="h3">Table name</Typography>
                      </Box>
                      <Box>
                        {/* {sourceTypeList.length && allExistingTableNames.length > 0 && ( */}
                        <CustomTextField
                          setMapBtnCondiion={setMapBtnCondiion}
                          allExistingTableNames={allExistingTableNames}
                          allSelectedData={allSelectedData}
                          dispatch={dispatch}
                          sourceTypeList={sourceTypeList}
                          refreshDataSourceComp={refreshDataSourceComp}
                        />
                        {/* )} */}
                      </Box>
                    </Box>
                  </>
                )}
                <Button
                  disabled={mapBtnCondiion || mapBtnDisabled}
                  className="submitBtn glbSubmitBtn"
                  classes={{
                    root: 'globalSubmitButton globalSubmitButtonOk'
                  }}
                  onClick={(e) => {
                    if (newOrExits === 'new') {
                      setShowMapExitsColumn(true);
                      setShowMapColumn(true);
                      setmapeExitsSource(null);
                    } else {
                      setShowMapExitsColumn(true);
                      setShowMapColumn(null);
                      setmapeExitsSource(true);
                    }
                  }}
                >
                  Map column
                </Button>
              </Box>
            </Box>
            <Dialog
              open={ShowMapExitsColumn}
              onClose={(e) => {
                setShowMapExitsColumn(null);
                setShowMapColumn(null);
              }}
              fullScreen={true}
              maxWidth="xs"
            >
              <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  Column mapping
                  <Button
                    className="submitBtn glbSubmitBtn"
                    classes={{
                      root: 'globalSubmitButton globalSubmitButtonOk'
                    }}
                    onClick={(e) => {
                      setShowMapExitsColumn(null);
                      setShowMapColumn(null);
                    }}
                  >
                    <GrClose color="#fff" style={{ color: '#fff' }} />
                  </Button>
                </Box>
              </DialogTitle>
              <DialogContent>
                {mapeExitsSource && (
                  <Box border={0} borderColor="#ddd" borderRadius={1} className="mappedSource">
                    <div
                      ref={measuredRef}
                      id="drawflow"
                      className="mapDrawFlow"
                      onDragOver={(e) => e.preventDefault()}
                    />
                  </Box>
                )}
                {ShowMapColumn && (
                  <TableContainer component={Paper} className="columnMappingContainer">
                    <Table aria-label="simple table" className="columnMappingTable">
                      <TableHead>
                        <TableRow className="columnMappingRowTh">
                          {/* <TableCell width={30}>S. No</TableCell> */}
                          <TableCell className="tableCells" width={120}>
                            Field
                          </TableCell>
                          <TableCell
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                            className="tableCells"
                          >
                            Data type
                            <Tooltip title="Click to multi-select">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setMultiColDatatypeDialog((prev) => ({ ...prev, dialog: true }))
                                }
                              >
                                <Checklist />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell className="tableCells">Size</TableCell>
                          <TableCell className="tableCells">Primary key</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Check if dataSourceListItems.currFieldsLists exits and length greater than 0, Then loop into currFieldsLists on Redux store states */}
                        {dataSourceListItems.currFieldsLists &&
                          dataSourceListItems.currFieldsLists.length > 0 &&
                          dataSourceListItems.currFieldsLists.map((tables, tableIndex) => {
                            // console.log('tables', tables);
                            return (
                              <TableRow
                                key={tables.column_name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className="columnMappingRowTd"
                              >
                                <TableCell
                                  className="tableCells"
                                  width="90px"
                                  component="td"
                                  scope="row"
                                >
                                  {tables.column_name}
                                </TableCell>
                                <TableCell
                                  className="tableCells"
                                  width="30px"
                                  component="td"
                                  scope="row"
                                >
                                  {/* {JSON.stringify(
                                    dataSourceListItems.currFieldsLists[tableIndex].data_type
                                  )} */}
                                  {dataTypeDropdownList &&
                                    dataTypeDropdownList.length > 0 &&
                                    autoComplateInputVal.length > 0 && (
                                      <Autocomplete
                                        id="free-solo-demo"
                                        classes={{
                                          root: 'columnMppingAuto',
                                          input: 'columnMppingAutoInput'
                                        }}
                                        options={dataTypeDropdownList}
                                        value={
                                          tables.data_type !== '' ? tables.data_type : 'varchar'
                                        }
                                        // defaultValue={
                                        //   tables.data_type !== '' ? tables.data_type : 'varchar'
                                        // }
                                        // inputValue={
                                        //   dataSourceListItems.currFieldsLists[tableIndex].data_type
                                        // }
                                        onInputChange={(e, changedVal) => {
                                          const prevDataList = dataSourceListItems.currFieldsLists;
                                          const selectedOption = dataTypeDropdownList.find(
                                            (option) => option.label === changedVal
                                          );
                                          // console.log('changedVal', selectedOption);
                                          if (selectedOption) {
                                            prevDataList[tableIndex].data_type =
                                              selectedOption.value;
                                            dispatch({
                                              type: 'STORE_LISTS_DATA',
                                              payload: {
                                                key: 'currFieldsLists',
                                                value: prevDataList
                                              }
                                            });
                                          }
                                          // else {
                                          //   prevDataList[tableIndex].data_type = changedVal;
                                          //   dispatch({
                                          //     type: 'STORE_LISTS_DATA',
                                          //     payload: {
                                          //       key: 'currFieldsLists',
                                          //       value: prevDataList
                                          //     }
                                          //   });
                                          // }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            onChange={(e) => {}}
                                          />
                                        )}
                                        onChange={(e, newvalue) => {
                                          // console.log('newvaluenewvalue', newvalue);
                                          console.log('newvaluenewvalue 1', newvalue.value);
                                          const val = newvalue.value;
                                          const prevDataList = dataSourceListItems.currFieldsLists;
                                          // console.log('newvaluenewvalue', val);
                                          prevDataList[tableIndex].data_type = newvalue.value;
                                          console.log('newvaluenewvalue 1', prevDataList);
                                          dispatch({
                                            type: 'STORE_LISTS_DATA',
                                            payload: {
                                              key: 'currFieldsLists',
                                              value: prevDataList
                                            }
                                          });
                                        }}
                                      />
                                    )}
                                </TableCell>
                                <TableCell
                                  className="tableCells"
                                  width="30px"
                                  component="td"
                                  scope="row"
                                >
                                  {/* {JSON.stringify(tables.data_type)} */}
                                  <TextField
                                    variant="standard"
                                    type="number"
                                    disabled={
                                      tables.data_type !== 'char' && tables.data_type !== 'varchar'
                                    }
                                    value={
                                      tables.data_type !== 'char' && tables.data_type !== 'varchar'
                                        ? '0'
                                        : tables.character_maximum_length
                                    }
                                    // placeholder={
                                    //   tables.data_type !== 'char' && tables.data_type !== 'varchar'
                                    //     ? '0'
                                    //     : tables.character_maximum_length
                                    // }
                                    fullWidth
                                    onChange={(e) => {
                                      const selectedMapedField = e.target.value;
                                      const prevDataList = dataSourceListItems.currFieldsLists;
                                      prevDataList[tableIndex].character_maximum_length =
                                        selectedMapedField;
                                      dispatch({
                                        type: 'STORE_LISTS_DATA',
                                        payload: {
                                          key: 'currFieldsLists',
                                          value: prevDataList
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  className="tableCells"
                                  width="30px"
                                  component="td"
                                  scope="row"
                                >
                                  <Radio
                                    id="mapColumn"
                                    // icon={getIcon(CheckBoxOutlineBlank, null, 15, 15)}
                                    // checkedIcon={getIcon(CheckBoxIcon, null, 15, 15)}
                                    checked={tables.primary_key}
                                    style={{ marginRight: 8, fontSize: '20px' }}
                                    className="checkBoxes"
                                    onChange={(e) => {
                                      const selectedMapedField = e.target.checked;
                                      const prevDataList = dataSourceListItems.currFieldsLists;
                                      prevDataList.forEach((item) => (item.primary_key = false));
                                      prevDataList[tableIndex].primary_key = selectedMapedField;
                                      dispatch({
                                        type: 'STORE_LISTS_DATA',
                                        payload: {
                                          key: 'currFieldsLists',
                                          value: prevDataList
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Box>
                  <Dialog
                    // fullScreen={dialogfullScreen}
                    open={confirmationPopup.open}
                  >
                    <DialogTitle sx={{ textAlign: 'center' }}>Confirmation Message</DialogTitle>
                    <DialogContent sx={{ padding: '20px 10px', width: '400px' }}>
                      <Typography>
                        Are you sure you want to continue? Once you submit this step, it is
                        <strong>&nbsp;irreversible</strong>.
                      </Typography>
                      <Typography sx={{ mt: 2 }}>
                        Please check that all the column data types are correct or valid.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setConfirmationPopup({ open: false, confirm: true })}>
                        Back to edit
                      </Button>
                      <Button
                        onClick={() => {
                          handleSubmitColMapping();
                          setConfirmationPopup({ open: false, confirm: true });
                        }}
                      >
                        Yes, I am sure
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Button
                    className="submitBtn glbSubmitBtn"
                    classes={{
                      root: 'globalSubmitButton globalSubmitButtonOk'
                    }}
                    disabled={doneBtnIsDisabled(dataSourceListItems.currFieldsLists)}
                    onClick={handleSubmitColMapping}
                  >
                    Done
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        )}
        {/* If third step selected (Link) */}
        {activeStep === 2 && (
          <Box>
            {exitsTableList && exitsTableList.length > 0 ? (
              <>
                <Box p={1}>
                  <Box
                    border={1}
                    p={1}
                    borderColor="#ddd"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      {dataSourceListItems.addMoreLinkingArray && (
                        <Box>{`${tableConnectionCount} ${
                          tableConnectionCount > 1 ? 'links' : 'link'
                        } created.`}</Box>
                      )}
                    </Box>
                    {/* <Box>
                      <Button
                        className="submitBtn glbSubmitBtn"
                        classes={{
                          root: 'globalSubmitButton globalSubmitButtonOk'
                        }}
                        onClick={(e) => setShowAllLinked(e.currentTarget)}
                      >
                        View all
                      </Button>
                    </Box> */}
                    <Dialog
                      open={showAllLinked}
                      onClose={(e) => {
                        setShowAllLinked(null);
                      }}
                      fullScreen={true}
                      maxWidth="xs"
                    >
                      {/* // onClose={(e) => handleCloseVisualization()} */}
                      <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          All linked dataset
                          {currentSelected && (
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              border={1}
                              borderColor="#ddd"
                              style={{ fontSize: 11 }}
                            >
                              <Box pr={1}>
                                {currentSelected.tables.name}.{currentSelected.curentField.id}
                              </Box>
                              <Box borderLeft={1} borderColor="#ddd" pl={1} pr={1}>
                                {currentSelected.mappedSource.id}.{currentSelected.mappedField.id}
                              </Box>
                              <Box
                                borderLeft={1}
                                borderColor="#ddd"
                                pl={1}
                                pr={1}
                                style={{ cursor: 'pointer' }}
                              >
                                <GrEdit />
                              </Box>
                              <Box
                                borderLeft={1}
                                borderColor="#ddd"
                                pl={1}
                                style={{ cursor: 'pointer' }}
                                onClick={() => alert('sadasd')}
                              >
                                <AiOutlineDelete />
                              </Box>
                            </Box>
                          )}
                          <Button
                            className="submitBtn glbSubmitBtn"
                            classes={{
                              root: 'globalSubmitButton globalSubmitButtonOk'
                            }}
                            onClick={(e) => setShowAllLinked(null)}
                          >
                            <GrClose color="#fff" style={{ color: '#fff' }} />
                          </Button>
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        <CytoscapeComponent
                          className="cytographs counter_"
                          elements={nodesData}
                          style={{ width: '100%', height: '96vh' }}
                          stylesheet={nodeStyle}
                          wheelSensitivity={0.05}
                          zoom={0.3}
                          layout={{ name: 'preset' }}
                          cy={(cy) => setCyToState(cy)}
                        />
                      </DialogContent>
                    </Dialog>
                  </Box>
                  {/* dataSourceListItems.addMoreLinkingArray.length > 0
                // ? dataSourceListItems.addMoreLinkingArray.map((linkedItems, lenkedIndex) => {
                //     console.log('linkedItems', linkedItems);
                //     return (
                //       <Box display="flex" justifyContent="space-between">
                //         <Box>
                //           <Typography>
                //             <Typography className="datasetName">
                //               {linkedItems.tables.name}
                //             </Typography>
                //             {linkedItems.curentField.id}
                //           </Typography>
                //         </Box>
                //         <Box>
                //           <Typography>
                //             <Typography className="datasetName">
                //               {linkedItems.mappedSource.id}
                //             </Typography>
                //             {linkedItems.mappedField.id}
                //           </Typography>
                //         </Box>
                //       </Box>
                //     );
                //   })
                // : ''} */}
                </Box>
                {/*              <Box
                  border={1}
                  mt={1}
                  mb={1}
                  backgroundColor="#dddddd45"
                  borderColor="#111"
                  borderRadius={1}
                >
                  <Box p={1}>
                    <Typography variant="h3">Source Field</Typography>
                  </Box>
                  <Box pl={1} pr={1}>
                    {dataSourceListItems.currFieldsLists &&
                    dataSourceListItems.currFieldsLists.length > 0 ? (
                      <Select
                        className="col-2 form-control selectControl"
                        variant="standard"
                        style={{ width: '100%' }}
                        value={dataSourceListItems.allSelectedData.curentField.id}
                        onChange={(e) => {
                          const selectedCurrField = e.target.value;
                          dispatch({
                            type: 'STORE_ALL_SELECTED_DATA',
                            payload: {
                              key: 'curentField',
                              value: { id: selectedCurrField, name: '%%' }
                            }
                          });
                        }}
                      >
                        <MenuItem value="%%" disabled>
                          Select
                        </MenuItem>
                        {dataSourceListItems.currFieldsLists.map((dataset, sourceIndex) => {
                          console.log('dataset', dataset);
                          return (
                            <MenuItem key={dataset.id} value={dataset.column_name}>
                              {dataset.column_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      // If dataSourceListItems.currFieldsLists not exits or length is not greater than 0 on Redux store states
                      <Select
                        className="col-2 form-control selectControl"
                        variant="standard"
                        disabled
                        value={dataSourceListItems.allSelectedData.curentField.id}
                        style={{ width: '100%' }}
                      >
                        <MenuItem value="%%">Select</MenuItem>
                      </Select>
                    )}
                  </Box>
                </Box>
              <Box
                  border={1}
                  mt={1}
                  mb={1}
                  backgroundColor="#dddddd45"
                  borderColor="#111"
                  borderRadius={1}
                >
                  <Box p={1}>
                    <Typography variant="h3">Application Category</Typography>
                  </Box>
                  <Box pl={1} pr={1}>
                    {sourceTypeList && sourceTypeList.length > 0 && (
                      <Autocomplete
                        id="free-solo-demo"
                        options={getFilteredList(sourceTypeList, exitsTableList).map(
                          (option) => option.lable
                        )}
                        renderInput={(params) => <TextField {...params} variant="standard" />}
                        defaultValue={sourceTypeList[0].lable}
                        onInputChange={(e, newInputValue) => {
                          console.log('newInputValue', newInputValue);
                          dispatch({
                            type: 'STORE_ALL_SELECTED_DATA',
                            payload: {
                              key: 'curentdataTypeMap',
                              value: { id: newInputValue, name: newInputValue }
                            }
                          });
                        }}
                        onChange={(e, newvalue) => {
                          dispatch({
                            type: 'STORE_ALL_SELECTED_DATA',
                            payload: {
                              key: 'curentdataTypeMap',
                              value: { id: newvalue, name: newvalue }
                            }
                          });
                          if (exitsTableList && exitsTableList.length > 0) {
                            const filteredTables = exitsTableList.filter(
                              (item) => item.dataset_type === newvalue
                            );

                            dispatch({
                              type: 'STORE_LISTS_DATA',
                              payload: { key: 'selectedTableListMap', value: filteredTables }
                            });
                          }
                        }}
                      />
                    )}
                  </Box>
                  <Box p={1}>
                    <Typography variant="h3">Application Dataset</Typography>
                  </Box>
                  <Box pl={1} pr={1}>
                    {selectedTableListMap && selectedTableListMap.length > 0 ? (
                      <Select
                        className="col-2 form-control selectControl"
                        variant="standard"
                        style={{ width: '100%' }}
                        value={dataSourceListItems.allSelectedData.mappedSource.id}
                        onChange={(e) => {
                          // selectedTableListMap
                          // getMappedFieldLists(e, dataSourceListItems.allSelectedData)

                          dispatch({
                            type: 'STORE_ALL_SELECTED_DATA',
                            payload: {
                              key: 'mappedSource',
                              value: { id: e.target.value, name: e.target.value }
                            }
                          });

                          const ifvaluesExits = selectedTableListMap.find(
                            (option) => option.table_name === e.target.value
                          );

                          console.log('ifvaluesExits', ifvaluesExits);

                          if (ifvaluesExits) {
                            dispatch({
                              type: 'STORE_LISTS_DATA',
                              payload: {
                                key: 'mapedFieldLists',
                                value: ifvaluesExits.columns_schema
                              }
                            });
                          }
                        }}
                      >
                        <MenuItem value="%%" disabled>
                          Select
                        </MenuItem>
                         {selectedTableListMap.map((dataset, sourceIndex) => {
                          console.log('datasetdataset', dataset);
                          return (
                            <MenuItem key={sourceIndex} value={dataset.table_name}>
                              {dataset.table_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      // If dataSourceListItems.mappedSource not exits or length is not greater than 0 on Redux store states
                      <Select
                        className="col-2 form-control selectControl"
                        variant="standard"
                        disabled
                        value={dataSourceListItems.allSelectedData.mappedSource.id}
                        style={{ width: '100%' }}
                      >
                        <MenuItem value="%%">Select</MenuItem>
                      </Select>
                    )}
                  </Box>
                  <Box p={1}>
                    <Typography variant="h3">Application Dataset Field</Typography>
                  </Box>
                  <Box pl={1} pr={1}>
                     {dataSourceListItems.mapedFieldLists &&
                    dataSourceListItems.mapedFieldLists.length > 0 ? (
                      <Select
                        className="col-2 form-control selectControl"
                        variant="standard"
                        style={{ width: '100%' }}
                        value={dataSourceListItems.allSelectedData.mappedField.id}
                        onChange={(e) => {
                          const selectedMapedField = e.target.value;
                          dispatch({
                            type: 'STORE_ALL_SELECTED_DATA',
                            payload: {
                              key: 'mappedField',
                              value: { id: selectedMapedField, name: '%%' }
                            }
                          });
                        }}
                      >
                        <MenuItem value="%%" disabled>
                          Select
                        </MenuItem>
                        {dataSourceListItems.mapedFieldLists.map((dataset, sourceIndex) => {
                          return (
                            <MenuItem key={dataset.app_column_name} value={dataset.app_column_name}>
                              {dataset.app_column_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      // If dataSourceListItems.mappedField not exits or length is not greater than 0 on Redux store states
                      <Select
                        className="col-2 form-control selectControl"
                        variant="standard"
                        disabled
                        value={dataSourceListItems.allSelectedData.mappedField.id}
                        style={{ width: '100%' }}
                      >
                        <MenuItem value="%%">Select</MenuItem>
                      </Select>
                    )}
                  </Box> 
                </Box> */}
                {/* <Box>
                  <Button
                    className="submitBtn glbSubmitBtn"
                    classes={{
                      root: 'globalSubmitButton globalSubmitButtonOk'
                    }}
                    onClick={(e) => AddMoreLinking()}
                  >
                    Add +
                  </Button>
                  </Box> */}
              </>
            ) : (
              <Box textAlign="center" p={3}>
                No table found for link
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box mt={3} display="flex" justifyContent={activeStep === 0 ? 'flex-end' : 'space-between'}>
        {activeStep > 0 && (
          <Button
            className="submitBtn glbSubmitBtn"
            classes={{
              root: 'globalSubmitButton globalSubmitButtonOk'
            }}
            onClick={(e) => {
              setactiveStep(activeStep - 1);
              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: { key: 'currentActiveStep', value: activeStep - 1 }
              });
            }}
          >
            Back
          </Button>
        )}
        {activeStep < steps.length - 1 ? (
          <Button
            disabled={nextBtnDisabled}
            className="submitBtn glbSubmitBtn"
            classes={{
              root: 'globalSubmitButton globalSubmitButtonOk'
            }}
            // disabled={checkIfConditionMatch(dataSourceListItems.currFieldsLists, activeStep)}
            onClick={(e) => {
              // if (activeStep === 1) {
              //   setShowAllLinked(e.currentTarget);
              // }
              setactiveStep(activeStep + 1);
              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: { key: 'currentActiveStep', value: activeStep + 1 }
              });
              setRefreshPopupComp(new Date());
            }}
          >
            Next
          </Button>
        ) : exitsTableList && exitsTableList.length > 0 && tableConnectionCount < 1 ? (
          <Tooltip placement="top" title="Please make atleast 1 link.">
            <Button
              sx={{ opacity: 0.6 }}
              className="submitBtn glbSubmitBtn"
              classes={{
                root: 'globalSubmitButton globalSubmitButtonOk'
              }}
              // onClick={(e) => {
              //   // finshiedSourceLinkNmap();
              // }}
            >
              Finished
            </Button>
          </Tooltip>
        ) : (
          <Button
            className="submitBtn glbSubmitBtn"
            classes={{
              root: 'globalSubmitButton globalSubmitButtonOk'
            }}
            onClick={(e) => {
              finshiedSourceLinkNmap();
            }}
          >
            Finished
          </Button>
        )}
      </Box>
      {loader && <LoaderBlur />}
    </Box>
  );
}

export default KgDataSourceComp;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  fontSize: '1rem !important',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  })
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <FaCloudUploadAlt />,
    2: <BsMapFill />,
    3: <AiOutlineLink />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function getLeftHolderHtml(dataSourceListItems) {
  // console.log('dataSourceListItems', dataSourceListItems);
  return `<div class="leftHolderBox mainHolderBox">
  <div class="holderTitle">${dataSourceListItems.allSelectedData.tables.name}</div>
  <div class="holderBox">${ReactDOMServer.renderToStaticMarkup(
    getLoopData(dataSourceListItems)
  )}</div>
  </div>`;
}

function getRightHolderHtml(dataForMaspping, tableName) {
  return `<div class="rightHolderBox mainHolderBox">
  <div class="holderTitle">${tableName}</div>
  <div class="holderBox">${ReactDOMServer.renderToStaticMarkup(getLoopData2(dataForMaspping))}</div>
  </div>`;
}

function getLoopColum(data) {
  return ReactDOMServer.renderToStaticMarkup(
    Object.keys(data).map((input) => {
      return <Box className="columnForMapped">{input}</Box>;
    })
  );
}

function getColumnsname(names) {
  return <Box className="columnForMapped">{names}</Box>;
}

function getLoopData(data) {
  return (
    data.currFieldsLists &&
    data.currFieldsLists.length > 0 &&
    data.currFieldsLists.map((tables, tableIndex) => {
      // console.log('tablestables', tables);
      return <Box className="columnForMapped">{tables.column_name}</Box>;
    })
  );
}

function getLoopData2(data) {
  return (
    data &&
    data.length > 0 &&
    data.map((tables, tableIndex) => {
      // console.log('tablestables', tables);
      return <Box className="columnForMapped">{tables.app_column_name}</Box>;
    })
  );
}

function getOutputDots(data) {
  const obj = {};
  if (data.currFieldsLists) {
    if (data.currFieldsLists.length > 0) {
      data.currFieldsLists.forEach((item, index) => {
        obj[`output_${index + 1}`] = { connections: [], label: item };
      });
    }
  }
  return obj;
}

function getInputDots(data) {
  const obj = {};
  if (data) {
    if (data.length > 0) {
      data.forEach((item, index) => {
        obj[`input_${index + 1}`] = { connections: [], label: item };
      });
    }
  }
  return obj;
}

let timeoutID;

function CustomTextField({
  setMapBtnCondiion,
  allExistingTableNames,
  allSelectedData,
  dispatch,
  sourceTypeList,
  refreshDataSourceComp
}) {
  const [filedValue, setFieldValue] = useState('');
  const [txtFieldError, setTxtFieldError] = useState(null);
  // console.log('refreshDataSourceComp', refreshDataSourceComp, allSelectedData.newtableName);
  useEffect(() => {
    setFieldValue(allSelectedData.newtableName);
  }, [refreshDataSourceComp]);
  const onInputChnage = (inputValue, allSelectedData, dispatch, sourceTypeList) => {
    setFieldValue(inputValue);
    // console.log('allSelectedData.exitsTableName', sourceTypeList);
  };
  return (
    <TextField
      variant="standard"
      placeholder="Type table name"
      value={filedValue}
      error={txtFieldError ? true : false}
      helperText={txtFieldError}
      fullWidth
      onChange={(e) => {
        onInputChnage(e.target.value, allSelectedData, dispatch, sourceTypeList);
        const newTableName = e.target.value;

        const pattern = /^[a-zA-Z0-9_ ]+$/;

        clearTimeout(timeoutID);
        timeoutID = setTimeout(() => {
          const searchValue = allExistingTableNames.find((item) => item === newTableName);
          const isValid = pattern.test(newTableName);

          if (isValid) {
            if (searchValue) {
              setTxtFieldError('Table name already exists!');
              setMapBtnCondiion(true);
            } else {
              setTxtFieldError(null);
              setMapBtnCondiion(false);
            }
          } else {
            setTxtFieldError('Your string contains invalid characters.');
            setMapBtnCondiion(true);
          }

          dispatch({
            type: 'STORE_ALL_SELECTED_DATA',
            payload: {
              key: 'newtableName',
              value: newTableName
            }
          });
          dispatch({
            type: 'STORE_LISTS_DATA',
            payload: {
              key: 'refreshDataSourceComp',
              value: new Date()
            }
          });
        }, 500);
      }}
    />
  );
}

function checkIfConditionMatch(dropdownlistData, activeTabs) {
  // console.log('dropdownlistData', dropdownlistData);
  if (activeTabs === 1) {
    const allPrimaryKeysFalse = dropdownlistData.every((obj) => obj.primary_key === false);
    return allPrimaryKeysFalse;
  }
  return false;
}

function getFilteredList(data, exitsTableList) {
  const newdata = [];
  data.forEach((item) => {
    exitsTableList.forEach((table) => {
      if (item.lable === table.dataset_type) {
        newdata.push(item);
      }
    });
  });

  return newdata;
}

function getMaxlength(item) {
  if (
    item.data_type === 'int4' ||
    item.data_type === 'int8' ||
    item.data_type === 'date' ||
    item.data_type === 'bool' ||
    item.data_type === 'text' ||
    item.data_type === 'timestamp without time zone'
  ) {
    return '';
  }
  if (item.character_maximum_length === '') {
    return 350;
  }
  return item.character_maximum_length;
}

function findLengthyObject(arr) {
  let maxLength = 0;
  let lengthyObject = null;

  arr.forEach((obj) => {
    // console.log('lengthyObject', obj);
    const dataObj = obj.ui_config.drawflow.Home.data;
    // console.log('lengthyObject', dataObj);
    const numProperties = Object.keys(dataObj).length;

    if (numProperties > maxLength) {
      maxLength = numProperties;
      lengthyObject = dataObj;
    }
  });
  // console.log('lengthyObject', lengthyObject);
  return lengthyObject;
}

function doneBtnIsDisabled(tablesArray) {
  let isDisabled = true;
  const checkPrimaryKeyArr = tablesArray.filter((item) => {
    return item.primary_key === true;
  });

  if (checkPrimaryKeyArr.length > 0) {
    isDisabled = false;
  }
  return isDisabled;
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}
