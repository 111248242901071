const sqlAutocompleteSuggestions = {
  keywords: [
    'SELECT',
    'FROM',
    'WHERE',
    'GROUP BY',
    'HAVING',
    'ORDER BY',
    'INSERT INTO',
    'VALUES',
    'UPDATE',
    'SET',
    'DELETE',
    'JOIN',
    'INNER JOIN',
    'LEFT JOIN',
    'RIGHT JOIN',
    'FULL JOIN',
    'ON',
    'UNION',
    'DISTINCT',
    'COUNT',
    'SUM',
    'AVG',
    'MIN',
    'MAX',
    'AS',
    'BETWEEN',
    'LIKE',
    'IN',
    'NOT IN',
    'IS NULL',
    'IS NOT NULL',
    'EXISTS',
    'NOT EXISTS',
    'CASE',
    'WHEN',
    'THEN',
    'ELSE',
    'END',
    'LIMIT',
    'OFFSET'
  ],
  functions: [
    'ABS',
    'AVG',
    'CEIL',
    'FLOOR',
    'ROUND',
    'COUNT',
    'SUM',
    'MIN',
    'MAX',
    'LENGTH',
    'UPPER',
    'LOWER',
    'CONCAT',
    'TRIM',
    'SUBSTRING',
    'REPLACE',
    'DATEPART',
    'GETDATE',
    'NOW',
    'DATEADD',
    'DATEDIFF',
    'CAST',
    'CONVERT',
    'COALESCE',
    'NULLIF',
    'ISNULL'
  ]
};

export default sqlAutocompleteSuggestions;
