import { styled, Paper, Tooltip } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function FullScreenTogglePage() {
  const dispatch = useDispatch();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [refreshFullScreen, setRefreshFullScreen] = useState(null);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F11') {
        event.preventDefault(); // Prevent browser's default behavior for F11 key
        if (isFullScreen) {
          exitFullScreen();
        } else {
          enterFullScreen();
          setIsFullScreen(true);
        }
      } else if (event.key === 'Escape') {
        setIsFullScreen(false);
        if (isFullScreen) {
          exitFullScreen();
        }
      }
    };

    const handleFullscreenChange = () => {
      if (document.fullscreenElement === null) {
        // Exiting fullscreen
        setIsFullScreen(false);
      } else {
        // Entering fullscreen
        setIsFullScreen(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullScreen, refreshFullScreen]);

  const enterFullScreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().then(() => {
        // Set focus to the document after entering fullscreen
        document.body.focus();
      });
    }

    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setIsFullScreen(false);
  };

  const toggleFullScreen = () => {
    if (isFullScreen) {
      exitFullScreen();
    } else {
      enterFullScreen();
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'hideSideBar',
          value: ''
        }
      });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'hideSideBarRight',
          value: false
        }
      });
    }
  };

  return (
    <div>
      <Item
        onClick={() => {
          toggleFullScreen();
        }}
        sx={{ marginTop: '-2px' }}
      >
        <Tooltip title={isFullScreen ? 'Exit Fullscreen' : 'Fullscreen'} arrow>
          {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
        </Tooltip>
      </Item>
    </div>
  );
}
