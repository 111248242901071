import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
// State management //

import cytoscape from 'cytoscape';
import avsdf from 'cytoscape-avsdf';
import cise from 'cytoscape-cise';
import cola from 'cytoscape-cola';
import dagre from 'cytoscape-dagre';
import euler from 'cytoscape-euler';
import fcose from 'cytoscape-fcose';
import klay from 'cytoscape-klay';

import graphml from 'cytoscape-graphml';
import jquery from 'jquery';
import { FaLessThanEqual } from 'react-icons/fa';

import {
  AVSDFLayout,
  CircleLayout,
  CiseLayout,
  ColaLayout,
  ConcentricLayout,
  CoseBilkentLayout,
  CoseLayout,
  DagreLayout,
  EulerLayout,
  FcoseLayout,
  GridLayout,
  HierarchyLayout,
  KlayLayout,
  PresetLayout,
  RandomLayout,
  SpreadLayout
} from './GraphLayoutSettings';

const coseBilkent = require('cytoscape-cose-bilkent');
const spread = require('cytoscape-spread');

cytoscape.use(euler);
cytoscape.use(dagre);
cytoscape.use(cola);
cytoscape.use(cise);
cytoscape.use(klay);
cytoscape.use(fcose);
cytoscape.use(coseBilkent);
cytoscape.use(avsdf);
graphml(cytoscape, jquery);

spread(cytoscape);

function CanvasDisplayData(props) {
  const { cyToState, expandedPanel, handleExpanedPanel, CurrentLayoutName } = props;
  const averageCoordinates = calculateCanvasPosition(cyToState);

  // Using React Redux useSelector and useDispatch hook for get and set states values into stores
  // const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  // console.log('knowledgeGraphStoreItem', knowledgeGraphStoreItem);
  // const {
  //   allSelectedData: { refreshComponent, currentActiveStep },
  //   createKnowledgeGraph: { styling }
  // } = knowledgeGraphStoreItem;
  // const [labelToDisplay, setLabelToDisplay] = useState([]);

  const [allCanvasSettings, setAllCanvasSettings] = useState({
    name: CurrentLayoutName
  });
  const [metaDataValue, setMetaDataValue] = useState(null);
  const [allMetaDataSettings, setAllMetaDataSettings] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false); // Close the menu if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNodeDegree = (evt) => {
    if (evt.target.value === 'degree') {
      cyToState.nodes().forEach((item) => {
        const MaxDeg = cyToState.elements().maxDegree();

        const deg = cyToState.$().dc({ root: `#${item.id()}` }).degree;
        const nodeSize = ((deg * 100) / MaxDeg / 1.4).toFixed(0) + '%';
        console.log(item.id() + ' = ' + nodeSize);
        const propertyObj = {};
        propertyObj.width = nodeSize;
        propertyObj.height = nodeSize;
        item.style(propertyObj);
      });
    } else if (evt.target.value === 'closeness') {
      let MaxDeg = 0;
      cyToState.nodes().forEach((item) => {
        if (item.id) {
          if (cyToState.$().cc({ root: `#${item.id()}` }) > MaxDeg) {
            MaxDeg = cyToState.$().cc({ root: `#${item.id()}` });
          }
          const deg = cyToState.$().cc({ root: `#${item.id()}` });
          const nodeSize = ((deg * 100) / MaxDeg / 1.4).toFixed(0) + '%';
          const propertyObj = {};
          propertyObj.width = nodeSize;
          propertyObj.height = nodeSize;
          console.log(item.id() + ' = ' + nodeSize);
          item.style(propertyObj);
        }
      });
    } else if (evt.target.value === 'betweenness') {
      let MaxDeg = 0;
      cyToState.nodes().forEach((item) => {
        if (item.id) {
          const bcValue = cyToState.$().bc().betweenness(`#${item.id()}`);
          if (bcValue > MaxDeg) {
            MaxDeg = bcValue;
          }
          const deg = bcValue;
          const nodeSize = ((deg * 100) / MaxDeg / 1.4).toFixed(0) + '%';
          const propertyObj = {};
          propertyObj.width = nodeSize;
          propertyObj.height = nodeSize;
          console.log(item.id() + ' = ' + nodeSize);
          item.style(propertyObj);
        }
      });
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu
  };

  const handleClose = (event) => {
    event.stopPropagation();
  };

  const changeLayout = (e) => {
    const layoutName = e.target.value;
    if (cyToState) {
      let layoutSettings = { name: layoutName };
      if (layoutName === 'random') {
        layoutSettings = RandomLayout({ fit: true, padding: 25 });
      }
      if (layoutName === 'preset') {
        // layoutSettings = PresetLayout({ fit: true });
      }
      if (layoutName === 'grid') {
        layoutSettings = GridLayout({ fit: true, padding: 25 });
      }
      if (layoutName === 'circle') {
        layoutSettings = CircleLayout({ fit: true, padding: 25 });
      }
      if (layoutName === 'concentric') {
        layoutSettings = ConcentricLayout({ fit: true, padding: 25 });
      }
      if (layoutName === 'breadthfirst') {
        layoutSettings = HierarchyLayout({ fit: true, padding: 30 });
      }
      if (layoutName === 'cose') {
        layoutSettings = CoseLayout({
          fit: false,
          animate: 'end',
          stop: function () {
            cyToState.animate(
              {
                pan: { x: -averageCoordinates.x, y: -averageCoordinates.y },
                zoom: 1
              },
              {
                duration: 1000
              }
            );
          }
        });
      }
      if (layoutName === 'spread') {
        // layoutSettings = SpreadLayout({ fit: true, padding: 20 });
      }
      if (layoutName === 'cise') {
        const arrayOfClusterArrays = [
          [
            'customer-HydroworksLtd',
            'buyer-RadiantPulseInnovations',
            'buyer-ICEpaints',
            'buyer-JaiSports',
            'buyer-NebulaQuantumVentures',
            'buyer-polywires'
          ],
          ['address-PineviewDrive', 'customer-InnovexEnterprises', 'customer-B2ltd'],
          ['customer-8f9dfaa4-7e04-4202-91b7-8f1464e5da70']
        ];

        const clusterColors = [
          '#756D76',
          '#3ac4e1',
          '#ad277e',
          '#4139dd',
          '#d57dba',
          '#8ab23c',
          '#8dcaa4'
        ];

        layoutSettings = CiseLayout({
          fit: false,
          animate: 'end',
          nodeSeparation: 0.1,
          allowNodesInsideCircle: true,
          maxRatioOfNodesInsideCircle: 0.1,
          clusters: arrayOfClusterArrays,
          idealInterClusterEdgeLengthCoefficient: 1.4,
          stop: function () {
            cyToState.animate(
              {
                pan: { x: -averageCoordinates.x, y: -averageCoordinates.y },
                zoom: 1
              },
              {
                duration: 1000
              }
            );
          }
        });
      }
      if (layoutName === 'euler') {
        // layoutSettings = EulerLayout({ fit: true, padding: 25 });
        layoutSettings = EulerLayout({
          springLength: (edge) => 150,
          mass: (node) => 30,
          randomize: true,
          fit: false,
          animate: false,
          stop: function () {
            cyToState.animate(
              {
                pan: { x: -averageCoordinates.x, y: -averageCoordinates.y },
                zoom: 1
              },
              {
                duration: 1000
              }
            );
          }
        });
      }
      if (layoutName === 'dagre') {
        layoutSettings = DagreLayout({ fit: false, padding: 25 });
      }
      if (layoutName === 'cola') {
        layoutSettings = ColaLayout({
          autounselectify: true,
          boxSelectionEnabled: false,
          fit: false,
          stop: function () {
            cyToState.animate(
              {
                pan: { x: -averageCoordinates.x, y: -averageCoordinates.y },
                zoom: 0.7
              },
              {
                duration: 1000
              }
            );
          }
        });
      }
      if (layoutName === 'klay') {
        // layoutSettings = KlayLayout({
        //   fit: true,
        //   nodeDimensionsIncludeLabels: true
        // });
      }
      if (layoutName === 'fcose') {
        // layoutSettings = FcoseLayout({ fit: true, padding: 25 });
      }
      if (layoutName === 'avsdf') {
        // layoutSettings = AVSDFLayout({ fit: true, refresh: 25 });
      }

      if (layoutName === 'cose-bilkent') {
        layoutSettings = CoseBilkentLayout({
          name: 'cose-bilkent',
          animate: false,
          animationEasing: 'ease-out',
          animationDuration: 2000,
          randomize: true,
          nestingFactor: 1,
          fit: false,
          stop: function () {
            cyToState.animate(
              {
                pan: { x: -averageCoordinates.x, y: -averageCoordinates.y },
                zoom: 1
              },
              {
                duration: 1000
              }
            );
          }
        });
      }

      const layout = cyToState.layout(layoutSettings);
      layout.run();
    }
    setAllCanvasSettings({ ...allCanvasSettings, name: e.target.value });
  };

  // const changeLayout = (e) => {
  //   const layoutName = e.target.value;
  //   if (cyToState) {
  //     const layout = cyToState.layout({
  //       name: layoutName
  //     });
  //     layout.run();
  //   }
  //   setAllCanvasSettings({ ...allCanvasSettings, name: e.target.value });
  // };

  return (
    <Box sx={{ pb: 0 }} className="nodeDesign">
      <Accordion
        className="accordT"
        expanded={expandedPanel === 'panel6'}
        onChange={handleExpanedPanel('panel6')}
      >
        <AccordionSummary
          className="accordTrigger"
          aria-controls="panel6d-content"
          id="panel6d-header"
          classes={{ content: 'accordTgrtxt' }}
          expandIcon={<ExpandMore />}
        >
          <Typography variant="h5" className="nodeAccordHead">
            Manage Canvas
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordInfo">
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Layout Type</Typography>
              <FormControl variant="standard" className="col-2">
                <Select
                  className="form-control selectControl"
                  value={allCanvasSettings.name}
                  onChange={(e) => changeLayout(e)}
                  label="nodeProperty"
                >
                  <MenuItem value="random">Random</MenuItem>
                  <MenuItem value="preset">Preset</MenuItem>
                  <MenuItem value="grid">Grid</MenuItem>
                  <MenuItem value="circle">Circle</MenuItem>
                  <MenuItem value="concentric">Concentric</MenuItem>
                  <MenuItem value="breadthfirst">Hierarchy</MenuItem>
                  <MenuItem value="cose">Cose</MenuItem>
                  <MenuItem value="cose-bilkent">Cose Bilkent</MenuItem>
                  <MenuItem value="spread">Spread</MenuItem>
                  <MenuItem value="cise">Cise</MenuItem>
                  <MenuItem value="euler">Euler</MenuItem>
                  <MenuItem value="dagre">Dagre</MenuItem>
                  <MenuItem value="cola">Cola</MenuItem>
                  <MenuItem value="klay">Klay</MenuItem>
                  <MenuItem value="fcose">Fcose</MenuItem>
                  <MenuItem value="avsdf">AVSDF</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="form-row size-col-2">
              <Typography className="col-2 labelHeading">Meta Data</Typography>
              <FormControl variant="standard" className="col-2">
                <Select
                  className="form-control selectControl"
                  value={metaDataValue}
                  onChange={(e) => {
                    setMetaDataValue(e.target.value);
                    handleNodeDegree(e);
                  }}
                  label="nodeProperty"
                >
                  <MenuItem value="degree">Degree</MenuItem>
                  <MenuItem value="closeness">Closeness</MenuItem>
                  <MenuItem value="betweenness">Betweenness</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  function calculateCanvasPosition(cyToState) {
    if (cyToState && cyToState.nodes) {
      const allNodes = cyToState.nodes();

      // Calculate the average position of all nodes
      let totalX = 0;
      let totalY = 0;

      allNodes.forEach((node) => {
        const position = node.position();
        totalX += position.x;
        totalY += position.y;
      });

      const averageX = totalX / allNodes.length;
      const averageY = totalY / allNodes.length;
      console.log('avgPosition', averageX, averageY);

      return { x: averageX, y: averageY };
    }
  }
}

export default CanvasDisplayData;
