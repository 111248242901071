import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Card,
  Box,
  Stack,
  Container,
  CardContent,
  Badge,
  List,
  ListItem,
  generate,
  ListItemIcon,
  ListItemText,
  Demo,
  MailIcon,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
  Grid,
  Item,
  AppBar,
  IconButton,
  MenuIcon,
  SearchIcon,
  Toolbar,
  InputBase,
  Autocomplete,
  Menu,
  MenuItem,
  Checkbox
} from '@mui/material';

import { styled, alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
// components

import { getIcon, KnowledgegraphIcon } from '../../vector';
import RenderIcons from '../global/RenderIcons';
import PageTitle from '../global/PageHeading';
// services
import KnowledgeGraphService from '../../services/KnowledgeGraphService';

import { userDataFromLocal } from '../../utils/getUserDetails';
import { fDate, fDateTime } from '../../utils/formatTime';
import { camlecaseAndUnderScore } from '../../utils/camleCaseAndUScroe';
import FunctionButtons from './KnowledgeGraph/DataGridButtons';
import LeftNavigationBar from './LeftNavigationBar';

const ITEM_HEIGHT = 48;

const { CreateVisualization, ProjectList, SearchNodes, DeleteNodes, GetApiByUrl, DeleteApiByUrl } =
  KnowledgeGraphService;

/**
 *
 * @returns react-element
 */
function TopNavigationBar() {
  // react Router Dom Variable
  const navigate = useNavigate();
  // console.log(useLocation());
  const { enqueueSnackbar } = useSnackbar();
  const chartRef = useRef(null);

  // const [activeList, setActiveList] = useState('');
  const [anchorEl, setAnchorEl] = useState(null); // states for more icon pop over
  const [anchorEl2, setAnchorEl2] = useState(null); // state for other menu item
  const [currentSelection, setCurrentSelection] = useState(null);
  const [currentSelectionName, setCurrentSelectionName] = useState(null);

  const { experimentId, experimentName } = useParams;
  const [searchKnowledgeG, setSearchKnowledgeG] = useState('');
  const [inputText, setInputText] = useState(''); // states for search bar
  const [activeList, setActiveList] = useState(3);
  const [mutateList, setMutateList] = useState(); // for mutate state
  const [showData, setShowData] = useState([]); // states for showing data in card item
  // states using for autocomplete search bar
  const [openSearch, setOpenSearch] = useState(false);
  const [options, setOptions] = useState([]);
  const [openDelete, setOpenDelete] = useState(false); // dialog box for delete
  const [deletableArray, setDeletableArray] = useState([]);
  // console.log('deletableArray', deletableArray);
  const loading = openSearch && options.length === 0;
  // used for moreicon pop over
  const open = Boolean(anchorEl);
  const openOtherMenu = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOtherMenuClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleOtherMenuClose = (event) => {
    setAnchorEl2(null);
  };

  //  For handling open delete popup
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  //  For handling close delete popup
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setAnchorEl(null);
  };
  // create visualization state
  const [openVisualization, setOpenVisualization] = useState(false);
  const [openVisualizationIdentifier, setOpenVisualizationIdentifier] = useState('');

  // handleCloseVisualization functoin
  const openSpecificVisualization = (identifier) => {
    setOpenVisualizationIdentifier(identifier.toLowerCase());
    setOpenVisualization(true);
  };
  // handleCloseVisualization functoin
  const handleCloseVisualization = () => {
    setOpenVisualization(false);
    setOpenVisualizationIdentifier('');
  };
  // autocomplete functionality
  const onChangeHandle = async (value) => {
    const userDetails = JSON.parse(localStorage.getItem('userDeatils'));
    const authToken = userDataFromLocal();
    const response = await SearchNodes(
      { experiment_name: value, crea_user: userDetails.userName },
      authToken.token
    );
    if (response.status === 'success') {
      // setOptions(response.data);
      if (response.data.length > 0) {
        setOptions(response.data);
      }
    }
  };
  // for deleting the card
  const handleDelete = async (e, itemId) => {
    console.log(itemId);
    const authToken = userDataFromLocal();
    const credential = {
      project_ids: itemId,
      creator_user_id: authToken.userID
    };
    const deleteCard = await DeleteApiByUrl('/visual/manage_projects', credential, authToken.token);
    console.log('deleteCard', deleteCard);
    enqueueSnackbar(deleteCard.message, {
      variant: deleteCard.status,
      autoHideDuration: 2000
    });
    if (deleteCard.status === 'success') {
      handleClose();
      handleOtherMenuClose();
      handleCloseDelete();
      setDeletableArray([]);
      setMutateList(new Date());
    }
  };

  const makeDeletable = (e, itemId) => {
    console.log('itemIditemId', itemId, e.target.checked);
    const cuurentCheck = e.target.checked;
    const prevData = deletableArray;
    if (cuurentCheck) {
      prevData.push(itemId);
      setDeletableArray([...prevData]);
    } else {
      const filterData = prevData.filter((ids) => ids !== itemId);
      setDeletableArray([...filterData]);
    }
    // deletableArray
  };

  useEffect(() => {
    (async () => {
      const authToken = userDataFromLocal();
      const params = `?user_id=${authToken.userID}`;
      const projectListData = await ProjectList(params, authToken.token); // api to fire projectlist
      console.log(projectListData, 'checkprojectlistdata');
      if (projectListData.status === 'success') {
        projectListData.data.projects.sort((a, b) => {
          return new Date(b.modified_date) - new Date(a.modified_date);
        });
        setShowData(projectListData.data.projects);
      }
    })();
    // condition for autocomplete search box
    if (!openSearch) {
      setOptions([]);
    }
  }, [openSearch, mutateList]);
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="pageTitleholder KnowledgegraphPge"
      borderBottom={1}
      borderColor="#ccc"
      paddingBottom={2}
    >
      <Box
        display="flex"
        alignItems="center"
        className="globalCardHeadertitleBox"
        sx={{ width: '100%' }}
      >
        <Grid container marginTop={0} spacing={0} id="k-Graph-wrap">
          <Grid item xs={3} style={{ padding: '0', fontSize: '30px', letterSpacing: '1px' }}>
            {/* <PageTitle
              icon={getIcon(KnowledgegraphIcon, '#0d4689', 32, 32)}
              info="Knowledge Graph"
              style={{ margin: '10px', padding: '10px 20px', color: '#0d4689' }}
            /> */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <RenderIcons
                icon={getIcon(KnowledgegraphIcon, '#0d4689', 32, 32)}
                sx={{ marginLeft: '32px' }}
              />
              <Typography
                variant="body2"
                color="primary" // or use any other color name
                sx={{
                  margin: '10px 10px 10px 5px',
                  padding: '10px 20px',
                  fontSize: '22px',
                  fontWeight: '500'
                }}
              >
                Knowledge Graph
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={9} style={{ padding: '0' }}>
            <LeftNavigationBar />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
export default TopNavigationBar;
