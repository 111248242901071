import { useEffect, useState, useReducer, memo, useMemo, useCallback } from 'react';
import {
  Box,
  Card,
  Tooltip,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Container,
  Stack,
  Typography,
  DialogActions,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  DialogTitle,
  FormHelperText,
  Divider
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { HiOutlineUserGroup } from 'react-icons/hi';

import Page from '../Page';
import '../../../global.css';
import PageTitle from '../../global/PageHeading';
import RenderIcons from '../../global/RenderIcons';
import { getIcon, CancelIcon, AddIcon } from '../../../vector/index';
import UserManageAuthService from '../../../services/UserManageAuthService';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import AlertDialog from '../../global/AlertDialog';
import { getGlobals } from '../../../utils/Globals';
import BrudCrumbs from '../../global/BreadCrumbs';
import TopNavigationBar from '../TopNavigationBar';

// const authToken = userDataFromLocal().allTokens?.users?.data?.access;

const {
  ViewUserAuthAPI,
  UsersListlevel,
  AddUserAuthAPI,
  ReportingManagers,
  // EditUserListViewAPI,
  EditUserListAPI
} = UserManageAuthService;
const userAuth = userDataFromLocal().allTokens?.users?.data?.access;

function UsersAdd() {
  const { username } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { GLOBAL_PATH } = getGlobals();
  console.log('GLOBAL_PATH', GLOBAL_PATH);

  const { pathname } = useLocation();
  const path = pathname.split('/');
  const [openLoader, setOpenLoader] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsgServer, setValidationMsgServer] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
    setValidationMsgServer('');
  };

  // Level list array
  const [selectLevel, setSelectLevel] = useState([
    { level: 'L1', value: 1 },
    { level: 'L2', value: 2, list: [{ level: 'L1', value: 1, isDisable: false }] },
    {
      level: 'L3',
      value: 3,
      list: [
        { level: 'L2', value: 2, isDisable: false },
        { level: 'L1', value: 1, isDisable: true, name: '' }
      ]
    },
    {
      level: 'L4',
      value: 4,
      list: [
        { level: 'L3', value: 3, isDisable: false },
        { level: 'L2', value: 2, isDisable: true, name: '' },
        { level: 'L1', value: 1, isDisable: true, name: '' }
      ]
    }
  ]);
  // Level list array

  const initialValue = {
    first_name: '',
    last_name: '',
    email: '',
    user_level: ' ',
    gender: ' ',
    // is_active: ' ',
    // is_admin: ' ',
    user_dob: '',
    contact_number: '',
    reporting_manager_id: ' ',
    username: '',
    password: ''
  };
  const [userAccessValues, DispatchUserAccesValues] = useReducer(reduceUserAccess, initialValue); // UserReducer using for multiple state
  const [selectReportingManager, setSelectReportingManager] = useState([]);
  const [mngrHierarchy, setMngrHierarchy] = useState([]);
  const [Refresh, setRefresh] = useState(null);
  const [mutateList, setMutateList] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [validationMsg, setValidationMsg] = useState(false); // Validation error
  const [dialogMsg, setDialogMsg] = useState(''); // Dialog Message
  console.log('useAccessVales', userAccessValues);
  console.log('selectReportingManager', selectReportingManager);

  useEffect(() => {
    console.log('mngrHierarchy', mngrHierarchy);
  }, [mngrHierarchy]);

  function reduceUserAccess(prevState, nextStateAction) {
    console.log('stateprevState', prevState);
    console.log('statenextStateAction', nextStateAction);
    return {
      ...prevState,
      [nextStateAction.type]: nextStateAction.value
    };
  }
  const closeAlertHandler = (e) => {
    setOpenAlertDialog(false);
    setDialogMsg('');
  };

  const fireUseLevelApi = async (e, edituserInfo, authToken) => {
    console.log('editcaseValue', edituserInfo);

    console.log('editcaseValue', userAccessValues);
    if (!edituserInfo) {
      DispatchUserAccesValues({
        type: 'user_level',
        value: e.target.value
      });
    }
    try {
      const authToken = userDataFromLocal();
      const UsersListlevelApi = await UsersListlevel(
        edituserInfo !== undefined ? edituserInfo.user_level : e.target.value,
        authToken.token
        // edituserInfo !== undefined ? authToken : userAuth
      );
      console.log('UsersListlevelApi', UsersListlevelApi);
      if (UsersListlevelApi.status === 'success') {
        if (edituserInfo !== undefined) {
          console.log('edituserInfo', edituserInfo);
          const managerobject = {};
          const Splitandjoing = edituserInfo.user_level.split('');
          const newNumber = Splitandjoing[1] - 1;
          console.log('Splitandjoing', Splitandjoing);
          Splitandjoing[1] = String(newNumber);
          const newLevelList = Splitandjoing.join('');
          Object.keys(edituserInfo).map((user, index) => {
            if (user.includes(newLevelList)) {
              console.log('useruser', user);
              if (user.includes('id')) {
                managerobject.user_id = edituserInfo[user];
              } else {
                managerobject.username = edituserInfo[user];
              }
            }
          });

          DispatchUserAccesValues({
            type: 'reporting_manager_id',
            value: managerobject.username
          });

          // if l1 !== null {

          console.log('Splitandjoing', managerobject);

          fireMangerAPi('event', managerobject, edituserInfo, authToken);
        } else {
          setSelectLevel([
            { level: 'L1', value: 1 },
            { level: 'L2', value: 2, list: [{ level: 'L1', value: 1, isDisable: false }] },
            {
              level: 'L3',
              value: 3,
              list: [
                { level: 'L2', value: 2, isDisable: false },
                { level: 'L1', value: 1, isDisable: true, name: '' }
              ]
            },
            {
              level: 'L4',
              value: 4,
              list: [
                { level: 'L3', value: 3, isDisable: false },
                { level: 'L2', value: 2, isDisable: true, name: '' },
                { level: 'L1', value: 1, isDisable: true, name: '' }
              ]
            }
          ]);
        }
        setSelectReportingManager(UsersListlevelApi.data);
        setRefresh(new Date());
      } else if (UsersListlevelApi.status === 'failed') {
        // setOpenValidation(false);
        // setValidationMsgServer('');
        setOpenAlertDialog(true);
        setDialogMsg(UsersListlevelApi.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const fireMangerAPi = async (e, managerobject, edituserInfo, authToken) => {
    console.log('managerobject', userAccessValues);
    console.log('managerobject', e, managerobject);
    console.log('managerobject', e, edituserInfo);
    if (managerobject === undefined) {
      console.log('entering length 0');
      DispatchUserAccesValues({
        type: 'reporting_manager_id',
        value: e.target.value
      });
    }
    try {
      const authToken = userDataFromLocal();
      const ReportingManagersAPi = await ReportingManagers(
        managerobject === undefined
          ? e.target.value
          : Object.keys(managerobject).length > 0
          ? managerobject.username
          : edituserInfo.reporting_manager_name_L1,
        authToken.token
      );

      console.log('ReportingManagersAPi', ReportingManagersAPi);
      if (ReportingManagersAPi.status === 'success') {
        console.log('aajsjhs', ReportingManagersAPi.data[0]);
        setMngrHierarchy(ReportingManagersAPi.data);

        const prevSelectLevel = selectLevel;

        const filterArray = prevSelectLevel
          .filter((item) => {
            console.log('prevSelectLevel', item.level === userAccessValues?.user_level);
            console.log('prevSelectLevel', item.level);
            console.log('prevSelectLevel', userAccessValues);
            if (edituserInfo !== undefined) {
              return item.level === edituserInfo.user_level;
            } else {
              return item.level === userAccessValues?.user_level;
            }

            // return item.level === edituserInfo !== undefined
            //   ? edituserInfo.user_level
            //   : userAccessValues?.user_level;
          })[0]
          .list?.filter((item2) => item2.isDisable === true);

        console.log('prevSelectLevel', filterArray);

        filterArray.forEach((item, index) => {
          console.log('parent', item);
          Object.keys(ReportingManagersAPi.data[0]).forEach((key, index) => {
            // console.log('key', key);
            if (key.includes('name')) {
              console.log('parentChild', key);

              if (key.includes(item.level)) {
                item.name = ReportingManagersAPi.data[0][key];
              }
            }
            // if (key === 'reporting_manager_name_l1' || key === 'reporting_manager_name_l2') {
            //   item[index][key] = ReportingManagersAPi.data[key];
            // }
          });
        });
        setSelectLevel([...prevSelectLevel]);
        console.log('prevSelectLevel', filterArray);
      } else if (ReportingManagersAPi.status === 'failed') {
        console.log('error');
        setOpenAlertDialog(true);
        setDialogMsg(ReportingManagersAPi.message);
      }
    } catch (error) {
      console.log('error', error);
    }

    setRefresh(new Date());
  };

  // userAdd API
  const handleAddUser = async () => {
    // Empty field check here before saving
    if (userAccessValues.first_name === '') {
      setValidationMsg(true);
      return;
    } else if (userAccessValues.last_name === '') {
      setValidationMsg(true);
      return;
    }
    // else if (userAccessValues.contact_number === '') {
    //   setValidationMsg(true);
    //   return;
    // }
    else if (userAccessValues.email === '') {
      setValidationMsg(true);
      return;
    } else if (userAccessValues.user_dob === '') {
      setValidationMsg(true);
      return;
    } else if (userAccessValues.gender === ' ') {
      setValidationMsg(true);
      return;
      // } else if (userAccessValues.is_active === ' ') {
      //   setValidationMsg(true);
      //   return;
      // } else if (userAccessValues.is_admin === ' ') {
      //   setValidationMsg(true);
      //   return;
    } else if (userAccessValues.user_level === ' ') {
      setValidationMsg(true);
      return;
    } else if (
      userAccessValues.user_level !== 'L1' &&
      userAccessValues.reporting_manager_id === ' '
    ) {
      setValidationMsg(true);
      return;
    } else if (userAccessValues.username === '') {
      setValidationMsg(true);
      return;
    } else if (userAccessValues.password === '') {
      setValidationMsg(true);
      return;
    }
    try {
      // const authToken = userDataFromLocal().allTokens.users.data.access;
      const credential = {
        username: userAccessValues.username ? userAccessValues.username : '',
        password: userAccessValues.password ? userAccessValues.password : '',
        first_name: userAccessValues.first_name ? userAccessValues.first_name : '',
        last_name: userAccessValues.last_name ? userAccessValues.last_name : '',
        email: userAccessValues.email ? userAccessValues.email : '',
        user_level: userAccessValues.user_level ? userAccessValues.user_level : '',
        contact_number: userAccessValues.contact_number
          ? Number(userAccessValues.contact_number)
          : '',
        user_dob: userAccessValues.user_dob ? userAccessValues.user_dob : '',
        gender: userAccessValues.gender,
        status: 'A',
        crea_user: 'root',
        updt_user: 'root',
        // reporting_manager_id: userAccessValues.reporting_manager_id,
        ...mngrHierarchy[0]
      };
      setOpenLoader(true);
      if (path.includes('edit')) {
        const authToken = userDataFromLocal();
        const isUserListEdit = await EditUserListAPI(credential, authToken.token);
        setOpenLoader(false);
        if (isUserListEdit.status === 'success') {
          enqueueSnackbar(isUserListEdit.message, {
            variant: isUserListEdit.status,
            autoHideDuration: 2000
          });
          navigate(`/usermanagement`);
        } else if (isUserListEdit.status === 'failed') {
          console.log('error');
          setOpenAlertDialog(true);
          setDialogMsg(isUserListEdit.message);
        }
      } else {
        // const isAddEditSaveAPI = path[3] !== undefined ? EditUserListAPI : AddUserAuthAPI;
        const authToken = userDataFromLocal();
        const isAddUserAuth = await AddUserAuthAPI(credential, authToken.token);
        setOpenLoader(false);
        console.log('isAddUserAuth', isAddUserAuth);
        if (isAddUserAuth.status === 'success') {
          enqueueSnackbar(isAddUserAuth.message, {
            variant: isAddUserAuth.status,
            autoHideDuration: 2000
          });
          navigate(`/usermanagement`);
        } else if (isAddUserAuth.status === 'failed') {
          console.log('error');
          setOpenAlertDialog(true);
          setDialogMsg(isAddUserAuth.message);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (path.includes('edit')) {
          const authToken = userDataFromLocal();
          const _getUserData = await ViewUserAuthAPI(username, authToken.token);

          if (_getUserData.status === 'success') {
            Object.keys(_getUserData.data[0]).forEach((key, index) => {
              console.log('editkeykeys', key);
              DispatchUserAccesValues({
                type: key,
                value: Object.values(_getUserData.data[0])[index]
              });
            });
            if (path.includes('edit')) {
              fireUseLevelApi('event', _getUserData.data[0] ?? undefined);
            }
          } else if (_getUserData.status === 'failed') {
            console.log('error');
            setOpenAlertDialog(true);
            setDialogMsg(_getUserData.message);
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    })();
  }, []);

  return (
    <Page title="Users">
      <Container maxWidth="xl" className="umgnoPadding bg-h-100">
        <TopNavigationBar />
        {/* <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsgServer}
          handleClose={handleClose}
        /> */}

        <Box py={3} px={2} mb={4}>
          <AlertDialog
            openAlertDialog={openAlertDialog}
            dialogMsg={dialogMsg}
            closeAlertHandler={closeAlertHandler}
          />
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageTitleholder"
          >
            <PageTitle
              icon={<HiOutlineUserGroup size={20} />}
              info={path.filter((item) => item === 'edit')[0] === 'edit' ? 'Edit User' : 'Add User'}
              className="navTxt"
            />
            <Button variant="outlined" color="primary" onClick={handleAddUser}>
              Save User
            </Button>
          </Stack> */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="umgpageHeading"
          >
            <PageTitle
              info={path.filter((item) => item === 'edit')[0] === 'edit' ? 'Edit User' : 'Add User'}
            />
            {/* <Box>
              <BrudCrumbs />
            </Box> */}
          </Stack>

          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ color: '#004C99' }}>
              Manage Users
            </Typography>
          </Box> */}

          <Typography variant="body1" className="umgheadingLineUp umgheadingLineUpOut">
            General
          </Typography>
          <Box className="umgviewLeadCard umgcardTopMinusMargin" mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  First Name
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  // variant="standard"
                  // className="umggbleinp"
                  // className="umgformUnderline"
                  inputProps={{
                    className: 'umgtextInput'
                  }}
                  placeholder="Type Your First Name"
                  value={userAccessValues.first_name}
                  onChange={(e) =>
                    DispatchUserAccesValues({ type: 'first_name', value: e.target.value })
                  }
                  error={userAccessValues.first_name === '' ? validationMsg : false}
                  helperText={
                    (userAccessValues.first_name === undefined ||
                      userAccessValues.first_name === '') &&
                    validationMsg === true
                      ? 'Please fill first name'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  inputProps={{
                    className: 'umgtextInput'
                  }}
                  placeholder="Type Your Last Name"
                  value={userAccessValues.last_name}
                  onChange={(e) =>
                    DispatchUserAccesValues({ type: 'last_name', value: e.target.value })
                  }
                  error={userAccessValues.last_name === '' ? validationMsg : false}
                  helperText={
                    (userAccessValues.last_name === undefined ||
                      userAccessValues.last_name === '') &&
                    validationMsg === true
                      ? 'Please fill last name'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="body2"
                  className="umgformLabel"
                  // className="formLabel"
                >
                  Contact Number
                </Typography>
                <Typography>
                  <TextField
                    fullWidth
                    type="number"
                    inputProps={{
                      className: 'umgtextInput'
                    }}
                    placeholder="Type Contact Number"
                    value={userAccessValues.contact_number}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        DispatchUserAccesValues({
                          type: 'contact_number',
                          value: e.target.value
                        });
                      }
                    }}
                    // error={userAccessValues.contact_number === '' ? validationMsg : false}
                    // helperText={
                    //   (userAccessValues.contact_number === undefined ||
                    //     userAccessValues.contact_number === '') &&
                    //   validationMsg === true
                    //     ? 'Please fill your contact number'
                    //     : ''
                    // }
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  Email
                </Typography>
                <TextField
                  fullWidth
                  type="email"
                  inputProps={{
                    className: 'umgtextInput'
                  }}
                  placeholder="Type Your Email"
                  value={userAccessValues.email}
                  onChange={(e) =>
                    DispatchUserAccesValues({ type: 'email', value: e.target.value })
                  }
                  error={userAccessValues.email === '' ? validationMsg : false}
                  helperText={
                    (userAccessValues.email === undefined || userAccessValues.email === '') &&
                    validationMsg === true
                      ? 'Please fill your email'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  User DOB
                </Typography>
                <TextField
                  inputProps={{
                    className: 'umgtextInput',
                    max: '3000-12-31'
                  }}
                  fullWidth
                  type="date"
                  placeholder="Select User DOB"
                  onFocus={(e) => (e.currentTarget.type = 'date')}
                  onBlur={(e) => (e.currentTarget.type = 'date')}
                  value={userAccessValues.user_dob}
                  onChange={(e) => {
                    // console.log('date current', e.currentTarget.value);
                    // console.log('date', e.target.value);
                    // console.log('date event', e);
                    // console.log('date event', e.target.validationMessage);
                    DispatchUserAccesValues({ type: 'user_dob', value: e.target.value });
                  }}
                  error={
                    userAccessValues.user_dob === ''
                      ? validationMsg
                      : new Date(userAccessValues.user_dob) > new Date('3000-12-31')
                      ? true
                      : false
                  }
                  helperText={
                    (userAccessValues.user_dob === undefined || userAccessValues.user_dob === '') &&
                    validationMsg === true
                      ? 'Please fill your date of birth '
                      : new Date(userAccessValues.user_dob) > new Date('3000-12-31')
                      ? 'Value exceeds max date. (i.e. 3000-12-31)'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  Gender
                </Typography>
                <Select
                  fullWidth
                  type="text"
                  inputProps={{
                    className: `umgtextInput umgdissabledMenu${userAccessValues.gender}`
                  }}
                  value={userAccessValues.gender}
                  onChange={(e) =>
                    DispatchUserAccesValues({ type: 'gender', value: e.target.value })
                  }
                  error={userAccessValues.gender === ' ' ? validationMsg : false}
                >
                  <MenuItem value=" " disabled>
                    Select Gender
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="others">Others</MenuItem>
                </Select>
                <FormHelperText className="umgselectHelper" error>
                  {userAccessValues.gender === ' ' && validationMsg === true
                    ? 'Please select gender type'
                    : ''}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  Level
                </Typography>
                <Select
                  fullWidth
                  type="text"
                  inputProps={{
                    className: `umgtextInput umgdissabledMenu${userAccessValues.user_level}`
                  }}
                  value={userAccessValues.user_level}
                  onChange={(e) => {
                    fireUseLevelApi(e, undefined, userAuth);
                    // DispatchUserAccesValues({ type: 'user_level', value: e.target.value })
                  }}
                  error={userAccessValues.user_level === ' ' ? validationMsg : false}
                >
                  <MenuItem value=" " disabled>
                    Select Level
                  </MenuItem>
                  {selectLevel.length > 0 &&
                    selectLevel.map((levelList, levelIndex) => {
                      console.log('levelList', levelList);
                      return <MenuItem value={levelList.level}>{levelList.level}</MenuItem>;
                    })}
                </Select>
                <FormHelperText className="umgselectHelper" error>
                  {userAccessValues.user_level === ' ' && validationMsg === true
                    ? 'Please select level type'
                    : ''}
                </FormHelperText>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  Active Status
                </Typography>
                <Select
                  fullWidth
                  type="text"
                  inputProps={{
                    className: `umgtextInput umgdissabledMenu${userAccessValues.is_active}`
                  }}
                  value={userAccessValues.is_active}
                  onChange={(e) =>
                    DispatchUserAccesValues({ type: 'is_active', value: e.target.value })
                  }
                  error={userAccessValues.is_active === ' ' ? validationMsg : false}
                >
                  <MenuItem value=" " disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
                <FormHelperText className="umgselectHelper" error>
                  {userAccessValues.is_active === ' ' && validationMsg === true
                    ? 'Please select active status'
                    : ''}
                </FormHelperText>
              </Grid> */}
              {/* <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  Admin Status
                </Typography>
                <Select
                  fullWidth
                  type="text"
                  inputProps={{
                    className: `umgtextInput umgdissabledMenu${userAccessValues.is_admin}`
                  }}
                  value={userAccessValues.is_admin}
                  onChange={(e) =>
                    DispatchUserAccesValues({ type: 'is_admin', value: e.target.value })
                  }
                  error={userAccessValues.is_admin === ' ' ? validationMsg : false}
                >
                  <MenuItem value=" " disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
                <FormHelperText className="umgselectHelper" error>
                  {userAccessValues.is_admin === ' ' && validationMsg === true
                    ? 'Please select active status'
                    : ''}
                </FormHelperText>
              </Grid> */}
            </Grid>
            {selectReportingManager.length > 0 ? (
              <Box className="umginnerBoxBg" mt={2}>
                <Grid container spacing={2}>
                  {selectLevel.length > 0 &&
                    selectLevel
                      .filter((item) => item.level === userAccessValues.user_level)[0]
                      .list?.map((filteredList, index) => {
                        console.log('filteredList', filteredList);
                        if (index === 0) {
                          return (
                            <Grid item xs={12} sm={6} md={4}>
                              <Typography variant="body2" className="umgformLabel">
                                Reporting Manager ( {filteredList.level})
                              </Typography>
                              <Select
                                fullWidth
                                type="text"
                                inputProps={{
                                  className: `umgtextInput umgdissabledMenu${userAccessValues?.reporting_manager_id}`
                                }}
                                value={userAccessValues?.reporting_manager_id}
                                onChange={(e) => {
                                  fireMangerAPi(e);
                                }}
                                error={
                                  userAccessValues?.reporting_manager_id === ' '
                                    ? validationMsg
                                    : false
                                }
                              >
                                <MenuItem value=" " disabled>
                                  Select Reporting Manager
                                </MenuItem>
                                {selectReportingManager.length > 0 &&
                                  selectReportingManager.map((managerList, managerIndex) => {
                                    console.log('managerList', managerList);
                                    return (
                                      <MenuItem value={managerList.username}>
                                        {managerList.username}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText className="umgselectHelper" error>
                                {userAccessValues?.reporting_manager_id === ' ' &&
                                validationMsg === true
                                  ? 'Please select reporting manager'
                                  : ''}
                              </FormHelperText>
                            </Grid>
                          );
                        } else {
                          return (
                            <Grid item xs={12} sm={6} md={4}>
                              <Typography variant="body2" className="umgformLabel">
                                Reporting Manager ( {filteredList.level})
                              </Typography>
                              <TextField
                                fullWidth
                                type="text"
                                inputProps={{
                                  className: 'umgtextInput',
                                  style: { cursor: 'not-allowed' }
                                }}
                                placeholder="Select Reporting Manager"
                                // disabled={filteredList.isDisable}

                                InputProps={{
                                  readOnly: filteredList.isDisable
                                }}
                                value={
                                  filteredList?.name !== undefined || filteredList?.name !== ''
                                    ? filteredList?.name
                                    : ''
                                }
                                // error={filteredList?.name === '' ? validationMsg : false}
                                // helperText={
                                //   (filteredList?.name === undefined || filteredList?.name === '') &&
                                //   validationMsg === true
                                //     ? 'Please select your reporting manager '
                                //     : ''
                                // }
                              />
                            </Grid>
                          );
                        }
                      })}
                </Grid>
              </Box>
            ) : (
              ''
            )}
          </Box>

          <Typography variant="body1" className="umgheadingLineUp umgheadingLineUpOut">
            User ID Creation
          </Typography>
          <Box className="umgviewLeadCard umgcardTopMinusMargin" mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  User name
                </Typography>
                <TextField
                  fullWidth
                  disabled={path.includes('edit')}
                  type="text"
                  // variant="standard"
                  // className="umggbleinp"
                  placeholder="Type User ID"
                  value={userAccessValues.username}
                  onChange={(e) =>
                    DispatchUserAccesValues({
                      type: 'username',
                      value: e.target.value
                    })
                  }
                  inputProps={{
                    className: 'umgtextInput'
                  }}
                  className="umgformUnderline"
                  error={userAccessValues.username === '' ? validationMsg : false}
                  helperText={
                    (userAccessValues.username === undefined || userAccessValues.username === '') &&
                    validationMsg === true
                      ? 'Please enter user name'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" className="umgformLabel">
                  Password
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <TextField
                    fullWidth
                    // variant="standard"
                    // className="umggbleinp"
                    type="password"
                    autoComplete="new-password"
                    placeholder="Type Password"
                    value={userAccessValues.password}
                    onChange={(e) =>
                      DispatchUserAccesValues({
                        type: 'password',
                        value: e.target.value
                      })
                    }
                    inputProps={{
                      className: 'umgtextInput'
                    }}
                    className="umgformUnderline"
                    error={userAccessValues.password === '' ? validationMsg : false}
                    helperText={
                      (userAccessValues.password === undefined ||
                        userAccessValues.password === '') &&
                      validationMsg === true
                        ? 'Please enter password'
                        : ''
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="umgtextAlignRight">
            <Button className="umgmainBtn" onClick={handleAddUser}>
              Save User
            </Button>
          </Box>
          {/* Loading after done */}
          <Dialog open={openLoader} className="umgloaderWrap">
            <DialogTitle className="umgloader-title">Please wait!</DialogTitle>
            <DialogContent className="umgloader-content">
              <Box className="umgloaderBar">
                <CircularProgress />
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}

export default UsersAdd;
