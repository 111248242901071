const locationHostName = window.location.hostname;

let DomainName = '';
let BankWiseUrl = {};

if (locationHostName.includes('-')) {
  console.log('domainNameChecking', locationHostName);
  DomainName = locationHostName.split('-')[0];
} else {
  console.log('domainNameChecking', locationHostName);
  DomainName = locationHostName.split('.')[0];
}

export const globalUrl = 'https://164.52.215.10:9000';
export const globalUrlAml = 'https://216.48.180.65:9000'; // Automal API path and port
export const globalUrlDS = 'https://91.203.132.251:10000'; // DataSource with 10000 port will be remove if puranjay will confirm
export const globalUrlDF = 'https://216.48.180.65:7000'; // DataFlow with 7000 port
export const gbleUrlCatalog = 'https://216.48.180.65:5000';
export const globalUrlJava = 'https://164.52.208.46:8080'; // Jouney or decision engine API Old
export const gbleUrlPythonJourney = 'https://216.48.180.65:6070'; // Jouney or decision engine API New
// export const globalUrlJava = 'https://164.52.208.46:8080';
export const globalUrlPython = 'https://91.203.132.251:8000';
export const gbleUrlPython = 'https://91.203.132.251:9000'; // ScoreCard New API
// export const globalUrlJava = 'https://158.101.150.130:8080';
// global KnowladgeGraph url
// export const globalKnowladgeUrl = 'https://164.52.208.46:8080/KnowledgeGraphRestServices/'; // old url
// export const globalKnowledgeGraphUrl = 'https://192.168.1.24:2400'; // local machine
// export const globalKnowledgeGraphUrl = 'https://164.52.215.10:2400';
// This GlobalUrl currently we are using in catalog section only
export const globalKonnectRestServices = 'https://164.52.208.46:8080/KonnectRestServices';
// global KnowladgeGraph url
export const globalKnowladgeUrl = 'http://164.52.208.46:8080/KnowledgeGraphRestServices/'; // old url
// export const globalKnowledgeGraphUrl = 'http://192.168.1.24:2400'; // local machine
// export const globalKnowledgeGraphUrl = 'http://91.203.132.251:9998'; // 'http://91.203.132.251:9998';
// export const globalKnowledgeGraphUrl = 'https://xylogic-kg.kalolytic.com'; // 'https://91.203.132.251:9998';
// export const globalKnowledgeGraphUrl = 'http://216.48.180.20:9998'; // 'https://91.203.132.251:9998';
// export const globalKnowledgeGraphUrlSource = 'http://216.48.180.65:10000';

if (
  locationHostName !== 'localhost'
  //  && process.env.REACT_APP_LOCALHOST_URL !== 'development'
) {
  console.log('build of development');
  BankWiseUrl = {
    DomainName: DomainName,
    // globalKnowledgeGraphUrl: 'http://216.48.180.20:9998'
    // globalKnowledgeGraphUrl: 'http://216.48.190.140:9998'
    // globalKnowledgeGraphUrl: `http://${locationHostName}:9998`
    globalKnowledgeGraphUrl: `https://${locationHostName}`
  };
} else {
  console.log('build of localhost');
  BankWiseUrl = {
    DomainName: DomainName,
    // globalKnowledgeGraphUrl: 'http://216.48.190.140:9998'
    // globalKnowledgeGraphUrl: 'http://216.48.180.20:9998'
    globalKnowledgeGraphUrl: 'http://216.48.190.140:8000'
  };
}

export { BankWiseUrl };
