import { useState, useRef } from 'react';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Button
} from '@mui/material';
// import { DateRange } from 'react-date-range';
// import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';
// icons
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
// import add from '@iconify/icons-ic/add';
// import exportIcon from '@iconify/icons-ant-design/export-outline';
// import { RiAlertFill } from 'react-icons/ri';
// import { BiExport, BiSearch } from 'react-icons/bi';
// import { MdAssignmentInd } from 'react-icons/md';
// import { GoIssueReopened } from 'react-icons/go';
//
import { RootStyle, SearchStyle } from '../../global/Styling';
// ---------------------------------------------------------
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { fDate } from '../../../utils/formatTime';
import { getGlobals } from '../../../utils/Globals';
// ----------------------------------------------------------------------

export default function ManageAccessToolbar({
  numSelected,
  filterName,
  onFilterName,
  setReopenPopup,
  setAssignPopup,
  setExportPopup,
  parent,
  manageAccessListApi,
  setOpenDelete,
  selectedUsers
}) {
  const navigate = useNavigate();
  const { GLOBAL_PATH } = getGlobals();
  console.log('GLOBAL_PATH', GLOBAL_PATH);
  const [isOpenFilter, openFilter] = useState(false);
  const [selectChannel, setSelectChannel] = useState(' ');
  const [selectPaymentType, setSelectPaymentType] = useState(' ');
  const [selectRiskType, setSelectRiskType] = useState(' ');
  const [selectLoanStatus, setSelectLoanStatus] = useState(' ');
  const [selectDate, setSelectDate] = useState(' ');
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const [filerValue, setFIlterValue] = useState('');
  return (
    <>
      {isOpenFilter && (
        <Box sx={{ px: 2, pt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                inputProps={{ className: 'textInput' }}
                placeholder="Type First Name"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                inputProps={{ className: 'textInput' }}
                placeholder="Type Last Name"
              />
            </Grid>
            {/* <Grid item xs={4}>
              <Select
                fullWidth
                inputProps={{
                  className: `textInput customSelectBox dissabledMenu${selectRiskType}`
                }}
                value={selectRiskType}
                onChange={(e) => setSelectRiskType(e.target.value)}
              >
                <MenuItem value=" " disabled>
                  Select Risk Type
                </MenuItem>
                <MenuItem value="New Loan">New Loan</MenuItem>
                <MenuItem value="Refinance">Refinance</MenuItem>
                <MenuItem value="LAP">LAP</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </Select>
            </Grid>
            <Grid item xs sx={{ position: 'relative' }}>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
                {filterDatesRange[0].startDate === null && filterDatesRange[0].endDate === null ? (
                  <Typography className="dissabledMenu">Select Period</Typography>
                ) : (
                  ''
                )}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid> */}
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45,
          borderBottom: '1px solid #eaedf1'
        }}
      >
        <Box>
          {/* <Tooltip title="Add New Users" placement="top" arrow>
            <IconButton
              className="squareIconButton"
              onClick={(e) => navigate(`${GLOBAL_PATH}/users/add`)}
            >
              <Icon icon={add} width={18} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ p: 1 }}>
            {selectedUsers.length > 1 && (
              <Button
                size="small"
                variant="contained"
                onClick={() => setOpenDelete(true)}
                color="error"
              >
                Delete All
              </Button>
            )}
          </Box>
          <SearchStyle
            value={filterName}
            onChange={(event) => {
              console.log('searchEvent', event.target.value);
              setFIlterValue(event.target.value);
              manageAccessListApi(event.target.value);
            }}
            placeholder="Search User"
            classes={{
              root: 'searchHolder',
              input: 'searchInput'
            }}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          {/* <Tooltip title="Filter list">
            <IconButton
              className="ml-1"
              onClick={(e) => {
                if (isOpenFilter) {
                  openFilter(false);
                } else {
                  openFilter(true);
                }
              }}
            >
              <Icon icon={roundFilterList} />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Reset Filter">
            <IconButton className="">
              <FilterListOffIcon />
            </IconButton>
          </Tooltip> */}
        </Box>
      </RootStyle>
    </>
  );
}
