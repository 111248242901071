import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';

export default function KnowledgeGraphListDialog(props) {
  const {
    deleteModelData,
    deleteModelColumn,
    currentSelection,
    currentSelectionName,
    confirmDeletePopUp,
    setConfirmDeletePopUp,
    deletableArray,
    handleDelete,
    deleteProjectBtnLoader,
    setDeleteProjectBtnLoader,
    setDeleteModelData,
    selectedCard
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { DeleteApiByUrl } = KnowledgeGraphService;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showModelPop, setShowModelPop] = useState(false);
  const [showConfirmMsg, setShowConfirmMsg] = useState('');
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);

  let deleteProjectTimeoutVar;
  const handleConfirmDeletePopUp = () => {
    setConfirmDeletePopUp(true);
  };

  const handleSelectionModelChange = (newSelection) => {
    const newProjectIdsSet = new Set();
    const newModelIds = [];

    newSelection.forEach((selectedId) => {
      const selectedRow = deleteModelData.data.find((row) => row.id === selectedId);
      if (selectedRow) {
        // Add the project ID to the set
        newProjectIdsSet.add(selectedRow.project_id);
        newModelIds.push(selectedRow.link_id);
      }
    });

    const newProjectIds = Array.from(newProjectIdsSet);

    setSelectedProjectIds(newProjectIds);
    setSelectedModelIds(newModelIds);
    setSelectionModel(newSelection);
  };

  const deleteModelModels = async () => {
    if (selectionModel.length > 0) {
      setButtonDisabled(false);
    }

    try {
      const modelCredential = {
        project_ids: selectedProjectIds,
        model_ids: selectedModelIds
      };

      console.log(modelCredential, 'projectsandmodels');

      const deletingModel = await DeleteApiByUrl(
        '/visual/neo4j/get_project_link_model',
        modelCredential
      );

      if (deletingModel?.status === 'success' && deletingModel?.code === 200) {
        // Update state and reset selectedProjectIds and selectedModelIds
        const updatedData = deleteModelData.data.filter(
          (item) =>
            !selectedProjectIds.includes(item.project_id) ||
            !selectedModelIds.includes(item.link_id)
        );

        setDeleteModelData({
          show: true,
          data: updatedData
        });
        setSelectedProjectIds([]);
        setSelectedModelIds([]);
        setSelectionModel([]);

        setShowModelPop(false);
      } else if (deletingModel.code === 403) {
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: deletingModel.message }
          }
        });
      } else {
        console.error('Failed to delete models:', deletingModel);
      }
    } catch (error) {
      console.error('Error deleting models:', error);
    }
  };

  useEffect(() => {
    if (selectionModel.length === 0) {
      setButtonDisabled(true);
    } else {
      setShowConfirmMsg(
        <p>
          Are you sure you want to delete
          {selectionModel.length === 1 ? (
            <>
              {''} the <strong>selected Model</strong> ?
            </>
          ) : (
            <>
              {''} all <strong>{selectionModel.length} selected Models</strong> ?
            </>
          )}
        </p>
      );
      setButtonDisabled(false);
    }
  }, [selectionModel]);

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <DialogContent>
          <Typography sx={{ textTransform: 'uppercase', fontWeight: '900', fontSize: '1.3rem' }}>
            Model List
          </Typography>
        </DialogContent>
      </Box>

      <Box m={2}>
        {deleteModelData.data.length > 0 ? (
          <Box style={{ height: 400 }}>
            <DataGrid
              checkboxSelection
              rows={deleteModelData.data}
              columns={deleteModelColumn}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) => handleSelectionModelChange(newSelection)}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 4
            }}
          >
            <Typography textTransform={'uppercase'} variant="subtitle2">
              No model found in this project:
            </Typography>
            <Button
              onClick={(e) => {
                navigate(`/knowledgegraph/edit/${selectedCard.itemId}/${selectedCard.name}`);
              }}
              variant="contained"
              color="info"
            >
              Create model
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            borderTop: '1px solid #ccc',
            justifyContent: 'flex-end',
            p: 0,
            m: 0,
            mt: 2,
            pb: 2,
            py: 1
          }}
        >
          <DialogActions>
            <Box>
              <Button
                variant="outlined"
                color="error"
                disabled={buttonDisabled}
                onClick={() => setShowModelPop(true)}
              >
                Delete Selected Models
              </Button>
            </Box>
            <Box>
              <Button
                className="globalSubmitButton"
                classes={{
                  root: 'globalSubmitButton globalSubmitButtonDelete autoButton',
                  label: 'globalSubmitButtonLabel globalSubmitButtonDeleteLabel'
                }}
                variant="contained"
                color="secondary"
                onClick={handleConfirmDeletePopUp}
              >
                Delete Project
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>

      <Dialog open={confirmDeletePopUp} onClose={handleConfirmDeletePopUp}>
        <DialogContent>
          <Typography variant="body1" sx={{ padding: '1rem', lineHeight: '1.25rem' }}>
            By choosing this option, all models created under this Project will be permanently
            deleted.
          </Typography>
          <Typography variant="body1" sx={{ padding: '0rem 1rem', lineHeight: '1.25rem' }}>
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmDeletePopUp(false)}>
            No
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={deleteProjectBtnLoader ? { opacity: 0.6, pointerEvents: 'none' } : {}}
            startIcon={
              deleteProjectBtnLoader ? (
                <CircularProgress size={20} sx={{ color: 'black' }} />
              ) : (
                <Delete />
              )
            }
            onClick={(e) => {
              setDeleteProjectBtnLoader(true);
              clearTimeout(deleteProjectTimeoutVar);
              deleteProjectTimeoutVar = setTimeout(() => {
                handleDelete(e, deletableArray);
              }, 400);
            }}
          >
            Yes, Sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showModelPop} onClose={() => setShowModelPop(false)}>
        <DialogContent>
          <Typography variant="body1" sx={{ padding: '1rem', lineHeight: '1.25rem' }}>
            {showConfirmMsg}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModelPop(false)}>No</Button>
          <Button
            onClick={() => {
              deleteModelModels();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
    // </Dialog>
  );
}
