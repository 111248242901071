import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
// import { DataGrid } from '@mui/x-data-grid';
// material
import {
  Container,
  Box,
  Typography,
  Card,
  Button,
  Stack,
  Switch,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { CheckCircleOutlined, Close } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { HiEye, HiOutlineBookOpen, HiViewList } from 'react-icons/hi';
import { RiDeleteBin6Line, RiDeleteBinLine } from 'react-icons/ri';
import { AiFillEdit } from 'react-icons/ai';
import { IoMdUnlock } from 'react-icons/io';
import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';

// styling
import Scrollbar from '../../Scrollbar';

// components
import Page from '../Page';
import PageTitle from '../../global/PageHeading';
import { getIcon, AddIcon, EditIcon, DeleteIcon } from '../../../vector/index';

import RenderIcons from '../../global/RenderIcons';
import UserManageAuthService from '../../../services/UserManageAuthService';
import { getGlobals } from '../../../utils/Globals';
import BrudCrumbs from '../../global/BreadCrumbs';
import ManageAccessToolbar from './ManageAccessToolbar';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import TopNavigationBar from '../TopNavigationBar';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';

// const authToken = userDataFromLocal().allTokens?.manage_access?.data?.access;

const { UsersListAuthAPI, DeleteUserListAPI } = UserManageAuthService;

const useStyles = makeStyles({
  root: {
    border: '0',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid rgb(241, 243, 244)',
      '&:nth-child(even)': {
        backgroundColor: 'transparent' // Set your desired background color for even rows
      },
      '&:nth-child(odd)': {
        backgroundColor: 'rgba(145, 158, 171, 0.08)' // Set the background color for odd rows (if needed)
      }
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid rgb(241, 243, 244)',
      fontSize: '12px'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: 'rgb(13, 70, 137)',
      backgroundColor: '#fff',
      fontWeight: 500,
      fontSize: '12px',
      textTransform: 'uppercase'
    },
    '& .MuiDataGrid-columnSeparator ': {
      display: 'none'
    }
  }
});
function ManageAccess() {
  const classes = useStyles();
  // Navigate const
  const navigate = useNavigate();
  const { GLOBAL_PATH } = getGlobals();
  console.log('GLOBAL_PATH', GLOBAL_PATH);
  const [openLoader, setOpenLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [currentHeight, setCurrentHeight] = useState('70vh');
  const [rowId, setRowId] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log('selectedUsers', selectedUsers);
  const [openDelete, setOpenDelete] = useState(false);
  const [mutateList, setMutateList] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');

  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  // Action part start
  const getActionMore = (params) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
      <Tooltip title="Delete" arrow placement="top">
        <StyledIconButton
          variant="outlined"
          size="small"
          classes={{
            root: 'umgsquareIconButton umgactionButton'
          }}
          onClick={(e) => {
            if (
              userDataFromLocal().modules.some((module) => module.module_name === 'User Access')
            ) {
              if (
                userDataFromLocal().modules.find((module) => module.module_name === 'User Access')
                  .permissions.delete
              ) {
                setOpenDelete(true);
                setSelectedUsers([params.row.username]);
              } else {
                setValidationMsg('User does not have permission to delete.');
                setOpenValidation(true);
              }
            }
          }}
        >
          <RiDeleteBinLine size={14} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Edit/View" arrow placement="top">
        <StyledIconButton
          variant="outlined"
          size="small"
          classes={{
            root: 'umgsquareIconButton umgactionButton'
          }}
          onClick={(e) => {
            if (
              userDataFromLocal().modules.some((module) => module.module_name === 'User Access')
            ) {
              if (
                userDataFromLocal().modules.find((module) => module.module_name === 'User Access')
                  .permissions.edit
              ) {
                // navigate(`${GLOBAL_PATH}/manage-access/edit/${userDataFromLocal()?.userID}`);
                navigate(`${GLOBAL_PATH}/manage-access/edit/${params.row.user_id}`);
              } else {
                setValidationMsg('User does not have permission to edit.');
                setOpenValidation(true);
              }
            }
          }}
        >
          <AiFillEdit size={14} />
        </StyledIconButton>
      </Tooltip>
    </Box>
  );

  /**
   * For showing status
   */
  // const getStatus = (params) => (
  //   <Box width="100%" textAlign="center">
  //     <Tooltip
  //       title={
  //         params.value === 'Pending'
  //           ? 'Pending'
  //           : params.value === 'In Progress'
  //           ? 'In Progress'
  //           : params.value === 'Active'
  //           ? 'Active'
  //           : params.value === 'Completed'
  //           ? 'Completed'
  //           : params.value === 'Error'
  //           ? 'Error'
  //           : 'Inactive'
  //       }
  //       arrow
  //       placement="top"
  //     >
  //       <IconButton>
  //         {params.value === 'Pending' ? (
  //           <RenderIcons icon={getIcon(PendingIcon, null, 25, '#778899')} color="#778899" />
  //         ) : params.value === 'In Progress' ? (
  //           <CircularProgress size={10} />
  //         ) : params.value === 'Active' ? (
  //           <RenderIcons icon={getIcon(ActiveIcon, '#e5d30c', 19, 19)} color="#e5d30c" />
  //         ) : params.value === 'Error' ? (
  //           <RenderIcons icon={getIcon(PendingIcon, '#FF0000', 25, 25)} color="#FF0000" />
  //         ) : params.value === 'Completed' ? (
  //           <RenderIcons icon={getIcon(CompletedIcon, '#008000', 25, 25)} color="#008000" />
  //         ) : (
  //           <RenderIcons icon={getIcon(ActiveIcon, '#ddd', 19, 19)} color="#ddd" />
  //         )}
  //       </IconButton>
  //     </Tooltip>
  //   </Box>
  // );

  // Datagrid columns
  const columns = [
    { field: 'id', headerName: 'User ID', width: 100, hide: true },
    { field: 'username', headerName: 'User Name', width: 280 },
    { field: 'first_name', headerName: 'First Name', width: 280 },
    { field: 'last_name', headerName: 'Last Name	', width: 280 },
    { field: 'email', headerName: 'Email', width: 280 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      // headerAlign: 'center',
      renderCell: getActionMore
    }
  ];

  // Datagrid rows
  const [rowsArchive, setRowsArchive] = useState([]);
  const [rows, setRows] = useState([]);

  /**
   * For handling delete function
   */
  const handleDelete = async (e) => {
    // const prevArray = [...rows];
    // prevArray.splice(rowId.row.id, 1);
    // const flterItm = prevArray.filter((user) => user.id !== rowId.row.id);
    // setRows(flterItm);
    // setOpenDelete(false);
    // above given static delete
    try {
      // const authToken = userDataFromLocal()?.allTokens['manage-access'].data?.access;
      setOpenLoader(true);
      const authToken = userDataFromLocal();
      const payload = { usernames: selectedUsers, user_id: authToken.userID };

      const isDeleteUserList = await DeleteUserListAPI(payload, authToken.token);
      setOpenDelete(false);
      setOpenLoader(false);
      if (isDeleteUserList.status === 'success') {
        // enqueueSnackbar(isDeleteUserList.message, {
        //   variant: isDeleteUserList.status,
        //   autoHideDuration: 2000
        // });
        // setOpenAlertDialog(true);
        // setDialogMsg(isDeleteUserList.message);
        setMutateList(new Date());
      } else {
        setOpenAlertDialog(true);
        setDialogMsg(isDeleteUserList.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const manageAccessListApi = async (userName) => {
    // manageList API is also using for manage list API
    try {
      // const isUserList = await UsersListAuthAPI(username, authToken); // manageList API is also using for manage list API
      // // setOpenLoader(false);
      // if (isUserList.status === 'success') {
      //   console.log('isUserList', isUserList.data);
      //   const fltrArray = isUserList.data.filter((data) => (data.id = data?.user_id)); // Id is mandatory so define from here
      //   // const fltrArray = isUserList.data.filter((data) => (data.id = data?.user_id)); // Id is mandatory so define from here
      //   console.log('fltrArray', fltrArray);
      //   setRows(fltrArray);
      // } else if (isUserList.status === 'error') {
      //   console.log('error');
      //   setOpenAlertDialog(true);
      //   setDialogMsg(isUserList.message);
      // }
      // const isUserList = await UsersListAuthAPI(authToken);
      if (userName !== '' && userName !== undefined) {
        const lowerCaseValue = userName.toLowerCase();
        const exactMatches = [];
        const partialMatches = [];

        rowsArchive.map((item) => {
          const _username = String(item.username).toLowerCase();
          const _firstname = String(item.first_name).toLowerCase();
          const _lastname = String(item.last_name).toLowerCase();
          const _email = String(item.email_id).toLowerCase();

          if (
            _username === lowerCaseValue ||
            _firstname === lowerCaseValue ||
            _lastname === lowerCaseValue ||
            _email === lowerCaseValue
          ) {
            exactMatches.unshift(item);
          } else if (
            _username.includes(lowerCaseValue) ||
            _firstname.includes(lowerCaseValue) ||
            _lastname.includes(lowerCaseValue) ||
            _email.includes(lowerCaseValue)
          ) {
            partialMatches.push(item);
          }
        });

        setRows([...exactMatches, ...partialMatches]);
      } else {
        const authToken = userDataFromLocal();
        const _userID = userDataFromLocal()?.userID;
        const isUserList = await UsersListAuthAPI(userName, _userID, authToken.token);
        // setOpenLoader(false);
        if (isUserList.status === 'success') {
          console.log('isUserList', isUserList.data);
          const fltrArray = isUserList.data.filter((data) => (data.id = data?.user_id)); // Id is mandatory so define from here
          setRows(fltrArray);
          setRowsArchive(fltrArray);
        } else if (isUserList.status === 'error') {
          setOpenValidation(true);
          setValidationMsg(isUserList.message);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    console.log('manageaccess');
    // manageList API is also using for manage list API
    manageAccessListApi('');
  }, [mutateList]);

  return (
    <Page title="Manage Access">
      <Container maxWidth="xl" className="umgnoPadding bg-h-100">
        <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
        />

        <TopNavigationBar />
        <Box pt={3} pb={2} px={2} mb={4}>
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="umgpageTitleholder"
          >
            <PageTitle icon={<IoMdUnlock size={20} />} info="Manage Access" className="umgnavTxt" />
            <Tooltip title="Add new" arrow placement="top">
              <Button
                className="umgnavLink"
                variant="outlined"
                size="small"
                to="#"
                startIcon={getIcon(AddIcon, 16, 16)}
                onClick={(e) => navigate('/manage-access/add')}
              >
                Add New
              </Button>
            </Tooltip>
          </Stack> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.8rem' }}>
            <Typography variant="h6" sx={{ color: '#004C99', p: 1 }}>
              Manage Access
            </Typography>
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '10px'
              }}
            >
              <Button
                color="error"
                variant="contained"
                startIcon={<Delete />}
                sx={
                  confirmUserDeletePopUp.openedFromCheckbox &&
                  confirmUserDeletePopUp.user_id.length > 0
                    ? {
                        padding: '4px 16px'
                      }
                    : { padding: '4px 16px', pointerEvents: 'none', opacity: 0.5 }
                }
                onClick={() =>
                  setConfirmUserDeletePopUp((prev) => ({
                    ...prev,
                    openDialog: true
                  }))
                }
              >
                Delete Selected Users
              </Button>

              <Button
                sx={{
                  backgroundColor: '#0d4689',
                  padding: '4px 16px',
                  color: 'white',

                  '&:hover': { backgroundColor: '#0056b3' }
                }}
                onClick={() => {
                  navigate(`/usermanagement/add-user`);
                }}
              >
                <AddCircleOutline sx={{ marginRight: '7px' }} />
                Add Users
              </Button>
            </Box> */}
          </Box>
          <Card sx={{ padding: '10px' }}>
            <ManageAccessToolbar
              selectedUsers={selectedUsers}
              setOpenDelete={setOpenDelete}
              manageAccessListApi={manageAccessListApi}
            />
            <Scrollbar>
              <Box
                className="umgdataGridBox umgglobalDatGridHolder"
                style={{ height: currentHeight, width: '100%' }}
              >
                <DataGrid
                  headerHeight={40}
                  rowHeight={40}
                  checkboxSelection
                  onSelectionModelChange={(newSelection) => {
                    const selectedIds = [];
                    rows.forEach((item) => {
                      if (newSelection.includes(item.id)) {
                        selectedIds.push(item?.username);
                      }
                    });
                    setSelectedUsers(selectedIds);
                  }}
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  hideFooter
                  classes={{
                    root: classes.root
                  }}
                />
              </Box>
            </Scrollbar>
            {/* Open delete dialog  */}
            <Dialog
              open={openDelete}
              onClose={(e) => {
                setOpenDelete(false);
              }}
              fullWidth
            >
              <DialogTitle className="umgpopupTitle">
                <Typography variant="subtitle1" className="umgpopupSubTitle">
                  Delete User List
                </Typography>
                <IconButton onClick={(e) => setOpenDelete(false)} size="small">
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers className="umgpopupInfo">
                <Typography variant="subtitle1" className="umgcolorBlue" mt={4} mb={4}>
                  Are you sure you want to delete?
                </Typography>
              </DialogContent>
              <DialogActions disableSpacing={false} className="umgpopupAction">
                <Button
                  onClick={(e) => setOpenDelete(false)}
                  variant="outlined"
                  color="primary"
                  className="umgmainBtn"
                >
                  Close
                </Button>
                <Button
                  className="umgmainBtn umgredBtn"
                  onClick={(e) => {
                    handleDelete(e);
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            {/* Loading after done */}
            <Dialog open={openLoader} className="umgloaderWrap">
              <DialogTitle className="umgloader-title">"Please wait"</DialogTitle>
              <DialogContent className="umgloader-content">
                <Box className="umgloaderBar">
                  <CircularProgress />
                </Box>
              </DialogContent>
            </Dialog>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default ManageAccess;
