import useApiServices from './useApiServices';
import { BankWiseUrl } from './globalApi';

const { globalKnowledgeGraphUrl } = BankWiseUrl;
//
/**
 * @module dataSourceService
 * @returns  it return All DataSource Apis
 */

const dataSourceService = {
  Diallinputpram: (authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/visual/diallinputpram`, authToken),
  Datasourcelist: (path, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/fetch_datasource_lists${path}`,
      authToken
    ),
  Savedbconfig: (credential, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/visual/db_test`, credential, authToken),
  SavedbconfigFormData: (file, authToken) =>
    useApiServices().postFormDataApi(`${globalKnowledgeGraphUrl}/visual/db_test`, file, authToken),
  Savedatasource: (credential, authToken) =>
    useApiServices().postFormDataApi(
      // `${globalKnowledgeGraphUrl}/savedatasource`,
      `${globalKnowledgeGraphUrl}/visual/manage_datasources`,
      credential,
      authToken
    ),
  ExcelSheetName: (file, authToken) =>
    useApiServices().postFormDataApi(
      `${globalKnowledgeGraphUrl}/visual/excel_sheet_name`,
      file,
      authToken
    ),
  Deletedatasource: (payload, authToken) =>
    useApiServices().putApi(
      `${globalKnowledgeGraphUrl}/visual/deletedatasource`,
      payload,
      authToken
    ),
  Editidatasourcelist: (path, authToken) =>
    useApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/datasource_details/${path}`,
      authToken
    ),
  Editidatasource: (path, data, authToken) =>
    useApiServices().putApiFormData(
      `${globalKnowledgeGraphUrl}/visual/editdatasourcemaster/${path}`,
      data,
      authToken
    ),
  // Savedatasource: (credential) =>
  //   useApiServices().postApi(`${globalKnowledgeGraphUrl}/savedatasource`, credential),
  // Savedatasource: () => `${globalKnowledgeGraphUrl}/savedatasource`,

  RelationShipList: (path, authToken) =>
    useApiServices().getApi(`${globalKnowledgeGraphUrl}/relationshiplist/`, authToken),

  Testdbedit: (credential, path, authToken) =>
    useApiServices().postApi(
      `${globalKnowledgeGraphUrl}/testdbedit/${path}`,
      credential,
      authToken
    ),
  Apicall: (credential, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/apicall`, credential, authToken),
  ApicallFormData: (credential, authToken) =>
    useApiServices().postFormDataApi(`${globalKnowledgeGraphUrl}/apicall`, credential, authToken),
  DatasetList: (credential, path, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/tablelist/`, credential, authToken),
  DatasetColumn: (credential, path, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/columnlist/`, credential, authToken),
  DatasetSchema: (credential, path, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/schemalist/`, credential, authToken),

  // Editidatasourcelist: (path) =>
  //   useApiServices().putApi(`${globalKnowledgeGraphUrl}/editidatasourcelist/${path}`)

  // Editidatasource: (path) => `${globalKnowledgeGraphUrl}/editdatasource/${path}`,

  JsonToDf: (credential, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/json_to_df`, credential, authToken),
  JsonFileSave: (file, authToken) =>
    useApiServices().postFormDataApi(`${globalKnowledgeGraphUrl}/json_file_save`, file, authToken),
  // ExcelSheetName: (file, authToken) =>
  //   useApiServices().postFormDataApi(`${globalKnowledgeGraphUrl}/excel_sheet_name`, file, authToken),
  // Insertdatasetrule: (cred, authToken) =>
  //   useApiServices().postApi(`${globalKnowledgeGraphUrl}/insertdatasetrule`, cred, authToken),
  XmlToDf: (credential, authToken) =>
    useApiServices().postApi(`${globalKnowledgeGraphUrl}/xml_to_df`, credential, authToken),
  XmlFileSave: (file, authToken) =>
    useApiServices().postFormDataApi(`${globalKnowledgeGraphUrl}/xml_file_save`, file, authToken),

  TestFileDynamic: (path, authToken) => useApiServices().getApiWithBlobData(path, authToken),
  FormURLEncodedFilePath: (path, authToken) => useApiServices().getApiWithBlobData(path, authToken)
};

export default dataSourceService;

// import useApiServices from './useApiServices';
// import { globalUrlDS } from './globalApi';
// //
// /**
//  * @module dataSourceService
//  * @returns  it return All DataSource Apis
//  */

// const dataSourceService = {
//   Diallinputpram: () => useApiServices().getApi(`${globalUrlDS}/diallinputpram`),
//   // Savedatasource: (credential) =>
//   //   useApiServices().postApi(`${globalUrlDS}/savedatasource`, credential),
//   Savedatasource: () => `${globalUrlDS}/savedatasource`,
//   Datasourcelist: (path) => useApiServices().getApi(`${globalUrlDS}/datasourcelist/${path}`),
//   RelationShipList: (path) => useApiServices().getApi(`${globalUrlDS}/relationshiplist/`),
//   Savedbconfig: (credential) => useApiServices().postApi(`${globalUrlDS}/savedbconfig`, credential),
//   Testdbedit: (credential, path) =>
//     useApiServices().postApi(`${globalUrlDS}/testdbedit/${path}`, credential),
//   Apicall: (credential, path) => useApiServices().postApi(`${globalUrlDS}/apicall`, credential),
//   DatasetList: (credential, path) =>
//     useApiServices().postApi(`${globalUrlDS}/tablelist/`, credential),
//   DatasetColumn: (credential, path) =>
//     useApiServices().postApi(`${globalUrlDS}/columnlist/`, credential),
//   DatasetSchema: (credential, path) =>
//     useApiServices().postApi(`${globalUrlDS}/schemalist/`, credential),
//   Deletedatasource: (path) => useApiServices().putApi(`${globalUrlDS}/deletedatasource/${path}`),
//   // Editidatasourcelist: (path) =>
//   //   useApiServices().putApi(`${globalUrlDS}/editidatasourcelist/${path}`)
//   Editidatasourcelist: (path) =>
//     useApiServices().getApi(`${globalUrlDS}/editidatasourcelist/${path}`),
//   Editidatasource: (path) => `${globalUrlDS}/editdatasource/${path}`
// };

// export default dataSourceService;
