import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  Tooltip,
  Button,
  Container,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  tableCellClasses,
  Checkbox,
  Radio
} from '@mui/material';
import styled from '@emotion/styled';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoPlay, IoSpeedometerOutline } from 'react-icons/io5';
import { IoMdUnlock } from 'react-icons/io';
import { AiFillSave } from 'react-icons/ai';
// accodion
import MuiAccordion from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Page from '../Page';
import PageTitle from '../../global/PageHeading';
import RenderIcons from '../../global/RenderIcons';
import { getIcon, CancelIcon } from '../../../vector/index';
import UserManageAuthService from '../../../services/UserManageAuthService';

import { getGlobals } from '../../../utils/Globals';
import BrudCrumbs from '../../global/BreadCrumbs';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import TopNavigationBar from '../TopNavigationBar';

// const authToken = userDataFromLocal().allTokens?.manage_access?.data?.access;

const { EditManageListAPI, EditManageSaveAPI } = UserManageAuthService;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: '0.5rem'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '0.5rem'
  }
}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2)
}));

function ManageAccessAdd({ scoreObj }) {
  const navigate = useNavigate();
  const { GLOBAL_PATH } = getGlobals();
  console.log('GLOBAL_PATH', GLOBAL_PATH);
  const [openLoader, setOpenLoader] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [mutate, setMutate] = useState(null);
  const [navListAPI, setNavListAPI] = useState([]);

  const pathId = useParams();
  const user_id = pathId.userid;
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
    setValidationMsg('');
  };

  console.log('navListAPI', navListAPI);

  // console.log('selectedValue', selectedValue);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRadioChange = (event, radioIndx, radioV, item) => {
    console.log('event.target.checked', event, event.target.checked);
    console.log('event.target.checked', item);

    item[radioV] = String(event.target.checked);
    const prevArra = [...navListAPI];
    prevArra[radioIndx] = item;
    console.log('itemitem', item);
    setNavListAPI(prevArra);
    // setMutate(new Date());
  };

  // const handleRadioChange = (event, radioIndx, radioV, fal1, fal2, fal3, item) => {
  //   console.log('event.target.checked', item[radioV]);
  //   item[radioV] = item[radioV] === 'true' ? 'false' : 'true';
  //   item[fal1] = 'false';
  //   item[fal2] = 'false';
  //   item[fal3] = 'false';
  //   console.log('eventevent2', item);
  //   const prevArra = [...navListAPI];
  //   prevArra[radioIndx] = item;
  //   setNavListAPI(prevArra);

  //   // prevArra.keyEnable = radioV;
  //   // const flterArra = prevArra.filter(
  //   //   // (item) => (item.keyEnable = item.moduleName === navList.moduleName ? radioV : '')
  //   //   (item) => (item = item.keyEnable !== '')
  //   // );
  //   // setNavListAPI(flterArra);
  // };

  // manage edit save
  // userAdd API
  const handleEditSave = async () => {
    // const authToken = userDataFromLocal().allTokens['manage-access'].data.access;
    navListAPI.forEach((item) => {
      item.crea_user = userDataFromLocal().userName;
      item.updt_user = userDataFromLocal().userName;
    });
    const credential = navListAPI;
    setOpenLoader(true);
    try {
      if (user_id) {
        const main_user_id = userDataFromLocal()?.userID;
        const authToken = userDataFromLocal();
        const isEditManageSave = await EditManageSaveAPI(main_user_id, credential, authToken.token);
        setOpenLoader(false);
        if (isEditManageSave.status === 'success') {
          // enqueueSnackbar(isEditManageSave.message, {
          //   variant: isEditManageSave.status,
          //   autoHideDuration: 2000
          // });
          navigate(`${GLOBAL_PATH}/manage-access`);
        } else if (isEditManageSave.status === 'error') {
          setOpenValidation(true);
          setValidationMsg(isEditManageSave.message);
          // enqueueSnackbar(isEditManageSave.message, {
          //   variant: isEditManageSave.status,
          //   autoHideDuration: 2000
          // });
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // manageList API is also using at user list page
    (async () => {
      try {
        // const authToken = userDataFromLocal().allTokens['manage-access'].data.access;
        setOpenLoader(true);
        const authToken = userDataFromLocal();
        const isEditManageList = await EditManageListAPI(
          user_id,
          authToken.userID,
          authToken.token
        ); // manageList API is also using for manage list API
        setOpenLoader(false);
        if (isEditManageList.status === 'success') {
          console.log('isEditManageList', isEditManageList.data);
          setNavListAPI(isEditManageList.data);
        } else if (isEditManageList.status === 'error') {
          setOpenValidation(true);
          setValidationMsg(isEditManageList.message);
        }
      } catch (error) {
        console.log('error', error);
      }
    })();
  }, []);

  return (
    <Page title="Manage Access">
      <Container maxWidth="xl" className="noPadding bg-h-100">
        <TopNavigationBar />
        <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
        />

        <Box pt={3} pb={2} px={2} mb={4}>
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageTitleholder"
          >
            <PageTitle icon={<IoMdUnlock size={20} />} info="Set Up Roles" className="navTxt" />
            <Box display="flex" alignItems="center" justifyContent="right" textAlign="right">
              <Button
                variant="outlined"
                size="small"
                classes={{
                  root: 'globalSubmitButton globalSubmitButtonCancel autoButton'
                }}
                onClick={handleEditSave}
              >
                <AiFillSave /> Save
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="outlined"
                size="small"
                classes={{
                  root: 'globalSubmitButton globalSubmitButtonCancel autoButton'
                }}
                onClick={() => navigate(`${GLOBAL_PATH}/manage-access`)}
              >
                <RenderIcons icon={getIcon(CancelIcon, null, 16, 16)} /> Cancel
              </Button>
            </Box>
          </Stack> */}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.8rem' }}>
            <Typography variant="h6" sx={{ color: '#004C99', p: 1 }}>
              Set Up Roles
            </Typography>
          </Box>

          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Set Up Roles" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack> */}

          <Box className="" mb={2}>
            {/* dashboard */}
            {navListAPI.length > 0 &&
              navListAPI.map((item, index) => {
                return (
                  <Accordion
                    classes={{ root: 'umgaccordionOuter', expanded: 'umgaccordionOuterExpanded' }}
                    expanded={expanded === item.module_name}
                    onChange={handleChange(item.module_name)}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel14bh-content"
                      id="panel14bh-header"
                      className="umgaccordionHeader"
                      classes={{
                        expanded: 'umgaccordionHeaderExpanded',
                        content: 'umgaccordionHeaderInner',
                        expandIconWrapper: 'umgaccordionIcon'
                      }}
                    >
                      <Typography
                        sx={{
                          width: '100%',
                          flexGrow: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className="umgfontBold"
                          sx={{ width: '33%', flexShrink: 0, textTransform: 'capitalize' }}
                        >
                          {item.module_name}
                        </Typography>
                        {/* <Checkbox onClick={(e) => e.stopPropagation()} /> */}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="umgaccordionDetailBox">
                      <Card>
                        <TableContainer>
                          <Table border={1} borderColor="#ebedf2">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Type of access</StyledTableCell>
                                <StyledTableCell width={60}>Permission</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow hover tabIndex={-1} role="checkbox">
                                <StyledTableCell align="left">
                                  <strong>Add</strong>
                                </StyledTableCell>
                                <TableCell padding="checkbox" sx={{ textAlign: 'center' }}>
                                  <Checkbox
                                    // checked={false}
                                    checked={item.add === 'true'}
                                    onChange={(e) => handleRadioChange(e, index, 'add', item)}
                                    // onClick={(e) =>
                                    //   handleRadioChange(
                                    //     e,
                                    //     index,
                                    //     'create',
                                    //     'edit',
                                    //     'view',
                                    //     'manage',
                                    //     item
                                    //   )
                                    // }
                                    sx={{
                                      padding: '0',
                                      color: '#4FBBC8',
                                      '&.Mui-checked': {
                                        color: '#4FBBC8'
                                      }
                                    }}
                                    value="add"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                  />
                                </TableCell>
                              </StyledTableRow>
                              <StyledTableRow hover tabIndex={-1} role="checkbox">
                                <StyledTableCell align="left">
                                  <strong>Edit</strong>
                                </StyledTableCell>
                                <TableCell padding="checkbox" sx={{ textAlign: 'center' }}>
                                  <Checkbox
                                    checked={item.edit === 'true'}
                                    onChange={(e) => handleRadioChange(e, index, 'edit', item)}
                                    // onClick={(e) =>
                                    //   handleRadioChange(
                                    //     e,
                                    //     index,
                                    //     'edit',
                                    //     'create',
                                    //     'view',
                                    //     'manage',
                                    //     item
                                    //   )
                                    // }
                                    sx={{
                                      padding: '0',
                                      color: '#4FBBC8',
                                      '&.Mui-checked': {
                                        color: '#4FBBC8'
                                      }
                                    }}
                                    value="edit"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                  />
                                </TableCell>
                              </StyledTableRow>
                              <StyledTableRow hover tabIndex={-1} role="checkbox">
                                <StyledTableCell align="left">
                                  <strong>View</strong>
                                </StyledTableCell>
                                <TableCell padding="checkbox" sx={{ textAlign: 'center' }}>
                                  <Checkbox
                                    checked={item.view === 'true'}
                                    onChange={(e) => handleRadioChange(e, index, 'view', item)}
                                    // onClick={(e) =>
                                    //   handleRadioChange(
                                    //     e,
                                    //     index,
                                    //     'view',
                                    //     'create',
                                    //     'edit',
                                    //     'manage',
                                    //     item
                                    //   )
                                    // }
                                    sx={{
                                      padding: '0',
                                      color: '#4FBBC8',
                                      '&.Mui-checked': {
                                        color: '#4FBBC8'
                                      }
                                    }}
                                    value="view"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                  />
                                </TableCell>
                              </StyledTableRow>
                              <StyledTableRow hover tabIndex={-1} role="checkbox">
                                <StyledTableCell align="left">
                                  <strong>Delete</strong>
                                </StyledTableCell>
                                <TableCell padding="checkbox" sx={{ textAlign: 'center' }}>
                                  <Checkbox
                                    checked={item.delete === 'true'}
                                    onChange={(e) => handleRadioChange(e, index, 'delete', item)}
                                    // onClick={(e) =>
                                    //   handleRadioChange(
                                    //     e,
                                    //     index,
                                    //     'manage',
                                    //     'create',
                                    //     'edit',
                                    //     'view',
                                    //     item
                                    //   )
                                    // }
                                    sx={{
                                      padding: '0',
                                      color: '#4FBBC8',
                                      '&.Mui-checked': {
                                        color: '#4FBBC8'
                                      }
                                    }}
                                    value="delete"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                  />
                                </TableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Box>
          <Box textAlign="right">
            <Button className="umgmainBtn" onClick={() => navigate(`${GLOBAL_PATH}/manage-access`)}>
              Cancel
            </Button>
            <Button className="umgmainBtn umgml-1" onClick={handleEditSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default ManageAccessAdd;
