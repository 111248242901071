import { Close, PlusOne } from '@material-ui/icons';
import { IconButton, Tab, Tabs, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import './BottomTabsStyle.css';
import { useSelector, useDispatch } from 'react-redux';

export default function AddGraphTabs() {
  const dispatch = useDispatch();
  const { selectedGraphTabValue, cytoDataAllGraph, snackbarToggle } = useSelector(
    (state) => state.knowledgeGraph.allSelectedData
  );
  const { cyToState } = useSelector((state) => state.knowledgeGraph.createKnowledgeGraph);
  const [tabLabels, setLebels] = useState(['Graph 1']);
  const [nextNumber, setNextNumber] = useState(1);
  const [graphTabValue, setGraphTabValue] = useState(0);

  const removeTab = (item) => {
    const newArray = tabLabels.filter((elm) => elm !== item);
    const index = tabLabels.indexOf(item);
    if (item === selectedGraphTabValue) {
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'selectedGraphTabValue',
          value: tabLabels[index - 1]
        }
      });
    }
    console.log('selectedGraphTabValue remove', tabLabels[index - 1], newArray);
    setLebels(newArray);
  };

  return (
    <Box
      sx={{ width: 'auto' }}
      style={{
        position: 'fixed',
        top: '0'
      }}
    >
      <Tabs
        value={selectedGraphTabValue}
        onChange={(e, newValue) => {
          setGraphTabValue(newValue);
        }}
        aria-label={'basic tabs example'}
        className={'tabContainer bottom'}
        style={{ minHeight: '36px' }}
      >
        {tabLabels.map((item, index) => {
          // console.log('setGraphTabValue', index, graphTabValue);
          return (
            <Tab
              className={'tabLabel customTab'}
              classes={{ selected: 'SubGraphtabActive' }}
              label={item}
              value={item}
              onClick={(evt) =>
                dispatch({
                  type: 'STORE_ALL_SELECTED_DATA',
                  payload: {
                    key: 'selectedGraphTabValue',
                    value: evt.currentTarget.innerText
                  }
                })
              }
              key={item + index}
              // className={graphTabValue === index ? 'tabActive' : ''}
              icon={
                index > 0 ? (
                  <IconButton
                    sx={{ marginBottom: 0, marginLeft: '6px' }}
                    size={'small'}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeTab(item);
                    }}
                    className={'CloseGraph'}
                  >
                    <Close
                      style={{
                        fontSize: '0.78rem',
                        color: selectedGraphTabValue === item ? '#ececec' : '#637381'
                      }}
                    />
                  </IconButton>
                ) : (
                  ''
                )
              }
              disableRipple={true}
            />
          );
        })}
        <Tab
          label={'Add Graph'}
          className={'tabLabel customTab'}
          onClick={() => {
            const prevTabs = [...tabLabels];
            const newLabel = `Graph ${nextNumber + 1}`;
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'selectedGraphTabValue',
                value: `Graph ${nextNumber + 1}`
              }
            });
            setLebels([...prevTabs, ...[newLabel]]);
            setNextNumber(nextNumber + 1);
          }}
          aria-label={'person'}
          disableRipple={true}
        />
      </Tabs>
    </Box>
  );
}
