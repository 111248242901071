import addressImg from './images/address.png';
import buyerImg from './images/buyer.png';
import companyImg from './images/company.png';
import directorImg from './images/director.png';
import moneyImg from './images/money.png';
import phoneImg from './images/phone.png';
import blacklistDirImg from './images/blacklist_dir.png';
import blacklistedImg from './images/blacklisted.png';
import kglogoImg from './images/kg_logo.png';
import blacklist_companyImg from './images/blacklist_company.png';
import nodeLogoImg from './images/nodeLogo.svg';

const dataTypeDropdownList = [
  {
    label: 'bool',
    value: 'bool',
    description: 'logical Boolean (true/false)'
  },
  {
    label: 'char',
    value: 'char',
    description: 'fixed-length character string'
  },
  {
    label: 'varchar',
    value: 'varchar',
    description: 'variable-length character string'
  },
  {
    label: 'text',
    value: 'text',
    description: 'text string'
  },
  {
    label: 'float8',
    value: 'float8',
    description: 'double floating-point number (8 bytes)'
  },
  {
    label: 'int8',
    value: 'int8',
    description: 'signed four-byte integer'
  },
  {
    label: 'bigint',
    value: 'bigint',
    description: 'signed eight-byte integer'
  },
  {
    label: 'date',
    value: 'date',
    description: 'calendar date (year, month, day)'
  },
  {
    label: 'time',
    value: 'time',
    description: 'time of day (no time zone)'
  }
  // {
  //   label: 'bigserial',
  //   value: 'serial8',
  //   description: 'autoincrementing eight-byte integer'
  // },
  // {
  //   label: 'bit',
  //   value: 'bit',
  //   description: 'fixed-length bit string'
  // },
  // {
  //   label: 'bit varying',
  //   value: 'varbit',
  //   description: 'variable-length bit string'
  // },
  // {
  //   label: 'box',
  //   value: 'box',
  //   description: 'rectangular box on a plane'
  // },
  // {
  //   label: 'bytea',
  //   value: 'bytea',
  //   description: 'binary data (“byte array”)'
  // },
  // {
  //   label: 'cidr',
  //   value: 'cidr',
  //   description: 'IPv4 or IPv6 network address'
  // },
  // {
  //   label: 'circle',
  //   value: 'circle',
  //   description: 'circle on a plane'
  // },
  // {
  //   label: 'inet',
  //   value: 'inet',
  //   description: 'IPv4 or IPv6 host address'
  // },
  // { label: 'json', value: 'json', description: 'textual JSON data' },
  // {
  //   label: 'jsonb',
  //   value: 'jsonb',
  //   description: 'binary JSON data, decomposed'
  // },
  // {
  //   label: 'macaddr',
  //   value: 'macaddr',
  //   description: 'MAC (Media Access Control) address'
  // },
  // {
  //   label: 'macaddr8',
  //   value: 'macaddr8',
  //   description: 'MAC (Media Access Control) address (EUI-64 format)'
  // },
  // { label: 'money', value: 'money', description: 'currency amount' },
  // {
  //   label: 'numeric',
  //   value: 'decimal',
  //   description: 'exact numeric of selectable precision'
  // },
  // {
  //   label: 'real',
  //   value: 'float4',
  //   description: 'single precision floating-point number (4 bytes)'
  // },
  // {
  //   label: 'smallint',
  //   value: 'int2',
  //   description: 'signed two-byte integer'
  // },
  // {
  //   label: 'smallserial',
  //   value: 'serial2',
  //   description: 'autoincrementing two-byte integer'
  // },
  // {
  //   label: 'serial',
  //   value: 'serial4',
  //   description: 'autoincrementing four-byte integer'
  // },
  // {
  //   label: 'text',
  //   value: 'text',
  //   description: 'variable-length character string'
  // },
  // {
  //   label: 'timetz',
  //   value: 'timetz',
  //   description: 'time of day, including time zone'
  // },
  // {
  //   label: 'timestamp',
  //   value: 'timestamp',
  //   description: 'date and time (no time zone)'
  // },
  // {
  //   label: 'timestamptz',
  //   value: 'timestamptz',
  //   description: 'date and time, including time zone'
  // },
  // {
  //   label: 'uuid',
  //   value: 'uuid',
  //   description: 'universally unique identifier'
  // },
  // { label: 'xml', value: 'xml', description: 'XML data' }
];

const initialState = {
  isLoading: false,
  items: [],
  knowledgeGraph: {
    refreshDataSourceComp: null,
    allSelectedData: {
      sourceType: { id: 'Db', name: 'Db' },
      dataSource: { id: '%%', name: '%%' },
      schemas: { id: '%%', name: '%%' },
      tables: { id: '%%', name: '%%' },
      fileds: { id: '%%', name: '%%' },
      mappedSource: { id: '%%', name: '%%' },
      mappedField: { id: '%%', name: '%%' },
      curentField: { id: '%%', name: '%%' },
      curentdataType: { id: '%%', name: '%%' },
      newtableName: '',
      exitsTableName: ' ',
      appTableExits: false,
      appDatasetsCommonColumns: [],
      currentActiveStep: 0,
      initMainGraph: false,
      currentdataSetId: '',
      retrivedDatasetId: '',
      nodeForConnection: [],
      currentSelectedmodelId: null,
      slectedOption: '',
      currentNodeid: '',
      currentNodeInfo: {},
      currentEdgeid: '',
      currentEdgeData: {},
      hideSideBar: '',
      hideSideBarRight: false,
      expanded: '',
      selectedGraphTabValue: 'Graph 1',
      cytoDataAllGraph: [],
      snackbarToggle: { trigger: false, message: '' },
      commonTableColumns: { key: null },
      apiErrorDialogValue: { dialog: false, message: '' }
    },
    node_edge_styling: {
      labels: { nodeLabels: [], edgeLabels: [] },
      vizEdgeStyles: {},
      vizNodeStyles: {}
    },
    addMoreLinkingArray: [],
    allModelsForProjects: [],
    mappedFieldList: {},
    sourceTypeList: [
      { lable: '%%' },
      { lable: 'Banking' },
      { lable: 'User' },
      { lable: 'Supplier' },
      { lable: 'Buyer' }
    ],
    dataTypeDropdownList: dataTypeDropdownList,
    createKnowledgeGraph: {
      colorCodes: [
        { company: 'rgb(96, 74, 14)' },
        { customer: 'rgb(201, 144, 192)' },
        { address: 'rgb(247, 151, 103)' },
        { director: 'rgb(87, 199, 227)' },
        { blacklistDirector: 'rgb(241, 102, 103)' },
        { blacklistCompany: 'rgb(241, 102, 103)' },
        { blacklist: 'rgb(241, 102, 103)' },
        { buyer: 'rgb(217, 200, 174)' },
        { companyDirector: 'rgb(141, 204, 147)' },
        { transactions: 'rgb(76, 142, 218)' },
        { phone: 'rgb(254, 255, 189)' },
        { kgLogo: 'rgb(243, 243, 243)' }
      ],
      imgCategory: [
        { company: companyImg },
        { customer: companyImg },
        { address: addressImg },
        { director: directorImg },
        { blacklistDirector: blacklistDirImg },
        { blacklistCompany: blacklist_companyImg },
        { blacklist: blacklistedImg },
        { buyer: buyerImg },
        { phone: phoneImg },
        { companyDirector: directorImg },
        { transactions: moneyImg },
        { kgLogo: kglogoImg },
        { node: nodeLogoImg }
      ],
      styling: [
        // the stylesheet for the graph
        {
          selector: 'node',
          style: {
            backgroundColor: function (node) {
              // console.log(
              //   'colorCodesExp',
              //   initialState.knowledgeGraph.createKnowledgeGraph.colorCodes
              // );
              // console.log('colorCodesExp', node.data('label'));
              const filterCode = initialState.knowledgeGraph.createKnowledgeGraph.colorCodes.filter(
                (code) => {
                  // console.log('abra ka dabra', code);
                  // console.log('abra ka dabra', node.data('label'));
                  return code[node.data('label')];
                }
              );
              // console.log('colorCodesExp', filterCode);
              if (filterCode.length > 0) {
                return filterCode[0][node.data('label')];
              }
              return 'rgb(255, 255, 255)';
              // if (node.data('label') === 'directors') {
              //   if (node.data('props') && node.data('props').isBlacklisted === 'yes') {
              //     return 'red';
              //   }
              //   return 'rgb(141, 204, 147)';
              // }

              // // rgb(141, 204, 147)
              // // use function to set dynamic label
              // if (node.data('props') && node.data('props').isBlacklisted === 'yes') {
              //   return 'red';
              // }
              // if (node.data('label') === 'blackDirector') {
              //   return 'red';
              // }
            },

            label: function (node) {
              // console.log('colorCodesExpLable', node);
              return node.data('label');
            },
            height: 80,
            width: 80,
            padding: 10,
            'background-fit': 'cover',
            'background-opacity': 1,
            'border-width': 0,
            'border-style': 'solid',
            'border-color': '#52a8e1',
            'border-opacity': 1,
            shape: 'rectangle',
            'background-repeat': 'no-repeat',
            'background-image': [],
            'background-image-opacity': 1,
            'font-size': 9,
            // 'text-margin-y': '-7px',
            'text-valign': 'top',
            'text-halign': 'center',
            color: '#000',
            'text-opacity': 1,
            backgroundImage: function (node) {
              const filterCode =
                initialState.knowledgeGraph.createKnowledgeGraph.imgCategory.filter((code) => {
                  return code[node.data('label')];
                });
              // console.log('colorCodesExp', filterCode);
              if (filterCode.length > 0) {
                return filterCode[0][node.data('label')];
              }
              return nodeLogoImg;
            },
            backgroundOpacity: function (node) {
              const filterCode =
                initialState.knowledgeGraph.createKnowledgeGraph.imgCategory.filter((code) => {
                  return code[node.data('label')];
                });
              if (filterCode.length > 0) {
                return 0;
              }
              return 1;
            },
            borderOpacity: function (node) {
              const filterCode =
                initialState.knowledgeGraph.createKnowledgeGraph.imgCategory.filter((code) => {
                  return code[node.data('label')];
                });
              if (filterCode.length > 0) {
                return 0;
              }
              return 1;
            }
          }
        },
        {
          selector: 'node:selected',
          style: {
            'underlay-color': '#52a8e1'
          }
        },
        {
          selector: 'node.highlighted',
          style: {
            'min-zoomed-font-size': 0,
            'z-index': 9999
          }
        },
        {
          selector: 'node.faded',
          style: {
            events: 'no',
            opacity: 0.1
          }
        },
        {
          selector: 'edge.faded',
          style: {
            events: 'no',
            opacity: 0.1
          }
        },
        {
          selector: 'edge.highlighted',
          style: {
            opacity: 0.8,
            width: 4,
            'z-index': 9999
          }
        },
        {
          selector: 'node.visible',
          style: {
            opacity: 1,
            display: 'block'
          }
        },
        {
          selector: 'edge',
          style: {
            label: 'data(label)',
            'curve-style': 'unbundled-bezier',
            'target-arrow-shape': 'triangle',
            'edge-text-rotation': 'autorotate',
            'font-size': 12,
            'text-margin-y': '-7px',
            'text-margin-x': '7px',
            'target-text-margin-y': '-7px',
            'line-color': '#ddd',
            'line-style': 'solid',
            width: 5,
            color: '#000',
            'text-opacity': 1
            // "text-margin-x":"10px"
          }
        },
        {
          selector: ':parent',
          css: {
            'text-valign': 'top',
            'text-halign': 'center'
          }
        }
      ],
      stylingArchive: [
        // the stylesheet for the graph
        {
          selector: 'node',
          style: {
            backgroundColor: function (node) {
              // console.log(
              //   'colorCodesExp',
              //   initialState.knowledgeGraph.createKnowledgeGraph.colorCodes
              // );
              // console.log('colorCodesExp', node.data('label'));
              const filterCode = initialState.knowledgeGraph.createKnowledgeGraph.colorCodes.filter(
                (code) => {
                  // console.log('abra ka dabra', code);
                  // console.log('abra ka dabra', node.data('label'));
                  return code[node.data('label')];
                }
              );
              // console.log('colorCodesExp', filterCode);
              if (filterCode.length > 0) {
                return filterCode[0][node.data('label')];
              }
              return 'rgb(255, 255, 255)';
              // if (node.data('label') === 'directors') {
              //   if (node.data('props') && node.data('props').isBlacklisted === 'yes') {
              //     return 'red';
              //   }
              //   return 'rgb(141, 204, 147)';
              // }

              // // rgb(141, 204, 147)
              // // use function to set dynamic label
              // if (node.data('props') && node.data('props').isBlacklisted === 'yes') {
              //   return 'red';
              // }
              // if (node.data('label') === 'blackDirector') {
              //   return 'red';
              // }
            },

            label: function (node) {
              // console.log('colorCodesExpLable', node);
              return node.data('label');
            },
            height: 80,
            width: 80,
            padding: 10,
            'background-fit': 'cover',
            'background-opacity': 1,
            'border-width': 0,
            'border-style': 'solid',
            'border-color': '#52a8e1',
            'border-opacity': 1,
            shape: 'rectangle',
            'background-repeat': 'no-repeat',
            'background-image': [],
            'background-image-opacity': 1,
            'font-size': 9,
            // 'text-margin-y': '-7px',
            'text-valign': 'top',
            'text-halign': 'center',
            color: '#000',
            'text-opacity': 1,
            backgroundImage: function (node) {
              const filterCode =
                initialState.knowledgeGraph.createKnowledgeGraph.imgCategory.filter((code) => {
                  return code[node.data('label')];
                });
              // console.log('colorCodesExp', filterCode);
              if (filterCode.length > 0) {
                return filterCode[0][node.data('label')];
              }
              return nodeLogoImg;
            },
            backgroundOpacity: function (node) {
              const filterCode =
                initialState.knowledgeGraph.createKnowledgeGraph.imgCategory.filter((code) => {
                  return code[node.data('label')];
                });
              if (filterCode.length > 0) {
                return 0;
              }
              return 1;
            },
            borderOpacity: function (node) {
              const filterCode =
                initialState.knowledgeGraph.createKnowledgeGraph.imgCategory.filter((code) => {
                  return code[node.data('label')];
                });
              if (filterCode.length > 0) {
                return 0;
              }
              return 1;
            }
          }
        },
        {
          selector: 'node:selected',
          style: {
            'underlay-color': '#52a8e1'
          }
        },
        {
          selector: 'node.highlighted',
          style: {
            'min-zoomed-font-size': 0,
            'z-index': 9999
          }
        },
        {
          selector: 'node.faded',
          style: {
            events: 'no',
            opacity: 0.1
          }
        },
        {
          selector: 'edge.faded',
          style: {
            events: 'no',
            opacity: 0.1
          }
        },
        {
          selector: 'edge.highlighted',
          style: {
            opacity: 0.8,
            width: 4,
            'z-index': 9999
          }
        },
        {
          selector: 'node.visible',
          style: {
            opacity: 1,
            display: 'block'
          }
        },
        {
          selector: 'edge',
          style: {
            label: 'data(label)',
            'curve-style': 'unbundled-bezier',
            'target-arrow-shape': 'triangle',
            'edge-text-rotation': 'autorotate',
            'font-size': 12,
            'text-margin-y': '-7px',
            'text-margin-x': '7px',
            'target-text-margin-y': '-7px',
            'line-color': '#ddd',
            'line-style': 'solid',
            width: 5,
            color: '#000',
            'text-opacity': 1
            // "text-margin-x":"10px"
          }
        },
        {
          selector: ':parent',
          css: {
            'text-valign': 'top',
            'text-halign': 'center'
          }
        }
      ],
      datasourceLinkMap: ''
    }
  },
  userManagement: {
    userDetailsAdd: []
  }
};
const rootReducer = (state = initialState, action) => {
  // console.log('init store', action);
  switch (action.type) {
    case 'ITEMS_REQUEST':
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case 'ITEMS_REQUEST_SUCCESS':
      return {
        ...state,
        items: state.items.concat(action.items),
        isLoading: action.isLoading
      };
    case 'storeKgDataSourceList':
      return {
        ...state,
        knowledgeGraph: { ...state.knowledgeGraph, dataSourceList: action.payload }
      };
    case 'STORE_LISTS_DATA':
      return {
        ...state,
        knowledgeGraph: { ...state.knowledgeGraph, [action.payload.key]: action.payload.value }
      };
    case 'STORE_SCHEMA_LIST':
      return {
        ...state,
        knowledgeGraph: { ...state.knowledgeGraph, schemaLists: action.payload }
      };
    case 'STORE_TABLES_LIST':
      return {
        ...state,
        knowledgeGraph: { ...state.knowledgeGraph, tablesLists: action.payload }
      };
    case 'STORE_ADD_MORE_LINK':
      return {
        ...state,
        knowledgeGraph: { ...state.knowledgeGraph, addMoreLinkingArray: action.payload }
      };
    case 'STORE_ALL_SELECTED_DATA':
      return {
        ...state,
        knowledgeGraph: {
          ...state.knowledgeGraph,
          allSelectedData: {
            ...state.knowledgeGraph.allSelectedData,
            [action.payload.key]: action.payload.value
          }
        }
      };
    case 'STYLING_DATA_UPDATE':
      return {
        ...state,
        knowledgeGraph: {
          ...state.knowledgeGraph,
          node_edge_styling: {
            ...state.knowledgeGraph.node_edge_styling,
            [action.payload.key]: {
              ...state.knowledgeGraph.node_edge_styling[action.payload.key],
              ...action.payload.value
            }
          }
        }
      };
    case 'STYLING_DATA_LABEL_UPDATE':
      return {
        ...state,
        knowledgeGraph: {
          ...state.knowledgeGraph,
          node_edge_styling: {
            ...state.knowledgeGraph.node_edge_styling,
            labels: {
              ...state.knowledgeGraph.node_edge_styling.labels,
              [action.payload.key]: [...action.payload.value]
            }
          }
        }
      };
    case 'STORE_USER_INFORMATION_DATA':
      return {
        ...state,
        userManagement: {
          ...state.userManagement,
          [action.payload.key]: action.payload.value
        }
      };

    case 'CREATE_KG':
      // console.log('action.payload', action.payload);
      return {
        ...state,
        knowledgeGraph: {
          ...state.knowledgeGraph,
          createKnowledgeGraph: {
            ...state.knowledgeGraph.createKnowledgeGraph,
            [action.payload.key]: action.payload.value
          }
        }
      };
    default:
      return state;
  }
};
export default rootReducer;
