import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _shuffle from 'lodash/shuffle';
import {
  Box,
  Tab,
  Tabs,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Tooltip,
  Modal,
  Paper,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  ButtonGroup,
  ListItemText,
  MenuList,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination
  // TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import CodeMirror from '@uiw/react-codemirror';
import { PostgreSQL, sql } from '@codemirror/lang-sql';
import { autocompletion, Completion, CompletionContext } from '@codemirror/autocomplete';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DataGrid } from '@mui/x-data-grid';
import {
  TextIncrease,
  IosShare,
  GridOn,
  PictureAsPdf,
  ViewModule,
  Save,
  MoreVert,
  Keyboard,
  AutoAwesome,
  FilterAltOff,
  FilterAlt,
  AcUnit
} from '@mui/icons-material';
import './customDataGrid.css';
import * as XLSX from 'xlsx';
import { ArrowDownward, ArrowUpward, Close, FilterList, VisibilityOff } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import DatagridRuleEngine from './DatagridRuleEngine';
import sqlAutocompleteSuggestions from './sqlAutocompleteSuggestions';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import Scrollbar from '../../Scrollbar';

const { PostApiByUrl, DatasetDetails, SaveSqlQueryApi, SavedSqlRuleList, SqlRuleApply } =
  KnowledgeGraphService;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function MyDataGrid() {
  const [tabValue, setTabValue] = useState('');
  const [tabNames, setTabNames] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [rowDataArchive, setRowDataArchive] = useState([]);
  const [tableColumnArchive, setTableColumnArchive] = useState([]);

  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const [gridCursor, setGridCursor] = useState('text');
  const [tablePage, setTablePage] = useState({
    page_no: 0,
    limit: 50,
    total_pages: 0,
    first_time_load: true
  });
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  // const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  // const contextMenuRef = useRef(null);

  // const handleContextMenu = (event) => {
  //   event.preventDefault();

  //   const menuWidthOffset = contextMenuRef.current.offsetWidth;
  //   const menuHeightOffset = contextMenuRef.current.offsetHeight;

  //   const clickX =
  //     event.clientX > window.innerWidth - menuWidthOffset
  //       ? event.clientX - menuWidthOffset
  //       : event.clientX;
  //   const clickY =
  //     event.clientY > window.innerHeight - menuHeightOffset
  //       ? event.clientY - menuHeightOffset
  //       : event.clientY;

  //   setContextMenu({
  //     visible: true,
  //     x: clickX,
  //     y: clickY
  //   });
  // };

  // const handleClose = () => {
  //   setContextMenu({ visible: false, x: 0, y: 0 });
  // };

  const fetchDataAndAppend = async (_tabValue, _tablePage) => {
    console.log(_tabValue, 'tab _value');

    const startingRow = _tablePage.page_no * _tablePage.limit;

    const apiPayload = {
      dataset_ids: [Number(_tabValue.tableId)],
      skip: startingRow,
      limit: _tablePage.limit
    };

    const getDataSet = await PostApiByUrl('/visual/data_grid', apiPayload);
    if (getDataSet.status === 'success' && getDataSet.code === 200) {
      const lowerCaseData = Object.entries(getDataSet.data).reduce((acc, [key, value]) => {
        acc[key.toLowerCase()] = value;
        return acc;
      }, {});

      console.log('getDataSet', lowerCaseData);

      const rowObj = lowerCaseData[String(tabValue.tableName).toLowerCase()];
      const allColumns = [...new Set(Object.keys(rowObj[0]))];
      const colsWithTypes = allColumns.map((item) => {
        return { name: item, type: typeof rowObj[0][item] };
      });

      setTableRows(rowObj);
      setRowDataArchive(rowObj);
      if (_tablePage.first_time_load) {
        setTableColumns(colsWithTypes);
        setTableColumnArchive(colsWithTypes);
        setTablePage((prev) => ({
          ...prev,
          first_time_load: false,
          total_pages: getDataSet.total_count
        }));
      }
    }
  };

  // useEffect(() => {
  //   if (tabValue && tabValue.tableName) {
  //     (async () => {
  //       const apiPayload = {
  //         dataset_ids: [Number(tabValue.tableId)],
  //         skip: 0,
  //         limit: 40
  //       };

  //       const getDataSet = await PostApiByUrl('/visual/data_grid', apiPayload);
  //       if (getDataSet.status === 'success' && getDataSet.code === 200) {
  //         console.log('getDataSet', getDataSet);

  //         const allColumns = [...new Set(Object.keys(getDataSet.data[tabValue.tableName][0]))];
  //         console.log('getDataSet allColumns', allColumns);

  //         const rowObj = getDataSet.data[tabValue.tableName];

  //         const colsWithTypes = allColumns.map((item) => {
  //           return { name: item, type: typeof rowObj[0][item] };
  //         });

  //         // if (colsWithTypes.length > 0) {
  //         //   colsWithTypes.unshift({ name: 'Select', type: 'checkbox' });
  //         // }

  //         console.log('getDataSet colTypes', colsWithTypes);
  //         setTableColumns(colsWithTypes);
  //         setTableColumnArchive(colsWithTypes);
  //         setTableRows(rowObj);
  //         setRowDataArchive(rowObj);
  //       }
  //     })();
  //   }
  // }, [tabValue]);

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    // console.log('tablePage', event, newPage);
    setTablePage((prev) => ({ ...prev, page_no: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setTablePage((prev) => ({ ...prev, limit: +event.target.value, page_no: 0 }));
    // setRowsPerPage(+event.target.value);
    // setPage(0);
  };

  useEffect(() => {
    console.log('tablePage', tablePage);
    if (tablePage && tabValue && tabValue.tableName) {
      fetchDataAndAppend(tabValue, tablePage);
    }
  }, [tablePage, tabValue]);

  return (
    <Box
      // onContextMenu={(e) => {
      //   // e.preventDefault();
      //   // if (!contextMenu.visible) {
      //   handleContextMenu(e);
      //   // }
      // }}
      // onClick={handleClose}
      sx={{ height: '100vh', width: '100%' }}
    >
      {/* Context Menu Start */}
      {/* <Paper
        style={{
          position: 'absolute',
          top: contextMenu.y,
          left: contextMenu.x,
          backgroundColor: 'white',
          border: '1px solid black',
          zIndex: 999,
          visibility: contextMenu.visible ? 'visible' : 'hidden',
          pointerEvents: contextMenu.visible ? 'auto' : 'none'
        }}
        sx={{ width: 150, maxWidth: '100%' }}
      >
        <span ref={contextMenuRef}>
          <MenuList>
            <MenuItem>
              <ListItemText>Cut</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘X
              </Typography>
            </MenuItem>
            <MenuItem>
              <ListItemText>Copy</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘C
              </Typography>
            </MenuItem>
            <MenuItem>
              <ListItemText>Paste</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘V
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem>
              <ListItemText>Web Clipboard</ListItemText>
            </MenuItem>
          </MenuList>
        </span>
      </Paper> */}
      {/* Context Menu End */}

      <NavTabs
        tabValue={tabValue}
        setTabValue={setTabValue}
        tabNames={tabNames}
        setTabNames={setTabNames}
      />
      <CustomDataGridMenu
        rowDataArchive={rowDataArchive}
        tableColumnArchive={tableColumnArchive}
        tableRows={tableRows}
        setTableRows={setTableRows}
        tableRef={tableRef}
        tabValue={tabValue}
        tableColumns={tableColumns}
        setTableColumns={setTableColumns}
      />
      <Box ref={tableContainerRef} sx={{ overflow: 'scroll', maxHeight: '75vh' }}>
        <Table id="customDatagridTable" ref={tableRef} className="table-container">
          <TableHead>
            <StyledTableRow className="table-tr">
              {tableColumns &&
                tableColumns.map((column, index) => {
                  return (
                    <ResizableCol
                      rowDataArchive={rowDataArchive}
                      gridCursor={gridCursor}
                      setGridCursor={setGridCursor}
                      tableColumns={tableColumns}
                      setTableColumns={setTableColumns}
                      tableRows={tableRows}
                      setTableRows={setTableRows}
                      draggedColumnIndex={draggedColumnIndex}
                      setDraggedColumnIndex={setDraggedColumnIndex}
                      column={column.name}
                      colType={column.type}
                      colIndex={index}
                      key={column.name}
                      tabValue={tabValue}
                    />
                  );
                })}
            </StyledTableRow>
          </TableHead>
          <TableBody className="table-body">
            {tableRows &&
              tableRows.map((item, rowIndex) => (
                <ResizableRow
                  gridCursor={gridCursor}
                  setGridCursor={setGridCursor}
                  tableRows={tableRows}
                  setTableRows={setTableRows}
                  draggedRowIndex={draggedRowIndex}
                  rowDataObject={item}
                  setDraggedRowIndex={setDraggedRowIndex}
                  rowIndex={rowIndex}
                  key={rowIndex}
                  rows={tableColumns.map((col, index) => {
                    if (col.type === 'checkbox') {
                      return (
                        <td className="table-td" key={`${index}${rowIndex}`}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          >
                            {/* <CheckBox /> */}
                            <input style={{ cursor: 'pointer' }} type="checkbox" name="abc" />
                          </Box>
                        </td>
                      );
                    } else {
                      return (
                        <td className="table-td" key={`${index}${rowIndex}`}>
                          {item[col.name]}
                        </td>
                      );
                    }
                  })}
                />
              ))}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <TablePagination
          sx={{ '& .MuiToolbar-root': { minHeight: 0 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={tablePage.total_pages}
          rowsPerPage={tablePage.limit}
          page={tablePage.page_no}
          onPageChange={handleChangePage}
          labelRowsPerPage={'Page Limit'}
          // labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
          // slotProps={{
          //   actions: { previousButton: { disabled: true, style: { display: 'none' } } }
          // }}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* <Button size="small" onClick={fetchDataAndAppend} variant="contained">
          Load More
        </Button> */}
      </Box>
    </Box>
  );
}

function ResizableCol({
  column,
  colType,
  tableColumns,
  setTableColumns,
  draggedColumnIndex,
  setDraggedColumnIndex,
  colIndex,
  tableRows,
  setTableRows,
  gridCursor,
  setGridCursor,
  tabValue,
  rowDataArchive
}) {
  const [colWidth, setColWidth] = useState(40);
  const tableColRef = useRef(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const handleFilterModalOpen = () => setOpenFilterModal(true);
  const handleFilterModalClose = () => setOpenFilterModal(false);

  const [leftAltDown, setLeftAltDown] = useState(false);

  const handleKeyDown = (e) => {
    // console.log('down alt', e);
    if (e.code === 'AltLeft') {
      setLeftAltDown(false);
    }
  };

  const handleKeyUp = (e) => {
    // console.log('up alt', e);

    if (e.code === 'AltLeft') {
      setLeftAltDown(true);
    }
  };

  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyUp);

  const handleColumnDragStart = (e, index) => {
    if (!e.altKey && !leftAltDown) {
      // console.log('alt key');
      return;
    }
    // setGridCursor('all-scroll');
    setDraggedColumnIndex(index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleColumnDragOver = (e, index) => {
    if (!e.altKey && !leftAltDown) {
      // console.log('alt key');
      return;
    }
    e.preventDefault();
  };

  const handleColumnDrop = (e, index) => {
    if (!e.altKey && !leftAltDown) {
      // console.log('alt key');
      return;
    }
    e.preventDefault();
    const columnToMove = tableColumns[draggedColumnIndex];
    const newColumns = [...tableColumns];
    newColumns.splice(draggedColumnIndex, 1);
    newColumns.splice(index, 0, columnToMove);
    setDraggedColumnIndex(null);
    setTableColumns(newColumns);
    // setGridCursor('text');
    // console.log('coldrag drop', newColumns);
  };

  const handleMouseDown = (mouseDownEvent) => {
    // console.log('col current width', tableColRef.current.offsetWidth);

    if (!mouseDownEvent.shiftKey) {
      // console.log('shift key');
      return;
    }
    setGridCursor('col-resize');
    mouseDownEvent.preventDefault();

    const startWidth = tableColRef.current.offsetWidth;
    const startPosition = mouseDownEvent.clientX;

    const onMouseMove = (mouseMoveEvent) => {
      // window.getComputedStyle(el, null).getPropertyValue('min-height');
      const newWidth = startWidth - startPosition + mouseMoveEvent.clientX;
      setColWidth(newWidth > 0 ? newWidth : 0);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      setGridCursor('text');
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const [anchorE3, setAnchorE3] = useState(null);
  const openColInlineMenu = Boolean(anchorE3);

  const handleColInlineOpen = (event) => {
    setAnchorE3(event.currentTarget);
  };
  const handleColInlineClose = () => {
    setAnchorE3(null);
  };

  const handleHideColumn = (evt, column) => {
    setTableColumns((prevValue) => prevValue.filter((label) => label.name !== column));
    // console.log('nagar', column);
  };

  const handleSortAsc = (evt, column) => {
    // const colName = String(column);
    // console.log('nagar col', colName);

    const data = [...tableRows];
    data.sort((a, b) => {
      const nameA = String(a[column]).toUpperCase(); // Convert names to uppercase for case-insensitive sorting
      const nameB = String(b[column]).toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setTableRows([...data]);
    setAnchorE3(null);
    // console.log('nagar', data);
  };

  const handleSortDesc = (evt, column) => {
    const data = [...tableRows];
    data.sort((a, b) => {
      const nameA = String(a[column]).toUpperCase(); // Convert names to uppercase for case-insensitive sorting
      const nameB = String(b[column]).toUpperCase();

      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
    setTableRows([...data]);
    setAnchorE3(null);
    // console.log('nagar', data);
  };

  const [filterCompareValue, setFilterCompareValue] = useState(null);
  const [filterCompareMinValue, setFilterCompareMinValue] = useState(null);
  const [filterCompareMaxValue, setFilterCompareMaxValue] = useState(null);
  const [filterCompareCondition, setFilterCompareCondition] = useState(null);

  const colFilterConditions = {
    string: ['contains', 'exclude', 'starts with', 'ends with', 'exact match', 'not an exact'],
    number: ['greater than', 'less than', 'equals', 'not equals', 'range']
  };

  const handleFilterConditionChange = (_, value) => setFilterCompareCondition(value);
  const handleFilterColumnReset = () => setTableRows(rowDataArchive);

  const handleFilterColumn = () => {
    const data = [...rowDataArchive];

    if (colType === 'string') {
      switch (filterCompareCondition) {
        case 'contains': {
          const containsFilteredData = data.filter((item) =>
            item[column].toLowerCase().includes(filterCompareValue.toLowerCase())
          );
          setTableRows(containsFilteredData);
          break;
        }

        case 'exclude': {
          const excludeFilteredData = data.filter(
            (item) => !item[column].toLowerCase().includes(filterCompareValue.toLowerCase())
          );
          setTableRows(excludeFilteredData);
          break;
        }

        case 'starts with': {
          const startsWithFilteredData = data.filter((item) =>
            item[column].toLowerCase().startsWith(filterCompareValue.toLowerCase())
          );
          setTableRows(startsWithFilteredData);
          break;
        }

        case 'ends with': {
          const endsWithFilteredData = data.filter((item) =>
            item[column].toLowerCase().endsWith(filterCompareValue.toLowerCase())
          );
          setTableRows(endsWithFilteredData);
          break;
        }

        case 'exact match': {
          const equalsFilteredData = data.filter(
            (item) => item[column].toLowerCase() === filterCompareValue.toLowerCase()
          );
          setTableRows(equalsFilteredData);
          break;
        }

        case 'not an exact': {
          const notEqualsFilteredData = data.filter(
            (item) => item[column].toLowerCase() !== filterCompareValue.toLowerCase()
          );
          setTableRows(notEqualsFilteredData);
          break;
        }

        default:
          break;
      }

      handleColInlineClose();
      handleFilterModalClose();
    } else if (colType === 'number') {
      switch (filterCompareCondition) {
        case 'greater than': {
          const greaterThanFilteredData = data.filter(
            (item) => parseFloat(item[column]) > parseFloat(filterCompareValue)
          );
          setTableRows(greaterThanFilteredData);
          break;
        }

        case 'less than': {
          const lessThanFilteredData = data.filter(
            (item) => parseFloat(item[column]) < parseFloat(filterCompareValue)
          );
          setTableRows(lessThanFilteredData);
          break;
        }

        case 'equals': {
          const equalsFilteredData = data.filter(
            (item) => parseFloat(item[column]) === parseFloat(filterCompareValue)
          );
          setTableRows(equalsFilteredData);
          break;
        }

        case 'not equals': {
          const notEqualsFilteredData = data.filter(
            (item) => parseFloat(item[column]) !== parseFloat(filterCompareValue)
          );
          setTableRows(notEqualsFilteredData);
          break;
        }

        case 'range': {
          const rangeFilteredData = data.filter((item) => {
            const numericValue = parseFloat(item[column]);
            return numericValue >= filterCompareMinValue && numericValue <= filterCompareMaxValue;
          });

          // console.log('range', rangeFilteredData);
          setTableRows(rangeFilteredData);
          break;
        }

        default:
          break;
      }
      handleColInlineClose();
      handleFilterModalClose();
    }
  };

  return (
    <>
      <StyledTableCell
        ref={tableColRef}
        draggable
        onDragStart={(e) => handleColumnDragStart(e, colIndex)}
        onDragOver={(e) => handleColumnDragOver(e, colIndex)}
        onDrop={(e) => handleColumnDrop(e, colIndex)}
        className="table-th"
        onMouseDown={handleMouseDown}
        style={{
          minWidth: `${colWidth}px`,
          cursor: gridCursor
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '4px'
          }}
        >
          <span>{column}</span>
          {column.toLowerCase() !== 'select' && (
            <>
              <IconButton
                sx={{ p: 0 }}
                aria-controls={openColInlineMenu ? 'col-inline-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openColInlineMenu ? 'true' : undefined}
                onClick={handleColInlineOpen}
                style={{ position: 'relative' }}
              >
                <MoreVert
                  className="col-menu"
                  style={{ height: '16px', padding: '0px' }}
                  sx={{ '&:hover': { opacity: 1 } }}
                />
              </IconButton>
              <Menu
                id="col-inline-menu"
                anchorEl={anchorE3}
                open={openColInlineMenu}
                onClose={handleColInlineClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                getcontentanchorel={null}
              >
                <MenuItem
                  onClick={(evt) => handleSortAsc(evt, column)}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>Sort By Asc : </span>
                  <ArrowUpward style={{ height: '16px' }} />
                </MenuItem>
                <MenuItem
                  onClick={(evt) => handleSortDesc(evt, column)}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>Sort By Desc : </span>
                  <ArrowDownward style={{ height: '16px' }} />
                </MenuItem>
                <MenuItem
                  onClick={handleFilterModalOpen}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>Quick Filter : </span>
                  <FilterList style={{ height: '16px' }} />
                </MenuItem>
                <Divider orientation="horizontal" flexItem />
                <MenuItem
                  onClick={(evt) => handleHideColumn(evt, column)}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>Hide Column</span>
                  <VisibilityOff style={{ height: '16px' }} />
                </MenuItem>
              </Menu>
            </>
          )}
        </span>
      </StyledTableCell>
      {/* Filter Modal */}
      <Modal
        open={openFilterModal}
        onClose={handleFilterModalClose}
        aria-labelledby="modal-filter"
        aria-describedby="modal-filter"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            borderRadius: 1,
            p: 4
          }}
        >
          <Typography
            sx={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '2rem' }}
            variant="h6"
          >
            Column Filter Menu
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '20px'
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={tableColumns.map((item) => item.name)}
              sx={{ width: 300 }}
              value={column}
              size="small"
              readOnly
              renderInput={(params) => <TextField variant="filled" {...params} label="Column" />}
            />
            <Divider orientation="vertical" flexItem />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={handleFilterConditionChange}
              options={colFilterConditions[colType]}
              sx={{ width: 300 }}
              value={filterCompareCondition}
              size="small"
              renderInput={(params) => <TextField variant="filled" {...params} label="Condition" />}
            />
            <Divider orientation="vertical" flexItem />
            {filterCompareCondition !== 'range' ? (
              <TextField
                value={filterCompareValue}
                onChange={(e) => setFilterCompareValue(e.target.value)}
                variant="filled"
                label="Value"
              />
            ) : (
              <>
                <TextField
                  value={filterCompareMinValue}
                  onChange={(e) => setFilterCompareMinValue(e.target.value)}
                  variant="filled"
                  label="Min"
                />
                <TextField
                  value={filterCompareMaxValue}
                  onChange={(e) => setFilterCompareMaxValue(e.target.value)}
                  variant="filled"
                  label="Max"
                />
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2rem',
              gap: '10px'
            }}
          >
            <Button
              startIcon={<FilterAltOff />}
              color="warning"
              variant="contained"
              onClick={handleFilterColumnReset}
            >
              Reset
            </Button>
            <Button
              disabled={
                filterCompareCondition !== 'range'
                  ? !(filterCompareCondition && filterCompareValue)
                  : !(filterCompareCondition && filterCompareMinValue && filterCompareMaxValue)
              }
              startIcon={<AutoAwesome />}
              sx={{ border: '1px solid black' }}
              variant="contained"
              onClick={handleFilterColumn}
            >
              Apply Filter
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function ResizableRow({
  rows,
  rowIndex,
  tableRows,
  setTableRows,
  draggedRowIndex,
  setDraggedRowIndex,
  gridCursor,
  setGridCursor,
  rowDataObject
}) {
  const [height, setHeight] = useState(11); // Initial height
  // const [rowCursor, setRowCursor] = useState('');

  const handleMouseDown = (mouseDownEvent) => {
    if (!mouseDownEvent.shiftKey) {
      // console.log('shift key');
      return;
    }

    setGridCursor('row-resize');
    const startHeight = height;
    const startPosition = mouseDownEvent.clientY;

    const onMouseMove = (mouseMoveEvent) => {
      const newHeight = startHeight - startPosition + mouseMoveEvent.clientY;
      setHeight(newHeight > 0 ? newHeight : 0);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      setGridCursor('text');
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleRowDragStart = (e, index) => {
    if (!e.altKey) {
      // console.log('alt key');
      return;
    }
    setDraggedRowIndex(index);
    // setGridCursor('all-scroll');
    // console.log('rowdrag start1', index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleRowDragOver = (e, index) => {
    if (!e.altKey) {
      // console.log('alt key');
      return;
    }
    e.preventDefault();
  };

  const handleRowDrop = (e, index) => {
    if (!e.altKey) {
      // console.log('alt key');
      return;
    }
    e.preventDefault();
    const rowToMove = tableRows[draggedRowIndex];
    const newData = [...tableRows];
    newData.splice(draggedRowIndex, 1);
    newData.splice(index, 0, rowToMove);
    setDraggedRowIndex(null);
    setTableRows(newData);
    // setGridCursor('text');
  };

  const [rowDetailModalData, setRowDetailModalData] = useState({ open: false, detail: [] });

  const handleRowDetailModalOpen = () => {
    const arrayOfObjects = Object.entries(rowDataObject).map(([key, value]) => ({ [key]: value }));
    setRowDetailModalData({ open: true, detail: [...arrayOfObjects] });
  };

  const handleRowDetailModalClose = () => {
    // const arrayOfObjects = Object.entries(rowDataObject).map(([key, value]) => ({ [key]: value }));
    setRowDetailModalData((prev) => ({ ...prev, open: false }));
  };

  return (
    <StyledTableRow
      onDoubleClick={handleRowDetailModalOpen}
      draggable
      onDragStart={(e) => handleRowDragStart(e, rowIndex)}
      onDragOver={(e) => handleRowDragOver(e, rowIndex)}
      onDrop={(e) => handleRowDrop(e, rowIndex)}
      className="table-tr"
      style={{
        height: `${height}px`,
        cursor: gridCursor
      }}
      onMouseDown={handleMouseDown}
    >
      {rows}
      <Modal open={rowDetailModalData.open} onClose={handleRowDetailModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            borderRadius: 1,
            p: 3
          }}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              textAlign: 'center',
              fontWeight: 900,
              marginBottom: 2,
              fontSize: '1.1rem'
            }}
          >
            Row Data
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {rowDetailModalData.detail.map((obj, index) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <span style={{ fontWeight: 'bold' }}>{key}:</span>
                  <span>{value}</span>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Modal>
    </StyledTableRow>
  );
}

function NavTabs({ tabValue, setTabValue, tabNames, setTabNames }) {
  const { GetFieldsNames } = KnowledgeGraphService;
  const { experimentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    console.log(
      'setTabValue',
      tabNames.find((item) => item.tableName === newValue)
    );
    setTabValue(tabNames.find((item) => item.tableName === newValue));
  };

  useEffect(() => {
    (async () => {
      const getTablesList = await GetFieldsNames('visual/manage_app_tables');

      if (getTablesList.status === 'success' && getTablesList.code === 200) {
        const filteredTableNames = getTablesList.data.filter(
          (item) => Number(item.project_id) === Number(experimentId)
        );

        console.log('getTablesList', filteredTableNames);

        const tabName = [
          ...new Set(
            filteredTableNames.map((item) => {
              return { tableName: item.table_name, tableId: item.dataset_id };
            })
          )
        ];

        setTabNames([...tabName]);
        setTabValue(tabName[0]);
        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: { key: 'exitsTableList', value: filteredTableNames }
        });
      } else {
        enqueueSnackbar(getTablesList?.message, {
          variant: 'error',
          autoHideDuration: 2000
        });
      }
    })();
  }, []);

  return (
    <Box sx={{ width: '100%', background: '#2a2a2a' }}>
      {tabNames && tabValue && (
        <Tabs
          value={tabValue.tableName}
          onChange={handleChange}
          sx={{
            '& .MuiTab-root': { color: '#bfbfbf' },
            '& .Mui-selected': { color: 'white' },
            '& .MuiTabs-indicator': { backgroundColor: 'white' },
            '& .MuiButtonBase-root': { textTransform: 'none !important' }
          }}
        >
          {tabNames.map((item) => {
            return <Tab key={item.tableName} value={item.tableName} label={item.tableName} />;
          })}
        </Tabs>
      )}
    </Box>
  );
}

const initialQuery = { rules: [] };

function CustomDataGridMenu({
  tabValue,
  tableRef,
  tableColumns,
  setTableColumns,
  tableRows,
  rowDataArchive,
  tableColumnArchive,
  setTableRows
}) {
  const [rowSpacing, setRowSpacing] = useState('medium');

  const [openShortcutKeys, setOpenShortcutKeys] = useState(false);
  const handleShortcutKeysOpen = () => setOpenShortcutKeys(true);
  const handleShortcutKeysClose = () => setOpenShortcutKeys(false);

  const [openGlobalFilterDialog, setOpenGlobalFilterDialog] = useState(false);
  const handleGlobalFilterDialogOpen = () => setOpenGlobalFilterDialog(true);
  const handleGlobalFilterDialogClose = () => setOpenGlobalFilterDialog(false);

  const [openSqlFilterDialog, setOpenSqlFilterDialog] = useState(false);
  const handleSqlFilterDialogOpen = () => setOpenSqlFilterDialog(true);
  const handleSqlFilterDialogClose = () => setOpenSqlFilterDialog(false);
  const [datasetColNames, setDatasetColNames] = useState([]);

  const handleRowSpacing = (event, spaceValue) => {
    setRowSpacing(spaceValue);

    switch (spaceValue) {
      case 'small':
        document.documentElement.style.setProperty('--table-row-font', '9.5px');
        document.documentElement.style.setProperty('--table-row-padding', '2.5px');
        break;
      case 'medium':
        document.documentElement.style.setProperty('--table-row-font', '11.5px');
        document.documentElement.style.setProperty('--table-row-padding', '4px');
        break;
      case 'large':
        document.documentElement.style.setProperty('--table-row-font', '14px');
        document.documentElement.style.setProperty('--table-row-padding', '8px');
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (tabValue.tableId) {
      (async () => {
        try {
          const _datasetDetails = await DatasetDetails(Number(tabValue.tableId));
          if (_datasetDetails.status === 'success') {
            setDatasetColNames(_datasetDetails.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [tabValue.tableId]);

  const [anchorEl, setAnchorEl] = useState(null);
  const openExportMenu = Boolean(anchorEl);
  const handleExportOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const exportToExcel = () => {
    const table = document.getElementById('customDatagridTable');

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${tabValue.tableName}.xlsx`);
    handleExportClose();
  };

  const exportToPdf = () => {
    if (tableRef) {
      const printWindow = window.open('', '_blank');
      const tableHtml = tableRef.current.outerHTML;
      const style = `
      <style>
      .table-container {
        border-spacing: 0;
        border-collapse: collapse;
      }
      .table-td {
        border: 1px solid black;
      }
      .table-th {
        border: 1px solid black;
        padding: 8px;
        background-color: #c6c8c6;
        color: #151515;
        text-align: left;
      } 
      </style>`;

      printWindow.document.write(
        `<html><head>
        <title>Print</title>
        ${style}</head><body>`
      );
      printWindow.document.write('<pre id="printTable">' + tableHtml + '</pre>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    }
    handleExportClose();
  };

  // Column Select Menu
  const [selectedLabels, setSelectedLabels] = useState([]);

  const [anchorE2, setAnchorE2] = useState(null);
  const openColSelectMenu = Boolean(anchorE2);

  const handleColSelectOpen = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleColSelectClose = () => {
    setAnchorE2(null);
  };

  const handleCheckBoxChange = (event) => {
    // console.log('nagar', event);
    const colToFind = event.target.labels[0].innerText;

    if (event.target.checked) {
      const foundObject = tableColumnArchive.find((label) => label.name === colToFind);
      // console.log('Selected Labels: foundObject', foundObject, colToFind);
      setSelectedLabels((prevSelected) => [...prevSelected, foundObject]);
    } else {
      setSelectedLabels((prevSelected) => prevSelected.filter((label) => label.name !== colToFind));
    }
  };

  const handleApplyClick = () => {
    // console.log('Selected Labels:', selectedLabels);
    setTableColumns(selectedLabels);
  };

  useEffect(() => {
    if (tableColumns.length > 0) {
      setSelectedLabels([...tableColumns]);
      // console.log('coldrag tr', tableColumns);
    }
  }, [tableColumns]);

  const ruleEngineOperators = [
    { name: 'contains', label: 'Contains', type: 'string' },
    { name: 'exclude', label: 'Exclude', type: 'string' },
    { name: 'starts_with', label: 'Starts With', type: 'string' },
    { name: 'ends_with', label: 'Ends With', type: 'string' },
    { name: 'exact_match', label: 'Exact Match', type: 'string' },
    { name: 'not_an_exact', label: 'Not An Exact', type: 'string' },
    { name: 'greater_than', label: '>', type: 'number' },
    { name: 'less_than', label: '<', type: 'number' },
    { name: 'equals', label: '=', type: 'number' },
    { name: 'not_equals', label: '!=', type: 'number' }
  ];

  const [query, setQuery] = useState(initialQuery);
  const [tableValues, setTableValues] = useState([]);
  const [gridSearchValue, setGridSearchValue] = useState(null);

  useEffect(() => {
    setQuery(initialQuery);
  }, [tabValue.tableName]);

  useEffect(() => {
    if (gridSearchValue) {
      const filteredData = rowDataArchive.filter((item) => {
        return Object.values(item).some(
          (val) =>
            // String(val).toLowerCase().includes(gridSearchValue.toLowerCase())
            String(val).toLowerCase() === gridSearchValue.toLowerCase()
        );
      });
      // console.log('search', filteredData);
      setTableRows(filteredData);
    } else {
      setTableRows(rowDataArchive);
    }
  }, [gridSearchValue]);

  useEffect(() => {
    // For Global Search Box
    if (rowDataArchive.length > 0) {
      const tbValues = rowDataArchive.map((item) => Object.values(item).map((elm) => String(elm)));
      setTableValues(_shuffle([...new Set(tbValues.flat(1))]));
      // console.log('rows', rowDataArchive);
    }
  }, [rowDataArchive]);

  function arraysAreEqual(arr1, arr2) {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => {
        if (Array.isArray(value) && Array.isArray(arr2[index])) {
          return arraysAreEqual(value, arr2[index]);
        }
        return value === arr2[index];
      })
    );
  }

  const freezeIconRef = useRef(null);
  const handleColumnFreeze = () => {
    const styles = getComputedStyle(document.documentElement);
    const positionValue = styles.getPropertyValue('--freeze-position');

    if (positionValue === 'relative') {
      freezeIconRef.current.style.color = '#e63221';
      document.documentElement.style.setProperty('--freeze-position', 'sticky');
      document.documentElement.style.setProperty('--freeze-row-color', '#76d4e6');
      document.documentElement.style.setProperty('--freeze-col-color', '#76d4e6');
      document.documentElement.style.setProperty('--freeze-col-cell-color', 'black');
    } else {
      freezeIconRef.current.style.color = '#667280';
      document.documentElement.style.setProperty('--freeze-position', 'relative');
      document.documentElement.style.setProperty('--freeze-row-color', 'transparent');
      document.documentElement.style.setProperty('--freeze-col-color', 'black');
      document.documentElement.style.setProperty('--freeze-col-cell-color', 'white');
    }
  };

  return (
    <Box sx={{ width: '90%', background: '#d6d8d6', margin: '10px auto', borderRadius: 1 }}>
      <Box
        sx={{
          color: 'black',
          padding: '10px 15px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          gap: '15px'
        }}
      >
        {/* Search & Keyboard Shortcuts */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            gap: '5px',
            margin: 'auto 0 auto auto',
            order: '9999'
          }}
        >
          {/* <Divider orientation="vertical" flexItem /> */}

          {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
          <Autocomplete
            disablePortal
            clearOnBlur
            options={tableValues}
            sx={{
              width: 150,
              '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                fontSize: '11px'
              }
            }}
            value={gridSearchValue}
            onChange={(_, newValue) => setGridSearchValue(newValue)}
            size="small"
            renderInput={(params) => (
              <TextField
                sx={{ '&>label': { fontSize: '0.7rem' } }}
                variant="outlined"
                {...params}
                label="Search"
              />
            )}
          />
          {/* </Box> */}

          <Divider orientation="vertical" flexItem />

          <Tooltip title="Keyboard Shortcuts">
            <IconButton onClick={handleShortcutKeysOpen}>
              <Keyboard style={{ height: '22px' }} />
            </IconButton>
          </Tooltip>

          <Modal
            open={openShortcutKeys}
            onClose={handleShortcutKeysClose}
            aria-labelledby="modal-keyboard-shortcuts"
            aria-describedby="modal-keyboard-shortcuts"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                border: '1px solid #000',
                boxShadow: 24,
                borderRadius: 1,
                p: 4
              }}
            >
              <Typography
                sx={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '2rem' }}
                variant="h6"
              >
                Keyboard Shortcuts
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '20px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '20px'
                  }}
                >
                  <Typography variant="button" component="span">
                    Drag & Drop:
                  </Typography>
                  <Box component="span">
                    <code className="shortcut-keys">Alt</code> +{' '}
                    <code className="shortcut-keys">Left Click</code> +{' '}
                    <code className="shortcut-keys">Drag</code>
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '20px'
                    }}
                  >
                    <Typography variant="button" component="span">
                      Adjust Row Size:
                    </Typography>
                    <Box component="span">
                      <code className="shortcut-keys">Shift</code> +{' '}
                      <code className="shortcut-keys">Left Click</code> +{' '}
                      <code className="shortcut-keys">Drag</code>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>

        {/* Other Items */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            gap: '5px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Font Size:</Typography>
          <ToggleButtonGroup
            value={rowSpacing}
            onChange={handleRowSpacing}
            exclusive
            size="small"
            sx={{ '& > button > .MuiSvgIcon-root': { height: '14px' } }}
          >
            <ToggleButton value="small">
              <TextIncrease style={{ fontSize: '11px' }} />
            </ToggleButton>
            <ToggleButton value="medium">
              <TextIncrease style={{ fontSize: '15px' }} />
            </ToggleButton>
            <ToggleButton value="large">
              <TextIncrease style={{ fontSize: '18px' }} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            gap: '5px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Export:</Typography>
          <IconButton
            id="export-button"
            aria-controls={openExportMenu ? 'export-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openExportMenu ? 'true' : undefined}
            onClick={handleExportOpen}
          >
            <IosShare style={{ height: '16px' }} />
          </IconButton>
          <Menu
            id="export-menu"
            anchorEl={anchorEl}
            open={openExportMenu}
            onClose={handleExportClose}
            MenuListProps={{
              'aria-labelledby': 'export-button'
            }}
          >
            <MenuItem
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={exportToExcel}
            >
              <span>Excel : </span>
              <GridOn style={{ height: '16px' }} />
            </MenuItem>
            <MenuItem
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={exportToPdf}
            >
              <span>PDF : </span>
              <PictureAsPdf style={{ height: '16px' }} />
            </MenuItem>
          </Menu>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            gap: '5px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Column Select:</Typography>
          <IconButton
            id="col-select-button"
            aria-controls={openColSelectMenu ? 'col-select-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openColSelectMenu ? 'true' : undefined}
            onClick={handleColSelectOpen}
          >
            <ViewModule style={{ height: '16px' }} />
          </IconButton>
          <Menu
            id="col-select-menu"
            anchorEl={anchorE2}
            open={openColSelectMenu}
            onClose={handleColSelectClose}
            MenuListProps={{
              'aria-labelledby': 'col-select-button'
            }}
          >
            {tableColumnArchive.length > 0 && (
              <Box sx={{ padding: '0px 10px', display: 'flex', flexDirection: 'column' }}>
                <FormGroup>
                  {tableColumnArchive.map((col) => {
                    return (
                      <FormControlLabel
                        key={col.name}
                        onChange={handleCheckBoxChange}
                        control={
                          <Checkbox
                            size="small"
                            checked={selectedLabels.some((label) => label.name === col.name)}
                          />
                        }
                        label={col.name}
                      />
                    );
                  })}
                </FormGroup>
                <Button
                  sx={{ marginLeft: 'auto', marginRight: '0px' }}
                  size="small"
                  variant="contained"
                  startIcon={<Save />}
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>
              </Box>
            )}
          </Menu>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            gap: '5px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Query Builder:</Typography>
          <ButtonGroup size="small" variant="text">
            <Button sx={{ color: '#667280' }} onClick={handleGlobalFilterDialogOpen}>
              <FilterAlt style={{ height: '16px' }} />
            </Button>
            {!arraysAreEqual(rowDataArchive, tableRows) && (
              <Tooltip title="Reset Filter">
                <Button color="error" onClick={() => setTableRows(rowDataArchive)}>
                  <FilterAltOff style={{ height: '16px' }} />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
          <Dialog
            open={openGlobalFilterDialog}
            onClose={handleGlobalFilterDialogClose}
            aria-labelledby="dialog-global-filter"
            aria-describedby="dialog-global-filter"
            sx={{ '& > .MuiDialog-container>div': { maxWidth: '70vw', width: '70vw' } }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>Query Builder</DialogTitle>
            <IconButton
              onClick={handleGlobalFilterDialogClose}
              size="small"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#fff',
                background: 'red',
                borderRadius: 1,
                '&:hover': { filter: 'contrast(0.8)', color: '#fff', background: 'red' }
              }}
            >
              <Close />
            </IconButton>
            <DatagridRuleEngine
              rowDataArchive={rowDataArchive}
              tableRows={tableRows}
              initialQuery={initialQuery}
              query={query}
              setQuery={setQuery}
              setTableRows={setTableRows}
              colsWithTypes={tableColumns}
              operators={ruleEngineOperators}
              handleGlobalFilterDialogOpen={handleGlobalFilterDialogOpen}
              handleGlobalFilterDialogClose={handleGlobalFilterDialogClose}
            />
          </Dialog>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            gap: '5px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>SQL Editor:</Typography>
          <ButtonGroup size="small" variant="text">
            <Button sx={{ color: '#667280' }} onClick={handleSqlFilterDialogOpen}>
              <FilterAlt style={{ height: '16px' }} />
            </Button>
            {!arraysAreEqual(rowDataArchive, tableRows) && (
              <Tooltip title="Reset Filter">
                <Button color="error" onClick={() => setTableRows(rowDataArchive)}>
                  <FilterAltOff style={{ height: '16px' }} />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
          <Dialog
            open={openSqlFilterDialog}
            onClose={handleSqlFilterDialogClose}
            aria-labelledby="dialog-Sql-filter"
            aria-describedby="dialog-Sql-filter"
            sx={{ '& > .MuiDialog-container>div': { maxWidth: '70vw', width: '70vw' } }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>SQL Editor</DialogTitle>
            <IconButton
              onClick={handleSqlFilterDialogClose}
              size="small"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#fff',
                background: 'red',
                borderRadius: 1,
                '&:hover': { filter: 'contrast(0.8)', color: '#fff', background: 'red' }
              }}
            >
              <Close />
            </IconButton>
            <SqlFilterDialog
              setOpenSqlFilterDialog={setOpenSqlFilterDialog}
              datasetColNames={datasetColNames}
              currentTableName={tabValue.tableName}
            />
          </Dialog>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            gap: '5px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Freeze:</Typography>
          <ButtonGroup size="small" variant="text">
            <Button ref={freezeIconRef} sx={{ color: '#667280' }} onClick={handleColumnFreeze}>
              <AcUnit style={{ height: '16px' }} />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Box>
  );
}

export function SqlFilterDialog({ setOpenSqlFilterDialog, datasetColNames, currentTableName }) {
  const { enqueueSnackbar } = useSnackbar();
  const { experimentId } = useParams();

  const [sqlValue, setSqlValue] = useState('');
  const [ruleName, setRuleName] = useState('');
  const [savedSqlRuleList, setSavedSqlRuleList] = useState({ value: null, list: [] });
  const [queryDatagridResult, setQueryDatagridResult] = useState([]);
  const [tablePage, setTablePage] = useState({
    page_no: 0,
    limit: 50,
    total_pages: 0,
    first_time_load: true
  });

  // Function to check if the input is a subsequence of the target string
  function isSubsequence(target, input) {
    let tIndex = 0;
    let iIndex = 0;

    while (tIndex < target.length && iIndex < input.length) {
      if (target[tIndex].toUpperCase() === input[iIndex].toUpperCase()) {
        iIndex++;
      }
      tIndex++;
    }
    return iIndex === input.length;
  }

  // Custom completion provider with partial match functionality
  function sqlCompletionProvider(context) {
    const word = context.matchBefore(/\w*/);
    if (word.from === word.to) return null; // No match or empty string

    // Custom completions
    const keywordSuggestions = sqlAutocompleteSuggestions.keywords.map((keyword) => ({
      label: keyword,
      type: 'keyword',
      detail: 'SQL Keyword'
    }));

    const functionSuggestions = sqlAutocompleteSuggestions.functions.map((func) => ({
      label: func,
      type: 'function',
      detail: 'SQL Function'
    }));

    const columnSuggestions = datasetColNames.map((item) => ({
      label: item.column_name,
      type: 'enum',
      detail: 'Column Name'
    }));

    const currTable = {
      label: currentTableName,
      type: 'namespace',
      detail: 'Table Name'
    };

    // Filter suggestions using subsequence matching
    const allSuggestions = [
      ...keywordSuggestions,
      ...functionSuggestions,
      ...columnSuggestions,
      currTable
    ];
    const filteredSuggestions = allSuggestions.filter((item) =>
      isSubsequence(item.label, word.text)
    );

    return {
      from: word.from,
      to: word.to,
      options: filteredSuggestions
    };
  }

  const onSqlChange = useCallback((value) => {
    setSqlValue(value);
  }, []);

  const handleQuerySave = async (_, _type) => {
    try {
      const authToken = userDataFromLocal()?.authToken;
      const payload = {
        sql_query: sqlValue.replace(/(\r\n|\n|\r)/g, ''),
        rule_name: ruleName,
        project_id: experimentId,
        created_by: userDataFromLocal()?.userName,
        creator_user_id: userDataFromLocal()?.userID
      };

      const _saveData = await SaveSqlQueryApi(payload, authToken);
      if (_saveData.status === 'success') {
        if (_type === 'save&apply') {
          handleQueryExecution(_saveData.data.rule_id, 'newID');
          getSavedRuleList();
          return;
        }

        setOpenSqlFilterDialog(false);
        getSavedRuleList();
        enqueueSnackbar(_saveData?.message, {
          variant: 'success',
          autoHideDuration: 2000
        });
      } else {
        enqueueSnackbar(_saveData?.message, {
          variant: 'error',
          autoHideDuration: 2000
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQueryExecution = async (rule_id, _type) => {
    try {
      const ruleID = rule_id ? rule_id : savedSqlRuleList.value.rule_id;
      const authToken = userDataFromLocal()?.authToken;

      const startingRow = tablePage.page_no * tablePage.limit;

      let queryParam = `rule_id=${ruleID}`;

      switch (_type) {
        case 'append':
          queryParam = `rule_id=${ruleID}&offset=${startingRow}&limit=${tablePage.limit}`;
          break;

        case 'newID':
          setTablePage({
            page_no: 0,
            limit: 50,
            total_pages: 0,
            first_time_load: true
          });
          break;

        default:
          break;
      }

      const _saveData = await SqlRuleApply(queryParam, authToken);

      if (_saveData.status === 'success') {
        const _rows = _saveData.data.map((item, index) => ({ ...item, id: index }));
        setQueryDatagridResult(_rows);
        if (_type !== 'append') {
          enqueueSnackbar(_saveData?.message, {
            variant: 'success',
            autoHideDuration: 2000
          });
        }

        if (tablePage.first_time_load || _type === 'newID') {
          setTablePage((prev) => ({
            ...prev,
            first_time_load: false,
            total_pages: _saveData.total_count
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSavedRuleList = async () => {
    try {
      const params = `rule_type=data_grid&project_id=${experimentId}&user_id=${
        userDataFromLocal()?.userID
      }`;
      console.log('paramsparams', params);
      const _ruleList = await SavedSqlRuleList(params);
      if (_ruleList.status === 'success') {
        setSavedSqlRuleList((prev) => ({
          ...prev,
          list: _ruleList.data.map((item) => ({
            query: item.query,
            rule_id: item.rule_id,
            rule_name: item.rule_name
          }))
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSavedRuleList();
  }, []);

  const handleChangePage = (event, newPage) => {
    setTablePage((prev) => ({ ...prev, page_no: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setTablePage((prev) => ({ ...prev, limit: +event.target.value, page_no: 0 }));
  };

  useEffect(() => {
    // console.log('tablePage', tablePage);
    if (tablePage && !tablePage.first_time_load) {
      handleQueryExecution(undefined, 'append');
    }
  }, [tablePage]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pr: 2,
          pb: 1,
          pt: 1
        }}
      >
        <Autocomplete
          size="small"
          disablePortal
          value={savedSqlRuleList.value}
          onChange={(e, _newVal) => {
            setSavedSqlRuleList((prev) => ({ ...prev, value: _newVal }));
            const filtrObj = savedSqlRuleList.list.find(
              (item) => item.rule_name === _newVal.rule_name
            );
            if (String(filtrObj?.query).includes('data_integration. ')) {
              setSqlValue(String(filtrObj?.query).replace('data_integration. ', ''));
            } else {
              setSqlValue(filtrObj?.query);
            }
          }}
          options={savedSqlRuleList.list}
          getOptionLabel={(option) => option.rule_name}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField placeholder="Apply saved rule" {...params} />}
        />
        <TextField
          size="small"
          placeholder="Write Query Name"
          value={ruleName}
          onChange={(e) => setRuleName(e.target.value)}
        />
        {/* <Button size="small" variant="contained">
          Save Query
        </Button> */}
      </Box>
      <CodeMirror
        placeholder={'Write your SQL query here.'}
        value={sqlValue}
        height="120px"
        // theme={oneDark}
        extensions={[
          sql({ dialect: PostgreSQL }), // Default SQL completion
          autocompletion({
            override: [sqlCompletionProvider] // Add custom completions
          })
        ]}
        onChange={onSqlChange}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: 2,
          gap: '15px'
        }}
      >
        <Button
          disabled={savedSqlRuleList.value === null}
          onClick={() => handleQueryExecution(undefined, 'newID')}
          size="small"
          variant="contained"
        >
          Apply
        </Button>
        <Button
          disabled={ruleName === '' || sqlValue === ''}
          onClick={handleQuerySave}
          size="small"
          variant="contained"
        >
          Save Query
        </Button>
        <Button
          color="success"
          disabled={ruleName === '' || sqlValue === ''}
          onClick={() => handleQuerySave(undefined, 'save&apply')}
          size="small"
          variant="contained"
        >
          Save & Apply Query
        </Button>
      </Box>
      {queryDatagridResult.length > 0 && (
        <Scrollbar>
          <Box
            className="umgdataGridBox umgglobalDatGridHolder"
            style={{
              // height: '50vh',
              width: '100%'
            }}
          >
            <DataGrid
              autoHeight
              headerHeight={40}
              rowHeight={40}
              rows={queryDatagridResult}
              columns={Object.keys(queryDatagridResult[0]).map((item) => ({
                field: item,
                headerName: item,
                flex: 1
              }))}
              pageSize={100}
              hideFooter
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false
                  }
                }
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <TablePagination
              sx={{ '& .MuiToolbar-root': { minHeight: 0 } }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={tablePage.total_pages}
              rowsPerPage={tablePage.limit}
              page={tablePage.page_no}
              onPageChange={handleChangePage}
              labelRowsPerPage={'Page Limit'}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Scrollbar>
      )}
    </Box>
  );
}
