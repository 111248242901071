import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  Close,
  CreateNewFolder,
  Search,
  Sort,
  WifiTethering
} from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  AddCircleOutline,
  Ballot,
  CalendarMonth,
  Delete,
  DesignServices,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  PendingActions,
  Storage,
  SwipeDownAlt,
  SwipeUpAlt,
  WifiTetheringError
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { FiEdit, FiLoader } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
// components
import Page from '../Page';

// services
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import { userDataFromLocal } from '../../../utils/getUserDetails';

// import { userDataFromLocal } from '../../../utils/getUserDetails';
import { fDate, fDateTime } from '../../../utils/formatTime';
import { camlecaseAndUnderScore } from '../../../utils/camleCaseAndUScroe';
import FunctionButtons from './DataGridButtons';
import TopNavigationBar from '../TopNavigationBar';
// import LeftNavigationBar from '../LeftNavigationBar';
import KnowledgeGraphListDialog from './KnowledgeGraphListDialog';
import ApiErrorDialog from './ApiErrorDialog';
import LoaderBlur from './LoaderBlur';

import ValidationMsgOnly from '../../global/ValidationMsgOnly';

/**
 *
 * @returns react-element
 */

// const socketWorker = new Worker(new URL('./socketWorker.js', import.meta.url));

function KnowledgeGraphList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ProjectList, GetApiByUrl, DeleteApiByUrl, EditProject, SharedProjectList } =
    KnowledgeGraphService;

  const { enqueueSnackbar } = useSnackbar();

  // const [anchorEl, setAnchorEl] = useState(null); // states for more icon pop over
  // const [currentSelection, setCurrentSelection] = useState(null);
  // const [currentSelectionName, setCurrentSelectionName] = useState(null);

  const [modelPopup, setModelPopup] = useState(null);
  const [allModelData, setAllModelData] = useState({ show: false, data: [] });
  const [allModelColumn, setAllModelColumn] = useState([]);

  const [deleteModelPopup, setDeleteModelPopup] = useState(null);
  const [deleteModelData, setDeleteModelData] = useState({ show: false, data: [] });
  const [deleteModelColumn, setDeleteModelColumn] = useState([]);
  const [confirmDeletePopUp, setConfirmDeletePopUp] = useState(false);

  const { experimentId, experimentName } = useParams;
  const urlInfo = useLocation();

  // const [activeList, setActiveList] = useState(3);
  const [mutateList, setMutateList] = useState(); // for mutate state
  const [showDataArchive, setShowDataArchive] = useState([]); // states for showing data in card item
  const [showData, setShowData] = useState([]); // states for showing data in card item

  const [deletableArray, setDeletableArray] = useState([]);
  const [loader, setLoader] = useState(true);
  const [deleteProjectBtnLoader, setDeleteProjectBtnLoader] = useState(false);
  const [editProjectName, setEditProjectName] = useState({
    dialogOpen: false,
    projectID: '',
    projectName: '',
    newProjectName: ''
  });
  const [socketListDialog, setSocketListDialog] = useState({
    open: false,
    socketData: [],
    connectionStatus: false
  });
  const [socketData, setSocketData] = useState([]);
  const [reconnectSocketConnection, setReconnectSocketConnection] = useState(new Date());

  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };

  // for deleting the card
  const handleDelete = async (e, itemId) => {
    const authToken = userDataFromLocal();
    const credential = {
      project_ids: itemId,
      creator_user_id: authToken.userID
    };

    const deleteCard = await DeleteApiByUrl('/visual/manage_projects', credential, authToken.token);
    console.log('deleteCard', deleteCard);

    // const deleteProjects = showData?.filter((item) => itemId.includes(item.project_id));

    // if (deleteProjects?.length > 0) {
    //   deleteProjects?.forEach((project) => {
    //     setDeleteProjectBtnLoader(false);
    //     enqueueSnackbar(`${project?.project_name} deleted successfully.`, {
    //       variant: deleteCard.status,
    //       autoHideDuration: 2000
    //     });
    //   });
    // }

    if (deleteCard.status === 'success') {
      const deleteProjects = showData?.filter((item) => itemId.includes(item.project_id));

      if (deleteProjects?.length > 0) {
        deleteProjects?.forEach((project) => {
          setDeleteProjectBtnLoader(false);
          enqueueSnackbar(`${project?.project_name} deleted successfully.`, {
            variant: deleteCard.status,
            autoHideDuration: 2000
          });
        });
      }

      setDeletableArray([]);
      setMutateList(new Date());
      setConfirmDeletePopUp(false);
      setDeleteModelPopup(false);
    } else if (deleteCard.code === 403) {
      setDeletableArray([]);
      setDeleteProjectBtnLoader(false);
      // setMutateList(new Date());
      setConfirmDeletePopUp(false);
      setDeleteModelPopup(false);
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'apiErrorDialogValue',
          value: { dialog: true, message: deleteCard.message }
        }
      });
    }
  };

  useEffect(() => {
    (async () => {
      setLoader(true);
      const authToken = userDataFromLocal();
      console.log('authToken', authToken);
      const params = `?user_id=${authToken.userID}`;

      let projectListData;
      if (urlInfo.pathname.includes('shared-with-me')) {
        projectListData = await SharedProjectList(params, authToken.token); // api to fire projectlist
      } else {
        projectListData = await ProjectList(params, authToken.token); // api to fire projectlist
      }
      if (projectListData.status === 'success') {
        const projectData = projectListData.data.projects.sort((a, b) => {
          return new Date(b.modified_date) - new Date(a.modified_date);
        });

        const showDataArray = [];
        if (projectData.length > 0) {
          try {
            const authToken = userDataFromLocal();
            console.log('authToken', authToken);
            const params = `&user_id=${authToken.userID}`;
            // Use Promise.all with map to wait for all promises to resolve

            projectData.map((item) => {
              const itemId = item.project_id;

              showDataArray.push({
                ...item,
                modelDetail: {
                  p_id: itemId,
                  m_count: item.link_models.length,
                  m_id: item.link_models.map((item) => item.link_id)
                }
              });
            });

            // await Promise.all(
            //   projectData.map(async (item) => {
            //     const itemId = item.project_id;

            //     const getAllModelsInProjects = await GetApiByUrl(
            //       'visual/neo4j/get_project_link_model?project_id=' + itemId + params,
            //       authToken.token
            //     );

            //     if (
            //       getAllModelsInProjects.status === 'success' &&
            //       getAllModelsInProjects.code === 200
            //     ) {
            //       showDataArray.push({
            //         ...item,
            //         modelDetail: {
            //           p_id: itemId,
            //           m_count: getAllModelsInProjects.data.length,
            //           m_id: getAllModelsInProjects.data.map((item) => item.link_id)
            //         }
            //       });
            //     }
            //   })
            // );
            const sortedShowDataArray = showDataArray.sort((a, b) => {
              return new Date(b.modified_date) - new Date(a.modified_date);
            });
            setShowData(sortedShowDataArray);
            setShowDataArchive(sortedShowDataArray);
            console.log('getAllModelsInProjects init', sortedShowDataArray, projectData);
          } catch (error) {
            console.error('Error fetching models:', error);
          }
        }
      } else if (projectListData.status === 'failed' && projectListData.code === 403) {
        setValidationMsg(projectListData.message);
        // setOpenValidation(true);
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: projectListData.message }
          }
        });
      } else if (projectListData.status === 'failed' && projectListData.code === 404) {
        setValidationMsg(projectListData.message);
        // setOpenValidation(true);
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: projectListData.message }
          }
        });
      } else {
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: 'There was an error while processing your request.' }
          }
        });
      }
      setLoader(false);
    })();
  }, [mutateList, urlInfo.pathname]);

  useEffect(() => {
    // Connect to WebSocket
    const socketWorker = new Worker(new URL('./socketWorker.js', import.meta.url));
    console.log('worker', socketWorker);

    let locationHostName = window.location.hostname;
    if (locationHostName === 'localhost') {
      locationHostName = 'xylogic-kg.kalolytic.com';
    }
    socketWorker.postMessage({
      type: 'CONNECT',
      payload: { url: `wss://${locationHostName}/ws/progress/` }
    });

    socketWorker.onmessage = (event) => {
      console.log('worker', event);
      const { type, payload } = event.data;

      if (type === 'SOCKET_OPEN') {
        setSocketListDialog((prev) => ({ ...prev, connectionStatus: true }));
        console.log('WebSocket live:');
      } else if (type === 'SOCKET_MESSAGE') {
        const data = payload;
        console.log('WebSocket message', data);
        const _projectId = data?.message[0]?.project_id;
        setSocketListDialog((prev) => ({
          ...prev,
          connectionStatus: true,
          socketData: Array.from(new Set([...prev.socketData, _projectId]))
        }));

        setSocketData((prevData) => {
          const newProgress1 = data?.progress?.P1 ?? 0;
          const newProgress2 = data?.progress?.P2 ?? 0;
          const newProgress3 = data?.progress?.P3 ?? 0;
          const newProgress4 = data?.progress?.P4 ?? 0;

          const newProgress = newProgress1 + newProgress2 + newProgress3 + newProgress4;

          const newMessage = data?.message[0];
          const newLinkModelId = newMessage?.link_model_id;
          const newLinkModelName = newMessage?.link_model_name;
          const newProjectName = newMessage?.project_name;
          const existingIndex = prevData.findIndex(
            (item) => item?.link_model_id === newLinkModelId
          );

          if (existingIndex !== -1) {
            if (prevData[existingIndex].progress.props.value !== newProgress) {
              const updatedObject = {
                ...prevData[existingIndex],
                status: newMessage.status,
                progress: (
                  <Box sx={{ width: '100%' }}>
                    <CircularProgressWithLabel
                      setSocketListDialog={setSocketListDialog}
                      projectID={_projectId}
                      value={newProgress}
                      data={data?.progress}
                    />
                  </Box>
                )
              };
              return [
                ...prevData.slice(0, existingIndex),
                updatedObject,
                ...prevData.slice(existingIndex + 1)
              ];
            }
            return prevData;
          } else {
            const newObject = {
              id: newLinkModelId,
              link_model_id: newLinkModelId,
              link_model_name: newLinkModelName,
              project_name: newProjectName,
              status: newMessage.status,
              progress: (
                <Box sx={{ width: '100%' }}>
                  <CircularProgressWithLabel
                    setSocketListDialog={setSocketListDialog}
                    value={newProgress}
                    data={data?.progress}
                    projectID={_projectId}
                  />
                </Box>
              )
            };
            return [...prevData, newObject];
          }
        });
      } else if (type === 'SOCKET_ERROR') {
        setSocketListDialog((prev) => ({ ...prev, connectionStatus: false }));
        console.error('WebSocket error:', payload);
      } else if (type === 'SOCKET_CLOSE') {
        setSocketListDialog((prev) => ({ ...prev, connectionStatus: false }));
        console.log('WebSocket connection closed');
      }
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      socketWorker.postMessage({ type: 'DISCONNECT' });
    };
  }, [reconnectSocketConnection]);

  const [anchorEdit, setAnchorEdit] = useState(null);
  const openEditMenu = Boolean(anchorEdit);
  const [selectedCard, setSelectedCard] = useState({});
  // console.log('selectedCardselectedCard', selectedCard);
  const handleEditMenuOpen = (event) => {
    setAnchorEdit(event.currentTarget);
  };
  const handleEditMenuClose = () => {
    setAnchorEdit(null);
    setSelectedCard({});
  };

  const [anchorProjectSort, setAnchorProjectSort] = useState(null);
  const openProjectSortMenu = Boolean(anchorProjectSort);
  // const [selectedCard, setSelectedCard] = useState({});
  // console.log('selectedCardselectedCard', selectedCard);
  const handleProjectSortMenuOpen = (event) => {
    setAnchorProjectSort(event.currentTarget);
  };
  const handleProjectSortMenuClose = () => {
    setAnchorProjectSort(null);
    // setSelectedCard({});
  };

  const elementToSubtractRef = useRef(null);

  const [targetHeight, setTargetHeight] = useState('0px');

  useEffect(() => {
    const resizeFunc = () => {
      const subtractHeight = elementToSubtractRef.current.offsetHeight;

      const targetHeight = window.innerHeight - subtractHeight;

      setTargetHeight(`${targetHeight - 2}px`);
    };
    resizeFunc();
    document.addEventListener('resize', resizeFunc);
  }, []);

  return (
    <Page title="Knowledge Graph">
      <Container maxWidth="xl" className="noPadding bg-h-100">
        <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
        />

        <ApiErrorDialog />
        {/* SSE Connection */}
        <Dialog
          keepMounted
          open={socketListDialog.open}
          onClose={() => {
            setSocketListDialog((prev) => ({
              ...prev,
              open: false
            }));
          }}
          maxWidth
          fullWidth
          // sx={deleteModelData.data.length > 0 ? {} : { width: '45vw', margin: 'auto' }}
          sx={{ width: '70vw', margin: 'auto' }}
        >
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="h6">Live Model Status</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px'
              }}
            >
              <Tooltip
                title={
                  socketListDialog.connectionStatus
                    ? 'Connection is live.'
                    : 'Connection Broken, click to reconnect.'
                }
              >
                <IconButton
                  onClick={(event) => {
                    if (socketListDialog.connectionStatus) {
                      event.preventDefault();
                      event.stopPropagation();
                    } else {
                      setReconnectSocketConnection(new Date());
                    }
                  }}
                  size="small"
                  className="popUpCloseIcon"
                >
                  {socketListDialog.connectionStatus ? (
                    <WifiTethering style={{ color: '#37b137' }} size={20} />
                  ) : (
                    <WifiTetheringError color="error" size={20} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton
                  onClick={() =>
                    setSocketListDialog((prev) => ({
                      ...prev,
                      open: false
                    }))
                  }
                  size="small"
                  className="popUpCloseIcon"
                >
                  <Close size={20} />
                </IconButton>
              </Tooltip>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ height: 400, width: '100%' }}>
              <DataGrid
                initialState={{ columns: { columnVisibilityModel: { id: false } } }}
                rows={socketData}
                columns={[
                  { field: 'id', headerName: 'S No.', width: 90 },
                  { field: 'project_name', headerName: 'Project Name', width: 190 },
                  { field: 'link_model_name', headerName: 'Model Name', width: 190 },
                  // { field: 'link_model_id', headerName: 'Model ID', width: 130 },
                  { field: 'status', headerName: 'Message', width: 290 },
                  {
                    field: 'progress',
                    headerName: 'Progress',
                    width: 250,
                    renderCell: (params) => params.value
                  }
                ]}
                // pagination={false}
                disableRowSelectionOnClick
              />
            </Box>
          </DialogContent>
          <Tooltip
            title={socketListDialog.connectionStatus ? 'Connection is live.' : 'Connection Broken.'}
          >
            <Divider
              sx={{
                height: '5px',
                background: socketListDialog.connectionStatus ? '#37b137' : 'red',
                width: '100%',
                cursor: 'help'
              }}
            />
          </Tooltip>
        </Dialog>
        {loader && <LoaderBlur bgProp="white" />}
        <Box ref={elementToSubtractRef}>
          <TopNavigationBar />
        </Box>

        {/* Sort Project Menu */}
        <Menu
          anchorEl={anchorProjectSort}
          open={openProjectSortMenu}
          onClose={handleProjectSortMenuClose}
        >
          <MenuItem
            onClick={() => {
              const sortedArray = [...showData].sort((a, b) => {
                const nameA = a.project_name.toUpperCase();
                const nameB = b.project_name.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0; // Names are equal
              });
              // console.log('getAllModelsInProjects', sortedArray);
              setShowData(sortedArray);
              handleProjectSortMenuClose();
            }}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>By Name (Asc) : </span>
            <ArrowUpward style={{ height: '16px' }} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              const sortedArray = [...showData].sort((a, b) => {
                const nameA = a.project_name.toUpperCase();
                const nameB = b.project_name.toUpperCase();

                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                return 0; // Names are equal
              });
              // console.log('getAllModelsInProjects', sortedArray);
              setShowData(sortedArray);
              handleProjectSortMenuClose();
            }}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>By Name (Desc) : </span>
            <ArrowDownward style={{ height: '16px' }} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              const sortedArray = [...showData].sort((a, b) => {
                const nameA = String(a.modelDetail.m_count).toUpperCase();
                const nameB = String(b.modelDetail.m_count).toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0; // Names are equal
              });
              // console.log('getAllModelsInProjects', sortedArray);
              setShowData(sortedArray);
              handleProjectSortMenuClose();
            }}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>By Model Count (Asc) : </span>
            <ArrowUpward style={{ height: '16px' }} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              const sortedArray = [...showData].sort((a, b) => {
                const nameA = String(a.modelDetail.m_count).toUpperCase();
                const nameB = String(b.modelDetail.m_count).toUpperCase();

                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                return 0; // Names are equal
              });
              // console.log('getAllModelsInProjects', sortedArray);
              setShowData(sortedArray);
              handleProjectSortMenuClose();
            }}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>By Model Count (Desc) : </span>
            <ArrowDownward style={{ height: '16px' }} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              const sortedArray = [...showData].sort((a, b) => {
                return new Date(b.modified_date) - new Date(a.modified_date);
              });

              // console.log('getAllModelsInProjects', sortedArray);
              setShowData(sortedArray);
              handleProjectSortMenuClose();
            }}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>By Date Modified : </span>
            <CalendarMonth style={{ height: '16px' }} />
          </MenuItem>
        </Menu>

        <Grid container marginTop={0} spacing={5} id="k-Graph-wrap">
          {/* right side knowledge graph list */}
          <Grid
            style={{
              height: targetHeight
            }}
            item
            xs={12}
            id="k-graphRight"
            sx={{ backgroundColor: '#f4f8fd' }}
          >
            <Box
              sx={{
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 4,
                p: 2,
                position: 'sticky',
                top: 0,
                zIndex: 5
              }}
            >
              {urlInfo.pathname.includes('shared-with-me') ? (
                <Typography
                  variant="h6"
                  sx={{
                    color: '#004C99',
                    margin: '10px 0 20px 40px',
                    textAlign: 'start',
                    width: '100%'
                  }}
                >
                  Shared Visualization
                </Typography>
              ) : (
                <Paper
                  elevation={3}
                  component="form"
                  sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: 900,
                    '&:hover': {
                      boxShadow:
                        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                    }
                  }}
                >
                  <IconButton sx={{ p: '10px', pointerEvents: 'none' }} aria-label="search">
                    <Search />
                  </IconButton>
                  <InputBase
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const filterShowData = showDataArchive.filter((elm) =>
                        String(elm.project_name)
                          .toLowerCase()
                          .includes(String(inputValue).toLowerCase())
                      );

                      setShowData(filterShowData);
                    }}
                    autoFocus
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Project Name"
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Tooltip title="Sort Projects">
                    <IconButton
                      onClick={handleProjectSortMenuOpen}
                      type="button"
                      sx={{ p: '10px' }}
                    >
                      <Sort />
                    </IconButton>
                  </Tooltip>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Tooltip title="Create New Project">
                    <IconButton
                      type="button"
                      onClick={() => {
                        // check permission then let it access
                        if (
                          userDataFromLocal().modules.some(
                            (module) => module.module_name === 'Projects'
                          )
                        ) {
                          if (
                            userDataFromLocal().modules.find(
                              (module) => module.module_name === 'Projects'
                            ).permissions.add
                          ) {
                            navigate(`/knowledgegraph/add`);
                          } else {
                            setValidationMsg('User does not have permission to Add.');
                            setOpenValidation(true);
                          }
                        }
                      }}
                      sx={{ p: '10px', color: '#047edf' }}
                    >
                      <CreateNewFolder />
                    </IconButton>
                  </Tooltip>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Tooltip title="View Model Status">
                    <IconButton
                      onClick={() => setSocketListDialog((prev) => ({ ...prev, open: true }))}
                      type="button"
                      sx={{
                        p: '10px',
                        // green : red color based on socket connection status
                        color: socketListDialog.connectionStatus ? '#37b137' : '#ed4949'
                      }}
                    >
                      <Badge badgeContent={socketData.length} color="info">
                        <PendingActions />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Paper>
              )}
            </Box>
            <Box className="graphRight-info active">
              {/* using map function for showing data in card item */}
              <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
                {/* <Grid item xs={12} sm={4} md={3}>
                  <Button
                    onClick={() => {
                      // openSpecificVisualization('dialog1');
                      navigate(`/knowledgegraph/add`);
                      // setDialogVisualization(true);
                    }}
                    className="cardOuter cardOuterWithBorder"
                    style={{
                      width: '100%',
                      height: '100%',
                      minHeight: '152px',
                      backgroundColor: '#047edf',
                      color: 'white',
                      fontSize: '1.0125rem',
                      fontWeight: '400',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    startIcon={<AddCircleOutline sx={{ fontSize: '22px', marginBottom: '10px' }} />}
                  >
                    New Project
                  </Button>
                </Grid> */}
                {/* Delete Model List */}
                {!deleteModelData.show && deleteModelPopup && (
                  <LoaderBlur customMsg="Loading model..." />
                )}
                <Dialog
                  open={deleteModelPopup && deleteModelData.show}
                  onClose={(e) => {
                    setDeleteModelPopup(false);
                    setDeleteModelData((prev) => ({
                      ...prev,
                      show: false
                    }));
                  }}
                  maxWidth
                  fullWidth
                  sx={deleteModelData.data.length > 0 ? {} : { width: '45vw', margin: 'auto' }}
                >
                  <KnowledgeGraphListDialog
                    deleteProjectBtnLoader={deleteProjectBtnLoader}
                    setDeleteProjectBtnLoader={setDeleteProjectBtnLoader}
                    deleteModelData={deleteModelData}
                    setDeleteModelData={setDeleteModelData}
                    deleteModelColumn={deleteModelColumn}
                    // currentSelection={currentSelection}
                    // currentSelectionName={currentSelectionName}
                    selectedCard={selectedCard}
                    confirmDeletePopUp={confirmDeletePopUp}
                    setConfirmDeletePopUp={setConfirmDeletePopUp}
                    deletableArray={deletableArray}
                    handleDelete={handleDelete}
                  />
                </Dialog>

                {/* View Model List */}
                {!allModelData.show && modelPopup && <LoaderBlur customMsg="Loading model..." />}
                <Dialog
                  open={modelPopup && allModelData.show}
                  onClose={(e) => {
                    setModelPopup(null);
                    setAllModelData((prev) => ({
                      ...prev,
                      show: false
                    }));
                  }}
                  maxWidth
                  fullWidth
                  sx={allModelData.data.length > 0 ? {} : { width: '45vw', margin: 'auto' }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <DialogContent>
                      <Typography
                        sx={{ textTransform: 'uppercase', fontWeight: '900', fontSize: '1.3rem' }}
                      >
                        Model List
                      </Typography>
                    </DialogContent>
                  </Box>

                  <Box m={2}>
                    {allModelData.data.length > 0 ? (
                      <Box style={{ height: 400 }}>
                        <DataGrid
                          rows={allModelData.data}
                          columns={allModelColumn}
                          hideFooter
                          hideFooterPagination
                          hideFooterSelectedRowCount
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 4
                        }}
                      >
                        <Typography textTransform={'uppercase'} variant="subtitle2">
                          No model found in this project:
                        </Typography>
                        <Button
                          onClick={(e) => {
                            dispatch({
                              type: 'STORE_ALL_SELECTED_DATA',
                              payload: {
                                key: 'expanded',
                                value: 'panel5'
                              }
                            });
                            navigate(
                              `/knowledgegraph/edit/${selectedCard.itemId}/${selectedCard.name}`
                            );
                          }}
                          variant="contained"
                          color="info"
                        >
                          Create model
                        </Button>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        borderTop: '1px solid #ccc',
                        justifyContent: 'flex-end',
                        p: 0,
                        m: 0,
                        mt: 2,
                        pb: 2,
                        py: 1
                      }}
                    >
                      <DialogActions>
                        {allModelData.data.length > 0 ? (
                          <>
                            <Button
                              onClick={(e) => {
                                dispatch({
                                  type: 'STORE_ALL_SELECTED_DATA',
                                  payload: {
                                    key: 'expanded',
                                    value: 'panel5'
                                  }
                                });
                                navigate(
                                  `/knowledgegraph/edit/${selectedCard.itemId}/${selectedCard.name}`
                                );
                              }}
                              variant="contained"
                              color="info"
                            >
                              Add model
                            </Button>
                            <Button
                              onClick={(e) => {
                                setModelPopup(null);
                                setAllModelData((prev) => ({
                                  ...prev,
                                  show: false
                                }));
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              Close
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={(e) => {
                              setModelPopup(null);
                              setAllModelData((prev) => ({
                                ...prev,
                                show: false
                              }));
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            Close
                          </Button>
                        )}
                      </DialogActions>
                    </Box>
                  </Box>
                </Dialog>

                {/* Edit Project Name Dialog */}
                <Dialog
                  open={editProjectName.dialogOpen}
                  // onClose={handleClose}
                  // aria-labelledby="alert-dialog-title"
                  // aria-describedby="alert-dialog-description"
                >
                  <DialogTitle className="popupTitle">
                    <Typography variant="h6">Edit Project</Typography>
                    <IconButton
                      //  onClick={(e) => setOpenValidation(false)}
                      onClick={(e) =>
                        setEditProjectName({
                          dialogOpen: false,
                          projectID: '',
                          projectName: '',
                          newProjectName: ''
                        })
                      }
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Box py={1} px={4} textAlign="center">
                        <TextField
                          size="small"
                          value={editProjectName.newProjectName}
                          onChange={(e) =>
                            setEditProjectName((prev) => ({
                              ...prev,
                              newProjectName: e.target.value
                            }))
                          }
                          // error
                          // id="outlined-error-helper-text"
                          // label="Error"
                          placeholder="Enter Project Name"
                          // helperText="Incorrect entry."
                        />
                        <Typography sx={{ mt: 2 }} variant="body2">
                          Current Name: {editProjectName.projectName}
                        </Typography>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      className="dialogBtn"
                      onClick={async (e) => {
                        const authToken = userDataFromLocal();
                        // const params = `?user_id=${authToken.userID}`;
                        const payload = {
                          project_id: editProjectName.projectID,
                          project_name: editProjectName.newProjectName,
                          creator_user_id: authToken.userID
                        };

                        const _renameAPI = await EditProject(payload, authToken.token);
                        if (_renameAPI.status === 'success') {
                          enqueueSnackbar(
                            `${editProjectName.projectName} renamed successfully to ${editProjectName.newProjectName}.`,
                            {
                              variant: _renameAPI.status,
                              autoHideDuration: 3000
                            }
                          );
                          setMutateList(new Date());
                          setEditProjectName({
                            dialogOpen: false,
                            projectID: '',
                            projectName: '',
                            newProjectName: ''
                          });
                          handleEditMenuClose();
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Edit Menu */}
                <Menu anchorEl={anchorEdit} open={openEditMenu} onClose={handleEditMenuClose}>
                  <MenuItem
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={async (e) => {
                      const authToken = userDataFromLocal();
                      console.log('authToken', authToken);
                      const params = `&user_id=${authToken.userID}`;
                      setModelPopup(true);
                      // navigate(`/knowledgegraph/view/visualization/${currentSelection}`);
                      const getAllModelsInProjects = await GetApiByUrl(
                        'visual/neo4j/get_project_link_model?project_id=' +
                          selectedCard.itemId +
                          params
                      );
                      console.log('getAllModelsInProjects', getAllModelsInProjects);
                      if (
                        getAllModelsInProjects.status === 'success' &&
                        getAllModelsInProjects.code === 200 &&
                        getAllModelsInProjects.data.length
                      ) {
                        getAllModelsInProjects.data.forEach((element) => {
                          const cretdeTime = fDateTime(element.created_time);
                          const modifedTime = fDateTime(element.modified_time);
                          element.id = element.link_id;
                          element.created_time = cretdeTime;
                          element.modified_time = modifedTime;
                        });
                        const columns = [];

                        let modelNameColumnEncountered = false;

                        Object.keys(getAllModelsInProjects.data[0]).forEach((col) => {
                          let headerName = camlecaseAndUnderScore(col);
                          if (col === 'link_model_name') {
                            headerName = 'Name';
                            modelNameColumnEncountered = true;
                          } else if (col === 'link_id') {
                            headerName = 'Link';
                          }
                          const obj = {
                            field: col,
                            headerName: headerName,
                            width: col === 'link_model_name' ? 220 : 150,
                            hide: col === 'id' || col === 'link_id' || col === 'project_id',
                            hidden: col === 'id' || col === 'link_id' || col === 'project_id'
                          };
                          if (col !== 'link_model_name') {
                            columns.push(obj);
                          }
                          if (modelNameColumnEncountered && col === 'link_model_name') {
                            columns.push(obj);
                            columns.push({
                              field: 'description',
                              headerName: 'Description',
                              width: 360,
                              hide: false,
                              hidden: false
                            });
                          }
                        });

                        columns.push({
                          field: 'created_by',
                          headerName: 'Created By',
                          width: 150,
                          hide: false,
                          hidden: false
                        });

                        columns.push({
                          field: 'modified_by',
                          headerName: 'Modified By',
                          width: 150,
                          hide: false,
                          hidden: false
                        });

                        // columns.push({
                        //   field: 'action',
                        //   headerName: 'Action',
                        //   width: 150,
                        //   renderCell: (params) => {
                        //     const currentModelID = params.row.link_id; // Assuming link_id is the unique identifier for each model
                        //     return EditIconFunction({
                        //       p_id: selectedCard.itemId,
                        //       p_name: selectedCard.name,
                        //       model_ID: currentModelID
                        //     });
                        //   }
                        // });
                        // setAllModelColumn(columns);

                        const newColumns = [...columns];

                        const addFirstInColumn = {
                          field: 'action',
                          headerName: 'Action',
                          width: 80,
                          renderCell: (params) => {
                            const currentModelID = params.row.link_id; // Assuming link_id is the unique identifier for each model
                            return EditIconFunction(
                              {
                                p_id: selectedCard.itemId,
                                p_name: selectedCard.name,
                                model_ID: currentModelID
                              },
                              setOpenValidation,
                              setValidationMsg
                            );
                          }
                        };

                        newColumns.unshift(addFirstInColumn);
                        setAllModelColumn(newColumns);

                        setAllModelData({
                          show: true,
                          data: getAllModelsInProjects.data
                        });
                      } else {
                        setAllModelData({
                          show: true,
                          data: []
                        });
                      }
                    }}
                  >
                    <span>Model : </span>
                    <Storage style={{ height: '16px' }} />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={() => {
                      dispatch({
                        type: 'STORE_ALL_SELECTED_DATA',
                        payload: {
                          key: 'expanded',
                          value: 'panel4'
                        }
                      });
                      navigate(`/knowledgegraph/edit/${selectedCard.itemId}/${selectedCard.name}`);
                    }}
                  >
                    <span>Datasource : </span>
                    <Ballot style={{ height: '16px' }} />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={() =>
                      setEditProjectName({
                        dialogOpen: true,
                        projectID: selectedCard.itemId,
                        projectName: selectedCard.name,
                        newProjectName: ''
                      })
                    }
                  >
                    <span>Project : </span>
                    <DesignServices style={{ height: '16px' }} />
                  </MenuItem>
                </Menu>

                {showData.length ? (
                  showData.map((item, index) => {
                    const itemId = item.project_id;
                    const name = item.project_name;

                    return (
                      <Grid key={`card-${index}`} item xs={12} sm={4} md={3}>
                        <Box className="cardOuter cardOuterWithBorder">
                          <Box sx={{ position: 'relative' }} key={index} className="k-cardLoop-New">
                            {socketListDialog.socketData.includes(String(item.project_id)) && (
                              <Tooltip title="Model creation in progress.">
                                <IconButton
                                  onClick={() =>
                                    setSocketListDialog((prev) => ({ ...prev, open: true }))
                                  }
                                  sx={{
                                    position: 'absolute',
                                    zIndex: 15,
                                    top: '2px',
                                    left: '2px'
                                  }}
                                >
                                  <div className="circle-progress" />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Box
                              className="k-card-cell cs-padding"
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: '100px',
                                background: item?.modelDetail.m_count <= 0 ? '#ddd' : '',
                                '&:hover': { background: '#d8e1ed' }
                              }}
                              onClick={async (e) => {
                                setSelectedCard({ itemId: itemId, name: name }); // accessing value from one object instead of separate (currentSelection, currentSelectionName)
                                // setCurrentSelection(itemId);
                                // setCurrentSelectionName(name);

                                if (
                                  userDataFromLocal().modules.some(
                                    (module) => module.module_name === 'Projects'
                                  )
                                ) {
                                  if (
                                    userDataFromLocal().modules.find(
                                      (module) => module.module_name === 'Projects'
                                    ).permissions.edit
                                  ) {
                                    if (item.modelDetail.m_count === 1) {
                                      navigate(
                                        `/knowledgegraph/view/visualization/${item.modelDetail.p_id}/${item.modelDetail.m_id}`
                                      );
                                    } else {
                                      const authToken = userDataFromLocal();
                                      const params = `&user_id=${authToken.userID}`;
                                      setModelPopup(true);
                                      // navigate(`/knowledgegraph/view/visualization/${currentSelection}`);
                                      const getAllModelsInProjects = await GetApiByUrl(
                                        'visual/neo4j/get_project_link_model?project_id=' +
                                          itemId +
                                          params
                                      );
                                      // console.log('getAllModelsInProjects', getAllModelsInProjects);
                                      if (
                                        getAllModelsInProjects.status === 'success' &&
                                        getAllModelsInProjects.code === 200 &&
                                        getAllModelsInProjects.data.length
                                      ) {
                                        getAllModelsInProjects.data.forEach((element) => {
                                          // console.log('fDateTime(element.created_time)', fDateTime(element.created_time));
                                          const cretdeTime = fDateTime(element.created_time);
                                          const modifedTime = fDateTime(element.modified_time);
                                          element.id = element.link_id;
                                          element.created_time = cretdeTime;
                                          element.modified_time = modifedTime;
                                        });
                                        const columns = [];

                                        let modelNameColumnEncountered = false;

                                        Object.keys(getAllModelsInProjects.data[0]).forEach(
                                          (col) => {
                                            let headerName = camlecaseAndUnderScore(col);
                                            if (col === 'link_model_name') {
                                              headerName = 'Name';
                                              modelNameColumnEncountered = true;
                                            } else if (col === 'link_id') {
                                              headerName = 'Link';
                                            }
                                            const obj = {
                                              field: col,
                                              headerName: headerName,
                                              width: col === 'link_model_name' ? 220 : 150,
                                              hide:
                                                col === 'id' ||
                                                col === 'link_id' ||
                                                col === 'project_id',
                                              hidden:
                                                col === 'id' ||
                                                col === 'link_id' ||
                                                col === 'project_id'
                                            };
                                            if (col !== 'link_model_name') {
                                              columns.push(obj);
                                            }
                                            if (
                                              modelNameColumnEncountered &&
                                              col === 'link_model_name'
                                            ) {
                                              columns.push(obj);
                                              columns.push({
                                                field: 'description',
                                                headerName: 'Description',
                                                width: 360,
                                                hide: false,
                                                hidden: false
                                              });
                                            }
                                          }
                                        );

                                        columns.push({
                                          field: 'created_by',
                                          headerName: 'Created By',
                                          width: 150,
                                          hide: false,
                                          hidden: false
                                        });

                                        columns.push({
                                          field: 'modified_by',
                                          headerName: 'Modified By',
                                          width: 150,
                                          hide: false,
                                          hidden: false
                                        });

                                        const newColumns = [...columns];

                                        const addFirstInColumn = {
                                          field: 'action',
                                          headerName: 'Action',
                                          width: 80,
                                          renderCell: FunctionButtons
                                        };

                                        newColumns.unshift(addFirstInColumn);
                                        setAllModelColumn(newColumns);

                                        setAllModelData({
                                          show: true,
                                          data: getAllModelsInProjects.data
                                        });
                                      } else {
                                        setAllModelData({
                                          show: true,
                                          data: []
                                        });
                                      }
                                    }
                                  } else {
                                    setValidationMsg('User does not have permission to edit.');
                                    setOpenValidation(true);
                                  }
                                }
                              }}
                            >
                              <Box className="flex-container">
                                {String(item.project_name).length > 24 ? (
                                  <Tooltip
                                    placement="top"
                                    arrow
                                    title={
                                      <span className="projectNameTooltip">
                                        {item.project_name}
                                      </span>
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        textAlign: 'center'
                                      }}
                                      color="text.secondary"
                                      className="project-name"
                                      gutterBottom
                                    >
                                      {item.project_name}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      textAlign: 'center'
                                    }}
                                    color="text.secondary"
                                    className="project-name"
                                    gutterBottom
                                  >
                                    {item.project_name}
                                  </Typography>
                                )}
                              </Box>
                              <Box className="flex-item">
                                <p className="create-date"> {fDate(item.creation_date)} </p>
                                <p className="project-status">
                                  {' '}
                                  {item.isArchived ? 'Active' : 'Inactive'}
                                </p>
                              </Box>
                            </Box>
                            <Box className="cardBottom">
                              <Box>
                                <span
                                  style={{
                                    background: '#bddbdf',
                                    padding: '1px 5px',
                                    fontWeight: '500',
                                    borderRadius: '4px',
                                    fontFamily: 'monospace'
                                  }}
                                >
                                  Model Count: {item?.modelDetail?.m_count}
                                </span>
                              </Box>

                              <Box>
                                <Tooltip title="Edit" arrow placement="top">
                                  <IconButton
                                    className="cardIconFooter"
                                    sx={{ padding: '0px 8px' }}
                                    onClick={(evt) => {
                                      if (
                                        userDataFromLocal().modules.some(
                                          (module) => module.module_name === 'Projects'
                                        )
                                      ) {
                                        if (
                                          userDataFromLocal().modules.find(
                                            (module) => module.module_name === 'Projects'
                                          ).permissions.edit
                                        ) {
                                          setSelectedCard({ itemId: itemId, name: name }); // accessing value from one object instead of separate (currentSelection, currentSelectionName)
                                          // setCurrentSelection(itemId);
                                          // setCurrentSelectionName(name);
                                          handleEditMenuOpen(evt);
                                        } else {
                                          setValidationMsg(
                                            'User does not have permission to edit.'
                                          );
                                          setOpenValidation(true);
                                        }
                                      }
                                    }}
                                  >
                                    <FiEdit size={20} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete" arrow placement="top">
                                  <IconButton
                                    className="cardIconFooter"
                                    onClick={async (e) => {
                                      if (
                                        userDataFromLocal().modules.some(
                                          (module) => module.module_name === 'Projects'
                                        )
                                      ) {
                                        if (
                                          userDataFromLocal().modules.find(
                                            (module) => module.module_name === 'Projects'
                                          ).permissions.delete
                                        ) {
                                          const authToken = userDataFromLocal();
                                          const params = `&user_id=${authToken.userID}`;
                                          setSelectedCard({ itemId: itemId, name: name });
                                          setDeleteModelPopup(true);
                                          setModelPopup(false);
                                          // navigate(`/knowledgegraph/view/visualization/${currentSelection}`);
                                          const getAllModelsInProjects = await GetApiByUrl(
                                            'visual/neo4j/get_project_link_model?project_id=' +
                                              itemId +
                                              params
                                          );
                                          console.log(
                                            'getAllModelsInProjects',
                                            getAllModelsInProjects
                                          );
                                          if (
                                            getAllModelsInProjects.status === 'success' &&
                                            getAllModelsInProjects.code === 200 &&
                                            getAllModelsInProjects.data.length
                                          ) {
                                            getAllModelsInProjects.data.forEach((element) => {
                                              // console.log('fDateTime(element.created_time)', fDateTime(element.created_time));
                                              const cretdeTime = fDateTime(element.created_time);
                                              const modifedTime = fDateTime(element.modified_time);
                                              element.id = element.link_id;
                                              element.created_time = cretdeTime;
                                              element.modified_time = modifedTime;
                                            });

                                            const columns = [];

                                            let modelNameColumnEncountered = false;

                                            Object.keys(getAllModelsInProjects.data[0]).forEach(
                                              (col) => {
                                                let headerName = camlecaseAndUnderScore(col);
                                                if (col === 'link_model_name') {
                                                  headerName = 'Name';
                                                  modelNameColumnEncountered = true;
                                                } else if (col === 'link_id') {
                                                  headerName = 'Link';
                                                }
                                                const obj = {
                                                  field: col,
                                                  headerName: headerName,
                                                  width: col === 'link_model_name' ? 220 : 150,
                                                  hide:
                                                    col === 'id' ||
                                                    col === 'link_id' ||
                                                    col === 'project_id',
                                                  hidden:
                                                    col === 'id' ||
                                                    col === 'link_id' ||
                                                    col === 'project_id'
                                                };
                                                if (col !== 'link_model_name') {
                                                  columns.push(obj);
                                                }
                                                if (
                                                  modelNameColumnEncountered &&
                                                  col === 'link_model_name'
                                                ) {
                                                  columns.push(obj);
                                                  columns.push({
                                                    field: 'description',
                                                    headerName: 'Description',
                                                    width: 360,
                                                    hide: false,
                                                    hidden: false
                                                  });
                                                }
                                              }
                                            );

                                            columns.push({
                                              field: 'created_by',
                                              headerName: 'Created By',
                                              width: 150,
                                              hide: false,
                                              hidden: false
                                            });

                                            columns.push({
                                              field: 'modified_by',
                                              headerName: 'Modified By',
                                              width: 150,
                                              hide: false,
                                              hidden: false
                                            });

                                            setDeleteModelColumn(columns);
                                            setDeleteModelData({
                                              show: true,
                                              data: getAllModelsInProjects.data
                                            });
                                            setDeletableArray([itemId]);
                                          } else {
                                            setDeleteModelData({
                                              show: true,
                                              data: []
                                            });
                                            setDeletableArray([itemId]);
                                          }
                                        } else {
                                          setValidationMsg(
                                            'User does not have permission to delete.'
                                          );
                                          setOpenValidation(true);
                                        }
                                      }
                                    }}
                                    sx={{ padding: '0px 8px' }}
                                  >
                                    <Delete size={20} sx={{ color: '#722F37' }} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })
                ) : (
                  <Container
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Typography>No Project Found..</Typography>
                  </Container>
                )}
              </Grid>
            </Box>

            {/* <Box
              className={activeList === 2 ? 'graphRight-info active' : 'graphRight-info'}
              display="flex"
            >
              <Box>Tab 2</Box>
            </Box> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default KnowledgeGraphList;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function EditIconFunction(params, setOpenValidation, setValidationMsg) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <IconButton
        onClick={() => {
          if (userDataFromLocal().modules.some((module) => module.module_name === 'Models')) {
            if (
              userDataFromLocal().modules.find((module) => module.module_name === 'Models')
                .permissions.edit
            ) {
              navigate(`/knowledgegraph/edit/${params.p_id}/${params.p_name}/${params.model_ID}`);
            } else {
              setValidationMsg('User does not have permission to edit.');
              setOpenValidation(true);
            }
          }
        }}
        aria-label="edit"
      >
        <Edit />
      </IconButton>
    </div>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 20 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function CircularProgressWithLabel(props) {
  const { setSocketListDialog } = props;
  const [progress, setProgress] = useState({
    p1: 0,
    p2: 0,
    p3: 0,
    p4: 0
  });

  useEffect(() => {
    const intervals = [];

    const createInterval = (updateFunc, increment, delay) => {
      let _progress = 0;
      const interval = setInterval(() => {
        _progress += increment;
        if (_progress > 100) {
          _progress = 100;
          clearInterval(interval);
        }
        updateFunc(_progress);
      }, delay);
      intervals.push(interval);
    };
    if (Object.keys(props.data).includes('P2') && progress.p1 === 0)
      createInterval((_progress) => setProgress((prev) => ({ ...prev, p1: _progress })), 10, 300);
    if (Object.keys(props.data).includes('P3') && progress.p2 === 0)
      createInterval((_progress) => setProgress((prev) => ({ ...prev, p2: _progress })), 10, 400);
    if (Object.keys(props.data).includes('P4') && progress.p3 === 0) {
      createInterval((_progress) => setProgress((prev) => ({ ...prev, p3: _progress })), 10, 600);
      createInterval((_progress) => setProgress((prev) => ({ ...prev, p4: _progress })), 5, 450);

      console.log(props.projectID, 'props.projectID');
      setSocketListDialog((prev) => ({
        ...prev,
        socketData: prev.socketData.filter((item) => item !== props.projectID)
      }));
    }

    // return () => {
    //   intervals.forEach(clearInterval);
    // };
  }, [props.value, props.data]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
      {Object.keys(progress).length > 0 &&
        Object.keys(progress).map((item, index) => (
          <Box key={index} sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={progress[item]} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(progress[item])}%`}
              </Typography>
            </Box>
          </Box>
        ))}
    </Box>
  );
}
