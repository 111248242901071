import { BankWiseUrl } from './globalApi';
import dynamicApiServices from './dynamicApiServices';

const { globalKnowledgeGraphUrl } = BankWiseUrl;

const UserManageAuthService = {
  UsersListAuthAPI: (username, _userID, authToken) =>
    dynamicApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/userslist?username=${
        username ? username : ''
      }&user_id=${_userID}`,
      authToken
    ),
  ViewUserAuthAPI: (username, authToken) =>
    dynamicApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/viewuser/${username}`,
      authToken
    ),
  UsersListlevel: (id, authToken) =>
    dynamicApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/userslistlevel/${id}`,
      authToken
    ),
  ReportingManagers: (user_name, authToken) =>
    dynamicApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/reporting_managers/${user_name}`,
      authToken
    ),

  AddUserAuthAPI: (credential, authToken) =>
    dynamicApiServices().postApi(
      `${globalKnowledgeGraphUrl}/visual/adduser`,
      credential,
      authToken
    ),
  DeleteUserListAPI: (payload, authToken) =>
    dynamicApiServices().putApi(`${globalKnowledgeGraphUrl}/visual/deleteuser`, payload, authToken),
  // DeleteUserListAPI: (payload, authToken) =>
  //   dynamicApiServices().deleteApi(
  //     `${globalKnowledgeGraphUrl}/authentication/delete-user/`,
  //     payload,
  //     authToken
  //   ),
  // EditUserListViewAPI: (rowId, authToken) =>
  //   dynamicApiServices().getApi(`${globalKnowledgeGraphUrl}/viewuser/${rowId}`, authToken),
  EditUserListAPI: (credential, authToken) =>
    dynamicApiServices().putApi(
      `${globalKnowledgeGraphUrl}/visual/edituser/${credential.username}`,
      credential,
      authToken
    ),
  // Manage acces API

  EditManageListAPI: (userId, loginuserId, authToken) =>
    dynamicApiServices().getApi(
      `${globalKnowledgeGraphUrl}/visual/viewmapping/${userId}?user_id=${loginuserId}`,
      authToken
    ),

  EditManageSaveAPI: (userId, credential, authToken) =>
    dynamicApiServices().putApi(
      `${globalKnowledgeGraphUrl}/visual/edituseraccess/${userId}`,
      // `${globalKnowledgeGraphUrl}/edit-permission/${rowId}`,
      credential,
      authToken
    )
  // EditPermission: (credential, authToken) =>
  // dynamicApiServices().putApi(
  //   `${globalKnowledgeGraphUrl}/authentication/edit-permission/`,
  //   credential,
  //   authToken
  // ),
};

export default UserManageAuthService;
