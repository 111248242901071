import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Brush,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from '@mui/material';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';

const { GetApiByUrl } = KnowledgeGraphService;

export default function TimelineGraph({
  // modelId,
  cytoscapeComponentRef,
  displayCytoData,
  graphStyling,
  setCyToState,
  handleExitTimeline
}) {
  const dispatch = useDispatch();
  const { experimentId, modelId } = useParams();

  const [legendOpacity, setLegendOpacity] = useState({
    uv: 1,
    pv: 1
  });

  const [graphDisplayData, setGraphDisplayData] = useState([]);

  const resetTimelineGraphInitState = () => {
    setTimelineGraphInit({
      xaxis: { value: '#', listData: [] },
      property: { value: '#', listData: [] },
      edge_name: { value: '#', listData: [] },
      graphData: [],
      openModal: false,
      submit: false,
      resetCurrentState: resetTimelineGraphInitState
    });
  };

  const [timelineGraphInit, setTimelineGraphInit] = useState({
    xaxis: { value: '#', listData: [] },
    property: { value: '#', listData: [] },
    edge_name: { value: '#', listData: [] },
    graphData: [],
    openModal: false,
    submit: false,
    resetCurrentState: resetTimelineGraphInitState
  });

  const customObjArraySort = (arr, datatype, order = 'asc', key = 'xaxis') => {
    const comparators = {
      string: (a, b) => a.localeCompare(b),
      number: (a, b) => a - b,
      date: (a, b) => new Date(a) - new Date(b)
    };

    if (!comparators[datatype]) {
      console.error('Invalid type provided.');
      return;
    }

    if (order !== 'asc' && order !== 'desc') {
      console.error('Invalid order provided. Must be "asc" or "desc".');
      return;
    }

    const comparator = comparators[datatype];
    const sortedArray = [...arr].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];
      const result = comparator(valueA, valueB);
      return order === 'asc' ? result : -result;
    });

    return sortedArray;
  };

  const getDatatypeValue = (value) => {
    if (typeof value === 'string') {
      if (Number.isNaN(Date.parse(value))) {
        return 'string';
      } else {
        return 'date';
      }
    } else if (typeof value === 'number') {
      return 'number';
    } else {
      return 'unknown';
    }
  };

  const handleLegendMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      //   setLegendOpacity((prev) => ({ ...prev, [dataKey]: 0.4 }));
      const updatedOpacities = Object.fromEntries(
        Object.entries(legendOpacity).map(([name]) => [name, name === dataKey ? 1 : 0.4])
      );

      setLegendOpacity(updatedOpacities);
    },
    [legendOpacity, setLegendOpacity]
  );

  const handleLegendMouseLeave = useCallback(() => {
    const updatedOpacities = Object.fromEntries(
      Object.entries(legendOpacity).map(([name]) => [name, 1])
    );

    setLegendOpacity(updatedOpacities);
  }, [legendOpacity, setLegendOpacity]);

  const handleTimelineInit = (type, e, newVal) => {
    const targetValue = e.target.value;

    switch (type) {
      case 'edge_name': {
        const _dyanmicPropertyList = timelineGraphInit.graphData.find(
          (item) => item.edge_name === targetValue
        );

        const numericKeys = Object.entries(_dyanmicPropertyList?.properties).reduce(
          (acc, [key, value]) => {
            if (typeof value === 'number') {
              acc.push(key);
            }
            return acc;
          },
          []
        );

        // console.log('timelineGraphInit prop', _dyanmicPropertyList);
        setTimelineGraphInit((prev) => ({
          ...prev,
          edge_name: { ...prev.edge_name, value: targetValue },
          xaxis: {
            ...prev.xaxis,
            listData: [...new Set(Object.keys(_dyanmicPropertyList?.properties))]
          },
          property: {
            ...prev.property,
            listData: [...new Set(numericKeys)]
          }
        }));
        break;
      }

      case 'property': {
        setTimelineGraphInit((prev) => ({
          ...prev,
          property: { ...prev.property, value: targetValue }
        }));
        break;
      }

      case 'xaxis': {
        setTimelineGraphInit((prev) => ({ ...prev, xaxis: { ...prev.xaxis, value: targetValue } }));
        break;
      }

      default:
        break;
    }
  };

  const handleTimelineSubmit = () => {
    const _edge_name = timelineGraphInit.edge_name.value;
    const _xaxis = timelineGraphInit.xaxis.value;
    const _property = timelineGraphInit.property.value;

    const _filteredData = timelineGraphInit.graphData.filter(
      (item) => item.edge_name === _edge_name
    );

    if (_filteredData.length === 0) {
      console.log('No data found for the specified edge name');
      return;
    }

    const _transformedData = _filteredData.map((item, index) => ({
      id: index,
      xaxis: item.properties[_xaxis],
      [_property]: item.properties[_property]
    }));

    let _sortedData = [];

    if (_transformedData.length > 0) {
      switch (getDatatypeValue(_transformedData[0].xaxis)) {
        case 'string':
          _sortedData = customObjArraySort(_transformedData, 'string');
          break;
        case 'date':
          _sortedData = customObjArraySort(_transformedData, 'date');
          break;
        case 'number':
          _sortedData = customObjArraySort(_transformedData, 'number');
          break;
        default:
          console.log('Invalid datatype');
          break;
      }
    }

    // Optimized grouping logic
    const _groupedDataMap = new Map();
    _sortedData.forEach((item) => {
      const key = item.xaxis;
      if (_groupedDataMap.has(key)) {
        _groupedDataMap.get(key)[_property] += item[_property];
      } else {
        _groupedDataMap.set(key, { ...item });
      }
    });

    const _groupedDataArray = Array.from(_groupedDataMap.values());

    console.log('timeline final data', _groupedDataArray);

    setGraphDisplayData(_groupedDataArray);
    setTimelineGraphInit((prev) => ({ ...prev, openModal: false, submit: true }));
  };

  useEffect(() => {
    (async () => {
      const _timelineGraphDataApi = await GetApiByUrl(
        `visual/neo4j/relationship-details?&link_id=${modelId}&project_id=${experimentId}`
      );

      if (_timelineGraphDataApi.status === 'success' && _timelineGraphDataApi.code === 200) {
        console.log('_timelineGraphDataApi', _timelineGraphDataApi);
        const _nameList = [];
        _timelineGraphDataApi.data.map((item) => {
          _nameList.push(item.edge_name);
        });

        setTimelineGraphInit((prev) => ({
          ...prev,
          openModal: true,
          edge_name: { ...prev.edge_name, listData: [...new Set(_nameList)] },
          graphData: _timelineGraphDataApi.data
        }));
      }
    })();
  }, []);

  // useEffect(() => {
  //   console.log('timelineGraphInit', timelineGraphInit);
  // }, [timelineGraphInit]);

  return (
    <Box sx={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
      <Dialog open={timelineGraphInit.openModal}>
        <DialogTitle>Graph Config</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={timelineGraphInit.edge_name.value}
                onChange={(e, newVal) => {
                  handleTimelineInit('edge_name', e, newVal);
                }}
              >
                <MenuItem disabled value="#">
                  <em>Select Edge</em>
                </MenuItem>
                {timelineGraphInit.edge_name.listData.length > 0 &&
                  timelineGraphInit.edge_name.listData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>Edge Name*</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={timelineGraphInit.xaxis.value}
                onChange={(e, newVal) => {
                  handleTimelineInit('xaxis', e, newVal);
                }}
              >
                <MenuItem disabled value="#">
                  <em>Select X-Axis</em>
                </MenuItem>
                {timelineGraphInit.xaxis.listData.length > 0 &&
                  timelineGraphInit.xaxis.listData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>X-Axis*</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={timelineGraphInit.property.value}
                onChange={(e, newVal) => {
                  handleTimelineInit('property', e, newVal);
                }}
              >
                <MenuItem disabled value="#">
                  <em>Select Property</em>
                </MenuItem>
                {timelineGraphInit.property.listData.length > 0 &&
                  timelineGraphInit.property.listData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>Property (type number)*</FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              timelineGraphInit.resetCurrentState();
              handleExitTimeline();
            }}
            variant="contained"
          >
            Close
          </Button>
          <Button
            disabled={
              timelineGraphInit.edge_name.value === '#' ||
              timelineGraphInit.xaxis.value === '#' ||
              timelineGraphInit.property.value === '#'
            }
            onClick={handleTimelineSubmit}
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {timelineGraphInit.submit && graphDisplayData.length > 0 && (
        <Paper
          elevation={10}
          sx={{
            background: '#fff',
            position: 'absolute',
            bottom: 0,
            right: 0,
            p: '10px 0px',
            width: '100%',
            height: 250
          }}
        >
          <ResponsiveContainer>
            <LineChart
              data={graphDisplayData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Brush dataKey="xaxis" height={20} stroke="#8884d8" />
              <XAxis dataKey="xaxis" />
              <YAxis />
              <Tooltip />
              <Legend onMouseEnter={handleLegendMouseEnter} onMouseLeave={handleLegendMouseLeave} />
              <Line
                type="monotone"
                dataKey={timelineGraphInit.property.value}
                strokeOpacity={legendOpacity.pv}
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              {/* <Line type="monotone" dataKey="uv" strokeOpacity={legendOpacity.uv} stroke="#82ca9d" /> */}
            </LineChart>
          </ResponsiveContainer>
        </Paper>
      )}
    </Box>
  );
}
