export function camlecaseAndUnderScore(newStr) {
  if (newStr) {
    const removeUcore = newStr.replace(/_|-/g, ' ');
    const splitedStr = removeUcore.split(' ');

    const capitalize = splitedStr.map((str) => capitalizeFirstLetter(str));
    // console.log('splitedStr', capitalize);
    return capitalize.join(' ');
  }
  return '';
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
