import React from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const FunctionButtons = (params) => {
  const navigate = useNavigate();
  // console.log('paramsparams', params);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="view"
        onClick={(e) => {
          navigate(
            `/knowledgegraph/view/visualization/${params.row.project_id}/${params.row.link_id}`
          );
        }}
      >
        <VisibilityIcon />
      </IconButton>
      {/* <IconButton aria-label="delete">
        <DeleteIcon />
      </IconButton> */}
    </div>
  );
};

export default FunctionButtons;
