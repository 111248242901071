import useApiServices from './useApiServices';
import { BankWiseUrl } from './globalApi';

const { globalKnowledgeGraphUrl } = BankWiseUrl;

const GlobelService = {
  // login api
  // LoginApi: (Credential) =>
  // useApiServices().postLoginFormDataApi(`${gbleUrlPythonAuth}/userlogin`, Credential),
  KeyIdentifier: () =>
    useApiServices().getApiNoToken(`${globalKnowledgeGraphUrl}/visual/key_identifier`)
};

export default GlobelService;
