import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  ListItemIcon,
  ListItemText,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Divider
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { GrClose } from 'react-icons/gr';
import {
  getIcon,
  WhatsAppIcon,
  AddIcon,
  CheckBoxOutlineBlank,
  UserIcon,
  CheckBoxIcon
} from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
// import dataSourceService from '../../../services/dataSourceService';
// import { expandGraphByNodeNeighborhood } from './Utils';
// import { userDataFromLocal } from '../../../utils/getUserDetails';
import './KnowledgeGraph.css';

const { DatasetSchema, GetTableNames, GetFieldsNames, SaveSourceMapings, GetApiByUrl } =
  KnowledgeGraphService;
//
// const { DatasetColumn, RelationShipList } = dataSourceService;
function NodeInfo(props) {
  const {
    nodeSelect,
    responseColoumData,
    cySelectedElement,
    cyToState,
    randomIntFromInterval,
    datasetList,
    experimentName,
    selectedSourceAndColumn,
    setColroCodes,
    setRefreshKGComp,
    setStyling,
    styling,
    slectedOption
  } = props;
  const { experimentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const commonTableColumns = useSelector(
    (state) => state.knowledgeGraph.allSelectedData.commonTableColumns
  );
  const {
    exitsTableList,
    neo4jEdgeLabel,
    neo4jNodeLabel,
    createKnowledgeGraph: { colorCodes }
  } = knowledgeGraphStoreItem;
  // console.log('neo4jNodeLabelneo4jNodeLabel', neo4jNodeLabel);
  const dispatch = useDispatch();
  // console.log('responseColoumDatacyToState', exitsTableList);
  const [refreshComp, setRefreshComp] = useState(null);
  // source dataset state
  const [sourceDatasetValue, setSourceDatasetValue] = useState(' ');
  // target dataset state
  const [targetDatasetValue, setTargetDatasetValue] = useState(' ');
  const [relationshipName, setRelationshipName] = useState('');
  console.log('relationshipNamerelationshipName', relationshipName);
  const [relationshipProp, setRelationshipProp] = useState([]);
  const [sourceNodeValue, setSourceNodeValue] = useState(' ');
  // Source node state
  const [sourceNodeColumnData, setSourceNodeColumnData] = useState([]);
  // Target node state
  const [targetNodeColumnData, setTargetNodeColumnData] = useState([]);
  const [propertyFromEdge, setPropertyFromEdge] = useState([]);
  useEffect(() => {
    (async () => {
      if (cyToState !== null && slectedOption === 'edge' && cySelectedElement.id) {
        const sourceId = cyToState.$('#' + cySelectedElement.id).data().source;
        const targetId = cyToState.$('#' + cySelectedElement.id).data().target;

        console.log('cyToState', await cyToState.$('#' + cySelectedElement.id).data());
        console.log('sourceData', await cyToState.$('#' + sourceId).data());
        console.log('targetData', await cyToState.$('#' + targetId).data());

        if (sourceId && targetId) {
          const sourceData = await cyToState.$('#' + sourceId).data();
          const targetData = await cyToState.$('#' + targetId).data();

          const tempArray = [...sourceData.properties, ...targetData.properties];

          const uniqueArray = tempArray.reduce((accumulator, currentItem) => {
            const existingItem = accumulator.find(
              (item) => item.app_column_name === currentItem.app_column_name
            );

            if (!existingItem) {
              accumulator.push(currentItem);
            }

            return accumulator;
          }, []);

          setPropertyFromEdge([...uniqueArray]);

          const labelEdge = cyToState.$('#' + cySelectedElement.id).data().label;
          setRelationshipName(labelEdge);
          setRelationshipProp(cyToState.$('#' + cySelectedElement.id).data().properties);
        }
      }
    })();
  }, [slectedOption, cySelectedElement.id, cyToState]);

  // properties state
  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesValue, setPropertiesValue] = useState([]);
  // onchange for properties box
  const [propertiesSelect, setPropertiesSelect] = useState([]); // state for properties box
  // select box in properties
  const isAllSelected =
    propertiesData.length > 0 && propertiesSelect.length === propertiesData.length;
  // handle change function for properties
  // const handleChangeProperties = (event) => {
  //   const value = event.target.value;
  // };

  // console.log('propertiesData', propertiesData);
  // onChange function for source dataset box
  const handleSourceDataset = (e) => {
    setSourceDatasetValue(e.target.value);
  };
  // onChange function for target dataset box
  const handleTargetDataset = (e) => {
    setTargetDatasetValue(e.target.value);
  };

  // onchange function for relationship box
  const handleChangeRelationship = (event, value) => {
    setRelationshipName(value);
    setRefreshComp(new Date());
  };
  // target node state
  const [targetData, setTargetData] = useState(' ');
  const [targetDataLabelType, setTargetDataLabelType] = useState(' ');
  const [targetNodeId, setTargetNodeId] = useState();
  const [expanded, setExpanded] = useState('nodeSource');
  const [targetExpended, setTargetExpended] = useState('');
  const [targteFromNodes, setTargetFromNodes] = useState(' ');
  const [ErrorPopup, showErrorPopup] = useState(false);
  // onchange function for target box
  const handleChangeTarget = (event) => {
    // cyToState.$('#' + targetNodeId).data('label', event.target.value);
    setTargetData(event.target.value);
    setRefreshComp(new Date());
  };
  // fire api for source properties
  // const getProperties = async (cyToState, selectedTableName) => {
  //   const credential = {
  //     datasource_name: selectedSourceAndColumn.dataSourceName,
  //     crea_user: userDataFromLocal().userName,
  //     schema: selectedSourceAndColumn.schmeaName,
  //     table: selectedTableName
  //   };
  //   const getPropertiesList = await DatasetColumn(credential);
  //   // console.log('getPropertiesList', getPropertiesList);
  //   if (getPropertiesList.status === 'success') {
  //     cyToState.$('#' + cySelectedElement.id).data('properties', getPropertiesList.data);
  //   } else {
  //     cyToState.$('#' + cySelectedElement.id).data('properties', [
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 1' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 2' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 3' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 4' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 5' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 6' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 7' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 8' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 9' }
  //     ]);
  //   }
  //   setRefreshComp(new Date());
  // };
  // console.log('cyToState.$( + cySelectedElement.id)', cyToState.$('#' + cySelectedElement.id).data());
  // fire api for target properties
  // const getPropertiesTarget = async (datasetId) => {
  //   const credential = {
  //     datasource_name: selectedSourceAndColumn.dataSourceName,
  //     crea_user: userDataFromLocal().userName,
  //     schema: selectedSourceAndColumn.schmeaName,
  //     table: datasetId
  //   };
  //   const getPropertiesListTarget = await DatasetColumn(credential);
  //   // console.log('getPropertiesListTarget', getPropertiesListTarget);
  //   if (getPropertiesListTarget.status === 'success') {
  //     setPropertiesData(getPropertiesListTarget.data);
  //   } else {
  //     setPropertiesData([
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 1' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 2' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 3' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 4' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 5' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 6' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 7' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 8' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 9' }
  //     ]);
  //   }
  //   setRefreshComp(new Date());
  // };
  // fire api for accordion target properties
  // const getPropertiesTargetNode = async (cyToState, datasetId, item) => {
  //   const getPropertiesTarget = await DatasetSchema(datasetId);
  //   // console.log('getPropertiesTarget', getPropertiesTarget);
  //   if (getPropertiesTarget.status === 'success') {
  //     cyToState.$('#' + item).data('properties', getPropertiesTarget.data);
  //   } else {
  //     cyToState.$('#' + item).data('properties', [
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 1' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 2' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 3' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 4' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 5' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 6' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 7' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 8' },
  //       { dataset_schema_id: 2, dataset_id: 1, field_name: 'Column Name 9' }
  //     ]);
  //   }
  //   setRefreshComp(new Date());
  // };
  // console.log('cyToStateDataset', cyToState.$('#' + cySelectedElement.id).data());

  const currentLabel = cyToState.$('#' + cySelectedElement.id).data('label')
    ? { defaultValue: cyToState.$('#' + cySelectedElement.id).data('label') }
    : {};
  console.log('cyToStateDataset', currentLabel);
  const sourceDatasettable = getSettablename(cyToState.$('#' + cySelectedElement.id).data());

  // const targetLabelName = getTargetLabel(cyToState.$('#' + cySelectedElement.id).data());
  // add new node
  // const addNewNode = async (
  //   e,
  //   cyToState,
  //   cySelectedElement,
  //   targetData,
  //   relationshipName,
  //   sourceDatasetValue,
  //   propertiesData,
  //   sourceNodeValue,
  //   setTargetData,
  //   targetDataLabelType,
  //   setTargetDataLabelType,
  //   setRelationshipName,
  //   relationshipProp,
  //   setRelationshipProp,
  //   targteFromNodes,
  //   setTargetFromNodes
  // ) => {
  //   if (targteFromNodes === ' ') {
  //     // console.log('targetDataLabelType', targetDatasetValue);
  //     // console.log('targetDataLabelType', cySelectedElement.id);
  //     // console.log('targetDataLabelType', targetData);
  //     // console.log('targetDataLabelType', propertiesData);
  //     // console.log('targetDataLabelType', datasetList);
  //     // console.log('targetDataLabelType', cyToState.$(`#${cySelectedElement.id}`).data());
  //     const sourceDatasetId = cyToState.$(`#${cySelectedElement.id}`).data()
  //       .datasetValue.dataset_id;
  //     const targetDatasetId = targetDatasetValue.dataset_id;
  //     const isConnectionPossible = await GetApiByUrl(
  //       `visual/checks/nodes_mapping?source_dataset_id=${sourceDatasetId}&target_dataset_id=${targetDatasetId}`
  //     );
  //     // console.log('isConnectionPossible', isConnectionPossible);
  //     if (isConnectionPossible.code === 200) {
  //       const targetId = cyToState.nodes().length + 1;
  //       setTargetNodeId(targetId); // setting target node id
  //       // for node
  //       cyToState.add({
  //         data: {
  //           id: targetId,
  //           label: targetData,
  //           dataset: datasetList,
  //           properties: propertiesData,
  //           relationship: [],
  //           datasetValue: targetDatasetValue,
  //           propertiesValue: targetNodeColumnData,
  //           targeted: [],
  //           labelType: targetDataLabelType,
  //           position: {
  //             x: randomIntFromInterval(300, 1000),
  //             y: randomIntFromInterval(150, 700)
  //           }
  //         },
  //         position: {
  //           x: randomIntFromInterval(300, 1000),
  //           y: randomIntFromInterval(150, 700)
  //         }
  //       });
  //       // for edge
  //       cyToState.add({
  //         data: {
  //           source: cySelectedElement.id,
  //           target: targetId,
  //           label: relationshipName,
  //           properties: relationshipProp
  //         }
  //       });
  //       // console.log(cyToState.$(`#${cySelectedElement.id}`).data());
  //       // console.log(cyToState.$('#' + cySelectedElement.id).data().targeted);
  //       const prevTargetedIds = cyToState.$('#' + cySelectedElement.id).data().targeted;
  //       prevTargetedIds.push(targetId);
  //       const sourceData = {
  //         targeted: prevTargetedIds
  //       };
  //       cyToState.$('#' + cySelectedElement.id).data(sourceData); // pushing all connected targeted node id to the source node
  //       setRelationshipProp([]);
  //       setRelationshipName('');
  //       setTargetDataLabelType(' ');
  //       setTargetData('');
  //       // console.log('cyToState25', cyToState.$('#' + cySelectedElement.id).data(sourceData));
  //       // setTargetData('');
  //       // setRelationshipName('');
  //       // console.log('cyToState1', cyToState.elements());
  //     } else {
  //       showErrorPopup(true);
  //     }
  //   } else {
  //     // for edge
  //     cyToState.add({
  //       data: {
  //         source: cySelectedElement.id,
  //         target: targteFromNodes,
  //         label: relationshipName,
  //         properties: relationshipProp
  //       }
  //     });
  //     // console.log('cyToState25', cyToState.$(`#${cySelectedElement.id}`).data());
  //     // console.log('cyToState25', cyToState.$('#' + cySelectedElement.id).data().targeted);
  //     const prevTargetedIds = cyToState.$('#' + cySelectedElement.id).data().targeted;
  //     prevTargetedIds.push(targteFromNodes);
  //     const sourceData = {
  //       targeted: prevTargetedIds
  //     };
  //     cyToState.$('#' + cySelectedElement.id).data(sourceData); // pushing all connected targeted node id to the source node
  //     // console.log('cyToState25', cyToState.$('#' + cySelectedElement.id).data(sourceData));
  //     // setTargetData('');
  //     // setRelationshipName('');
  //     // console.log('cyToState25', cyToState.elements());
  //     setRefreshComp(new Date());
  //     expandGraphByNodeNeighborhood(cyToState, cySelectedElement.id);
  //   }
  // };
  useEffect(() => {
    // console.log('cyToState222', cyToState.$('#' + cySelectedElement.id).data());
    // debugger;
    setSourceNodeValue(cyToState.$('#' + cySelectedElement.id).data('label'));

    // get relationship list from API
    (async () => {
      const getTablesList = await GetFieldsNames('visual/manage_app_tables');
      if (getTablesList.status === 'success' && getTablesList.code === 200) {
        // experimentId
        // console.log('getTablesList', experimentId);
        // console.log('getTablesList', getTablesList.data[0].project_id);
        const filteredTableNames = getTablesList.data.filter(
          (item) => Number(item.project_id) === Number(experimentId)
        );
        // console.log('getTablesList', filteredTableNames);
        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: { key: 'exitsTableList', value: filteredTableNames }
        });
      } else {
        enqueueSnackbar(getTablesList?.message, {
          variant: 'error',
          autoHideDuration: 2000
        });
      }
      const getNodeLabels = await GetApiByUrl('visual/neo4j/get_all?labels=true');
      if (getNodeLabels.status === 'success' && getNodeLabels.code === 200) {
        // console.log('neo4jNodeLabel', getNodeLabels.data);
        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: {
            key: 'neo4jNodeLabel',
            value:
              Object.entries(getNodeLabels.data.labels).length > 0 ? getNodeLabels.data.labels : []
          }
        });
      }
      const getEdgeLabels = await GetApiByUrl('visual/neo4j/get_all?relation=true');
      if (getEdgeLabels.status === 'success' && getEdgeLabels.code === 200) {
        // console.log('neo4jEdgeLabel', getEdgeLabels.data);
        dispatch({
          type: 'STORE_LISTS_DATA',
          payload: { key: 'neo4jEdgeLabel', value: getEdgeLabels.data.relation }
        });
      }
    })();
  }, [
    cyToState
    // cySelectedElement.id
  ]);

  return (
    <Box className="nodeInfo editorInfo">
      <Box sx={{ mb: 1, p: '0px 0px 0px 8px' }}>
        <Accordion className="accordT" expanded={true}>
          <AccordionSummary
            className="accordTrigger"
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{ content: 'accordTgrtxt' }}
          >
            <Typography className="typoAccord">Project</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordSummery">
            <Typography className="userVisual" variant="h2" mb={1}>
              <RenderIcons icon={getIcon(UserIcon, null, 13, 13)} />
              {experimentName}
              <Typography variant="body2" className="spanCustom">
                #{experimentId}
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* 
        <Box sx={{ display: 'flex-col' }}>
          <Box>
            <Typography className="typoAccord">Visualization</Typography>
          </Box>
          <Box>
            <Typography className="userVisual" variant="h2">
              <RenderIcons icon={getIcon(UserIcon, null, 13, 13)} />
              {experimentName}
              <Typography variant="body2" className="spanCustom">
                #{experimentId}
              </Typography>
            </Typography>
          </Box>
          <Typography>
            {cyToState.$('#' + cySelectedElement.id).data('label') === ' '
              ? 'Untitled'
              : cyToState.$('#' + cySelectedElement.id).data('label')}{' '}
            #{cySelectedElement.id}
          </Typography> 
        </Box>
        */}
      </Box>
      {slectedOption === 'node' && cySelectedElement.type === 'node' ? (
        <Box
          sx={{ height: '83vh', overflowY: 'auto', paddingBottom: '30px' }}
          className="nodeProperties"
        >
          <Box sx={{ border: 1, borderColor: '#ddd', mb: 1, p: 1 }}>
            <Accordion
              className="accordT"
              expanded={expanded === 'nodeSource'}
              onChange={() => setExpanded('nodeSource')}
            >
              <AccordionSummary
                className="accordTrigger"
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ content: 'accordTgrtxt' }}
              >
                <Typography className="typoAccord">Source</Typography>
              </AccordionSummary>

              <AccordionDetails className="accordSummery">
                <Box
                  style={{ width: '99%' }}
                  border={1}
                  p={1}
                  mt={1}
                  mb={1}
                  backgroundColor="#dddddd45"
                  borderColor="#111"
                  borderRadius={1}
                >
                  {/* <Typography>Source Node {cyToState.$('#' + cySelectedElement.id).data('label')}</Typography> */}
                  <Typography className="labelHeading">Source Node Label Type</Typography>
                  {/* {neo4jNodeLabel && neo4jNodeLabel.length > 0 ? ( */}
                  <Autocomplete
                    freeSolo
                    // options={neo4jNodeLabel}
                    options={[]}
                    renderInput={(params) => {
                      // console.log('cyToStatenodeId', cyToState.$('#' + cySelectedElement.id).data('label'));

                      const allParams = {
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          value: cyToState.$('#' + cySelectedElement.id).data('label')
                            ? cyToState.$('#' + cySelectedElement.id).data('label')
                            : ''
                        }
                      };
                      return (
                        <TextField
                          {...allParams}
                          variant="standard"
                          placeholder="Type label name"
                          onBlur={(e) => {
                            const islabelExits = colorCodes.filter((code) => code[e.target.value]);
                            // console.log('newcolorCodes', e.target.value);
                            if (islabelExits.length === 0) {
                              const newColorCode = Math.floor(Math.random() * 16777215).toString(
                                16
                              );
                              const mergePrevColor = colorCodes;
                              mergePrevColor.push({ [e.target.value]: '#' + newColorCode });
                              // console.log('newcolorCodes', mergePrevColor);
                              setColroCodes([...mergePrevColor]);
                              setStyling([...styling]);
                              // console.log('colorcodes', newColorCode);
                              dispatch({
                                type: 'CREATE_KG',
                                payload: {
                                  key: 'colorCodes',
                                  value: [...colorCodes, ...mergePrevColor]
                                }
                              });
                              setRefreshKGComp(new Date());
                            }
                          }}
                        />
                      );
                    }}
                    onChange={(e, newvalue) => {
                      if (cySelectedElement.id) {
                        const prevData = cyToState.$('#' + cySelectedElement.id).data();
                        console.log('nodeData onchange', prevData);
                        cyToState
                          .$('#' + cySelectedElement.id)
                          .data({ ...prevData, label: newvalue });
                        // setStyling([...styling]);
                        // handleChangeRelationship(e, value);
                      }
                      // console.log('abcNewVal', newvalue);
                      // cyToState.$('#' + cySelectedElement.id).data('label', newvalue);
                    }}
                    onInputChange={(e, newvalue) => {
                      if (slectedOption === 'node') {
                        const prevData = cyToState.$('#' + cySelectedElement.id).data();
                        console.log('nodeData inputchange', prevData);
                        cyToState
                          .$('#' + cySelectedElement.id)
                          .data({ ...prevData, label: newvalue });
                        // setStyling([...styling]);
                      }
                      // console.log('abcNewVal', newvalue);
                      // cyToState.$('#' + cySelectedElement.id).data('label', newvalue);
                    }}
                    {...currentLabel}
                  />
                  {/* ) : (
                    ''
                  )} */}
                </Box>
                <Box
                  style={{ width: '99%' }}
                  border={1}
                  p={1}
                  mt={1}
                  mb={1}
                  backgroundColor="#dddddd45"
                  borderColor="#111"
                  borderRadius={1}
                >
                  <Typography className="labelHeading">Source dataset</Typography>
                  {exitsTableList && exitsTableList.length > 0 ? (
                    <Autocomplete
                      options={exitsTableList}
                      getOptionLabel={(option) => option.table_name}
                      renderInput={(params) => {
                        console.log('newparams', params);

                        const allParams = {
                          ...params,
                          inputProps: {
                            ...params.inputProps,
                            value: getSettablename(cyToState.$('#' + cySelectedElement.id).data())
                              ? getSettablename(cyToState.$('#' + cySelectedElement.id).data())
                              : ''
                          }
                        };
                        return (
                          <TextField
                            {...allParams}
                            variant="standard"
                            placeholder="type and find dataset"
                          />
                        );
                      }}
                      onChange={(e, newvalue) => {
                        // console.log('abcNewVal', newvalue);
                        // const filtredProperties = exitsTableList.filter(
                        //   (item) => item.table_name === newvalue
                        // );
                        // console.log('abcNewVal', filtredProperties);
                        cyToState.$('#' + cySelectedElement.id).data('datasetValue', newvalue);
                        cyToState.$('#' + cySelectedElement.id).data('propertiesValue', []);
                        cyToState
                          .$('#' + cySelectedElement.id)
                          .data('properties', newvalue.columns_schema);
                        setRefreshComp(new Date());
                      }}
                      {...sourceDatasettable}
                    />
                  ) : (
                    // If exitsTableList not exits or length is not greater than 0 on Redux store states
                    <Select
                      className="col-2 form-control selectControl"
                      variant="standard"
                      disabled
                      value=" "
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=" ">Select</MenuItem>
                    </Select>
                  )}
                </Box>
                <Box
                  style={{ width: '99%' }}
                  border={1}
                  p={1}
                  mt={1}
                  mb={1}
                  backgroundColor="#dddddd45"
                  borderColor="#111"
                  borderRadius={1}
                >
                  <Typography className="labelHeading">Properties</Typography>
                  <Autocomplete
                    multiple
                    options={
                      cyToState.$('#' + cySelectedElement.id).data() !== undefined
                        ? cyToState.$('#' + cySelectedElement.id).data().properties
                        : []
                    }
                    onChange={(e, newValue) => {
                      if (cySelectedElement.id) {
                        let prevData = {};
                        if (cyToState.$('#' + cySelectedElement.id).data()) {
                          prevData = cyToState.$('#' + cySelectedElement.id).data();
                        }
                        console.log('nodeData', prevData);
                        cyToState
                          .$('#' + cySelectedElement.id)
                          .data({ ...prevData, propertiesValue: newValue });
                        setRefreshComp(new Date());
                      }

                      // console.log('propertiesValue', newValue);
                      // cyToState.$('#' + cySelectedElement.id).data('propertiesValue', newValue);
                      // setRefreshComp(new Date());
                    }}
                    disableCloseOnSelect
                    // getOptionLabel={(option) => option.Field}
                    getOptionLabel={(option) => option.app_column_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={getIcon(CheckBoxOutlineBlank, null, 15, 15)}
                          checkedIcon={getIcon(CheckBoxIcon, null, 15, 15)}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.app_column_name}
                      </li>
                    )}
                    value={
                      cyToState.$('#' + cySelectedElement.id).data()?.propertiesValue
                        ? cyToState.$('#' + cySelectedElement.id).data().propertiesValue
                        : []
                    }
                    renderInput={(params) => {
                      console.log('propParams', params);

                      // const allParams = {
                      //   ...params,
                      //   inputProps: {
                      //     ...params.inputProps,
                      //     value: cyToState.$('#' + cySelectedElement.id).data()
                      //       ? cyToState.$('#' + cySelectedElement.id).data().propertiesValue
                      //       : ''
                      //   }
                      // };
                      return <TextField {...params} placeholder="Search..." variant="standard" />;
                    }}
                  />
                </Box>

                <FormControl fullWidth className="form-row size-col-2">
                  <Typography className="labelHeading col-2">Source Label</Typography>
                  <Select
                    className="col-2 form-control selectControl"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      cyToState.$('#' + cySelectedElement.id).data() !== undefined
                        ? cyToState.$('#' + cySelectedElement.id).data().labelType
                          ? cyToState.$('#' + cySelectedElement.id).data().labelType
                          : ' '
                        : ' '
                    }
                    variant="standard"
                    onChange={(e) => {
                      cyToState.$('#' + cySelectedElement.id).data('labelType', e.target.value);
                      setRefreshComp(new Date());
                    }}
                  >
                    <MenuItem value=" ">Select</MenuItem>
                    {cyToState.$('#' + cySelectedElement.id).data('propertiesValue')
                      ? cyToState
                          .$('#' + cySelectedElement.id)
                          .data('propertiesValue')
                          .map((item, index) => {
                            console.log(item);
                            return (
                              <MenuItem value={item.app_column_name} key={index}>
                                {item.app_column_name}
                              </MenuItem>
                            );
                          })
                      : ''}
                  </Select>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </Box>
          {/* for target nodes parts */}
          {/* <Box sx={{ border: 1, borderColor: '#ddd', mb: 0, p: 1 }}>
            <Accordion
              className="accordT"
              expanded={expanded === 'nodeTarget'}
              onChange={() => setExpanded('nodeTarget')}
            >
              <AccordionSummary
                className="accordTrigger"
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ content: 'accordTgrtxt' }}
              >
                <Typography className="typoAccord">Target(s)</Typography>
              </AccordionSummary>

              <AccordionDetails className="accordSummery">
                {cySelectedElement.id !== undefined ? (
                  cyToState.$('#' + cySelectedElement.id).data().targeted &&
                  cyToState.$('#' + cySelectedElement.id).data().targeted.length > 0 ? (
                    <>
                      <Box style={{ border: '4px dashed #ddd', padding: '6px' }}>
                        <Box
                          style={{ width: '99%' }}
                          backgroundColor="#ddd"
                          pb={1}
                          pt={1}
                          pl={2}
                          pr={2}
                          mb={1}
                        >
                          <strong>Existing target(s)</strong>
                        </Box>
                        {cyToState
                          .$('#' + cySelectedElement.id)
                          .data()
                          .targeted.map((item, index) => {
                            // console.log('item22', cyToState.$('#' + item).data());
                            return (
                              <Box style={{ width: '99%' }}>
                                <Box>
                                  <Box className="gbleAccord2">
                                    <Accordion className="accordT">
                                      <AccordionSummary
                                        expanded={
                                          targetExpended === cyToState.$('#' + item).data().id
                                        }
                                        onChange={() =>
                                          setTargetExpended(cyToState.$('#' + item).data().id)
                                        }
                                        // expandIcon={<ExpandMoreIcon />}
                                        className="accordTrigger"
                                        classes={{ content: 'accordTgrtxt' }}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography className="typoAccord">
                                          {cyToState.$('#' + item).data().label}
                                        </Typography>
                                      </AccordionSummary>
                                    </Accordion>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                      <Box style={{ width: '99%', padding: '6px 0' }}>
                        <Typography variant="h3" textAlign="center">
                          Or
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <Box style={{ border: '4px dashed #ddd', padding: '6px' }}>
                  <Box style={{ width: '99%' }} backgroundColor="#ddd" pb={1} pt={1} pl={2} pr={2}>
                    <strong>Create new target</strong>
                  </Box>
                  <Box
                    style={{ width: '99%' }}
                    border={1}
                    p={1}
                    mt={1}
                    mb={1}
                    backgroundColor="#dddddd45"
                    borderColor="#111"
                    borderRadius={1}
                  >
                    <Typography className="labelHeading">Target Node Label Type</Typography>

                    <Autocomplete
                      freeSolo
                      options={neo4jNodeLabel}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" placeholder="Type label name" />
                      )}
                      onChange={(e, newvalue) => {
                        setTargetData(newvalue);
                      }}
                      onInputChange={(e, newvalue) => {
                        setTargetData(newvalue);
                      }}
                    />
                  </Box>
                  <Box
                    style={{ width: '99%' }}
                    border={1}
                    p={1}
                    mt={1}
                    mb={1}
                    backgroundColor="#dddddd45"
                    borderColor="#111"
                    borderRadius={1}
                  >
                    <Typography className="labelHeading">Target Dataset</Typography>
                    {exitsTableList && exitsTableList.length > 0 ? (
                      <Autocomplete
                        options={exitsTableList}
                        getOptionLabel={(option) => option.table_name}
                        renderInput={(params) => <TextField {...params} variant="standard" />}
                        onChange={(e, newvalue) => {
                          setTargetDatasetValue(newvalue);
                          setPropertiesData(newvalue.columns_schema);
                        }}
                      />
                    ) : (
                      // If exitsTableList not exits or length is not greater than 0 on Redux store states
                      <Select
                        className="col-2 form-control selectControl"
                        variant="standard"
                        disabled
                        value=" "
                        style={{ width: '100%' }}
                      >
                        <MenuItem value=" ">Select</MenuItem>
                      </Select>
                    )}
                  </Box>
                  <Box
                    style={{ width: '99%' }}
                    border={1}
                    p={1}
                    mt={1}
                    mb={1}
                    backgroundColor="#dddddd45"
                    borderColor="#111"
                    borderRadius={1}
                  >
                    <Typography className="labelHeading">Properties</Typography>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={propertiesData}
                      onChange={(event, newValue) => {
                        // console.log('targetnewValue', newValue);
                        setTargetNodeColumnData(newValue);
                        // setTargetPropertiesValue([
                        //   ...cyToState
                        //     .$('#' + cySelectedElement.id)
                        //     .data()
                        //     .properties.filter((item) =>
                        //       Object.values(commonTableColumns).flat(2).includes(item.app_column_name)
                        //     ),
                        //   ...newValue
                        // ]);
                      }}
                      // value={
                      //   targetPropertiesValue.length > 0 && exitsTableList.length > 0
                      //     ? [...new Set(targetPropertiesValue)]
                      //     : []
                      // }
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.app_column_name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={getIcon(CheckBoxOutlineBlank, null, 15, 15)}
                            checkedIcon={getIcon(CheckBoxIcon, null, 15, 15)}
                            style={{ marginRight: 8 }}
                            // checked={
                            //   selected ||
                            //   Object.values(commonTableColumns)
                            //     .flat(2)
                            //     .some((label) => label === option.app_column_name)
                            // }
                          />
                          {option.app_column_name}
                        </li>
                      )}
                      // style={{ width: 230 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Search..." variant="standard" />
                      )}
                    />
                    <FormControl fullWidth className="form-row size-col-2">
                      <Typography className="labelHeading col-2">Target Label</Typography>
                      <Select
                        className="col-2 form-control selectControl"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={targetDataLabelType}
                        variant="standard"
                        onChange={(e) => setTargetDataLabelType(e.target.value)}
                      >
                        <MenuItem value=" ">Select</MenuItem>
                        {targetNodeColumnData.map((item, index) => {
                          // console.log(item);
                          return (
                            <MenuItem value={item.app_column_name} key={index}>
                              {item.app_column_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box style={{ width: '99%', padding: '6px 0' }}>
                  <Typography variant="h3" textAlign="center">
                    Or
                  </Typography>
                </Box>
                <Box style={{ border: '4px dashed #ddd', padding: '6px' }}>
                  <Box style={{ width: '99%' }}>Select node from canvas</Box>
                  <Box style={{ width: '99%' }}>
                    <Select
                      className="col-2 form-control selectControl"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={targteFromNodes}
                      variant="standard"
                      onChange={(e) => {
                        setTargetFromNodes(e.target.value);
                        // console.log(
                        //   'nodeItem',
                        //   cyToState.nodes('#' + e.target.value).data().datasetValue
                        // );
                        setPropertiesData(
                          cyToState.nodes('#' + e.target.value).data().datasetValue.columns_schema
                        );
                        setTargetDatasetValue(
                          cyToState.nodes('#' + e.target.value).data().datasetValue
                        );
                      }}
                      style={{ width: '100%' }}
                    >
                      <MenuItem value=" ">Select</MenuItem>
                      {cyToState.nodes().length > 0
                        ? cyToState.nodes().map((nodeItem, nodeIndex) => {
                            // console.log(
                            //   'nodeItem',
                            //   nodeItem.data(),
                            //   cyToState.$('#' + cySelectedElement.id).data()
                            // );
                            if (nodeItem.data() && cyToState.$('#' + cySelectedElement.id).data()) {
                              if (nodeItem.data().label && cyToState.$('#' + cySelectedElement.id).data().label) {
                                if (
                                  nodeItem.data().label !== cyToState.$('#' + cySelectedElement.id).data().label
                                ) {
                                  return (
                                    <MenuItem key={nodeItem.data().id} value={nodeItem.data().id}>
                                      {nodeItem.data().label}
                                    </MenuItem>
                                  );
                                } else {
                                  return '';
                                }
                              } else {
                                return '';
                              }
                            }
                          })
                        : ''}
                    </Select>
                  </Box>

                  <Box sx={{ pt: 1, pb: 1 }} textAlign="right">
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        // for edge
                        cyToState.add({
                          data: {
                            source: cySelectedElement.id,
                            target: targteFromNodes,
                            label: relationshipName,
                            properties: relationshipProp
                          }
                        });
                        // console.log('cyToState25', cyToState.$(`#${cySelectedElement.id}`).data());
                        // console.log('cyToState25', cyToState.$('#' + cySelectedElement.id).data().targeted);
                        const prevTargetedIds = cyToState.$('#' + cySelectedElement.id).data().targeted;
                        prevTargetedIds.push(targteFromNodes);
                        const sourceData = {
                          targeted: prevTargetedIds
                        };
                        cyToState.$('#' + cySelectedElement.id).data(sourceData); // pushing all connected targeted node id to the source node
                        // console.log('cyToState25', cyToState.$('#' + cySelectedElement.id).data(sourceData));
                        // setTargetData('');
                        // setRelationshipName('');
                        // console.log('cyToState25', cyToState.elements());
                        setRefreshComp(new Date());
                        expandGraphByNodeNeighborhood(cyToState, cySelectedElement.id);
                      }}
                    >
                      Add &nbsp;
                      <RenderIcons icon={getIcon(AddIcon, null, 25, 25)} />
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box> */}
          {/* for relationship part */}

          {/* <Box sx={{ border: 1, borderColor: '#ddd', mb: 1, p: 1 }}>
            <Accordion
              className="accordT"
              expanded={expanded === 'relationInfo'}
              onChange={() => setExpanded('relationInfo')}
            >
              <AccordionSummary
                className="accordTrigger"
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ content: 'accordTgrtxt' }}
              >
                <Typography className="typoAccord">Relationship</Typography>
              </AccordionSummary>

              <AccordionDetails className="accordSummery">
                <Box
                  style={{ width: '99%' }}
                  border={1}
                  p={1}
                  mt={1}
                  mb={1}
                  backgroundColor="#dddddd45"
                  borderColor="#111"
                  borderRadius={1}
                >
                  <Autocomplete
                    disablePortal
                    // id="combo-box-demo"
                    freeSolo
                    options={neo4jEdgeLabel}
                    onChange={(e, value) => handleChangeRelationship(e, value)} // print the selected value
                    onInputChange={(e, newInputValue) => handleChangeRelationship(e, newInputValue)}
                    renderInput={(params) => (
                      <TextField
                        // value={relationshipName}
                        {...params}
                        variant="standard"
                        placeholder="Type and find relation"
                      />
                    )}
                  />
                </Box>
                {cyToState &&
                  cyToState.$('#' + cySelectedElement.id) &&
                  cyToState.$('#' + cySelectedElement.id).data() &&
                  cyToState.$('#' + cySelectedElement.id).data().properties &&
                  propertiesData.length > 0 && (
                    <Box
                      style={{ width: '99%' }}
                      border={1}
                      p={1}
                      mt={1}
                      mb={1}
                      backgroundColor="#dddddd45"
                      borderColor="#111"
                      borderRadius={1}
                    >
                      <Typography className="labelHeading">Edge Properties</Typography>
                      <Autocomplete
                        multiple
                        // id="checkboxes-tags-demo"
                        options={
                          cyToState.$('#' + cySelectedElement.id).data() !== undefined &&
                          propertiesData.length > 0
                            ? [
                                ...cyToState.$('#' + cySelectedElement.id).data().propertiesValue,
                                ...propertiesData
                              ]
                            : []
                        }
                        onChange={(e, newValue) => {
                          setRelationshipProp(newValue);
                          setRefreshComp(new Date());
                        }}
                        value={relationshipProp}
                        disableCloseOnSelect
                        // getOptionLabel={(option) => option.Field}
                        getOptionLabel={(option) => option.app_column_name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={getIcon(CheckBoxOutlineBlank, null, 15, 15)}
                              checkedIcon={getIcon(CheckBoxIcon, null, 15, 15)}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.app_column_name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Search..." variant="standard" />
                        )}
                      />
                    </Box>
                  )}
              </AccordionDetails>
            </Accordion>

            <Box sx={{ pt: 1, pb: 1 }}>
              <Button
                variant="outlined"
                onClick={(e) => {
                  addNewNode(
                    e,
                    cyToState,
                    cySelectedElement.id,
                    targetData,
                    relationshipName,
                    sourceDatasetValue,
                    propertiesData,
                    sourceNodeValue,
                    setTargetData,
                    targetDataLabelType,
                    setTargetDataLabelType,
                    setRelationshipName,
                    relationshipProp,
                    setRelationshipProp,
                    targteFromNodes,
                    setTargetFromNodes
                  );
                  expandGraphByNodeNeighborhood(cyToState, cySelectedElement.id);
                }}
              >
                Add &nbsp;
                <RenderIcons icon={getIcon(AddIcon, null, 25, 25)} />
              </Button>
            </Box>
          </Box> */}
        </Box>
      ) : slectedOption === 'edge' && cySelectedElement.type === 'edge' ? (
        <Box sx={{ border: 1, borderColor: '#ddd', mb: 1, p: 1 }}>
          <Box
            style={{ width: '99%' }}
            border={1}
            p={1}
            mt={1}
            mb={1}
            backgroundColor="#dddddd45"
            borderColor="#111"
            borderRadius={1}
          >
            {/* {neo4jEdgeLabel && neo4jEdgeLabel.length > 0 ? ( */}
            <Autocomplete
              disablePortal
              freeSolo
              options={neo4jEdgeLabel && neo4jEdgeLabel.length > 0 ? neo4jEdgeLabel : []}
              onChange={(e, value) => {
                if (cySelectedElement.id) {
                  const prevData = cyToState.$('#' + cySelectedElement.id).data();
                  console.log('edgeData', prevData);
                  cyToState.$('#' + cySelectedElement.id).data({ ...prevData, label: value });
                  setStyling([...styling]);
                  handleChangeRelationship(e, value);
                }
              }} // print the selected value
              onInputChange={(e, newInputValue) => {
                if (slectedOption === 'edge') {
                  const prevData = cyToState.$('#' + cySelectedElement.id).data();
                  console.log('edgeData', prevData);
                  cyToState
                    .$('#' + cySelectedElement.id)
                    .data({ ...prevData, label: newInputValue });
                  setStyling([...styling]);
                }
                handleChangeRelationship(e, newInputValue);
              }}
              defaultValue={relationshipName}
              value={
                cyToState.$('#' + cySelectedElement.id).data()?.label
                  ? cyToState.$('#' + cySelectedElement.id).data().label
                  : ''
              }
              renderInput={(params) => {
                const allParams = {
                  ...params,
                  inputProps: {
                    ...params.inputProps,
                    value: relationshipName
                  }
                };
                console.log('paramsparams', params);
                console.log('paramsparams', relationshipName);
                return (
                  <TextField {...allParams} variant="standard" placeholder="Relationship name" />
                );
              }}
            />
            {/* ) : (
              ''
            )} */}
          </Box>
          {propertyFromEdge.length > 0 ? (
            <Box
              style={{ width: '99%' }}
              border={1}
              p={1}
              mt={1}
              mb={1}
              backgroundColor="#dddddd45"
              borderColor="#111"
              borderRadius={1}
            >
              <Typography className="labelHeading">Edge Properties</Typography>
              {propertyFromEdge && propertyFromEdge.length > 0 ? (
                <Autocomplete
                  multiple
                  options={propertyFromEdge}
                  onChange={(e, newValue) => {
                    if (cySelectedElement.id) {
                      let prevData = {};
                      if (cyToState.$('#' + cySelectedElement.id).data()) {
                        prevData = cyToState.$('#' + cySelectedElement.id).data();
                      }
                      console.log('edgeData', prevData);
                      cyToState
                        .$('#' + cySelectedElement.id)
                        .data({ ...prevData, properties: newValue });
                      setRelationshipProp(newValue);
                      setRefreshComp(new Date());
                    }
                  }}
                  // value={relationshipProp}
                  value={
                    cyToState.$('#' + cySelectedElement.id).data()?.properties
                      ? cyToState.$('#' + cySelectedElement.id).data().properties
                      : []
                  }
                  disableCloseOnSelect
                  // getOptionLabel={(option) => option.Field}
                  getOptionLabel={(option) => option.app_column_name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={getIcon(CheckBoxOutlineBlank, null, 15, 15)}
                        checkedIcon={getIcon(CheckBoxIcon, null, 15, 15)}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.app_column_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search..." variant="standard" />
                  )}
                />
              ) : (
                ''
              )}
            </Box>
          ) : (
            <Typography sx={{ color: 'red', mt: 2 }}>
              Please select source and target node's datasets first.
            </Typography>
          )}
        </Box>
      ) : (
        ''
      )}

      <Dialog
        open={ErrorPopup}
        onClose={(e) => {
          showErrorPopup(false);
        }}
      >
        {/* // onClose={(e) => handleCloseVisualization()} */}
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Error
            <Button
              className="submitBtn glbSubmitBtn"
              classes={{
                root: 'globalSubmitButton globalSubmitButtonOk'
              }}
              onClick={(e) => {
                showErrorPopup(false);
              }}
            >
              <GrClose color="#fff" style={{ color: '#fff' }} />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Mapping doesn't exist between both the Datasets.</DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default NodeInfo;

function getSettablename(nodeData) {
  if (nodeData && nodeData.datasetValue) {
    const defltvalue = nodeData.datasetValue.table_name;
    return defltvalue;
  }
  return '';
}

function getTargetLabel(nodeData) {
  return '';
}
