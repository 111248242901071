import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, Grid } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../animate';
import Page from './Page';
import TopNavigationBar from './TopNavigationBar';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------
/**
 * 404 page
 * @class
 * @return {React.ReactElement} - React component
 */
function Page404() {
  return (
    <>
      {/* Previous called JSX without custom header applied 
    // <RootStyle title="404 Page Not Found | Minimal-UI">
    //   <Container> */}
      <Page title="Error 404 | Page Not Found">
        <Container maxWidth="xl" className="noPadding bg-h-100">
          <TopNavigationBar />
          <Grid container marginTop={0} spacing={5} id="k-Graph-wrap">
            <Grid item xs={12} id="k-graphRight" sx={{ backgroundColor: '#f4f8fd' }}>
              <Box className="graphRight-info active" style={{ justifyContent: 'center' }}>
                <MotionContainer initial="initial" open>
                  <Box
                    sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center', paddingTop: '15px' }}
                  >
                    <motion.div variants={varBounceIn}>
                      <Typography variant="h3" paragraph>
                        Sorry, page not found!
                      </Typography>
                    </motion.div>
                    <Typography sx={{ color: 'text.secondary' }}>
                      Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped
                      the URL? Be sure to check your spelling.
                    </Typography>

                    <motion.div variants={varBounceIn}>
                      <Box
                        component="img"
                        src="/static/illustrations/illustration_404.svg"
                        sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                      />
                    </motion.div>

                    <Button to="/" size="large" variant="contained" component={RouterLink}>
                      Go to Home
                    </Button>
                  </Box>
                </MotionContainer>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
      {/* </RootStyle> */}
    </>
  );
}
export default Page404;
