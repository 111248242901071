import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Paper,
  styled,
  Button,
  Typography,
  InputLabel,
  Autocomplete,
  Modal,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import './ruleEngineQuery.css';
import { queries } from '@testing-library/react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import CustomizedTextField from './CustomizedTextField';
import CustomizedTextFieldGraphEdges from './CustomizedTextFieldGraphEdges';
import RuleEngineQuery from './RuleEngineQuery';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';

const operators = [
  { name: '=', label: 'Equals', type: 'string' },
  { name: 'contains', label: 'Contains', type: 'string' },
  { name: 'starts_with', label: 'Starts With', type: 'string' },
  { name: 'ends_with', label: 'Ends With', type: 'string' },
  { name: 'matches', label: 'Matches (Regex)', type: 'string' },
  { name: 'like', label: 'Like (SQL Wildcards)', type: 'string' },
  { name: 'i_equals', label: 'Case-Insensitive Equals', type: 'string' }
];

const initialQuery = { rules: [] };

function EdgeRuleEngine(props) {
  const {
    currentMetaDataId,
    setRefreshStyleComp,
    cyToStateTwo,
    labelEdgeToDisplay,
    selectedEdge,
    setSelectedEdge,
    toggleSaveModal,
    setToggleSaveModal,
    savedRulesNameList,
    setSavedRulesNameList,
    setSavedRuleSelection,
    setSavedEdgeRuleData,
    savedEdgeRuleData,
    edgeSavedRulesNameList,
    setEdgeSavedRulesNameList,
    patchToggleSaveModal,
    setPatchToggleSaveModal,
    selectedRules,
    setMutateSavedRuleList
  } = props;
  // Using React Redux useSelector and useDispatch hook for get and set states values into store

  const { experimentId, modelId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { GetApiByUrl } = KnowledgeGraphService;

  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const {
    createKnowledgeGraph: { cyToState }
  } = knowledgeGraphStoreItem;
  const [query, setQuery] = useState(initialQuery);
  const [fieldData, setFieldData] = useState([]);
  const [savedRulesData, setSavedRulesData] = useState([]);

  const [ruleTextFieldName, setRuleTextFieldName] = useState('');
  const [ruleTextFieldErrorMsg, setRuleTextFieldErrorMsg] = useState('');
  // const [savedRulesNameList, setSavedRulesNameList] = useState([]);
  // const [toggleSaveModal, setToggleSaveModal] = useState(false);
  // const [autoCompleteValue, setAutoCompleteValue] = useState('');

  const [expanded, setExpanded] = useState(false);

  const initialEdgeStyles = {
    'line-color': '#f9ab62',
    label: 'data(label)',
    width: '2',
    padding: '0',
    'curve-style': 'bezier',
    'line-style': 'solid',
    'target-arrow-shape': 'triangle',
    'target-arrow-color': '#c4c4c4',
    'arrow-scale': 1,
    'line-opacity': 1,
    'font-size': 9,
    // 'text-margin-y': '-7px',
    'text-valign': 'center',
    'text-halign': 'center',
    color: '#000',
    'text-opacity': 1
  };
  const [allRuleStyleSettings, setAllRuleStyleSettings] = useState(initialEdgeStyles);
  const [dynamicRuleEngineKey, setDynamicRuleEngineKey] = useState('dynamicRuleEngineKey');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default Enter key behavior
      // Do whatever you need with the input value here
      // console.log('Entered value:', inputValue);
    }
  };

  const handleOpen = () => setToggleSaveModal(true);
  const handleEdgeRuleClose = () => {
    // alert('this');
    setToggleSaveModal(false);
    setPatchToggleSaveModal(false);
  };

  const handleEdgeRuleResetValues = () => {
    setAllRuleStyleSettings(initialEdgeStyles);
    setDynamicRuleEngineKey(new Date());
  };

  // -- Push all Nodes properties to the rule engine.
  useEffect(() => {
    if (cyToState) {
      const fieldDataAll = [];
      cyToState.elements().forEach((element) => {
        if (element.data().props !== undefined) {
          fieldDataAll.push(...Object.keys(element.data().props));
        }
      });
      const filteredData = [...new Set(fieldDataAll)];
      const formattedFieldsData = filteredData.map((data) => ({
        name: data,
        label: data
      }));
      setFieldData([...formattedFieldsData]);
    }
  }, [cyToState]);

  // -- Triggers when the user saves a rule to the rule list.
  const handleRuleSave = () => {
    if (savedRulesNameList.length > 0 && ruleTextFieldName) {
      const labelExists = savedRulesNameList.some((obj) => obj.label === ruleTextFieldName);
      if (labelExists) {
        setRuleTextFieldErrorMsg('Name already exists.');
      }
      if (!labelExists) {
        const newSavedRuleName = { label: String(ruleTextFieldName) };
        setSavedRulesNameList((prevNames) => [...prevNames, newSavedRuleName]);

        const newRuleData = {
          ruleName: ruleTextFieldName,
          ruleSettings: allRuleStyleSettings
        };
        setSavedEdgeRuleData((prevDataArray) => [...prevDataArray, newRuleData]);
        setToggleSaveModal(false);
        setRuleTextFieldErrorMsg('');
        // console.log('queryqueryquery', newRuleData);
      }
      setRuleTextFieldName('');
    } else if (savedRulesNameList.length === 0 && ruleTextFieldName) {
      const newSavedRuleName = { label: String(ruleTextFieldName) };
      setSavedRulesNameList((prevNames) => [...prevNames, newSavedRuleName]);

      const newRuleData = {
        ruleName: ruleTextFieldName,
        ruleSettings: allRuleStyleSettings
      };
      setSavedEdgeRuleData((prevDataArray) => [...prevDataArray, newRuleData]);
      setRuleTextFieldErrorMsg('');
      setToggleSaveModal(false);
      setRuleTextFieldName('');
    }
  };

  // Debounce function for style properties with different delays
  const debounceTimers = {};

  const debouncedUpdateState = (styleProperty, value, delay) => {
    clearTimeout(debounceTimers[styleProperty]);
    debounceTimers[styleProperty] = setTimeout(() => {
      setAllRuleStyleSettings((prevSettings) => ({
        ...prevSettings,
        [styleProperty]: value
      }));
      setRefreshStyleComp(new Date());
    }, delay);
  };

  const handleStyleValueChange = (e, styleProperty, debounceDelay) => {
    const newValue = e.target.value;

    if (debounceDelay) {
      debouncedUpdateState(styleProperty, newValue, debounceDelay);
    } else {
      setAllRuleStyleSettings((prevSettings) => ({
        ...prevSettings,
        [styleProperty]: newValue
      }));
      setRefreshStyleComp(new Date());
    }
  };

  const GridItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));

  useEffect(() => {
    if (patchToggleSaveModal) {
      (async () => {
        const metaResponse = await GetApiByUrl(
          `visual/manage_metadata?link_id=${modelId}&project_id=${experimentId}`
        );

        if (
          metaResponse.status === 'success' &&
          metaResponse.code === 200 &&
          // --------------- Model Specific ---------------------------
          metaResponse.data.metadata.length > 0 &&
          Object.keys(metaResponse.data.metadata[0].edge_rules).length > 0
        ) {
          const savedRuleId = selectedRules[0].id;

          const { edge_rules } = metaResponse.data.metadata[0];
          if (Object.keys(edge_rules).length > 0 && edge_rules[savedRuleId]) {
            const _savedStyles = {
              color: edge_rules[savedRuleId]?.color,
              label: edge_rules[savedRuleId]?.label,
              width: edge_rules[savedRuleId]?.width,
              padding: edge_rules[savedRuleId]?.padding,
              'font-size': edge_rules[savedRuleId]['font-size'] ?? '11px',
              'line-color': edge_rules[savedRuleId]['line-color'] ?? 'red',
              'arrow-scale': edge_rules[savedRuleId]['arrow-scale'] ?? 1,
              'curve-style': edge_rules[savedRuleId]['curve-style'] ?? 'unbundled-bezier',
              'text-halign': edge_rules[savedRuleId]['text-halign'] ?? 'center',
              'text-valign': edge_rules[savedRuleId]['text-halign'] ?? 'center',
              'line-opacity': edge_rules[savedRuleId]['line-opacity'] ?? 100,
              'text-opacity': edge_rules[savedRuleId]['text-opacity'] ?? 100,
              'target-arrow-color': edge_rules[savedRuleId]['target-arrow-color'] ?? '#f21f07',
              'target-arrow-shape': edge_rules[savedRuleId]['target-arrow-shape'] ?? 'triangle',
              'line-style': edge_rules[savedRuleId]['line-style'] ?? 'solid'
            };
            setAllRuleStyleSettings(_savedStyles);
          } else {
            console.log('no data found');
          }
        }
      })();
    }
  }, [patchToggleSaveModal]);

  return (
    <Box>
      <Modal
        open={toggleSaveModal || patchToggleSaveModal}
        onClose={handleEdgeRuleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '65%',
            background: 'white',
            border: '1px solid #ddd',
            boxShadow: 24,
            padding: '1.25rem 1rem',
            height: 'auto',
            maxHeight: '80vh',
            borderRadius: 8,
            overflow: 'scroll'
          }}
          className="drawerWrap"
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '-1rem',
              marginBottom: '8px',
              padding: '0.50rem 1rem 0.25rem 1rem',
              backgroundColor: '#061d6b'
            }}
          >
            <Typography variant="h6" style={{ color: '#fff' }}>
              {patchToggleSaveModal ? 'Edit Edge Rule' : 'Create Edge Rule'}
            </Typography>
            <CloseIcon onClick={handleEdgeRuleClose} style={{ cursor: 'pointer', color: '#fff' }} />
          </Box>
          {/* <Grid container spacing={1}>
            <Grid xs={6} md={6}>
              <GridItem className="RuleEngineGridItemFlex">
                <Typography className="labelGridItem">Rule Name</Typography>
                <CustomizedTextField
                  ruleTextFieldName={ruleTextFieldName}
                  setRuleTextFieldName={setRuleTextFieldName}
                  ruleTextFieldErrorMsg={ruleTextFieldErrorMsg}
                  placeholder="Enter rule Name here..."
                />
              </GridItem>
            </Grid>

            <Grid xs={6} md={6}>
              <GridItem className="RuleEngineGridItemFlex">
                <Typography className="labelGridItem">Label</Typography>
                <FormControl
                  variant="standard"
                  style={{ width: '100%' }}
                  className="inputGridItemField"
                >
                  
                  <Select
                    className="form-control selectControl"
                    value={selectedEdge}
                    onChange={(e) => {
                      setSelectedEdge(e.target.value);
                      if (e.target.value !== 'all') {
                        const selectedStyle = cyToStateTwo
                          .edges(`[label='${e.target.value}']`)
                          .style();
                        if (selectedStyle) {
                          const prevObj = {};
                          Object.keys(allRuleStyleSettings).map((key) => {
                            if (key.includes('color')) {
                              let red = selectedStyle[key].replace('rgb(', '');
                              red = red.replace(')', '');

                              prevObj[key] = rgbToHex(red.split(','));
                            } else {
                              prevObj[key] = selectedStyle[key];
                            }
                          });
                          // console.log('checkLabel', prevObj);
                          // rgbToHex
                          setAllRuleStyleSettings(prevObj);
                        }
                      }
                    }}
                    label="edgeProperty"
                  >
                    <MenuItem value="all">All</MenuItem>
                    {labelEdgeToDisplay.length > 0 &&
                      labelEdgeToDisplay.map((edge) => (
                        <MenuItem value={edge.label}>{edge.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          </Grid> */}

          <Accordion
            className="accordTRuleEngine"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              className="accordTrigger"
              aria-controls="panel1d-content"
              id="panel1d-header"
              classes={{ content: 'accordTgrtxt' }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '0.825rem',
                  fontWeight: '500',
                  marginLeft: '5px'
                }}
                gutterBottom
              >
                Customize Edge Properties:
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
              <Grid container spacing={2}>
                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Type</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Edge Type</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['curve-style']}
                      onChange={(e) => handleStyleValueChange(e, 'curve-style')}
                      // setRefreshStyleComp(new Date());
                      label="edgeProperty"
                    >
                      <MenuItem value="bezier">Bezier</MenuItem>
                      <MenuItem value="unbundled-bezier">Unbundled-Bezier</MenuItem>
                      <MenuItem value="haystack">Haystack</MenuItem>
                      <MenuItem value="segments">Segments</MenuItem>
                      <MenuItem value="taxi">Taxi</MenuItem>
                      <MenuItem value="straight">Straight</MenuItem>
                      <MenuItem value="straight-triangle">Straight-Triangle</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Width</Typography>
                  {/* <CustomizedTextFieldGraphEdges
                    allRuleStyleSettings={allRuleStyleSettings}
                    setAllRuleStyleSettings={setAllRuleStyleSettings}
                    setRefreshStyleComp={setRefreshStyleComp}
                    propType="width"
                  /> */}

                  <TextField
                    variant="standard"
                    size="small"
                    value={allRuleStyleSettings.width}
                    className="inputGridItemField"
                    onChange={(e) => handleStyleValueChange(e, 'width')}
                    error={ruleTextFieldErrorMsg ? true : false}
                    helperText={ruleTextFieldErrorMsg}
                  />
                </Grid>

                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Line Style</Typography>
                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Edge Line Style</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['line-style']}
                      onChange={(e) => handleStyleValueChange(e, 'line-style', 300)}
                    >
                      <MenuItem value="solid">Solid</MenuItem>
                      <MenuItem value="dotted">Dotted</MenuItem>
                      <MenuItem value="dashed">Dashed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Line Color</Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    variant="standard"
                    className="inputGridItemField"
                  >
                    <input
                      type="color"
                      style={{
                        width: '20px',
                        height: '24px',
                        marginRight: '15px',
                        padding: 0
                      }}
                      value={allRuleStyleSettings['line-color']}
                      onChange={(e) => handleStyleValueChange(e, 'line-color', 300)}
                    />
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{ className: 'shiftLeft' }}
                      style={{
                        width: 'auto',
                        marginLeft: '5px',
                        BorderBottom: 'solid'
                      }}
                      value={allRuleStyleSettings['line-color']}
                      disabled
                    />
                    {/* <RenderIcons icon={getIcon(ColorPanelIcon, null, 18, 18)} /> */}
                  </Box>
                </Grid>
                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Line Opacity</Typography>
                  <Slider
                    // value={allRuleStyleSettings['line-opacity']}
                    value={allRuleStyleSettings['line-opacity']}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'line-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ margin: '15px auto auto auto' }}
                  />
                </Grid>
                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Arrow Size</Typography>
                  <TextField
                    variant="standard"
                    size="small"
                    value={allRuleStyleSettings['arrow-scale']}
                    className="inputGridItemField"
                    onChange={(e) => handleStyleValueChange(e, 'arrow-scale')}
                    error={ruleTextFieldErrorMsg ? true : false}
                    helperText={ruleTextFieldErrorMsg}
                  />
                </Grid>

                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Arrow Style</Typography>

                  <FormControl variant="standard" className="inputGridItemField">
                    {/* <InputLabel id="demo-simple-select-label">Edge Arrow Style</InputLabel> */}
                    <Select
                      className="form-control selectControl"
                      value={allRuleStyleSettings['target-arrow-shape']}
                      onChange={(e) => {
                        setAllRuleStyleSettings((prevSettings) => ({
                          ...prevSettings,
                          'target-arrow-shape': e.target.value
                        }));
                        // setRefreshStyleComp(new Date());
                      }}
                      label="edgeProperty"
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="triangle">Triangle</MenuItem>
                      <MenuItem value="triangle-tee">Triangle-Tee</MenuItem>
                      <MenuItem value="circle-triangle">Circle-Triangle</MenuItem>
                      <MenuItem value="triangle-cross">Triangle-Cross</MenuItem>
                      <MenuItem value="triangle-backcurve">Triangle-Backcurve</MenuItem>
                      <MenuItem value="vee">Vee</MenuItem>
                      <MenuItem value="tee">Tee</MenuItem>
                      <MenuItem value="chevron">chevron</MenuItem>
                      <MenuItem value="square">Square</MenuItem>
                      <MenuItem value="circle">Circle</MenuItem>
                      <MenuItem value="diamond">Diamond</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Edge Arrow Color</Typography>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="inputGridItemField"
                  >
                    <input
                      type="color"
                      style={{
                        width: '20px',
                        height: '24px',
                        marginRight: '15px',
                        padding: 0
                      }}
                      value={allRuleStyleSettings['target-arrow-color']}
                      onChange={(e) => handleStyleValueChange(e, 'target-arrow-color', 300)}
                    />
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{ className: 'shiftLeft' }}
                      style={{
                        width: 'auto',
                        marginLeft: '5px',
                        BorderBottom: 'solid'
                      }}
                      value={allRuleStyleSettings['target-arrow-color']}
                      disabled
                    />
                    {/* <RenderIcons icon={getIcon(ColorPanelIcon, null, 18, 18)} /> */}
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className="accordTRuleEngine"
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              className="accordTrigger"
              aria-controls="panel2d-content"
              id="panel2d-header"
              classes={{ content: 'accordTgrtxt' }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '0.825rem',
                  fontWeight: '500',
                  marginLeft: '5px'
                }}
                gutterBottom
              >
                Customize Edge Labels:
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
              <Grid container spacing={2}>
                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Font Size</Typography>
                  <TextField
                    variant="standard"
                    size="small"
                    value={allRuleStyleSettings['font-size']}
                    className="inputGridItemField"
                    onChange={(e) => handleStyleValueChange(e, 'font-size')}
                    error={ruleTextFieldErrorMsg ? true : false}
                    helperText={ruleTextFieldErrorMsg}
                  />
                </Grid>
                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem"> Color</Typography>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="inputGridItemField"
                  >
                    <input
                      type="color"
                      style={{
                        width: '20px',
                        height: '24px',
                        marginRight: '15px',
                        padding: 0
                      }}
                      value={allRuleStyleSettings.color}
                      onChange={(e) => handleStyleValueChange(e, 'color', 300)}
                    />
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{ className: 'shiftLeft' }}
                      style={{
                        width: 'auto',
                        marginLeft: '5px',
                        BorderBottom: 'solid'
                      }}
                      value={allRuleStyleSettings.color}
                      disabled
                    />
                    {/* <RenderIcons icon={getIcon(ColorPanelIcon, null, 18, 18)} /> */}
                  </Box>
                </Grid>

                <Grid xs={6} md={3} className="RuleEngineGridItemFlex">
                  <Typography className="labelGridItem">Text Opacity</Typography>
                  <Slider
                    value={allRuleStyleSettings['text-opacity']}
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e) => handleStyleValueChange(e, 'text-opacity')}
                    size="small"
                    valueLabelDisplay="auto"
                    sx={{ margin: '15px auto auto auto' }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* <QueryBuilder
            fields={fieldData}
            operators={operators}
            query={query}
            onQueryChange={(q) => setQuery(q)}
            controlElements={{
              addGroupAction: () => null
            }}
            independentCombinators
            showCloneButtons
            showLockButtons
          />
          <Button
            sx={{ float: 'right', marginTop: '20px' }}
            onClick={handleRuleSave}
            variant={'contained'}
          >
            Save Rule
          </Button> */}

          <RuleEngineQuery
            key={dynamicRuleEngineKey}
            currentMetaDataId={currentMetaDataId}
            stylesheet={allRuleStyleSettings}
            savedRulesNameList={edgeSavedRulesNameList}
            setSavedRulesNameList={setEdgeSavedRulesNameList}
            closeRuleSaveModel={handleEdgeRuleClose}
            ruleType="edge_rule"
            handleEdgeRuleResetValues={handleEdgeRuleResetValues}
            selectedRules={selectedRules}
            patchToggleSaveModal={patchToggleSaveModal}
            setMutateSavedRuleList={setMutateSavedRuleList}
          />

          {/* Edge Rule 1 */}
          {/* <Accordion
            className="accordTRuleEngine"
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              className="accordTrigger"
              aria-controls="panel1d-content"
              id="panel1d-header"
              classes={{ content: 'accordTgrtxt' }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: '0.825rem', fontWeight: '500', marginLeft: '5px' }}
                gutterBottom
              >
                Source Node Rule:
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
              <RuleEngineQuery
                currentMetaDataId={currentMetaDataId}
                stylesheet={allRuleStyleSettings}
                savedRulesNameList={edgeSavedRulesNameList}
                setSavedRulesNameList={setEdgeSavedRulesNameList}
                ruleType="edge_rule1"
              />
            </AccordionDetails>
          </Accordion> */}

          {/* Edge Rule 2 */}
          {/* <Accordion
            className="accordTRuleEngine"
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary
              className="accordTrigger"
              aria-controls="panel1d-content"
              id="panel1d-header"
              classes={{ content: 'accordTgrtxt' }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: '0.825rem', fontWeight: '500', marginLeft: '5px' }}
                gutterBottom
              >
                Relationship Rule:
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
              <RuleEngineQuery
                currentMetaDataId={currentMetaDataId}
                stylesheet={allRuleStyleSettings}
                savedRulesNameList={edgeSavedRulesNameList}
                setSavedRulesNameList={setEdgeSavedRulesNameList}
                ruleType="edge_rule2"
              />
            </AccordionDetails>
          </Accordion> */}

          {/* Edge Rule 3 */}
          {/* <Accordion
            className="accordTRuleEngine"
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
          >
            <AccordionSummary
              className="accordTrigger"
              aria-controls="panel1d-content"
              id="panel1d-header"
              classes={{ content: 'accordTgrtxt' }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: '0.825rem', fontWeight: '500', marginLeft: '5px' }}
                gutterBottom
              >
                Target Node Rule:
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
              <RuleEngineQuery
                currentMetaDataId={currentMetaDataId}
                stylesheet={allRuleStyleSettings}
                savedRulesNameList={edgeSavedRulesNameList}
                setSavedRulesNameList={setEdgeSavedRulesNameList}
                ruleType="edge_rule3"
              />
            </AccordionDetails>
          </Accordion> */}
        </Box>
      </Modal>
    </Box>
  );
}

export default EdgeRuleEngine;

function rgbToHex(r, g, b) {
  // console.log('rrr', r);
  const hexR = Math.floor(r[0]).toString(16).padStart(2, '0');
  const hexG = Math.floor(r[1]).toString(16).padStart(2, '0');
  const hexB = Math.floor(r[2]).toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
}
