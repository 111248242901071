import { userDataFromLocal } from '../utils/getUserDetails';

// const _authToken = userDataFromLocal();

const statusMessages = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status (WebDAV)',
  208: 'Already Reported (WebDAV)',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: '(Unused)',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect (experimental)',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I am a teapot (RFC 2324)',
  420: 'Enhance Your Calm (Twitter)',
  422: 'Unprocessable Entity (WebDAV)',
  423: 'Locked (WebDAV)',
  424: 'Failed Dependency (WebDAV)',
  425: 'Reserved for WebDAV',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'No Response (Nginx)',
  449: 'Retry With (Microsoft)',
  450: 'Blocked by Windows Parental Controls (Microsoft)',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request (Nginx)',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates (Experimental)',
  507: 'Insufficient Storage (WebDAV)',
  508: 'Loop Detected (WebDAV)',
  509: 'Bandwidth Limit Exceeded (Apache)',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  598: 'Network read timeout error',
  599: 'Network connect timeout error'
};
async function firePatchApi(endpoint, credentials, authToken) {
  const _authToken = userDataFromLocal();
  const headers = {
    'Content-Type': 'application/json'
  };

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function fireApi(endpoint, credentials, authToken) {
  const _authToken = userDataFromLocal();
  const headers = {
    'Content-Type': 'application/json'
  };

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function fireApiNoToken(endpoint, credentials) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log('errorerror', error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firePostFormDataApi(endpoint, credentials, authToken) {
  const _authToken = userDataFromLocal();
  const headers = {};

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'POST',
    headers: headers,
    body: credentials
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function postFileApi(endpoint, credentials, authToken) {
  const _authToken = userDataFromLocal();
  const headers = {
    'Content-Type': 'application/json'
  };

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      // console.log(response);

      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function putToApi(endpoint, credential, authToken) {
  const _authToken = userDataFromLocal();
  const headers = {
    'Content-Type': 'application/json'
  };

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(credential)
  })
    .then((response) => {
      // console.log(response);
      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function putToApiFormData(endpoint, credential, authToken) {
  const _authToken = userDataFromLocal();
  const headers = {};

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'PUT',
    headers: headers,
    body: credential
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        message: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        message: 'Server not responding',
        data: []
      };
    });
}
async function putToApiNoToken(endpoint, credential) {
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credential)
  })
    .then((response) => {
      // console.log(response);
      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function deleteToApi(endpoint, credentials, authToken) {
  const _authToken = userDataFromLocal();
  const headers = {
    'Content-Type': 'application/json'
  };

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      // console.log(response);

      if (response.ok && response.status === 200) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function deleteToApiNoToken(endpoint, credentials) {
  return fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      // console.log(response);

      if (response.ok && response.status === 200) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function getToApiNoToken(endpoint, authToken) {
  // const _authToken = userDataFromLocal();
  const headers = {
    'Content-Type': 'application/json'
  };

  // if (authToken && typeof authToken === 'string' && authToken !== '') {
  //   headers.Authorization = `Bearer ${authToken}`;
  // } else {
  //   headers.Authorization = `Bearer ${_authToken.token}`;
  // }
  return fetch(endpoint, {
    method: 'GET',
    headers: headers
  })
    .then((response) => {
      // console.log(response);

      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function getToApi(endpoint, authToken) {
  const _authToken = userDataFromLocal();
  console.log('authToken getToApi', authToken);
  const headers = {
    'Content-Type': 'application/json'
  };

  if (authToken && typeof authToken === 'string' && authToken !== '') {
    headers.Authorization = `Bearer ${authToken}`;
  } else {
    headers.Authorization = `Bearer ${_authToken.token}`;
  }
  return fetch(endpoint, {
    method: 'GET',
    headers: headers
  })
    .then((response) => {
      // console.log(response);

      if (response.ok) {
        return response.json();
      } else if (!response.ok) {
        return response.json();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch(() => {
      // console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

export default function useApiServices() {
  const postApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await fireApi(endPoint, credential, authToken);
    return isDataPosted;
  };
  const patchApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await firePatchApi(endPoint, credential, authToken);
    return isDataPosted;
  };

  const postApiNoToken = async (endPoint, credential) => {
    const isDataPosted = await fireApiNoToken(endPoint, credential);
    return isDataPosted;
  };

  const postFormDataApi = async (endPoint, credential) => {
    const isDataPosted = await firePostFormDataApi(endPoint, credential);
    return isDataPosted;
  };

  const postFileData = async (endPoint, credential) => {
    const isDataPosted = await postFileApi(endPoint, credential);
    return isDataPosted;
  };

  const putApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await putToApi(endPoint, credential, authToken);
    return isDataPosted;
  };
  const putApiNoToken = async (endPoint, credential) => {
    const isDataPosted = await putToApiNoToken(endPoint, credential);
    return isDataPosted;
  };

  const getApiNoToken = async (endPoint) => {
    // console.log('readfromJsion', endPoint);
    const isDataPosted = await getToApiNoToken(endPoint);
    return isDataPosted;
  };
  const getApi = async (endPoint, authToken) => {
    // console.log('readfromJsion', endPoint, authToken);
    const isDataPosted = await getToApi(endPoint, authToken);
    return isDataPosted;
  };

  const deleteApi = async (endPoint, credential, authToken) => {
    const isDataPosted = await deleteToApi(endPoint, credential, authToken);
    return isDataPosted;
  };
  const deleteApiNoToken = async (endPoint, credential) => {
    const isDataPosted = await deleteToApiNoToken(endPoint, credential);
    return isDataPosted;
  };
  const putApiFormData = async (endPoint, credential, authToken) => {
    const isDataPosted = await putToApiFormData(endPoint, credential, authToken);
    return isDataPosted;
  };

  return {
    postApiNoToken,
    postApi,
    putApi,
    putApiNoToken,
    getApiNoToken,
    getApi,
    deleteApi,
    deleteApiNoToken,
    postFileData,
    postFormDataApi,
    patchApi,
    deleteToApiNoToken,
    putApiFormData
  };
}
