import { Image, MobileScreenShare, Share, SystemUpdateAlt } from '@material-ui/icons';
// import cytoscape from 'cytoscape';
// import clipboard from 'cytoscape-clipboard';
import {
  ContentCopy,
  ContentCut,
  ContentPasteGoSharp,
  ResetTv,
  VerticalAlignCenter,
  ZoomIn,
  RestartAlt,
  CancelPresentation
} from '@mui/icons-material';

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Checkbox
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import React, { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import JsPDF from 'jspdf';
import {
  EditIcon,
  HomeIcon,
  JpgIcon,
  PdfIcon,
  PngIcon,
  XmlIcon,
  getIcon
} from '../../../vector/index';
import RenderIcons from '../../global/RenderIcons';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import FullScreenTogglePage from './FullScreenTogglePage';
import SearchNodesAndEdges from './SearchNodesAndEdges';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';

const { GetApiByUrl, ShareUserList, SaveSharedUserList } = KnowledgeGraphService;
// import FullScreenToggle from './OptionPlate';
// State management

// clipboard(cytoscape);

function OptionsPlate(props) {
  const { currentprojectData, resetGraphFunction } = props;
  const dispatch = useDispatch();
  const [visualData, setVisualData] = useState({ projectName: '', projectId: '' });
  const { enqueueSnackbar } = useSnackbar();
  const { experimentId, modelId } = useParams();
  const { searchBarRef } = useRef();

  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };

  useEffect(() => {
    if (currentprojectData) {
      if (currentprojectData.projects) {
        setVisualData({
          projectName: currentprojectData.projects.project_name,
          projectId: currentprojectData.projects.project_id
        });
      }
    }
  }, [currentprojectData]);

  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const {
    createKnowledgeGraph: { cyToState }
  } = knowledgeGraphStoreItem;
  // console.log('satyam cyto', cyToState);

  const { selectedGraphTabValue, cytoDataAllGraph, snackbarToggle } = useSelector(
    (state) => state.knowledgeGraph.allSelectedData
  );

  // cyToState.use(clipboard);
  // const clipboard = cyToState.clipboard();

  const navigate = useNavigate();
  const [anchorElem, setAnchorElem] = useState(null);
  const open = Boolean(anchorElem);
  const handlePopClick = (event) => {
    setAnchorElem(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorElem(null);
  };

  const setGraphZoomLevel = (e, newValue) => {
    cyToState.zoom({
      level: newValue / 10,
      position: { x: 500, y: 500 }
    });
  };

  const panelLeftSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBar',
        value: newValue
      }
    });
  };

  const panelRightSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBarRight',
        value: newValue
      }
    });
  };

  const updateCanvas = (type) => {
    //   // Make Document Full Screen
    //   if (type === 'fullscreen') {
    //     const elem = document.documentElement;
    //     if (isFullScreen) {
    //       if (document.exitFullscreen) {
    //         document.exitFullscreen();
    //       } else if (document.webkitExitFullscreen) {
    //         // Safari
    //         document.webkitExitFullscreen();
    //       } else if (document.msExitFullscreen) {
    //         // IE11
    //         document.msExitFullscreen();
    //       }
    //       setIsFullScreen(false);
    //     } else {
    //       if (elem.requestFullscreen) {
    //         elem.requestFullscreen();
    //       }
    //       setIsFullScreen(true);
    //     }
    //   }

    if (type === 'reset_styles') {
      if (cyToState.elements().length > 0) {
        cyToState.elements().removeStyle();
        cyToState.elements().removeClass('faded selected');
      }
    }
    if (type === 'reset') {
      resetGraphFunction();
      cyToState.fit();
    }

    if (type === 'aligncanvascenter') {
      // cyToState.removeListener('zoom');

      cyToState.center();
      panelLeftSideBar(false);
      panelRightSideBar(false);
    }

    if (type === 'fitscreen') {
      cyToState.removeListener('zoom');
      if (type === 'fitscreen') {
        cyToState.removeListener('zoom');
        cyToState.center();
        cyToState.fit();
        panelLeftSideBar(false);
        panelRightSideBar(false);
      }
    }

    if (type === 'pastecanvas') {
      const copiedElementsId = cyToState.data('copiedElements');
      const cutElementsId = cyToState.data('cutElements');
      const operationType = cyToState.data('operationType');

      if (copiedElementsId.length <= 0) {
        const toggleSnackBar = { trigger: true, message: 'Clipboard is empty!' };

        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'snackbarToggle',
            value: toggleSnackBar
          }
        });

        return;
      }

      if (copiedElementsId.length > 0) {
        // console.log('pastecanvas copy:', copiedElementsId);
        // console.log('pastecanvas cut:', cutElementsId);
        // console.log('pastecanvas operation:', operationType);
        // console.log('pastecanvas cytoData:', cytoDataAllGraph);

        const targetGraphDataObject = cytoDataAllGraph.find(
          (item) => item.label.toLowerCase() === operationType.targetGraph.toLowerCase()
        );

        const filteredGraphData = targetGraphDataObject.graphData.filter((item) =>
          copiedElementsId.includes(String(item.data.id))
        );
        // console.log('pastecanvas filteredGraphData:', filteredGraphData);

        const updatedArray = [...cytoDataAllGraph];
        // console.log('pastecanvas updatedArray:', updatedArray);

        const indexOfSearch = updatedArray.findIndex(
          (item) => item.label.toLowerCase() === selectedGraphTabValue.toLowerCase()
        );
        const indexOf_targetGraphDataObject = cytoDataAllGraph.findIndex(
          (item) => item.label.toLowerCase() === operationType.targetGraph.toLowerCase()
        );
        // console.log('pastecanvas index:', indexOfSearch);

        if (indexOfSearch !== -1) {
          const toggleSnackBar = { trigger: true, message: 'Item pasted!' };

          dispatch({
            type: 'STORE_ALL_SELECTED_DATA',
            payload: {
              key: 'snackbarToggle',
              value: toggleSnackBar
            }
          });
          if (operationType.type === 'copy') {
            updatedArray[indexOfSearch] = {
              ...updatedArray[indexOfSearch],
              graphData: [...updatedArray[indexOfSearch].graphData, ...filteredGraphData]
            };
          }
          if (operationType.type === 'cut') {
            let filterTargetGraphData = [];

            if (operationType.targetGraph.toLowerCase() === selectedGraphTabValue.toLowerCase()) {
              filterTargetGraphData = targetGraphDataObject.graphData;
              operationType.removedElements.restore();
            } else if (
              cutElementsId.length > 0 &&
              operationType.targetGraph.toLowerCase() !== selectedGraphTabValue.toLowerCase()
            ) {
              filterTargetGraphData = targetGraphDataObject.graphData.filter(
                (item) => !cutElementsId.includes(String(item.data.id))
              );
            }

            cyToState.data('copiedElements', []);
            cyToState.data('cutElements', []);

            updatedArray[indexOf_targetGraphDataObject] = {
              ...updatedArray[indexOf_targetGraphDataObject],
              graphData: [...filterTargetGraphData]
            };

            updatedArray[indexOfSearch] = {
              ...updatedArray[indexOfSearch],
              graphData: [...updatedArray[indexOfSearch].graphData, ...filteredGraphData]
            };
          }

          dispatch({
            type: 'STORE_ALL_SELECTED_DATA',
            payload: {
              key: 'cytoDataAllGraph',
              value: [...updatedArray]
            }
          });

          dispatch({
            type: 'STORE_ALL_SELECTED_DATA',
            payload: {
              key: 'kgUpdate',
              value: new Date()
            }
          });
        }
      }
    }
    if (type === 'cutcanvas') {
      // console.log('satyam keydown:', cyToState);
      const selectedElements = cyToState.elements(':selected');
      const copiedElementsId = selectedElements.map((item) => item.id());

      const removedElements = cyToState.remove(selectedElements);
      if (selectedElements.length > 0) {
        const toggleSnackBar = { trigger: true, message: 'Item cut!' };

        const copiedEdgesIds = selectedElements
          .filter((element) => element.group() === 'edges')
          .map((element) => element.id());

        const copiedNodesIds = selectedElements
          .filter((element) => element.group() === 'nodes')
          .map((element) => element.id());

        const targetGraphDataObject = cytoDataAllGraph.find(
          (item) => item.label.toLowerCase() === selectedGraphTabValue.toLowerCase()
        );

        const copiedEdges = targetGraphDataObject.graphData.filter((item) =>
          copiedEdgesIds.includes(item.data.id)
        );

        const filteredEdgeId = copiedEdges
          .filter(
            (edge) =>
              copiedElementsId.some((id) => id === edge.data.source) &&
              copiedElementsId.some((id) => id === edge.data.target)
          )
          .map((edge) => edge.data.id);

        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'snackbarToggle',
            value: toggleSnackBar
          }
        });
        cyToState.data('copiedElements', [...copiedNodesIds, ...filteredEdgeId]);
        cyToState.data('cutElements', [...copiedNodesIds, ...copiedEdgesIds]);
        cyToState.data('operationType', {
          type: 'cut',
          targetGraph: selectedGraphTabValue,
          removedElements: removedElements
        });
      }
    }
    if (type === 'copycanvas') {
      const selectedElements = cyToState.elements(':selected');
      const copiedElementsId = selectedElements.map((item) => item.id());

      if (selectedElements.length > 0) {
        const toggleSnackBar = { trigger: true, message: 'Item copied!' };

        const copiedEdgesIds = selectedElements
          .filter((element) => element.group() === 'edges')
          .map((element) => element.id());

        const copiedNodesIds = selectedElements
          .filter((element) => element.group() === 'nodes')
          .map((element) => element.id());

        const targetGraphDataObject = cytoDataAllGraph.find(
          (item) => item.label.toLowerCase() === selectedGraphTabValue.toLowerCase()
        );

        const copiedEdges = targetGraphDataObject.graphData.filter((item) =>
          copiedEdgesIds.includes(item.data.id)
        );

        // console.log('satyam edge2', copiedEdges);

        const filteredEdgeId = copiedEdges
          .filter(
            (edge) =>
              copiedElementsId.some((id) => id === edge.data.source) &&
              copiedElementsId.some((id) => id === edge.data.target)
          )
          .map((edge) => edge.data.id);

        // console.log('satyam edge', filteredEdgeId);

        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'snackbarToggle',
            value: toggleSnackBar
          }
        });
        cyToState.data('copiedElements', [...copiedNodesIds, ...filteredEdgeId]);

        cyToState.data('operationType', { type: 'copy', targetGraph: selectedGraphTabValue });
      }
    }
  };
  const [exportBoxOpen, setExportBoxOpen] = useState(false);
  const [shareBoxOpen, setShareBoxOpen] = useState(false);
  const [shareInAppData, setShareInAppData] = useState({ open: false, userList: [] });
  const [userListData, setUserListData] = useState([]);
  const theme = useTheme();
  const dialogfullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleExportBoxOpen = () => {
    setExportBoxOpen(true);
  };

  const handleExportBoxClose = () => {
    setExportBoxOpen(false);
  };

  const handleShareBoxOpen = () => {
    setShareBoxOpen(true);
  };

  const handleShareBoxClose = () => {
    setShareBoxOpen(false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));

  // Export graph
  const exportFiles = (type) => {
    if (type === 'exportXml') {
      const xmltext = cyToState.graphml();
      const pom = document.createElement('a');

      const filename = 'graph' + new Date() + '.xml';
      const bb = new Blob([xmltext], { type: 'text/plain' });

      pom.setAttribute('href', window.URL.createObjectURL(bb));
      pom.setAttribute('download', filename);

      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
      pom.draggable = true;
      pom.classList.add('dragout');
      pom.click();
    } else {
      let imgurl = '';
      if (type === 'exportJpg') {
        imgurl = cyToState.jpg();
      }

      if (type === 'exportPng') {
        imgurl = cyToState.png();
      }

      if (type === 'pdf') {
        imgurl = cyToState.jpg();
        const img = new Image();
        const doc = new JsPDF('landscape');

        img.src = imgurl;

        console.log('imgurl', imgurl);
        img.onload = () => {
          const aspectRatio = img.width / img.height;

          const pageWidth = doc.internal.pageSize.getWidth() - 30;
          const maxWidth = pageWidth;
          const maxHeight = maxWidth / aspectRatio;

          doc.addImage(imgurl, 'JPG', 10, 10, maxWidth, maxHeight);
          doc.save(`canvas_image${new Date()}.pdf`);
        };
        return true;
      }

      const pom = document.createElement('a');
      const filename = 'graph' + new Date() + imgurl;

      pom.setAttribute('href', imgurl);
      pom.setAttribute('download', filename);

      // pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
      pom.draggable = true;
      pom.classList.add('dragout');
      pom.click();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (cyToState && event.ctrlKey) {
        switch (event.key) {
          case 'c':
            updateCanvas('copycanvas');
            break;
          case 'v':
            updateCanvas('pastecanvas');
            break;
          case 'x':
            updateCanvas('cutcanvas');
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [cyToState, selectedGraphTabValue, cytoDataAllGraph]);

  useEffect(() => {
    if (shareInAppData.open) {
      (async () => {
        try {
          const authToken = userDataFromLocal()?.token;
          const param = { projID: experimentId, userID: userDataFromLocal()?.userID };
          const _userListData = await ShareUserList(param, authToken);

          if (_userListData.status === 'success' && _userListData.code === 200) {
            setUserListData(_userListData.data);
            // console.log('_userListData', _userListData);
          }
        } catch (error) {
          console.log('error', error);
        }
      })();
    }
  }, [shareInAppData.open]);

  const handleSaveSharedUserData = async () => {
    try {
      const authToken = userDataFromLocal()?.token;
      const cred = {
        // user_ids: [6, 5],
        // shrd_proj: '96'
        user_ids: shareInAppData.userList,
        shrd_proj: experimentId
      };
      const _saveUserData = await SaveSharedUserList(cred, authToken);

      if (_saveUserData.status === 'success' && _saveUserData.code === 200) {
        enqueueSnackbar(_saveUserData.message, {
          variant: 'success',
          autoHideDuration: 2000
        });
        setShareInAppData({ open: false, userList: [] });
        setShareBoxOpen(false);
        // console.log('_saveUserData', _saveUserData);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Box>
      <ValidationMsgOnly
        openValidation={openValidation}
        validationMsg={validationMsg}
        handleClose={handleClose}
      />

      <Box className="MuiBottomContainer">
        <Stack direction="row" spacing={1}>
          <Item>
            <Tooltip title="Cut" arrow>
              <ContentCut
                onClick={() => updateCanvas('cutcanvas')}
                className="bottomIconFontSize"
              />
            </Tooltip>
          </Item>

          <Item>
            <Tooltip title="Copy" arrow>
              <ContentCopy
                onClick={() => updateCanvas('copycanvas')}
                className="bottomIconFontSize"
              />
            </Tooltip>
          </Item>

          <Item>
            <Tooltip title="Paste" arrow>
              <ContentPasteGoSharp
                onClick={() => updateCanvas('pastecanvas')}
                className="bottomIconFontSize"
              />
            </Tooltip>
          </Item>
          <Divider orientation="vertical" flexItem />
          <FullScreenTogglePage />
          <Item
            id="menu-positioned-button"
            aria-controls={open ? 'menu-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handlePopClick}
          >
            <ZoomIn className="bottomIconFontSize" />
          </Item>
          <Divider orientation="vertical" flexItem />
          <Item onClick={() => updateCanvas('aligncanvascenter')}>
            <Tooltip title="Center" arrow>
              <VerticalAlignCenter className="bottomIconFontSize rotate90deg" />
            </Tooltip>
          </Item>

          <Item onClick={() => updateCanvas('reset_styles')}>
            <Tooltip title="Reset Styles" arrow>
              <CancelPresentation className="bottomIconFontSize" />
            </Tooltip>
          </Item>
          <Item
            sx={selectedGraphTabValue !== 'Graph 1' ? { pointerEvents: 'none', opacity: 0.5 } : {}}
            onClick={() => updateCanvas('reset')}
          >
            <Tooltip title="Reset Graph" arrow>
              <RestartAlt className="bottomIconFontSize rotate90deg" />
            </Tooltip>
          </Item>
          <Item onClick={() => updateCanvas('fitscreen')}>
            <Tooltip title="Fit Screen" arrow>
              <ResetTv className="bottomIconFontSize" />
            </Tooltip>
          </Item>

          <Divider orientation="vertical" flexItem />
          <Item onClick={() => handleExportBoxOpen()}>
            <Tooltip title="Export" arrow>
              <SystemUpdateAlt
                className="bottomIconFontSize"
                style={{ transform: ' rotate(-180deg)' }}
              />
            </Tooltip>
          </Item>
          <Item onClick={() => handleShareBoxOpen()}>
            <Tooltip title="Share" arrow>
              <Share className="bottomIconFontSize" />
            </Tooltip>
          </Item>
        </Stack>
      </Box>
      <Box>
        <Dialog
          fullScreen={dialogfullScreen}
          open={exportBoxOpen}
          onClose={() => handleExportBoxClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'center' }}>
            Export As
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                sx={{
                  minWidth: 280,
                  width: '100%!important',
                  overflow: 'auto',
                  p: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  m: 'auto',
                  marginLeft: '0px!important'
                }}
              >
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item sx={{ cursor: 'pointer' }} onClick={() => exportFiles('exportJpg')}>
                    <RenderIcons icon={getIcon(JpgIcon, null, 48, 48)} />
                  </Item>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item sx={{ cursor: 'pointer' }} onClick={() => exportFiles('exportPng')}>
                    <RenderIcons icon={getIcon(PngIcon, null, 48, 48)} />
                  </Item>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item sx={{ cursor: 'pointer' }} onClick={() => exportFiles('pdf')}>
                    <RenderIcons icon={getIcon(PdfIcon, null, 48, 48)} />
                  </Item>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item sx={{ cursor: 'pointer' }} onClick={() => exportFiles('exportXml')}>
                    <RenderIcons icon={getIcon(XmlIcon, null, 48, 48)} />
                  </Item>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExportBoxClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Box>
        {/* Share in app dialog */}
        <Dialog
          fullScreen={dialogfullScreen}
          open={shareInAppData.open}
          onClose={() => setShareInAppData({ open: false, userList: [] })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'center' }}>
            Assign To Users
          </DialogTitle>
          <DialogContent sx={{ padding: '20px 10px', width: '400px' }}>
            {/* <Typography>User List</Typography> */}

            <FormControl fullWidth>
              <Select
                multiple
                value={shareInAppData.userList}
                onChange={(e, val) => {
                  setShareInAppData((prev) => ({ ...prev, userList: e.target.value }));
                }}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => {
                      const user = userListData.find((item) => item.user_id === value);
                      return user ? <span key={value}>{user.username}, </span> : null;
                    })}
                  </div>
                )}
              >
                <MenuItem
                  // disabled
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pointerEvents: 'none',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                  }}
                  value={''}
                >
                  <span>Username</span> - <span>Assigned</span>
                </MenuItem>
                {userListData.length > 0 &&
                  userListData.map((item, index) => (
                    <MenuItem
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                      disabled={item.already_shared}
                      key={index}
                      value={item.user_id}
                    >
                      {item.username} &nbsp;
                      <Checkbox
                        size="small"
                        // defaultChecked={item.already_shared}
                        checked={
                          shareInAppData.userList.includes(item.user_id) || item.already_shared
                        }
                        color={item.already_shared ? 'success' : 'primary'}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShareInAppData({ open: false, userList: [] })}>Close</Button>
            <Button onClick={handleSaveSharedUserData}>Submit</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={dialogfullScreen}
          open={shareBoxOpen}
          onClose={() => handleShareBoxClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'center' }}>
            Share
          </DialogTitle>
          <DialogContent sx={{ padding: '20px 10px' }}>
            <DialogContentText id="alert-dialog-description">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                sx={{
                  minWidth: 420,
                  width: '100%!important',
                  overflow: 'auto',
                  p: 1,
                  justifyContent: 'space-evenly',
                  m: 'auto'
                }}
              >
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                      '&:hover': { background: '#eee' }
                    }}
                    onClick={() => setShareInAppData((prev) => ({ ...prev, open: true }))}
                  >
                    {/* Share A Link */}
                    <MobileScreenShare />
                    Share In App
                  </Item>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                      '&:hover': { background: '#eee' }
                    }}
                    onClick={() => exportFiles('exportPng')}
                  >
                    <Image />
                    Share As Image
                  </Item>
                </Grid>
                {/* <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Item sx={{ cursor: 'pointer' }} onClick={() => exportFiles('exportXml')}>
                    Email Link
                  </Item>
                </Grid> */}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleShareBoxClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Menu
        id="menu-positioned-menu"
        aria-labelledby="menu-positioned-button"
        anchorEl={anchorElem}
        open={open}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          root: 'menuRoot',
          paper: 'menupaper'
        }}
      >
        <MenuItem onClick={handlePopClick}>
          <Slider
            defaultValue={3}
            step={0.1}
            min={1}
            max={10}
            onChange={(event, newValue) => setGraphZoomLevel(event, newValue)}
            size="small"
            aria-label="Small"
            valueLabelDisplay="auto"
            style={{ width: 240, padding: 10 }}
          />
        </MenuItem>
      </Menu>
      <Box className="MuiTopContainer">
        <Stack direction="row" spacing={1}>
          <SearchNodesAndEdges ref={searchBarRef} />
          <Tooltip title="Home">
            <Item
              sx={{ backgroundColor: 'transparent', marginLeft: '0px' }}
              onClick={(e) => navigate('/knowledgegraph/')}
            >
              <RenderIcons
                icon={getIcon(HomeIcon, null, 30, 30)}
                style={{
                  backgroundColor: '#f4f8fd',
                  border: '1px solid',
                  borderRadius: '50%',
                  padding: '7px 3px 7px 14px',
                  width: '40px',
                  height: '40px'
                }}
              />
            </Item>
          </Tooltip>
          <Tooltip title="Edit Model">
            <Item
              sx={{ backgroundColor: 'transparent', marginLeft: '0px!important' }}
              onClick={(e) => {
                if (userDataFromLocal().modules.some((module) => module.module_name === 'Models')) {
                  if (
                    userDataFromLocal().modules.find((module) => module.module_name === 'Models')
                      .permissions.edit
                  ) {
                    navigate(
                      `/knowledgegraph/edit/${visualData.projectId}/${visualData.projectName}/${modelId}`
                    );
                  } else {
                    setValidationMsg('User does not have permission to edit.');
                    setOpenValidation(true);
                  }
                }
              }}
            >
              <RenderIcons
                icon={getIcon(EditIcon, null, 35, 35)}
                style={{
                  backgroundColor: '#f4f8fd',
                  border: '1px solid',
                  borderRadius: '50%',
                  padding: '10px 4px 4px 18px',
                  width: '40px',
                  height: '40px'
                }}
              />
            </Item>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
  );
}

export default OptionsPlate;
