import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import logoVid from '../../../images/kl-logo.mp4';
import kg_logo from '../../../images/kg_logo.png';

export default function LoaderOpaque({ loader, setAllContentLoaded }) {
  const [itemsLoaded, setItemsLoaded] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!loader) {
      setItemsLoaded(true);
    }
  }, [loader]);

  useEffect(() => {
    if (itemsLoaded && !loader) {
      if (videoRef.current) {
        videoRef.current.playbackRate = 1.3;
      }
      setTimeout(() => {
        setItemsLoaded(false);
        setLoadingAnimation(false);
        setAllContentLoaded(true);
      }, 3700);
    }
  }, [itemsLoaded, loader]);

  if (loadingAnimation) {
    return (
      <Box
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          background: 'rgba(255,255,255,1)',
          top: '0',
          left: '0',
          zIndex: '10000000'
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: '10000000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          {loader ? (
            <>
              <img
                className="imgLoadingAnimation"
                style={{ height: 100, width: 100 }}
                // src="https://i.ibb.co/BKmwmTy/logo192.png"
                src={kg_logo}
                alt=""
              />
              <Typography>Please wait...</Typography>
            </>
          ) : itemsLoaded ? (
            <video style={{ height: '400px' }} ref={videoRef} autoPlay muted>
              <source src={logoVid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : null}
        </Box>
      </Box>
    );
  }
}
