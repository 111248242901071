import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link, NavLink } from 'react-router-dom';
import {
  Card,
  Box,
  Stack,
  Container,
  CardContent,
  Badge,
  List,
  ListItem,
  generate,
  ListItemIcon,
  ListItemText,
  Demo,
  MailIcon,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
  Grid,
  Item,
  AppBar,
  IconButton,
  MenuIcon,
  SearchIcon,
  Toolbar,
  InputBase,
  Autocomplete,
  Menu,
  MenuItem,
  Checkbox,
  Divider,
  Avatar
} from '@mui/material';
import { GroupIcon, ExpandLess, ExpandMore, Edit, Logout } from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
// components
import Page from './Page';
import {
  getIcon,
  KnowledgegraphIcon,
  MoreIcon,
  DashboardIcon,
  SharedWithAllIcon,
  RelationShipCategoryIcon,
  DomainMasterIcon,
  RelationshipIcon,
  RoundedPlusIcon,
  GroupUserIcon,
  SettingIcon,
  GearIcon
} from '../../vector';
import RenderIcons from '../global/RenderIcons';

// services
import KnowledgeGraphService from '../../services/KnowledgeGraphService';

import { userDataFromLocal } from '../../utils/getUserDetails';
import { fDate, fDateTime } from '../../utils/formatTime';
import { camlecaseAndUnderScore } from '../../utils/camleCaseAndUScroe';
import FunctionButtons from './KnowledgeGraph/DataGridButtons';

const ITEM_HEIGHT = 48;

const { CreateVisualization, ProjectList, SearchNodes, DeleteNodes, GetApiByUrl, DeleteApiByUrl } =
  KnowledgeGraphService;

/**
 *
 * @returns react-element
 */
function LeftNavigationBar() {
  // react Router Dom Variable
  const navigate = useNavigate();
  // console.log(useLocation());
  const { enqueueSnackbar } = useSnackbar();
  const chartRef = useRef(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [dataFromApi, setDataFromApi] = useState([]);

  // useEffect(() => {
  //   fetchDataFromAPI();
  // }, []);

  // const fetchDataFromAPI = async () => {
  //   fetch('https://jsonplaceholder.typicode.com/posts?_limit=10')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       setDataFromApi(data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  const handleMenuClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userDeatils');
    navigate('/login');
  };

  return (
    <List
      className="k-navList"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '30px',
        alignItems: 'center'
      }}
    >
      <NavLink exact to="/knowledgegraph" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          Home
        </ListItem>
      </NavLink>

      {/* <NavLink exact to="/knowledgegraph" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          AML
        </ListItem>
      </NavLink> */}

      {/* <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
      <NavLink exact to="/tbml" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          TBML
        </ListItem>
      </NavLink> */}
      {/* <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
      <NavLink exact to="/cus360" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          Customer 360
        </ListItem>
      </NavLink> */}
      <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
      <NavLink to="/shared-with-me" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          Shared Visualization
        </ListItem>
      </NavLink>
      <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
      <NavLink to="/usermanagement" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          User Manangement
        </ListItem>
      </NavLink>
      <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
      <NavLink to="/manage-access" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          Manage Access
        </ListItem>
      </NavLink>
      <NavLink to="/datasource" activeClassName="active" className="nav-link">
        <ListItem variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
          Datasource
        </ListItem>
      </NavLink>
      <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
      <ListItem onClick={handleMenuClick}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
            <Avatar src="/broken-image.jpg" />
          </Typography>
        </Box>
      </ListItem>
      <Menu
        anchorEl={anchorElMenu}
        open={Boolean(anchorElMenu)}
        onClose={handleMenuClose}
        sx={{ padding: '0', top: '10px' }}
        PaperProps={{
          className: 'MuiList-root MuiList-padding', // Assign the classes
          style: { padding: '0' } // Apply inline styles
        }}
      >
        {/* <MenuItem onClick={() => navigate('/#')} sx={{ width: '240px' }}>
          {dataFromApi.length > 0 && (
            <Typography
              key={dataFromApi[0].id}
              variant="h6"
              sx={{ fontSize: '0.825rem!important' }}
            >
              {dataFromApi[0].title}
            </Typography>
          )}
        </MenuItem> */}
        <MenuItem sx={{ width: '240px', pointerEvents: 'none' }}>
          <Typography
            variant="h6"
            sx={{ fontSize: '0.825rem!important', textAlign: 'center', width: '100%' }}
          >
            {userDataFromLocal().userName}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => navigate(`/usermanagement/edit/${userDataFromLocal().userName}`)}
          sx={{
            width: '240px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          Edit Profile
          <Edit sx={{ fontSize: '16px !important' }} />
        </MenuItem>
        {/* <MenuItem onClick={() => navigate('/#')} sx={{ width: '240px' }}>
          Customer 360
        </MenuItem> */}
        <MenuItem
          onClick={(e) => {
            handleLogout(e);
          }}
          sx={{
            width: '240px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          Log Out
          <Logout sx={{ fontSize: '16px !important', color: '#722F37' }} />
        </MenuItem>
      </Menu>
    </List>

    // <List
    //   className="k-navList"
    //   sx={{
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'flex-end',
    //     marginRight: '30px'
    //   }}
    // >
    //   <ListItem onClick={(e) => navigate(`/dashboard`)}>
    //     <ListItemIcon>
    //       <RenderIcons icon={getIcon(DashboardIcon, null, 24, 24)} color="primary" />
    //     </ListItemIcon>
    //     <ListItemText primary="Dashboard" className="k-list-navTxt" />
    //   </ListItem>
    //   <ListItem onClick={(e) => navigate(`/domain-master`)}>
    //     <ListItemIcon>
    //       <RenderIcons icon={getIcon(DomainMasterIcon, null, 24, 24)} color="primary" />
    //     </ListItemIcon>
    //     <ListItemText primary="Domain Master" className="k-list-navTxt" />
    //   </ListItem>

    //   <ListItem onClick={(e) => navigate(`/relationship-category-master`)}>
    //     <ListItemIcon>
    //       <RenderIcons icon={getIcon(RelationShipCategoryIcon, null, 24, 24)} color="primary" />
    //     </ListItemIcon>
    //     <ListItemText primary="Relationship Category Master" className="k-list-navTxt" />
    //   </ListItem>

    //   <ListItem onClick={(e) => navigate(`/relationship-master`)}>
    //     <ListItemIcon>
    //       <RenderIcons icon={getIcon(RelationshipIcon, null, 24, 24)} color="primary" />
    //     </ListItemIcon>
    //     <ListItemText primary="Relationship Master (VLA)" className="k-list-navTxt" />
    //   </ListItem>
    //   <NavLink exact to="/knowledgegraph" activeClassName="active" className="nav-link">
    //     <ListItem sx={{ paddingTop: '15px' }}>
    //       <Box>
    //         <ListItemIcon sx={{ marginRight: '0', justifyContent: 'center' }}>
    //           <RenderIcons icon={getIcon(KnowledgegraphIcon, '#666', 40, 40)} />
    //         </ListItemIcon>
    //       </Box>
    //       <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    //         <Typography variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
    //           Visualization <span>#{showData.length}</span>
    //         </Typography>
    //       </Box>
    //     </ListItem>
    //   </NavLink>
    //   <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
    //   <NavLink to="/shared-with-me" activeClassName="active" className="nav-link">
    //     <ListItem sx={{ paddingTop: '15px' }}>
    //       <Box>
    //         <ListItemIcon sx={{ marginRight: '0', justifyContent: 'center' }}>
    //           <RenderIcons icon={getIcon(SharedWithAllIcon, '#666', 40, 40)} />
    //         </ListItemIcon>
    //       </Box>
    //       <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    //         <Typography variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
    //           Favourites <span>#2</span>
    //         </Typography>
    //       </Box>
    //     </ListItem>
    //   </NavLink>
    //   <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
    //   <NavLink to="/usermanagement" activeClassName="active" className="nav-link">
    //     <ListItem sx={{ paddingTop: '15px' }}>
    //       <Box>
    //         <ListItemIcon sx={{ marginRight: '0', justifyContent: 'center' }}>
    //           <RenderIcons icon={getIcon(GroupUserIcon, '#666', 40, 40)} color="primary" />
    //         </ListItemIcon>
    //       </Box>
    //       <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    //         <Typography variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
    //           User Manangement
    //         </Typography>
    //       </Box>
    //     </ListItem>
    //   </NavLink>
    //   <Divider sx={{ width: '1px', backgroundColor: '#e4e4e4' }} />
    //   <ListItem onClick={handleMenuClick} sx={{ paddingTop: '15px' }}>
    //     <Box>
    //       <ListItemIcon sx={{ marginRight: '0', justifyContent: 'center' }}>
    //         <RenderIcons icon={getIcon(SettingIcon, '#666', 40, 40)} color="primary" />
    //       </ListItemIcon>
    //     </Box>
    //     <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    //       <Typography variant="body2" className="k-list-navTxt" sx={{ fontSize: '0.8rem' }}>
    //         Domain
    //       </Typography>
    //       {isOpen ? <ExpandLess /> : <ExpandMore />}
    //     </Box>
    //   </ListItem>

    //   <Menu
    //     anchorEl={anchorElMenu}
    //     open={Boolean(anchorElMenu)}
    //     onClose={handleMenuClose}
    //     sx={{ marginTop: '-3px' }}
    //   >
    //     <MenuItem onClick={() => navigate('/#')}>AML</MenuItem>
    //     <MenuItem onClick={() => navigate('/#')}>TBML</MenuItem>
    //     <MenuItem onClick={() => navigate('/#')}>Customer 360</MenuItem>
    //   </Menu>

    // </List>
  );
}
export default LeftNavigationBar;
