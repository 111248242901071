import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  IconButton,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TextField,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogTitle,
  Modal
} from '@mui/material';
import { Edit, Delete, AddCircleOutline, ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { useSnackbar } from 'notistack';

// components

import Page from '../Page';

// services
import TopNavigationBar from '../TopNavigationBar';
import '../customstyled.css';
import { fDateTime } from '../../../utils/formatTime';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import UserManageAuthService from '../../../services/UserManageAuthService';
import AddUserRole from './AddUserRole';
import { userDataFromLocal } from '../../../utils/getUserDetails';
import ValidationMsgOnly from '../../global/ValidationMsgOnly';
import UserManagenetToolbar from './UserManagenetToolbar';

// user Details

/**
 *
 * @returns react-element
 */

const { DeleteUserListAPI } = UserManageAuthService;

function UserManagement() {
  const navigate = useNavigate();
  const { GetApiByUrl, DeleteApiByUrl } = KnowledgeGraphService;
  const { enqueueSnackbar } = useSnackbar();

  const [userInformationData, setUserInformationData] = useState([]);
  const [userInformationDataArchive, setUserInformationDataArchive] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log('selectedUsers', selectedUsers);
  const [openDelete, setOpenDelete] = useState(false);
  const [userTableSort, setUserTableSort] = useState({
    is_active: null,
    ID: { sortType: 'asc' },
    FIRST_NAME: { sortType: 'asc' },
    LAST_NAME: { sortType: 'asc' },
    USERNAME: { sortType: 'asc' },
    EMAIL: { sortType: 'asc' },
    ADMIN_STATUS: { sortType: 'asc' },
    ACTIVE_STATUS: { sortType: 'asc' },
    CREATION_DATE: { sortType: 'asc' }
  });
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };
  // const [editUserInfo, setEditUserInfo] = useState({
  //   data: {
  //     email_id: '',
  //     first_name: '',
  //     last_name: '',
  //     is_active: 'true',
  //     is_admin: 'false',
  //     password: '',
  //     confirm_password: ''
  //   },
  //   user_id: null,
  //   editModalOpen: false
  // });
  const [confirmUserDeletePopUp, setConfirmUserDeletePopUp] = useState({
    openDialog: false,
    openedFromCheckbox: false,
    user_id: []
  });
  let deleteUserTimeoutVar;
  const [deleteUserBtnLoader, setDeleteUserBtnLoader] = useState(false);

  const handleUserDelete = async (_, user_ids_array) => {
    const authToken = userDataFromLocal();
    const apiPayload = {
      usernames: user_ids_array,
      user_id: authToken.userID
    };

    const deleteUser = await DeleteUserListAPI(apiPayload, authToken.token);

    if (deleteUser.status === 'success') {
      // console.log('deleteUser success');
      setDeleteUserBtnLoader(false);
      setConfirmUserDeletePopUp({
        openDialog: false,
        openedFromCheckbox: false,
        user_id: []
      });
      const filterDeletedUsers = userInformationData.filter(
        (item) => !user_ids_array.includes(item.username)
      );
      console.log('deleteUser', filterDeletedUsers);
      setUserInformationData(filterDeletedUsers);
      enqueueSnackbar(
        user_ids_array.length > 1
          ? `${user_ids_array.length} users deleted successfully.`
          : 'User deleted successfully.',
        {
          variant: 'success',
          autoHideDuration: 4000
        }
      );
    } else {
      setDeleteUserBtnLoader(false);
      setConfirmUserDeletePopUp({
        openDialog: false,
        openedFromCheckbox: false,
        user_id: []
      });
      enqueueSnackbar(
        user_ids_array.length > 1
          ? `Problem while deleting these ${user_ids_array.length} users.`
          : 'Problem while deleting this user.',
        {
          variant: 'error',
          autoHideDuration: 4000
        }
      );
    }
  };

  const manageAccessListApi = async (userName) => {
    try {
      if (userName !== '' && userName !== undefined) {
        const lowerCaseValue = userName.toLowerCase();
        const exactMatches = [];
        const partialMatches = [];

        userInformationDataArchive.map((item) => {
          const _username = String(item.username).toLowerCase();
          const _firstname = String(item.first_name).toLowerCase();
          const _lastname = String(item.last_name).toLowerCase();
          const _email = String(item.email_id).toLowerCase();

          if (
            _username === lowerCaseValue ||
            _firstname === lowerCaseValue ||
            _lastname === lowerCaseValue ||
            _email === lowerCaseValue
          ) {
            exactMatches.unshift(item);
          } else if (
            _username.includes(lowerCaseValue) ||
            _firstname.includes(lowerCaseValue) ||
            _lastname.includes(lowerCaseValue) ||
            _email.includes(lowerCaseValue)
          ) {
            partialMatches.push(item);
          }
        });

        setUserInformationData([...exactMatches, ...partialMatches]);
      } else {
        const _userID = userDataFromLocal()?.userID;
        const getUserInfo = await GetApiByUrl(`visual/userslist?username=&user_id=${_userID}`);
        if (getUserInfo.status === 'success') {
          const fltrArray = getUserInfo.data.filter((data) => (data.id = data?.user_id));
          setUserInformationData(fltrArray);
          setUserInformationDataArchive(fltrArray);
          console.log(getUserInfo, 'getUserInfo');
          // setUserInformationData(getUserInfo.data);
          // setUserInformationData(getUserInfo.data);
        } else if (getUserInfo.status === 'error') {
          setValidationMsg(getUserInfo.message);
          setOpenValidation(true);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // (async () => {
    //   const _userID = userDataFromLocal()?.userID;
    //   const getUserInfo = await GetApiByUrl(`visual/userslist?username=&user_id=${_userID}`);
    //   if (getUserInfo.status === 'success') {
    //     console.log(getUserInfo, 'getUserInfo');
    //     setUserInformationData(getUserInfo.data);
    //   } else if (getUserInfo.status === 'error') {
    //     setValidationMsg(getUserInfo.message);
    //     setOpenValidation(true);
    //   }
    // })();

    manageAccessListApi();
  }, []);

  return (
    <Page title="User Access Management">
      <Container maxWidth="xl" className="noPadding bg-h-100">
        <ValidationMsgOnly
          openValidation={openValidation}
          validationMsg={validationMsg}
          handleClose={handleClose}
        />

        <TopNavigationBar />
        {/* Edit User Modal */}
        {/* <Modal
          open={editUserInfo.editModalOpen}
          onClose={() => {
            setEditUserInfo({
              data: {
                email_id: '',
                first_name: '',
                last_name: '',
                is_active: 'true',
                is_admin: 'false',
                password: '',
                confirm_password: ''
              },
              user_id: null,
              editModalOpen: false
            });
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80vw',
              height: '80vh',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              overflow: 'scroll'
              // p: 4
            }}
          >
            <AddUserRole
              operation_type="edit_user"
              setEditUserInfo={setEditUserInfo}
              editUserInfo={editUserInfo}
              userInformationData={userInformationData}
              setUserInformationData={setUserInformationData}
            />
          </Box>
        </Modal> */}

        {/* Delete Dialog Box */}
        <Dialog
          open={confirmUserDeletePopUp.openDialog}
          onClose={() =>
            setConfirmUserDeletePopUp({ openDialog: false, openedFromCheckbox: false, user_id: [] })
          }
        >
          <DialogTitle>Attention</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ margin: '10px 0px', lineHeight: '1.25rem' }}>
              {confirmUserDeletePopUp.user_id.length > 1
                ? `Are you sure you want to delete these ${confirmUserDeletePopUp.user_id.length} users?`
                : 'Are you sure you want to delete this user?'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() =>
                setConfirmUserDeletePopUp({
                  openDialog: false,
                  openedFromCheckbox: false,
                  user_id: []
                })
              }
            >
              No
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={deleteUserBtnLoader ? { opacity: 0.6, pointerEvents: 'none' } : {}}
              startIcon={
                deleteUserBtnLoader ? (
                  <CircularProgress size={20} sx={{ color: 'black' }} />
                ) : (
                  <Delete />
                )
              }
              onClick={(e) => {
                setDeleteUserBtnLoader(true);
                clearTimeout(deleteUserTimeoutVar);
                deleteUserTimeoutVar = setTimeout(() => {
                  handleUserDelete(e, confirmUserDeletePopUp.user_id);
                }, 400);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container marginTop={0} spacing={5} id="k-Graph-wrap">
          {/* <Grid item xs={3} id="k-graphLeft">
            <LeftNavigationBar />
          </Grid> */}
          <Grid item xs={12} id="k-graphRight" sx={{ backgroundColor: '#f4f8fd' }}>
            <Box className="graphRight-info active">
              <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ color: '#004C99' }}>
                      Manage Users
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '10px'
                      }}
                    >
                      <Button
                        color="error"
                        variant="contained"
                        startIcon={<Delete />}
                        sx={
                          confirmUserDeletePopUp.openedFromCheckbox &&
                          confirmUserDeletePopUp.user_id.length > 0
                            ? {
                                padding: '4px 16px'
                              }
                            : { padding: '4px 16px', pointerEvents: 'none', opacity: 0.5 }
                        }
                        onClick={() => {
                          if (
                            userDataFromLocal().modules.some(
                              (module) => module.module_name === 'User Management'
                            )
                          ) {
                            if (
                              userDataFromLocal().modules.find(
                                (module) => module.module_name === 'User Management'
                              ).permissions.delete
                            ) {
                              setConfirmUserDeletePopUp((prev) => ({
                                ...prev,
                                openDialog: true
                              }));
                            } else {
                              setValidationMsg('User does not have permission to delete.');
                              setOpenValidation(true);
                            }
                          }
                        }}
                      >
                        Delete Selected Users
                      </Button>

                      <Button
                        sx={{
                          backgroundColor: '#0d4689',
                          padding: '4px 16px',
                          color: 'white',

                          '&:hover': { backgroundColor: '#0056b3' }
                        }}
                        onClick={() => {
                          if (
                            userDataFromLocal().modules.some(
                              (module) => module.module_name === 'User Management'
                            )
                          ) {
                            if (
                              userDataFromLocal().modules.find(
                                (module) => module.module_name === 'User Management'
                              ).permissions.add
                            ) {
                              navigate(`/usermanagement/add-user`);
                            } else {
                              setValidationMsg('User does not have permission to add.');
                              setOpenValidation(true);
                            }
                          }
                        }}
                      >
                        <AddCircleOutline sx={{ marginRight: '7px' }} />
                        Add Users
                      </Button>
                    </Box>
                  </Box>
                  <Paper elevation={8} sx={{ marginTop: '10px' }}>
                    <UserManagenetToolbar
                      selectedUsers={selectedUsers}
                      setOpenDelete={setOpenDelete}
                      manageAccessListApi={manageAccessListApi}
                    />
                    <TableContainer
                      sx={{ height: '70vh', margin: '0px !important', padding: '0px !important' }}
                      component={Paper}
                      className="custom-table-container"
                    >
                      <Table
                        sx={{ margin: '10px', border: 'none !important' }}
                        aria-label="User Table"
                        className="custom-user-table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'id'
                                }));
                              }}
                            >
                              ID{' '}
                              {userTableSort.is_active === 'id' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.ID.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort(
                                        (a, b) => a.user_id - b.user_id
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        ID: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.ID.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort(
                                        (a, b) => b.user_id - a.user_id
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        ID: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.ID.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'fname'
                                }));
                              }}
                            >
                              First Name{' '}
                              {userTableSort.is_active === 'fname' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.FIRST_NAME.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        a.first_name.localeCompare(b.first_name)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        FIRST_NAME: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.FIRST_NAME.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        b.first_name.localeCompare(a.first_name)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        FIRST_NAME: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.FIRST_NAME.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'lname'
                                }));
                              }}
                            >
                              Last Name{' '}
                              {userTableSort.is_active === 'lname' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.LAST_NAME.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        a.last_name.localeCompare(b.last_name)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        LAST_NAME: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.LAST_NAME.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        b.last_name.localeCompare(a.last_name)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        LAST_NAME: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.LAST_NAME.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'username'
                                }));
                              }}
                            >
                              Username{' '}
                              {userTableSort.is_active === 'username' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.USERNAME.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        a.username.localeCompare(b.username)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        USERNAME: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.USERNAME.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        b.username.localeCompare(a.username)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        USERNAME: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.USERNAME.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'email'
                                }));
                              }}
                            >
                              Email{' '}
                              {userTableSort.is_active === 'email' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.EMAIL.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        a.email.localeCompare(b.email)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        EMAIL: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.EMAIL.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        b.email.localeCompare(a.email)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        EMAIL: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.EMAIL.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            {/* <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'username'
                                }));
                              }}
                            >
                              Email / Username{' '}
                              {userTableSort.is_active === 'username' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.EMAIL_USERNAME.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        a.email_id.localeCompare(b.email_id)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        EMAIL_USERNAME: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.EMAIL_USERNAME.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        b.email_id.localeCompare(a.email_id)
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        EMAIL_USERNAME: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.EMAIL_USERNAME.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell> */}
                            {/* <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'admin_status'
                                }));
                              }}
                            >
                              Admin Status{' '}
                              {userTableSort.is_active === 'admin_status' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.ADMIN_STATUS.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        String(a.is_admin).localeCompare(String(b.is_admin))
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        ADMIN_STATUS: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.ADMIN_STATUS.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        String(b.is_admin).localeCompare(String(a.is_admin))
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        ADMIN_STATUS: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.ADMIN_STATUS.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell> */}
                            {/* <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'active_status'
                                }));
                              }}
                            >
                              Active Status{' '}
                              {userTableSort.is_active === 'active_status' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.ACTIVE_STATUS.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        String(a.is_active).localeCompare(String(b.is_active))
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        ACTIVE_STATUS: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.ACTIVE_STATUS.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        String(b.is_active).localeCompare(String(a.is_active))
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        ACTIVE_STATUS: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.ACTIVE_STATUS.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell> */}
                            {/* <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setUserTableSort((prev) => ({
                                  ...prev,
                                  is_active: 'c_date'
                                }));
                              }}
                            >
                              Creation Date{' '}
                              {userTableSort.is_active === 'c_date' && (
                                <IconButton
                                  onClick={() => {
                                    if (userTableSort.CREATION_DATE.sortType === 'asc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        String(a.creation_date).localeCompare(
                                          String(b.creation_date)
                                        )
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        CREATION_DATE: { sortType: 'desc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    } else if (userTableSort.CREATION_DATE.sortType === 'desc') {
                                      const sortedArray = userInformationData.sort((a, b) =>
                                        String(b.creation_date).localeCompare(
                                          String(a.creation_date)
                                        )
                                      );
                                      setUserTableSort((prev) => ({
                                        ...prev,
                                        CREATION_DATE: { sortType: 'asc' }
                                      }));
                                      setUserInformationData(sortedArray);
                                    }
                                  }}
                                  sx={{ width: 25, height: 25, '& svg': { width: 15, height: 15 } }}
                                >
                                  {userTableSort.CREATION_DATE.sortType === 'desc' ? (
                                    <ArrowUpward />
                                  ) : (
                                    <ArrowDownward />
                                  )}
                                </IconButton>
                              )}
                            </TableCell> */}
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userInformationData.map((_user, _id) => (
                            <TableRow key={_id}>
                              <TableCell>
                                <Checkbox
                                  checked={confirmUserDeletePopUp.user_id.includes(_user.username)}
                                  onChange={(evt) => {
                                    if (evt.target.checked) {
                                      setConfirmUserDeletePopUp((prev) => ({
                                        ...prev,
                                        openedFromCheckbox: true,
                                        user_id: [...prev.user_id, _user.username]
                                      }));
                                    } else {
                                      const user_id_array = confirmUserDeletePopUp.user_id.filter(
                                        (item) => item !== _user.username
                                      );
                                      setConfirmUserDeletePopUp((prev) => ({
                                        ...prev,
                                        openedFromCheckbox: true,
                                        user_id: user_id_array
                                      }));
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell>{_user.user_id}</TableCell>
                              <TableCell>{_user.first_name}</TableCell>
                              <TableCell>{_user.last_name}</TableCell>
                              <TableCell>{_user.username}</TableCell>
                              <TableCell>{_user.email}</TableCell>
                              {/* <TableCell>
                                <Chip
                                  color={_user.is_admin ? 'success' : 'error'}
                                  sx={
                                    _user.is_admin
                                      ? {
                                          backgroundColor: '#80e280',
                                          color: '#165515',
                                          fontWeight: 'bold'
                                        }
                                      : { fontWeight: 'bold' }
                                  }
                                  label={_user.is_admin ? 'Yes' : 'No'}
                                />
                              </TableCell> */}
                              {/* <TableCell>
                                <Chip
                                  color={_user.is_active ? 'success' : 'error'}
                                  sx={
                                    _user.is_active
                                      ? {
                                          backgroundColor: '#80e280',
                                          color: '#165515',
                                          fontWeight: 'bold'
                                        }
                                      : { fontWeight: 'bold' }
                                  }
                                  label={_user.is_active ? 'Yes' : 'No'}
                                />
                              </TableCell> */}
                              {/* <TableCell>{fDateTime(_user.creation_date)}</TableCell> */}
                              <TableCell>
                                <Tooltip title="Edit" placement="top" arrow>
                                  <IconButton
                                    className="customized-icons"
                                    // onClick={() => {
                                    //   setEditUserInfo({
                                    //     data: {
                                    //       first_name: _user.first_name,
                                    //       last_name: _user.last_name,
                                    //       email_id: _user.email_id,
                                    //       password: _user.password,
                                    //       confirm_password: '',
                                    //       is_active: _user.is_active,
                                    //       is_admin: _user.is_admin
                                    //     },
                                    //     user_id: _user.user_id,
                                    //     editModalOpen: true
                                    //   });
                                    // }}
                                    onClick={(e) => {
                                      if (
                                        userDataFromLocal().modules.some(
                                          (module) => module.module_name === 'User Management'
                                        )
                                      ) {
                                        if (
                                          userDataFromLocal().modules.find(
                                            (module) => module.module_name === 'User Management'
                                          ).permissions.edit
                                        ) {
                                          navigate(`/usermanagement/edit/${_user.username}`);
                                        } else {
                                          setValidationMsg(
                                            'User does not have permission to edit.'
                                          );
                                          setOpenValidation(true);
                                        }
                                      }
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" placement="top" arrow>
                                  <IconButton
                                    className="customized-icons"
                                    onClick={() =>
                                      setConfirmUserDeletePopUp({
                                        openDialog: true,
                                        openedFromCheckbox: false,
                                        user_id: [_user.username]
                                      })
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default UserManagement;
