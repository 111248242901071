import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import './ruleEngineQuery.css';
import { useSelector } from 'react-redux';
import {
  Box,
  TextField,
  Autocomplete,
  Typography,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  FormHelperText,
  Divider
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import { Save, Close as MuiCloseIcon } from '@mui/icons-material';

import { useParams } from 'react-router';
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import { userDataFromLocal } from '../../../utils/getUserDetails';

const {
  GetApiByUrl,
  PostSaveRuleApi,
  ExtractRuleApi,
  PatchSavedRuleApi,
  PatchApiByUrl,
  ViewSavedRuleApi
} = KnowledgeGraphService;

const ruleEngineOperators = [
  // { name: 'exclude', label: 'Exclude', type: 'string' },
  // { name: 'not_an_exact', label: 'Not An Exact', type: 'string' },
  { name: 'contains', label: 'Contains', type: 'string' },
  { name: 'starts_with', label: 'Starts With', type: 'string' },
  { name: 'ends_with', label: 'Ends With', type: 'string' },
  { name: 'exact_match', label: 'Exact Match', type: 'string' },
  { name: 'is_null', label: 'Is Null', type: 'string & number' },
  { name: 'greater_than', label: '>', type: 'number' },
  { name: 'less_than', label: '<', type: 'number' },
  { name: '=', label: '=', type: 'number' },
  { name: 'greater_than_equals', label: '>=', type: 'number' },
  { name: 'less_than_equals', label: '<=', type: 'number' },
  { name: 'not_equals', label: '!=', type: 'number' }
];

const initialQuery = { rules: [] };

export default function RuleEngineQuery({
  currentMetaDataId,
  savedRulesNameList,
  setSavedRulesNameList,
  ruleType,
  stylesheet,
  setOpenRuleModel,
  setPatchToggleSaveModal,
  closeRuleSaveModel,
  handleNodeRuleResetValues,
  handleEdgeRuleResetValues,
  setDynamicGlobalRuleEngineKey,
  patchToggleSaveModal,
  selectedRules,
  setMutateSavedRuleList
}) {
  const [nodeQuery, setNodeQuery] = useState(initialQuery);
  const [edgeQuerySource, setEdgeQuerySource] = useState(initialQuery);
  const [edgeQueryTarget, setEdgeQueryTarget] = useState(initialQuery);
  const [edgeQueryRelationship, setEdgeQueryRelationship] = useState(initialQuery);
  const [fieldData, setFieldData] = useState([]);

  const [edgeFieldData, setEdgeFieldData] = useState({
    toDisplay: [],
    allData: []
  });
  const [edgeFieldData_SrcTarget, setEdgeFieldData_SrcTarget] = useState({
    source: { toDisplay: [], allData: [] },
    target: { toDisplay: [], allData: [] }
  });
  const [ruleTextFieldName, setRuleTextFieldName] = useState('');
  const [ruleDesc, setRuleDesc] = useState('');
  const [sourceName, setSourceName] = useState('');
  const [ruleTypeName, setRuleTypeName] = useState('node_rule');
  const [ruleTextFieldErrorMsg, setRuleTextFieldErrorMsg] = useState('');
  const [source_TargetData, setSource_TargetData] = useState({
    source: { label: '', conditions: [] },
    target: { label: '', conditions: [] }
  });

  const [edgeFilterData, setEdgeFilterData] = useState({
    name: '',
    direction: 'in',
    operation_type: 'count',
    count_conditions: {
      operator: ruleEngineOperators.filter((item) => item.type.includes('number'))[0].name,
      value: '',
      operator_list: ruleEngineOperators.filter((item) => item.type.includes('number'))
    },
    property_conditions: []
  });
  const { enqueueSnackbar } = useSnackbar();

  // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const knowledgeGraphStoreItem = useSelector((state) => state.knowledgeGraph);
  const {
    createKnowledgeGraph: { cyToState }
  } = knowledgeGraphStoreItem;
  // console.log('knowledgeGraphStoreItemcyToState', knowledgeGraphStoreItem);

  // -- Push all Nodes properties to the rule engine.
  useEffect(() => {
    if (cyToState) {
      const fieldDataAll = [];
      const allColumns = [];

      cyToState.nodes().forEach((element) => {
        if (element.data().props !== undefined) {
          fieldDataAll.push(
            ...Object.keys(element.data().props).map((elm) => `${element.data().label} ➡️ ${elm}`)
          );
          allColumns.push(element.data().props);
        }
      });

      const filteredData = [...new Set(fieldDataAll)];
      const formattedFieldsData = filteredData.map((data) => {
        const label = data.split('➡️')[1].trim();
        const type = typeof allColumns.find((obj) => obj[label])[label];
        return {
          name: data,
          label: data,
          type: type
        };
      });

      const fieldsWithTypes = formattedFieldsData.map((data) => {
        let _inputType = 'text';

        switch (data.type) {
          case 'string':
            _inputType = 'text';
            break;
          case 'number':
            _inputType = 'number';
            break;
          case 'date':
            _inputType = 'date';
            break;

          default:
            break;
        }

        return {
          name: data.name,
          label: data.name,
          type: data.type,
          inputType: _inputType,
          operators: ruleEngineOperators.filter((item) => {
            return String(item.type).includes(data.type);
          })
        };
      });

      setFieldData([...fieldsWithTypes]);
      console.log('fieldsWithTypes', fieldsWithTypes);

      const edgefieldDataAll = [];
      const edgeAllColumns = [];
      cyToState.edges().forEach((element) => {
        if (element.data().label !== undefined) {
          edgefieldDataAll.push(
            ...Object.keys(element.data().props).map((elm) => `${element.data().label} ➡️ ${elm}`)
          );
          edgeAllColumns.push(element.data().props);
        }
      });

      const filteredEdgeData = [...new Set(edgefieldDataAll)];
      console.log('satyy', filteredEdgeData);

      const formattedEdgeData = filteredEdgeData.map((data) => {
        const label = data.split('➡️')[1].trim();
        const type = typeof edgeAllColumns.find((obj) => obj[label])[label];
        return {
          name: data,
          label: data,
          type: type
        };
      });

      const edgeFieldsWithTypes = formattedEdgeData.map((data) => {
        let _inputType = 'text';

        switch (data.type) {
          case 'string':
            _inputType = 'text';
            break;
          case 'number':
            _inputType = 'number';
            break;
          case 'date':
            _inputType = 'date';
            break;

          default:
            break;
        }
        return {
          name: data.name,
          label: data.name,
          type: data.type,
          inputType: _inputType,
          operators: ruleEngineOperators.filter((item) => {
            return item.type === data.type;
          })
        };
      });

      setEdgeFieldData_SrcTarget((prev) => ({
        ...prev,
        source: {
          allData: [...fieldsWithTypes],
          toDisplay: [...fieldsWithTypes]
        },
        target: {
          allData: [...fieldsWithTypes],
          toDisplay: [...fieldsWithTypes]
        }
      }));

      setEdgeFieldData((prev) => ({
        ...prev,
        toDisplay: [...edgeFieldsWithTypes],
        allData: [...edgeFieldsWithTypes]
      }));
    }
  }, [cyToState]);

  // -- Triggers when the user saves a rule to the rule list.
  const { experimentId, modelId } = useParams();

  const handleRuleSave = () => {
    console.log('creating node rule');
    if (ruleTextFieldName && ruleTypeName) {
      // const labelExists = savedRulesNameList.some((obj) => obj.label === ruleTextFieldName);
      // if (labelExists) {
      //   setRuleTextFieldErrorMsg('Name already exists.');
      //   return;
      // }

      // setRuleTextFieldErrorMsg('');

      const modifiedData = nodeQuery.rules.map((item) => {
        if (typeof item === 'object' && item !== null && 'field' in item) {
          const [beforeValue, afterValue] = item.field.split(' ➡️ ').map((str) => str.trim());
          const { id, valueSource, operator, field, value } = item;
          if (
            ruleEngineOperators.some((item) => item.name === operator && item.type === 'number')
          ) {
            return {
              value: Number(value),
              operator: operator,
              property: afterValue,
              label: beforeValue,
              datatype: _.isInteger(Number(value)) ? 'int' : 'float'
            };
          } else if (
            ruleEngineOperators.some((item) => item.name === operator && item.type === 'string')
          ) {
            return {
              value,
              operator,
              property: afterValue,
              label: beforeValue,
              datatype: 'string'
            };
          }
        }
        return item;
      });

      const apiObject = modifiedData.flatMap((item) => {
        if (typeof item === 'object' && modifiedData.length > 1) {
          return ['(', item, ')'];
        } else {
          return item;
        }
      });

      const authToken = userDataFromLocal();

      const apiPayload = {
        rule_name: ruleTextFieldName,
        description: ruleDesc,
        source_name: sourceName,
        project_id: experimentId,
        rule_type:
          ruleType === 'node_rule'
            ? 'node_rule'
            : ruleType === 'edge_rule'
            ? 'edge_rule'
            : ruleTypeName,
        created_by: authToken.userName,
        creator_user_id: authToken.userID,
        rule_config: apiObject,
        ui_rule_config: nodeQuery,
        isGlobal: ruleType === 'node_rule' ? false : ruleType === 'edge_rule' ? false : true
      };

      (async () => {
        let apiResponse = {};
        let apiMethod = null;
        if (patchToggleSaveModal && selectedRules.length > 0) {
          const savedRuleId = selectedRules[0].id;
          apiPayload.rule_id = savedRuleId;
          apiPayload.updated_by = authToken.userName;
          apiMethod = 'patch';
          apiResponse = await PatchSavedRuleApi(apiPayload);
        } else {
          apiMethod = 'post';
          apiResponse = await PostSaveRuleApi(null, apiPayload);
        }

        if (ruleType !== 'node_rule' && ruleType !== 'edge_rule') {
          enqueueSnackbar(apiResponse.message, {
            variant: apiResponse.status,
            autoHideDuration: 3000
          });
        }

        if (apiResponse.status === 'success' && apiResponse.code === 200) {
          // console.log('rule saved Response', apiResponse.data);
          if (patchToggleSaveModal) {
            setMutateSavedRuleList(new Date());
          } else {
            setSavedRulesNameList((prevNames) => [
              ...prevNames,
              { label: ruleTextFieldName, id: apiResponse.data.rule_id }
            ]);
          }
          if (ruleType === 'node_rule') {
            const metaResponse = await GetApiByUrl(
              `visual/manage_metadata?link_id=${modelId}&project_id=${experimentId}`
            );
            if (metaResponse.status === 'success' && metaResponse.code === 200) {
              const { meta_id, nodes_metadata, edges_metadata, node_rules, edge_rules, others } =
                metaResponse.data.metadata[0];

              const metaCred = {
                meta_id: meta_id,
                project_id: experimentId,
                link_id: modelId,
                nodes_metadata: nodes_metadata,
                edges_metadata: edges_metadata,
                node_rules: {
                  ...node_rules,
                  [apiResponse.data.rule_id]: stylesheet
                },
                edge_rules: edge_rules,
                others: others
              };
              console.log('metadata api', metaCred);
              const saveMetaData = await PatchApiByUrl('/visual/manage_metadata', metaCred);

              if (saveMetaData.status === 'success' && saveMetaData.code === 200) {
                console.log('metadata patch api', saveMetaData);
                enqueueSnackbar('Node Rule Created Successfully.', {
                  variant: saveMetaData.status,
                  autoHideDuration: 5000
                });
                closeRuleSaveModel();
                handleNodeRuleResetValues();
              } else {
                enqueueSnackbar('Problem while creating node rule', {
                  variant: 'error',
                  autoHideDuration: 5000
                });
              }
            }
          } else if (ruleType !== 'node_rule' && ruleType !== 'edge_rule') {
            setDynamicGlobalRuleEngineKey(new Date());
            setOpenRuleModel(false);
            setPatchToggleSaveModal(false);
          }

          if (apiMethod === 'post') {
            const extractRuleApi = await ExtractRuleApi(
              `rule_id=${apiResponse.data.rule_id}&edit=false`
            );
            console.log('extractRuleApi', extractRuleApi);
          } else if (apiMethod === 'patch') {
            const extractRuleApi = await ExtractRuleApi(
              `rule_id=${apiResponse.data.rule_id}&edit=true`
            );
            console.log('extractRuleApi', extractRuleApi);
          }
        } else {
          console.log('rule saved Response Failed', apiResponse);
        }
      })();

      console.log('rule saved Api', apiPayload);
    }
  };

  const handleEdgeRuleSave = () => {
    if ((ruleTextFieldName && ruleType === 'edge_rule') || ruleTypeName === 'edge_rule') {
      // const labelExists = savedRulesNameList.some((obj) => obj.label === ruleTextFieldName);
      // if (labelExists) {
      //   setRuleTextFieldErrorMsg('Name already exists.');
      //   return;
      // }

      // setRuleTextFieldErrorMsg('');

      console.log('edgeFilterData', edgeFilterData);

      const authToken = userDataFromLocal();
      const apiPayload = {
        rule_name: ruleTypeName === 'edge_rule' ? `edge: ${ruleTextFieldName}` : ruleTextFieldName,
        description: ruleDesc,
        source_name: sourceName,
        project_id: experimentId,
        rule_type: 'edge_rule',
        created_by: authToken.userName,
        creator_user_id: authToken.userID,
        rule_config: {
          source: { label: '', conditions: [] },
          relation: {
            name: '',
            direction: edgeFilterData.direction,
            operation_type: edgeFilterData.operation_type,
            count_conditions: {
              operator: '',
              value: ''
            },
            property_conditions: []
          },
          target: { label: '', conditions: [] }
        },
        ui_rule_config: {
          source: { label: '', conditions: [] },
          relation: {
            name: '',
            direction: edgeFilterData.direction,
            operation_type: edgeFilterData.operation_type,
            count_conditions: {
              operator: '',
              value: ''
            },
            property_conditions: []
          },
          target: { label: '', conditions: [] }
        },
        isGlobal: ruleType === 'node_rule' ? false : ruleType === 'edge_rule' ? false : true
      };

      const queryToCypher = (query) => {
        const apiObj = { label: '', conditions: [] };
        const _uiApiObj = { label: '', conditions: query };

        const modifiedData = query.rules.map((item) => {
          if (typeof item === 'object' && item !== null && 'field' in item) {
            const [beforeValue, afterValue] = item.field.split(' ➡️ ').map((str) => str.trim());
            const { id, valueSource, operator, field, value } = item;

            apiObj.label = beforeValue;
            _uiApiObj.label = beforeValue;

            if (
              ruleEngineOperators.some((item) => item.name === operator && item.type === 'number')
            ) {
              return {
                value: Number(value),
                operator: operator,
                property: afterValue,
                datatype: _.isInteger(Number(value)) ? 'int' : 'float'
              };
            } else if (
              ruleEngineOperators.some((item) => item.name === operator && item.type === 'string')
            ) {
              return {
                value,
                operator,
                property: afterValue,
                datatype: 'string'
              };
            }
          }
          return item;
        });

        const apiObject = modifiedData.flatMap((item) => {
          if (typeof item === 'object') {
            return ['(', item, ')'];
          } else {
            return item;
          }
        });
        apiObj.conditions = apiObject;
        if (apiObj.label === '' && apiObj.conditions.length === 0) {
          return {};
        } else {
          return { apiObj, _uiApiObj };
        }
      };
      const { apiObj: apiObj1, _uiApiObj: _uiApiObj1 } = queryToCypher(edgeQuerySource);
      apiPayload.rule_config.source = apiObj1;
      apiPayload.ui_rule_config.source = _uiApiObj1;
      // console.log('myQuery source', myQuerySource);

      const { apiObj: apiObj2, _uiApiObj: _uiApiObj2 } = queryToCypher(edgeQueryTarget);
      apiPayload.rule_config.target = apiObj2;
      apiPayload.ui_rule_config.target = _uiApiObj2;
      // console.log('myQuery target', myQueryTarget);

      if (edgeFilterData.operation_type === 'property') {
        const { apiObj: apiObj3, _uiApiObj: _uiApiObj3 } = queryToCypher(edgeQueryRelationship);

        apiPayload.rule_config.relation.property_conditions = apiObj3.conditions;
        apiPayload.rule_config.relation.name = apiObj3.label;
        apiPayload.rule_config.relation.count_conditions = {};
        apiPayload.ui_rule_config.relation.property_conditions = _uiApiObj3.conditions;
        apiPayload.ui_rule_config.relation.name = _uiApiObj3.label;
        apiPayload.ui_rule_config.relation.count_conditions = {};
        // console.log('myQuery edge', myQueryEdge);
        // console.log('myQuery edge data', edgeQueryRelationship);
      } else if (edgeFilterData.operation_type === 'count') {
        apiPayload.rule_config.relation.count_conditions.operator =
          edgeFilterData.count_conditions.operator;

        apiPayload.rule_config.relation.count_conditions.value = Number(
          edgeFilterData.count_conditions.value
        );

        apiPayload.rule_config.relation.name = edgeFilterData.name;
        apiPayload.rule_config.relation.property_conditions = [];

        // ui_api_config
        apiPayload.ui_rule_config.relation.count_conditions.operator =
          edgeFilterData.count_conditions.operator;

        apiPayload.ui_rule_config.relation.count_conditions.value = Number(
          edgeFilterData.count_conditions.value
        );

        apiPayload.ui_rule_config.relation.name = edgeFilterData.name;
        apiPayload.ui_rule_config.relation.property_conditions = [];
      }

      console.log('myQuery apipayload', apiPayload);

      (async () => {
        let apiResponse = {};
        let apiMethod = null;
        if (patchToggleSaveModal && selectedRules.length > 0) {
          const savedRuleId = selectedRules[0].id;
          apiPayload.rule_id = savedRuleId;
          apiPayload.updated_by = authToken.userName;
          apiMethod = 'patch';
          apiResponse = await PatchSavedRuleApi(apiPayload);
        } else {
          apiMethod = 'post';
          apiResponse = await PostSaveRuleApi(null, apiPayload);
        }

        // const apiResponse = await PostSaveRuleApi(null, apiPayload);

        if (ruleType !== 'node_rule' && ruleType !== 'edge_rule') {
          enqueueSnackbar(apiResponse.message, {
            variant: apiResponse.status,
            autoHideDuration: 5000
          });
        }

        if (apiResponse.status === 'success' && apiResponse.code === 200) {
          // console.log('rule saved Response', apiResponse.data);
          if (patchToggleSaveModal) {
            setMutateSavedRuleList(new Date());
          } else {
            setSavedRulesNameList((prevNames) => [
              ...prevNames,
              { label: apiPayload.rule_name, id: apiResponse.data.rule_id }
            ]);
          }
          if (ruleType === 'edge_rule') {
            const metaResponse = await GetApiByUrl(
              `visual/manage_metadata?link_id=${modelId}&project_id=${experimentId}`
            );
            if (metaResponse.status === 'success' && metaResponse.code === 200) {
              const { meta_id, nodes_metadata, edges_metadata, node_rules, edge_rules, others } =
                metaResponse.data.metadata[0];

              const metaCred = {
                meta_id: meta_id,
                project_id: experimentId,
                link_id: modelId,
                nodes_metadata: nodes_metadata,
                edges_metadata: edges_metadata,
                node_rules: node_rules,
                edge_rules: {
                  ...edge_rules,
                  [apiResponse.data.rule_id]: stylesheet
                },
                others: others
              };
              console.log('metadata api', metaCred);
              const saveMetaData = await PatchApiByUrl('/visual/manage_metadata', metaCred);

              if (saveMetaData.status === 'success' && saveMetaData.code === 200) {
                console.log('metadata patch api', saveMetaData);
                enqueueSnackbar('Edge Rule Created Successfully.', {
                  variant: saveMetaData.status,
                  autoHideDuration: 5000
                });
                handleEdgeRuleResetValues();
                closeRuleSaveModel();
              } else {
                enqueueSnackbar('Problem while creating node rule', {
                  variant: 'error',
                  autoHideDuration: 5000
                });
              }
            }
          } else if (ruleType !== 'node_rule' && ruleType !== 'edge_rule') {
            setDynamicGlobalRuleEngineKey(new Date());
            setOpenRuleModel(false);
            setPatchToggleSaveModal(false);
          }

          if (apiMethod === 'post') {
            const extractRuleApi = await ExtractRuleApi(
              `rule_id=${apiResponse.data.rule_id}&edit=false`
            );
            console.log('extractRuleApi', extractRuleApi);
          } else if (apiMethod === 'patch') {
            const extractRuleApi = await ExtractRuleApi(
              `rule_id=${apiResponse.data.rule_id}&edit=true`
            );
            console.log('extractRuleApi', extractRuleApi);
          }
        } else {
          console.log('rule saved Response Failed', apiResponse);
        }
      })();

      // console.log('rule saved Api', apiPayload);
    }
  };

  useEffect(() => {
    if (patchToggleSaveModal && selectedRules.length > 0) {
      (async () => {
        const authToken = userDataFromLocal();
        const savedRuleId = selectedRules[0].id;

        const param = `project_id=${experimentId}&user_id=${authToken.userID}&rule_id=${savedRuleId}`;
        const _savedRuleData = await ViewSavedRuleApi(param);

        if (
          _savedRuleData.status === 'success' &&
          _savedRuleData.code === 200 &&
          _savedRuleData.data.length > 0
        ) {
          const ruleData = _savedRuleData.data[0];
          setRuleTextFieldName(ruleData.rule_name);
          setRuleDesc(ruleData.description);
          setSourceName(ruleData.source_name);

          if (
            ruleType === 'node_rule' ||
            (ruleType === 'global_rule' && 'rules' in ruleData.ui_rule_config)
          ) {
            if (ruleType === 'global_rule' && 'rules' in ruleData.ui_rule_config) {
              setRuleTypeName('node_rule');
            }
            setNodeQuery(
              ruleData.ui_rule_config.rules.length > 0 ? ruleData.ui_rule_config : initialQuery
            );
          } else if (ruleType === 'edge_rule' || ruleType === 'global_rule') {
            if ('source' in ruleData.ui_rule_config) {
              setRuleTypeName('edge_rule');
            }
            setEdgeQuerySource(
              ruleData.ui_rule_config?.source?.conditions?.rules?.length > 0
                ? ruleData.ui_rule_config.source.conditions
                : initialQuery
            );
            setEdgeQueryTarget(
              ruleData.ui_rule_config?.target?.conditions?.rules?.length > 0
                ? ruleData.ui_rule_config.target.conditions
                : initialQuery
            );
            setSource_TargetData({
              source: { label: ruleData.ui_rule_config?.source?.label ?? '', conditions: [] },
              target: { label: ruleData.ui_rule_config?.target?.label ?? '', conditions: [] }
            });
            setEdgeFilterData({
              name: ruleData.ui_rule_config?.relation.name,
              direction: ruleData.ui_rule_config?.relation.direction,
              operation_type: ruleData.ui_rule_config?.relation.operation_type,
              count_conditions: {
                operator: ruleData.ui_rule_config?.relation.count_conditions.operator,
                value: ruleData.ui_rule_config?.relation.count_conditions.value,
                operator_list: ruleEngineOperators.filter((item) => item.type.includes('number'))
              },
              property_conditions: []
            });
            if (
              ruleData.ui_rule_config.relation.property_conditions &&
              typeof ruleData.ui_rule_config.relation.property_conditions === 'object' &&
              Array.isArray(ruleData.ui_rule_config.relation.property_conditions.rules) &&
              ruleData.ui_rule_config.relation.property_conditions.rules.length > 0
            ) {
              const _queryData = {
                rules: ruleData.ui_rule_config.relation.property_conditions.rules
              };
              console.log('_queryData', _queryData);
              setEdgeQueryRelationship(_queryData.rules.length > 0 ? _queryData : initialQuery);
            }
          }
          console.log('ruleData', ruleData);

          // else {
          //   console.log('no data found');
          // }
        }
      })();
    }
  }, [patchToggleSaveModal]);

  useEffect(() => {
    console.log('source_TargetData', source_TargetData);
  }, [source_TargetData]);

  const _edgeLabels = [...new Set(cyToState.edges())].map((element) => element.data().label);
  const _nodeLabels = [...new Set(cyToState.nodes())].map((element) => element.data().label);
  // console.log(_nodeLabels, '_nodeLabels_nodeLabels');

  return (
    <>
      <Box sx={{ backgroundColor: 'transparent', marginBottom: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              variant={'span'}
              sx={{ fontWeight: 'bold', fontSize: '0.8rem', marginRight: 0.5 }}
            >
              Rule Name:
            </Typography>
            <TextField
              value={ruleTextFieldName}
              onChange={(elm) => {
                // setRuleTextFieldErrorMsg('');
                setRuleTextFieldName(elm.currentTarget.value);
              }}
              size={'small'}
              sx={{ '&>div': { border: '1px solid #ddd' } }}
              // error={ruleTextFieldErrorMsg ? true : false}
              // helperText={ruleTextFieldErrorMsg}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              variant={'span'}
              sx={{ fontWeight: 'bold', fontSize: '0.8rem', marginRight: 0.5 }}
            >
              Description:
            </Typography>
            <TextField
              value={ruleDesc}
              onChange={(elm) => {
                setRuleDesc(elm.currentTarget.value);
              }}
              size={'small'}
              sx={{ '&>div': { border: '1px solid #ddd' } }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              variant={'span'}
              sx={{ fontWeight: 'bold', fontSize: '0.8rem', marginRight: 0.5 }}
            >
              Source Name:
            </Typography>
            <TextField
              value={sourceName}
              onChange={(elm) => {
                setSourceName(elm.currentTarget.value);
              }}
              size={'small'}
              sx={{ '&>div': { border: '1px solid #ddd' } }}
            />
          </Box>
          {ruleType !== 'node_rule' && ruleType !== 'edge_rule' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant={'span'}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '0.8rem',
                  marginRight: 0.5,
                  flexWrap: 'nowrap'
                }}
              >
                Apply to:
              </Typography>
              <FormControl>
                <Select
                  size="small"
                  value={ruleTypeName}
                  placeholder="Select"
                  onChange={(evt) => setRuleTypeName(evt.target.value)}
                >
                  <MenuItem value={'node_rule'}>Nodes</MenuItem>
                  <MenuItem value={'edge_rule'}>Edges</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
          <Button
            startIcon={<Save />}
            onClick={
              ruleTypeName === 'edge_rule' || ruleType === 'edge_rule'
                ? handleEdgeRuleSave
                : handleRuleSave
            }
            sx={
              ruleTypeName === 'edge_rule' || ruleType === 'edge_rule'
                ? {
                    pointerEvents:
                      edgeFilterData.name !== '' &&
                      // source_TargetData.source.label !== '' &&
                      // edgeQuerySource.rules.length > 0 &&
                      ruleTextFieldName.trim()
                        ? 'visible'
                        : 'none',
                    opacity:
                      edgeFilterData.name !== '' &&
                      // source_TargetData.source.label !== '' &&
                      // edgeQuerySource.rules.length > 0 &&
                      ruleTextFieldName.trim()
                        ? 1
                        : 0.5
                  }
                : {
                    pointerEvents:
                      nodeQuery.rules.length > 0 && ruleTextFieldName.trim() ? 'visible' : 'none',
                    opacity: nodeQuery.rules.length > 0 && ruleTextFieldName.trim() ? 1 : 0.5
                  }
            }
            variant={'contained'}
          >
            Save Rule
          </Button>
        </Box>
      </Box>
      {ruleTypeName === 'edge_rule' || ruleType === 'edge_rule' ? (
        <>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>Source Node Rule</Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  size="small"
                  value={source_TargetData.source.label}
                  onChange={(evt) => {
                    setSource_TargetData((prev) => ({
                      ...prev,
                      source: { ...prev.source, label: evt.target.value }
                    }));

                    setEdgeQuerySource(initialQuery);

                    setEdgeFieldData_SrcTarget((prev) => ({
                      ...prev,
                      source: {
                        ...prev.source,
                        toDisplay: prev.source.allData.filter((item) =>
                          item.name.includes(evt.target.value)
                        )
                      }
                    }));
                  }}
                >
                  {_nodeLabels.length > 0 &&
                    [...new Set(_nodeLabels)].map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Source Label</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={
                source_TargetData.source.label === '' ? { pointerEvents: 'none', opacity: 0.5 } : {}
              }
            >
              <QueryBuilder
                fields={edgeFieldData_SrcTarget.source.toDisplay}
                query={edgeQuerySource}
                onQueryChange={(q) => setEdgeQuerySource(q)}
                controlElements={{
                  addGroupAction: () => null
                }}
                independentCombinators
                showCloneButtons
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>Relationship Rule</Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  size="small"
                  value={edgeFilterData.name}
                  onChange={(evt) => {
                    setEdgeFilterData((prev) => ({
                      ...prev,
                      name: evt.target.value
                    }));

                    setEdgeFieldData((prev) => ({
                      ...prev,
                      toDisplay: prev.allData.filter((item) => item.name.includes(evt.target.value))
                    }));
                  }}
                >
                  {_edgeLabels.length > 0 &&
                    [...new Set(_edgeLabels)].map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Edge Label</FormHelperText>
              </FormControl>
            </Box>
            <Container
              sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'baseline',
                gap: 2
              }}
            >
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  size="small"
                  value={edgeFilterData.direction}
                  onChange={(evt) =>
                    setEdgeFilterData((prev) => ({
                      ...prev,
                      direction: evt.target.value
                    }))
                  }
                >
                  <MenuItem value="in">In</MenuItem>
                  <MenuItem value="out">Out</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </Select>
                <FormHelperText>Edge Direction</FormHelperText>
              </FormControl>

              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  size="small"
                  value={edgeFilterData.operation_type}
                  onChange={(evt) => {
                    setEdgeFilterData((prev) => ({
                      ...prev,
                      operation_type: evt.target.value
                    }));
                  }}
                >
                  <MenuItem value="count">Count</MenuItem>
                  <MenuItem value="property">Property</MenuItem>
                </Select>
                <FormHelperText>Operation Type</FormHelperText>
              </FormControl>

              {edgeFilterData.operation_type === 'count' && (
                <>
                  <Divider orientation="vertical" flexItem />

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography>Count Conditions:</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'baseline'
                      }}
                    >
                      <FormControl sx={{ minWidth: 120 }}>
                        <Select
                          size="small"
                          value={edgeFilterData.count_conditions.operator}
                          onChange={(evt) =>
                            setEdgeFilterData((prev) => ({
                              ...prev,
                              count_conditions: {
                                ...prev.count_conditions,
                                operator: evt.target.value
                              }
                            }))
                          }
                        >
                          {edgeFilterData.count_conditions.operator_list.map((item) => (
                            <MenuItem key={`${item.name}${item.label}`} value={item.name}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Operator</FormHelperText>
                      </FormControl>
                      <TextField
                        value={edgeFilterData.count_conditions.value}
                        onChange={(evt) =>
                          setEdgeFilterData((prev) => ({
                            ...prev,
                            count_conditions: {
                              ...prev.count_conditions,
                              value: evt.target.value
                            }
                          }))
                        }
                        size="small"
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Container>
            {edgeFilterData.operation_type === 'property' && (
              <Box>
                <Typography>Property Conditions:</Typography>
                <Box sx={edgeFilterData.name === '' ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
                  <QueryBuilder
                    fields={edgeFieldData.toDisplay}
                    query={edgeQueryRelationship}
                    onQueryChange={(q) => setEdgeQueryRelationship(q)}
                    controlElements={{
                      addGroupAction: () => null
                    }}
                    independentCombinators
                    showCloneButtons
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>Target Node Rule</Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  size="small"
                  value={source_TargetData.target.label}
                  onChange={(evt) => {
                    setSource_TargetData((prev) => ({
                      ...prev,
                      target: { ...prev.target, label: evt.target.value }
                    }));

                    setEdgeQueryTarget(initialQuery);

                    setEdgeFieldData_SrcTarget((prev) => ({
                      ...prev,
                      target: {
                        ...prev.target,
                        toDisplay: prev.target.allData.filter((item) =>
                          item.name.includes(evt.target.value)
                        )
                      }
                    }));
                  }}
                >
                  {_nodeLabels.length > 0 &&
                    [...new Set(_nodeLabels)].map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Target Label</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={
                source_TargetData.target.label === '' ? { pointerEvents: 'none', opacity: 0.5 } : {}
              }
            >
              <QueryBuilder
                fields={edgeFieldData_SrcTarget.target.toDisplay}
                query={edgeQueryTarget}
                onQueryChange={(q) => setEdgeQueryTarget(q)}
                controlElements={{
                  addGroupAction: () => null
                }}
                independentCombinators
                showCloneButtons
              />
            </Box>
          </Box>
        </>
      ) : (
        <QueryBuilder
          fields={fieldData}
          query={nodeQuery}
          onQueryChange={(q) => setNodeQuery(q)}
          controlElements={{
            addGroupAction: () => null
          }}
          independentCombinators
          showCloneButtons
          // showLockButtons
        />
      )}
    </>
  );
}
