// routes
import Router from './routes';
// theme
import ThemeConfig from './components/theme';
import GlobalStyles from './components/theme/globalStyles';
// components
import ScrollToTop from './components/global/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------
/**
 * Component for rendering other child component
 * @class
 */
function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}
export default App;
