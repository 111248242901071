import React, { useEffect, useState } from 'react';
import BingMapsReact from 'bingmaps-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

const API_KEY = 'AocV8siW3d40MWWQZTFru95ZktXhM3EMlBfx2qGyszYEBfNUSRO7ebQ-MfRcwkZU';

export default function BingMap({ cyToState, setTabValue }) {
  const [mapData, setMapData] = useState([]);
  const [bingMap, setBingMap] = useState();
  const [pushPins, setPushPins] = useState([]);
  const [bingMapType, setBingMapType] = useState('cluster');
  const [mapLatLongKeysList, setMapLatLongKeysList] = useState([]);
  const [mapLatLongConfig, setMapLatLongConfig] = useState({
    latitudeKey: '#',
    longitudeKey: '#',
    open: true,
    submit: false
    // allKeysList: []
  });
  const Microsoft = window.Microsoft;

  function clusterClicked(e, map) {
    if (e.target.containedPushpins) {
      const locs = [];
      for (let i = 0, len = e.target.containedPushpins.length; i < len; i++) {
        // Get the location of each pushpin.
        locs.push(e.target.containedPushpins[i].getLocation());
      }

      // Create a bounding box for the pushpins.
      const bounds = Microsoft.Maps.LocationRect.fromLocations(locs);

      // Zoom into the bounding box of the cluster.
      // Add a padding to compensate for the pixel area of the pushpins.
      map.setView({ bounds: bounds, padding: 100 });
    }
  }

  useEffect(() => {
    if (cyToState) {
      const nodes = cyToState.nodes().map((elm) => elm.data());
      setMapLatLongKeysList([...new Set(nodes.flatMap((item) => Object.keys(item?.props)))]);

      if (mapLatLongConfig.submit) {
        const latLng_Nodes = nodes.filter(
          (elm) =>
            elm.props[mapLatLongConfig?.latitudeKey] !== undefined &&
            elm.props[mapLatLongConfig?.longitudeKey] !== undefined
          // (elm) => elm.props.latitude !== undefined && elm.props.longitude !== undefined
        );

        const _pushPins = [];
        const nodeData = latLng_Nodes.map((item) => {
          // const { latitude, longitude, ...restProps } = item.props;
          const {
            [mapLatLongConfig?.latitudeKey]: latitude,
            [mapLatLongConfig?.longitudeKey]: longitude,
            ...restProps
          } = item.props;
          _pushPins.push({
            latitude: item.props[mapLatLongConfig?.latitudeKey],
            longitude: item.props[mapLatLongConfig?.longitudeKey],
            title: String(item.labelType)
          });
          return {
            id: item.id,
            name: String(item.labelType),
            lat: item.props[mapLatLongConfig?.latitudeKey],
            lng: item.props[mapLatLongConfig?.longitudeKey],
            ...restProps
          };
        });
        // console.log('map', latLng_Nodes);
        setPushPins(_pushPins);
        setMapData([...nodeData]);
      }
    }
  }, [cyToState, mapLatLongConfig]);

  useEffect(() => {
    if (pushPins.length > 0) {
      const defaultColor = 'purple';
      const hoverColor = 'red';
      const mouseDownColor = 'blue';
      let clusterLayer;
      const _bingMap = new Microsoft.Maps.Map('#myBingMap', { zoom: 1 });
      setBingMap(_bingMap);
      //   console.log('Ms_map', bingMap);

      if (bingMapType === 'cluster' || bingMapType === 'hybrid') {
        // Load the Clustering module.
        Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', function () {
          const _pin = [];
          pushPins.map((item) => {
            _pin.push(
              new Microsoft.Maps.Pushpin(
                { latitude: item.latitude, longitude: item.longitude },
                {
                  title: item.title
                  // subTitle: 'City Center'
                  //   text: '1'
                }
              )
            );
          });
          clusterLayer = new Microsoft.Maps.ClusterLayer(_pin, {
            clusteredPinCallback: (cluster) => {
              Microsoft.Maps.Events.addHandler(cluster, 'click', (e) => {
                clusterClicked(e, _bingMap);
              });
              if (bingMapType === 'hybrid') {
                // Define variables for minimum cluster radius, and how wide the outline area of the circle should be.
                const minRadius = 10;
                const outlineWidth = 5;

                // Get the number of pushpins in the cluster
                const clusterSize = cluster.containedPushpins.length;

                // Calculate the radius of the cluster based on the number of pushpins in the cluster, using a logarithmic scale.
                const radius = (Math.log(clusterSize) / Math.log(10)) * 5 + minRadius;

                // Default cluster color is green.
                let fillColor = 'rgba(20, 180, 20, 0.8)';

                if (clusterSize > 5 && clusterSize < 15) {
                  // Make the cluster yellow.
                  fillColor = 'rgba(255, 210, 40, 1)';
                } else if (clusterSize >= 15 && clusterSize < 25) {
                  // Make the cluster orange.
                  fillColor = 'rgba(255, 104, 31, 1)';
                } else if (clusterSize >= 25) {
                  // Make the cluster red.
                  fillColor = 'rgba(255, 40, 40, 0.8)';
                }

                // Create an SVG string of two circles, one on top of the other, with the specified radius and color.
                const svg = [
                  '<svg xmlns="http://www.w3.org/2000/svg" width="',
                  radius * 2,
                  '" height="',
                  radius * 2,
                  '">',
                  '<circle cx="',
                  radius,
                  '" cy="',
                  radius,
                  '" r="',
                  radius,
                  '" fill="',
                  fillColor,
                  '"/>',
                  '<circle cx="',
                  radius,
                  '" cy="',
                  radius,
                  '" r="',
                  radius - outlineWidth,
                  '" fill="',
                  fillColor,
                  '"/>',
                  '</svg>'
                ];

                // Customize the clustered pushpin using the generated SVG and anchor on its center.
                cluster.setOptions({
                  icon: svg.join(''),
                  anchor: new Microsoft.Maps.Point(radius, radius),
                  textOffset: new Microsoft.Maps.Point(0, radius - 8) // Subtract 8 to compensate for height of text.
                });
              }
            },
            gridSize: 95
          });
          _bingMap.layers.insert(clusterLayer);

          Microsoft.Maps.Events.addHandler(clusterLayer, 'mouseover', function (e) {
            e.target.setOptions({ color: hoverColor });
          });

          Microsoft.Maps.Events.addHandler(clusterLayer, 'mousedown', function (e) {
            e.target.setOptions({ color: mouseDownColor });
          });

          Microsoft.Maps.Events.addHandler(clusterLayer, 'mouseout', function (e) {
            e.target.setOptions({ color: defaultColor });
          });
        });
      } else if (bingMapType === 'heatmap') {
        const _heatmap = [];
        pushPins.map((item) => {
          const _coords = new Microsoft.Maps.Location(
            Number(item.latitude),
            Number(item.longitude)
          );
          _heatmap.push(_coords);
        });

        Microsoft.Maps.loadModule('Microsoft.Maps.HeatMap', function () {
          const heatmap = new Microsoft.Maps.HeatMapLayer(_heatmap);
          _bingMap.layers.insert(heatmap);
        });
      }
    }
  }, [pushPins, bingMapType]);

  if (pushPins.length > 0) {
    return (
      <>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: 2,
            background: '#fff',
            borderRadius: 1
          }}
          size="small"
        >
          {/* <InputLabel id="map_type_select">Map Type</InputLabel> */}
          <Select
            // labelId="map_type_select"
            value={bingMapType}
            // label="Map Type"
            onChange={(e) => {
              setBingMapType(e.target.value);
            }}
          >
            <MenuItem value="cluster">Cluster</MenuItem>
            <MenuItem value="heatmap">Heat Map</MenuItem>
            <MenuItem value="hybrid">Hybrid</MenuItem>
          </Select>
        </FormControl>
        {/* <Button variant="con" sx={{ position: 'absolute', top: '10px', left: '10px', zIndex: 2 }}>
          Map Type
        </Button> */}
        <div
          id="myBingMap"
          style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}
        />
      </>
    );
  } else {
    // return 'No Data';
    return (
      <Box>
        <Dialog open={mapLatLongConfig.open}>
          <DialogTitle>Map Configuration</DialogTitle>
          <DialogContent>
            <Box>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={mapLatLongConfig?.latitudeKey}
                  onChange={(e, newVal) => {
                    // handleTimelineInit('edge_name', e, newVal);
                    setMapLatLongConfig((prev) => ({ ...prev, latitudeKey: e.target.value }));
                  }}
                >
                  <MenuItem disabled value="#">
                    <em>Select Latitude</em>
                  </MenuItem>
                  {mapLatLongKeysList.length > 0 &&
                    mapLatLongKeysList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Latitude Key*</FormHelperText>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={mapLatLongConfig?.longitudeKey}
                  onChange={(e, newVal) => {
                    setMapLatLongConfig((prev) => ({ ...prev, longitudeKey: e.target.value }));
                    // handleTimelineInit('xaxis', e, newVal);
                  }}
                >
                  <MenuItem disabled value="#">
                    <em>Select Longitude</em>
                  </MenuItem>
                  {mapLatLongKeysList.length > 0 &&
                    mapLatLongKeysList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Longitude Key*</FormHelperText>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setMapLatLongConfig({
                  latitudeKey: '#',
                  longitudeKey: '#',
                  open: false,
                  submit: false
                });
                setTabValue(0);
              }}
              variant="contained"
            >
              Close
            </Button>
            <Button
              disabled={
                mapLatLongConfig?.latitudeKey === '#' || mapLatLongConfig?.longitudeKey === '#'
              }
              onClick={() =>
                setMapLatLongConfig((prev) => ({ ...prev, submit: true, open: false }))
              }
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}
